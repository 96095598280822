import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  CONTRACT_LISTING,
  GET_CONTRACT_UPDATE,
  CONTRACT_SIGNED,
  CREATE_CONTRACT,
  GET_CONTRACT,
  SIGN_CONTRACT,
  GET_ONP_CONTRACT_DEPLOYED_STATUS,
  CONTRACT_LISTING_PARTNER_BASED,
  GET_REBATE_VALUE,
  UPDATE_AUTHORIZED_ORGANIZATIONS,
  UPDATED_AUTHORIZED_ORGANIZATIONS,
} from '../../graphql/Procurement/contract';
import { orderListing } from '../../utils';
import { useNotification } from '../Shared/notification';
import { CONSTANTS } from '../../constants';

const ContractContext = React.createContext([{}, () => {}]);

const ContractProvider = ({ children }) => {
  const { t } = useTranslation();
  const { handleNotification, showFaucetModal, setShowFaucetModal } = useNotification();

  const [contracts, setContracts] = useState([]);
  const [rebateValue, setRebateValue] = useState([]);

  const [getContracts, { error: contractsError, loading: contractsLoading }] = useLazyQuery(
    CONTRACT_LISTING,
    {
      onCompleted: data => {
        setContracts(orderListing(data.contracts || []));
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [
    getContractsByBusinessPartner,
    { error: partnercontractsError, loading: partnercontractsLoading },
  ] = useLazyQuery(CONTRACT_LISTING_PARTNER_BASED, {
    onCompleted: data => {
      setContracts(orderListing(data.contractsByBusinessPartner || []));
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getContractDetail,
    { data: contractDetail, error: contractDetailError, loading: contractDetailLoading },
  ] = useLazyQuery(GET_CONTRACT, {
    fetchPolicy: 'no-cache',
  });

  const [createContract, { loading: createContractsLoading }] = useMutation(CREATE_CONTRACT, {
    onCompleted: () => {
      handleNotification(t('contracts.createContractSuccess'), 'success');
    },
    onError: () => handleNotification(t('contracts.createContractError'), 'error'),
  });

  const [
    updateAuthorizedOrganizations,
    { loading: updateAuthorizedOrganizationsLoading },
  ] = useMutation(UPDATE_AUTHORIZED_ORGANIZATIONS, {
    fetchPolicy: 'no-cache',
  });

  const [signContract, { loading: signContractsLoading }] = useMutation(SIGN_CONTRACT);

  const [getRebateValue, { error: rebateValueError, loading: rebateLoading }] = useLazyQuery(
    GET_REBATE_VALUE,
    {
      onCompleted: data => {
        setRebateValue(data.getRebateValueForContract);
      },
      fetchPolicy: 'no-cache',
    },
  );

  useSubscription(GET_CONTRACT_UPDATE, {
    onSubscriptionData: data => {
      setContracts(stateContracts => [data.subscriptionData.data.newContract, ...stateContracts]);
    },
  });

  useSubscription(CONTRACT_SIGNED, {
    onSubscriptionData: data => {
      const index = contracts.findIndex(
        obj => obj.contractId === data.subscriptionData.data.contractSigned.contractId,
      );
      contracts[index] = data.subscriptionData.data.contractSigned;
      setContracts(contracts);
    },
  });

  const contractDeploySubscription = useSubscription(GET_ONP_CONTRACT_DEPLOYED_STATUS, {
    onSubscriptionData: data => {
      const contractStatus = data.subscriptionData?.data?.contractONPDeployed;
      if (contractStatus?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
        getContractDetail({
          variables: { contractId: contractStatus?.contractId },
        });
        getContracts();
        handleNotification(t('contracts.signContractSuccess'), 'success');
      } else {
        handleNotification(t('contracts.signContractError'), 'error');
      }
    },
  });

  const updatedAuthorizedOrganizationsSubscription = useSubscription(
    UPDATED_AUTHORIZED_ORGANIZATIONS,
    {
      onSubscriptionData: data => {
        const contractStatus = data.subscriptionData?.data?.updatedMyOrganizations;
        if (contractStatus?.myOrganizationstatus === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          getContractDetail({
            variables: { contractId: contractStatus?._id },
          });
          getContracts();
          handleNotification('Authorized parties updated', 'success');
        } else if (contractStatus?.myOrganizationstatus === CONSTANTS.CONTRACT_STATUSES.FAILED) {
          handleNotification('Updation Failed', 'error');
        }
      },
    },
  );

  const contractLoading =
    createContractsLoading ||
    contractDetailLoading ||
    contractsLoading ||
    signContractsLoading ||
    partnercontractsLoading ||
    rebateLoading ||
    updateAuthorizedOrganizationsLoading;
  const error = contractDetailError || contractsError || partnercontractsError || rebateValueError;

  return (
    <ContractContext.Provider
      value={{
        contracts,
        error,
        getContracts,
        setContracts,
        createContract,
        contractLoading,
        getContractDetail,
        signContract,
        getContractsByBusinessPartner,
        contractDeploySubscription,
        contract: contractDetail?.contract,
        showFaucetModal,
        setShowFaucetModal,
        getRebateValue,
        rebateValue,
        updateAuthorizedOrganizations,
        updatedAuthorizedOrganizationsSubscription,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

const useContractContext = () => useContext(ContractContext);

ContractProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useContractContext, ContractProvider };
