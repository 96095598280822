import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import { filter } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import AddArea from '../../Traceability/AddArea';
import LinkedItem from './LinkedItem';
import { CONSTANTS } from '../../../constants';
import { GET_ERC_TOKENS } from '../../../graphql/Tokenization/token';
import { useNotification } from '../../../contexts/Shared/notification';

const useStyles = makeStyles(() => ({
  controllRoot: {
    display: 'grid',
  },
  description: {
    margin: '10px 0px 10px 0px',
  },
}));

const LinkedTokenField = ({ name, linkedTokens, setFieldValue, readOnly }) => {
  const [allTokens, setAllTokens] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const { linkedTokenDetails } = linkedTokens;
  const { handleNotification } = useNotification();
  const emptyItem = {
    relationship: '',
    relationshipType: '',
    smartContract: '',
    tokenId: '',
    description: '',
  };

  const [getTokens, { loading: tokenContractsLoading }] = useLazyQuery(GET_ERC_TOKENS, {
    onCompleted: data => {
      setAllTokens(data.ercTokenContracts);
    },
    onError: err => {
      handleNotification(
        t('tokens.errorGetTokenContracts', {
          contractError: err?.message || '',
        }),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  const admittedTypes = [
    CONSTANTS.SMARTCONTRACT_TYPES.ERC721,
    CONSTANTS.SMARTCONTRACT_TYPES.ERC1155,
  ];

  const contractCollection = filter(allTokens, token => {
    return admittedTypes.includes(token.contract.tokenType);
  }).map(token => {
    return {
      label: token?.tokenName,
      value: token?.contract?._id,
      key: token?.contract?._id,
    };
  });

  const buildAddArea = push => {
    const addBanner =
      linkedTokenDetails.length < 1
        ? t('traceability.traceItemForm.addRow')
        : t('traceability.traceItemForm.addMoreRow');

    return <AddArea bannerText={addBanner} onClick={() => push(emptyItem)} />;
  };

  const linkedTokenRow = remove => {
    const handleDelete = index => {
      remove(index);
    };

    return linkedTokenDetails.map((linkedTokenDetail, index) => (
      <LinkedItem
        name={name}
        index={index}
        linkedTokenDetail={linkedTokenDetail}
        setFieldValue={setFieldValue}
        contractCollection={contractCollection}
        isContractCollectionLoading={tokenContractsLoading}
        readOnly={readOnly}
        handleDelete={handleDelete}
      />
    ));
  };

  return (
    <div>
      <div className={classes.description}>{t('tokens.linkedTokenMessage')}</div>
      <div className={classes.controllRoot}>
        <FieldArray name={`${name}.linkedTokenDetails`}>
          {({ push, remove }) => (
            <>
              <div>{linkedTokenRow(remove)}</div>
              {!readOnly && <div>{buildAddArea(push)}</div>}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

LinkedTokenField.propTypes = {
  name: PropTypes.string.isRequired,
  linkedTokens: PropTypes.shape({
    linkedTokenDetails: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

LinkedTokenField.defaultProps = {
  readOnly: false,
};
export default LinkedTokenField;
