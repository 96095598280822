import { find } from 'lodash';
import { useState, useEffect } from 'react';
import { TRACE_WIZARD_PROGRESSION_V2 } from '../../../constants';

export const getCurrentPath = history => (history?.location?.pathname?.split('/'))[2];
export const getCurrPathCode = currentPath =>
  find(TRACE_WIZARD_PROGRESSION_V2, { path: currentPath })?.code;

export const handleNavigation = (history, instanceId) => {
  const currentPath = getCurrentPath(history);
  const currPathCode = getCurrPathCode(currentPath);
  if (currPathCode < TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code) {
    const newPath = find(TRACE_WIZARD_PROGRESSION_V2, { code: currPathCode + 1 })?.path;
    history.push(`/traceability/${newPath}/${instanceId}`);
  } else {
    history.push(`/traceability/instance-review/${instanceId}`);
  }
};

export const objectKeyOptions = ({ key, withTranslation, t }) => ({
  customBodyRender: data => data && (withTranslation ? t(data[key]) : data[key]),
});
export const useEditMode = (previousStepCode, lastCompletedIterationCode) => {
  const [editMode, setEditMode] = useState(previousStepCode === lastCompletedIterationCode);

  useEffect(() => {
    setEditMode(previousStepCode === lastCompletedIterationCode);
  }, [lastCompletedIterationCode]);

  return [editMode, setEditMode];
};

export const isUnfinishedStep = (stepKey, instanceDetails) => {
  let isUnfinished = false;
  const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: stepKey })?.code;
  if (currPathCode > instanceDetails.content.lastCompletedIteration + 1) {
    isUnfinished = true;
  }
  return isUnfinished;
};
