import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  BehaviorChip: {
    borderColor: theme.palette.primary.main,
  },
}));

const BehaviorChip = ({ behavior }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Chip
        label={t(`tokens.metaDataStructure.behavior.${behavior}.label`)}
        variant="outlined"
        classes={{
          root: classes.BehaviorChip,
        }}
      />
    </>
  );
};

BehaviorChip.propTypes = {
  behavior: PropTypes.string.isRequired,
};

BehaviorChip.defaultProps = {};

export default BehaviorChip;
