import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { remove } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  addButton: {
    marginTop: '10px',
  },
  removeButtonContainer: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  textField: {
    margin: '5px 0px 5px 0px',
  },
}));

const JsonViewKeyArray = ({ limit, field: { name, value }, form: { setFieldValue }, readOnly }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);

  return (
    <FieldArray name={name}>
      {({ push }) => (
        <>
          <Grid container spacing={2}>
            {value?.length > 0 &&
              value.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  <Grid item xs={5}>
                    <Field
                      size="small"
                      name={`${name}.${index}.key`}
                      label={t('common.key')}
                      component={TextField}
                      disabled={readOnly}
                      className={classes.textField}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Field
                      size="small"
                      name={`${name}.${index}.value`}
                      label={t('common.value')}
                      component={TextField}
                      disabled={readOnly}
                      className={classes.textField}
                    />
                  </Grid>
                  {!readOnly && (
                    <Grid item xs={2} className={classes.removeButtonContainer}>
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={value.length < 2}
                        onClick={() => {
                          if (value.length >= 2) {
                            const tempArray = [...value];
                            remove(tempArray, (tempValue, tempIndex) => tempIndex === index);
                            setFieldValue(name, tempArray);
                          }
                          setCounter(counter - 1);
                        }}
                      >
                        <RemoveCircleOutlineRoundedIcon />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
          {!readOnly && (
            <div className={classes.addButton}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  push({ key: '', value: '' });
                  setCounter(counter + 1);
                }}
                startIcon={<AddIcon />}
                disabled={counter === limit - 1}
              >
                {t('common.addKeyPair')}
              </Button>
            </div>
          )}
        </>
      )}
    </FieldArray>
  );
};

JsonViewKeyArray.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
  limit: PropTypes.number,
  readOnly: PropTypes.bool,
};

JsonViewKeyArray.defaultProps = {
  limit: Number.MAX_SAFE_INTEGER,
  readOnly: false,
};

export default JsonViewKeyArray;
