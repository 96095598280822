import gql from 'graphql-tag';
import { ITEMS_ATTRIBUTES } from './items';
import { STEPS_ATTRIBUTES } from './steps';

export const INSTANCE_ATTRIBUTES = gql`
  fragment INSTANCE_ATTRIBUTES on Instance {
    _id
    date
    name
    status
    lastCompletedIteration
    organizationId
    blockchainType
    blockchainNetwork
    items {
      ...ITEMS_ATTRIBUTES
    }
    steps {
      ...STEPS_ATTRIBUTES
    }
  }
  ${ITEMS_ATTRIBUTES}
  ${STEPS_ATTRIBUTES}
`;

export const INSTANCE_QUERY_ATTRIBUTES = gql`
  fragment INSTANCE_QUERY_ATTRIBUTES on Instance {
    _id
    date
    name
    status
    lastCompletedIteration
    organizationId
    blockchainType
    blockchainNetwork
  }
`;

export const INSTANCE_TRANSACTION_ATTRIBUTES = gql`
  fragment INSTANCE_TRANSACTION_ATTRIBUTES on Transaction {
    transactionHash
    contractAddress
    status
  }
`;

export const GET_INSTANCES = gql`
  query instances {
    instances {
      ...INSTANCE_QUERY_ATTRIBUTES
    }
  }
  ${INSTANCE_QUERY_ATTRIBUTES}
`;

export const GET_INSTANCE_BY_ID = gql`
  query instance($input: String!) {
    instance(_id: $input) {
      ...INSTANCE_ATTRIBUTES
    }
  }
  ${INSTANCE_ATTRIBUTES}
`;

export const ADD_INSTANCE = gql`
  mutation createInstance($input: inputInstance!) {
    createInstance(input: $input) {
      ...INSTANCE_ATTRIBUTES
    }
  }
  ${INSTANCE_ATTRIBUTES}
`;

export const UPDATE_INSTANCE = gql`
  mutation updateInstance($instanceId: String!, $name: String!, $status: Int!) {
    updateInstance(_id: $instanceId, name: $name, status: $status) {
      ...INSTANCE_ATTRIBUTES
    }
  }
  ${INSTANCE_ATTRIBUTES}
`;

export const UPDATE_INSTANCE_PROGRESS = gql`
  mutation updateInstanceProgress($instanceId: String!, $inputIteration: Int!) {
    updateInstanceProgress(instance: $instanceId, inputIteration: $inputIteration) {
      ...INSTANCE_ATTRIBUTES
    }
  }
  ${INSTANCE_ATTRIBUTES}
`;
