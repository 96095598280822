import XLSX from 'xlsx';

export const readFileBinaryString = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      const { result } = e.target;
      resolve(result);
    };
    reader.readAsBinaryString(file);
  });
};
const makeCol = refStr => {
  const o = [];
  const C = XLSX.utils.decode_range(refStr).e.c + 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < C; i++) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
export const getHeaderAndData = str => {
  const wb = XLSX.read(str, { type: 'binary', cellDates: true });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  const [header] = XLSX.utils.sheet_to_json(ws, { header: 1 });
  const data = XLSX.utils.sheet_to_json(ws, { defval: '' });
  let col = makeCol(ws['!ref']);
  col = col.map((i, index) => {
    return {
      range: i.name,
      name: header[index],
    };
  });
  return { header, data, col };
};

export const convertFileToData = async file => {
  const binaryStr = await readFileBinaryString(file);
  return getHeaderAndData(binaryStr);
};

export function allWithProgress(requests, callback) {
  let done = 0;
  let success = 0;
  let error = 0;
  requests.forEach(async item => {
    try {
      await item;
      success += 1;
    } catch (e) {
      error += 1;
    }
    done += 1;
    callback({ total: requests.length, done, error, success });
  });
  return Promise.allSettled(requests);
}
