import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useFormik, Form, Field, FormikProvider, FieldArray } from 'formik';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { findIndex, isInteger } from 'lodash';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Add from '@material-ui/icons/Add';
import uniqid from 'uniqid';
import Delete from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import { useTransaction } from '../../contexts/Procurement/transaction';
import FormErrors from '../Shared/FormErrors';
import DatePickerField from '../Shared/DatePickerField';
import { useContractContext } from '../../contexts/Procurement/contract';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import { useOrganizationContext } from '../../contexts/Procurement/organization';
import { getCurrencySymbol } from '../../utils';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import OcmAccessDeniedLandingPage from '../../pages/Procurement/OcmAccesDeniedLandingPage';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    minWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  noPartnerWarning: {
    paddingTop: theme.spacing(2),
    color: '#B9251C',
  },
  field: {
    width: '95%',
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  descriptor: {
    width: '95%',
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
  addMaterialButton: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(2),
  },
  removeButton: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    width: 'max-content',
    border: '1px solid',
    borderRadius: 0,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  addTierButton: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(2),
    border: '1px solid',
    borderRadius: 0,
  },
  removeTierButton: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    width: 'max-content',
    border: '1px solid',
    borderRadius: 0,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  pricing: {
    paddingTop: theme.spacing(2),
  },
  fields: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    display: 'block',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  materialHeader: {
    width: 'auto',
    '& .MuiIconButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
  addMaterialBox: {
    paddingTop: theme.spacing(8),
    width: '100%',
  },
  form: {
    paddingBottom: theme.spacing(10),
  },
  signingBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(2)}px`,
    boxShadow: `-8px 8px 8px 0px rgba(0,0,0,.25)`,
  },
  loaderContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
  },
}));

const CreateContractWithoutRFQ = () => {
  const [showMaterial, setShowMaterial] = useState([true]);
  const history = useHistory();
  const { signData } = useTransaction();
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const {
    euroCurrencySymbol,
    rebateContractDiscount,
    authorizedPartiesAccess,
  } = useSubscriptionContext();

  const showMaterialHandler = i => {
    setShowMaterial(showMaterial.map((element, index) => (index === i ? !element : element)));
  };

  const { getPartners, partners, getPartnersLoading } = usePartnerContext();
  const { createContract } = useContractContext();

  const { activeOrganization } = useBecOrganizationContext();
  const { userPermissions } = useUserInfo();

  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;

  const isUserNotAuthorized = () => {
    if (!userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATECONTRACT)) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  useEffect(() => {
    if (!isUserNotAuthorized()) {
      getPartners();
    }
  }, [getPartners]);

  const { organizations, getOrganizations, getOrganizationsLoading } = useOrganizationContext();

  useEffect(() => {
    if (rebateContractDiscount && authorizedPartiesAccess) {
      getOrganizations();
    }
  }, []);

  const orgTypes = {
    subsidiary: {
      label: 'Subsidiaries',
    },
    authorized_user: {
      label: 'Authorized Organizations',
    },
  };

  const contractTypes = [
    {
      value: 'tierPricing',
      label: t('contracts.volumeBasedTirePricing'),
    },
  ];

  if (rebateContractDiscount) {
    contractTypes.push({
      value: 'rebatePricing',
      label: t('contracts.fixedPriceWithRebates'),
    });
  }

  const partnersData = partners?.map(partner => {
    return {
      value: partner.partnerOrganization._id,
      label: partner.partnerOrganization.name,
      partnerOrganization: partner.partnerOrganization,
    };
  });

  const closeModal = () => {
    history.goBack();
  };

  const formik = useFormik({
    initialValues: {
      startDate: moment().unix(),
      endDate: moment()
        .add(1, 'days')
        .unix(),
      materials: [{ key: uniqid(), rates: [{ key: uniqid() }], rebates: [{ key: uniqid() }] }],
      recipient: null,
      contractType: contractTypes[0],
      authorizedParties: [],
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const contractInput = {
        rfqId: undefined,
        materials: [],
        startDate: moment(values.startDate * 1000).format(),
        endDate: moment(values.endDate * 1000).format(),
        supplier: { organizationId: values.recipient.value },
        contractType: values.contractType.value,
      };
      values.materials.forEach(material => {
        const materialInput = {
          sku: material.material.replace(/\s+/g, ' ').trim(),
          materialName: material.material.replace(/\s+/g, ' ').trim(),
          materialDescription: material.materialDescription.replace(/\s+/g, ' ').trim(),
        };

        if (
          values.contractType &&
          values.contractType &&
          values.contractType.value === 'tierPricing'
        ) {
          const tierBounds = [];
          const pricesByTier = [];
          if (material.rates.length) {
            material.rates.forEach((rate, index) => {
              if (index === 0) tierBounds.push(0);
              tierBounds.push(rate.quantity);
              pricesByTier.push(Number(rate.price));
            });
          }
          materialInput.tierRules = {
            tierBounds,
            pricesByTier,
          };
        } else {
          const thresholdQty = [];
          const rebateValue = [];
          materialInput.uom = material.uom;
          if (material.rebates.length) {
            material.rebates.forEach(rebate => {
              thresholdQty.push(rebate.quantity);
              rebateValue.push(Number(rebate.percentage));
            });
          }
          materialInput.rebateRules = {
            thresholdQty,
            rebateValue,
          };
          materialInput.unitPrice = material.unitPrice;
        }

        contractInput.materials.push(materialInput);
      });

      const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));

      if (
        rebateContractDiscount &&
        contractInput.contractType === 'rebatePricing' &&
        authorizedPartiesAccess
      ) {
        contractInput.myOrganizations = values.authorizedParties.map(selectedParties => {
          return {
            myOrgHeaderId: selectedParties.value,
            organizationId: selectedParties.organizationId,
            role: selectedParties.role,
            address: selectedParties.address,
          };
        });
      } else {
        contractInput.myOrganizations = [];
      }
      const preimageData = { ...contractInput };
      delete preimageData.contractType;
      const preimage = JSON.stringify(preimageData);

      const { blockchainType } = JSON.parse(blockchainCookie);
      contractInput.blockchainType = blockchainType;

      const buyerSignature = await signData(preimage, blockchainType);
      const valued = {
        ...contractInput,
        preimage,
        buyerSignature,
      };
      const result = await createContract({
        variables: {
          input: {
            ...valued,
          },
        },
      });
      setLoading(false);
      if (result) {
        resetForm();
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.lazy(values => {
      return Yup.object().shape({
        recipient: Yup.string().required(t('rfqs.validation.businessPartner')),
        contractType: Yup.string().required(t('rfqs.validation.contractType')),
        startDate: Yup.number().required(),
        endDate: Yup.number().when(
          'startDate',
          (startDate, schema) =>
            startDate && schema.min(startDate, t('contracts.validation.contractDate')),
        ),
        materials: Yup.array().of(
          Yup.object().shape({
            material: Yup.string()
              .required(t('rfqs.validation.material'))
              .test('material-test', t('rfqs.validation.materialDuplicated'), function materialTest(
                item,
              ) {
                // eslint-disable-next-line react/no-this-in-sfc
                const index = this.path.split(/\[(.*?)\]/)[1];
                if (!item) return true;
                let result = true;
                for (let i = index; i >= 0; i -= 1) {
                  if (i !== index) {
                    if (
                      values.materials[i]?.material.replace(/\s+/g, ' ').trim() ===
                      item.replace(/\s+/g, ' ').trim()
                    ) {
                      result = false;
                    }
                  }
                }
                return result;
              }),
            materialDescription: Yup.string().required(t('rfqs.validation.description')),
            uom: Yup.string(),
            unitPrice: Yup.string(),
            rates: Yup.array().of(
              Yup.object().shape({
                quantity: Yup.number().test(
                  'quantity-amount-test',
                  t('rfqs.validation.quantity'),
                  function quantityTest(item) {
                    if (values.contractType && values.contractType.value === 'rebatePricing')
                      return true;
                    // eslint-disable-next-line react/no-this-in-sfc
                    const i = this.path.split(/\[(.*?)\]/)[1];
                    const index = findIndex(values.materials[i].rates, value =>
                      value ? Number(value.quantity) === item : false,
                    );
                    const prevItem = values.materials[i].rates[index - 1];
                    if (!item) return true;
                    if (item < 0 || (item > 0 && !isInteger(item))) return false;
                    return prevItem ? prevItem.quantity < item : true;
                  },
                ),
                price: Yup.number().test(
                  'price-test',
                  t('rfqs.validation.price'),
                  function priceTest(item) {
                    if (values.contractType && values.contractType.value === 'rebatePricing')
                      return true;
                    // eslint-disable-next-line react/no-this-in-sfc
                    const i = this.path.split(/\[(.*?)\]/)[1];
                    const index = findIndex(values.materials[i].rates, value =>
                      value ? Number(value.price) === item : false,
                    );
                    const prevItem = values.materials[i].rates[index - 1];
                    if (!item) return true;
                    if (item < 0) return false;
                    return prevItem ? prevItem.price > item : true;
                  },
                ),
              }),
            ),
            rebates: Yup.array().of(
              Yup.object().shape({
                quantity: Yup.number().test(
                  'quantity-amount-test',
                  t('rfqs.validation.threshold'),
                  function quantityTest(item) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    const i = this.path.split(/\[(.*?)\]/)[1];
                    const index = findIndex(values.materials[i].rebates, value =>
                      value ? Number(value.quantity) === item : false,
                    );
                    const prevItem = values.materials[i].rebates[index - 1];
                    if (!item) return true;
                    if (item < 0 || (item > 0 && !isInteger(item))) return false;
                    return prevItem ? prevItem.quantity < item : true;
                  },
                ),
                percentage: Yup.number().test(
                  'percentage-test',
                  t('rfqs.validation.percentage'),
                  function percentage(item) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    const i = this.path.split(/\[(.*?)\]/)[1];
                    const index = findIndex(values.materials[i].rebates, value =>
                      value ? Number(value.percentage) === item : false,
                    );
                    const prevItem = values.materials[i].rebates[index - 1];
                    if (!item) return true;
                    return prevItem ? prevItem.percentage < item : true;
                  },
                ),
              }),
            ),
          }),
        ),
      });
    }),
  });

  const organizationList = organizations
    .map(org => {
      return {
        label: org.childOrganization.name,
        organizationId: org.childOrganizationId,
        address: org.childOrganization.wallets[0]?.address,
        role: org.type,
        groupby: orgTypes[org.type].label,
        value: org._id,
      };
    })
    .sort((a, b) => {
      if (a.groupby.toUpperCase() < b.groupby.toUpperCase()) {
        return -1;
      }
      if (a.groupby.toUpperCase() > b.groupby.toUpperCase()) {
        return 1;
      }
      return 0;
    });

  // eslint-disable-next-line consistent-return
  const quantityHelperText = (showQuantityHelper, indexRate, quantity, quantityInfo) => {
    if (indexRate > 0) {
      if (
        showQuantityHelper &&
        quantity !== '' &&
        Number(quantityInfo[Number(indexRate) - 1].quantity) >= quantity
      ) {
        return t('rfqs.validation.quantityMustBeGreater');
      }
    }
    if (quantity !== '' && Number(quantity) < 0) {
      return formik.values &&
        formik.values.contractType &&
        formik.values.contractType.value === 'tierPricing'
        ? t('rfqs.validation.negativeQuantity')
        : t('rfqs.validation.negativeThreshold');
    }
    if (quantity !== '' && !isInteger(Number(quantity)) && Number(quantity) > 0) {
      return formik.values &&
        formik.values.contractType &&
        formik.values.contractType.value === 'tierPricing'
        ? t('rfqs.validation.quantity')
        : t('rfqs.validation.threshold');
    }
    if (quantity !== undefined && indexRate > 0 && quantity !== 0 && quantity > 0) {
      const getQuantityItems = [];
      quantityInfo.forEach(item => getQuantityItems.push(item.quantity));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < quantityInfo.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < quantityInfo.length; j++) {
          if (Number(quantityInfo[i].quantity) !== Number(quantityInfo[j].quantity)) {
            return Number(quantityInfo[i].quantity) > Number(quantityInfo[j].quantity)
              ? t('rfqs.validation.quantityMustBeGreater')
              : '';
          }
        }
      }
    }
  };

  const quantityErrors = (
    showQuantityHelper,
    indexRate,
    rate,
    quantityInfo,
    // eslint-disable-next-line consistent-return
  ) => {
    if (
      showQuantityHelper &&
      indexRate > 0 &&
      rate.quantity !== '' &&
      Number(quantityInfo[Number(indexRate) - 1].quantity) >= rate.quantity
    ) {
      return true;
    }
    if (rate.quantity !== '' && Number(rate.quantity) < 0) {
      return true;
    }
    if (rate.quantity !== '' && !isInteger(Number(rate.quantity)) && Number(rate.quantity) > 0) {
      return true;
    }
    if (rate.quantity !== undefined && indexRate > 0 && rate.quantity !== 0 && rate.quantity > 0) {
      const getQuantityItems = [];
      quantityInfo.forEach(item => getQuantityItems.push(item.quantity));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < quantityInfo.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < quantityInfo.length; j++) {
          if (Number(quantityInfo[i].quantity) !== Number(quantityInfo[j].quantity)) {
            return Number(quantityInfo[i].quantity) > Number(quantityInfo[j].quantity);
          }
        }
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const percentageHelperText = (showPercentageHelper, indexRate, percentage, percentageInfo) => {
    if (indexRate > 0) {
      if (
        showPercentageHelper &&
        percentage !== '' &&
        Number(percentageInfo[Number(indexRate) - 1].percentage) >= percentage
      ) {
        return t('rfqs.validation.percentageMustBeGreater');
      }
    }
    if (percentage !== '' && Number(percentage) < 0) {
      return t('rfqs.validation.negativePercentage');
    }
    if (percentage !== undefined && indexRate > 0 && percentage !== 0 && percentage > 0) {
      const getpercentageItems = [];
      percentageInfo.forEach(item => getpercentageItems.push(item.percentage));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < percentageInfo.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < percentageInfo.length; j++) {
          if (Number(percentageInfo[i].percentage) !== Number(percentageInfo[j].percentage)) {
            return Number(percentageInfo[i].percentage) > Number(percentageInfo[j].percentage)
              ? t('rfqs.validation.percentageMustBeGreater')
              : '';
          }
        }
      }
    }
  };

  const percentageErrors = (
    showPercentageHelper,
    indexRate,
    rate,
    percentageInfo,
    // eslint-disable-next-line consistent-return
  ) => {
    if (
      showPercentageHelper &&
      indexRate > 0 &&
      rate.percentage !== '' &&
      Number(percentageInfo[Number(indexRate) - 1].percentage) >= rate.percentage
    ) {
      return true;
    }
    if (rate.percentage !== '' && Number(rate.percentage) < 0) {
      return true;
    }
    if (
      rate.percentage !== undefined &&
      indexRate > 0 &&
      rate.percentage !== 0 &&
      rate.percentage > 0
    ) {
      const getPercentageItems = [];
      percentageInfo.forEach(item => getPercentageItems.push(item.percentage));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < percentageInfo.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < percentageInfo.length; j++) {
          if (Number(percentageInfo[i].percentage) !== Number(percentageInfo[j].percentage)) {
            return Number(percentageInfo[i].percentage) > Number(percentageInfo[j].percentage);
          }
        }
      }
    }
  };

  const isSubmitButtonDisable = () => {
    if (!formik.values.recipient) {
      return true;
    }
    let result = false;
    formik.values.materials.forEach(material => {
      if (!material.material || !material.materialDescription) {
        result = true;
      } else if (
        formik.values &&
        formik.values.contractType &&
        formik.values.contractType.value === 'tierPricing' &&
        material.rates.length > 0
      ) {
        result = material.rates.some(i => !i.quantity || !i.price);
      } else if (
        formik.values &&
        formik.values.contractType &&
        formik.values.contractType.value === 'rebatePricing' &&
        material.rebates.length > 0
      ) {
        const requiredRebates = material.rebates.some(i => !i.quantity || !i.percentage);
        const requireduom = !(material.uom && material.unitPrice);
        if (requiredRebates || requireduom || material.unitPrice < 0) {
          result = true;
        }
      }
    });

    return result;
  };

  const [showQuantityHelper, setShowQuantityHelper] = useState(false);
  const [showPercentageHelper, setShowPercentageHelper] = useState(false);

  return isUserNotAuthorized() ? (
    isUserNotAuthorized()
  ) : (
    <>
      {getPartnersLoading || getOrganizationsLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {loading ? (
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          ) : (
            ''
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            px={2}
            className={classes.navContainer}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => closeModal()}
              className={classes.navButton}
              startIcon={<ArrowBackIosIcon />}
            >
              {t('common.contracts')}
            </Button>
          </Box>
          <Divider classes={{ root: classes.navButtonDivider }} />
          <Card className={classes.root}>
            <Typography variant="h4" className={classes.title}>
              {t('common.createContract')}
            </Typography>
            {!partnersData.length && !getPartnersLoading && (
              <Typography className={classes.noPartnerWarning}>
                {t('common.validation.partnerExist')}
              </Typography>
            )}
            <Divider classes={{ root: classes.navButtonDivider }} />
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} className={classes.form}>
                {/* Show form errors */}
                <FormErrors form={formik} />
                <Typography className={classes.subtitle} variant="subtitle1">
                  {t('common.businessPartner')}
                </Typography>
                <Grid item xs={3}>
                  <Field
                    component={AutocompleteDropDown}
                    placeholder={t('common.businessPartner')}
                    onChange={formik.handleChange}
                    name="recipient"
                    options={partnersData}
                    disabled={!partnersData.length}
                    testid="createContract-dropdown-partners"
                  />
                </Grid>
                <Typography className={classes.subtitle} variant="subtitle1">
                  {t('common.contractType')}
                </Typography>
                <Grid item xs={3}>
                  <Field
                    component={AutocompleteDropDown}
                    onChange={formik.handleChange}
                    name="contractType"
                    options={contractTypes}
                    selected={
                      !formik.values.contractType ? contractTypes[0] : formik.values.contractType
                    }
                    disabled={!contractTypes.length}
                    testid="createContract-dropdown-contractType"
                  />
                </Grid>
                {rebateContractDiscount &&
                  authorizedPartiesAccess &&
                  formik.values &&
                  formik.values.contractType &&
                  formik.values.contractType.value === 'rebatePricing' && (
                    <>
                      <Typography className={classes.subtitle} variant="subtitle1">
                        {t('common.authorizedParties')}
                      </Typography>
                      <Grid item xs={3}>
                        <Field
                          component={AutocompleteDropDown}
                          isMultipleSelection
                          onChange={formik.handleChange}
                          name="authorizedParties"
                          placeholder={t('common.authorizedParties')}
                          disabled={!organizationList.length}
                          options={organizationList}
                          size="small"
                          withCheckboxes
                          disableCloseOnSelect
                          startingValue={formik.values.authorizedParties}
                          limitTags={2}
                          selected={
                            !formik.values.authorizedParties
                              ? organizationList
                              : formik.values.authorizedParties
                          }
                          testid="createContract-dropdown-authorizedParties"
                        />
                      </Grid>
                    </>
                  )}
                <Typography className={classes.subtitle} variant="subtitle1">
                  {t('contracts.validityPeriod')}
                </Typography>
                <Grid container className={classes.fields}>
                  <Grid item xs={3}>
                    <Field
                      component={DatePickerField}
                      label={t('contracts.contractStartDate')}
                      onChange={formik.handleChange}
                      name="startDate"
                      testid="createContract-datepicker-startdate"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={DatePickerField}
                      label={t('contracts.contractEndDate')}
                      onChange={formik.handleChange}
                      name="endDate"
                      testid="createContract-datepicker-enddate"
                    />
                  </Grid>
                </Grid>
                <Divider classes={{ root: classes.navButtonDivider }} />
                <Grid>
                  <Typography className={classes.subtitle} variant="subtitle1">
                    {t('common.materials')}
                  </Typography>
                  <FieldArray
                    name="materials"
                    render={arrayHelpers => (
                      <Grid item xs={6}>
                        {formik.values &&
                          formik.values.materials &&
                          formik.values.materials.map((material, index) => (
                            <React.Fragment key={material.key}>
                              <Grid container justify="space-between">
                                <Grid item container className={classes.materialHeader}>
                                  <Typography className={classes.subtitle} variant="subtitle1">
                                    {`${t('common.material')} ${index + 1}`}
                                  </Typography>
                                  <IconButton
                                    type="button"
                                    onClick={() => showMaterialHandler(index)}
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      setShowMaterial(
                                        showMaterial.filter((item, i) => i !== index),
                                      );
                                    }}
                                    className={classes.removeButton}
                                    disabled={formik.values.materials.length === 1}
                                  >
                                    <Delete />
                                    {t('contracts.removeMaterial')}
                                  </IconButton>
                                </Grid>
                              </Grid>
                              {showMaterial[index] ? (
                                <>
                                  <Grid container>
                                    <Field
                                      component={TextField}
                                      label={t('common.material')}
                                      onChange={formik.handleChange}
                                      field={{
                                        name: `materials[${index}].material`,
                                        onChange: e => {
                                          formik.setFieldTouched(
                                            `materials[${index}].material`,
                                            true,
                                          );
                                          formik.handleChange(e);
                                        },
                                        value: material.material || '',
                                      }}
                                      name="material"
                                      data-testid={`createContract-input-material-${index}`}
                                    />
                                    <Field
                                      component={TextField}
                                      label={t('common.materialDescription')}
                                      onChange={formik.handleChange}
                                      field={{
                                        name: `materials[${index}].materialDescription`,
                                        onChange: e => {
                                          formik.setFieldTouched(
                                            `materials[${index}].materialDescription`,
                                            true,
                                          );
                                          formik.handleChange(e);
                                        },
                                        value: material.materialDescription || '',
                                      }}
                                      name="materialDescription"
                                      data-testid={`createContract-input-material-description-${index}`}
                                    />
                                  </Grid>
                                  {formik.values.contractType &&
                                    formik.values.contractType.value === 'tierPricing' && (
                                      <Typography className={classes.pricing} variant="subtitle1">
                                        {`${t('common.material')} ${index + 1} tier prices`}
                                      </Typography>
                                    )}
                                  {formik.values &&
                                    formik.values.contractType &&
                                    formik.values.contractType.value === 'rebatePricing' && (
                                      <Grid container>
                                        <Field
                                          component={TextField}
                                          label={t('common.unitOfMeasurement')}
                                          onChange={formik.handleChange}
                                          field={{
                                            name: `materials[${index}].uom`,
                                            onChange: e => {
                                              formik.setFieldTouched(
                                                `materials[${index}].uom`,
                                                true,
                                              );
                                              formik.handleChange(e);
                                            },
                                            value: material.uom || '',
                                          }}
                                          name="uom"
                                          data-testid={`createContract-input-uom-${index}`}
                                        />
                                        <Field
                                          component={NumberFormat}
                                          label={t('common.fixedUnitPrice')}
                                          onValueChange={vals => {
                                            formik.setFieldValue(
                                              `materials[${index}].unitPrice`,
                                              Number(vals.value),
                                            );
                                          }}
                                          field={{
                                            name: `materials[${index}].unitPrice`,
                                            onChange: e => {
                                              formik.setFieldTouched(
                                                `materials[${index}].unitPrice`,
                                                true,
                                              );
                                              formik.handleChange(e);
                                            },
                                            value: material.unitPrice || '',
                                          }}
                                          name="unitPrice"
                                          value={material.unitPrice || ''}
                                          customInput={TextField}
                                          thousandSeparator
                                          isNumericString
                                          prefix={getCurrencySymbol(euroCurrencySymbol)}
                                          error={
                                            !!(material.unitPrice !== '' && material.unitPrice < 0)
                                          }
                                          helperText={
                                            material.unitPrice !== '' && material.unitPrice < 0
                                              ? t('rfqs.validation.negativePrice')
                                              : ''
                                          }
                                          data-testid={`createContract-input-unitPrice-${index}`}
                                        />
                                      </Grid>
                                    )}
                                  {formik.values &&
                                  formik.values.contractType &&
                                  formik.values.contractType.value === 'tierPricing' ? (
                                    <Grid container>
                                      <Grid item xs={5}>
                                        <Typography className={classes.pricing} variant="subtitle1">
                                          {t('proposals.quantity')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography className={classes.pricing} variant="subtitle1">
                                          {t('common.pricing')}
                                        </Typography>
                                      </Grid>
                                      <FieldArray
                                        name={`materials[${index}].rates`}
                                        render={arrayHelpersRates => (
                                          <>
                                            {formik.values &&
                                              material.rates &&
                                              material.rates.map((rate, indexRate) => (
                                                <React.Fragment key={rate.key}>
                                                  <Grid item xs={5}>
                                                    <Field
                                                      className={classes.field}
                                                      component={NumberFormat}
                                                      label={t('proposals.qtyAndabove')}
                                                      field={{
                                                        name: `materials[${index}].rates.${indexRate}.quantity`,
                                                        onChange: e => {
                                                          formik.setFieldTouched(
                                                            `materials[${index}].rates.${indexRate}.quantity`,
                                                            true,
                                                          );
                                                          formik.handleChange(e);
                                                        },
                                                        value: rate.quantity || '',
                                                      }}
                                                      onValueChange={vals => {
                                                        formik.setFieldValue(
                                                          `materials[${index}].rates.${indexRate}.quantity`,
                                                          Number(vals.value),
                                                        );
                                                        if (indexRate > 0) {
                                                          if (
                                                            Number(
                                                              material.rates[Number(indexRate) - 1]
                                                                .quantity,
                                                            ) >= Number(vals.value)
                                                          ) {
                                                            setShowQuantityHelper(true);
                                                          } else {
                                                            if (
                                                              Number(
                                                                material.rates[
                                                                  Number(indexRate) - 1
                                                                ].quantity,
                                                              ) +
                                                                1 ===
                                                              Number(vals.value)
                                                            ) {
                                                              setShowQuantityHelper(true);
                                                            }
                                                            setShowQuantityHelper(false);
                                                          }
                                                        }
                                                      }}
                                                      value={rate.quantity || ''}
                                                      customInput={TextField}
                                                      suffix={
                                                        indexRate < material.rates.length - 1
                                                          ? ''
                                                          : '+'
                                                      }
                                                      error={quantityErrors(
                                                        showQuantityHelper,
                                                        indexRate,
                                                        rate,
                                                        material.rates,
                                                      )}
                                                      variant="outlined"
                                                      helperText={quantityHelperText(
                                                        showQuantityHelper,
                                                        indexRate,
                                                        rate.quantity,
                                                        material.rates,
                                                      )}
                                                      data-testid={`proposalQuantity-input-${indexRate}-material-${index}`}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                    <Field
                                                      className={classes.field}
                                                      component={NumberFormat}
                                                      label={t('common.price')}
                                                      field={{
                                                        name: `materials[${index}].rates.${indexRate}.price`,
                                                        onChange: e => {
                                                          formik.setFieldTouched(
                                                            `materials[${index}].rates.${indexRate}.price`,
                                                            true,
                                                          );
                                                          formik.handleChange(e);
                                                        },
                                                        value: rate.price || '',
                                                      }}
                                                      onValueChange={vals => {
                                                        formik.setFieldValue(
                                                          `materials[${index}].rates.${indexRate}.price`,
                                                          Number(vals.value),
                                                        );
                                                      }}
                                                      value={rate.price || ''}
                                                      customInput={TextField}
                                                      thousandSeparator
                                                      isNumericString
                                                      prefix={getCurrencySymbol(euroCurrencySymbol)}
                                                      error={
                                                        !!(rate.price !== '' && rate.price < 0)
                                                      }
                                                      helperText={
                                                        rate.price !== '' && rate.price < 0
                                                          ? t('rfqs.validation.negativePrice')
                                                          : ''
                                                      }
                                                      data-testid={`proposalPrice-input-${indexRate}-material-${index}`}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <IconButton
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpersRates.remove(indexRate)
                                                      }
                                                      className={classes.removeTierButton}
                                                      disabled={
                                                        indexRate === material.rates.length - 1 &&
                                                        material.rates.length < 2
                                                      }
                                                    >
                                                      <Delete />
                                                      {t('contracts.removeTier')}
                                                    </IconButton>
                                                  </Grid>

                                                  {indexRate === material.rates.length - 1 ? (
                                                    <Grid item xs={3}>
                                                      <IconButton
                                                        type="button"
                                                        onClick={() => {
                                                          if (
                                                            material.rates[indexRate].quantity &&
                                                            material.rates[indexRate].price
                                                          ) {
                                                            arrayHelpersRates.push({
                                                              quantity: 0,
                                                              price: 0,
                                                              key: uniqid(),
                                                            });
                                                          }
                                                        }}
                                                        disabled={
                                                          !material.rates[indexRate].quantity ||
                                                          !material.rates[indexRate].price
                                                        }
                                                        data-testid="tierAdd-button"
                                                        className={classes.addTierButton}
                                                      >
                                                        <Add />
                                                        {t('contracts.addTier')}
                                                      </IconButton>
                                                    </Grid>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            <Grid item className={classes.addMaterialBox}>
                                              <Divider
                                                classes={{ root: classes.navButtonDivider }}
                                              />
                                              {index === formik.values.materials.length - 1 ? (
                                                <Button
                                                  type="button"
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      material: '',
                                                      materialDescription: '',
                                                      rates: [
                                                        { key: uniqid(), quantity: 0, price: 0 },
                                                      ],
                                                      key: uniqid(),
                                                    });
                                                    setShowMaterial([...showMaterial, true]);
                                                  }}
                                                  className={classes.addMaterialButton}
                                                  variant="contained"
                                                  color="primary"
                                                  // disabled={isAddMaterialDisable(material)}
                                                  data-testid="materialAdd-button"
                                                >
                                                  <Add />
                                                  {t('contracts.addMaterial')}
                                                </Button>
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid container>
                                      <Grid item xs={5}>
                                        <Typography className={classes.pricing} variant="subtitle1">
                                          {t('proposals.rebateThreshold')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography className={classes.pricing} variant="subtitle1">
                                          {t('proposals.rebateTPercentage')}
                                        </Typography>
                                      </Grid>
                                      <FieldArray
                                        name={`materials[${index}].rebates`}
                                        render={arrayHelpersRebates => (
                                          <>
                                            {formik.values &&
                                              material.rebates &&
                                              material.rebates.map((rebate, indexRate) => (
                                                <React.Fragment key={rebate.key}>
                                                  <Grid item xs={5}>
                                                    <Field
                                                      className={classes.field}
                                                      component={NumberFormat}
                                                      label={t('common.threshold')}
                                                      field={{
                                                        name: `materials[${index}].rebates.${indexRate}.quantity`,
                                                        onChange: e => {
                                                          formik.setFieldTouched(
                                                            `materials[${index}].rebates.${indexRate}.quantity`,
                                                            true,
                                                          );
                                                          formik.handleChange(e);
                                                        },
                                                        value: rebate.quantity || '',
                                                      }}
                                                      onValueChange={vals => {
                                                        formik.setFieldValue(
                                                          `materials[${index}].rebates.${indexRate}.quantity`,
                                                          Number(vals.value),
                                                        );
                                                        if (indexRate > 0) {
                                                          if (
                                                            Number(
                                                              material.rebates[
                                                                Number(indexRate) - 1
                                                              ].quantity,
                                                            ) >= Number(vals.value)
                                                          ) {
                                                            setShowQuantityHelper(true);
                                                          } else {
                                                            if (
                                                              Number(
                                                                material.rebates[
                                                                  Number(indexRate) - 1
                                                                ].quantity,
                                                              ) +
                                                                1 ===
                                                              Number(vals.value)
                                                            ) {
                                                              setShowQuantityHelper(true);
                                                            }
                                                            setShowQuantityHelper(false);
                                                          }
                                                        }
                                                      }}
                                                      value={rebate.quantity || ''}
                                                      customInput={TextField}
                                                      error={quantityErrors(
                                                        showQuantityHelper,
                                                        indexRate,
                                                        rebate,
                                                        material.rebates,
                                                      )}
                                                      variant="outlined"
                                                      helperText={quantityHelperText(
                                                        showQuantityHelper,
                                                        indexRate,
                                                        rebate.quantity,
                                                        material.rebates,
                                                      )}
                                                      data-testid={`thresholdQuantity-input-${indexRate}-material-${index}`}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                    <Field
                                                      className={classes.field}
                                                      component={NumberFormat}
                                                      label={t('common.percentage')}
                                                      field={{
                                                        name: `materials[${index}].rebates.${indexRate}.percentage`,
                                                        onChange: e => {
                                                          formik.setFieldTouched(
                                                            `materials[${index}].rebates.${indexRate}.percentage`,
                                                            true,
                                                          );
                                                          formik.handleChange(e);
                                                        },
                                                        value: rebate.percentage || '',
                                                      }}
                                                      onValueChangee={vals => {
                                                        formik.setFieldValue(
                                                          `materials[${index}].rebates.${indexRate}.percentage`,
                                                          Number(vals.value),
                                                        );
                                                      }}
                                                      onValueChange={vals => {
                                                        formik.setFieldValue(
                                                          `materials[${index}].rebates.${indexRate}.percentage`,
                                                          Number(vals.value),
                                                        );
                                                        if (indexRate > 0) {
                                                          if (
                                                            Number(
                                                              material.rebates[
                                                                Number(indexRate) - 1
                                                              ].percentage,
                                                            ) >= Number(vals.value)
                                                          ) {
                                                            setShowPercentageHelper(true);
                                                          } else {
                                                            if (
                                                              Number(
                                                                material.rebates[
                                                                  Number(indexRate) - 1
                                                                ].percentage,
                                                              ) +
                                                                1 ===
                                                              Number(vals.value)
                                                            ) {
                                                              setShowPercentageHelper(true);
                                                            }
                                                            setShowPercentageHelper(false);
                                                          }
                                                        }
                                                      }}
                                                      value={rebate.percentage || ''}
                                                      customInput={TextField}
                                                      thousandSeparator
                                                      isNumericString
                                                      suffix="%"
                                                      error={percentageErrors(
                                                        showPercentageHelper,
                                                        indexRate,
                                                        rebate,
                                                        material.rebates,
                                                      )}
                                                      helperText={percentageHelperText(
                                                        showPercentageHelper,
                                                        indexRate,
                                                        rebate.percentage,
                                                        material.rebates,
                                                      )}
                                                      data-testid={`proposalPercentage-input-${indexRate}-material-${index}`}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <IconButton
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpersRebates.remove(indexRate)
                                                      }
                                                      className={classes.removeTierButton}
                                                      disabled={
                                                        indexRate === material.rebates.length - 1 &&
                                                        material.rebates.length < 2
                                                      }
                                                    >
                                                      <Delete />
                                                      {t('contracts.removeThreshold')}
                                                    </IconButton>
                                                  </Grid>
                                                  {indexRate === material.rebates.length - 1 ? (
                                                    <Grid item xs={3}>
                                                      <IconButton
                                                        type="button"
                                                        onClick={() => {
                                                          if (
                                                            material.rebates[indexRate].quantity &&
                                                            material.rebates[indexRate].percentage
                                                          ) {
                                                            arrayHelpersRebates.push({
                                                              quantity: 0,
                                                              percentage: 0,
                                                              key: uniqid(),
                                                            });
                                                          }
                                                        }}
                                                        disabled={
                                                          !material.rebates[indexRate].quantity ||
                                                          !material.rebates[indexRate].percentage
                                                        }
                                                        data-testid="thresholdAdd-button"
                                                        className={classes.addTierButton}
                                                      >
                                                        <Add />
                                                        {t('contracts.addThreshold')}
                                                      </IconButton>
                                                    </Grid>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            <Grid item className={classes.addMaterialBox}>
                                              <Divider
                                                classes={{ root: classes.navButtonDivider }}
                                              />
                                              {index === formik.values.materials.length - 1 ? (
                                                <Button
                                                  type="button"
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      material: '',
                                                      materialDescription: '',
                                                      rebates: [
                                                        {
                                                          key: uniqid(),
                                                          quantity: 0,
                                                          percentage: 0,
                                                        },
                                                      ],
                                                      key: uniqid(),
                                                    });
                                                    setShowMaterial([...showMaterial, true]);
                                                  }}
                                                  className={classes.addMaterialButton}
                                                  variant="contained"
                                                  color="primary"
                                                  // disabled={isAddMaterialDisable(material)}
                                                  data-testid="materialAdd-button"
                                                >
                                                  <Add />
                                                  {t('contracts.addMaterial')}
                                                </Button>
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typography variant="subtitle1">
                                    {material.material || ''}
                                  </Typography>
                                  <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                    <Divider classes={{ root: classes.navButtonDivider }} />
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </Grid>
                    )}
                  />
                </Grid>

                <Grid container className={classes.signingBox}>
                  <Button onClick={() => closeModal()} variant="outlined" color="primary">
                    {t('common.cancel')}
                  </Button>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitButtonDisable()}
                    data-testid="createContractWithoutRFQ-button"
                  >
                    {t('common.signAndSubmitContract')}
                  </Button>
                </Grid>
              </Form>
            </FormikProvider>
          </Card>
        </>
      )}
    </>
  );
};

export default CreateContractWithoutRFQ;
