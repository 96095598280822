import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { publicRootPaths } from '../../../utils';

const ERROR_CODES = {
  GRAPHQL_OPERATION_NAME_NOT_FOUND: 'graphql_operation_name_not_found',
};

const uri = `${window.config.apiUrl}/graphql`;
const wsUri = `${window.config.apiUrl}/graphql`;
const isPublicPath = publicRootPaths.find(path => {
  return window.location.pathname.startsWith(path);
});

let httpLink;
let wsLink;

if (isPublicPath) {
  httpLink = new HttpLink({
    uri: window.config.nodeEnv === 'development' ? `http://${uri}` : `https://${uri}`,
  });

  /**
   *  Declaring outside to avoid creating a new websocket every time
   *  this component re-renders.
   */
  wsLink = new WebSocketLink({
    uri: window.config.nodeEnv === 'development' ? `ws://${wsUri}` : `wss://${wsUri}`,
    options: {
      reconnect: true,
      connectionParams: () => ({}),
      /**
       * Fix to address known issue causing gql console warnings
       * https://github.com/apollographql/subscriptions-transport-ws/issues/377
       */
      minTimeout: 10000,
    },
  });
}

const PublicApolloClientProvider = ({ children }) => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      for (let i = 0; i < graphQLErrors.length; i += 1) {
        const { message } = graphQLErrors[i];
        if (message.includes(ERROR_CODES.GRAPHQL_OPERATION_NAME_NOT_FOUND)) {
          // eslint-disable-next-line no-console
          console.error('Graphql operation name not found in server');
        }
      }
    }
  });

  const terminatingLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const link = ApolloLink.from([errorLink, terminatingLink]);

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

PublicApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicApolloClientProvider;
