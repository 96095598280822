import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grade from '@material-ui/icons/Grade';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils';

const useStyles = makeStyles(theme => ({
  tierContainer: {
    height: '61px',
    borderLeft: '1px solid black',
    display: 'inline-block',
  },
  price: {
    position: 'relative',
    top: '45%',
    paddingLeft: theme.spacing(1),
    fontSize: '12px',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
  },
  unit: {
    paddingLeft: theme.spacing(1),
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  unitContainer: {
    zIndex: 20,
    background: theme.palette.primary.lightest,
    position: 'relative',
    width: '20%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  starIcon: {
    height: '11.08px',
    width: '11.67px',
    color: '#FFE600',
    stroke: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

const TierMarker = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { tierBounds, pricesByTier, tier, index, total, rebateContract } = props;

  return (
    <div
      className={classes.tierContainer}
      style={{ width: `${Math.round(100 / tierBounds.length)}%` }}
    >
      {index === 0 && (
        <div className={classes.unitContainer}>
          <Typography variant="body1" className={classes.unit}>
            {t('purchaseOrderProgressBar.base')}
          </Typography>
        </div>
      )}
      {index === tierBounds.length - 1 && (
        <div className={classes.unitContainer}>
          <Typography
            variant="body1"
            className={clsx(classes.unit, {
              [classes.boldText]: total > tier,
            })}
          >
            {`${tier}+`}
          </Typography>
        </div>
      )}
      {index > 0 && index < tierBounds.length - 1 && (
        <div className={classes.unitContainer}>
          <Typography
            variant="body1"
            className={clsx(classes.unit, {
              [classes.boldText]: total > tier,
            })}
          >
            {tier}
          </Typography>
        </div>
      )}
      <Typography
        variant="body1"
        className={clsx(classes.price, {
          [classes.boldText]: index !== 0 && total > tier,
        })}
      >
        {!rebateContract &&
          formatCurrency(pricesByTier[index] || pricesByTier[pricesByTier.length - 1])}
        {rebateContract && `${pricesByTier[index]}%`}
        {total > tier && total < tierBounds[index + 1] && <Grade className={classes.starIcon} />}
        {total > tierBounds[tierBounds.length - 1] && <Grade className={classes.starIcon} />}
      </Typography>
    </div>
  );
};

TierMarker.propTypes = {
  tierBounds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pricesByTier: PropTypes.arrayOf(PropTypes.number).isRequired,
  tier: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rebateContract: PropTypes.bool,
};

TierMarker.defaultProps = {
  rebateContract: false,
};

export default TierMarker;
