import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import TableHeadCell from '../Shared/TableHeadCell';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: 40,
    boxShadow: '0px 0px 0px 0px',
    marginTop: theme.spacing(3),
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      borderLeft: '0.5rem solid rgba(247, 218, 11, 11)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tiertable: {
    width: '35%',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.lightest,
      width: '10%',
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tierComponentSubTable: {
    marginTop: 0,
    width: '50%',
  },
  assoicatedButton: {
    width: 120,
    fontSize: '10px !important',
  },
  assoicatedButtonFont: {
    fontSize: '10px !important',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  acceptedChip: {
    background: 'white',
    color: theme.palette.info.main,
    fontWeight: 300,
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: 'inherit',
  },
  partiallyChip: {
    color: '#155CB4',
    borderColor: '#155CB4',
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const InvoicePricingTable = ({ materials }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = [
    {
      name: 'materialName',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'sku',
      label: t('invoices.sku'),
      options: {
        display: false,
      },
    },
    {
      name: 'shipment',
      label: t('invoices.shipmentId'),
      options: {
        display: false,
      },
    },
    {
      name: 'description',
      label: t('invoices.description'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'quantity',
      label: t('common.quantity'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netValue',
      label: t('pos.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'tierValue',
      label: t('pos.tierValue'),
      options: {
        display: false,
      },
    },
    {
      name: 'contractType',
      label: t('common.contractType'),
      options: {
        display: false,
      },
    },
  ];

  const tierColumns = [
    {
      name: 'quantity',
      label: t('common.quantity'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netPrice',
      label: t('pos.netPrice'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'qtyInTier',
      label: t('invoices.qualityInTier'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const rebateColumns = [
    {
      name: 'rebateThreshold',
      label: t('contracts.rebateThreshold'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'rebatePercentage',
      label: t('contracts.rebatePercentage'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const Row = props => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            {row[6].length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            {row[0]}
          </TableCell>
          <TableCell>{row[3]}</TableCell>
          <TableCell>{row[4]}</TableCell>
          <TableCell>{row[5]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  title={
                    row[7] === 'tierPricing'
                      ? t('contracts.tierPrices')
                      : t('contracts.rebateThresholds')
                  }
                  className={classes.tiertable}
                  columns={row[7] === 'tierPricing' ? tierColumns : rebateColumns}
                  data={row[6]}
                  options={{
                    pagination: false,
                    filter: false,
                    search: false,
                    searchOpen: false,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  Row.propTypes = {
    row: PropTypes.oneOfType([PropTypes.array]),
  };

  Row.defaultProps = {
    row: [],
  };

  return (
    <>
      <Table
        title={t('common.materials')}
        className={classes.table}
        columns={columns}
        data={materials}
        options={{
          filter: false,
          search: false,
          searchOpen: false,
          elevation: 0,
          customRowRender: data => data && data.length > 0 && <Row key={uniqid()} row={data} />,
          textLabels: {
            body: {
              noMatch: t('common.noMatchingRecords'),
            },
          },
        }}
      />
    </>
  );
};

InvoicePricingTable.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      isSender: PropTypes.bool,
    }),
  ),
};

InvoicePricingTable.defaultProps = {
  materials: [],
};

export default InvoicePricingTable;
