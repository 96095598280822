import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@eyblockchain/ey-ui/core/DialogTitle';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { ADD_INSTANCE } from '../../graphql/Traceability/instance';
import { useNotification } from '../../contexts/Shared/notification';
import { TRACEABILITY_DISPATCHER_ACTIONS, CONSTANTS } from '../../constants';

import track from '../../mixpanel';

const useStyles = makeStyles(() => ({
  loadingBanner: {
    marginTop: '2px',
  },
}));

const CreateInstanceForm = ({ isDialogOpen, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const history = useHistory();
  const { setLastCompletedIteration, instanceDispatcher } = useConfigurationWizardContext();
  const { activeWallet } = useBecOrganizationContext();
  const [isFormProcessing, setFormProcessing] = useState(false);

  const [addInstance] = useMutation(ADD_INSTANCE);

  const formik = useFormik({
    initialValues: {
      valueChainName: '',
    },
    validationSchema: Yup.object({
      valueChainName: Yup.string().required(t('traceability.errorName')),
    }),
    onSubmit: async values => {
      try {
        const { valueChainName } = values;
        setFormProcessing(true);
        const res = await addInstance({
          variables: {
            input: {
              name: valueChainName,
              date: moment().unix(),
            },
          },
        });
        if (res.data.createInstance) {
          instanceDispatcher({
            type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE,
            payload: res.data.createInstance,
          });
          track(CONSTANTS.MIXPANEL_EVENTS.TRACEABILITY.NEW_VALUE_CHAIN, {
            blockchainNetwork: res.data.createInstance?.blockchainNetwork,
            valueChainId: res.data.createInstance?._id,
          });
        }
        setLastCompletedIteration(0);
        history.push(`/traceability/items/${res.data.createInstance._id}`);
        handleNotification(t('traceability.createInstanceSuccess'), 'success');
      } catch (err) {
        handleNotification(err.message, 'error');
        setFormProcessing(false);
        track(CONSTANTS.MIXPANEL_ERRORS.TRACEABILITY.NEW_VALUE_CHAIN, {
          blockchainNetwork: activeWallet?.network,
          error: err.message,
        });
      }
    },
  });

  const handleDialogClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
      <DialogTitle onClose={handleDialogClose}>{t('traceability.addNewInstance')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Field
              component={TextField}
              label={t('traceability.instanceName')}
              name="valueChainName"
              className={classes.addrField}
              helperText={formik.errors?.valueChainName || ''}
            />
            {isFormProcessing && (
              <Typography variant="body2" className={classes.loadingBanner}>
                {t('traceability.creatingInstance')}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDialogClose}
              disabled={isFormProcessing}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isFormProcessing || !!formik.errors.valueChainName}
            >
              {t('traceability.submitInstance')}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

CreateInstanceForm.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

CreateInstanceForm.defaultProps = {};

export default CreateInstanceForm;
