import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import { Button, Card } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/styles';
import Cookies from 'js-cookie';
import { find, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../constants';
import { useAuth } from '../../contexts/Shared/auth';
import { useNotification } from '../../contexts/Shared/notification';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import { useTokenContext } from '../../contexts/Tokenization/token';
import {
  BURN_ERC20,
  BURN_ERC721,
  ERC_721_META_DATA_UPDATED,
  GET_ERC1155_TOKEN_LIST,
  GET_ERC721_TOKEN_LIST,
  GET_ERC721_TOKEN_LIST_FROM_OTHER_ORGANIZATION,
  MINT_ERC1155,
  MINT_ERC20,
  MINT_ERC20_BCOS,
  MINT_ERC20_BSN,
  MINT_ERC721,
  MINT_ERC721_BCOS,
  MINT_ERC721_BSN,
  TRANSACTION_STATUS,
  TRANSFER_ERC20,
  TRANSFER_ERC20_BCOS,
  TRANSFER_ERC20_BSN,
  TRANSFER_ERC721,
  TRANSFER_ERC721_BCOS,
  TRANSFER_ERC721_BSN,
  ZKP_TRANSFER_ERC721,
} from '../../graphql/Tokenization/token';
import useQueryTokenBalance from '../../hooks/useQueryTokenBalance';
import useUserInfo from '../../hooks/useUserInfo';
import track from '../../mixpanel';
import { getPrettifiedEthAddr } from '../../utils';
import BlockchainInfo from '../Shared/BlockchainInfo';
import ConfirmationPopup from '../Shared/ConfirmationPopup';
import BurnErc1155Token from './BurnErc1155Token';
import BurnErc20Token from './BurnErc20Token';
import BurnErc721Token from './BurnErc721Token';
import ERC20BalanceCounter from './ERC20BalanceCounter';
import MintNonFungibleToken from './MintNonFungibleToken';
import MintToken from './MintToken';
import MintTokenIcon from './MintTokenIcon';
import TokenList from './TokenList';
import TokenPanel from './TokenPanel';
import TransferErc1155Token from './TransferErc1155Token';
import TransferErc20Token from './TransferErc20Token';
import TransferErc721Token from './TransferErc721Token';

const useStyles = makeStyles(theme => ({
  ContractPanelRoot: {
    width: '60%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  balanceArea: {
    display: 'flex',
    alignItems: 'center',
  },
  contractHeader: {
    fontWeight: '400',
  },
  symbolArea: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  addrArea: {
    marginLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  buttonArea: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  collabButton: {
    marginLeft: theme.spacing(1),
  },
  labelHeader: {
    fontWeight: '100',
    marginRight: theme.spacing(2),
  },
  closeIcon: {
    marginRight: -8,
  },
  errorBanner: {
    marginTop: theme.spacing(1),
  },
  buttonRightSpace: {
    marginRight: '1rem',
  },
}));

const ContractPanel = ({
  selectedIndex,
  handlePanelClose,
  selectedToken,
  setSelectedToken,
  getSelectedTokens,
  isMultipleSelection,
  preSelectTokens,
  purchaseOrder,
  hideTokens,
  materialId,
  bulkMintingHandler,
  bulkTransferHandler,
  updateBatchMetadataHandler,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mintErc20FormOpen, setMintErc20FormOpen] = useState(false);
  const [mintErc721FormOpen, setMintErc721FormOpen] = useState(false);
  const [mintErc1155FormOpen, setMintErc1155FormOpen] = useState(false);
  const [burnErc721FormOpen, setBurnErc721FormOpen] = useState(false);
  const [burnErc20FormOpen, setBurnErc20FormOpen] = useState(false);
  const [burnErc1155FormOpen, setBurnErc1155FormOpen] = useState(false);
  const [transferFormOpen, setTransferFormOpen] = useState(false);
  const [transferErc721FormOpen, setTransferErc721FormOpen] = useState(false);
  const [removeBurnedTokenOpen, setRemoveBurnedTokenOpen] = useState(false);
  const [transferErc1155FormOpen, setTransferErc1155FormOpen] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [burnTokenPopupData, setburnTokenPopupData] = useState({
    title: '',
    confirmation: '',
    warning: '',
    actionParameters: null,
    executeAction: () => {},
  });
  const [removeBurnedTokenPopupData, setRemoveBurnedTokenPopupData] = useState({
    title: '',
    confirmation: '',
    warning: '',
    actionParameters: null,
    executeAction: () => {},
  });
  const [TokensSelectedForProcess, setTokensSelectedForProcess] = useState([]);
  const { company } = useAuth();
  const { zkpAccess } = useSubscriptionContext();
  const {
    permissionsFlags: {
      isUserAuthToMintERC20,
      isUserAuthToBurnERC20,
      isUserAuthToTransferERC20,
      isUserAuthToMintERC721,
      isUserAuthToBurnERC721,
      isUserAuthToTransferERC721,
      isUserAuthToMintERC1155,
      isUserAuthToBurnERC1155,
      isUserAuthToTransferERC1155,
    },
  } = useUserInfo();

  const isPrivate = token => {
    return !!token.commitment;
  };

  const {
    tokenContracts,
    incrementPendingTransactions,
    pendingZkpSingleTransferCount,
    setPendingZkpSingleTransferCount,
    incrementPendingTransfers,
    setSelectedContract,
    getErc721BatchTokenList,
    tokenBatches,
    getSingleTokenContract,
    singleTokenContractLoading,
    errorGetSingleTokenContract,
    transferErc1155TokenEth,
    isTransferErc1155Processing,
    setChainTransactionProgressing,
    burnErc1155Tokens,
    isErc1155BurnLoading,
    erc20BalanceDetails,
  } = useTokenContext();

  const { activeWallet } = useBecOrganizationContext();
  const {
    handleNotification,
    showFaucetModal,
    setShowFaucetModal,
    setUpdateLivePanel,
    finishedTnxs,
    setFinishedTnxs,
  } = useNotification();

  const {
    getTokenBalance,
    balance: erc20Balance,
    loading: loadingERC20Balance,
  } = useQueryTokenBalance(CONSTANTS.SMARTCONTRACT_TYPES.ERC20);

  const selectedContract = find(tokenContracts, token => token?.contract?._id === selectedIndex);
  const isERC1155 =
    selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;
  const isERC721 = selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721;

  useEffect(() => {
    setSelectedContract(selectedContract);
  }, [selectedContract]);

  useEffect(() => {
    if (selectedContract?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721) {
      getErc721BatchTokenList({
        variables: {
          smartContractId: selectedContract?.contract?._id,
        },
      });
    } else if (selectedContract?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC20) {
      getTokenBalance({
        variables: {
          contractAddress: selectedContract.contract.contractAddress,
        },
      });
    }
  }, [selectedContract]);

  const currOrgId = atob(Cookies.get(`${window.config.cookiePrefix}active_organization`));
  const isContractOwner = selectedContract?.contract?.organizationId === currOrgId;

  let handleMint = () => {};
  let handleBurn = () => {};
  let isUserAuthToMint = false;
  let isUserAuthToBurn = false;
  let isUserAuthToTransfer = false;

  switch (selectedContract?.contract?.tokenType) {
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC20:
      isUserAuthToBurn = isUserAuthToBurnERC20;
      isUserAuthToMint = isUserAuthToMintERC20;
      handleMint = () => setMintErc20FormOpen(true);
      handleBurn = () => setBurnErc20FormOpen(true);
      break;
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
      // TODO: update permissions for ERC1155
      isUserAuthToMint = isUserAuthToMintERC1155;
      isUserAuthToTransfer = isUserAuthToTransferERC1155;
      isUserAuthToBurn = isUserAuthToBurnERC1155;
      handleMint = () => setMintErc1155FormOpen(true);
      handleBurn = () => setBurnErc1155FormOpen(true);
      break;
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
      isUserAuthToBurn = isUserAuthToBurnERC721;
      isUserAuthToMint = isUserAuthToMintERC721;
      isUserAuthToTransfer = isUserAuthToTransferERC721;
      handleMint = () => setMintErc721FormOpen(true);
      handleBurn = () => setBurnErc721FormOpen(true);
      break;
    default:
      break;
  }

  const closeModalForBurnERC721 = () => {
    setBurnErc721FormOpen(false);
    setburnTokenPopupData({
      title: '',
      confirmation: '',
      warning: '',
      actionParameters: null,
      executeAction: () => {},
    });
  };

  const closeModalForBurnERC20 = () => {
    setBurnErc20FormOpen(false);
    setburnTokenPopupData({
      title: '',
      confirmation: '',
      warning: '',
      actionParameters: null,
      executeAction: () => {},
    });
  };

  const closeModalForBurnERC1155 = () => {
    setBurnErc1155FormOpen(false);
    setburnTokenPopupData({
      title: '',
      confirmation: '',
      warning: '',
      actionParameters: null,
      executeAction: () => {},
    });
  };

  const [getErc1155tokenList, { loading: tokenListLoadingForErc1155 }] = useLazyQuery(
    GET_ERC1155_TOKEN_LIST,
    {
      onCompleted: data => {
        const tokenListWithValidAmount = data?.getTokensInERC1155Contract?.filter(
          token =>
            token?.latestTransaction?.status === CONSTANTS.CONTRACT_STATUSES.PENDING ||
            token?.owners1155?.length > 0,
        );
        setTokenList(tokenListWithValidAmount);
        if (data?.getTokensInERC1155Contract?.length > 0) {
          if (selectedToken) {
            const newTokenSelection = find(data?.getTokensInERC1155Contract, {
              _id: selectedToken._id,
            });
            setSelectedToken(newTokenSelection);
          }
        }
      },
      onError: error => {
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [getErc721TokenList, { loading: tokenListLoadingForErc721 }] = useLazyQuery(
    GET_ERC721_TOKEN_LIST,
    {
      onCompleted: data => {
        setTokenList(data?.erc721TokenList);
        if (data?.erc721TokenList?.length > 0) {
          if (selectedToken) {
            const newTokenSelection = find(data?.erc721TokenList, { _id: selectedToken._id });
            setSelectedToken(newTokenSelection);
          }
        }
      },
      onError: error => {
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [
    getErc721TokenListFromOtherOrganization,
    { loading: tokenFromOtherOrganizationLoading },
  ] = useLazyQuery(GET_ERC721_TOKEN_LIST_FROM_OTHER_ORGANIZATION, {
    onCompleted: data => {
      setTokenList(data?.erc721TokenListFromOtherOrganization);
      if (data?.erc721TokenListFromOtherOrganization?.length > 0) {
        if (selectedToken) {
          const newTokenSelection = find(data?.erc721TokenListFromOtherOrganization, {
            _id: selectedToken._id,
          });
          setSelectedToken(newTokenSelection);
        }
      }
    },
    onError: error => {
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const transactionStatus = data.subscriptionData?.data?.transactionStatus;

      const tnxHash = transactionStatus?.transactionHash;
      if (tnxHash) {
        const newFinishedTransactions = [...finishedTnxs];
        newFinishedTransactions.push(tnxHash);
        setFinishedTnxs(newFinishedTransactions);
      }

      if (transactionStatus?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
        if (transactionStatus?.contractAddress === selectedContract.contract.contractAddress) {
          switch (selectedContract?.contract?.tokenType) {
            case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
              getErc721TokenList({
                variables: {
                  smartContractId: selectedContract?.contract?._id,
                },
              });
              break;
            case CONSTANTS.SMARTCONTRACT_TYPES.ERC20:
              getTokenBalance({
                variables: {
                  contractAddress: selectedContract.contract.contractAddress,
                },
              });
              break;
            case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
              setChainTransactionProgressing(false);
              getErc1155tokenList({
                variables: {
                  contractAddress: selectedContract?.contract?.contractAddress,
                },
              });
              break;
            default:
              break;
          }
        }

        handleNotification(
          t([`tokens.${transactionStatus.transactionType}Success`, 'tokens.transactionSuccess']),
          'success',
        );
        track('Token transaction success', {
          tokenType: transactionStatus.smartcontractType || 'Unknown token type',
          transactionType: transactionStatus.transactionType || 'Unknown transaction type',
        });
      } else if (transactionStatus?.status === CONSTANTS.CONTRACT_STATUSES.FAILED) {
        handleNotification(
          t([`tokens.${transactionStatus.transactionType}Error`, 'tokens.transactionFailure']),
          'error',
        );
        track('Token transaction failure', {
          tokenType: transactionStatus.smartcontractType || 'Unknown token type',
          transactionType: transactionStatus.transactionType || 'Unknown transaction type',
        });
      } else if (transactionStatus?.status === CONSTANTS.CONTRACT_STATUSES.PENDING) {
        if (transactionStatus?.transactionType === CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA) {
          const newState = [...tokenList];

          const index = findIndex(
            newState,
            token => token.tokenId.toString() === transactionStatus?.tokenId,
          );

          if (newState[index]) {
            const currentMetadataTx = newState[index].latestMetadataTransaction;
            newState[index].latestMetadataTransaction = {
              ...currentMetadataTx,
              status: transactionStatus?.status,
              transactionHash: transactionStatus?.transactionHash,
              transactionType: CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA,
            };

            setTokenList(newState);

            if (selectedToken?._id === newState[index]?._id) {
              const newTokenSelection = find(newState, { _id: selectedToken._id });
              setSelectedToken({ ...newTokenSelection });
            }
          }
        }
      }
    },
  });

  useSubscription(ERC_721_META_DATA_UPDATED, {
    onSubscriptionData: data => {
      const newState = [...tokenList];
      const responseToken = data.subscriptionData?.data?.erc721metadataUpdated;

      const index = findIndex(newState, {
        smartContract: { contractAddress: responseToken?.smartContract?.contractAddress || '' },
        tokenId: responseToken?.tokenId,
      });

      if (newState[index]) {
        newState[index].metadata = responseToken.metadata;
        newState[index].metadataHash = responseToken.metadataHash;
        newState[index].metadataUrls = responseToken?.metadataUrls;

        const currentMetadataTx = newState[index].latestMetadataTransaction;
        if (currentMetadataTx.status === CONSTANTS.CONTRACT_STATUSES.PENDING) {
          newState[index].latestMetadataTransaction = {
            ...currentMetadataTx,
            status: CONSTANTS.CONTRACT_STATUSES.COMPLETED,
          };
        }

        setTokenList(newState);

        if (selectedToken?._id === newState[index]?._id) {
          const newTokenSelection = find(newState, { _id: selectedToken._id });
          setSelectedToken({ ...newTokenSelection });
        }
      }
    },
  });

  const [mintErc1155TokenEth, { loading: isErc1155MintLoading }] = useMutation(MINT_ERC1155, {
    onError: error => {
      throw error;
    },
  });

  const [mintErc721TokenEth, { loading: isErc721MintLoading }] = useMutation(MINT_ERC721, {
    onCompleted: data => {
      setRemoveBurnedTokenOpen(false);
      setRemoveBurnedTokenPopupData({
        title: '',
        confirmation: '',
        warning: '',
        actionParameters: {},
        executeAction: () => {},
      });
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.MINT_ERC721, {
        blockchainNetwork: activeWallet?.network,
        tokenId: data.mintErc721?.tokenId,
        contractId: selectedContract?.contract?._id,
        status: data.mintErc721?.status,
      });
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'token_already_minted_and_burned') {
        setMintErc721FormOpen(false);
        setRemoveBurnedTokenOpen(true);
      } else {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.MINT_ERC721, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
        setRemoveBurnedTokenOpen(false);
        throw error;
      }
    },
  });

  const [mintErc721TokenBcos, { loading: isErc721BcosMintLoading }] = useMutation(MINT_ERC721_BCOS);
  const [mintErc721TokenBsn, { loading: isErc721BsnMintLoading }] = useMutation(MINT_ERC721_BSN);

  const [burnErc721TokenEth, { loading: isErc721BurnLoading }] = useMutation(BURN_ERC721, {
    onCompleted: data => {
      setSelectedToken(null);
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.BURN_ERC721, {
        blockchainNetwork: activeWallet?.network,
        tokenId: data.burnErc721?.tokenId,
        contractId: selectedContract?.contract?._id,
        status: data.burnErc721?.status,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.BURN_ERC721, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      throw error;
    },
  });

  const [burnErc20TokenEth, { loading: isErc20BurnLoading }] = useMutation(BURN_ERC20, {
    onCompleted: data => {
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.BURN_ERC20, {
        blockchainNetwork: activeWallet?.network,
        tokenId: data.burnERC20Token?.tokenId,
        contractId: selectedContract?.contract?._id,
        status: data.burnERC20Token?.status,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.BURN_ERC20, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      throw error;
    },
  });

  const [mintErc20TokenEth, { loading: isErc20MintLoading }] = useMutation(MINT_ERC20, {
    onCompleted: data => {
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.MINT_ERC20, {
        blockchainNetwork: activeWallet?.network,
        contractId: selectedContract?.contract?._id,
        amount: data?.mintERC20Token?.amount || 0,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.MINT_ERC20, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      throw error;
    },
  });
  const [mintErc20TokenBcos, { loading: isErc20MintBcosLoading }] = useMutation(MINT_ERC20_BCOS);
  const [mintErc20TokenBsn, { loading: isErc20MintBsnLoading }] = useMutation(MINT_ERC20_BSN);

  const [transferErc20TokenEth, { loading: isErc20TransferLoading }] = useMutation(TRANSFER_ERC20, {
    onCompleted: data => {
      incrementPendingTransactions(data.transferErc20.contractAddress);
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.TRANSFER_ERC20, {
        blockchainNetwork: activeWallet?.network,
        contract: data?.transferErc20?.contractAddress,
        contractId: selectedContract?.contract?._id,
      });
      setUpdateLivePanel(true);
      setTransferFormOpen(false);
      setSelectedToken(null);
      handleNotification(t('tokens.transactionSubmitted'), 'success');
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.TRANSFER_ERC20, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });
  const [transferErc20TokenBsn, { loading: isErc20TransferBSNLoading }] = useMutation(
    TRANSFER_ERC20_BSN,
    {
      onCompleted: data => {
        setTransferFormOpen(false);
        setSelectedToken(null);
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC20 BSN Token Transfer transaction Completed', {
          contract: data?.transferErc20Bsn?.contractAddress,
        });
        getSingleTokenContract({
          variables: {
            contractAddress: selectedContract?.contract?.contractAddress,
          },
        });
      },
      onError: error => {
        track('ERC20 BSN Token Transfer transaction Error');
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
          'error',
        );
      },
    },
  );
  const [transferErc20TokenBcos, { loading: isErc20TransferBcosLoading }] = useMutation(
    TRANSFER_ERC20_BCOS,
    {
      onCompleted: data => {
        setTransferFormOpen(false);
        setSelectedToken(null);
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC20 BCOS Token Transfer transaction Completed', {
          contract: data?.mintERC20TokenBcos?.contractAddress,
        });
        getSingleTokenContract({
          variables: {
            contractAddress: selectedContract?.contract?.contractAddress,
          },
        });
      },
      onError: error => {
        track('ERC20 BCOS Token Transfer transaction Error');
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
          'error',
        );
      },
    },
  );
  const handleErc20TokenTransfer = variables => {
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
        transferErc20TokenBsn(variables);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        transferErc20TokenBcos(variables);
        break;
      }
      default: {
        transferErc20TokenEth(variables);
        break;
      }
    }
  };

  const [zkpTransferErc721TokenEth, { loading: isZkpErc721TransferLoading }] = useMutation(
    ZKP_TRANSFER_ERC721,
    {
      onCompleted: data => {
        // incrementPendingTransfers(data?.zkpSingleTransferErc721?.transactionHash);
        setTransferErc721FormOpen(false);
        const transferedToken = data.zkpSingleTransferErc721;
        if (pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress]) {
          pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress][
            transferedToken.tokenId
          ] = true;
        } else {
          pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress] = {
            [transferedToken.tokenId]: true,
          };
        }
        setPendingZkpSingleTransferCount({
          ...pendingZkpSingleTransferCount,
        });
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        track('ERC721 Token Transfer transaction Submitted', {
          contract: data?.zkpSingleTransferErc721?.smartContract?.contractAddress,
          transactionHash: data?.zkpSingleTransferErc721?.latestTransaction?.transactionHash,
        });
        getErc721TokenList({ variables: { smartContractId: selectedContract?.contract?._id } });
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        if (errorTransfer?.graphQLErrors[0]?.errorCode === 'insufficient_funds')
          setShowFaucetModal(true);
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );
  const [transferErc721TokenEth, { loading: isErc721TransferLoading }] = useMutation(
    TRANSFER_ERC721,
    {
      onCompleted: data => {
        incrementPendingTransfers(data?.transferErc721?.transactionHash);
        setTransferErc721FormOpen(false);
        setSelectedToken(null);
        setUpdateLivePanel(true);
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.TRANSFER_ERC721, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedContract?.contract?._id,
          contract: data?.transferErc721?.contractAddress,
          transactionHash: data?.transferErc721?.transactionHash,
        });
        getErc721TokenList({
          variables: {
            smartContractId: selectedContract?.contract?._id,
            purchaseOrderId: purchaseOrder?.purchaseOrderId,
          },
        });
      },
      onError: errorTransfer => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.TRANSFER_ERC721, {
          blockchainNetwork: activeWallet?.network,
          error: errorTransfer.message,
        });
        if (errorTransfer?.graphQLErrors[0]?.errorCode === 'insufficient_funds')
          setShowFaucetModal(true);
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [transferErc721TokenBcos, { loading: isErc721BcosTransferLoading }] = useMutation(
    TRANSFER_ERC721_BCOS,
    {
      onCompleted: data => {
        setTransferErc721FormOpen(false);
        setSelectedToken(null);
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC721 Token Transfer transaction completed', {
          contract: data?.transferErc721Bcos?.contractAddress,
          transactionHash: data?.transferErc721Bcos?.transactionHash,
        });
        getSingleTokenContract({
          variables: {
            contractAddress: selectedContract?.contract?.contractAddress,
          },
        });
        getErc721TokenList({
          variables: {
            smartContractId: selectedContract?.contract?._id,
            purchaseOrderId: purchaseOrder?.purchaseOrderId,
          },
        });
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [transferErc721TokenBsn, { loading: isErc721BsnTransferLoading }] = useMutation(
    TRANSFER_ERC721_BSN,
    {
      onCompleted: data => {
        setTransferErc721FormOpen(false);
        setSelectedToken(null);
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC721 Token Transfer transaction completed', {
          contract: data?.transferErc721Bsn?.contractAddress,
          transactionHash: data?.transferErc721Bsn?.transactionHash,
        });
        getErc721TokenList({
          variables: {
            smartContractId: selectedContract?.contract?._id,
            purchaseOrderId: purchaseOrder?.purchaseOrderId,
          },
        });
        getSingleTokenContract({
          variables: {
            contractAddress: selectedContract?.contract?.contractAddress,
          },
        });
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const handleErc721TokenTransfer = variables => {
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        transferErc721TokenBcos(variables);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
        transferErc721TokenBsn(variables);
        break;
      }
      default: {
        if (zkpAccess && isPrivate(selectedToken)) zkpTransferErc721TokenEth(variables);
        else transferErc721TokenEth(variables);
        break;
      }
    }
  };

  const handleErc1155TokenTransfer = async variables => {
    const res = await transferErc1155TokenEth(variables);
    if (res?.data?.transferERC1155Token?.transactionHash) {
      setSelectedToken(null);
      getErc1155tokenList({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
    }
    return res;
  };

  const handleBulkMint = () => {
    if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721) {
      bulkMintingHandler(true);
    }
  };

  const handleBulkTransfer = () => {
    if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155) {
      bulkTransferHandler(prevState => ({
        ...prevState,
        display: true,
        tokens: TokensSelectedForProcess,
        processType: CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER,
      }));
    }
  };
  const handleBulkBurn = () => {
    if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155) {
      bulkTransferHandler(prevState => ({
        ...prevState,
        display: true,
        tokens: TokensSelectedForProcess,
        processType: CONSTANTS.TRANSACTION_TYPES.BATCH_BURN,
      }));
    }
  };

  const handleErc20Mint = async variables => {
    try {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const {
            data: { mintERC20TokenBcos: tokenResponse },
          } = await mintErc20TokenBcos(variables);
          track('ERC20 BCOS Token Mint transaction Completed', {
            contract: tokenResponse?.contractAddress,
          });
          handleNotification(t('tokens.mintSuccess'), 'success');
          break;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
          const {
            data: { mintERC20TokenBsn: tokenResponse },
          } = await mintErc20TokenBsn(variables);
          track('ERC20 BSN Token Mint transaction Completed', {
            contract: tokenResponse?.contractAddress,
          });
          handleNotification(t('tokens.mintSuccess'), 'success');
          break;
        }
        default: {
          const {
            data: { mintERC20Token: tokenResponse },
          } = await mintErc20TokenEth(variables);
          incrementPendingTransactions(tokenResponse?.contractAddress);
          track('ERC20 Token Mint transaction Submitted', {
            contract: tokenResponse?.contractAddress,
          });
          setUpdateLivePanel(true);
          handleNotification(t('tokens.mintSubmitted'), 'success');
          break;
        }
      }
      setMintErc20FormOpen(false);
      getSingleTokenContract({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.mintError']),
        'error',
      );
    }
  };

  const handleErc721Mint = async variables => {
    try {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const {
            data: { mintErc721: tokenResponse },
          } = await mintErc721TokenBcos(variables);
          track('ERC721 BCOS Token Mint transaction Completed', {
            contract: tokenResponse?.contractAddress,
          });
          handleNotification(t('tokens.mintSuccess'), 'success');
          break;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
          const {
            data: { mintErc721: tokenResponse },
          } = await mintErc721TokenBsn(variables);
          track('ERC721 BSN Token Mint transaction Completed', {
            contract: tokenResponse?.contractAddress,
          });
          handleNotification(t('tokens.mintSuccess'), 'success');
          break;
        }
        default: {
          const {
            data: { mintErc721: tokenResponse },
          } = await mintErc721TokenEth(variables);
          incrementPendingTransactions(tokenResponse?.contractAddress);
          setUpdateLivePanel(true);
          handleNotification(t('tokens.mintSubmitted'), 'success');
          break;
        }
      }
      setMintErc721FormOpen(false);
      getSingleTokenContract({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
      getErc721TokenList({
        variables: {
          smartContractId: selectedContract?.contract?._id,
          purchaseOrderId: purchaseOrder?.purchaseOrderId,
        },
      });
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.mintError']),
        'error',
      );
    }
  };

  const handleErc1155Mint = async variables => {
    let tokenResponse;
    try {
      const {
        data: { mintERC1155Token },
      } = await mintErc1155TokenEth(variables);
      tokenResponse = { ...mintERC1155Token };
      incrementPendingTransactions(tokenResponse?.contractAddress);
      setUpdateLivePanel(true);
      handleNotification(t('tokens.mintSubmitted'), 'success');

      setMintErc1155FormOpen(false);
      getSingleTokenContract({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
      getErc1155tokenList({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.mintError']),
        'error',
      );
    }
    return tokenResponse;
  };

  const handleErc721Burn = async variables => {
    try {
      switch (activeWallet.blockchainType) {
        default: {
          const {
            data: { burnErc721: tokenResponse },
          } = await burnErc721TokenEth(variables);
          incrementPendingTransactions(tokenResponse?.contractAddress);
          setUpdateLivePanel(true);
          handleNotification(t('tokens.burnSubmitted'), 'success');
          break;
        }
      }
      getSingleTokenContract({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
      closeModalForBurnERC721();
      getErc721TokenList({
        variables: {
          smartContractId: selectedContract?.contract?._id,
          purchaseOrderId: purchaseOrder?.purchaseOrderId,
        },
      });
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.burnError']),
        'error',
      );
    }
  };

  const handleErc20Burn = async variables => {
    try {
      const {
        data: { burnERC20Token },
      } = await burnErc20TokenEth(variables);
      incrementPendingTransactions(burnERC20Token?.contractAddress);
      setUpdateLivePanel(true);
      handleNotification(t('tokens.burnSubmitted'), 'success');
      closeModalForBurnERC20();
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.burnError']),
        'error',
      );
    }
  };

  const handleErc1155Burn = async variables => {
    try {
      const {
        data: { burnERC1155Token: tokenResponse },
      } = await burnErc1155Tokens(variables);
      setSelectedToken(null);
      incrementPendingTransactions(tokenResponse?.contractAddress);
      setUpdateLivePanel(true);
      handleNotification(t('tokens.burnSubmitted'), 'success');
      closeModalForBurnERC1155();
    } catch (err) {
      if (err?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${err?.graphQLErrors[0]?.errorCode}`, 'tokens.burnError']),
        'error',
      );
    }
  };

  useEffect(() => {
    setTokenList([]);
    if (
      selectedContract &&
      selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721
    ) {
      if (hideTokens) {
        getErc721TokenListFromOtherOrganization({
          variables: {
            smartContractId: selectedContract?.contract?._id,
            purchaseOrderId: purchaseOrder?.purchaseOrderId,
            buyerOrganizationId: purchaseOrder?.buyer?.id,
            supplierOrganizationId: purchaseOrder?.supplier?.id,
            organizationId: selectedContract?.contract?.organizationId,
            sku: purchaseOrder?.materials[materialId].materialName,
          },
        });
      } else {
        getErc721TokenList({
          variables: {
            smartContractId: selectedContract?.contract?._id,
            purchaseOrderId: purchaseOrder?.purchaseOrderId,
            buyerOrganizationId: purchaseOrder?.buyer?.id,
            supplierOrganizationId: purchaseOrder?.supplier?.id,
            sku: purchaseOrder?.materials[materialId].materialName,
          },
        });
      }
    } else if (
      selectedContract &&
      selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155
    ) {
      getErc1155tokenList({
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      });
    }
  }, [selectedContract]);

  if (errorGetSingleTokenContract) {
    return (
      <Card variant="outlined" className={classes.ContractPanelRoot}>
        <Typography variant="body1" className={classes.errorBanner}>
          {`${t('tokens.errorGetSingleTokenContract')}.`}
        </Typography>
      </Card>
    );
  }

  const closeModalForRemovingBurnedToken = () => {
    setRemoveBurnedTokenOpen(false);
    setRemoveBurnedTokenPopupData({
      title: '',
      confirmation: '',
      warning: '',
      actionParameters: null,
      executeAction: () => {},
    });
  };

  const generatePopupData = () => {
    const title = t('tokens.burnTokens');
    const confirmation = (
      <>
        {t('tokens.burnConfirmation')} <strong>{selectedToken?.tokenId}</strong>{' '}
        {t('common.rbac.questionMark')}
      </>
    );
    const content =
      activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
        <BlockchainInfo
          validateForm={() => {}}
          values={{ tokenId: selectedToken?.tokenId }}
          contractAddress={selectedContract?.contract?.contractAddress}
          method={CONSTANTS.TRANSACTION_TYPES.BURN}
          methodArgs={[selectedToken?.tokenId]}
          byPassValidation
        />
      ) : (
        ''
      );
    const warning = '';
    const executeAction =
      selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721
        ? handleErc721Burn
        : handleErc20Burn;
    const actionParameters = {
      variables: {
        input: {
          tokenId: selectedToken?.tokenId,
          contractAddress: selectedContract?.contract?.contractAddress,
        },
      },
    };

    setburnTokenPopupData({
      title,
      confirmation,
      warning,
      content,
      actionParameters,
      executeAction,
    });
  };

  if (selectedToken) {
    return (
      <>
        <TokenPanel
          selectedToken={selectedToken}
          tokenList={tokenList}
          selectedContract={selectedContract}
          handleTokenClose={() => {
            setSelectedToken(null);
          }}
          handlePanelClose={() => {
            setSelectedToken(null);
            handlePanelClose();
          }}
          handleTransferClick={() =>
            isERC1155 ? setTransferErc1155FormOpen(true) : setTransferErc721FormOpen(true)
          }
          handleBurnClick={() => {
            if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721) {
              setBurnErc721FormOpen(true);
              generatePopupData();
            }
            if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155) {
              setBurnErc1155FormOpen(true);
            }
          }}
          isBuyer={hideTokens}
        />
        {!showFaucetModal && isUserAuthToTransfer && (
          <>
            {isERC721 && (
              <TransferErc721Token
                open={transferErc721FormOpen}
                closeModal={() => setTransferErc721FormOpen(false)}
                onSubmit={handleErc721TokenTransfer}
                contractAddress={selectedContract?.contract?.contractAddress}
                currentUserEthAddress={company.companyEthereumAddress || ''}
                tokenId={selectedToken?.tokenId}
                loading={
                  isErc721TransferLoading ||
                  isErc721BcosTransferLoading ||
                  isErc721BsnTransferLoading ||
                  isZkpErc721TransferLoading
                }
                isPrivate={!!selectedToken?.commitment}
              />
            )}

            {isERC1155 && (
              <TransferErc1155Token
                open={transferErc1155FormOpen}
                closeModal={() => setTransferErc1155FormOpen(false)}
                onSubmit={handleErc1155TokenTransfer}
                contractAddress={selectedContract?.contract?.contractAddress}
                currentUserEthAddress={company.companyEthereumAddress || ''}
                tokenId={selectedToken?.tokenId}
                balance={selectedToken?.owners1155 && selectedToken?.owners1155[0]?.balance}
                loading={isTransferErc1155Processing}
                isPrivate={!!selectedToken?.commitment}
              />
            )}
          </>
        )}

        {!showFaucetModal && isUserAuthToBurnERC721 && (
          <>
            <BurnErc1155Token
              open={burnErc1155FormOpen}
              selectedToken={selectedToken}
              closeModal={() => setBurnErc1155FormOpen(false)}
              onSubmit={handleErc1155Burn}
              loading={isErc1155BurnLoading}
              contractAddress={selectedContract?.contract?.contractAddress}
              metadataConfigs={selectedContract?.contract?.metadataConfig}
              isContractOwner={isContractOwner}
            />
          </>
        )}

        {/* need to implement the check for burn permission */}
        {!showFaucetModal && burnErc721FormOpen && (
          <ConfirmationPopup popupData={burnTokenPopupData} closePopup={closeModalForBurnERC721} />
        )}
        {!showFaucetModal && burnErc20FormOpen && (
          <ConfirmationPopup popupData={burnTokenPopupData} closePopup={closeModalForBurnERC20} />
        )}
      </>
    );
  }

  return (
    <>
      <Card variant="outlined" className={classes.ContractPanelRoot}>
        <div className={classes.headerArea}>
          <div className={classes.balanceArea}>
            <Typography variant="h5" className={classes.contractHeader}>
              {selectedContract?.contract?.contractName}
            </Typography>
          </div>

          <IconButton onClick={handlePanelClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.symbolArea}>
          <Typography variant="body2" className={classes.labelHeader}>
            {t('tokens.tokenContractSymbol')}:
          </Typography>
          <Typography variant="body2">{selectedContract?.tokenSymbol}</Typography>
          {selectedContract?.contract?.contractAddress && (
            <div className={classes.addrArea}>
              <Typography variant="body2" className={classes.labelHeader}>
                {t('tokens.contractAddress')}:
              </Typography>
              <Typography variant="body2">
                {getPrettifiedEthAddr(selectedContract?.contract?.contractAddress)}
              </Typography>
              <CopyContent
                content={selectedContract?.contract?.contractAddress}
                onCopyEvent={() =>
                  track(CONSTANTS.MIXPANEL_EVENTS.COPYING_CONTRACT_ADDRESS, {
                    copyContractAddress: true,
                    page: 'Dashboard',
                    network: activeWallet.connectionName,
                  })
                }
              />
            </div>
          )}
        </div>
        <div>
          <ERC20BalanceCounter
            isBalanceLoading={loadingERC20Balance}
            balanceValue={erc20Balance}
            tokenType={selectedContract?.contract?.tokenType}
          />
        </div>
        <div className={classes.buttonArea}>
          {!hideTokens && isUserAuthToMint && (
            <>
              <Button
                id="btn_mint"
                variant="outlined"
                color="primary"
                onClick={handleMint}
                startIcon={<MintTokenIcon />}
                className={classes.buttonRightSpace}
              >
                {t('tokens.mint')}
              </Button>
              {selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC721 ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBulkMint}
                  startIcon={<MintTokenIcon />}
                  id="bulkmintBtn"
                  className={classes.buttonRightSpace}
                >
                  {t('tokens.bulkMint')}
                </Button>
              ) : (
                ''
              )}
            </>
          )}
          {selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155 &&
            isUserAuthToTransfer && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBulkTransfer}
                startIcon={<CallMadeIcon />}
                className={classes.buttonRightSpace}
                disabled={TokensSelectedForProcess?.length === 0}
              >
                {t('tokens.transfer')}
              </Button>
            )}
          {/* need to implement the check for burn permission */}
          {!hideTokens && isUserAuthToBurn && (
            <>
              <Button
                id="btn_burn"
                variant="outlined"
                color="primary"
                onClick={
                  selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155
                    ? handleBulkBurn
                    : handleBurn
                }
                className={classes.buttonRightSpace}
                startIcon={<DeleteForeverIcon />}
                disabled={
                  selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155
                    ? TokensSelectedForProcess?.length === 0
                    : false
                }
              >
                {t('tokens.burn')}
              </Button>
            </>
          )}
          {selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC20 &&
            isUserAuthToTransferERC20 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setTransferFormOpen(true)}
                className={classes.collabButton}
                startIcon={<CallMadeIcon fontSize="large" />}
              >
                {t('tokens.transfer')}
              </Button>
            )}
        </div>
        <TokenList
          tokenListLoading={
            tokenListLoadingForErc721 ||
            tokenFromOtherOrganizationLoading ||
            singleTokenContractLoading ||
            tokenListLoadingForErc1155
          }
          tokenList={tokenList}
          selectedContract={selectedContract}
          setSelectedToken={setSelectedToken}
          setTokensSelectedForProcess={setTokensSelectedForProcess}
          getSelectedTokens={getSelectedTokens}
          isMultipleSelection={
            isMultipleSelection ||
            selectedContract?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
          }
          preSelectTokens={preSelectTokens}
          isBuyer={hideTokens}
          purchaseOrder={purchaseOrder}
          tokenBatchList={tokenBatches}
          updateBatchMetadataHandler={updateBatchMetadataHandler}
        />
      </Card>

      {!showFaucetModal && (
        <>
          <MintToken
            open={mintErc20FormOpen}
            closeModal={() => setMintErc20FormOpen(false)}
            onSubmit={handleErc20Mint}
            loading={isErc20MintLoading || isErc20MintBcosLoading || isErc20MintBsnLoading}
            contractAddress={selectedContract?.contract?.contractAddress}
          />
          {/* ERC721 */}
          {selectedContract?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721 && (
            <MintNonFungibleToken
              open={mintErc721FormOpen}
              closeModal={() => setMintErc721FormOpen(false)}
              onSubmit={handleErc721Mint}
              loading={isErc721MintLoading || isErc721BcosMintLoading || isErc721BsnMintLoading}
              contractAddress={selectedContract?.contract?.contractAddress}
              metadataConfigs={selectedContract?.contract?.metadataStructure?.metadataConfig}
              metadataMode={selectedContract?.contract?.metadataStructure?.behavior}
              isContractOwner={isContractOwner}
              smartContractType={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
              setRemoveBurnedTokenPopupData={setRemoveBurnedTokenPopupData}
            />
          )}
          {/* ERC1155 */}
          {selectedContract?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && (
            <MintNonFungibleToken
              open={mintErc1155FormOpen}
              closeModal={() => setMintErc1155FormOpen(false)}
              onSubmit={handleErc1155Mint}
              loading={isErc1155MintLoading}
              contractAddress={selectedContract?.contract?.contractAddress}
              metadataConfigs={selectedContract?.contract?.metadataStructure?.metadataConfig}
              metadataMode={selectedContract?.contract?.metadataStructure?.behavior}
              isContractOwner={isContractOwner}
              smartContractType={CONSTANTS.SMARTCONTRACT_TYPES.ERC1155}
              setRemoveBurnedTokenPopupData={setRemoveBurnedTokenPopupData}
            />
          )}
          <BurnErc721Token
            open={burnErc721FormOpen}
            closeModal={() => setBurnErc721FormOpen(false)}
            onSubmit={handleErc721Burn}
            tokenList={tokenList?.filter(token => token?.latestOwner === activeWallet?.address)}
            loading={
              isErc721BurnLoading || tokenListLoadingForErc721 || tokenFromOtherOrganizationLoading
            }
            contractAddress={selectedContract?.contract?.contractAddress}
            metadataConfigs={selectedContract?.contract?.metadataConfig}
            isContractOwner={isContractOwner}
          />
          <BurnErc20Token
            open={burnErc20FormOpen}
            closeModal={() => setBurnErc20FormOpen(false)}
            onSubmit={handleErc20Burn}
            loading={isErc20BurnLoading}
            contractAddress={selectedContract?.contract?.contractAddress}
            metadataConfigs={selectedContract?.contract?.metadataConfig}
            isContractOwner={isContractOwner}
          />
          <TransferErc20Token
            open={transferFormOpen}
            closeModal={() => setTransferFormOpen(false)}
            onSubmit={handleErc20TokenTransfer}
            loading={
              isErc20TransferLoading || isErc20TransferBcosLoading || isErc20TransferBSNLoading
            }
            contractAddress={selectedContract?.contract?.contractAddress}
            contractAbi={selectedContract?.abi}
            tokenBalance={erc20BalanceDetails || 0}
            currentUserEthAddress={company.companyEthereumAddress || ''}
          />
        </>
      )}
      {removeBurnedTokenOpen && (
        <ConfirmationPopup
          popupData={removeBurnedTokenPopupData}
          closePopup={closeModalForRemovingBurnedToken}
        />
      )}
    </>
  );
};

ContractPanel.propTypes = {
  selectedIndex: PropTypes.string.isRequired,
  handlePanelClose: PropTypes.func.isRequired,
  selectedToken: PropTypes.shape({
    tokenId: PropTypes.string,
    commitment: PropTypes.bool,
    _id: PropTypes.string.isRequired,
    owners1155: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
  }),
  setSelectedToken: PropTypes.func.isRequired,
  getSelectedTokens: PropTypes.func,
  isMultipleSelection: PropTypes.bool,
  preSelectTokens: PropTypes.instanceOf(Array),
  purchaseOrder: PropTypes.shape({
    purchaseOrderId: PropTypes.string,
    buyer: PropTypes.shape({
      id: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      id: PropTypes.string,
    }),
    materials: PropTypes.array,
  }),
  hideTokens: PropTypes.bool,
  materialId: PropTypes.number,
  bulkMintingHandler: PropTypes.func,
  bulkTransferHandler: PropTypes.func,
  updateBatchMetadataHandler: PropTypes.func,
};

ContractPanel.defaultProps = {
  selectedToken: {},
  getSelectedTokens: () => {},
  isMultipleSelection: false,
  preSelectTokens: [],
  purchaseOrder: null,
  hideTokens: false,
  materialId: null,
  bulkMintingHandler: () => {},
  bulkTransferHandler: () => {},
  updateBatchMetadataHandler: () => {},
};

export default ContractPanel;
