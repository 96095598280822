import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Table from '@eyblockchain/ey-ui/core/Table';
import { countBy } from 'lodash';
import { Button, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
//import GetAppIcon from '@material-ui/icons/GetApp';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NotarizeStatusChip from '../NotarizeStatusChip';
import DocumentMenu from '../DocumentMenu';
import DocDetailsModal from './DocDetailsModal';
import { CONSTANTS, DATE_PATTERNS } from '../../../constants';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import useUserInfo from '../../../hooks/useUserInfo';

import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import StatusBanner from '../StatusBanner';

const localeMap = {
  zh: 'zh-cn',
};

const useStyles = makeStyles(theme => ({
  registerButton: {
    height: '2.5rem',
  },
  searchInput: {
    height: '2.5rem',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingRight: 0,
    width: '75%',
  },
  successChip: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
  failedChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  progressChip: {
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  downloadButton: {
    borderRadius: '0',
    border: `1px solid ${theme.palette.primary.lighter}`,
    marginRight: theme.spacing(3),
  },
  table: {
    paddingLeft: '0px',
  },
  btnArea: {
    textAlign: 'right',
  },
  backdropProps: {
    opacity: '1',
  },
  filterAreaHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  filterTitle: {
    fontWeight: '600',
    fontSize: '1rem',
    width: '10%',
  },
  clearFilters: {
    fontSize: '1rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  filterArea: {
    display: 'flex',
  },
  statusFilter: {
    borderRight: `1px solid ${theme.palette.primary.lighter}`,
    padding: '8px 8px 8px 0',
    width: '50%',
  },
  timePeriodFilter: {
    borderLeft: `1px solid ${theme.palette.primary.lighter}`,
    padding: '8px 0px 8px 8px',
    width: '50%',
  },
  selectField: {
    minWidth: '100%',
  },
  datePickersArea: {
    display: 'flex',
  },
  stepDateInput: {
    margin: '8px',
    width: '45%',
  },
}));

const RegisteredDocsTable = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { notarizedDocs, setDocumentUploadVisible } = useNotarizationContext();
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);

  const today = moment(Date.now()).startOf('day');
  const [statusFilter, setStatusFilter] = useState('');
  const [timePeriodFilter, setTimePeriodFilter] = useState('');
  const [customTimePeriodFilter, setCustomTimePeriodFilter] = useState({
    startDate: today,
    endDate: today,
  });
  const { permissionsFlags: { isUserAuthToNotarizeDocuments, isUserAuthToVerifyDocuments, isUserAuthToViewTransactionDetails, isUserAuthToDownloadDocument, isUserAuthToViewTransactionStatus } } = useUserInfo();

  const statusSelectOptions = [
    { value: '', label: '' },
    {
      value: CONSTANTS.CONTRACT_STATUSES.COMPLETED,
      label: t(`notarization.statusCodes.${CONSTANTS.CONTRACT_STATUSES.COMPLETED}`),
    },
    {
      value: CONSTANTS.CONTRACT_STATUSES.PENDING,
      label: t(`notarization.statusCodes.${CONSTANTS.CONTRACT_STATUSES.PENDING}`),
    },
    {
      value: CONSTANTS.CONTRACT_STATUSES.FAILED,
      label: t(`notarization.statusCodes.${CONSTANTS.CONTRACT_STATUSES.FAILED}`),
    },
  ];

  const lastSevenDays = 'notarization.docFilters.lastSevenDays';
  const lastThirtyDays = 'notarization.docFilters.lastThirtyDays';
  const customTimePeriod = 'notarization.docFilters.customTimePeriod';

  const dateSelectOptions = [
    { value: '', label: '' },
    { value: lastSevenDays, label: t(lastSevenDays) },
    { value: lastThirtyDays, label: t(lastThirtyDays) },
    { value: customTimePeriod, label: t(customTimePeriod) },
  ];

  const resetDocSelection = () => {
    setSelectedDoc(null);
  };

  const handleMoreBtnClick = docDetails => {
    setSelectedDoc(docDetails);
  };

  const columnDefs = () => {
    const customOptions = () => {
      return {
        filter: false,
      };
    };

    const customDateSorted = () => {
      const options = customOptions();
      options.sortOrder = 'desc';

      options.filter = true;
      options.filterType = 'custom';
      options.filterList = timePeriodFilter !== '' ? [timePeriodFilter] : [];
      options.customFilterListOptions = {
        render: date => {
          if (date[0] !== customTimePeriod) {
            return `${t('notarization.docFilters.timePeriod')}: ${t(date)}`;
          }

          const startDateFormat = moment(customTimePeriodFilter.startDate).format(
            DATE_PATTERNS.DEFAULT,
          );
          const endDateFormat = moment(customTimePeriodFilter.endDate).format(
            DATE_PATTERNS.DEFAULT,
          );

          return startDateFormat === endDateFormat
            ? `${t('notarization.docFilters.timePeriod')}: ${startDateFormat}`
            : `${t('notarization.docFilters.timePeriod')}: ${startDateFormat} - ${endDateFormat}`;
        },
        update: (filterList, filterPos, index) => {
          setTimePeriodFilter('');
          const newFilterList = filterList;
          newFilterList[index] = [];
          return newFilterList;
        },
      };
      options.filterOptions = {
        logic: date => {
          const docDate = Date.parse(date);
          let weekAgo;
          let monthAgo;
          switch (timePeriodFilter) {
            case lastSevenDays:
              weekAgo = moment()
                .subtract(CONSTANTS.DOCUMENTS_FILTER.WEEKDAYS, 'days')
                .startOf('day')
                .valueOf();
              return docDate < weekAgo;
            case lastThirtyDays:
              monthAgo = moment()
                .subtract(CONSTANTS.DOCUMENTS_FILTER.THIRTYDAYS, 'days')
                .startOf('day')
                .valueOf();
              return docDate < monthAgo;
            case customTimePeriod:
              return (
                docDate < customTimePeriodFilter.startDate ||
                docDate > customTimePeriodFilter.endDate
              );
            default:
              return false;
          }
        },
      };

      options.customBodyRender = dateField =>
        moment(dateField * 1000).format(DATE_PATTERNS.DEFAULT);
      return options;
    };

    const customChipBody = () => {
      const options = customOptions();
      options.customBodyRender = statusField =>
        isUserAuthToViewTransactionStatus ? <NotarizeStatusChip statusField={statusField} /> : '';
      return options;
    };

    const customActionsBody = () => {
      const options = customOptions();

      options.filter = true;
      options.filterType = 'custom';
      options.filterList = statusFilter !== '' ? [statusFilter] : [];
      options.sort = false;
      options.customHeadLabelRender = () => "";
      options.customFilterListOptions = {
        render: status =>
          `${t('notarization.docFilters.status')}: ${t(`notarization.statusCodes.${status}`)}`,
        update: (filterList, filterPos, index) => {
          setStatusFilter('');
          const newFilterList = filterList;
          newFilterList[index] = [];
          return newFilterList;
        },
      };
      options.filterOptions = {
        logic: document => {
          if (statusFilter === CONSTANTS.CONTRACT_STATUSES.FAILED) {
            return document?.transaction?.status !== undefined;
          }
          return statusFilter !== '' ? document?.transaction?.status !== statusFilter : false;
        },
      };

      options.customBodyRender = docField => {
        return (
          <div className={classes.btnArea}>
            {/* {isUserAuthToDownloadDocument &&
              docField?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED && (
                <IconButton
                  variant="contained"
                  className={classes.downloadButton}
                  size="small"
                  disabled
                >
                  <GetAppIcon />
                </IconButton>
              )} */}
            {isUserAuthToViewTransactionDetails && (
              <DocumentMenu notarizedDoc={docField} handleMoreBtnClick={handleMoreBtnClick} />
            )}
          </div>
        );
      };

      return options;
    };

    return [
      {
        name: 'documentName',
        label: t('notarization.docTable.filename'),
        options: customOptions(),
      },
      ...(isUserAuthToViewTransactionStatus
        ? [
          {
            name: 'status',
            label: t('notarization.docTable.status'),
            options: customChipBody(),
          },
        ]
        : []),
      {
        name: 'creationDate',
        label: t('notarization.docTable.dateColumn'),
        options: customDateSorted(),
      },
      ...(isUserAuthToDownloadDocument || isUserAuthToViewTransactionDetails
        ? [
          {
            name: 'actions',
            label: t('notarization.docTable.actions'),
            options: customActionsBody(),
          },
        ]
        : []),
    ];
  };

  const tableData = notarizedDocs?.map(notarizedDoc => {
    const truncatedName =
      notarizedDoc?.filename?.length < 15
        ? notarizedDoc?.filename
        : `${notarizedDoc?.filename?.substring(0, 15)}...`;

    return {
      documentName: truncatedName,
      status: notarizedDoc.transaction?.status || CONSTANTS.CONTRACT_STATUSES.FAILED,
      creationDate: notarizedDoc.date,
      actions: notarizedDoc,
    };
  });

  const docCountPerStatus = countBy(notarizedDocs, value => value.transaction?.status);

  const handleCreate = () => {
    setDocumentUploadVisible(true);
  };

  const registerToolbar = () => {
    return isUserAuthToVerifyDocuments || isUserAuthToNotarizeDocuments ? (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.registerButton}
        onClick={handleCreate}
        data-testid="notarization-document-registerButton"
      >
        {isUserAuthToNotarizeDocuments ? t('notarization.registerNew') : t('notarization.verify')}
      </Button>
    ) : (
      <></>
    );
  };

  const clearFilters = e => {
    e.preventDefault();
    setStatusFilter('');
    setTimePeriodFilter('');
    setCustomTimePeriodFilter({ startDate: today, endDate: today });
  };

  const closePopover = () => {
    setFilterMenuAnchorEl(null);
  };

  const customDocSearch = (searchText, handleSearch) => (
    <div className={classes.searchBarWrapper}>
      <Input
        className={classes.searchInput}
        variant="filled"
        disableUnderline
        placeholder={t('notarization.searchDocuments')}
        onChange={e => handleSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton className={classes.searchButton} variant="contained">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        onClick={event => {
          setFilterMenuAnchorEl(event.currentTarget);
        }}
      >
        <FilterListIcon />
      </Button>

      <Popover
        className={classes.filterArea}
        open={Boolean(filterMenuAnchorEl)}
        onClose={closePopover}
        anchorEl={filterMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.paper}>
          <div className={classes.filterAreaHeader}>
            <Typography variant="h5" className={classes.filterTitle}>
              {t('notarization.docFilters.filters')}
            </Typography>
            <Typography className={classes.clearFilters} onClick={clearFilters}>
              {t('notarization.docFilters.clearFilters')}
            </Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={closePopover}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.filterArea}>
            <div className={classes.statusFilter}>
              <TextField
                select
                size="small"
                variant="outlined"
                name="status"
                label={t('notarization.docFilters.status')}
                hintText={t('notarization.docFilters.status')}
                onChange={event => setStatusFilter(event?.target?.value)}
                value={statusFilter}
                classes={{
                  root: classes.selectField,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {statusSelectOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className={classes.timePeriodFilter}>
              <TextField
                select
                size="small"
                name="timePeriod"
                variant="outlined"
                label={t('notarization.docFilters.timePeriod')}
                hintText={t('notarization.docFilters.transactionTimePeriod')}
                onChange={event => setTimePeriodFilter(event?.target?.value)}
                value={timePeriodFilter}
                classes={{
                  root: classes.selectField,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {dateSelectOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <MuiPickersUtilsProvider
                utils={MomentUtils}
                moment={moment}
                locale={localeMap[i18n.language]}
                className={classes.datePickersArea}
              >
                <KeyboardDatePicker
                  autoOk
                  initialFocusedDate={customTimePeriodFilter.startDate}
                  name="startDate"
                  label={t('notarization.docFilters.startDate')}
                  className={classes.stepDateInput}
                  minDate={CONSTANTS.DATEPICKERS.MINDATE}
                  format={CONSTANTS.DATEPICKERS.DATE_FORMAT}
                  disabled={timePeriodFilter !== customTimePeriod}
                  onChange={date => {
                    const endDate =
                      date <= customTimePeriodFilter.endDate
                        ? customTimePeriodFilter.endDate
                        : date;
                    setCustomTimePeriodFilter({
                      startDate: date,
                      endDate: endDate,
                    });
                  }}
                  value={customTimePeriodFilter.startDate}
                  cancelLabel={t('common.datePicker.cancelLabel')}
                  okLabel={t('common.datePicker.okLabel')}
                  clearLabel={t('common.datePicker.clearLabel')}
                />

                <KeyboardDatePicker
                  autoOk
                  initialFocusedDate={customTimePeriodFilter.endDate}
                  name="endDate"
                  label={t('notarization.docFilters.endDate')}
                  className={classes.stepDateInput}
                  minDate={CONSTANTS.DATEPICKERS.MINDATE}
                  format={CONSTANTS.DATEPICKERS.DATE_FORMAT}
                  disabled={timePeriodFilter !== customTimePeriod}
                  onChange={date => {
                    const startDate =
                      date >= customTimePeriodFilter.startDate
                        ? customTimePeriodFilter.startDate
                        : date;
                    setCustomTimePeriodFilter({
                      startDate: startDate,
                      endDate: date,
                    });
                  }}
                  value={customTimePeriodFilter.endDate}
                  cancelLabel={t('common.datePicker.cancelLabel')}
                  okLabel={t('common.datePicker.okLabel')}
                  clearLabel={t('common.datePicker.clearLabel')}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </Paper>
      </Popover>
    </div>
  );

  return (
    <>
      <StatusBanner countPerStatus={docCountPerStatus}/>
      <Table
        columns={columnDefs()}
        data={tableData}
        className={classes.table}
        options={{
          filter: false,
          selectableRows: 'none',
          selectToolbarPlacement: 'none',
          sort: true,
          search: false,
          searchOpen: true,
          pagination: true,
          elevation: 0,
          customToolbar: registerToolbar,
          customSearchRender: customDocSearch,
          searchProps: {
            variant: 'outlined',
          },
          textLabels: {
            body: {
              noMatch: t('notarization.docTable.noRecordsFound'),
            },
          },
        }}
      />
      {selectedDoc && isUserAuthToViewTransactionDetails && (
        <DocDetailsModal notarizedDoc={selectedDoc} resetDocSelection={resetDocSelection} />
      )}
    </>
  );
};

RegisteredDocsTable.propTypes = {};

RegisteredDocsTable.defaultProps = {};

export default RegisteredDocsTable;
