import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Table from '@eyblockchain/ey-ui/core/Table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import EqualizerIcon from '@material-ui/icons/EqualizerOutlined';
import _ from 'lodash';
import LoadingComponent from './loaderContainer';
import { useAuth } from '../../../contexts/Shared/auth';
import { GET_IMPORTED, GET_KEYS } from '../../../graphql/Procurement/import';
import { GET_MY_PARTNERS } from '../../../graphql/Procurement/partner';
import { getMonthLabel, months36 } from '../../../utils/dateUtils';
import ChartModal from '../ChartModal';
import useStyles from './styles';
import { displayToolTip, ellipsisText } from '../MtrOpenOrderSummary/tableUtils';
import { formatNumber } from '../MtrPurchaseOrderDetails/step';

const getSumScheduleData = input => {
  const skuGroups = _.groupBy(input, item => item.sku._id);
  return Object.keys(skuGroups)
    .map(key => {
      const group = skuGroups[key];
      return { ...group[0], locations: group };
    })
    .map(i => {
      const { locations } = i;
      const sum = locations.reduce(
        (accumulator, currentValue) => ({
          MONTH_1: accumulator.MONTH_1 + parseInt(currentValue.MONTH_1, 10),
          MONTH_2: accumulator.MONTH_2 + parseInt(currentValue.MONTH_2, 10),
          MONTH_3: accumulator.MONTH_3 + parseInt(currentValue.MONTH_3, 10),
          MONTH_4: accumulator.MONTH_4 + parseInt(currentValue.MONTH_4, 10),
          MONTH_5: accumulator.MONTH_5 + parseInt(currentValue.MONTH_5, 10),
          MONTH_6: accumulator.MONTH_6 + parseInt(currentValue.MONTH_6, 10),
        }),
        {
          MONTH_1: 0,
          MONTH_2: 0,
          MONTH_3: 0,
          MONTH_4: 0,
          MONTH_5: 0,
          MONTH_6: 0,
        },
      );
      return {
        ...i,
        ...sum,
      };
    });
};

const convertData = inputData =>
  inputData.import.map(item => {
    const data = JSON.parse(item.data);
    return { ...item, ...data };
  });

const getOnlyWarning = (input, type) => {
  return input
    .map(i => {
      const { compare } = i;
      if (!compare) {
        return { ...i, isWarning: false };
      }
      const scheduleRow = type === 'schedule' ? i : compare;
      const demandRow = type === 'schedule' ? compare : i;
      const isWarning = months36.slice(0, 6).some(m => {
        const month = `MONTH_${m}`;
        return demandRow[month] > scheduleRow[month];
      });
      return { ...i, isWarning };
    })
    .filter(({ isWarning }) => isWarning);
};

const Imported = () => {
  const type = window.location.pathname.substring(1);
  const { t } = useTranslation('mtr');
  const classes = useStyles();
  const { company } = useAuth();
  const [date, setDate] = useState();
  const [partner, setPartner] = useState('all');
  const [onlyWarning, setOnlyWarning] = useState(false);
  const [displayChart, setDisplayChart] = useState('');
  const [chartData, setChartData] = useState(null);
  // demand
  const [
    fetchDemand,
    { loading: loadingDemandData, data: demand = { import: [] } },
  ] = useLazyQuery(GET_IMPORTED, { fetchPolicy: 'no-cache' });
  // schedule
  const [
    fetchSchedule,
    { loading: loadingScheduleData, data: schedule = { import: [] } },
  ] = useLazyQuery(GET_IMPORTED, { fetchPolicy: 'no-cache' });

  const demandData = convertData(demand);
  let scheduleData = convertData(schedule);
  scheduleData = getSumScheduleData(scheduleData);

  const getCompareData = (inputData, ty) => {
    const compareData = ty === 'demand' ? scheduleData : demandData;
    return inputData.map(item => {
      const compare = compareData.find(it => item.sku._id === it.sku._id);
      return { ...item, compare };
    });
  };

  let tableData = {
    demand: getCompareData(demandData, 'demand'),
    schedule: getCompareData(scheduleData, 'schedule'),
  }[type];

  if (onlyWarning) {
    tableData = getOnlyWarning(tableData, type);
  }

  tableData = tableData.map(i => {
    return {
      ...i,
      uom: i.sku?.supplementInfo?.uom,
      description: i.sku?.description,
      supplierSku: i.sku?.supplierSku,
      buyerSku: i.sku?.buyerSku,
    };
  });

  // filter monthKeys
  const [
    fetchMonthKeys,
    { loading: loadingFetchMonthKeys, data: dataMonthKeys = { keys: [] } },
  ] = useLazyQuery(GET_KEYS, { fetchPolicy: 'no-cache' });
  // filter partnerKeys
  const [
    fetchPartnerKeys,
    { loading: loadingFetchPartnersKeys, data: dataPartnerKeys = { partners: [] } },
  ] = useLazyQuery(GET_MY_PARTNERS, { fetchPolicy: 'no-cache' });
  useEffect(() => {
    fetchMonthKeys();
    fetchPartnerKeys();
  }, [company]);
  const monthOptions = dataMonthKeys.keys
    .filter(({ key }) => key.includes(type))
    .map(({ key }) => key.split('-')[1])
    .sort((a, b) => b - a);
  const partnersOptions = dataPartnerKeys.partners.map(item => item.partnerOrganization);
  const defaultDate = date || (monthOptions.length !== 0 && monthOptions[0]);
  useEffect(() => {
    if (defaultDate) {
      fetchDemand({
        variables: { getImport: { type: 'demand', date: defaultDate, partner } },
      });
      fetchSchedule({
        variables: { getImport: { type: 'schedule', date: defaultDate, partner } },
      });
    }
  }, [company, date, partner, dataMonthKeys, type]);

  const getPageMetadata = () => {
    let metadataMap = {
      schedule: {
        title: t('common.productionSchedule'),
        type: 'schedule',
        columns: [],
      },
      demand: {
        title: t('common.demandForecast'),
        type: 'demand',
        columns: [],
      },
    };
    if (defaultDate) {
      const getItemDesc = () => ({
        name: `description`,
        label: t('pos.itemDescription'),
        options: {
          filter: false,
          customBodyRender: value => ellipsisText({ value, length: 12 }),
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '85px',
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '85px',
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
        },
      });
      const getCode = ty => ({
        name: ty === 'demand' ? 'supplierSku' : 'buyerSku',
        label: ty === 'demand' ? t('pos.part') : t('pos.itemCode'),
        options: {
          filter: false,
          customBodyRender: value => displayToolTip({ value, displayValue: value }),
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
        },
      });
      const getUOM = () => {
        return {
          name: `uom`,
          label: t('pos.UOM'),
          options: {
            filter: false,
            customBodyRender: value => ellipsisText({ value }),
            setCellProps: () => ({
              style: {
                whiteSpace: 'nowrap',
                position: 'sticky',
                left: '210px',
                zIndex: 3,
                background: '#FFFFFF',
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: 'nowrap',
                position: 'sticky',
                left: '210px',
                zIndex: 101,
                background: '#FFFFFF',
              },
            }),
          },
        };
      };
      const getStatic = () => ({
        name: `Chart`,
        label: t('pos.chart'),
        options: {
          download: false,
          filter: false,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '255px',
              zIndex: 3,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '255px',
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
          customBodyRender: (value, tableMeta) => (
            <Box className={classes.pointer}>
              <EqualizerIcon
                color="secondary"
                onClick={() => {
                  setChartData({
                    ...tableData[tableMeta.rowIndex],
                    yUnit: tableData[tableMeta.rowIndex].UOM,
                  });
                  setDisplayChart('chart');
                }}
              />
            </Box>
          ),
        },
      });
      const getInventory = () => ({
        name: `inventory`,
        label: t('pos.totalInventory'),
        options: {
          filter: false,
          customBodyRender: (values, tableMeta) => {
            const v = !values.length
              ? 'No Data '
              : values.reduce(
                  (accumulator, currentValue) =>
                    accumulator + parseInt(JSON.parse(currentValue).QUANTITY, 10),
                  0,
                );
            return (
              <Grid container className={classes.pointer} alignItems="center">
                <Grid item>
                  <EqualizerIcon
                    color="secondary"
                    onClick={() => {
                      setChartData(tableData[tableMeta.rowIndex]);
                      setDisplayChart('inventory');
                    }}
                  />
                </Grid>
                <Grid item>{v}</Grid>
              </Grid>
            );
          },
          setCellHeaderProps: () => ({ style: { whiteSpace: 'nowrap' } }),
        },
      });
      const getStockOnHand = () => ({
        name: `ON_HAND`,
        label: t('pos.SOH'),
        options: {
          customBodyRender: value => formatNumber(value),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '300px',
              zIndex: 101,
              background: '#FFFFFF',
            },
          }),
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '300px',
              zIndex: 3,
              background: '#FFFFFF',
              textAlign: 'right',
            },
          }),
        },
      });
      const getMonthColumn = count =>
        months36.slice(0, count).map(item => {
          const label = getMonthLabel(item, defaultDate);
          return {
            name: `MONTH_${item}`,
            label,
            options: {
              filter: false,
              customBodyRender: (value, tableMeta) => {
                let warning = null;
                const { compare } = tableData[tableMeta.rowIndex];
                if (compare) {
                  const compareValue = parseInt(compare[`MONTH_${item}`], 10);
                  const v = parseInt(value, 10);
                  const compareSchedule = type === 'schedule' ? v : compareValue;
                  const compareDemand = type === 'schedule' ? compareValue : v;
                  if (compareSchedule - compareDemand < 0) {
                    warning = {
                      compareSchedule,
                      compareDemand,
                    };
                  }
                }
                return (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      className={warning ? classes.showWarning : ''}
                    >
                      {warning && (
                        <Tooltip
                          arrow
                          title={`Potential shortfall, demand exceeds supply, schedule:${warning.compareSchedule}, demand:${warning.compareDemand},`}
                          placement="top-start"
                        >
                          <Grid className={classes.warningIcon}>
                            <ReportProblemOutlinedIcon />
                          </Grid>
                        </Tooltip>
                      )}
                    </Grid>
                    {formatNumber(value)}
                  </>
                );
              },
              setCellHeaderProps: () => ({ style: { whiteSpace: 'nowrap' } }),
              setCellProps: () => ({
                style: {
                  textAlign: 'right',
                },
              }),
            },
          };
        });
      metadataMap = {
        schedule: {
          title: t('common.productionSchedule'),
          type: 'schedule',
          columns: [
            getCode('schedule'),
            getCode('demand'),
            getUOM(),
            getStatic(),
            getInventory(),
            getItemDesc(),
            ...getMonthColumn(6),
          ],
        },
        demand: {
          title: t('common.demandForecast'),
          type: 'demand',
          columns: [
            getCode('schedule'),
            // getCode('demand'),
            getItemDesc(),
            getUOM(),
            getStatic(),
            getStockOnHand(),
            ...getMonthColumn(36),
          ],
        },
      };
    }
    return metadataMap[type];
  };

  const pageMetadata = getPageMetadata();

  const CustomToolbar = () =>
    monthOptions.length ? (
      <Grid container className={classes.toolbarContainer} justify="space-between">
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <InputLabel>{t('pos.month')}</InputLabel>
              <Select
                value={date}
                className={classes.dateSelector}
                defaultValue={monthOptions[0]}
                onChange={e => setDate(e.target.value)}
              >
                {monthOptions.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <InputLabel>{t('common.businessPartner')}</InputLabel>
              <Select
                value={partner}
                className={classes.partnerSelector}
                defaultValue="all"
                onChange={e => setPartner(e.target.value)}
              >
                <MenuItem value="all">{t('common.allBusinessPartner')}</MenuItem>
                {partnersOptions.map(item => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>

            {/* <Grid item> */}
            {/*  <InputLabel>Only Show Warning</InputLabel> */}
            {/*  <Checkbox */}
            {/*    checked={onlyWarning} */}
            {/*    onChange={() => setOnlyWarning(!onlyWarning)} */}
            {/*    color="primary" */}
            {/*  /> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            className={classes.field}
            onClick={() => {
              setDate(monthOptions[0]);
              setPartner('all');
              setOnlyWarning(false);
            }}
          >
            {t('pos.reset')}
          </Button>
        </Grid>
      </Grid>
    ) : null;

  if (loadingFetchMonthKeys || loadingFetchPartnersKeys) {
    return <LoadingComponent />;
  }

  return (
    <Grid className={classes.root}>
      <Typography className={classes.title} variant="h3">
        {pageMetadata.title}
      </Typography>
      <CustomToolbar />
      <Grid>
        {!(loadingDemandData || loadingScheduleData) ? (
          <Grid className={classes.table}>
            <Table
              data={tableData}
              columns={pageMetadata.columns}
              options={{
                sort: false,
                pagination: true,
                download: true,
                filter: false,
                tableBodyMaxHeight: '40rem',
                rowsPerPageOptions: [10, 50, 100],
                downloadOptions: { filename: `${type}.csv` },
                onDownload: (buildHead, buildBody, columns, data) =>
                  `\uFEFF${buildHead(columns)}${buildBody(data)}`,
                textLabels: {
                  body: {
                    noMatch: t('common.noMatchingRecords'),
                  },
                  pagination: {
                    rowsPerPage: t('common.rowsPerPage'),
                  },
                },
              }}
            />
            {displayChart && (
              <ChartModal
                chart={displayChart}
                closeModal={() => setDisplayChart(false)}
                data={chartData}
                defaultDate={defaultDate}
                type={type}
                yUnit={chartData.yUnit}
              />
            )}
          </Grid>
        ) : (
          <LoadingComponent />
        )}
      </Grid>
    </Grid>
  );
};

export default () => {
  const type = window.location.pathname.substring(1);
  return <Imported key={type} />;
};
