import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const BatchUploadContext = React.createContext({
  batchUploadLoading: false,
  batchUpload: () => {},
  batchUploadResult: [],
  resetBatchUpload: () => {},
});

function BatchUploadProvider({ children }) {
  const [batchUploadLoading, setBatchUploadLoading] = useState(false);
  const [batchUploadResult, setBatchUploadResult] = useState([]);

  const contextValue = {
    batchUploadLoading,
    batchUploadResult,
    batchUpload: async ({ jobs }) => {
      setBatchUploadLoading(true);
      const result = await Promise.allSettled(jobs);
      setBatchUploadResult(result);
      setBatchUploadLoading(false);
    },
    resetBatchUpload: () => {
      setBatchUploadResult([]);
    },
  };

  return <BatchUploadContext.Provider value={contextValue}>{children}</BatchUploadContext.Provider>;
}

BatchUploadProvider.propTypes = { children: PropTypes.node.isRequired };

function useBatchUpload() {
  return useContext(BatchUploadContext);
}

export { useBatchUpload, BatchUploadProvider };
