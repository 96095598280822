import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    height: '22px',
    width: '18px',
  },
}));

const TransferToolTip = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('tokens.transferTooltip')}>
        <InfoOutlined className={classes.tooltipIcon} />
      </Tooltip>
    </>
  );
};
export default TransferToolTip;
