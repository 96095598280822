import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Table from '@eyblockchain/ey-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { findIndex } from 'lodash';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useFormik, Form, FormikProvider } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BlockchainInfo from '../../components/Shared/BlockchainInfo';
import SignButton from '../../components/Shared/SignButton';
import TokenMetaDataForm from '../../components/Tokenization/TokenMetaDataForm';
import { CONSTANTS, TAB_VALUES } from '../../constants';
import { useTokenContext } from '../../contexts/Tokenization/token';

const useRowStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    color: theme.palette.primary.light,
  },
  status: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '.5rem',
  },
  table: {
    padding: '0px',
    '& .MuiTable-root': {
      width: '99%',
      margin: 'auto',
    },
    '& .MuiToolbar-root': {
      marginTop: '20px',
      marginBottom: '20px',
      padding: '0px',
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.primary.contrastText,
          border: `1px solid ${theme.palette.primary.main}`,
          boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
        },
      },
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-root': {
      padding: '10px',
      fontWeight: '400',
      margin: '5px',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      display: 'none',
    },
    '& > div': {
      overflow: 'visible',
    },
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
    marginTop: '50px',
  },
  subTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  divider: {
    background: '#e0e0e0',
    marginBottom: '.5rem',
    marginTop: '.5rem',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  statusContainer: {
    display: 'block',
  },
  preTitle: {
    fontWeight: '200',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '.875rem',
  },
  preTitleName: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.red,
    marginRight: '.5rem',
  },
  message: {
    display: 'flex',
    color: theme.colors.red,
    marginLeft: 10,
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  updateDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  preTitleRightSide: {
    marginLeft: 730,
  },
  inputContainer: {
    display: 'block',
    marginBottom: theme.spacing(5),
  },
  textfieldInstructions: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.8),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    fontWeight: 200,
  },
  signingBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(2)}px`,
    boxShadow: `-8px 8px 8px 4px rgba(0,0,0,.25)`,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    display: 'block',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: 'none',
  },
  paperModel: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
    padding: theme.spacing(3),
  },
  modalTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  mintTokenMessage: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 200,
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'normal',
  },
  sectionData: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  signCard: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  cardText: {
    color: theme.colors.blue,
    fontSize: theme.spacing(2.2),
    letterSpacing: 0,
    lineHeight: 'normal',
  },
  signButton: {
    margin: theme.spacing(1),
  },
  section: {
    paddingTop: theme.spacing(3),
  },
  processing: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  circularLoader: {
    marginRight: theme.spacing(3),
  },
  stagedSerialNo: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingLeft: '25px',
  },
  removeDuplicate: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingRight: '25px',
    textAlign: 'Right',
  },
  duplicateButton: {
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    width: 217,
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  statusIcon: {
    height: '20px',
    width: '20px',
    color: 'inherit',
  },
  readyChip: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  duplicateChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  duplicateSerial: {
    color: theme.palette.error.main,
  },
  loaderContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
  },
  countHighlighter: {
    color: '#B9251B',
  },
  gridSpace: {
    paddingLeft: 14,
  },
  modelMessage: {
    display: 'flex',
    color: theme.colors.blue,
    marginLeft: 10,
  },
  modelIcon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
}));

const BulkMetaDataModel = ({
  open,
  closeModal,
  redirectListpage,
  mintValues,
  handleBulkMetaUpdate,
  isBulkMetaUpdateProcessing,
  tokenCount,
  modalAction,
}) => {
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const classes = useRowStyles();
  const { selectedContract } = useTokenContext();
  const formik = useFormik({
    initialValues: {
      mintValues: mintValues,
    },
    validationSchema: Yup.object().shape({
      mintValues: Yup.string().required(),
    }),
  });

  return (
    <>
      {modalAction ? (
        <Modal className={classes.modal} open={open} onClose={closeModal}>
          <Paper className={classes.paperModel}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('tokens.updateMetaFeeConfirmation')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  <Typography variant="h6" className={classes.mintTokenMessage}>
                    {t('tokens.updateMetadataFeeIntro')}{' '}
                    <strong>
                      {`${tokenCount}`} {t('tokens.tokens')}
                    </strong>{' '}
                    {t('tokens.fromThe')}
                    <strong>{` ${selectedContract.tokenName}`}</strong>{' '}
                    {t('tokens.tokenSmartContract')}
                  </Typography>
                  <Typography variant="body1" display="inline" className={classes.modelMessage}>
                    {t('tokens.wantToProceed')}
                  </Typography>
                  {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                    <BlockchainInfo
                      validateForm={formik.validateForm}
                      values={formik.values}
                      contractAddress=""
                      contractName={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                      method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                      methodArgs={['2', '']}
                    />
                  ) : (
                    ''
                  )}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.sectionData}>
                    <Button
                      className={classes.signButton}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.cancel')}
                    </Button>
                    <SignButton
                      onSign={() => handleBulkMetaUpdate()}
                      className={classes.signButton}
                      label={t('tokens.updateMetaData', {
                        tokens: tokenCount,
                      })}
                      testId="updateMetadata"
                    />
                  </div>
                </Grid>
              </Form>
            </FormikProvider>
          </Paper>
        </Modal>
      ) : (
        <Modal
          className={classes.modal}
          open={open && isBulkMetaUpdateProcessing}
          onClose={closeModal}
        >
          <Paper className={classes.paperModel}>
            <Grid container direction="row" justify="space-between">
              <Typography variant="h4" className={classes.modalTitle}>
                {t('tokens.updateMetadata')}
              </Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container direction="column">
              <>
                <Grid container direction="row" alignItems="center" className={classes.processing}>
                  <CircularProgress
                    color="primary"
                    size={30}
                    thickness={2}
                    className={classes.circularLoader}
                  />
                  <Typography variant="body1" display="inline" className={classes.modelMessage}>
                    {t('tokens.updateMetadataProcess', {
                      tokenCount: tokenCount,
                      contractName: selectedContract.tokenName,
                    })}
                  </Typography>
                </Grid>
                <Card className={classes.signCard}>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.iconContainer}>
                      <InfoIcon className={classes.modelIcon} />
                    </div>
                    <Typography variant="h4" className={classes.cardText}>
                      {t('contracts.processingSigning')}
                    </Typography>
                  </CardContent>
                </Card>
              </>
              <Divider classes={{ root: classes.navButtonDivider }} />
              <div className={classes.section}>
                <Button
                  className={classes.submit}
                  variant="outlined"
                  color="primary"
                  onClick={redirectListpage}
                >
                  {t('common.close')}
                </Button>
              </div>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
};

BulkMetaDataModel.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  redirectListpage: PropTypes.func.isRequired,
  mintValues: PropTypes.shape({
    serialNo: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  handleBulkMetaUpdate: PropTypes.func.isRequired,
  isBulkMetaUpdateProcessing: PropTypes.bool.isRequired,
  modalAction: PropTypes.bool.isRequired,
  tokenCount: PropTypes.number.isRequired,
};

const TokenSearch = ({ searchText, onSearch }) => {
  const classes = useRowStyles();
  const { t } = useTranslation();

  const handleTextChange = event => {
    onSearch(event.target.value);
  };

  return (
    <div>
      <MUITextField
        variant="outlined"
        select={false}
        placeholder={t('tokens.searchTokens')}
        className={classes.searchText}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchText || ''}
        onChange={handleTextChange}
      />
    </div>
  );
};

TokenSearch.propTypes = {
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

TokenSearch.defaultProps = {
  searchText: '',
};

const UpdateBatchMetadata = ({ hideBatchMetadataUpdate, updateBatchMetadataList }) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const { selectedContract } = useTokenContext();
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [tokenListLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalShow, setIsModalShow] = useState(true);
  const [activeTab, setActiveTab] = useState(TAB_VALUES.CONTRACT_PANEL.LIST);
  const formRef = useRef();
  const { isBulkMetaUpdateProcessing, tokenBatchMetadataUpdating } = useTokenContext();

  useEffect(() => {
    if (tokenBatchMetadataUpdating === true) {
      hideBatchMetadataUpdate(false);
    }
  }, [tokenBatchMetadataUpdating]);

  const handleClose = () => {
    setOpen(false);
  };

  const redirectListpage = () => {
    setOpen(false);
    hideBatchMetadataUpdate(false);
  };

  const tabs = [
    {
      label: `${t('tokens.tokenList')} (${updateBatchMetadataList.length})`,
      name: TAB_VALUES.CONTRACT_PANEL.LIST,
      id: 'tokenlist_tab',
    },
  ];

  const tabChangeHandler = (event, newTabIndex) => {
    event.preventDefault();
    setActiveTab(tabs[newTabIndex]?.name);
  };

  const columnDefs = () => {
    const customOptions = () => {
      return {
        filter: false,
      };
    };

    const hiddenBody = () => ({
      filter: false,
      display: false,
    });

    return [
      {
        name: 'tokenId',
        label: '',
        options: customOptions(),
      },
      {
        name: 'id',
        options: hiddenBody(),
      },
    ];
  };
  const getTokenList = () => {
    return (
      <>
        <Table
          columns={columnDefs()}
          data={updateBatchMetadataList}
          className={classes.table}
          classes={{
            liveAnnounce: classes.liveAnnounce,
          }}
          options={{
            filter: false,
            selectableRows: 'none',
            selectToolbarPlacement: 'none',
            sort: false,
            search: false,
            searchOpen: true,
            pagination: true,
            elevation: 0,
            onChangePage: () => {},
            customToolbar: () => null,
            customSearchRender: (searchText, handleSearch, hideSearch, options) => {
              return (
                <TokenSearch searchText={searchText} onSearch={handleSearch} options={options} />
              );
            },
            textLabels: {
              body: {
                noMatch: t('tokens.noTokenRecords'),
              },
            },
          }}
        />
      </>
    );
  };

  const updateMetadataButtonLabel = () => {
    if (updateBatchMetadataList.length > 0) {
      return `${t('tokens.updateMetadata')} (${updateBatchMetadataList.length}) `;
    }
    return `${t('tokens.updateMetadata')}`;
  };

  const handleBulkMetaUpdate = async () => {
    if (formRef.current) {
      formRef.current.click();
      setIsModalShow(false);
    }
  };

  return (
    <>
      {tokenListLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        ''
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.navContainer}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => hideBatchMetadataUpdate(false)}
          className={classes.navButton}
          data-testid="backToTokensPage"
          startIcon={<ArrowBackIosIcon />}
        >
          {t('headers.token_plural')}
        </Button>
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Grid className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.status}
        >
          <div>
            <Typography className={classes.preTitle} variant="h5">
              {t('headers.token')}
              <span className={classes.preTitleName}>{` ${selectedContract.tokenName}`}</span>
            </Typography>
            <Typography className={classes.sectionTitle} variant="h5">
              {t('tokens.specifyMetadata')}
            </Typography>
          </div>
        </Grid>
        <Divider classes={{ root: classes.navButtonDivider }} />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.status}
        >
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.subTitle}>
              {t('tokens.updateMetadataFor', {
                nos: updateBatchMetadataList.length,
              })}
            </Typography>
            <Typography className={classes.updateDescription}>
              {t('tokens.updateMetadataInfo')}
            </Typography>
            <Paper className={classes.paper}>
              <Typography className={classes.message}>
                <WarningIcon className={classes.icon} />
                {t('tokens.updateMetadataWarning', {
                  nos: updateBatchMetadataList.length,
                })}
              </Typography>
            </Paper>
            <>
              <Tabs
                id="tab_items"
                value={findIndex(tabs, { name: activeTab })}
                onChange={tabChangeHandler}
                classes={{
                  indicator: classes.indicator,
                }}
              >
                {tabs.map(({ label, id }) => (
                  <Tab label={label} key={uniqid()} id={id} />
                ))}
              </Tabs>
              <div className={classes.tokenListContentArea}>
                {activeTab === TAB_VALUES.CONTRACT_PANEL.LIST && getTokenList()}
              </div>
            </>
          </Grid>
          <Grid item xs={6} className={classes.gridSpace}>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={12} className={classes.stagedSerialNo}>
                <Typography variant="h6" className={classes.subTitle}>
                  {t('tokens.metadataToUpdate')}
                </Typography>
                <div item xs={8}>
                  <TokenMetaDataForm
                    selectedToken={updateBatchMetadataList}
                    selectedContract={selectedContract}
                    batchMetaData
                    setIsButtonDisable={setIsButtonDisable}
                    ref={formRef}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.signingBox}>
            <Button
              onClick={() => hideBatchMetadataUpdate(false)}
              variant="outlined"
              color="primary"
            >
              {t('common.cancel')}
            </Button>
            <Button
              className={classes.submitButton}
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
              disabled={isButtonDisable}
              data-testid="bulkmintsubmit-button"
            >
              {updateMetadataButtonLabel()}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <BulkMetaDataModel
        open={open}
        closeModal={handleClose}
        redirectListpage={redirectListpage}
        mintValues={selectedContract}
        handleBulkMetaUpdate={handleBulkMetaUpdate}
        isBulkMetaUpdateProcessing={isBulkMetaUpdateProcessing}
        tokenCount={updateBatchMetadataList.length}
        modalAction={isModalShow}
      />
    </>
  );
};

UpdateBatchMetadata.propTypes = {
  hideBatchMetadataUpdate: PropTypes.func.isRequired,
  updateBatchMetadataList: PropTypes.shape([{}]).isRequired,
};

export default UpdateBatchMetadata;
