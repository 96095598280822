import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@eyblockchain/ey-ui/core/Table';
import uniqid from 'uniqid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@eyblockchain/ey-ui/core/DialogTitle';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import InstanceMenu from '../../components/Traceability/InstanceMenu';
import { GET_INSTANCES, UPDATE_INSTANCE } from '../../graphql/Traceability/instance';
import { CONSTANTS } from '../../constants';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import CreateInstanceForm from '../../components/Traceability/CreateInstanceForm';
import InstanceSearchBar from '../../components/Traceability/InstanceSearchBar';
import EmptyBanner from '../../components/Traceability/EmptyBanner';
import LoadingContentContainer from './LoadingContentContainer';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.primary.lightest,
      minHeight: '100vh',
    },
    header: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    successChip: {
      backgroundColor: theme.palette.success.main,
    },
    configChip: {
      backgroundColor: theme.palette.warning.lightest,
    },
    deactivatedChip: {
      backgroundColor: theme.palette.primary,
    },
    finalizeChip: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
    progressChip: {
      backgroundColor: theme.palette.primary,
    },
    contentContainer: {
      flex: 1,
      alignItems: 'stretch',
      marginTop: theme.spacing(2),
    },
    table: {
      backgroundColor: theme.palette.primary.lightest,
      '& .MuiTableCell-head': {
        paddingTop: theme.spacing(2),
        color: theme.palette.primary.lightest,
        '& svg': {
          fill: theme.palette.primary.light,
        },
      },
      '& .MuiToolbar-root': {
        padding: 0,
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          cursor: 'pointer',
        },
        wordBreak: 'break-word',
      },
      '& .MuiTableCell-root': {
        padding: '11px',
      },
    },
    loaderContainer: {
      height: '50%',
      width: '50%',
      alignSelf: 'center',
      marginTop: '20%',
    },
    loader: {
      position: 'relative',
    },
  };
});

const Overview = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => setDialogOpen(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const handleCreateClose = () => setIsCreateOpen(false);
  const [modalDescription, setModalDescription] = useState('');
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [modalInstanceId, setModalInstanceId] = useState('');
  const [modalInstanceName, setModalInstanceName] = useState('');
  const [clickedMenuItem, setClickedMenuItem] = useState('');
  const { activeWallet } = useBecOrganizationContext();
  const { resetInstanceDetails } = useConfigurationWizardContext();

  const {
    permissionsFlags: { isUserAuthToCreateValueChain, isUserAuthToEditValueChain },
  } = useUserInfo();

  const [getInstances, { data: serverData, loading }] = useLazyQuery(GET_INSTANCES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    resetInstanceDetails();
    getInstances();
  }, [activeWallet, getInstances]);

  const instances = serverData?.instances
    ? serverData.instances.map(instance => {
        const getStatus = ({ status }) => {
          const statusMap = {
            [CONSTANTS.INSTANCE_STATUSES.ACTIVE]: t('traceability.instanceStatus.active'),
            [CONSTANTS.INSTANCE_STATUSES.PASSIVE]: t('traceability.instanceStatus.deactivated'),
            [CONSTANTS.INSTANCE_STATUSES.FINALIZE]: t('traceability.instanceStatus.finalize'),
            [CONSTANTS.INSTANCE_STATUSES.PROGRESS]: t('traceability.instanceStatus.progress'),
          };
          return statusMap[status] || t('traceability.instanceStatus.config');
        };

        const { name: instanceName, _id, date } = instance;
        return {
          instanceName,
          instanceDate: moment(date * 1000).format('L'),
          instanceStatus: getStatus(instance),
          instanceActions: _id,
          instanceId: _id,
        };
      })
    : [];

  const handleDialogClick = async () => {
    switch (clickedMenuItem) {
      case t('traceability.instanceStatus.deactivated'):
        await updateInstance({
          variables: {
            instanceId: modalInstanceId,
            status: CONSTANTS.INSTANCE_STATUSES.PASSIVE,
            name: modalInstanceName,
          },
        });
        handleDialogClose();
        getInstances();
        break;
      case t('traceability.instanceStatus.active'):
        await updateInstance({
          variables: {
            instanceId: modalInstanceId,
            status: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
            name: modalInstanceName,
          },
        });
        handleDialogClose();
        getInstances();
        break;
      default:
        history.push(`/traceability/instance-review/${modalInstanceId}`);
        break;
    }
  };

  const generateColumns = () => {
    const customOptions = () => {
      return {
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      };
    };

    const customBody = () => {
      const options = customOptions();
      options.customBodyRender = (data, tableMeta) => {
        const { rowData } = tableMeta;
        // rowData returns an array of colums for each of the rows.
        // Each element of the array correponds to a column as they have been ordered in the table
        // In this case
        // rowData[0] = name
        // rowData[1] = creation date
        // rowData[2] = status
        // rowData[3] = id

        const isValueChainInProgress =
          rowData[2] === t('traceability.instanceStatus.config') ||
          rowData[2] === t('traceability.instanceStatus.progress');

        // user can edit the value chain if it's not finalized or if he has the permissions to do so
        return isValueChainInProgress || isUserAuthToEditValueChain ? (
          <InstanceMenu
            rowStatus={rowData[2]}
            rowId={rowData[3]}
            rowName={rowData[0]}
            dialogState={setDialogOpen}
            clickedItem={setClickedMenuItem}
            setInstanceId={setModalInstanceId}
            setInstanceName={setModalInstanceName}
            setModalTitle={setModalTitle}
            setModalDescription={setModalDescription}
          />
        ) : null;
      };
      return options;
    };

    const customChipBody = () => {
      const options = customOptions();
      options.customBodyRender = data => {
        let actionComponent;
        if (data === t('traceability.instanceStatus.active')) {
          actionComponent = <Chip label={data} className={classes.successChip} />;
        }
        if (data === t('traceability.instanceStatus.config')) {
          actionComponent = <Chip label={data} className={classes.configChip} />;
        }
        if (data === t('traceability.instanceStatus.deactivated')) {
          actionComponent = <Chip label={data} className={classes.deactivatedChip} />;
        }
        if (data === t('traceability.instanceStatus.finalize')) {
          actionComponent = <Chip label={data} className={classes.finalizeChip} />;
        }
        if (data === t('traceability.instanceStatus.progress')) {
          actionComponent = (
            <Chip
              label={data}
              className={classes.deactivatedChip}
              icon={<CircularProgress size={20} thickness={20} color="primary" />}
            />
          );
        }
        return actionComponent;
      };
      return options;
    };

    const hiddenBody = () => ({
      filter: false,
      display: false,
    });

    return [
      {
        name: 'instanceName',
        label: t('traceability.instanceTable.nameColumn'),
        options: customOptions(),
      },
      {
        name: 'instanceDate',
        label: t('traceability.instanceTable.dateColumn'),
        options: customOptions(),
      },
      {
        name: 'instanceStatus',
        label: t('traceability.instanceTable.status'),
        options: customChipBody(),
      },
      {
        name: 'instanceActions',
        label: t('traceability.instanceTable.actions'),
        options: customBody(),
      },
      {
        name: 'instanceId',
        options: hiddenBody(),
      },
    ];
  };

  const content = () => {
    return instances.length === 0 ? (
      <EmptyBanner
        bannerText={t('traceability.instanceEmpty')}
        headerText={t('traceability.landingHeader')}
        buttonText={t('traceability.addNewInstance')}
        onButtonClick={() => setIsCreateOpen(true)}
      />
    ) : (
      <div>
        <Table
          title={<Typography variant="h4">{t('traceability.landingHeader')}</Typography>}
          className={classes.table}
          columns={generateColumns()}
          data={instances}
          options={{
            onRowClick: row => {
              let nextPath = '/traceability/';
              nextPath += CONSTANTS.TRACE_PATHS.instanceReview;
              nextPath += `/${row[4]}/`;
              history.push(nextPath);
            },
            textLabels: {
              body: {
                noMatch: t('common.noMatchingRecords'),
              },
              pagination: {
                rowsPerPage: t('common.rowsPerPage'),
              },
            },
            responsive: 'standard',
            filter: false,
            search: false,
            searchOpen: true,
            elevation: 0,
            pagination: true,
            customSearchRender: (searchText, handleSearch, hideSearch, options) => (
              <InstanceSearchBar
                searchText={searchText}
                className={classes.search}
                handleSearch={handleSearch}
                hideSearch={hideSearch}
                options={options}
                placeHolderText={t('traceability.searchValueChain')}
                createButtonText={t('traceability.addNewInstance')}
                handleOpenForm={() => setIsCreateOpen(true)}
              />
            ),
          }}
        />
      </div>
    );
  };

  const DialogContainer = () => (
    <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
      <DialogTitle onClose={handleDialogClose}>{modalTitle}</DialogTitle>
      <DialogContent dividers>{modalDescription}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleDialogClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDialogClick}>
          {modalTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {isUserAuthToCreateValueChain && (
        <CreateInstanceForm isDialogOpen={isCreateOpen} handleClose={handleCreateClose} />
      )}
      <DialogContainer />
      <div className={classes.root}>
        <LoadingContentContainer content={content} loading={loading} />
      </div>
    </>
  );
};

export default Overview;
