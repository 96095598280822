import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isArray, isObject } from 'lodash';
import uniqid from 'uniqid';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
  },
}));

/**
 * A component to format and display error messages.
 *
 */

const ErrorMessage = ({ errors, errorKey }) => {
  const splittedTextCollection = [];
  const error = errors[errorKey];
  if (typeof error === 'string') {
    splittedTextCollection.push(error.split(/({.*?})/));
  }
  if (isArray(error)) {
    error.forEach(arr => {
      if (!arr) return;
      if (isObject(arr)) {
        const arrLength = Object.keys(arr);
        for (let i = 0; i < arrLength.length; i += 1) {
          const key = arrLength[i];
          splittedTextCollection.push(arr[key].split(/({.*?})/));
        }
      } else {
        splittedTextCollection.push(arr.split(/({.*?})/));
      }
    });
  }
  return (
    <>
      {splittedTextCollection.map(splittedText => (
        <Typography variant="body2" key={uniqid()}>
          {splittedText.length === 3 ? (
            <>
              {splittedText[0]}
              <strong>{splittedText[1].replace('{', '').replace('}', '')}</strong>
              {splittedText[2]}
            </>
          ) : (
            splittedText[0]
          )}
        </Typography>
      ))}
    </>
  );
};

ErrorMessage.propTypes = {
  /**
   * The formik error object
   */
  errors: PropTypes.shape({}).isRequired,
  /**
   * The key that maps to the particular error message being displayed
   */
  errorKey: PropTypes.string.isRequired,
};

/**
 * A component to handle errors on long Formik forms. If the error message contains a word wrapped in {} it will show in bold text.
 *
 */

const FormErrors = ({ form: { touched, errors }, className: classNameProp, ...other }) => {
  const classes = useStyles();
  const className = clsx(classes.root, classNameProp);

  const touchedlist = Object.keys(touched);
  const errorsToShow = [];

  Object.keys(errors).forEach(key => {
    const isArrayErrors = Array.isArray(errors[key]);
    if (touchedlist.includes(key)) {
      if (isArrayErrors) {
        errors[key].forEach(errorObj => {
          if (errorObj) {
            Object.keys(errorObj).forEach(errorKey =>
              errorsToShow.push(
                <ErrorMessage key={errorKey} errors={errorObj} errorKey={errorKey} />,
              ),
            );
          }
        });
      } else {
        errorsToShow.push(<ErrorMessage key={key} errors={errors} errorKey={key} />);
      }
    }
  });

  return (
    <>
      {errorsToShow.length > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          p={2}
          my={2}
          bgcolor="background.paper"
          className={className}
          {...other}
        >
          <Box pr={2}>
            <ErrorIcon color="error" />
          </Box>
          <Box>{errorsToShow}</Box>
        </Box>
      )}
    </>
  );
};

FormErrors.propTypes = {
  /**
   * The props from the Formik render method.
   */
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
  }).isRequired,
  /**
   * The additional classes which need to be applied to the root element.
   */
  className: PropTypes.string,
};

FormErrors.defaultProps = {
  className: null,
};

export default FormErrors;
