import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import track from '../../mixpanel';
import {
  PURCHASE_ORDER_LISTING,
  GET_PURCHASE_ORDER_UPDATE,
  CREATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_BY_ID,
  SIGN_PURCHASE_ORDER,
  PURCHASE_ORDER_SIGNED,
  PURCHASE_ORDER_LISTING_INVOICE,
} from '../../graphql/Procurement/purchaseOrder';
import { SIGN_DELIVERY, PURCHASE_ORDER_DELIVERED } from '../../graphql/Procurement/delivery';
import { orderListing } from '../../utils';
import { useNotification } from '../Shared/notification';

const PurchaseOrderContext = React.createContext([{}, () => {}]);

const PurchaseOrderProvider = ({ children }) => {
  const { t } = useTranslation();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const { handleNotification, showFaucetModal, setShowFaucetModal } = useNotification();
  const [onpDeploymentFailureTrigger, setOnpDeploymentFailureTrigger] = useState(false);

  const [
    getPurchaseOrders,
    { error: purchaseOrdersError, loading: purchaseOrdersLoading },
  ] = useLazyQuery(PURCHASE_ORDER_LISTING, {
    onCompleted: data => {
      setPurchaseOrders(orderListing(data.purchaseOrders));
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getPurchaseOrdersForInvoice,
    { error: purchaseOrdersErrorInvoice, loading: purchaseOrdersLoadingInvoice },
  ] = useLazyQuery(PURCHASE_ORDER_LISTING_INVOICE, {
    onCompleted: data => {
      setPurchaseOrders(orderListing(data.purchaseOrdersNotInvoiced));
    },
    fetchPolicy: 'no-cache',
  });

  const [createPurchaseOrder, { loading: createPurchaseOrderLoading }] = useMutation(
    CREATE_PURCHASE_ORDER,
    {
      onCompleted: () => {
        track('PO submission status received', { PurchaseOrderSubmissionStatus: 'Success' });
        handleNotification(t('pos.createPurchaseOrderSuccess'), 'success');
      },
      onError: () => {
        track('PO submission status received', { PurchaseOrderSubmissionStatus: 'Fail' });
        handleNotification(t('pos.createPurchaseOrderError'), 'error');
      },
    },
  );

  const [
    getPurchaseOrderDetail,
    {
      data: purchaseOrderDetailData,
      error: purchaseOrderDetailError,
      loading: purchaseOrderDetailLoading,
    },
  ] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [signPurchaseOrder, { loading: signPurchaseOrderLoading }] = useMutation(
    SIGN_PURCHASE_ORDER,
    {
      onCompleted: () => {
        // handleNotification(t('pos.poAccepted'), 'success');
      },
      onError: errors => {
        setOnpDeploymentFailureTrigger(!onpDeploymentFailureTrigger);
        if (errors?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
        handleNotification(t('pos.poRejected'), 'error');
      },
    },
  );

  const [signDelivery, { loading: deliveryPurchaseOrderLoading }] = useMutation(SIGN_DELIVERY, {
    onCompleted: () => {
      handleNotification(t('invoices.goodsReceiptAccept'), 'success');
    },
    onError: () => {
      handleNotification(t('invoices.goodsReceiptReject'), 'error');
    },
  });

  useSubscription(GET_PURCHASE_ORDER_UPDATE, {
    onSubscriptionData: data =>
      setPurchaseOrders(statepurchaseOrders => [
        data.subscriptionData.data.newPO,
        ...statepurchaseOrders,
      ]),
  });

  const poSignedSubscription = useSubscription(PURCHASE_ORDER_SIGNED, {
    onSubscriptionData: data => {
      const poStatus = data.subscriptionData.data.poSigned;
      if (poStatus?.status === 'accepted') {
        getPurchaseOrderDetail({
          variables: { purchaseOrderId: poStatus?._id },
        });
        getPurchaseOrders();
        handleNotification(t('pos.poAccepted'), 'success');
      } else {
        handleNotification(t('pos.poRejected'), 'error');
      }
    },
  });
  useSubscription(PURCHASE_ORDER_DELIVERED, {
    onSubscriptionData: data => {
      const poStatus = data.subscriptionData.data.poDelivered;
      if (poStatus?.status === 'delivered') {
        getPurchaseOrderDetail({
          variables: { purchaseOrderId: poStatus?._id },
        });
        getPurchaseOrders();
      }
    },
  });

  const purchaseOrderLoading =
    createPurchaseOrderLoading ||
    purchaseOrdersLoading ||
    purchaseOrdersLoadingInvoice ||
    purchaseOrderDetailLoading ||
    signPurchaseOrderLoading ||
    deliveryPurchaseOrderLoading;
  const error = purchaseOrdersError || purchaseOrderDetailError || purchaseOrdersErrorInvoice;

  return (
    <PurchaseOrderContext.Provider
      value={{
        purchaseOrders,
        getPurchaseOrdersForInvoice,
        createPurchaseOrder,
        getPurchaseOrders,
        getPurchaseOrderDetail,
        signPurchaseOrder,
        signDelivery,
        error,
        purchaseOrderLoading,
        purchaseOrderData: purchaseOrderDetailData?.purchaseOrderById,
        onpDeploymentFailureTrigger,
        poSignedSubscription,
        showFaucetModal,
      }}
    >
      {children}
    </PurchaseOrderContext.Provider>
  );
};

const usePurchaseOrderContext = () => useContext(PurchaseOrderContext);

PurchaseOrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { usePurchaseOrderContext, PurchaseOrderProvider };
