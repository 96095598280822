import React from 'react';
import PropTypes from 'prop-types';
import PublicApolloClientProvider from './apollo';

function PublicProvider({ children }) {
  return <PublicApolloClientProvider>{children}</PublicApolloClientProvider>;
}

PublicProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicProvider;
