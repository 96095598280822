import React from 'react';
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { TRACE_WIZARD_PROGRESSION_V2, TRACEABILITY_DISPATCHER_ACTIONS } from '../../constants';

const BackButton = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { instanceId, instanceDispatcher } = useConfigurationWizardContext();

  const handleRetreat = () => {
    const currentPath = (history?.location?.pathname?.split('/'))[2];
    const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: currentPath })?.code;

    if (currPathCode > TRACE_WIZARD_PROGRESSION_V2.NEW_WIZARD.code) {
      const newPath = find(TRACE_WIZARD_PROGRESSION_V2, { code: currPathCode - 1 })?.path;
      history.push(`/traceability/${newPath}/${instanceId}`);
      instanceDispatcher({
        type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_CURRENT_ITERATION,
        payload: currPathCode - 1,
      });
    } else {
      history.push(`/traceability/instance-review/${instanceId}`);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBackOutlined />}
        onClick={handleRetreat}
      >
        {t('traceability.configurationWizard.progress.back')}
      </Button>
    </>
  );
};

export default BackButton;
