import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import defaultTheme from '@eyblockchain/ey-ui/core/theme';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import merge from 'lodash/merge';
import mixpanel from 'mixpanel-browser';
import App from './App';
import RootProviders from './contexts/Shared';
import * as serviceWorker from './serviceWorker';
import customTheme from './components/Shared/theme';
import './i18n';
import PublicProvider from './contexts/Shared/public';
import PublicApp from './publicApp';
import { publicRootPaths } from './utils';

const theme = merge(defaultTheme, customTheme);

if (window.platformConfig.mixpanelId) {
  mixpanel.init(window.platformConfig.mixpanelId);
}

ReactDOM.render(
  <Router>
    <Suspense fallback={<div />}>
      <ThemeProvider theme={createTheme(theme)}>
        {publicRootPaths.find(path => {
          return window.location.pathname.startsWith(path);
        }) ? (
          <PublicProvider>
            <CssBaseline />
            <PublicApp />
          </PublicProvider>
        ) : (
          <RootProviders>
            <CssBaseline />
            <App />
          </RootProviders>
        )}
      </ThemeProvider>
    </Suspense>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
