import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { UPDATE_INSTANCE } from '../../../graphql/Traceability/instance';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { CONSTANTS } from '../../../constants';
import { TRANSACTION_STATUS } from '../../../graphql/Tokenization/token';
import WizardStepper from './WizardStepper';
import InstanceName from '../InstanceName';
import track from '../../../mixpanel';
import { useNotification } from '../../../contexts/Shared/notification';

const useStyles = makeStyles(theme => ({
  wizardRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.lightest,
  },
  mainHeader: {
    fontWeight: 700,
    marginTop: '1.5em',
    width: '100%',
  },
  supplyChainSubItemsArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  footerButtons: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '1.5em',
  },
  linkArea: {
    position: 'relative',
    top: '45%',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    position: 'relative',
    display: 'flex',
    top: 'calc(20%)',
    marginRight: theme.spacing(1),
  },
  deployButton: {
    position: 'relative',
    display: 'flex',
    top: 'calc(20%)',
    marginRight: theme.spacing(1),
  },
  instructionsArea: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subArea: {
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
  },
  childrenArea: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  progressBannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1em',
  },
  progressBanner: {
    marginLeft: '1em',
  },
}));

const WizardProgressBar = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TRACE_PATHS } = CONSTANTS;
  const [showDeployButton, setShowDeployButton] = useState(false);
  const [enableDeployButton, setEnableDeployButton] = useState(false);
  const [showSupplyChainSubItems, setShowSupplyChainSubItems] = useState(false);
  const [isDeploymentProcessing, setIsDeploymentProcessing] = useState(false);
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const { handleNotification } = useNotification();

  const history = useHistory();

  const {
    instanceId,
    instanceDetails,
    lastCompletedIteration,
    setLastCompletedIteration,
  } = useConfigurationWizardContext();

  const handleDeploy = async () => {
    try {
      setIsDeploymentProcessing(true);
      setEnableDeployButton(false);
      if (instanceId) {
        await updateInstance({
          variables: {
            instanceId: instanceId,
            status: CONSTANTS.INSTANCE_STATUSES.FINALIZE,
            name: instanceDetails?.content?.name,
          },
        });

        history.push(`/traceability/instance-tokens/${instanceId}`);
      }
    } catch (error) {
      setIsDeploymentProcessing(false);
      setEnableDeployButton(true);
      handleNotification(error?.message, 'error');
    }
  };

  const getInstanceDetails = async () => {
    await instanceDetails.getInstanceDetails({ variables: { input: instanceId } });
  };

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const contract = data.subscriptionData?.data?.transactionStatus;
      if (
        contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.DEPLOY &&
        contract?.smartcontractType === CONSTANTS.SMARTCONTRACT_TYPES.TRACEABILITY
      ) {
        if (contract?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          handleNotification(t('traceability.contractDeploySuccess'), 'success');
          track('Traceability Contract Deployment Transaction Success', {
            smartcontractType: contract?.smartcontractType,
            transactionType: contract?.transactionType,
          });
          setEnableDeployButton(false);
          getInstanceDetails();
        } else if (contract?.status === CONSTANTS.CONTRACT_STATUSES.FAILED) {
          handleNotification(t('traceability.contractDeployFailed'), 'error');
          track('Traceability Contract Deployment Transaction Failure', {
            smartcontractType: contract?.smartcontractType,
            transactionType: contract?.transactionType,
          });
          setEnableDeployButton(true);
        }
        setIsDeploymentProcessing(false);
      }
    },
  });

  useEffect(() => {
    const designWizardState = async () => {
      setShowSupplyChainSubItems(true);

      const currentPath = (history?.location?.pathname?.split('/'))[2];
      if (currentPath === TRACE_PATHS.personalization) {
        setShowDeployButton(true);
        setEnableDeployButton(true);
      }
    };

    designWizardState();
  }, [
    setShowSupplyChainSubItems,
    setLastCompletedIteration,
    lastCompletedIteration,
    TRACE_PATHS,
    history,
  ]);

  return (
    <>
      <div className={classes.wizardRoot}>
        <InstanceName />
        <div className={classes.mainHeader}>
          <div className={classes.subArea}>
            {showSupplyChainSubItems && <WizardStepper />}
            <div className={classes.childrenArea}>{children}</div>
          </div>

          <div className={classes.footerButtons}>
            <div className={classes.deployButton}>
              {showDeployButton && (
                <Button
                  disabled={!enableDeployButton || isDeploymentProcessing}
                  variant="contained"
                  color="secondary"
                  onClick={handleDeploy}
                >
                  {t('traceability.configurationWizard.progress.deploy')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WizardProgressBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WizardProgressBar;
