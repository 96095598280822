import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useFormik, FormikProvider, Form, FastField, FieldArray } from 'formik';
import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/Error';
import Paper from '@material-ui/core/Paper';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { find, filter, countBy, isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import uniqid from 'uniqid';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import DragBoard from '../../Shared/DragBoard';
import StepContent from './StepContent';
import AddArea from '../AddArea';
import { CREATE_STEP, UPDATE_STEP, DELETE_STEPS } from '../../../graphql/Traceability/steps';
import { UPDATE_INSTANCE_PROGRESS } from '../../../graphql/Traceability/instance';

import WarningDialog from '../WarningDialog';
import {
  TRACEABILITY_DISPATCHER_ACTIONS,
  VALUE_CHAIN_FIELDS,
  VALUE_CHAIN_FORMS,
  TRACE_WIZARD_PROGRESSION_V2,
  CONSTANTS,
} from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import WizardHelper, { getHeaderNameText, getTooltipsText } from './WizardHelper';
import DataStructureView from './DataStructureView';
import { handleNavigation, useEditMode, objectKeyOptions } from './traceabilityUtils';
import WizardTooltip from './WizardTooltip';
import WizardButtonArea from './WizardButtonArea';
import useUserInfo from '../../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    margin: '.7em',
    display: 'flex',
    color: 'red',
  },
  stepForm: {
    width: '100%',
  },
  addButton: {
    margin: '1em',
  },
  formArea: {
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1em',
    marginBottom: '1em',
  },
  errorArea: {
    marginTop: '1em',
    padding: '1em',
  },
  stepHelpers: {
    display: 'grid',
    gridTemplateColumns: '26% 24% 12% 23% 12%',
    margin: '0.5em 0',
    padding: theme.spacing(1),
    gridGap: '0.5%',
  },
  mandatoryFieldMarker: {
    color: theme.palette.error.main,
    padding: 0,
    margin: 0,
  },
  instructions: {
    marginTop: theme.spacing(2),
  },
  tooltipIcon: {
    height: '15px',
    width: '15px',
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
}));

const currentFormName = VALUE_CHAIN_FORMS.STEP;

const StepForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    instanceId,
    instanceDetails,
    ingestionTypes,
    instanceDispatcher,
    setIsDataLoading,
    setStepIdsToDelete,
    stepIdsToDelete,
    lastCompletedIteration,
  } = useConfigurationWizardContext();
  const { handleNotification } = useNotification();
  const { ingestionTypes: ingestionTypeRows } = ingestionTypes;
  const involvedItemRows = instanceDetails?.content?.items;
  const stepRows = instanceDetails?.content?.steps;
  const [orderChanged, setOrderChanged] = useState(false);
  const [warning, toggleWarning] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const {
    permissionsFlags: { isUserAuthToEditValueChain, isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const userNotAllowedToViewContent =
    lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
    !isUserAuthToViewValueChainDetails;

  const userAllowedToEdit = isUserAuthToEditValueChain && instanceDetails?.content?.isOwner;

  const [isEditMode, setEditMode] = useEditMode(
    TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.code,
    lastCompletedIteration,
  );
  const viewEditMode = () => setEditMode(true);

  const [addStep] = useMutation(CREATE_STEP);
  const [updateStep] = useMutation(UPDATE_STEP);
  const [deleteSteps] = useMutation(DELETE_STEPS);
  const [updateInstanceStatus] = useMutation(UPDATE_INSTANCE_PROGRESS);

  const emptyStep = {
    stepName: '',
    involvedItem: '',
    ingestionType: 'traceability.ingestionType.DataGatheringTool',
    createToken: false,
    dirty: false,
  };

  const involvedItemOptions = [];
  const ingestionTypeOptions = [];

  involvedItemRows.forEach(item => {
    involvedItemOptions.push({ label: item.name, value: item.name });
  });

  ingestionTypeRows.forEach(ingestionTypeRow => {
    ingestionTypeOptions.push({ label: ingestionTypeRow.name, value: ingestionTypeRow.name });
  });

  const generateInitialForm = () => {
    const initStepArray = [];

    stepRows.forEach(tempStep => {
      initStepArray.push({
        stepName: tempStep?.name,
        involvedItem: tempStep?.involvedItem?.name,
        ingestionType: tempStep?.ingestionType?.name,
        createToken: tempStep?.createToken,
        stepId: tempStep?._id,
        dirty: false,
      });
    });

    return initStepArray;
  };

  const itemInstances = instanceDetails?.content?.items;
  const booleanOptions = () => ({
    customBodyRender: data => (data ? t('tokens.yes') : t('tokens.no')),
  });

  const headerWithHelperOptions = ({ field }) => ({
    customHeadRender: columnMeta => (
      <TableCell key={uniqid()}>
        {columnMeta?.label}
        <WizardTooltip text={t(getTooltipsText({ formName: currentFormName, field }))}>
          <InfoOutlined className={classes.tooltipIcon} />
        </WizardTooltip>
      </TableCell>
    ),
  });
  const tokenizedItems = [];
  for (let i = 0; i < itemInstances?.length; i += 1) {
    if (itemInstances[i]?.tokenize === true) {
      tokenizedItems.push(itemInstances[i]?.name);
    }
  }

  const stepValidationSchema = Yup.object().shape({
    stepArray: Yup.array()
      .of(
        Yup.object().shape({
          stepName: Yup.string().required(
            t('traceability.configurationWizard.steps.errors.stepName'),
          ),
          involvedItem: Yup.string().required(
            t('traceability.configurationWizard.steps.errors.involvedItem'),
          ),
          ingestionType: Yup.string().required(
            t('traceability.configurationWizard.steps.errors.ingestionType'),
          ),
        }),
      )
      .test(
        'Step for token creation',
        t('traceability.configurationWizard.steps.errors.itemToStepTokenisation'),
        value => {
          let stepItemTokenised = [];

          for (let i = 0; i < value.length; i += 1) {
            if (tokenizedItems.includes(value[i].involvedItem)) {
              if (value[i].createToken === false) {
                let count = 0;
                stepItemTokenised.forEach(v => {
                  // eslint-disable-next-line
                  v === value[i].involvedItem && (count += 1);
                });
                stepItemTokenised = stepItemTokenised.filter(e => e !== value[i].involvedItem);
                for (let j = 0; j < count; j += 1) {
                  stepItemTokenised.push(value[j].involvedItem);
                }
              } else if (value[i].createToken === true) {
                stepItemTokenised.push(value[i].involvedItem);
              }
            }
          }
          for (let i = 0; i < tokenizedItems.length; i += 1) {
            if (!tokenizedItems.includes(stepItemTokenised[i])) {
              return false;
            }
          }

          return true;
        },
      )
      .test(
        'Check for duplicate ',
        t('traceability.configurationWizard.steps.errors.duplicateCreateTokenInStep'),
        formItemRows => {
          const stepsThatTokenize = filter(
            formItemRows,
            formItemRow =>
              formItemRow.createToken && tokenizedItems.includes(formItemRow.involvedItem),
          );

          const countsPerTokenizedItem = countBy(stepsThatTokenize, 'involvedItem');
          const duplicates = filter(countsPerTokenizedItem, resp => resp > 1);

          if (duplicates.length) {
            return false;
          }

          return true;
        },
      )
      .test(
        'Check for need to tokenise',
        t('traceability.configurationWizard.steps.errors.createTokenForNoTokenItem'),
        value => {
          const nonTokenizedItems = [];
          for (let i = 0; i < itemInstances.length; i += 1) {
            if (itemInstances[i].tokenize === false) {
              nonTokenizedItems.push(itemInstances[i].name);
            }
          }
          for (let i = 0; i < value.length; i += 1) {
            if (nonTokenizedItems.includes(value[i].involvedItem)) {
              if (value[i].createToken === true) {
                return false;
              }
            }
          }
          return true;
        },
      )
      .test(
        'Check for step to item parity',
        t('traceability.configurationWizard.steps.errors.itemToStepParity'),
        value => {
          const itemsList = [];
          const valueList = [];
          for (let i = 0; i < itemInstances.length; i += 1) {
            itemsList.push(itemInstances[i].name);
          }
          for (let i = 0; i < value.length; i += 1) {
            valueList.push(value[i].involvedItem);
          }

          for (let i = 0; i < itemsList.length; i += 1) {
            if (!valueList.includes(itemsList[i])) {
              return false;
            }
          }
          return true;
        },
      )
      .min(1, t('traceability.configurationWizard.steps.errors.minLength')),
  });

  const formik = useFormik({
    initialValues: {
      stepArray: generateInitialForm(),
    },
    validationSchema: stepValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setProcessing(true);
        setIsDataLoading(true);
        const stepArray = values?.stepArray;
        let checkIfUpdated = false;
        let orderedQueue = 1;

        for (let i = 0; i < stepArray.length; i += 1) {
          const step = stepArray[i];

          const { stepName, involvedItem, createToken, stepId, dirty } = step;
          if (dirty || orderChanged) {
            const submittedInvolvedItem = find(
              involvedItemRows,
              involvedItemRow => involvedItemRow.name === involvedItem,
            );
            const involvedItemId = submittedInvolvedItem ? submittedInvolvedItem._id : null;

            // const submittedIngestionType = find(
            //   ingestionTypeRows,
            //   ingestionTypeRow => ingestionTypeRow.name === ingestionType,
            // );
            // const ingestionTypeId = submittedIngestionType ? submittedIngestionType._id : null;
            const existingEntry = find(stepRows, stepRow => stepRow._id === stepId);
            if (existingEntry) {
              checkIfUpdated = true;
              const updatedStep = await updateStep({
                variables: {
                  input: {
                    _id: stepId,
                    name: stepName,
                    involvedItem: involvedItemId,
                    ingestionType: ingestionTypeRows.length > 0 ? ingestionTypeRows[0]._id : '',
                    order: orderedQueue,
                    createToken: createToken,
                  },
                },
              });
              instanceDispatcher({
                type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_STEPS,
                payload: updatedStep?.data?.updateStep,
              });
            } else {
              const addedStep = await addStep({
                variables: {
                  input: {
                    instance: instanceId,
                    name: stepName,
                    involvedItem: involvedItemId,
                    ingestionType: ingestionTypeRows.length > 0 ? ingestionTypeRows[0]._id : '',
                    order: orderedQueue,
                    createToken: createToken,
                  },
                },
              });
              instanceDispatcher({
                type: TRACEABILITY_DISPATCHER_ACTIONS.ADD_STEPS,
                payload: addedStep?.data?.createStep,
              });
            }
            orderedQueue += 1;
          }
        }

        if (stepIdsToDelete.length > 0) {
          await deleteSteps({
            variables: {
              input: {
                idList: stepIdsToDelete,
                instance: instanceId,
              },
            },
          });
          instanceDispatcher({
            type: TRACEABILITY_DISPATCHER_ACTIONS.DELETE_STEPS,
            payload: stepIdsToDelete,
          });
          setStepIdsToDelete([]);
        }

        // this will update the status if we're editing a finalized VC and didn't apply any changes to the steps
        await updateInstanceStatus({
          variables: {
            instanceId: instanceId,
            inputIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
          },
        });
        instanceDispatcher({
          type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE_STATUS,
          payload: {
            status: CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION,
            lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
            currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
          },
        });

        setOrderChanged(false);
        const toastMessage = checkIfUpdated
          ? t('traceability.configurationWizard.steps.updateSuccess')
          : t('traceability.configurationWizard.steps.creationSuccess');

        handleNotification(toastMessage, 'success');
        resetForm({ values });
        handleNavigation(history, instanceId);
      } catch (err) {
        handleNotification(err?.message, 'error');
      } finally {
        setProcessing(false);
        setIsDataLoading(false);
      }
    },
    enableReinitialize: true,
  });

  const { values: formValues, errors, setFieldValue } = formik;
  const { stepArray } = formValues;

  const handleOnChange = (boardItems, dragResult) => {
    const { source, destination } = dragResult;
    if (
      source === null ||
      source === undefined ||
      destination === null ||
      destination === undefined
    )
      return;

    const { index: sourceIndex } = source;
    const { index: destIndex } = destination;
    if (
      sourceIndex === null ||
      sourceIndex === undefined ||
      destIndex === null ||
      destIndex === undefined
    )
      return;

    const tempSteps = [...stepArray];
    const [reorderedItem] = tempSteps.splice(sourceIndex, 1);
    tempSteps.splice(destIndex, 0, reorderedItem);
    setFieldValue('stepArray', tempSteps);
    setOrderChanged(true);
  };

  const columnDefs = [
    {
      field: VALUE_CHAIN_FIELDS.STEPS.STEP,
      name: 'name',
      options: headerWithHelperOptions({ field: 'step' }),
    },
    {
      field: VALUE_CHAIN_FIELDS.STEPS.INVOLVED_ITEM,
      name: 'involvedItem',
      options: {
        ...headerWithHelperOptions({ field: 'involvedItem' }),
        ...objectKeyOptions({ key: 'name', withTranslation: false, t: t }),
      },
    },
    {
      field: VALUE_CHAIN_FIELDS.STEPS.CREATE_TOKEN,
      centeredFields: true,
      name: 'createToken',
      options: { ...headerWithHelperOptions({ field: 'createToken' }), ...booleanOptions() },
    },
    {
      field: VALUE_CHAIN_FIELDS.STEPS.UPDATED_DATA_WITH,
      name: 'ingestionType',
      options: {
        ...headerWithHelperOptions({ field: 'updatedDataWith' }),
        ...objectKeyOptions({ key: 'name', withTranslation: true, t: t }),
      },
    },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.ACTIONS,
      centeredFields: true,
      name: 'ACTIONS',
      hideInTable: true,
      options: headerWithHelperOptions({ field: 'actions' }),
    },
  ].map(step => ({
    ...step,
    label: t(getHeaderNameText({ formName: currentFormName, field: step.field })),
  }));

  const handleDeleteSteps = (stepsToDelete, index, remove) => {
    if (stepsToDelete?.stepId) {
      stepIdsToDelete.push(stepsToDelete.stepId);
    }
    remove(index);
  };

  const buildFields = remove => {
    return stepArray.map((item, index) => ({
      name: `stepArray.${index}`,
      component: (
        // eslint-disable-next-line react/no-array-index-key
        <FastField
          name={`stepArray.${index}`}
          component={StepContent}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onRemove={() => handleDeleteSteps(item, index, remove)}
        />
      ),
      keyValue: index.toString(),
    }));
  };

  const areStepsDefined = stepArray.length >= 1;

  const addBanner = areStepsDefined
    ? t('traceability.configurationWizard.steps.addMoreSteps')
    : t('traceability.configurationWizard.steps.addStep');

  let errorsToPrint;
  if (errors.stepArray && !Array.isArray(errors?.stepArray)) {
    errorsToPrint = errors?.stepArray;
  }

  return (
    <div className={classes.stepForm}>
      {userNotAllowedToViewContent ? (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('traceability.forbiddenContent')}
          </Typography>
        </Paper>
      ) : (
        <>
          <WarningDialog show={warning} toggle={toggleWarning} />
          <Typography variant="body1">
            {t('traceability.valueChainStepsForm.description')}
          </Typography>
          {isEditMode && stepArray.length > 0 && (
            <div className={classes.stepHelpers}>
              <WizardHelper
                formName={currentFormName}
                orderedFields={columnDefs.map(({ field }) => field)}
                centeredFields={columnDefs
                  .filter(({ centeredFields }) => centeredFields)
                  .map(({ field }) => field)}
              />
            </div>
          )}
          <FormikProvider value={formik}>
            {!!errorsToPrint && userAllowedToEdit && (
              <div>
                <Box
                  display="flex"
                  flexDirection="row"
                  p={2}
                  my={2}
                  bgcolor="background.paper"
                  className={classes.root}
                >
                  <Box pr={2}>
                    <ErrorIcon color="error" />
                  </Box>
                  <Box>{errorsToPrint}</Box>
                </Box>
              </div>
            )}
            <Form onSubmit={formik.handleSubmit}>
              <FieldArray name="stepArray">
                {({ push, remove }) => (
                  <>
                    {isEditMode && (
                      <>
                        {areStepsDefined && (
                          <DragBoard
                            boardName="stepsBoard"
                            onChange={handleOnChange}
                            boardItems={buildFields(remove)}
                            loadingMessage={t('traceability.configurationWizard.steps.loading')}
                            noItemsMessage={t('traceability.configurationWizard.steps.addAnyStep')}
                          />
                        )}
                        <AddArea onClick={() => push(emptyStep)} bannerText={addBanner} />
                      </>
                    )}

                    {!isEditMode && (
                      <DataStructureView
                        columnDefs={columnDefs.filter(({ hideInTable }) => !hideInTable)}
                        formValues={stepRows}
                        viewEditMode={viewEditMode}
                        hideEdit={
                          !userAllowedToEdit &&
                          lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code
                        }
                      />
                    )}
                  </>
                )}
              </FieldArray>
              <WizardButtonArea
                isEditMode={isEditMode}
                setEditMode={setEditMode}
                isCancelDisabled={
                  stepRows?.length === 0 ||
                  lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.code
                }
                errors={formik.errors}
                formik={formik}
                isProcessing={isProcessing}
                optionalStep={!formik.dirty && isEmpty(formik.errors) && stepRows?.length > 0}
              />
              {isEditMode && (
                <Typography variant="body2" className={classes.instructions}>
                  {`${t('traceability.valueChainStepsForm.instructions')}(`}
                  <span className={classes.mandatoryFieldMarker}>*</span>
                  {`) ${t('traceability.instructionsPartTwo')}`}
                </Typography>
              )}
            </Form>
          </FormikProvider>
        </>
      )}
    </div>
  );
};
export default StepForm;
