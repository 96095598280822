import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useFormik, FormikProvider, Form, FastField, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import TableCell from '@material-ui/core/TableCell';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import uniqid from 'uniqid';
import WizardHelper, { getHeaderNameText, getTooltipsText } from './WizardHelper';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { useNotification } from '../../../contexts/Shared/notification';
import ItemRowV2 from './ItemRow';
import AddArea from '../AddArea';
import { CREATE_ITEM, UPDATE_ITEM, DELETE_ITEMS } from '../../../graphql/Traceability/items';
import {
  TRACEABILITY_DISPATCHER_ACTIONS,
  VALUE_CHAIN_FIELDS,
  VALUE_CHAIN_FORMS,
  TRACE_WIZARD_PROGRESSION_V2,
} from '../../../constants';
import DataStructureView from './DataStructureView';
import { handleNavigation, objectKeyOptions, useEditMode } from './traceabilityUtils';
import WizardTooltip from './WizardTooltip';
import WizardButtonArea from './WizardButtonArea';
import useUserInfo from '../../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  itemsFormRoot: {
    width: '100%',
    minWidth: '1000px',
  },
  formArea: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1em',
    marginBottom: '1em',
  },
  itemHelpers: {
    display: 'grid',
    gridTemplateColumns: '24% 24% 24%  8% 11% 5%',
    margin: '0.5em 0',
    padding: theme.spacing(1),
    gridGap: '0.5%',
  },
  fieldName: {
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
  },
  tooltipIcon: {
    height: '15px',
    width: '15px',
  },
  centeredField: {
    textAlign: 'center',
  },
  mandatoryFieldMarker: {
    color: theme.palette.error.main,
    padding: 0,
    margin: 0,
  },
  instructions: {
    marginTop: theme.spacing(2),
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
}));

const ItemsFormV2 = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const noneField = 'traceability.traceItemForm.none';

  const {
    instanceId,
    instanceDetails,
    codificationTypes,
    itemTypes,
    instanceDispatcher,
    setIsDataLoading,
    lastCompletedIteration,
  } = useConfigurationWizardContext();

  const { handleNotification } = useNotification();

  const { items: itemRows } = instanceDetails?.content;
  const { codificationTypes: codRows } = codificationTypes;
  const { itemTypes: itemTypeRows } = itemTypes;
  const [isEditMode, setEditMode] = useEditMode(
    TRACE_WIZARD_PROGRESSION_V2.NEW_WIZARD.code,
    lastCompletedIteration,
  );
  const [isProcessing, setProcessing] = useState(false);
  const viewEditMode = () => setEditMode(true);
  const [dependsOnOptions, setDependsOnOptions] = useState([]);
  const {
    permissionsFlags: { isUserAuthToEditValueChain, isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const userNotAllowedToViewContent =
    lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
    !isUserAuthToViewValueChainDetails;

  const userAllowedToEdit = isUserAuthToEditValueChain && instanceDetails?.content?.isOwner;

  useEffect(() => {
    if (dependsOnOptions.length !== itemRows?.length + 1) {
      const options = [{ label: t(noneField), value: noneField }];
      setDependsOnOptions(
        options.concat(itemRows.map(itemRow => ({ label: itemRow.name, value: itemRow._id }))),
      );
    }
  }, [itemRows]);

  const [addItem] = useMutation(CREATE_ITEM);
  const [updateItem] = useMutation(UPDATE_ITEM);
  const [deleteItems] = useMutation(DELETE_ITEMS);

  const [itemsToDelete, setItemsToDelete] = useState([]);

  const codificationOptions = [];
  const itemTypeOptions = [];

  if (codRows) {
    codRows.forEach(codType => {
      codificationOptions.push({ label: codType.name, value: codType._id });
    });
  }

  if (itemTypeRows) {
    itemTypeRows.forEach(itemTypeRow => {
      itemTypeOptions.push({ label: itemTypeRow.name, value: itemTypeRow._id });
    });
  }

  const generateTempId = () => {
    const date = new Date();
    return `temp-${date.valueOf()}`;
  };

  const emptyItem = {
    itemName: '',
    itemType: itemTypeOptions && itemTypeOptions.length > 0 ? itemTypeOptions[0].value : null,
    dependsOn: noneField,
    codifiedWith:
      codificationOptions && codificationOptions.length > 0 ? codificationOptions[0].value : null,
    tokenize: true,
    tokenContract: {
      contractId: '',
      contractName: '',
    },
    dirty: false,
    isNew: true,
  };

  const generateInitialForm = () => {
    const initItemArray = [];

    itemRows.forEach(tempItem => {
      initItemArray.push({
        itemName: tempItem?.name,
        itemType: tempItem?.itemType?._id,
        dependsOn: tempItem?.parentItem?._id ? tempItem?.parentItem?._id : noneField,
        codifiedWith: tempItem?.codificationType?._id,
        tokenize: tempItem?.tokenize,
        itemId: tempItem?._id,
        tokenContract: tempItem?.tokenContract
          ? {
              contractName: tempItem?.tokenContract.contractName,
              contractId: tempItem?.tokenContract._id,
            }
          : null,
        dirty: false,
        isNew: false,
      });
    });

    return initItemArray;
  };

  const itemValidationSchema = Yup.object().shape({
    itemArray: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required(t('traceability.traceItemForm.errors.itemName')),
          itemType: Yup.string().required(t('traceability.traceItemForm.errors.itemType')),
          dependsOn: Yup.string(),
          codifiedWith: Yup.string().required(t('traceability.traceItemForm.errors.codType')),
          tokenize: Yup.bool().required(t('traceability.traceItemForm.errors.tokenize')),
          tokenContract: Yup.object().when('tokenize', {
            is: true,
            then: Yup.object().shape({
              contractId: Yup.string().required(
                t('traceability.traceItemForm.errors.smartContract'),
              ),
            }),
            otherwise: Yup.object().nullable(),
          }),
        }),
      )
      .min(1, t('traceability.traceItemForm.errors.minLength')),
  });

  const createNewItems = async itemArray => {
    const newItems = [];
    const newIdsMap = {
      [noneField]: null,
    };
    for (let i = 0; i < itemArray.length; i += 1) {
      const item = itemArray[i];
      const {
        itemName,
        itemType,
        codifiedWith,
        itemId,
        dirty,
        tokenize,
        tokenContract,
        isNew,
      } = item;

      if (isNew && dirty) {
        const newItem = await addItem({
          variables: {
            input: {
              name: itemName,
              tokenize: tokenize,
              instance: instanceId,
              itemType: itemType,
              codificationType: codifiedWith,
              parentItem: null,
              tokenContract: tokenize ? tokenContract?.contractId : null,
            },
          },
        });
        const newId = newItem?.data?.createItem?._id;
        newIdsMap[itemId] = newId;
        item.itemId = newId;
        newItems.push(newItem?.data?.createItem);
      }
    }
    return {
      newItemsRows: newItems,
      idsMap: newIdsMap,
    };
  };

  const updateItems = async (itemArray, idsMap, oldItemRows, newItemsRows) => {
    let updatedItemRows = oldItemRows.concat(newItemsRows);
    for (let j = 0; j < itemArray.length; j += 1) {
      const item = itemArray[j];
      const {
        itemName,
        itemType,
        codifiedWith,
        dependsOn,
        itemId,
        dirty,
        tokenize,
        tokenContract,
        isNew,
      } = item;

      if (dirty && (!isNew || (isNew && dependsOn))) {
        const updatedItem = await updateItem({
          variables: {
            input: {
              _id: itemId,
              name: itemName,
              tokenize: tokenize,
              instance: instanceId,
              itemType: itemType,
              codificationType: codifiedWith,
              parentItem: dependsOn in idsMap ? idsMap[dependsOn] : dependsOn,
              tokenContract: tokenize ? tokenContract?.contractId : null,
            },
          },
        });
        updatedItemRows = updatedItemRows.map(itemRow =>
          itemRow._id === updatedItem?.data?.updateItem?._id
            ? updatedItem?.data?.updateItem
            : itemRow,
        );
      }
      item.isNew = false;
    }
    return updatedItemRows;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      itemArray: generateInitialForm(),
    },
    validationSchema: itemValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsDataLoading(true);
        setProcessing(true);
        const { itemArray } = values;

        const { newItemsRows, idsMap } = await createNewItems(itemArray);
        let updatedItemRows = await updateItems(itemArray, idsMap, itemRows, newItemsRows);
        updatedItemRows = updatedItemRows.filter(item => !itemsToDelete.includes(item.itemId));
        instanceDispatcher({
          type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_ITEMS,
          payload: updatedItemRows,
        });

        if (itemsToDelete.length > 0) {
          await deleteItems({
            variables: {
              input: {
                idList: itemsToDelete,
                instance: instanceId,
              },
            },
          });
          instanceDispatcher({
            type: TRACEABILITY_DISPATCHER_ACTIONS.DELETE_ITEMS,
            payload: itemsToDelete,
          });
        }
        setItemsToDelete([]);
        handleNotification(t('traceability.traceItemForm.success'), 'success');
        resetForm({ values });
        handleNavigation(history, instanceId);
      } catch (err) {
        handleNotification(err?.message, 'error');
      } finally {
        setProcessing(false);
        setIsDataLoading(false);
      }
    },
  });

  const { values: formValues } = formik;

  const handleDeleteItem = (itemToDelete, index, remove) => {
    formValues.itemArray.forEach((item, i) => {
      if (item.dependsOn === itemToDelete?.itemId) {
        formik.setFieldValue(`itemArray.${i}`, {
          ...item,
          dependsOn: noneField,
          dirty: true,
        });
      }
    });
    setDependsOnOptions(dependsOnOptions.filter(option => option?.value !== itemToDelete?.itemId));

    if (!itemToDelete.isNew) {
      setItemsToDelete(itemsToDelete.concat(itemToDelete.itemId));
    }

    remove(index);
  };

  const updateDependencies = (newName, id) => {
    let found = false;
    const newDependencies = dependsOnOptions.map(option => {
      if (option.value === id) {
        found = true;
        return { label: newName, value: option.value };
      }
      return option;
    });
    if (!found) {
      newDependencies.push({
        label: newName,
        value: id,
      });
    }
    setDependsOnOptions(newDependencies);
  };

  const currentFormName = VALUE_CHAIN_FORMS.ITEM;

  const booleanOptions = () => ({
    customBodyRender: data => (data ? t('tokens.yes') : t('tokens.no')),
  });

  const headerWithHelperOptions = ({ field }) => ({
    customHeadRender: columnMeta => (
      <TableCell key={uniqid()}>
        {columnMeta?.label}
        <WizardTooltip text={t(getTooltipsText({ formName: currentFormName, field }))}>
          <InfoOutlined className={classes.tooltipIcon} />
        </WizardTooltip>
      </TableCell>
    ),
  });

  const columnDefs = [
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.ITEM_NAME,
      name: 'name',
      options: headerWithHelperOptions({ field: 'itemName' }),
    },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.ITEM_TYPE,
      name: 'itemType',
      options: {
        ...headerWithHelperOptions({ field: 'itemType' }),
        ...objectKeyOptions({ key: 'name', withTranslation: true, t: t }),
      },
    },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.DEPENDS_ON,
      name: 'parentItem',
      options: {
        ...headerWithHelperOptions({ field: 'dependsOn' }),
        ...objectKeyOptions({ key: 'name', t: t }),
      },
    },
    // {
    //   field: VALUE_CHAIN_FIELDS.ITEMS.COD_WITH,
    //   name: 'codificationType',
    //   options: {
    //     ...headerWithHelperOptions({ field: 'codType' }),
    //     ...objectKeyOptions({ key: 'name', withTranslation: true, t: t }),
    //   },
    // },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.TOKENIZE,
      centeredFields: true,
      name: 'tokenize',
      options: { ...headerWithHelperOptions({ field: 'tokenize' }), ...booleanOptions() },
    },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.SMARTCONTRACT,
      centeredFields: true,
      name: 'tokenContract',
      options: {
        ...headerWithHelperOptions({ field: 'smartContract' }),
        ...objectKeyOptions({ key: 'contractName', t: t }),
      },
    },
    {
      field: VALUE_CHAIN_FIELDS.ITEMS.ACTIONS,
      centeredFields: true,
      name: 'ACTIONS',
      hideInTable: true,
      options: headerWithHelperOptions({ field: 'actions' }),
    },
  ].map(item => ({
    ...item,
    label: t(getHeaderNameText({ formName: 'traceItemForm', field: item.field })),
  }));

  const buildFields = remove => {
    const { itemArray } = formValues;
    const fields = itemArray.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <FastField
        name={`itemArray.${index}`}
        value={item}
        component={ItemRowV2}
        index={index}
        key={item.itemId}
        dependsOnOptions={dependsOnOptions}
        itemTypeOptions={itemTypeOptions}
        updateDependencies={updateDependencies}
        shouldUpdate={() => true}
        onRemove={() => handleDeleteItem(item, index, remove)}
        instanceId={instanceId}
      />
    ));

    return fields;
  };

  const buildAddArea = push => {
    const { itemArray } = formValues;
    const addBanner =
      itemArray.length < 1
        ? t('traceability.traceItemForm.addRow')
        : t('traceability.traceItemForm.addMoreRow');

    return (
      <AddArea
        onClick={() => {
          emptyItem.itemId = generateTempId();
          push(emptyItem);
        }}
        bannerText={addBanner}
      />
    );
  };

  return (
    <div className={classes.itemsFormRoot}>
      {userNotAllowedToViewContent ? (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('traceability.forbiddenContent')}
          </Typography>
        </Paper>
      ) : (
        <>
          <Typography variant="body1">{t('traceability.traceItemForm.description')}</Typography>
          {isEditMode && (
            <div className={classes.itemHelpers}>
              <WizardHelper
                formName={currentFormName}
                orderedFields={columnDefs.map(({ field }) => field)}
                centeredFields={columnDefs
                  .filter(({ centeredFields }) => centeredFields)
                  .map(({ field }) => field)}
              />
            </div>
          )}
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FieldArray name="itemArray">
                {({ push, remove }) => (
                  <>
                    {isEditMode && (
                      <>
                        {buildFields(remove)}
                        {buildAddArea(push)}
                      </>
                    )}

                    {!isEditMode && (
                      <DataStructureView
                        columnDefs={columnDefs.filter(({ hideInTable }) => !hideInTable)}
                        formValues={itemRows}
                        viewEditMode={viewEditMode}
                        hideEdit={
                          !userAllowedToEdit &&
                          lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code
                        }
                      />
                    )}
                  </>
                )}
              </FieldArray>
              <WizardButtonArea
                isEditMode={isEditMode}
                isCancelDisabled={itemRows?.length === 0}
                setEditMode={setEditMode}
                errors={formik.errors}
                formik={formik}
                isProcessing={isProcessing}
              />
              {isEditMode && (
                <Typography variant="body2" className={classes.instructions}>
                  {`${t('traceability.traceItemForm.instructions')}(`}
                  <span className={classes.mandatoryFieldMarker}>*</span>
                  {`) ${t('traceability.instructionsPartTwo')}`}
                </Typography>
              )}
            </Form>
          </FormikProvider>
        </>
      )}
    </div>
  );
};

export default ItemsFormV2;
