import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { convertFileToData } from '../../utils/XLSXutils';

const FileToDataContext = React.createContext({
  convertFile: null,
  fileTableData: [],
  fileTableHeader: [],
  fileTableCol: [],
  convertFileToData: () => {},
  resetFileTable: () => {},
});

function FileToDataProvider({ children }) {
  const [convertFile, setConvertFile] = useState(null);
  const [fileTableData, setFileTableData] = useState([]);
  const [fileTableHeader, setFileTableHeader] = useState([]);
  const [fileTableCol, setFileTableCol] = useState([]);
  const [loadingConvertFileToData, setLoadingConvertFileToData] = useState(false);

  const contextValue = {
    convertFile,
    fileTableData: fileTableData.map(item => {
      const row = item;
      Object.keys(row).forEach(key => {
        if (row[key] instanceof Date) {
          row[key] =
            dayjs(row[key])
              .add(43, 's')
              .valueOf() / 1000;
        }
      });
      return row;
    }),
    fileTableHeader,
    fileTableCol,
    loadingConvertFileToData,
    convertFileToData: async file => {
      setLoadingConvertFileToData(true);
      setConvertFile(file);
      const { header, data, col } = await convertFileToData(file);
      setFileTableData(data);
      setFileTableHeader(header);
      setFileTableCol(col);
      setLoadingConvertFileToData(false);
      return { header, data, col };
    },
    resetFileTable: () => {
      setConvertFile(null);
      setFileTableData([]);
      setFileTableHeader([]);
      setFileTableCol([]);
    },
  };

  return <FileToDataContext.Provider value={contextValue}>{children}</FileToDataContext.Provider>;
}

FileToDataProvider.propTypes = { children: PropTypes.node.isRequired };

function useFileToData() {
  return useContext(FileToDataContext);
}

export { useFileToData, FileToDataProvider };
