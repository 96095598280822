import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import Divider from '@material-ui/core/Divider';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: theme.colors.grey,
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  mintButton: {
    backgroundColor: theme.colors.white,
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
}));

const CreateContractDialog = ({ open, closeModal, onSubmit, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      contractName: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      const res = await onSubmit({ variables: values });
      if (res) {
        closeModal();
      }
      resetForm();
    },
    validationSchema: Yup.object().shape({
      contractName: Yup.string().required(t('notarization.validation.enterContractName')),
    }),
  });

  const closeModalFn = () => {
    formik.resetForm();
    closeModal();
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('notarization.createNewContract')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Field
              component={TextField}
              label={t('notarization.contractName')}
              value={formik.values.contractName || ''}
              name="contractName"
            />
            <FormErrors form={formik} />
            <BlockchainInfo
              validateForm={formik.validateForm}
              values={formik.values}
              contractName={CONSTANTS.SMARTCONTRACT_TYPES.NOTARIZATION}
              contractAddress=""
              method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
              methodArgs={[formik.values.contractName]}
            />
            <Divider className={classes.divider} />
            <Button
              variant="contained"
              onClick={closeModalFn}
              color="primary"
              className={classes.mintButton}
            >
              {t('common.cancel')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.mintButton}
              disabled={!formik.values.contractName || loading}
            >
              {t('notarization.btnCreate')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

CreateContractDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CreateContractDialog;
