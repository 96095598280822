import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@apollo/react-hooks';
import { findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GET_SMART_CONTRACT_PERMISSION_UPDATED } from '../../graphql/Tokenization/token';
import { CONSTANTS } from '../../constants';
import { useTokenContext } from '../Tokenization/token';
import { useNotarizationContext } from '../Notarization/notarizationContext';
import { useNotification } from './notification';

const CollaboratorContext = React.createContext([{}, () => {}]);

const CollaboratorProvider = ({ children }) => {
  const { tokenContracts, setTokenContracts } = useTokenContext();
  const { handleNotification } = useNotification();
  const { notarizationContracts, setNotarizationContracts } = useNotarizationContext();
  const [chainUpdatingStatus, setChainUpdatingStatus] = useState(false);
  const [partnerUpdateType, setPartnerUpdateType] = useState();
  const { t } = useTranslation();

  const showNotification = () => {
    switch (partnerUpdateType) {
      case CONSTANTS.PARTNER_UPDATE_TYPE.ADD:
        handleNotification(t('tokens.partnerAddSuccess'), 'success');
        break;
      case CONSTANTS.PARTNER_UPDATE_TYPE.REMOVE:
        handleNotification(t('tokens.partnerRemoveSuccess'), 'success');
        break;
      case CONSTANTS.PARTNER_UPDATE_TYPE.UPDATE:
        handleNotification(t('tokens.roleUpdateSuccess'), 'success');
        break;
      default:
        break;
    }
  };

  useSubscription(GET_SMART_CONTRACT_PERMISSION_UPDATED, {
    onSubscriptionData: data => {
      if (
        data.subscriptionData?.data?.smartContractPermissionsUpdated?.tokenType ===
          CONSTANTS.SMARTCONTRACT_TYPES.ERC721 ||
        data.subscriptionData?.data?.smartContractPermissionsUpdated?.tokenType ===
          CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
      ) {
        if (tokenContracts) {
          const newState = [...tokenContracts];
          const responseToken = data.subscriptionData?.data?.smartContractPermissionsUpdated;
          setChainUpdatingStatus(false);
          const index = findIndex(newState, {
            contract: { contractAddress: responseToken?.contractAddress },
          });
          if (newState[index]) {
            newState[index].contract.collaborators = responseToken.collaborators;
            setTokenContracts(newState);
          }
          showNotification();
        }
      } else if (
        data.subscriptionData?.data?.smartContractPermissionsUpdated?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.NOTARIZATION
      ) {
        if (notarizationContracts) {
          const newState = [...notarizationContracts];
          const responseToken = data.subscriptionData?.data?.smartContractPermissionsUpdated;
          setChainUpdatingStatus(false);
          const index = findIndex(newState, {
            contractAddress: responseToken?.contractAddress,
          });
          if (newState[index]) {
            newState[index].collaborators = responseToken.collaborators;
            setNotarizationContracts(newState);
          }
          showNotification();
        }
      }
    },
  });

  return (
    <CollaboratorContext.Provider
      value={{
        chainUpdatingStatus,
        setChainUpdatingStatus,
        setPartnerUpdateType,
      }}
    >
      {children}
    </CollaboratorContext.Provider>
  );
};

const useCollaboratorContext = () => useContext(CollaboratorContext);

CollaboratorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useCollaboratorContext, CollaboratorProvider };
