import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { Box, Button, CircularProgress, Divider, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { ethers } from 'ethers';
import { Field, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import AutocompleteDropDown from '../../components/Shared/AutocompleteDropDown';
import BatchTransferErc1155Token from '../../components/Tokenization/BatchTransferErc1155Token';
import { CONSTANTS } from '../../constants';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import { useTokenContext } from '../../contexts/Tokenization/token';
import { getWalletAddress } from '../../utils';
import BulkTransferTokenRow from './BulkTransferTokenRow';
import TransferToolTip from '../../components/Tokenization/TransferTooltip';

const useStyles = makeStyles(theme => ({
  rootStyle: {
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px',
    },
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  preTitle: {
    fontWeight: '200',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '.875rem',
  },
  preTitleName: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  formConatiner: {
    maxWidth: '44%',
  },
  addressConatiner: {
    maxWidth: '70%',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginRight: theme.spacing(2),
  },

  boldFont: {
    fontWeight: 'bold',
  },

  recipientOrganization: {
    flexBasis: '94%',
  },

  spinner: {
    marginLeft: '10px',
  },
  checkField: {
    marginTop: '1rem',
    alignItems: 'center',
  },
  gridCcontainer: {
    display: 'grid',
    gridTemplateColumns: '50% 45% 10%',
    columnGap: '10px',
    width: '100%',
  },
}));

const BulkTransfer = ({
  setBulkTransferErc1155Data,
  displayActiveContract,
  selectedTokensForTransfer,
  processType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    selectedContract,
    transferErc1155TokenEth,
    isTransferErc1155Processing,
    burnErc1155Tokens,
    isErc1155BurnLoading,
  } = useTokenContext();
  const { activeWallet } = useBecOrganizationContext();
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  const { getPartners, partners, getPartnersLoading } = usePartnerContext();
  const [batchTransferModalOpen, setBatchTransferModalOpen] = useState(false);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    const companySelectOptionsTemp = partners.map(partner => {
      return {
        label: partner.partnerOrganization.name,
        value: getWalletAddress(partner.partnerOrganization.wallets, activeWallet),
      };
    });
    setCompanySelectOptions(companySelectOptionsTemp);
  }, [partners, activeWallet]);

  const handleNavigationToTokensClick = () => {
    setBulkTransferErc1155Data(prevState => ({ ...prevState, display: false }));
    displayActiveContract(selectedContract?.contract?._id);
  };

  const handleClose = () => {
    handleNavigationToTokensClick();
  };

  const generateInitialValues = () => {
    const tokenArray = selectedTokensForTransfer.map(token => ({
      tokenId: token?.tokenId,
      totalBalance: token?.owners1155[0]?.balance,
      amount: token?.owners1155[0]?.balance,
    }));
    const recipientEthereumAddress = {
      label: '',
      value: '',
    };
    const externalRecipient = false;
    const externalRecipientAddress = '';
    const transferReason = '';
    return {
      recipientEthereumAddress,
      tokenArray,
      externalRecipient,
      externalRecipientAddress,
      transferReason,
    };
  };

  const validateMaximumAmount = values => {
    const amountErrors = values?.tokenArray?.map(val => {
      if (parseInt(val?.amount, 10) > val?.totalBalance)
        return {
          amount:
            processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
              ? t('tokens.validation.transfer.maxAmount')
              : t('tokens.validation.transfer.maxAmountDelete'),
        };
      return {};
    });

    const errors = amountErrors?.every(obj => Object.keys(obj).length === 0)
      ? {}
      : { tokenArray: [...amountErrors] };
    return errors;
  };

  const formik = useFormik({
    initialValues: generateInitialValues(),
    onSubmit: () => {
      setBatchTransferModalOpen(true);
    },
    validate: validateMaximumAmount,
    validationSchema: Yup.object().shape({
      recipientEthereumAddress: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().when(processType, {
          is: value => value === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER,
          then: Yup.string().when('externalRecipient', {
            is: false,
            then: Yup.string()
              .required(t('tokens.validation.recipientEthereumAddress'))
              .test('is-valid-eth-address', t('tokens.validation.ethereumAddress'), val => {
                try {
                  return ethers.utils.getAddress(val) && true;
                } catch (e) {
                  return false;
                }
              })
              .test(
                'is-not-own-eth-address',
                t('tokens.validation.sameEthereumAddress'),
                val => val !== activeWallet?.address,
              ),
          }),
          otherwise: Yup.string(),
        }),
      }),
      tokenArray: Yup.array().of(
        Yup.object().shape({
          amount: Yup.number()
            .required(t('tokens.validation.amountRequired'))
            .min(1, t('tokens.validation.transferMinAmount', { min: 1 })),
        }),
      ),
      externalRecipientAddress: Yup.string().when('externalRecipient', {
        is: true,
        then: Yup.string()
          .required(t('tokens.validation.recipientEthereumAddress'))
          .matches(/^0x[a-fA-F0-9]{40}$/, t('tokens.validation.validEthereumAddress')),
      }),
      transferReason: Yup.string(),
    }),
  });

  const handleBatchTransfer = async () => {
    if (processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER) {
      let batchTransferReq;
      if (formik.values.externalRecipient) {
        batchTransferReq = {
          variables: {
            input: {
              contractAddress: selectedContract?.contract?.contractAddress,
              tokens: formik?.values?.tokenArray?.map(({ totalBalance, ...rest }) => ({
                ...rest,
                amount: parseInt(rest.amount, 10),
              })),
              to: formik?.values?.externalRecipientAddress,
              reason: formik?.values?.transferReason,
              becOverride: true,
            },
          },
        };
      } else {
        batchTransferReq = {
          variables: {
            input: {
              contractAddress: selectedContract?.contract?.contractAddress,
              tokens: formik?.values?.tokenArray?.map(({ totalBalance, ...rest }) => ({
                ...rest,
                amount: parseInt(rest.amount, 10),
              })),
              to: formik?.values?.recipientEthereumAddress?.value,
              reason: formik?.values?.transferReason,
            },
          },
        };
      }
      const res = await transferErc1155TokenEth(batchTransferReq);
      setBatchTransferModalOpen(false);
      if (res?.data?.transferERC1155Token?.transactionHash) {
        handleNavigationToTokensClick();
      }
    }
    if (processType === CONSTANTS.TRANSACTION_TYPES.BATCH_BURN) {
      const batchDeleteReq = {
        variables: {
          contractAddress: selectedContract?.contract?.contractAddress,
          tokens: formik?.values?.tokenArray?.map(({ totalBalance, ...rest }) => ({
            ...rest,
            amount: parseInt(rest.amount, 10),
          })),
        },
      };
      const burnResponse = await burnErc1155Tokens(batchDeleteReq);
      setBatchTransferModalOpen(false);
      if (burnResponse?.data?.burnERC1155Token?.transactionHash) {
        handleNavigationToTokensClick();
      }
    }
  };
  const valueChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    switch (modifiedField) {
      case 'externalRecipient':
        formik.setFieldValue('externalRecipient', event.target.checked);
        if (event.target.checked) {
          formik.setFieldValue('recipientEthereumAddress', { label: '', value: '' });
        }
        break;
      case 'externalRecipientAddress':
        formik.setFieldValue('externalRecipientAddress', newValue);
        break;
      default:
        break;
    }
  };
  const isButtonDisabled = () => {
    let isDisabled = true;
    if (!isTransferErc1155Processing) {
      if (
        (formik.values.recipientEthereumAddress?.value || formik.values.externalRecipientAddress) &&
        formik.isValid &&
        formik.values.tokenArray.length !== 0
      ) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <div className={classes.rootStyle}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.navContainer}
      >
        <Button
          variant="text"
          color="primary"
          onClick={handleNavigationToTokensClick}
          className={classes.navButton}
          startIcon={<ArrowBackIosIcon />}
        >
          {t('headers.token_plural')}
        </Button>
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Box component="div" p={2}>
        <Typography className={classes.preTitle} variant="h5">
          {t('headers.contract')}
          <span className={classes.preTitleName}>{` ${selectedContract.tokenName}`}</span>
        </Typography>
        {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER && (
          <Typography className={classes.sectionTitle} variant="h5">
            {t('tokens.transferTokens')}
          </Typography>
        )}
        {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_BURN && (
          <Typography className={classes.sectionTitle} variant="h5">
            Delete Tokens
          </Typography>
        )}
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Box component="div" p={2}>
        <Typography variant="body1">
          {t('tokens.batchTransfer.subHeader.part1')}{' '}
          <span className={classes.boldFont}>{formik?.values?.tokenArray?.length}</span>{' '}
          {t('tokens.batchTransfer.subHeader.part2')}{' '}
          <span className={classes.boldFont}>{selectedTokensForTransfer?.length}</span>
        </Typography>
      </Box>
      <Box p={2}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER && (
              <>
                <div className={classes.checkField}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="externalRecipient"
                        checked={formik.values.externalRecipient}
                        color="primary"
                        onChange={valueChangeHandler}
                      />
                    }
                    label={
                      <>
                        {t('tokens.externalRecipient')}
                        <TransferToolTip />
                      </>
                    }
                  />
                </div>
                <Box
                  sx={{ display: 'flex', alignItems: 'center' }}
                  className={classes.addressConatiner}
                >
                  <div className={classes.gridCcontainer}>
                    <div>
                      {!formik.values.externalRecipient && (
                        <>
                          <Field
                            component={AutocompleteDropDown}
                            label={t('common.recipient')}
                            options={companySelectOptions}
                            value={formik.values.recipientEthereumAddress}
                            name="recipientEthereumAddress"
                            disabled={!companySelectOptions.length}
                            disableClearable
                            className={classes.recipientOrganization}
                          />
                        </>
                      )}
                      {formik.values.externalRecipient && (
                        <Field
                          component={TextField}
                          label="Recipient address"
                          value={formik.values.externalRecipientAddress}
                          name="externalRecipientAddress"
                          customInput={TextField}
                          onChange={valueChangeHandler}
                          error={formik.errors && !!formik.errors.externalRecipientAddress}
                          helperText={formik.errors && formik.errors.externalRecipientAddress}
                        />
                      )}
                    </div>
                    <div>
                      <Field
                        component={TextField}
                        label={t('tokens.transferReason')}
                        value={formik.values.transferReason || ''}
                        name="transferReason"
                      />
                    </div>
                    <div>
                      {getPartnersLoading && <CircularProgress className={classes.spinner} />}
                    </div>
                  </div>
                </Box>
              </>
            )}
            <Box className={classes.formConatiner}>
              <FieldArray
                name="tokenArray"
                render={arrayHelpers => <BulkTransferTokenRow arrayHelpers={arrayHelpers} />}
              />
              <Box component="div" mt={2}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classes.cancelButton}
                >
                  {t('common.cancel')}
                </Button>
                {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isButtonDisabled()}
                  >
                    {t('tokens.transfer')} ({formik?.values?.tokenArray?.length})
                  </Button>
                )}

                {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_BURN && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                      Object.keys(formik?.errors).length !== 0 ||
                      formik.values.tokenArray.length === 0 ||
                      isTransferErc1155Processing
                    }
                  >
                    {t('tokens.delete')} ({formik?.values?.tokenArray?.length})
                  </Button>
                )}
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </Box>

      <BatchTransferErc1155Token
        open={batchTransferModalOpen}
        closeModal={() => setBatchTransferModalOpen(false)}
        onSubmit={handleBatchTransfer}
        contractAddress={selectedContract?.contract?.contractAddress}
        loading={
          processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
            ? isTransferErc1155Processing
            : isErc1155BurnLoading
        }
        tokens={formik?.values?.tokenArray}
        recipientOrganizationName={formik?.values?.recipientEthereumAddress?.label}
        smartContractName={selectedContract?.contract?.contractName}
        processType={processType}
        // TODO: need to add a check for private token
        isPrivate={false}
      />
    </div>
  );
};

BulkTransfer.propTypes = {
  setBulkTransferErc1155Data: PropTypes.func.isRequired,
  selectedTokensForTransfer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayActiveContract: PropTypes.func,
  processType: PropTypes.string.isRequired,
};

BulkTransfer.defaultProps = {
  displayActiveContract: () => {},
};

export default BulkTransfer;
