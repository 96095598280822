import React from 'react';
import { Route } from 'react-router-dom';
import Traceability from '../pages/Traceability/Overview';
import InstanceTokens from '../pages/Traceability/InstanceTokens';
import Items from '../pages/Traceability/Items';
import Steps from '../pages/Traceability/Steps';
import Metadata from '../pages/Traceability/Metadata';
import { subscriptionRequired } from '../components/Shared/SubscriptionsPartial';
import InstanceLanding from '../pages/Traceability/InstanceLanding';
import { ConfigurationWizardProvider } from '../contexts/Traceability/configurationWizard';
import { IngestionContextProvider } from '../contexts/Traceability/ingestion';
import Permissions from '../pages/Traceability/Permissions';
import PortalLanding from '../pages/Traceability/PortalLanding';
import PortalHistory from '../pages/Traceability/PortalHistory';
import SummaryView from '../pages/Traceability/SummaryView';

const traceabilityRoutes = traceabilityAccess => (
  <ConfigurationWizardProvider>
    <IngestionContextProvider>
      <Route
        path="/traceability"
        component={subscriptionRequired(Traceability, traceabilityAccess)}
      >
        <Route
          exact
          path="/traceability/overview"
          component={subscriptionRequired(Traceability, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/items/:id"
          component={subscriptionRequired(Items, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/steps/:id"
          component={subscriptionRequired(Steps, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/permissions/:id"
          component={subscriptionRequired(Permissions, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/metadata/:id"
          component={subscriptionRequired(Metadata, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/instance-review/:id"
          component={subscriptionRequired(InstanceLanding, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/data-gathering-portal/:id"
          component={subscriptionRequired(PortalLanding, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/ingestion-history/:id"
          component={subscriptionRequired(PortalHistory, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/instance-tokens/:id"
          component={subscriptionRequired(InstanceTokens, traceabilityAccess)}
        />
        <Route
          exact
          path="/traceability/finalize/:id"
          component={subscriptionRequired(SummaryView, traceabilityAccess)}
        />
      </Route>
    </IngestionContextProvider>
  </ConfigurationWizardProvider>
);

export default traceabilityRoutes;
