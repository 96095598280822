import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import TableHeadCell from '../Shared/TableHeadCell';
import ContractProgressBar from './PurchaseOrderProgressBar/ContractProgressBar';
import { useContractContext } from '../../contexts/Procurement/contract';
import { formatCurrencySymbol } from '../../utils';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: '40px',
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tiertable: {
    width: '35%',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.lightest,
      width: '10%',
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  rebateLoader: {
    loaderContainer: theme.loaderContainer,
    zIndex: 199,
  },
  stagedSerialNo: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingLeft: '25px',
  },
  subTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  rebateDisplay: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingRight: '25px',
    textAlign: 'Right',
  },
  rebateButton: {
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    width: 217,
  },
  calculateButton: {
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    width: 100,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 'large',
    marginLeft: '10px',
  },
}));

const ContractRebateTable = ({ materials, smartContract }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { rebateValue, contractLoading, getRebateValue } = useContractContext();
  const { euroCurrencySymbol } = useSubscriptionContext();
  const handleRebateCalculation = () => {
    getRebateValue({
      variables: {
        smartContractId: smartContract,
      },
    });
  };

  const isOwner = materials.find(x => x.isOwner);
  const columns = [
    {
      name: 'material',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'materialDescription',
      label: t('common.materialDescription'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'pricings',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'accumulatedVolume',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'unitPrice',
      label: t('invoices.unitPrice'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'accumulatedVolume',
      label: t('contracts.totalQuantityOrder'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'totalValue',
      label: t('contracts.totalValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'material',
      label: t('contracts.rebateAmount'),
      options: {
        display: isOwner,
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="left"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: (value, data) => {
          const rebateData = {
            rebateDiscount: -1,
          };
          let skuRebate;
          if (rebateValue && rebateValue.length > 0) {
            skuRebate = rebateValue.find(rebate => rebate.sku === value);
            rebateData.rebateDiscount =
              skuRebate && data.rowData[8] && data.rowData[8] === skuRebate.smartContractId
                ? skuRebate.rebateDiscount
                : -1;
          }
          if (rebateData.rebateDiscount < 0 && isOwner) {
            return (
              <>
                <Button
                  className={classes.calculateButton}
                  variant="outlined"
                  onClick={() => {
                    handleRebateCalculation();
                  }}
                  id="calculateRebateAmountInColumn"
                  data-testid="calculateRebateAmountInColumn"
                >
                  {t('contracts.calculate')}
                </Button>
              </>
            );
          }
          return (
            <>
              {isOwner && (
                <>
                  <>
                    <span>
                      {rebateValue &&
                        rebateData &&
                        rebateData.rebateDiscount >= 0 &&
                        formatCurrencySymbol(
                          `${Number(rebateData.rebateDiscount)}`,
                          euroCurrencySymbol,
                        )}
                    </span>
                  </>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.tooltipArrow,
                    }}
                    title={
                      <span>
                        <p>{`${data.rowData[5]} quantity * ${formatCurrencySymbol(
                          Number(data.rowData[4]),
                          euroCurrencySymbol,
                        )} unit price * ${skuRebate?.discountPercentage || ''}% rebate`}</p>
                      </span>
                    }
                    placement="top"
                    arrow
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'smartContractId',
      label: t('common.smartContractId'),
      options: {
        display: false,
      },
    },
  ];

  const tierColumns = [
    {
      name: 'RebateThreshold',
      label: t('contracts.rebateThreshold'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'RebatePercentage',
      label: t('contracts.rebatePercentage'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const Row = props => {
    const { row } = props;
    const theresholdArr = [0];
    const percentageArr = [0];

    if (row[2] && row[2].length > 0) {
      row[2].forEach(data => {
        theresholdArr.push(data.RebateThreshold);
        percentageArr.push(
          Number(data.RebatePercentage.substring(0, data.RebatePercentage.length - 1)),
        );
      });
    }
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            {row[2] && row[2].length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            {row[0]}
          </TableCell>
          <TableCell>{row[1]}</TableCell>
          <TableCell>{formatCurrencySymbol(Number(row[4]), euroCurrencySymbol)}</TableCell>
          <TableCell>{row[5]}</TableCell>
          <TableCell>{formatCurrencySymbol(Number(row[6]), euroCurrencySymbol)}</TableCell>
          {isOwner && <TableCell>{row[7]}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  title={t('contracts.rebateThresholds')}
                  className={classes.tiertable}
                  columns={tierColumns}
                  data={row[2]}
                  options={{
                    pagination: false,
                    filter: false,
                    search: false,
                    searchOpen: false,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                  }}
                />
              </Box>
              <ContractProgressBar
                key={uniqid()}
                threshold={theresholdArr}
                accumulatedVolume={row[3]}
                purchaseOrderVolume={0}
                percentage={percentageArr}
                className={classes.tierComponent}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  Row.propTypes = {
    row: PropTypes.oneOfType([PropTypes.array]),
  };

  Row.defaultProps = {
    row: [],
  };

  return (
    <>
      {contractLoading && (
        <div className={classes.rebateLoader}>
          <Loader />
        </div>
      )}
      <Grid item xs={12} className={classes.gridSpace}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={8} className={classes.stagedSerialNo} />
          {isOwner && (
            <Grid item xs={4} className={classes.rebateDisplay}>
              <Button
                className={classes.rebateButton}
                variant="outlined"
                onClick={() => {
                  handleRebateCalculation();
                }}
                id="calculateRebateAmount"
                data-testid="calculateRebateAmount"
              >
                {rebateValue.length
                  ? t('contracts.recalculateRebateAmount')
                  : t('contracts.calculateRebateAmount')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Table
        title={t('common.materialRequested')}
        className={classes.table}
        columns={columns}
        data={materials}
        options={{
          pagination: false,
          filter: false,
          customRowRender: data => data && data.length > 0 && <Row key={uniqid()} row={data} />,
          search: false,
          searchOpen: false,
          elevation: 0,
          textLabels: {
            body: {
              noMatch: t('common.noMatchingRecords'),
            },
            pagination: {
              rowsPerPage: t('common.rowsPerPage'),
            },
          },
        }}
      />
    </>
  );
};

ContractRebateTable.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  smartContract: PropTypes.string,
};

ContractRebateTable.defaultProps = {
  materials: [],
  smartContract: '',
};

export default ContractRebateTable;
