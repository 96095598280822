import { Modal, Paper, Typography, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors/FormErrors';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ethers } from 'ethers';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';

const useStyles = makeStyles(theme => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(1),
  },
}));

const AddOrganization = ({ open, closeModal, loading, onOrgPartnerHandler, partners }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const orgTypes = [
    {
      value: 'subsidiary',
      label: 'Subsidiary',
    },
    {
      value: 'authorized_user',
      label: 'Authorized Organization',
    },
  ];

  const formikAccess = useFormik({
    initialValues: {
      orgWalletAddress: '',
      BusinessPartnerId: [],
    },
    onSubmit: async (values, { resetForm }) => {
      const partnersId = values.BusinessPartnerId.map(items => items.partnerOrganization._id);
      const input = {
        partnersHeaderId: partnersId,
        orgWalletAddress: values.orgWalletAddress,
        organizationType: values.organizationType,
      };
      await onOrgPartnerHandler(input);
      resetForm();
    },
    validationSchema: Yup.object().shape({
      orgWalletAddress: Yup.string()
        .required(t('partners.validation.partnerWalletAddress'))
        .test('is-valid-eth-address', t('partners.validation.partnerWalletAddressChange'), val => {
          try {
            return ethers.utils.getAddress(val) && true;
          } catch (e) {
            return false;
          }
        })
        .matches(/^0x[a-fA-F0-9]{40}$/, t('partners.validation.partnerWalletAddressChange')),
      organizationType: Yup.string().required(t('organization.organizationTypeRequired')),
      BusinessPartnerId: Yup.array()
        .min(1, t('organization.error.businesspartnersError'))
        .required(t('organization.error.businesspartnersError')),
    }),
  });
  const disabled = !formikAccess.dirty || !formikAccess.isValid;

  return (
    <Modal className={classes.modal} open={open} onClose={closeModal}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('organization.addOrganization')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formikAccess}>
          <Form>
            <Field
              component={TextField}
              label={t('organization.organizationWalletAddress')}
              name="orgWalletAddress"
              data-testid="orgWalletAddress-input"
            />
            <Field
              component={AutocompleteDropDown}
              placeholder={t('organization.organizationType')}
              onChange={formikAccess.handleChange}
              name="organizationType"
              options={orgTypes}
              testid="createContract-dropdown-Organization"
            />
            <Typography variant="h4" className={classes.title}>
              {t('organization.choosebusinesspartner')}
            </Typography>
            <Field
              component={AutocompleteDropDown}
              isMultipleSelection
              onChange={formikAccess.handleChange}
              name="BusinessPartnerId"
              placeholder={t('partners.addPartner')}
              disabled={!partners.length}
              options={partners}
              size="small"
              withCheckboxes
              disableCloseOnSelect
              startingValue={formikAccess.values.BusinessPartnerId}
              limitTags={2}
              selected={
                !formikAccess.values.BusinessPartnerId
                  ? partners
                  : formikAccess.values.BusinessPartnerId
              }
              testid="org-dropdown-addPartner"
            />
            <FormErrors form={formikAccess} />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="addOrganization-button"
              disabled={disabled || loading}
              className={classes.saveButton}
            >
              {t('common.save')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

AddOrganization.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onOrgPartnerHandler: PropTypes.func.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      partnerOrganization: PropTypes.string.isRequired,
    }),
  ),
};
AddOrganization.defaultProps = {
  partners: [],
};
export default AddOrganization;
