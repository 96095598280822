import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormikRadioGroup = ({ field, options, useI18n, radioProps, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <RadioGroup {...field} {...props}>
        {options.map((option, index) => {
          const label = useI18n ? t(option.label) : option.label;

          return (
            <FormControlLabel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={option.value}
              control={<Radio {...radioProps} />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </>
  );
};

FormikRadioGroup.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  useI18n: PropTypes.bool,
  radioProps: PropTypes.shape({}),
};

FormikRadioGroup.defaultProps = {
  useI18n: false,
  radioProps: null,
};

export default FormikRadioGroup;
