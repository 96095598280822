import gql from 'graphql-tag';

export const TRANSACTION_DETAILS_ATTRIBUTES = gql`
  fragment TRANSACTION_DETAILS_ATTRIBUTES on TransactionBcos {
    transactionHash
    blockHash
    blockNumber
    blockchainType
    timestamp
    from
    to
    gasUsed
    transactionIndex
    error
  }
`;

export const DOCUMENT_TRANSACTION_ATTRIBUTES = gql`
  fragment DOCUMENT_TRANSACTION_ATTRIBUTES on Transaction {
    transactionHash
    status
    amount
    transactionType
    tokenId
  }
`;

export const GET_TRANSACTION = gql`
  query transactionBcos($transactionHash: String!) {
    transactionBcos(transactionHash: $transactionHash) {
      ...TRANSACTION_DETAILS_ATTRIBUTES
    }
  }
  ${TRANSACTION_DETAILS_ATTRIBUTES}
`;

export const GET_TRANSACTION_INFO = gql`
  query transactionInfo(
    $contractAddress: String
    $contractName: String
    $method: String
    $methodArgs: [String]
  ) {
    transactionInfo(
      contractAddress: $contractAddress
      contractName: $contractName
      method: $method
      methodArgs: $methodArgs
    ) {
      gasLimit
      gasPrice
      balance
    }
  }
`;

export const GET_RECENT_TRANSACTIONS = gql`
  query recentTransactions {
    recentTransactions {
      _id
      status
      blockchainNetwork
      transactionHash
      smartcontractType
      transactionType
      createdAt
      costInUSD
      tokenId
      to
      contractInfo {
        contractAddress
        contractName
      }
    }
  }
`;

export const GET_COMPLETED_TRANSACTION = gql`
  query completedTransaction($transactionHash: String!) {
    completedTransaction(transactionHash: $transactionHash) {
      _id
      status
      blockchainNetwork
      transactionHash
      smartcontractType
      transactionType
      createdAt
      costInUSD
      tokenId
      to
      contractInfo {
        contractAddress
        contractName
      }
    }
  }
`;

export const CLEAR_RECENT_TRANSACTION = gql`
  mutation clearRecentTransaction($transactions: String!) {
    clearRecentTransaction(transactions: $transactions) {
      modifiedCount
    }
  }
`;

export const TRANSACTION_STATUS = gql`
  subscription transactionStatus {
    transactionStatus {
      ...DOCUMENT_TRANSACTION_ATTRIBUTES
    }
  }
  ${DOCUMENT_TRANSACTION_ATTRIBUTES}
`;
