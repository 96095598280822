import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import Divider from '@material-ui/core/Divider';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import track from '../../mixpanel';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: theme.spacing(10),
    position: 'absolute',
    width: '60%',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  mintButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
}));

const MintToken = ({ open, closeModal, onSubmit, contractAddress, loading }) => {
  const classes = useStyles();
  const { activeWallet } = useBecOrganizationContext();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      track('Mint ERC20 Token Submitted', { contract: contractAddress, amount: values.amount });
      const res = await onSubmit({ variables: { input: { ...values, contractAddress } } });
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(1, t('tokens.validation.minAmount'))
        .max(100000000, t('tokens.validation.maxAmount'))
        .required(t('tokens.validation.required'))
        .integer(t('tokens.validation.integerValue')),
    }),
  });

  const closeModalFn = () => {
    closeModal();
    formik.resetForm();
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.mintTokens')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Field
              component={NumberFormat}
              label={t('tokens.amount')}
              value={formik.values.amount || ''}
              name="amount"
              customInput={TextField}
              onValueChange={vals => formik.setFieldValue('amount', vals.value)}
              thousandSeparator
              isNumericString
            />
            <FormErrors form={formik} />
            {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
              <BlockchainInfo
                validateForm={formik.validateForm}
                values={formik.values}
                contractAddress={contractAddress}
                method={CONSTANTS.TRANSACTION_TYPES.MINT}
                methodArgs={[activeWallet?.address, formik.values.amount?.toString()]}
              />
            ) : (
              ''
            )}
            <Divider className={classes.divider} />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!formik.values.amount || loading}
            >
              {t('tokens.mint')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.mintButton}
              onClick={closeModalFn}
            >
              {t('common.cancel')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

MintToken.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  loading: PropTypes.bool,
};

MintToken.defaultProps = {
  contractAddress: '',
  loading: false,
};

export default MintToken;
