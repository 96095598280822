import { FastField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddArea from '../AddArea';
import MandatoryLabel from './MandatoryLabel';
import {
  METADATA_STRUCTURE_PERMISSIONS,
  METADATA_STRUCTURE_DATA_MUTABILITY,
} from '../../../constants';

const buildOptions = options =>
  options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

const useStyles = makeStyles(theme => ({
  rowRoot: {
    display: 'grid',
    gridTemplateColumns: '18% 18% 18% 18% 18% 5%',
    margin: '0.5em 0',
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    gridGap: '0.5%',
  },
  selectField: {
    minWidth: '18%',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '18%',
  },
  deleteButton: {
    height: 'max-content',
  },
  dynamaticColumn: {
    display: 'flex',
  },
  dataType: {
    width: '100%',
  },
  split: {
    width: '5%',
  },
  metadataOptions: {
    width: '50%',
  },
}));

const RenderText = props => {
  const classes = useStyles();
  const { error, label } = props;
  return (
    <TextField
      {...props}
      label={<MandatoryLabel text={label} error={error} />}
      size="small"
      variant="outlined"
      InputProps={{ classes: { input: classes.itemNameInput } }}
    />
  );
};

RenderText.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
};
RenderText.defaultProps = {
  error: false,
};

const RenderSelect = props => {
  const classes = useStyles();
  const { options } = props;
  return (
    <TextField
      {...props}
      select
      size="small"
      variant="outlined"
      classes={{ root: classes.selectField }}
    >
      {buildOptions(options)}
    </TextField>
  );
};

RenderSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const MetadataRow = ({
  arrayHelpers,
  tabIndex,
  metadataTypeOptions,
  valueChainStepOptions,
  contractAddress,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const errors = arrayHelpers.form?.errors?.tabsMetadata;
  const tabError = errors ? errors[tabIndex]?.metadataArray : null;
  const permissionOptions = [
    {
      label: t('tokens.metaDataStructure.permissions.unrestricted'),
      value: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
    },
    {
      label: t('tokens.metaDataStructure.permissions.restricted'),
      value: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
    },
  ];
  const mutabilityOptions = [
    {
      label: t('tokens.metaDataStructure.dataMutability.mutable'),
      value: METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
    },
    {
      label: t('tokens.metaDataStructure.dataMutability.immutable'),
      value: METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
    },
  ];
  const rows = arrayHelpers.form.values.tabsMetadata?.[tabIndex]?.metadataArray?.map(
    (_, rowIndex) => {
      const rowError = tabError ? tabError[rowIndex] : {};
      const matchType = metadataTypeOptions.find(({ value }) => value === _.metadataType);
      const rowKey = `id-${tabIndex}-${rowIndex}`;
      return (
        <div className={classes.rowRoot} key={rowKey}>
          <FastField
            name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].metadataName`}
            label={t('traceability.metadataForm.labels.metadataName')}
            as={RenderText}
            error={!!rowError?.metadataName}
          />
          <div className={classes.dynamaticColumn}>
            <FastField
              name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].metadataType`}
              label={t('traceability.metadataForm.labels.dataType')}
              as={RenderSelect}
              options={metadataTypeOptions}
              error={!!rowError?.metadataType}
              className={classes.dataType}
            />
            {matchType?.options.length ? <div className={classes.split} /> : null}
            {matchType?.options.length ? (
              <FastField
                name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].chosenMetadataOption`}
                as={RenderSelect}
                options={matchType.options.map(item => ({
                  label: t(item),
                  value: item,
                }))}
                error={!!rowError?.chosenMetadataOption}
                style={{ width: '50%' }}
                className={classes.metadataOptions}
              />
            ) : null}
          </div>
          <FastField
            name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].metadataPermission`}
            label={t('traceability.metadataForm.labels.permission')}
            as={RenderSelect}
            options={permissionOptions}
            error={!!rowError?.metadataPermission}
          />
          <FastField
            name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].metadataMutability`}
            label={t('traceability.metadataForm.labels.mutability')}
            as={RenderSelect}
            options={mutabilityOptions}
            error={!!rowError?.metadataMutability}
          />
          <FastField
            name={`tabsMetadata[${tabIndex}].metadataArray[${rowIndex}].step.value`}
            label={t('traceability.metadataForm.labels.valueChainStep')}
            as={RenderSelect}
            options={valueChainStepOptions}
            error={!!rowError?.step?.value}
          />
          <Button className={classes.deleteButton} onClick={() => arrayHelpers.remove(rowIndex)}>
            <DeleteIcon />
          </Button>
        </div>
      );
    },
  );
  return (
    <>
      {rows}
      <AddArea
        onClick={() => {
          arrayHelpers.push({ contractAddress });
        }}
        bannerText={t('traceability.metadataForm.addRow')}
      />
    </>
  );
};

MetadataRow.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  metadataTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  valueChainStepOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  arrayHelpers: PropTypes.shape({
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.shape({
        tabsMetadata: PropTypes.shape({
          metadataArray: PropTypes.array.isRequired,
        }),
      }),
      errors: PropTypes.shape({
        tabsMetadata: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
      }),
    }),
  }).isRequired,
  contractAddress: PropTypes.string.isRequired,
};

export default MetadataRow;
