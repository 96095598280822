import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MintTokenIcon = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <title>77A7F05A-7E02-4F4B-85C2-2886A58F2A7A</title>
      <g id="New-design-ERC-721" stroke="none" strokeWidth="1">
        <g id="Token-list-hover" transform="translate(-675.000000, -318.000000)">
          <g id="Icon" transform="translate(675.000000, 318.000000)">
            <path
              d="M0,12.665625 L0,14 C0,15.103125 2.6875,16 6,16 C9.3125,16 12,15.103125 12,14 L12,12.665625 C10.709375,13.575 8.35,14 6,14 C3.65,14 1.290625,13.575 0,12.665625 Z M10,4 C13.3125,4 16,3.103125 16,2 C16,0.896875 13.3125,0 10,0 C6.6875,0 4,0.896875 4,2 C4,3.103125 6.6875,4 10,4 Z M0,9.3875 L0,11 C0,12.103125 2.6875,13 6,13 C9.3125,13 12,12.103125 12,11 L12,9.3875 C10.709375,10.45 8.346875,11 6,11 C3.653125,11 1.290625,10.45 0,9.3875 Z M13,9.73125 C14.790625,9.384375 16,8.740625 16,8 L16,6.665625 C15.275,7.178125 14.209375,7.528125 13,7.74375 L13,9.73125 Z M6,5 C2.6875,5 0,6.11875 0,7.5 C0,8.88125 2.6875,10 6,10 C9.3125,10 12,8.88125 12,7.5 C12,6.11875 9.3125,5 6,5 Z M12.853125,6.759375 C14.728125,6.421875 16,5.759375 16,5 L16,3.665625 C14.890625,4.45 12.984375,4.871875 10.978125,4.971875 C11.9,5.41875 12.578125,6.01875 12.853125,6.759375 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

MintTokenIcon.propTypes = {};

MintTokenIcon.defaultProps = {};

export default MintTokenIcon;
