import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';

import LaunchIcon from '@material-ui/icons/Launch';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import i18n from '../../../i18n';
import { CONSTANTS } from '../../../constants';

const Typography = withStyles(theme => ({
  body2: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
}))(MuiTypography);

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'inline-block',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  fundTestNet: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: '0.7rem',
  },
  icon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
  },
  arrows: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  circle2: {
    width: '200px',
    height: '200px',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  coins: {
    width: '125px',
    height: '125px',
    margin: '2.5rem 0 0 0',
  },
  activeButton: {
    '&:hover': { backgroundColor: theme.palette.primary.lightest },
  },
  backButton: {
    padding: '0 10px 0 0',
    marginRight: '10px',
  },
  forwardButton: {
    padding: '0 0 0 10px',
    marginLeft: '10px',
  },
  lightNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.lighter,
  },
  darkNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.main,
  },
  firstLink: {
    marginBottom: '0',
  },
}));

const FundYourWallet = ({ nextOnClick, backOnClick, testWalletAddress, copied }) => {
  const classes = useStyles();

  return (
    <>
      <Box style={{ height: '250px' }} display="flex" justifyContent="center">
        <span className={classes.circle2}>
          <img alt="" src="static/coinIcon.png" className={classes.coins} />
        </span>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.fundTestNet} variant="body2">
          {i18n.t('OnboardingModal.ScreenTwo.fundTestnet')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box maxWidth="700px">
          <Typography align="center" variant="body1" gutterBottom>
            {i18n.t('OnboardingModal.ScreenTwo.body1')}
          </Typography>
        </Box>
      </Box>
      <Box justifyContent="center">
        <Typography align="center" variant="body1" gutterBottom className={classes.firstLink}>
          <Trans i18nKey="OnboardingModal.ScreenTwo.body2">
            <Link style={{ color: '#155CB4' }} target="_blank" href={CONSTANTS.DOCS.ROPSTEN}>
              {i18n.t('OnboardingModal.ScreenTwo.body2')}g
            </Link>
          </Trans>
        </Typography>
        <Typography align="center" variant="body1" gutterBottom>
          <Trans i18nKey="OnboardingModal.ScreenTwo.body3">
            <Link style={{ color: '#155CB4' }} target="_blank" href={CONSTANTS.DOCS.POLYGON}>
              {i18n.t('OnboardingModal.ScreenTwo.body3')}
            </Link>
          </Trans>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography align="center" variant="body1" style={{ marginTop: '5px' }} gutterBottom>
          {i18n.t('OnboardingModal.ScreenTwo.address')} {'\u00A0'}
        </Typography>
        <Tooltip title={i18n.t('common.copied')} placement="top" open={copied} arrow>
          <Typography style={{ marginTop: '5px', fontWeight: '500' }}>
            {`${testWalletAddress.slice(0, 6)}...${testWalletAddress.slice(-4)}`}
          </Typography>
        </Tooltip>
        <CopyContent content={testWalletAddress} />
      </Box>

      <Box style={{ height: '20px' }} />

      <Box display="flex" justifyContent="center">
        <Button
          target="_blank"
          color="secondary"
          variant="contained"
          href={`${CONSTANTS.NETWORK_FAUCETS.POLYGON}`}
        >
          {i18n.t('OnboardingModal.ScreenTwo.fundMyWallet')} {'\u00A0'}
          <LaunchIcon />
        </Button>
      </Box>

      <Box style={{ height: '25px' }} />

      <Box className={classes.arrows} display="flex" justifyContent="center">
        <Button
          className={clsx(classes.activeButton, classes.backButton)}
          variant="outlined"
          color="primary"
          fontSize="large"
          onClick={backOnClick}
        >
          <KeyboardArrowLeftIcon className={classes.activeArrow} fontSize="large" />
          {i18n.t('OnboardingModal.back')}
        </Button>

        <FiberManualRecordIcon className={classes.lightNavCircle} />
        <FiberManualRecordIcon className={classes.darkNavCircle} />
        <FiberManualRecordIcon className={classes.lightNavCircle} />

        <Button
          className={clsx(classes.activeButton, classes.forwardButton)}
          color="secondary"
          variant="contained"
          fontSize="large"
          onClick={nextOnClick}
        >
          {i18n.t('OnboardingModal.next')}
          <KeyboardArrowRightIcon fontSize="large" />
        </Button>
      </Box>
    </>
  );
};

FundYourWallet.propTypes = {
  nextOnClick: PropTypes.func.isRequired,
  backOnClick: PropTypes.func.isRequired,
  copied: PropTypes.bool.isRequired,
  testWalletAddress: PropTypes.string,
};

FundYourWallet.defaultProps = {
  testWalletAddress: null,
};

export default FundYourWallet;
