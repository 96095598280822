// import mixpanel from 'mixpanel-browser';

/**
 * This function tracks an event on mixpanel.
 * @param {String} eventName - Registered event name in mixpanel.
 * @param {Object} metadata - Object containing event specific data.
 */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
function track(event, meta) {
  try {
    if (window.platformConfig.mixpanelId) {
      // mixpanel.track(event, meta);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default track;
