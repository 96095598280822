import React from 'react';

const EthereumLogo = ({ ...other }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 64" {...other}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-214.000000, -362.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(214.000000, 362.000000)">
            <g opacity="0.6" transform="translate(0.000000, 23.636364)">
              <polygon
                id="Path"
                points="19.6290909 0.0327272727 0 8.95636364 19.6290909 20.56 39.2618182 8.95636364"
              />
            </g>
            <g>
              <polygon
                opacity="0.45"
                points="0.00727272727 32.5927273 19.6363636 44.1963636 19.6363636 23.6690909 19.6363636 0.0218181818"
              />
              <polygon
                opacity="0.8"
                points="19.6363636 0.0218181818 19.6363636 23.6690909 19.6363636 44.1963636 39.2654545 32.5927273"
              />
            </g>
            <polygon
              opacity="0.45"
              points="0 36.3163636 19.6290909 63.9781818 19.6290909 47.9127273"
            />
            <polygon
              opacity="0.8"
              points="19.6290909 47.9127273 19.6290909 63.9781818 39.2727273 36.3163636"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EthereumLogo;
