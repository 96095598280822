import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent/CopyContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { find, upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../constants';
import { getBlockchainExplorerUrl, getPrettifiedEthAddr } from '../../utils';
import PageLoader from '../Shared/PageLoader';
import MintTokenIcon from './MintTokenIcon';
import UpdateMetadataTokenIcon from './UpdateMetadataIcon';

const useStyles = makeStyles(theme => ({
  timeLineRoot: {
    '& .MuiTimelineItem-missingOppositeContent:before': {
      content: 'none',
    },

    '& .MuiTimelineItem-root:first-child .MuiTimelineDot-root': {
      backgroundColor: theme.palette.primary.main,
    },

    '& .MuiTimelineItem-root:first-child .MuiTimelineContent-root > div:first-child > div:first-child': {
      backgroundColor: theme.palette.primary.main,
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
    },

    '& .MuiTimelineItem-root:last-child .MuiTimelineConnector-root': {
      marginBottom: '11px',
    },
  },
  timelineConnector: {},
  timelineDot: {},
  timelineSeparator: {
    '& .MuiTimelineDot-root': {
      marginTop: '0px !important',
      marginBottom: '0px !important',
    },

    '& .MuiTimelineConnector-root': {
      width: '1px',
    },
  },
  itemSeparatorRoot: {
    position: 'relative',
    margin: '20px 0 26px 0',
  },
  itemSeparator: {
    position: 'absolute',
    borderBottom: `1px solid ${theme.palette.primary.lighter}`,
    left: '-34px',
    width: '105%',
  },
  timeLineContentContainer: {},
  headerContent: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    padding: '7px 9px',
  },
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: 'inherit',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyContent: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.lightest,
  },
  footerContent: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.lighter,
  },
  fontLight: {
    fontWeight: '100',
  },
  bodyItem: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  transactionLink: {
    color: theme.colors.hyperLinkBlue,
    textDecoration: 'underline',
  },
  transactionLinkIcon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fill: theme.colors.hyperLinkBlue,
    color: theme.colors.hyperLinkBlue,
  },
}));

const TokenHistoryTimeline = ({ tokenHistoryInfo, loading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeWallet } = useBecOrganizationContext();
  const baseUrl = getBlockchainExplorerUrl(activeWallet.network);

  const calculateDuration = date => {
    const fromDate = moment.unix(date);
    return fromDate.fromNow();
  };

  const getMetaContent = address => {
    return (
      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        {address && (
          <>
            <Typography variant="body2">{getPrettifiedEthAddr(address)}</Typography>
            <CopyContent content={address} />
          </>
        )}
      </Box>
    );
  };

  const getOrganizationWalletAddress = wallets => {
    const wallet = find(wallets, {
      network: activeWallet?.network,
      blockchainType: activeWallet?.blockchainType,
    });
    return wallet?.address;
  };

  const getVerboseTransactionType = transaction => {
    if (
      transaction?.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER &&
      transaction?.from === activeWallet?.address
    )
      return t('tokenHistoryTimeLine.verboseTransactionType.outboundTransfer');
    if (
      transaction?.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER &&
      transaction?.to === activeWallet?.address
    )
      return t('tokenHistoryTimeLine.verboseTransactionType.inboundTransfer');
    if (transaction?.transactionType === CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA)
      return t('tokenHistoryTimeLine.verboseTransactionType.metadataUpdate');
    return upperFirst(transaction?.transactionType);
  };

  const getIconByTRansactionType = transaction => {
    switch (transaction?.transactionType) {
      case CONSTANTS.TRANSACTION_TYPES.MINT:
      case CONSTANTS.TRANSACTION_TYPES.BATCH_MINT:
        return <MintTokenIcon />;
      case CONSTANTS.TRANSACTION_TYPES.TRANSFER: {
        if (transaction?.from === activeWallet?.address) return <ArrowForwardIcon />;
        if (transaction?.to === activeWallet?.address) return <ArrowBackIcon />;
        return <CallMadeIcon />;
      }
      case CONSTANTS.TRANSACTION_TYPES.DEPOSIT:
        return <ArrowDownwardIcon />;
      case CONSTANTS.TRANSACTION_TYPES.WITHDRAW:
        return <ArrowUpwardIcon />;
      case CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA:
      case CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA_ON_CHAIN:
        return <UpdateMetadataTokenIcon />;
      default:
        return <ArrowForwardIcon />;
    }
  };

  const getTransactionLink = transactionHash => (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${baseUrl}/${transactionHash}`}
        className={classes.transactionLink}
      >
        {getPrettifiedEthAddr(transactionHash)}
      </a>
      <LaunchIcon className={classes.transactionLinkIcon} />
    </div>
  );

  const getHeaderContent = tokenHistory => {
    return (
      <div className={classes.headerContainer}>
        <Box component="div" sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
          <Typography variant="body2">
            {' '}
            {getVerboseTransactionType(tokenHistory?.transaction)}{' '}
          </Typography>
        </Box>
        <Box component="div" sx={{ display: 'flex', gap: '5px' }}>
          <AccessTimeIcon />
          <Typography variant="body2">{calculateDuration(tokenHistory?.date)}</Typography>
        </Box>
      </div>
    );
  };

  const organizationAddressInfo = wallets => (
    <Box component="div" className={classes.bodyItem}>
      <Typography variant="body2" className={classes.fontLight}>
        {t('tokenHistoryTimeLine.organizationAddress')}
      </Typography>
      <Typography variant="body2">
        {getMetaContent(getOrganizationWalletAddress(wallets))}
      </Typography>
    </Box>
  );

  const organizationNameInfo = organizationName => (
    <Box component="div" className={classes.bodyItem}>
      <Typography variant="body2" className={classes.fontLight}>
        {t('tokenHistoryTimeLine.organizationName')}
      </Typography>
      <Typography variant="body2">{organizationName}</Typography>
    </Box>
  );

  const balanceInfo = balance => (
    <Box component="div" className={classes.bodyItem}>
      <Typography variant="body2" className={classes.fontLight}>
        {t('tokenHistoryTimeLine.balance')}
      </Typography>
      <Typography variant="body2">{balance}</Typography>
    </Box>
  );

  const transactionHashInfo = transactionHash => (
    <Box component="div" className={classes.bodyItem}>
      <Typography variant="body2" className={classes.fontLight}>
        {t('tokenHistoryTimeLine.transactionHash')}
      </Typography>
      <Typography variant="body2">{getTransactionLink(transactionHash)}</Typography>
    </Box>
  );

  const getBodyContent = tokenHistory => {
    switch (tokenHistory?.transaction?.transactionType) {
      case 'mint':
        return (
          <>
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.receiver')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.organizationDetails?.name}</Typography>
            </Box>
            {organizationAddressInfo(tokenHistory?.organizationDetails?.wallets)}
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.amountMinted')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.amount}</Typography>
            </Box>
            {tokenHistory?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 &&
              tokenHistory?.balance &&
              balanceInfo(tokenHistory?.balance)}
            {transactionHashInfo(tokenHistory?.transaction?.transactionHash)}
          </>
        );
      case 'updateMetadata':
        return (
          <>
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.executedBy')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.organizationDetails?.name}</Typography>
            </Box>
            {organizationAddressInfo(tokenHistory?.organizationDetails?.wallets)}
            {transactionHashInfo(tokenHistory?.transaction?.transactionHash)}
          </>
        );
      case 'transfer':
        return (
          <>
            {tokenHistory?.transaction?.to === activeWallet?.address ? (
              <Box component="div" className={classes.bodyItem}>
                <Typography variant="body2" className={classes.fontLight}>
                  {t('tokenHistoryTimeLine.receivedFrom')}
                </Typography>
                <Typography variant="body2">{tokenHistory?.organizationDetails?.name}</Typography>
              </Box>
            ) : (
              organizationNameInfo(tokenHistory?.organizationDetails?.name)
            )}
            {organizationAddressInfo(tokenHistory?.organizationDetails?.wallets)}
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.amountSent')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.amount}</Typography>
            </Box>
            {tokenHistory?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 &&
              tokenHistory?.balance &&
              balanceInfo(tokenHistory?.balance)}
            {transactionHashInfo(tokenHistory?.transaction?.transactionHash)}
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.transferReason')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.transferReason}</Typography>
            </Box>
          </>
        );
      case 'burn':
      case 'batchBurn':
        return (
          <>
            {organizationNameInfo(tokenHistory?.organizationDetails?.name)}
            {organizationAddressInfo(tokenHistory?.organizationDetails?.wallets)}
            <Box component="div" className={classes.bodyItem}>
              <Typography variant="body2" className={classes.fontLight}>
                {t('tokenHistoryTimeLine.amountBurned')}
              </Typography>
              <Typography variant="body2">{tokenHistory?.amount}</Typography>
            </Box>
            {tokenHistory?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 &&
              tokenHistory?.balance &&
              balanceInfo(tokenHistory?.balance)}
            {transactionHashInfo(tokenHistory?.transaction?.transactionHash)}
          </>
        );
      default:
        return (
          <>
            {organizationNameInfo(tokenHistory?.organizationDetails?.name)}
            {organizationAddressInfo(tokenHistory?.organizationDetails?.wallets)}
            {tokenHistory?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 &&
              tokenHistory?.balance &&
              balanceInfo(tokenHistory?.balance)}
            {transactionHashInfo(tokenHistory?.transaction?.transactionHash)}
          </>
        );
    }
  };

  const getFooterContent = tokenHistory => {
    return (
      <Box component="div" sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant="body2">
          <span className={classes.fontLight}>{t('tokenHistoryTimeLine.status')}</span>{' '}
          {tokenHistory?.transaction?.status}
        </Typography>
        <span>|</span>
        <Typography variant="body2">
          <span className={classes.fontLight}>{t('tokenHistoryTimeLine.timestamp')}</span>{' '}
          <span>
            {moment
              .unix(tokenHistory?.transaction?.timestamp)
              .utc()
              .format('YYYY/MM/DD HH:mm')}{' '}
            (UTC)
          </span>
        </Typography>
      </Box>
    );
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Timeline className={classes.timeLineRoot}>
        {tokenHistoryInfo?.map(tokenHistory => (
          <TimelineItem>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot>{getIconByTRansactionType(tokenHistory?.transaction)}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className={classes.timeLineContentContainer}>
                <div className={classes.headerContent}>{getHeaderContent(tokenHistory)}</div>
                <div className={classes.bodyContent}>{getBodyContent(tokenHistory)}</div>
                <div className={classes.footerContent}>{getFooterContent(tokenHistory)}</div>
              </div>
              <div className={classes.itemSeparatorRoot}>
                <div className={classes.itemSeparator} />
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

TokenHistoryTimeline.propTypes = {
  tokenHistoryInfo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TokenHistoryTimeline;
