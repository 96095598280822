import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import clsx from 'clsx';
import { CONSTANTS, TRACEABILITY_FORM } from '../../../constants';
import { useTokenContext } from '../../../contexts/Tokenization/token';
import MandatoryLabel from './MandatoryLabel';
import BlockchainInfo from '../../Shared/BlockchainInfo';
import useUserInfo from '../../../hooks/useUserInfo';
import TokenTypeChip from '../../Shared/TokenTypeChip';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
    padding: theme.spacing(3),
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  confirmButton: {
    marginLeft: theme.spacing(2),
  },
  modalTitle: {
    paddingTop: theme.spacing(2),
    display: 'flex',
  },
  modalTitleName: {
    fontWeight: '400',
    marginLeft: '10px',
  },
  createNewContractFields: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  selectField: {
    marginBottom: theme.spacing(2),
  },
  contractNameField: {
    width: '58%',
    marginRight: '2%',
  },
  contractSymbolField: {
    width: '40%',
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    size: 'small',
    marginTop: theme.spacing(2),
  },
  disabledText: {
    color: theme.palette.primary.lighter,
  },
  renderOption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  renderOptionLabel: {
    margin: 0,
  },
  muiAutocompleteOption: {
    display: 'inherit',
  },
}));

const SmartContractModal = ({ open, closeModal, itemName, onContractSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const { tokenContractsLoading, tokenContracts, getTokenContracts } = useTokenContext();
  const {
    permissionsFlags: { isUserAuthToDeployERC721 },
  } = useUserInfo();

  const erc721DisabledText = clsx({ [classes.disabledText]: !isUserAuthToDeployERC721 });

  const contractsOptions = tokenContracts
    .filter(
      token =>
        token?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721 ||
        token?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155,
    )
    .map(token => {
      return {
        label: token?.tokenName,
        value: token?.contract?._id,
        key: token?.contract?._id,
        tokenType: token?.contract?.tokenType,
      };
    });

  useEffect(() => {
    getTokenContracts();
  }, [getTokenContracts]);

  const requestLoadingOption = () => [
    {
      label: t('common.loading'),
      value: t('common.loading'),
      key: t('common.loading'),
      tokenType: '',
    },
  ];

  const requestLoadingOptionRender = () => (
    <>
      <CircularProgress size={20} thickness={20} className={classes.spinner} />
      {t('common.loading')}
    </>
  );

  const formik = useFormik({
    initialValues: {
      contractSelection: TRACEABILITY_FORM.SELECT_CONTRACT,
      newContractName: '',
      newContractSymbol: '',
      selectedContractName: '',
      selectedContractId: '',
      selectedTokenType: '',
    },
    validationSchema: Yup.object().shape({
      contractSelection: Yup.string().required(),
      newContractName: Yup.string().when(TRACEABILITY_FORM.CONTRACT_SELECTION, {
        is: TRACEABILITY_FORM.CREATE_CONTRACT,
        then: Yup.string().required(),
      }),
      newContractSymbol: Yup.string().when(TRACEABILITY_FORM.CONTRACT_SELECTION, {
        is: TRACEABILITY_FORM.CREATE_CONTRACT,
        then: Yup.string().required(),
      }),
      selectedTokenType: Yup.string().when(TRACEABILITY_FORM.CONTRACT_SELECTION, {
        is: TRACEABILITY_FORM.CREATE_CONTRACT,
        then: Yup.string().required(),
      }),
      selectedContractName: Yup.string().when(TRACEABILITY_FORM.CONTRACT_SELECTION, {
        is: TRACEABILITY_FORM.SELECT_CONTRACT,
        then: Yup.string().required(),
      }),
      selectedContractId: Yup.string().when(TRACEABILITY_FORM.CONTRACT_SELECTION, {
        is: TRACEABILITY_FORM.SELECT_CONTRACT,
        then: Yup.string().required(),
      }),
    }),
    onSubmit: onContractSubmit,
  });

  const { setFieldValue } = formik;

  const handleContractSelection = event => {
    setFieldValue(TRACEABILITY_FORM.CONTRACT_SELECTION, event.target.value);
    setFieldValue(TRACEABILITY_FORM.TOKEN_TYPE, '');
  };

  const handleTokenTypeSelection = event => {
    setFieldValue(TRACEABILITY_FORM.TOKEN_TYPE, event.target.value);
  };

  const handleChange = event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const handleAutocompleteChange = option => {
    setFieldValue('selectedContractName', option?.label);
    setFieldValue('selectedContractId', option?.value);
    setFieldValue('selectedTokenType', option?.tokenType);
  };

  const isButtonDisabled = () => {
    return formik.values.contractSelection === TRACEABILITY_FORM.SELECT_CONTRACT
      ? !formik.values.selectedContractName || !formik.values.selectedContractId
      : !formik.values.newContractName ||
          !formik.values.newContractSymbol ||
          !formik.values.selectedTokenType;
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModal}
    >
      <Paper className={classes.paper}>
        <div className={classes.modalTitle}>
          <Typography variant="h4" className={classes.modalTitleText}>
            {t('traceability.traceItemForm.smartContractModal.associateContractTitle')}
          </Typography>
          <Typography variant="h4" className={classes.modalTitleName}>
            {itemName}
          </Typography>
        </div>

        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className={classes.radioButtons}>
              <Field
                component={Radio}
                color="default"
                name={TRACEABILITY_FORM.CONTRACT_SELECTION}
                value={TRACEABILITY_FORM.SELECT_CONTRACT}
                checked={formik.values.contractSelection === TRACEABILITY_FORM.SELECT_CONTRACT}
                onChange={handleContractSelection}
              />
              <Typography>
                {t('traceability.traceItemForm.smartContractModal.selectContract')}
              </Typography>
              <Field
                component={Radio}
                color="default"
                name={TRACEABILITY_FORM.CONTRACT_SELECTION}
                value={TRACEABILITY_FORM.CREATE_CONTRACT}
                checked={formik.values.contractSelection === TRACEABILITY_FORM.CREATE_CONTRACT}
                onChange={handleContractSelection}
                disabled={!isUserAuthToDeployERC721}
              />
              <Typography className={erc721DisabledText}>
                {t('traceability.traceItemForm.smartContractModal.createContract')}
              </Typography>
            </div>

            <Divider className={classes.divider} />

            {formik.values.contractSelection === TRACEABILITY_FORM.SELECT_CONTRACT ? (
              <Autocomplete
                options={tokenContractsLoading ? requestLoadingOption() : contractsOptions}
                getOptionLabel={option =>
                  option?.label ? option.label : formik.values.selectedContractName
                }
                onChange={(event, option) => handleAutocompleteChange(option)}
                value={{
                  label: formik.values.selectedContractName,
                  value: formik.values.selectedContractId,
                  key: formik.values.selectedContractId,
                  tokenType: formik.values.selectedTokenType,
                }}
                renderOption={option => (
                  <div key={option.key} className={classes.renderOption}>
                    {tokenContractsLoading ? (
                      requestLoadingOptionRender()
                    ) : (
                      <>
                        <p className={classes.renderOptionLabel}>{option.label}</p>
                        <TokenTypeChip tokenType={option.tokenType} />
                      </>
                    )}
                  </div>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="selectedContract"
                    label={
                      <MandatoryLabel
                        text={t('traceability.traceItemForm.smartContractModal.selectContract')}
                        error={
                          !formik.values.selectedContractName || !formik.values.selectedContractId
                        }
                      />
                    }
                    variant="outlined"
                    size="small"
                    classes={{
                      root: classes.selectField,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <TokenTypeChip tokenType={formik.values.selectedTokenType} />
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                classes={{
                  option: classes.muiAutocompleteOption,
                }}
              />
            ) : (
              <>
                <div className={classes.createNewContractFields}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label={
                      <MandatoryLabel
                        text={t('traceability.traceItemForm.smartContractModal.contractName')}
                        error={!formik.values.newContractName}
                      />
                    }
                    name="newContractName"
                    onChange={handleChange}
                    value={formik.values.newContractName}
                    classes={{
                      root: classes.contractNameField,
                    }}
                  />

                  <TextField
                    size="small"
                    variant="outlined"
                    name="newContractSymbol"
                    label={
                      <MandatoryLabel
                        text={t('traceability.traceItemForm.smartContractModal.contractSymbol')}
                        error={!formik.values.newContractSymbol}
                      />
                    }
                    onChange={handleChange}
                    value={formik.values.newContractSymbol}
                    classes={{
                      root: classes.contractSymbolField,
                    }}
                  />
                </div>
                <div className={classes.radioButtons}>
                  <Field
                    component={Radio}
                    color="default"
                    name={TRACEABILITY_FORM.TOKEN_TYPE}
                    value={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                    checked={
                      formik.values.selectedTokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721
                    }
                    onChange={handleTokenTypeSelection}
                    disabled={!isUserAuthToDeployERC721}
                  />
                  <Typography className={erc721DisabledText}>{t('tokens.erc721')}</Typography>
                  <Field
                    component={Radio}
                    color="default"
                    name={TRACEABILITY_FORM.TOKEN_TYPE}
                    value={CONSTANTS.SMARTCONTRACT_TYPES.ERC1155}
                    checked={
                      formik.values.selectedTokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
                    }
                    onChange={handleTokenTypeSelection}
                    // TODO: update with permissions for deploying erc1155
                    disabled={!isUserAuthToDeployERC721}
                  />
                  <Typography className={erc721DisabledText}>{t('tokens.erc1155')}</Typography>
                </div>

                {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                  <BlockchainInfo
                    validateForm={formik.validateForm}
                    values={formik.values}
                    contractAddress=""
                    contractName={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                    method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                    methodArgs={[formik.values.newContractName, formik.values.newContractSymbol]}
                  />
                ) : (
                  ''
                )}
              </>
            )}

            <Divider className={classes.divider} />

            <Button
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={closeModal}
            >
              {t('common.cancel')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.confirmButton}
              disabled={isButtonDisabled()}
            >
              {t('traceability.traceItemForm.smartContractModal.continue')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

SmartContractModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  onContractSubmit: PropTypes.func.isRequired,
};

SmartContractModal.defaultProps = {};

export default SmartContractModal;
