import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import {
  RFQ_LISTING,
  GET_RFQ_UPDATE,
  UPDATE_RFQ_AFTER_DELETE,
  UPDATE_RFQ_STATUS_ON_DELETE_SUPPLIER,
} from '../../graphql/Procurement/rfq';
import { orderListing } from '../../utils';

const RFQContext = React.createContext([{}, () => {}]);

const RFQProvider = ({ children }) => {
  const [rfqs, setRfqs] = useState([]);
  const [loading, setloading] = useState(true);
  const [getRfqs, { error }] = useLazyQuery(RFQ_LISTING, {
    onCompleted: data => {
      setRfqs(orderListing(data.rfqs));
      setloading(false);
    },
    fetchPolicy: 'no-cache',
  });

  useSubscription(GET_RFQ_UPDATE, {
    onSubscriptionData: data => {
      setRfqs(stateRfqs => [data.subscriptionData.data.newRFQ, ...stateRfqs]);
    },
  });

  useSubscription(UPDATE_RFQ_AFTER_DELETE, {
    onSubscriptionData: data => {
      setRfqs(stateRfqs =>
        stateRfqs.filter(item => item.rfqId !== data.subscriptionData.data.deletedRFQ.rfqId),
      );
    },
  });

  useSubscription(UPDATE_RFQ_STATUS_ON_DELETE_SUPPLIER, {
    onSubscriptionData: data => {
      setRfqs(stateRfqs =>
        stateRfqs.map(rfq =>
          rfq.rfqId === data.subscriptionData.data.updateRFQSupplier.rfqId
            ? { ...rfq, status: data.subscriptionData.data.updateRFQSupplier.status }
            : rfq,
        ),
      );
    },
  });

  return (
    <RFQContext.Provider value={{ rfqs, getRfqs, loading, error }}>{children}</RFQContext.Provider>
  );
};

const useRFQContext = () => useContext(RFQContext);

RFQProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useRFQContext, RFQProvider };
