import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../contexts/Shared/notification';
import { GET_ERC20_BALANCE } from '../graphql/Tokenization/token';
import { CONSTANTS } from '../constants';
import { useTokenContext } from '../contexts/Tokenization/token';

export default function(tokenType) {
  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const { setErc20BalanceDetails, erc20BalanceDetails } = useTokenContext();
  const tokenTypeQuery = {
    [CONSTANTS.SMARTCONTRACT_TYPES.ERC20]: GET_ERC20_BALANCE,
  }[tokenType];

  const queryResponseType = {
    [CONSTANTS.SMARTCONTRACT_TYPES.ERC20]: 'getErc20TokenBalance',
  }[tokenType];

  const [getTokenBalance, { loading }] = useLazyQuery(tokenTypeQuery, {
    onCompleted: data => {
      try {
        setErc20BalanceDetails(0);
        if (!queryResponseType) {
          throw new Error(t('tokens.errors.invalid_token_type'));
        }
        const tokenBalance = data?.[queryResponseType];
        setErc20BalanceDetails(tokenBalance);
      } catch (err) {
        handleNotification(err.message, 'error');
      }
    },
    onError: () => {
      handleNotification(t('tokens.errors.fetch_balance_failed'), 'error');
    },
    fetchPolicy: 'no-cache',
  });

  return { getTokenBalance, balance: erc20BalanceDetails, loading };
}
