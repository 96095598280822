import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { isEmpty, forEach } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { UPDATE_INSTANCE } from '../../graphql/Traceability/instance';
import PageLoader from '../../components/Shared/PageLoader';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import WizardProgressBar from '../../components/Traceability/WizardV2/WizardProgressBar';
import BackButton from '../../components/Traceability/BackButton';
import InstanceTokenBox from '../../components/Traceability/InstanceTokens/InstanceTokenBox';
import { useNotification } from '../../contexts/Shared/notification';
import { UPDATE_ITEM } from '../../graphql/Traceability/items';
import { GENERATE_METADATA_CONFIG } from '../../graphql/Tokenization/token';
import { CONSTANTS, TRACEABILITY_ROUTE } from '../../constants';

const useStyles = makeStyles(theme => ({
  buttonArea: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  continueButton: {
    marginLeft: theme.spacing(3),
  },
  contentArea: {
    marginTop: theme.spacing(4),
  },
  errorDisplay: {
    boxShadow: 'none',
  },
}));

const InstanceTokens = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const { isDataLoading, instanceId, instanceDetails } = useConfigurationWizardContext();
  const { items: itemRows } = instanceDetails?.content;
  const { steps: stepRows } = instanceDetails?.content;
  const [updateItem] = useMutation(UPDATE_ITEM);
  const [generateMetadataConfig] = useMutation(GENERATE_METADATA_CONFIG);
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const { handleNotification } = useNotification();
  const history = useHistory();

  const { activeWallet } = useBecOrganizationContext();
  const [currentWallet] = useState(activeWallet);

  useEffect(() => {
    if (currentWallet !== activeWallet) {
      history.push(TRACEABILITY_ROUTE);
    }
  }, [activeWallet]);

  /**
   * Reduce itemRows array to an object with an empty string for each item._id
   */
  const buildInitialValues = () =>
    itemRows.reduce((accumulator, currentValue) => {
      if (currentValue.tokenize) {
        accumulator[currentValue._id] = '';
      }
      return accumulator;
    }, {});

  function checkDuplicate(currValue) {
    const { path: currKey, parent: allValues } = this;

    return Object.keys(allValues).reduce((accumulator, eachKey) => {
      if (allValues[eachKey] === currValue && currKey !== eachKey) {
        return false;
      }
      return accumulator;
    }, true);
  }

  /**
   * Reduce itemRows array to an Yup object with two tests for each item._id
   *    value should be a mandatory string
   *    cannot be the same as another entry
   */
  const buildValidationSchema = () =>
    itemRows.reduce((accumulator, currentValue) => {
      if (currentValue.tokenize) {
        return accumulator.concat(
          Yup.object().shape({
            [currentValue._id]: Yup.string()
              .required(t('traceability.instanceTokens.formErrors.tokenRequired'))
              .test(
                'Duplication in item-to-contract mapping',
                t('traceability.instanceTokens.formErrors.duplicateContract'),
                checkDuplicate,
              ),
          }),
        );
      }
      return accumulator;
    }, Yup.object().shape({}));

  const formik = useFormik({
    initialValues: buildInitialValues(),
    validationSchema: buildValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        setIsProcessing(true);
        const fieldMappings = itemRows.reduce((accumulator, currentValue) => {
          if (currentValue.tokenize) {
            accumulator[currentValue._id] = [];
          }
          return accumulator;
        }, {});

        if (itemRows && stepRows) {
          forEach(stepRows, stepRow => {
            const itemId = stepRow.involvedItem._id;

            if (stepRow.involvedItem?.parentItem) {
              fieldMappings[itemId].push(stepRow.involvedItem?.parentItem?.name);
            }

            forEach(stepRow?.stepData, stepData => {
              fieldMappings[itemId].push(stepData.name);
            });
          });
        }

        const itemCalls = itemRows.map(async item => {
          const contractId = values[item._id];

          if (fieldMappings[item._id]?.length > 0) {
            await generateMetadataConfig({
              variables: {
                contractId,
                fieldNames: fieldMappings[item._id],
              },
            });
          }

          await updateItem({
            variables: {
              input: {
                _id: item._id,
                name: item.name,
                tokenize: item.tokenize,
                instance: item.instance._id,
                itemType: item.itemType._id,
                codificationType: item.codificationType._id,
                parentItem: item.parentItem?._id,
                tokenContract: contractId,
              },
            },
          });
        });

        await Promise.all(itemCalls);
        await updateInstance({
          variables: {
            instanceId: instanceId,
            status: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
            name: instanceDetails?.content?.name,
          },
        });

        history.push(TRACEABILITY_ROUTE);
      } catch (err) {
        setIsProcessing(false);
        handleNotification(err?.message || t('traceability.instanceTokens.mappingFailed'), 'error');
      }
    },
  });

  if (isDataLoading || itemRows?.length < 1) {
    return <PageLoader />;
  }

  return (
    <WizardProgressBar>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={classes.contentArea}>
            {itemRows.map(item => {
              if (item.tokenize) {
                return (
                  <InstanceTokenBox
                    key={item?._id}
                    item={item}
                    setFieldValue={formik.setFieldValue}
                    dirty={formik.dirty}
                    error={formik.errors[item?._id]}
                  />
                );
              }

              return null;
            })}
          </div>

          <FormErrors form={formik} />
          <div className={classes.buttonArea}>
            <BackButton />
            <Button
              className={classes.continueButton}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!formik?.dirty || !isEmpty(formik?.errors) || isProcessing}
            >
              {t('traceability.instanceTokens.continue')}
            </Button>
            {isProcessing && (
              <>
                <Typography variant="caption">
                  {t('traceability.instanceTokens.processing')}
                </Typography>
                <CircularProgress size={20} thickness={20} color="primary" />
              </>
            )}
          </div>
        </Form>
      </FormikProvider>
    </WizardProgressBar>
  );
};

export default InstanceTokens;
