import { isEmpty, isEqual } from 'lodash';
import { TOKEN_METADATA_FIELDS } from './constants';
import { convertLinksToFields, convertToJsonStr } from './utils';

export const locationDefaultValue = locationDetails => {
  return {
    locationName: locationDetails !== undefined ? locationDetails.name : '',
    latitude: locationDetails !== undefined ? locationDetails.geo.latitude : '',
    longitude: locationDetails !== undefined ? locationDetails.geo.longitude : '',
    addrCountry: locationDetails !== undefined ? locationDetails.address.addrCountry : '',
    addressRegion: locationDetails !== undefined ? locationDetails.address.addressRegion : '',
    addressLocality: locationDetails !== undefined ? locationDetails.address.addressLocality : '',
    postalCode: locationDetails !== undefined ? locationDetails.address.postalCode : '',
    streetAddress: locationDetails !== undefined ? locationDetails.address.streetAddress : '',
  };
};

export const jsonDefaultValues = jsonFieldDetails => {
  let fieldArrayvalues = [];
  if (jsonFieldDetails !== undefined) {
    Object.keys(jsonFieldDetails).forEach(jsonKey => {
      fieldArrayvalues.push({
        key: jsonKey,
        value: jsonFieldDetails[jsonKey],
      });
    });
  } else {
    fieldArrayvalues = [{ key: '', value: '' }];
  }
  return {
    rawMode: false,
    fieldArray: fieldArrayvalues,
    content: JSON.stringify({ '': '' }),
  };
};

export const isSameFieldName = (linkedTokenDetails, metadataName) => {
  let isSameField = false;
  let fieldName;
  if (linkedTokenDetails?.children?.length > 0) {
    fieldName = linkedTokenDetails?.children[0]?.fieldName;
  } else if (linkedTokenDetails?.parent?.length > 0) {
    fieldName = linkedTokenDetails?.parent[0]?.fieldName;
  }
  if (fieldName === metadataName) {
    isSameField = true;
  }
  return isSameField;
};

const linkedTokenValues = (linkedTokenDetails, metadataName) => {
  const linkdTokenDetails = [
    {
      relationship: '',
      relationshipType: '',
      smartContract: '',
      tokenId: '',
      description: '',
    },
  ];
  const result =
    linkedTokenDetails !== null &&
    linkedTokenDetails !== undefined &&
    isSameFieldName(linkedTokenDetails, metadataName)
      ? convertLinksToFields(linkedTokenDetails)
      : linkdTokenDetails;
  return {
    linkedTokenDetails: result,
  };
};

export const generateDefaultMetadataValues = (
  metadataConfigs,
  selectedToken,
  batchMetaData = false,
) => {
  const tokenMetadataInitialValues = {};
  if (metadataConfigs !== undefined && metadataConfigs.length > 0) {
    metadataConfigs.forEach(metadataConfig => {
      let defaultValue;
      let curMetadata;
      if (selectedToken?.[0]?.linkedToken?.metadata || selectedToken?.linkedToken?.metadata) {
        curMetadata = batchMetaData
          ? selectedToken[0]?.linkedToken?.metadata
          : selectedToken?.linkedToken?.metadata;
      } else {
        curMetadata = batchMetaData ? selectedToken[0]?.metadata : selectedToken?.metadata;
      }
      if (metadataConfig?.metadataType?.metadataTypeName) {
        switch (metadataConfig.metadataType.metadataTypeName) {
          case TOKEN_METADATA_FIELDS.BOOLEAN:
            defaultValue = false;
            if (curMetadata && curMetadata[metadataConfig.metadataName]) {
              defaultValue = curMetadata[metadataConfig.metadataName];
            }
            break;
          case TOKEN_METADATA_FIELDS.FREE_TEXT:
            defaultValue = '';
            if (curMetadata && curMetadata[metadataConfig.metadataName]) {
              defaultValue = curMetadata[metadataConfig.metadataName];
            }
            break;
          case TOKEN_METADATA_FIELDS.MEASUREMENT:
            defaultValue = '';
            if (curMetadata && curMetadata[metadataConfig.metadataName]) {
              defaultValue = curMetadata[metadataConfig.metadataName];
            }
            break;
          case TOKEN_METADATA_FIELDS.LOCATION:
            defaultValue = locationDefaultValue(
              curMetadata ? curMetadata[metadataConfig.metadataName] : undefined,
            );
            break;
          case TOKEN_METADATA_FIELDS.JSON:
            defaultValue = jsonDefaultValues(
              curMetadata ? curMetadata[metadataConfig.metadataName] : undefined,
            );
            break;
          case TOKEN_METADATA_FIELDS.LINKED_TOKEN:
            defaultValue = linkedTokenValues(curMetadata?.links, metadataConfig.metadataName);
            break;
          default:
            defaultValue = '';
            break;
        }
      }
      tokenMetadataInitialValues[metadataConfig.metadataName] = defaultValue;
    });
  }
  return tokenMetadataInitialValues;
};

export const prepareMetadataReqObj = (metadataConfigs, values) => {
  const parsedValues = { ...values };
  if (metadataConfigs && metadataConfigs.length > 0) {
    metadataConfigs.forEach(metadataConfig => {
      switch (metadataConfig?.metadataType?.metadataTypeName) {
        case TOKEN_METADATA_FIELDS.JSON:
          if (values[metadataConfig?.metadataName]?.rawMode) {
            parsedValues[metadataConfig?.metadataName] = JSON.parse(
              values[metadataConfig?.metadataName]?.content,
            );
          } else {
            parsedValues[metadataConfig?.metadataName] = JSON.parse(
              convertToJsonStr(values[metadataConfig?.metadataName]?.fieldArray),
            );
          }

          // prevent the default empty json key-value pair from being sent
          if (isEqual(parsedValues[metadataConfig?.metadataName], { '': '' })) {
            delete parsedValues[metadataConfig?.metadataName];
          }
          break;
        case TOKEN_METADATA_FIELDS.LOCATION:
          {
            const locationDetails = values[metadataConfig?.metadataName];
            const restructuredLocationDetails = {
              name: locationDetails.locationName,
              geo: {
                latitude: locationDetails.latitude,
                longitude: locationDetails.longitude,
              },
              address: {
                addrCountry: locationDetails.addrCountry,
                addressRegion: locationDetails.addressRegion,
                addressLocality: locationDetails.addressLocality,
                postalCode: locationDetails.postalCode,
                streetAddress: locationDetails.streetAddress,
              },
            };
            parsedValues[metadataConfig?.metadataName] = restructuredLocationDetails;
          }
          break;
        case TOKEN_METADATA_FIELDS.LINKED_TOKEN:
          parsedValues[metadataConfig?.metadataName] =
            values[metadataConfig?.metadataName]?.linkedTokenDetails;
          break;
        default:
          break;
      }
    });
    if (
      parsedValues.links &&
      isEqual(
        {
          description: '',
          relationship: '',
          relationshipType: '',
          smartContract: '',
          tokenId: '',
        },
        parsedValues.links[0],
      )
    ) {
      parsedValues.links = [];
    }
  }
  return parsedValues;
};

export const validateMetadata = (entry, t) => {
  const errors = {};
  switch (entry.type) {
    case TOKEN_METADATA_FIELDS.MEASUREMENT:
      if (Number.isNaN(Number(entry.value))) {
        errors[entry.name] = t('tokens.validation.measurementMetadataIsNumber', {
          measurement: entry.name,
        });
      }
      break;
    case TOKEN_METADATA_FIELDS.LOCATION:
      if (
        isEmpty(entry.value.locationName) &&
        isEmpty(entry.value.latitude) &&
        isEmpty(entry.value.longitude) &&
        isEmpty(entry.value.addrCountry) &&
        isEmpty(entry.value.streetAddress) &&
        isEmpty(entry.value.addressRegion) &&
        isEmpty(entry.value.addressLocality) &&
        isEmpty(entry.value.postalCode)
      )
        break;
      // eslint-disable-next-line no-case-declarations
      const emptyCoordinates = isEmpty(entry.value.latitude) && isEmpty(entry.value.longitude);
      // eslint-disable-next-line no-case-declarations
      const emptyAddress =
        isEmpty(entry.value.addrCountry) ||
        isEmpty(entry.value.streetAddress) ||
        isEmpty(entry.value.addressRegion) ||
        isEmpty(entry.value.addressLocality) ||
        isEmpty(entry.value.postalCode);
      if (entry.value.locationName === '')
        errors[`${entry.name}.name`] = 'Location name must be provided';
      if (emptyCoordinates && emptyAddress)
        errors[`${entry.name}`] = 'Either coordinates or address needed';
      else if (emptyAddress && (entry.value.latitude === '' || entry.value.longitude === ''))
        errors[`${entry.name}.coordinates`] = 'Both latitude and longitude must be filled out';
      break;
    case TOKEN_METADATA_FIELDS.BOOLEAN:
      break;
    case TOKEN_METADATA_FIELDS.FILE:
      break;
    case TOKEN_METADATA_FIELDS.JSON:
      break;
    case TOKEN_METADATA_FIELDS.FREE_TEXT:
      break;
    case TOKEN_METADATA_FIELDS.LINKED_TOKEN:
      [...entry.value?.linkedTokenDetails].forEach(token => {
        if (
          isEqual(
            {
              description: '',
              relationship: '',
              relationshipType: '',
              smartContract: '',
              tokenId: '',
            },
            token,
          )
        )
          return;
        if (token.relationship === '')
          errors[`${entry.name}Relationship`] = t('tokens.validation.relationshipMetadataIsEmpty', {
            relationshipName: entry.name,
            relationshipField: 'Relationship',
          });
        if (token.relationshipType === '')
          errors[`${entry.name}RelationshipType`] = t(
            'tokens.validation.relationshipMetadataIsEmpty',
            {
              relationshipName: entry.name,
              relationshipField: 'Relationship Type',
            },
          );
        if (token.smartContract === '')
          errors[`${entry.name}SmartContract`] = t(
            'tokens.validation.relationshipMetadataIsEmpty',
            {
              relationshipName: entry.name,
              relationshipField: 'Smart Contract',
            },
          );
        if (token.tokenId === '')
          errors[`${entry.name}TokenId`] = t('tokens.validation.relationshipMetadataIsEmpty', {
            relationshipName: entry.name,
            relationshipField: 'Token ID',
          });
      });
      break;

    default:
      break;
  }
  return errors;
};
