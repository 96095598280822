import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Box } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Table from '@eyblockchain/ey-ui/core/Table';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useLazyQuery } from '@apollo/react-hooks';
import { findIndex, find } from 'lodash';
import clsx from 'clsx';
import PageLoader from '../Shared/PageLoader';
import { CONSTANTS, TAB_VALUES } from '../../constants';
import MetaDataStructure from './MetadataStructure/MetaDataStructure';
import { GET_TOKEN_METADATA_TYPES } from '../../graphql/Tokenization/tokenMetadataTypes';
import { getBlockchainExplorerUrl } from '../../utils';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import ContractPermissions from '../Shared/ContractPermissions';
import useUserInfo from '../../hooks/useUserInfo';
import TokenBatchTable from './TokenBatchTable';
import track from '../../mixpanel';
import { useTokenContext } from '../../contexts/Tokenization/token';

const useStyles = makeStyles(theme => ({
  tokenListRoot: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(2),
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
  },
  tokenListContentArea: {
    marginTop: theme.spacing(2),
  },
  verticalScrollbar: {
    '& .MuiPaper-root > div:nth-child(3)': {
      overflowY: 'auto',
      maxHeight: '250px',
      paddingBottom: '75px',
    },
  },
  tokenStatus: {
    marginRight: '5px',
  },
  liveAnnounce: {
    display: 'none',
  },
  table: {
    padding: '0px',
    '& .MuiTable-root': {
      width: '99%',
      margin: 'auto',
    },
    '& .MuiToolbar-root': {
      marginTop: '20px',
      marginBottom: '20px',
      padding: '0px',
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.primary.contrastText,
          border: `1.1px solid ${theme.palette.primary.main}`,
          boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
          '& $dotMenu': {
            visibility: 'visible',
          },
        },
      },
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-root': {
      padding: '10px',
      fontWeight: '400',
      margin: '5px',
    },
    '& > div': {
      overflow: 'visible',
    },
  },
  tableColumsHidden: {
    '& .MuiTableHead-root': {
      display: 'none',
    },
  },
  loadContainer: {
    height: 300,
  },
  dotMenu: {
    display: 'flex',
    visibility: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'relative',
  },
  dotMenuOptions: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '100%',
  },
  dotMenuOptionButton: {
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    padding: '5px 25px',
    backgroundColor: 'white',
    zIndex: '2',
    '&:hover': {
      backgroundColor: '#F6F6FA',
    },
    whiteSpace: 'no-wrap',
    minWidth: 'max-content',
  },
  mintDescription: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  processing: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  circularLoader: {
    marginRight: theme.spacing(3),
  },
  bulkMinting: {
    paddingLeft: '25%',
    background: '#F6F6FA',
  },
  activeChip: { background: 'rgb(22 134 54)', color: '#fff' },
  pendingChip: { background: '#FF9831' },
  icon: {
    height: '15px',
    width: '15px',
    color: 'inherit',
  },
  status: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  countNumbers: {
    fontWeight: 'lighter',
  },
  tabs: {
    '& .MuiTabs-scrollButtons.Mui-disabled': {
      opacity: 0.3,
    },
  },
  lightColor: {
    color: theme.palette.primary.light,
  },
  progressRoot: {
    '& .MuiButton-outlinedPrimary': {
      border: '0px',
    },
  },
  inProgressBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
  },
  inProgressBanner: {
    marginRight: theme.spacing(1),
  },
}));

const TokenSearch = ({ searchText, onSearch }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTextChange = event => {
    onSearch(event.target.value);
  };

  return (
    <div>
      <MUITextField
        variant="outlined"
        select={false}
        placeholder={t('tokens.searchTokens')}
        className={classes.searchText}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchText || ''}
        onChange={handleTextChange}
      />
    </div>
  );
};

TokenSearch.propTypes = {
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

TokenSearch.defaultProps = {
  searchText: '',
};

const TokenList = ({
  tokenList,
  tokenListLoading,
  selectedContract,
  setSelectedToken,
  isMultipleSelection,
  getSelectedTokens,
  preSelectTokens,
  isBuyer,
  purchaseOrder,
  tokenBatchList,
  updateBatchMetadataHandler,
  setTokensSelectedForProcess,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { chainTransactionProgressing } = useTokenContext();

  const { activeWallet, activeOrganization } = useBecOrganizationContext();
  const {
    permissionsFlags: { isUserAuthToSetERC721Metadata, isUserAuthToSetERC1155Metadata },
  } = useUserInfo();

  const { visualizerDashboardAccess } = useSubscriptionContext();
  const [metaDataTypes, setMetadataTypes] = useState();
  const [measurementOptions, setMeasurementOptions] = useState();
  const isERC1155 =
    selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;

  const isUserAuthToSetERCMetadata = isERC1155
    ? isUserAuthToSetERC1155Metadata
    : isUserAuthToSetERC721Metadata;

  const [getTokenMetaDataTypes, { loading: isMetadataTypesLoading }] = useLazyQuery(
    GET_TOKEN_METADATA_TYPES,
    {
      onCompleted: async data => {
        const metaDataTypeOptions = [];
        const measurements = [];
        if (data?.tokenMetadataTypes) {
          data.tokenMetadataTypes.map(metaDataType => {
            if (metaDataType.metadataTypeName === 'tokens.metaDataTypes.measurement') {
              metaDataType.metadataTypeOptions.forEach(element => {
                measurements.push({
                  label: t(element),
                  value: element,
                });
              });
              setMeasurementOptions(measurements);
            }
            metaDataTypeOptions.push({
              label: t(metaDataType.metadataTypeName),
              value: metaDataType._id,
              key: metaDataType.metadataTypeName,
            });
            return metaDataType;
          });
        }
        setMetadataTypes(metaDataTypeOptions);
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    getTokenMetaDataTypes();
  }, []);

  const tokenCount = tokenList?.length;
  let tableData = [];
  const tableBatchData = [];

  tokenBatchList.forEach((batch, key) => {
    const sccessCount = batch.tokens.filter(id => id.mintSuccess === true).length;
    const pendingTokens = batch.tokens.filter(
      id => id.latestTransaction.status === 'pending' && id.mintSuccess === false,
    );
    tableBatchData.push({
      batchNumber: batch.tokenBatchId,
      status: {
        batchStatus: pendingTokens && pendingTokens.length > 0 ? 'pending' : 'minted',
        successCount: sccessCount,
        totalCount: batch.tokens.length,
      },
      metadataUpdate: batch.tokens,
      batchUniqueNo: key + 1,
    });
  });

  const tabs = [
    {
      label: `${t('tokens.tokenList')} (${
        isBuyer ? preSelectTokens[0]?.tokenIds?.length : tokenCount
      })`,
      name: TAB_VALUES.CONTRACT_PANEL.LIST,
      id: 'tokenlist_tab',
    },
  ];

  if (!isERC1155) {
    tabs.push({
      label: `${t('tokens.batches')} (${
        isBuyer ? preSelectTokens[0]?.tokenIds?.length : tokenBatchList.length
      })`,
      name: TAB_VALUES.CONTRACT_PANEL.BATCHES,
      id: 'batches_tab',
    });
  }

  if (
    (purchaseOrder && !isBuyer) ||
    selectedContract?.contract?.organizationId === activeOrganization?._id
  ) {
    tabs.push({
      label: t('common.permissions'),
      name: TAB_VALUES.PERMISSIONS,
      id: 'permission_tab',
    });
  }

  // TODO: update permissions for ERC1155
  if (isUserAuthToSetERCMetadata) {
    tabs.push({
      label: t('tokens.metadataStructure'),
      name: TAB_VALUES.CONTRACT_PANEL.METADATA_STRUCTURE,
      id: 'metadata_tab',
    });
  }

  const [activeTab, setActiveTab] = useState(TAB_VALUES.CONTRACT_PANEL.LIST);
  const [selectedRows, setSelectedRows] = useState([]);

  const tabChangeHandler = (event, newTabIndex) => {
    event.preventDefault();
    const newTabName = tabs[newTabIndex]?.name;
    setActiveTab(newTabName);
    if (newTabName === TAB_VALUES.CONTRACT_PANEL.BATCH) {
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.ACCESS_BATCH_DETAILS, {
        blockchainNetwork: activeWallet?.network,
        contractId: selectedContract?.contract?._id,
      });
    }
  };

  const handleDetails = selectedTokenId => {
    const newTokenSelection = find(tokenList, { _id: selectedTokenId });
    setSelectedToken(newTokenSelection);
  };

  const handleHyperLink = link => {
    const win = window.open(`${getBlockchainExplorerUrl(activeWallet.network)}/${link}`, '_blank');
    win.focus();
  };

  const handleExploreData = selectedToken => {
    const win = window.open(
      `${window.config.vizualizerTokensUrl}${selectedToken?.tokenId}/dashboard?sc=${selectedToken?.smartContract?.contractAddress}`,
      '_blank',
    );
    win.focus();
  };

  useEffect(() => {
    if (tokenList.length > 0) {
      const tokenSmartContract = preSelectTokens.find(
        token => token.smartContractId?.toString() === tokenList[0].smartContract._id.toString(), // token list will be always belongs to single smart contract.
      );
      const tokenIds = tokenSmartContract?.tokenIds || [];
      const rows = tokenList.map((token, index) =>
        tokenIds.indexOf(token._id) === -1 ? -1 : index,
      );
      setSelectedRows(rows);
    }
  }, [tokenList]);

  const columnDefs = () => {
    const renameColumnLabel = newName => {
      return {
        customHeadLabelRender: () => newName,
      };
    };

    const customOptions = () => {
      return {
        filter: false,
      };
    };

    const hiddenBody = () => ({
      filter: false,
      display: false,
    });

    const customStatusBody = () => {
      const options = customOptions();

      options.setCellProps = () => {
        return {
          align: 'right',
        };
      };

      options.customBodyRender = (rowStatus, rowMeta) => {
        let actionComponent;
        switch (rowStatus) {
          case CONSTANTS.CONTRACT_STATUSES.PENDING:
            actionComponent = <CircularProgress size={20} thickness={20} />;
            break;
          case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
            actionComponent = (
              <div className={classes.dotMenu}>
                <Button
                  id="btn_tokenOptions"
                  variant="outlined"
                  size="small"
                  style={{ minWidth: '2rem' }}
                  onClick={() => {
                    const control = document.getElementById(`dotMenuOptions_${rowMeta?.rowIndex}`);
                    control.style.display = 'flex';
                  }}
                >
                  ...
                </Button>
                <div
                  className={classes.dotMenuOptions}
                  id={`dotMenuOptions_${rowMeta?.rowIndex}`}
                  style={{ display: 'none' }}
                >
                  <Button
                    id="btn_tokenDetails"
                    variant="outlined"
                    size="small"
                    className={classes.dotMenuOptionButton}
                    onClick={() => handleDetails(rowMeta?.rowData[0])}
                  >
                    {t('tokens.showTokenDetails')}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.dotMenuOptionButton}
                    onClick={() =>
                      handleHyperLink(
                        find(tokenList, { _id: rowMeta?.rowData[0] }).latestTransaction
                          .transactionHash,
                      )
                    }
                  >
                    {t('tokens.seeBlockchainExplorer')}
                  </Button>
                  {visualizerDashboardAccess && (
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.dotMenuOptionButton}
                      onClick={() =>
                        handleExploreData(find(tokenList, { _id: rowMeta?.rowData[0] }))
                      }
                    >
                      {t('tokens.explore')}
                    </Button>
                  )}
                </div>
              </div>
            );
            break;
          default:
            break;
        }
        return <div className={classes.tokenStatus}>{actionComponent}</div>;
      };
      return options;
    };

    const customBalanceBody = () => {
      const options = customOptions();
      options.customBodyRender = rowStatus => {
        return (
          <Box component="div" sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            {rowStatus && (
              <>
                <Typography className={classes.lightColor}>|</Typography>
                <Typography variant="body2" className={classes.lightColor}>
                  {t('tokens.amountWithColon')}
                </Typography>
                <Typography variant="body2">{rowStatus}</Typography>
              </>
            )}
          </Box>
        );
      };
      return options;
    };

    return [
      {
        name: 'id',
        options: hiddenBody(),
      },
      {
        name: 'serialNumber',
        label: '',
        options: { ...customOptions(), ...renameColumnLabel(t('common.selectAll')) },
      },
      ...(isERC1155
        ? [
            {
              name: 'balance',
              label: '',
              options: { ...customBalanceBody(), ...renameColumnLabel('') },
            },
          ]
        : []),
      {
        name: 'status',
        label: '',
        options: { ...customStatusBody(), ...renameColumnLabel('') },
      },
      // {
      //   name: 'id',
      //   options: hiddenBody(),
      // },
    ];
  };
  const getTokenList = () => {
    if (chainTransactionProgressing) {
      return (
        <div className={classes.progressRoot}>
          <div className={classes.inProgressBox}>
            <Typography variant="body1" className={classes.inProgressBanner}>
              {t('tokens.transferUpdateInProgress')}
            </Typography>
            <CircularProgress size={20} thickness={10} />
          </div>
        </div>
      );
    }
    return (
      <>
        <Table
          columns={columnDefs()}
          data={tableData}
          className={clsx(
            classes.table,
            (!isERC1155 || tableData.length === 0) && classes.tableColumsHidden,
          )}
          classes={{
            liveAnnounce: classes.liveAnnounce,
          }}
          options={{
            filter: false,
            rowsSelected: selectedRows,
            selectableRows: !isMultipleSelection || isBuyer ? 'none' : 'multiple',
            isRowSelectable: dataIndex =>
              tableData[dataIndex].status !== CONSTANTS.CONTRACT_STATUSES.PENDING,
            selectableRowsHeader: isERC1155,
            selectToolbarPlacement: 'none',
            sort: false,
            search: false,
            searchOpen: true,
            pagination: !isERC1155,
            elevation: 0,
            onChangePage: () => {},
            onRowSelectionChange: (currentClick, allRows, rowsSelected) => {
              const curSelectedTokens = rowsSelected.map(index => tokenList[index]);
              getSelectedTokens(curSelectedTokens);
              setTokensSelectedForProcess(curSelectedTokens);
              setSelectedRows(rowsSelected);
            },
            setRowProps: (row, dataIndex) => {
              return {
                onMouseLeave: () => {
                  const controlDropDown = document.getElementById(`dotMenuOptions_${dataIndex}`);
                  if (controlDropDown) {
                    controlDropDown.style.display = 'none';
                  }
                },
              };
            },
            customToolbar: () => null,
            customSearchRender: (searchText, handleSearch, hideSearch, options) => {
              return (
                <TokenSearch searchText={searchText} onSearch={handleSearch} options={options} />
              );
            },
            textLabels: {
              body: {
                noMatch: t('tokens.noTokenRecords'),
              },
            },
          }}
        />
      </>
    );
  };
  //  TO-DO: Panel content for ERC 20 tokens to be decided - https://eyblockchain.atlassian.net/browse/DV-1168
  if (selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC20) {
    return <></>;
  }

  if (tokenListLoading) {
    return <PageLoader classes={{ loadContainer: classes.loadContainer }} />;
  }

  tableData = tokenList?.map(token => {
    return {
      serialNumber: token?.tokenId,
      status: token?.latestTransaction?.status,
      id: token?._id,
      ...(isERC1155 && {
        balance: token?.owners1155 && token?.owners1155[0]?.balance,
      }),
    };
  });

  return (
    <Card variant="outlined" className={classes.tokenListRoot}>
      <Tabs
        id="tab_items"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
        value={findIndex(tabs, { name: activeTab })}
        onChange={tabChangeHandler}
        classes={{
          indicator: classes.indicator,
        }}
      >
        {tabs.map(({ label, id }) => (
          <Tab label={label} key={uniqid()} id={id} />
        ))}
      </Tabs>

      <div className={clsx(classes.tokenListContentArea, isERC1155 && classes.verticalScrollbar)}>
        {activeTab === TAB_VALUES.CONTRACT_PANEL.LIST && getTokenList()}
        {activeTab === TAB_VALUES.CONTRACT_PANEL.BATCHES && (
          <TokenBatchTable
            batchData={tableBatchData}
            updateBatchMetadataHandler={updateBatchMetadataHandler}
            isUserAuthToSetERCMetadata={isUserAuthToSetERCMetadata}
          />
        )}
        {activeTab === TAB_VALUES.PERMISSIONS && (
          <ContractPermissions selectedContract={selectedContract?.contract} />
        )}
        {activeTab === TAB_VALUES.CONTRACT_PANEL.METADATA_STRUCTURE && (
          <MetaDataStructure
            selectedContract={selectedContract}
            isMetadataTypesLoading={isMetadataTypesLoading}
            metaDataTypes={metaDataTypes}
            measurementOptions={measurementOptions}
          />
        )}
      </div>
    </Card>
  );
};

TokenList.propTypes = {
  tokenList: PropTypes.arrayOf(
    PropTypes.shape({
      smartContract: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
  ),
  tokenListLoading: PropTypes.bool.isRequired,
  selectedContract: PropTypes.shape({
    contract: PropTypes.shape({
      _id: PropTypes.string,
      tokenType: PropTypes.string,
      organizationId: PropTypes.string,
    }),
  }).isRequired,
  setSelectedToken: PropTypes.func.isRequired,
  isMultipleSelection: PropTypes.bool,
  getSelectedTokens: PropTypes.func,
  /*
   * For preselecting the tokens when we have multiselect
   */
  preSelectTokens: PropTypes.arrayOf(PropTypes.shape([])),
  isBuyer: PropTypes.bool,
  purchaseOrder: PropTypes.shape({}),
  tokenBatchList: PropTypes.arrayOf(
    PropTypes.shape({
      smartContract: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  updateBatchMetadataHandler: PropTypes.func,
  setTokensSelectedForProcess: PropTypes.func,
};

TokenList.defaultProps = {
  isMultipleSelection: false,
  tokenList: [],
  getSelectedTokens: () => {},
  preSelectTokens: [],
  isBuyer: false,
  purchaseOrder: null,
  updateBatchMetadataHandler: () => {},
  setTokensSelectedForProcess: () => {},
};

export default TokenList;
