import gql from 'graphql-tag';

export const TOKEN_CONTRACT_ATTRIBUTES = gql`
  fragment TOKEN_CONTRACT_ATTRIBUTES on SmartContract {
    _id
    organizationId
    tokenType
    tokenSymbol
    contractName
    contractAddress
    transaction {
      _id
      status
      transactionHash
    }
    latestMintersTransaction {
      _id
      status
      transactionHash
    }
    minters {
      organizationId
      partnerOrganization {
        name
        wallets {
          address
          network
          networkId
        }
      }
    }
    metadataStructure {
      behavior
      version
      createdAt
      updatedAt
      metadataConfig {
        metadataName
        metadataMutability
        metadataPermission
        metadataType {
          _id
          metadataTypeName
          metadataTypeOptions
        }
        chosenMetadataOption
        step {
          _id
          name
          involvedItem {
            _id
          }
        }
      }
      version
    }
    collaborators {
      partner {
        _id
      }
      role
    }
  }
`;

export const ERC_TOKEN_LIST_ATTRIBUTES = gql`
  fragment ERC_TOKEN_LIST_ATTRIBUTES on ErcToken {
    _id
    blockchainType
    tokenType
    tokenId
    metadata
    metadataHash
    mintSuccess
    tokenBatchId
    latestOwner
    latestReceivedBy
    latestTransferReason
    tokenBatchId
    status
    smartContract {
      _id
      contractName
      contractAddress
      chainId
      tokenType
      metadataStructure {
        behavior
        version
        createdAt
        updatedAt
        metadataConfig {
          metadataName
          metadataMutability
          metadataPermission
          metadataType {
            _id
            metadataTypeName
            metadataTypeOptions
          }
          chosenMetadataOption
          step {
            _id
            name
            involvedItem {
              _id
            }
          }
        }
        version
      }
    }
    latestTransaction {
      _id
      transactionHash
      status
      transactionType
      chainId
    }
    latestMetadataTransaction {
      _id
      transactionHash
      status
      transactionType
      chainId
    }
    commitment {
      ercAddress
      tokenId
      value
      zkpPublicKey
    }
    metadataUrls {
      publicMutable
      publicImmutable
      privateImmutable
      privateMutable
    }
    pendingZkpTransaction
    purchaseOrder {
      id
      buyerId
      supplierId
    }
    metadataStructure {
      behavior
      version
      createdAt
      updatedAt
      metadataConfig {
        metadataName
        metadataMutability
        metadataPermission
        metadataType {
          _id
          metadataTypeName
          metadataTypeOptions
        }
        chosenMetadataOption
        step {
          _id
          name
          involvedItem {
            _id
          }
        }
      }
    }
    owners1155 {
      owner
      balance
    }
    linkedToken {
      _id
      metadata
      metadataStructure {
        metadataConfig {
          metadataName
          metadataPermission
          metadataMutability
          metadataType {
            _id
            metadataTypeName
            metadataTypeOptions
          }
          chosenMetadataOption
        }
      }
    }
    linkedContract {
      _id
      contractName
      metadataStructure {
        metadataConfig {
          metadataName
          metadataPermission
          metadataMutability
          metadataType {
            _id
            metadataTypeName
            metadataTypeOptions
          }
          chosenMetadataOption
        }
      }
    }
  }
`;

export const TOKEN_METADATA_ATTRIBUTES = gql`
  fragment TOKEN_METADATA_ATTRIBUTES on TokenMetadata {
    metadata
    metadataHash
  }
`;

export const TOKEN_URI_ATTRIBUTES = gql`
  fragment TOKEN_URI_ATTRIBUTES on TokenURI {
    tokenId
    tokenUri
  }
`;

export const ERC_TOKEN_ATTRIBUTES = gql`
  fragment ERC_TOKEN_ATTRIBUTES on ErcTokenContract {
    contract {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
    balance
    tokenName
    tokenSymbol
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE = gql`
  fragment ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE on ErcTokenContract {
    contract {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
    tokenName
    tokenSymbol
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const ERC721_TRANSACTION_ATTRIBUTES = gql`
  fragment ERC721_TRANSACTION_ATTRIBUTES on Transaction {
    transactionHash
    contractAddress
    status
    tokenId
    tokenIds
  }
`;

export const TOKEN_TRANSACTION_ATTRIBUTES = gql`
  fragment TOKEN_TRANSACTION_ATTRIBUTES on Transaction {
    transactionHash
    contractAddress
    status
    amount
    transactionType
    tokenId
  }
`;

export const ERC_1155_PERMISSION_ATTRIBUTES = gql`
  fragment ERC_1155_PERMISSION_ATTRIBUTES on PermissionUpdateResponse {
    chainResponse {
      address
      role
      chainHash
      roleStatus
    }
    contract {
      _id
      auth0Id
      contractAddress
      tokenType
      chainId
      blockchainType
      organizationId
      networkId
      contractName
      collaborators {
        role
        partner {
          _id
        }
      }
      baseUri
      tokenSymbol
      contractId
    }
  }
`;

export const DEPLOY_ERC721 = gql`
  mutation deployErc721Eth($tokenName: String!, $tokenSymbol: String!, $baseURI: String) {
    deployErc721Eth(tokenName: $tokenName, tokenSymbol: $tokenSymbol, baseURI: $baseURI) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC1155 = gql`
  mutation deployERC1155Contract($tokenName: String!, $tokenSymbol: String!, $baseURI: String!) {
    deployERC1155Contract(
      contractName: $tokenName
      contractSymbol: $tokenSymbol
      baseURI: $baseURI
    ) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC721_BCOS = gql`
  mutation deployErc721Bcos($tokenName: String!, $tokenSymbol: String!) {
    deployErc721Bcos(tokenName: $tokenName, tokenSymbol: $tokenSymbol) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC721_BSN = gql`
  mutation deployErc721Bsn($tokenName: String!, $tokenSymbol: String!) {
    deployErc721Bsn(tokenName: $tokenName, tokenSymbol: $tokenSymbol) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC20 = gql`
  mutation deployErc20Eth($tokenName: String!, $tokenSymbol: String!) {
    deployErc20Eth(tokenName: $tokenName, tokenSymbol: $tokenSymbol) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC20_BCOS = gql`
  mutation deployErc20Bcos($tokenName: String!, $tokenSymbol: String!) {
    deployErc20Bcos(tokenName: $tokenName, tokenSymbol: $tokenSymbol) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_ERC20_BSN = gql`
  mutation deployErc20Bsn($tokenName: String!, $tokenSymbol: String!) {
    deployErc20Bsn(tokenName: $tokenName, tokenSymbol: $tokenSymbol) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const SET_MINTERS = gql`
  mutation setMinters($input: inputPartnerMinters!) {
    setMinters(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_MINTERS_BCOS = gql`
  mutation setMintersBcos($input: inputPartnerMinters!) {
    setMintersBcos(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_MINTERS_BSN = gql`
  mutation setMintersBsn($input: inputMinters!) {
    setMintersBsn(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_TOKEN_METADATA_CONFIG = gql`
  mutation setMetadataConfig($input: inputMetadataConfig!) {
    setMetadataConfig(input: $input) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const SET_TOKEN_METADATA_ERC1155 = gql`
  mutation setERC1155Metadata($input: inputTokenMetadataERC1155!) {
    setERC1155Metadata(input: $input) {
      updateTriggeredOnChain
      newMetadata
      metadataTransaction {
        ...ERC721_TRANSACTION_ATTRIBUTES
      }
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_TOKEN_METADATA_ERC721 = gql`
  mutation setTokenMetadataERC721($input: inputTokenMetadataERC721!) {
    setTokenMetadataERC721(input: $input) {
      updateTriggeredOnChain
      newMetadata
      metadataTransaction {
        ...ERC721_TRANSACTION_ATTRIBUTES
      }
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_TOKEN_BULK_METADATA_ERC721 = gql`
  mutation setTokenBulkMetadataERC721($input: inputTokenBulkMetadataERC721!) {
    setTokenBulkMetadataERC721(input: $input) {
      updateTriggeredOnChain
      newMetadata
      metadataTransaction {
        ...ERC721_TRANSACTION_ATTRIBUTES
      }
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const MINT_ERC1155 = gql`
  mutation mintERC1155Token($input: inputMintERC1155!) {
    mintERC1155Token(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const MINT_ERC721 = gql`
  mutation mintErc721($input: inputMintErc721!) {
    mintErc721(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const BURN_ERC721 = gql`
  mutation burnErc721($input: inputBurnErc721!) {
    burnErc721(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const BURN_ERC1155 = gql`
  mutation burnERC1155Token($contractAddress: String!, $tokens: [inputERC1155Token!]) {
    burnERC1155Token(contractAddress: $contractAddress, tokens: $tokens) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const BATCH_MINT_ERC721 = gql`
  mutation batchMintErc721Tokens($input: inputBatchMintErc721!) {
    batchMintErc721Tokens(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const DEPOSIT_ERC721 = gql`
  mutation depositErc721($tokenId: String!, $contractId: String!) {
    depositErc721(tokenId: $tokenId, contractId: $contractId) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const WITHDRAW_ERC721 = gql`
  mutation withdrawErc721($tokenId: String!, $contractAddress: String!) {
    withdrawErc721(tokenId: $tokenId, contractAddress: $contractAddress) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const TRANSFER_ERC721 = gql`
  mutation transferErc721($input: inputTransferErc721!) {
    transferErc721(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC1155 = gql`
  mutation transferERC1155Token($input: inputTransferERC1155!) {
    transferERC1155Token(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const ZKP_TRANSFER_ERC721 = gql`
  mutation zkpSingleTransferErc721($input: inputTransferErc721!) {
    zkpSingleTransferErc721(input: $input) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

// Deprecated in favor of SET_TOKEN_METADATA_ERC721
export const UPDATE_ERC721_TOKEN_METADATA = gql`
  mutation updateERC721TokenMetadata($input: inputUpdateMetadataErc721!) {
    updateERC721TokenMetadata(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const SET_TOKEN_METADATA_ERC721_BCOS = gql`
  mutation setTokenMetadataERC721Bcos($input: inputTokenMetadataERC721!) {
    setTokenMetadataERC721Bcos(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

// Deprecated in favor of SET_TOKEN_METADATA_ERC721
export const UPDATE_ERC721_TOKEN_METADATA_BCOS = gql`
  mutation updateERC721TokenMetadataBcos($input: inputUpdateMetadataErc721!) {
    updateERC721TokenMetadataBcos(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

// Deprecated in favor of SET_TOKEN_METADATA_ERC721
export const UPDATE_ERC721_TOKEN_METADATA_BSN = gql`
  mutation updateERC721TokenMetadataBsn($input: inputUpdateMetadataErc721!) {
    updateERC721TokenMetadataBsn(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const GET_ERC_TOKENS = gql`
  query ercTokenContracts {
    ercTokenContracts {
      ...ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE
    }
  }
  ${ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE}
`;

export const GET_ERC_TOKENS_FROM_PO = gql`
  query ercTokenContractsFromPurchaseOrder(
    $purchaseOrderId: String!
    $buyerOrganizationId: String!
    $supplierOrganizationId: String!
    $smartContractId: String!
  ) {
    ercTokenContractsFromPurchaseOrder(
      purchaseOrderId: $purchaseOrderId
      buyerOrganizationId: $buyerOrganizationId
      supplierOrganizationId: $supplierOrganizationId
      smartContractId: $smartContractId
    ) {
      ...ERC_TOKEN_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_ATTRIBUTES}
`;

export const GET_ERC_TOKENS_BY_PAGE = gql`
  query getTokenContractsByPage($pageNumber: Int!, $contractsPerPage: Int!) {
    getTokenContractsByPage(pageNumber: $pageNumber, contractsPerPage: $contractsPerPage) {
      total
      tokenContracts {
        ...ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE
      }
    }
  }
  ${ERC_TOKEN_ATTRIBUTES_WITHOUT_BALANCE}
`;

export const TOKEN_METADATA = gql`
  query tokenMetadata($contractAddress: String!, $tokenId: BigInt!) {
    tokenMetadata(contractAddress: $contractAddress, tokenId: $tokenId) {
      ...TOKEN_METADATA_ATTRIBUTES
    }
  }
  ${TOKEN_METADATA_ATTRIBUTES}
`;

export const PUBLIC_TOKEN_METADATA = gql`
  query publicTokenMetadata($contractAddress: String!, $tokenId: BigInt!, $mutability: String!) {
    publicTokenMetadata(
      contractAddress: $contractAddress
      tokenId: $tokenId
      mutability: $mutability
    ) {
      ...TOKEN_METADATA_ATTRIBUTES
    }
  }
  ${TOKEN_METADATA_ATTRIBUTES}
`;

export const PRIVATE_TOKEN_METADATA = gql`
  query privateTokenMetadata($contractAddress: String!, $tokenId: BigInt!, $mutability: String!) {
    privateTokenMetadata(
      contractAddress: $contractAddress
      tokenId: $tokenId
      mutability: $mutability
    ) {
      ...TOKEN_METADATA_ATTRIBUTES
    }
  }
  ${TOKEN_METADATA_ATTRIBUTES}
`;

export const TOKEN_URI = gql`
  query tokenURI($contractAddress: String!, $tokenId: BigInt!) {
    tokenURI(contractAddress: $contractAddress, tokenId: $tokenId) {
      ...TOKEN_URI_ATTRIBUTES
    }
  }
  ${TOKEN_URI_ATTRIBUTES}
`;

export const GET_ERC1155_TOKEN_LIST = gql`
  query getTokensInERC1155Contract($contractAddress: String!) {
    getTokensInERC1155Contract(contractAddress: $contractAddress) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_ERC721_TOKEN_LIST = gql`
  query erc721TokenList(
    $smartContractId: String!
    $purchaseOrderId: String
    $buyerOrganizationId: String
    $supplierOrganizationId: String
    $sku: String
  ) {
    erc721TokenList(
      smartContractId: $smartContractId
      purchaseOrderId: $purchaseOrderId
      buyerOrganizationId: $buyerOrganizationId
      supplierOrganizationId: $supplierOrganizationId
      sku: $sku
    ) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_ERC721_BATCH_TOKEN_LIST = gql`
  query erc721BatchTokenList(
    $smartContractId: String!
    $purchaseOrderId: String
    $buyerOrganizationId: String
    $supplierOrganizationId: String
    $sku: String
  ) {
    erc721BatchTokenList(
      smartContractId: $smartContractId
      purchaseOrderId: $purchaseOrderId
      buyerOrganizationId: $buyerOrganizationId
      supplierOrganizationId: $supplierOrganizationId
      sku: $sku
    ) {
      tokenBatchId
      tokens {
        ...ERC_TOKEN_LIST_ATTRIBUTES
      }
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_ERC721_TOKEN_LIST_FROM_OTHER_ORGANIZATION = gql`
  query erc721TokenListFromOtherOrganization(
    $smartContractId: String!
    $purchaseOrderId: String!
    $organizationId: String!
    $buyerOrganizationId: String!
    $supplierOrganizationId: String!
    $sku: String
  ) {
    erc721TokenListFromOtherOrganization(
      smartContractId: $smartContractId
      purchaseOrderId: $purchaseOrderId
      organizationId: $organizationId
      buyerOrganizationId: $buyerOrganizationId
      supplierOrganizationId: $supplierOrganizationId
      sku: $sku
    ) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const DISSOCIATE_SMART_CONTRACT_TO_PO = gql`
  mutation dissociateERC721SmartContractPurchaseOrder($input: inputUpdateErc721!) {
    dissociateERC721SmartContractPurchaseOrder(input: $input) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_ERC721_TOKEN_LIST_ASSOCIATED = gql`
  query erc721TokenListAssociated($smartContractId: String!, $purchaseOrderId: String!) {
    erc721TokenListAssociated(
      smartContractId: $smartContractId
      purchaseOrderId: $purchaseOrderId
    ) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_ERC721_TOKEN_LIST_NOT_ASSOCIATED = gql`
  query erc721TokenListNotAssociated($smartContractId: String!) {
    erc721TokenListNotAssociated(smartContractId: $smartContractId) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_SINGLE_ERC721_TOKEN = gql`
  query getSingleErc721Token($contractAddress: String!, $tokenId: BigInt!) {
    getSingleErc721Token(contractAddress: $contractAddress, tokenId: $tokenId) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_SINGLE_ERC1155_TOKEN = gql`
  query getSingleTokenInERC1155Contract($contractAddress: String!, $tokenId: BigInt!) {
    getSingleTokenInERC1155Contract(contractAddress: $contractAddress, tokenId: $tokenId) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GET_SINGLE_TOKEN_CONTRACT_DETAILS = gql`
  query getSingleTokenContractInDetails($contractAddress: String!) {
    getSingleTokenContractInDetails(contractAddress: $contractAddress) {
      ...ERC_TOKEN_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_ATTRIBUTES}
`;

export const GET_SINGLE_TOKEN_CONTRACT = gql`
  query getSingleTokenContract($smartContractId: String!) {
    getSingleTokenContract(smartContractId: $smartContractId) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const ASSOCIATE_TOKEN_LIST_TO_PO = gql`
  mutation associateERC721TokenPurchaseOrder($input: inputUpdateErc721!) {
    associateERC721TokenPurchaseOrder(input: $input) {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const MINT_ERC721_BCOS = gql`
  mutation mintErc721Bcos($input: inputMintErc721!) {
    mintErc721Bcos(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const MINT_ERC721_BSN = gql`
  mutation mintErc721Bsn($input: inputMintErc721!) {
    mintErc721Bsn(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC721_BCOS = gql`
  mutation transferErc721Bcos($input: inputTransferErc721!) {
    transferErc721Bcos(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC721_BSN = gql`
  mutation transferErc721Bsn($input: inputTransferErc721!) {
    transferErc721Bsn(input: $input) {
      ...ERC721_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC721_TRANSACTION_ATTRIBUTES}
`;

export const GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS = gql`
  subscription onSmartContractDeployed {
    smartContractDeployed {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const GET_SMART_CONTRACT_PERMISSION_UPDATED = gql`
  subscription onSmartContractPermissionsUpdated {
    smartContractPermissionsUpdated {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const TRANSACTION_STATUS = gql`
  subscription transactionStatus {
    transactionStatus {
      ...TOKEN_TRANSACTION_ATTRIBUTES
    }
  }
  ${TOKEN_TRANSACTION_ATTRIBUTES}
`;

export const DEPOSIT_STATUS = gql`
  subscription depositStatus {
    depositStatus {
      contractAddress
      tokenId
      success
      message
    }
  }
`;

export const WITHDRAW_STATUS = gql`
  subscription withdrawStatus {
    withdrawStatus {
      contractAddress
      tokenId
      success
      message
    }
  }
`;

export const ZKP_TRANSACTION_STATUS = gql`
  subscription zkpTransactionStatus {
    zkpTransactionStatus {
      type
      contractAddress
      tokenId
      success
      message
    }
  }
`;

export const ERC20_TRANSACTION_ATTRIBUTES = gql`
  fragment ERC20_TRANSACTION_ATTRIBUTES on Transaction {
    transactionHash
    contractAddress
    status
    smartcontractType
    transactionType
    amount
  }
`;

export const MINT_ERC20 = gql`
  mutation mintERC20Token($input: inputMintERC20Token!) {
    mintERC20Token(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const BURN_ERC20 = gql`
  mutation burnERC20Token($input: inputBurnErc20!) {
    burnERC20Token(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const MINT_ERC20_BCOS = gql`
  mutation mintERC20TokenBcos($input: inputMintERC20Token!) {
    mintERC20TokenBcos(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const MINT_ERC20_BSN = gql`
  mutation mintERC20TokenBsn($input: inputMintERC20Token!) {
    mintERC20TokenBsn(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC20 = gql`
  mutation transferErc20($input: inputTransferErc20!) {
    transferErc20(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC20_BCOS = gql`
  mutation transferErc20Bcos($input: inputTransferErc20!) {
    transferErc20Bcos(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const TRANSFER_ERC20_BSN = gql`
  mutation transferErc20Bsn($input: inputTransferErc20!) {
    transferErc20Bsn(input: $input) {
      ...ERC20_TRANSACTION_ATTRIBUTES
    }
  }
  ${ERC20_TRANSACTION_ATTRIBUTES}
`;

export const SMART_CONTRACT_CONFIG_STRUCTURE_UPDATED = gql`
  subscription onSmartContractConfigUpdated {
    smartContractConfigUpdated {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const ERC_721_META_DATA_UPDATED = gql`
  subscription onErc721metadataUpdated {
    erc721metadataUpdated {
      ...ERC_TOKEN_LIST_ATTRIBUTES
    }
  }
  ${ERC_TOKEN_LIST_ATTRIBUTES}
`;

export const GENERATE_METADATA_CONFIG = gql`
  mutation generateMetadataConfig($contractId: String!, $fieldNames: [String]) {
    generateMetadataConfig(contractId: $contractId, fieldNames: $fieldNames) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const ADD_COLLABORATORS = gql`
  mutation addCollaborators($smartContractAddress: String!, $collabList: [inputCollaboratorInfo!]) {
    addCollaborators(smartContractAddress: $smartContractAddress, collaboratorList: $collabList) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const REMOVE_COLLABORATORS = gql`
  mutation removeCollaborator($smartContractAddress: String!, $partnerToRemove: String!) {
    removeCollaborator(smartContractAddress: $smartContractAddress, partnerId: $partnerToRemove) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const CHANGE_COLLABORATOR_ROLE = gql`
  mutation changeCollaboratorRole(
    $smartContractAddress: String!
    $newCollaboratorInfo: inputCollaboratorInfo!
  ) {
    changeCollaboratorRole(
      smartContractAddress: $smartContractAddress
      newCollaboratorInfo: $newCollaboratorInfo
    ) {
      ...TOKEN_CONTRACT_ATTRIBUTES
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const UPDATE_ERC_1155_PERMISSION = gql`
  mutation updateERC1155Permissions(
    $smartContractAddress: String!
    $collabList: [inputCollaboratorInfo!]
  ) {
    updateERC1155Permissions(
      smartContractAddress: $smartContractAddress
      collaboratorList: $collabList
    ) {
      ...ERC_1155_PERMISSION_ATTRIBUTES
    }
  }
  ${ERC_1155_PERMISSION_ATTRIBUTES}
`;

export const GET_ERC20_BALANCE = gql`
  query getErc20TokenBalance($contractAddress: String!) {
    getErc20TokenBalance(contractAddress: $contractAddress)
  }
`;
