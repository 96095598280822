import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ethers } from 'ethers';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
}));

const AddPartner = ({ open, closeModal, loading, onAddPartnerHandler }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formikOrgAccess = useFormik({
    initialValues: {
      partnerWalletAddress: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await onAddPartnerHandler(values);
      resetForm();
    },
    validationSchema: Yup.object().shape({
      partnerWalletAddress: Yup.string()
        .required(t('partners.validation.partnerWalletAddress'))
        .test('is-valid-eth-address', t('partners.validation.partnerWalletAddressChange'), val => {
          try {
            return ethers.utils.getAddress(val) && true;
          } catch (e) {
            return false;
          }
        })
        .matches(/^0x[a-fA-F0-9]{40}$/, t('partners.validation.partnerWalletAddressChange')),
    }),
  });
  const disabled = !formikOrgAccess.dirty || !formikOrgAccess.isValid;
  return (
    <Modal className={classes.modal} open={open} onClose={closeModal}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('partners.addPartner')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            formikOrgAccess.resetForm();
            closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formikOrgAccess}>
          <Form>
            <Field
              component={TextField}
              label={t('partners.partnerWalletAddress')}
              name="partnerWalletAddress"
              data-testid="partnersWalletAddress-input"
            />
            <FormErrors form={formikOrgAccess} />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="addPartner-button"
              disabled={disabled || loading}
            >
              {t('common.save')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

AddPartner.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onAddPartnerHandler: PropTypes.func.isRequired,
};

export default AddPartner;
