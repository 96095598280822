import { Button, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { Field, FieldArray } from 'formik';
import { find, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TOKEN_METADATA_FIELDS } from '../../../constants';
import MetaDataItems from './MetaDataItems';
import MetaDataStructureView from './MetaDataStructureView';

const useStyles = makeStyles(theme => ({
  editButton: {
    border: `1px solid ${theme.palette.primary.lightest}`,
    borderRadius: 0,
  },
  editButtonArea: {
    display: 'flex',
    justifyContent: 'right',
  },
  contentBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MetadataStructureFieldArray = ({
  formValues,
  metaDataTypes,
  measurementOptions,
  isEditMode,
  toggleEditMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyMetadata = {
    metadataName: '',
    metadataType: '',
    measurement: '',
    metadataPermission: '',
    metadataMutability: '',
    chosenMetadataOption: '',
  };

  const buildFields = remove => {
    const { metadataArray } = formValues;
    const handleDelete = index => {
      remove(index);
    };

    const locationId = find(metaDataTypes, ['key', TOKEN_METADATA_FIELDS.LOCATION])?.value;
    const linksDataId = find(metaDataTypes, ['key', TOKEN_METADATA_FIELDS.LINKED_TOKEN])?.value;
    const locationIndex = findIndex(
      metadataArray,
      metaData => metaData.metadataType === locationId,
    );
    const linksDataIndex = findIndex(
      metadataArray,
      metaData => metaData.metadataType === linksDataId,
    );

    const filterDataTypes = index => {
      return metaDataTypes.filter(field => {
        if (field.value === locationId) {
          return locationIndex < 0 || locationIndex === index;
        }
        if (field.value === linksDataId) {
          return linksDataIndex < 0 || linksDataIndex === index;
        }
        return true;
      });
    };
    return metadataArray.map((metadataRow, index) => (
      <Field
        name={`metadataArray.${index}`}
        component={MetaDataItems}
        index={index}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        handleDelete={handleDelete}
        metaDataTypes={filterDataTypes(index)}
        measurementOptions={measurementOptions}
      />
    ));
  };

  const getStructuredContent = () => {
    if (!isEditMode) {
      return <MetaDataStructureView formValues={formValues} metaDataTypes={metaDataTypes} />;
    }

    return (
      <>
        <FieldArray name="metadataArray">
          {({ push, remove }) => (
            <>
              {isEditMode && (
                <div>
                  {buildFields(remove)}
                  <Button
                    id="btn_add_metadata"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.saveButton}
                    onClick={() => push(emptyMetadata)}
                  >
                    {t('tokens.addMetaData')}
                  </Button>
                </div>
              )}
            </>
          )}
        </FieldArray>
      </>
    );
  };

  return (
    <>
      <div className={classes.editButtonArea}>
        <IconButton
          id="btn_edit_metadata"
          color="primary"
          onClick={toggleEditMode}
          className={classes.editButton}
          size="small"
        >
          <EditIcon />
        </IconButton>
      </div>
      <div className={classes.contentBox}>{getStructuredContent()}</div>
    </>
  );
};

MetadataStructureFieldArray.propTypes = {
  formValues: PropTypes.shape({
    metadataArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  metaDataTypes: PropTypes.arrayOf(PropTypes.shape({})),
  measurementOptions: PropTypes.arrayOf(PropTypes.shape({})),
  isEditMode: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
};

MetadataStructureFieldArray.defaultProps = {
  metaDataTypes: null,
  measurementOptions: null,
};

export default MetadataStructureFieldArray;
