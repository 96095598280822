import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import CategoryOutlined from '@material-ui/icons/CategoryOutlined';
import EventOutlined from '@material-ui/icons/EventOutlined';
import StorageOutlined from '@material-ui/icons/StorageOutlined';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import Check from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { TRACE_WIZARD_PROGRESSION_V2 } from '../../../constants';

const useStyles = makeStyles(theme => ({
  connector: {
    position: 'absolute',
    top: '40px',
    left: 'calc(-40%)',
    right: 'calc(60%)',
  },
  wizardStepperRoot: {
    width: '100%',
  },
  stepper: {
    backgroundColor: theme.palette.primary.lightest,
  },
  step: {},
  iconContainer: {
    width: '50px',
    height: '50px',
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32%',
    borderRadius: '32px',
    color: 'grey',
    backgroundColor: '#EAEAEE',
  },
  icon: {
    height: '30px',
    width: '30px',
  },
  iconCompleted: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  iconActivePending: {
    color: theme.palette.primary.dark,
    backgroundColor: 'white',
  },
  iconActiveCompleted: {
    color: theme.palette.success.main,
    backgroundColor: 'white',
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: '-80px',
    },
  },
  alternativeLabel: {},
}));

const Connector = () => {
  const classes = useStyles();
  return (
    <StepConnector
      classes={{
        root: classes.connector,
      }}
    />
  );
};
const WizardStepper = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { instanceDetails, lastCompletedIteration } = useConfigurationWizardContext();
  const currentIteration = instanceDetails?.content?.currentIteration;

  const steps = [
    {
      label: t('traceability.configurationWizard.progress.items'),
      key: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.path,
      code: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.code,
      Icon: CategoryOutlined,
    },
    {
      label: t('traceability.configurationWizard.progress.steps'),
      key: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.path,
      code: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
      Icon: EventOutlined,
    },
    {
      label: t('traceability.configurationWizard.progress.permission'),
      key: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.path,
      code: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
      Icon: PeopleAltOutlined,
    },
    {
      label: t('traceability.configurationWizard.progress.metadata'),
      key: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.path,
      code: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
      Icon: StorageOutlined,
    },
    {
      label: t('traceability.configurationWizard.progress.summary'),
      key: TRACE_WIZARD_PROGRESSION_V2.FINALIZE.path,
      code: TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code,
      Icon: Check,
    },
  ];

  const buildSteps = steps.map(stepItem => {
    const { Icon, label, key, code } = stepItem;
    return (
      <Step
        key={key}
        classes={{ root: classes.step }}
        connector={<Connector />}
        completed={code <= lastCompletedIteration}
        active={code === currentIteration}
      >
        <StepLabel
          classes={{
            alternativeLabel: classes.alternativeLabel,
            labelContainer: classes.labelContainer,
          }}
          StepIconComponent={({ completed, active }) => {
            return (
              <div
                className={clsx(classes.iconContainer, {
                  [classes.iconCompleted]: !active && completed,
                  [classes.iconActivePending]: active && !completed,
                  [classes.iconActiveCompleted]: active && completed,
                })}
              >
                <Icon className={classes.icon} />
              </div>
            );
          }}
        >
          {label}
        </StepLabel>
      </Step>
    );
  });

  return (
    <div className={classes.wizardStepperRoot}>
      <Stepper alternativeLabel nonLinear classes={{ root: classes.stepper }}>
        {buildSteps}
      </Stepper>
    </div>
  );
};

export default WizardStepper;
