import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  configure,
  reviewSubscriptions,
  refreshSubscriptions,
} from '@eyblockchain/subscription-sdk/browser';
import {
  OPSCHAIN_NOTARIZATION,
  OPSCHAIN_TOKENS,
  OPSCHAIN_TRACEABILITY,
  OPSCHAIN_WALLET,
  OPSCHAIN_STORAGE,
  OPSCHAIN_ZKP,
  OPSCHAIN_ORG_MANAGEMENT,
  OPSCHAIN_MAP_VIEW,
  VISUALIZER_DASHBOARD,
  VISUALIZER_HISTORY,
  MTR_PROCUREMENT,
  LIVE_TRANSACTIONS_PANEL,
  EURO_CURRENCY,
  REBATE_DISCOUNT,
  AUTHORIZED_PARTIES,
  OPSCHAIN_BUSINESS_ECOSYSTEMS,
} from '../../constants';

const SubscriptionContext = React.createContext();

const SubscriptionProvider = ({ children }) => {
  const { homeUrl: baseUrl, cookiePrefix, cookieDomain } = window.config;
  const [subscription, setSubscription] = useState(true);
  const [availableFlags, setAvailableFlags] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const flagsList = [
    OPSCHAIN_NOTARIZATION,
    OPSCHAIN_TOKENS,
    OPSCHAIN_TRACEABILITY,
    OPSCHAIN_WALLET,
    OPSCHAIN_ZKP,
    OPSCHAIN_ORG_MANAGEMENT,
    OPSCHAIN_STORAGE,
    MTR_PROCUREMENT,
    OPSCHAIN_MAP_VIEW,
    VISUALIZER_DASHBOARD,
    VISUALIZER_HISTORY,
    LIVE_TRANSACTIONS_PANEL,
    EURO_CURRENCY,
    REBATE_DISCOUNT,
    AUTHORIZED_PARTIES,
    OPSCHAIN_BUSINESS_ECOSYSTEMS,
  ];

  configure({
    baseUrl,
    cookiePrefix,
    cookieDomain,
  });

  const setAccess = flags => {
    const tempFlags = {
      notarizationAccess: false,
      tokensAccess: false,
      traceabilityAccess: false,
      zkpAccess: false,
      storageAccess: false,
      mtrProcurement: false,
      mapViewAccess: false,
      visualizerDashboardAccess: false,
      visualizerHistoryAccess: false,
      accessLiveTransactionsPanel: false,
      euroCurrencySymbol: false,
      rebateContractDiscount: false,
      authorizedPartiesAccess: false,
      businessEcosystemsAccess: false,
    };
    // const tempFlags = {};
    if (flags.includes(OPSCHAIN_NOTARIZATION)) tempFlags.notarizationAccess = true;
    if (flags.includes(OPSCHAIN_TOKENS)) tempFlags.tokensAccess = true;
    if (flags.includes(OPSCHAIN_TRACEABILITY)) tempFlags.traceabilityAccess = true;
    if (flags.includes(OPSCHAIN_ZKP)) tempFlags.zkpAccess = true;
    if (flags.includes(OPSCHAIN_STORAGE)) tempFlags.storageAccess = true;
    if (flags.includes(MTR_PROCUREMENT)) tempFlags.mtrProcurement = true;
    if (flags.includes(OPSCHAIN_MAP_VIEW)) tempFlags.mapViewAccess = true;
    if (flags.includes(VISUALIZER_DASHBOARD)) tempFlags.visualizerDashboardAccess = true;
    if (flags.includes(VISUALIZER_HISTORY)) tempFlags.visualizerHistoryAccess = true;
    if (flags.includes(LIVE_TRANSACTIONS_PANEL)) tempFlags.accessLiveTransactionsPanel = true;
    if (flags.includes(EURO_CURRENCY)) tempFlags.euroCurrencySymbol = true;
    if (flags.includes(REBATE_DISCOUNT)) tempFlags.rebateContractDiscount = true;
    if (flags.includes(AUTHORIZED_PARTIES)) tempFlags.authorizedPartiesAccess = true;
    if (flags.includes(OPSCHAIN_BUSINESS_ECOSYSTEMS)) tempFlags.businessEcosystemsAccess = true;

    setAvailableFlags(tempFlags);
  };

  const checkSubscriptions = async flags => {
    setSubscriptionLoading(true);
    const featureFlags = await reviewSubscriptions(flags);
    setSubscriptionLoading(false);
    setAccess(featureFlags);
    return featureFlags;
  };

  const requestSubscriptionsCheck = async () => {
    await refreshSubscriptions();
    const flags = await checkSubscriptions(flagsList);
    return flags;
  };

  useEffect(() => {
    checkSubscriptions(flagsList);
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        requestSubscriptionsCheck,
        notarizationAccess: availableFlags?.notarizationAccess,
        traceabilityAccess: availableFlags?.traceabilityAccess,
        tokensAccess: availableFlags?.tokensAccess,
        storageAccess: availableFlags?.storageAccess,
        zkpAccess: availableFlags?.zkpAccess,
        mapViewAccess: availableFlags?.mapViewAccess,
        visualizerDashboardAccess: availableFlags?.visualizerDashboardAccess,
        visualizerHistoryAccess: availableFlags?.visualizerHistoryAccess,
        mtrProcurement: availableFlags?.mtrProcurement,
        accessLiveTransactionsPanel: availableFlags?.accessLiveTransactionsPanel,
        euroCurrencySymbol: availableFlags?.euroCurrencySymbol,
        rebateContractDiscount: availableFlags?.rebateContractDiscount,
        authorizedPartiesAccess: availableFlags?.authorizedPartiesAccess,
        businessEcosystemsAccess: availableFlags?.businessEcosystemsAccess,
        subscriptionLoading,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => useContext(SubscriptionContext);

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useSubscriptionContext, SubscriptionProvider };
