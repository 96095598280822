import React, { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useNotarizationContext } from '../../contexts/Notarization/notarizationContext';
import CreateContractDialog from './CreateContractDialog';
import NotarizationContractList from './NotarizationContractList';
import PageLoader from '../Shared/PageLoader';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles(theme => ({
  ContractListRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  contractHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    fontWeight: '100',
    color: theme.palette.primary.light,
  },
  emptyRoot: {
    padding: theme.spacing(2),
    background: theme.palette.primary.lightest,
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
  },
  panelContainer: {
    paddingTop: '20px',
  },
  zeroContractContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  zeroMainBanner: {
    marginTop: '20vh',
    fontWeight: '400',
  },
  zeroDescLine: {
    marginTop: '5px',
  },
  zeroStartBanner: {
    marginTop: '2rem',
    fontWeight: 'bold',
  },
  zeroDeployButton: {
    marginTop: '2rem',
  },
}));

const NotarizationLanding = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userPermissions } = useUserInfo();

  const { activeWallet } = useBecOrganizationContext();

  const {
    notarizationContracts,
    getnotarizationContracts,
    notarizationListLoading,
    addContractToList,
  } = useNotarizationContext();

  const isUserAuthToDeploy = userPermissions?.notarization?.includes(
    PERMISSIONS.NOTARIZATION.DEPLOYNOTARIZATION,
  );
  const [createFormOpen, setCreateFormOpen] = useState(false);

  const closeForm = () => {
    setCreateFormOpen(false);
  };
  const handleCreate = () => {
    setCreateFormOpen(true);
  };
  const createContractHandler = async variables => {
    setCreateFormOpen(false);
    await addContractToList(variables);
  };

  useEffect(() => {
    getnotarizationContracts();
  }, [getnotarizationContracts, activeWallet]);

  if (notarizationListLoading) {
    return (
      <Card variant="outlined">
        <PageLoader />
      </Card>
    );
  }

  if (notarizationContracts?.length < 1) {
    return (
      <>
        <div className={classes.zeroContractContainer}>
          <Typography variant="h2" className={classes.zeroMainBanner}>
            {t('notarization.welcome')}
          </Typography>
          <Typography variant="body1" className={classes.zeroDescLine}>
            {t('notarization.welcomeDescription')}
          </Typography>
          <Typography
            variant="h5"
            className={classes.zeroStartBanner}
            data-testid="notarization-welcomeText"
          >
            {isUserAuthToDeploy
              ? t('notarization.welcomeStartHere')
              : t('notarization.noDeploymentAuthorization')}
          </Typography>
          {isUserAuthToDeploy && (
            <Button
              data-testid="notarization-createContractButton"
              id=""
              className={classes.zeroDeployButton}
              variant="contained"
              color="secondary"
              onClick={handleCreate}
            >
              {t('notarization.btnCreate')}
            </Button>
          )}
        </div>
        <div>
          <CreateContractDialog
            open={createFormOpen}
            closeModal={closeForm}
            loading={false}
            onSubmit={createContractHandler}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.ContractListRoot}>
        {isUserAuthToDeploy && (
          <div className={classes.contractHeaderArea}>
            <Typography variant="h3">{t('notarization.notarization')}</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreate}
              data-testid="notarization-createContractButton"
            >
              {t('notarization.createNewContract')}
            </Button>
          </div>
        )}
        <Typography variant="h5" className={classes.subHeader}>
          {t('notarization.totalContracts')}:{' '}
          {notarizationContracts?.length ? notarizationContracts.length : 0}
        </Typography>
      </div>
      <NotarizationContractList />
      <CreateContractDialog
        open={createFormOpen}
        closeModal={closeForm}
        loading={false}
        onSubmit={createContractHandler}
      />
    </>
  );
};
export default NotarizationLanding;
