import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from 'react-router-dom';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { ORG_TYPES } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    margin: `0px ${theme.spacing(2)}px`,
  },
  proceedButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  infoArea: {
    marginTop: '2em',
  },
  info: {
    marginBottom: '1em',
  },
  link: {
    color: theme.palette.info.main,
    fontSize: '1em',
    whiteSpace: 'no-wrap',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  linkIcon: {
    verticalAlign: 'text-bottom',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  hiddenLink: {
    color: theme.palette.background.paper,
    fontSize: '1em',
    whiteSpace: 'no-wrap',
    fontWeight: '400',
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
}));

const DashboardModal = ({
  open,
  closeModal,
  product,
  requestAccess,
  requestTrialPackage,
  orgType,
  linkAccess,
  visitHelpCenter,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { organizationsList, changeOrganization } = useBecOrganizationContext();

  // temporarily hides link to help center. To be removed
  const hideViewCenter = true;

  const replaceAndHighlight = (string, placeholder, value) => {
    const split = string.split(new RegExp(`(${placeholder})`));
    return split.map(subString =>
      subString === placeholder ? <strong key={`strong-${value}`}>{value}</strong> : subString,
    );
  };

  const switchToIndividualUserAccount = () => {
    const userOrg = organizationsList.find(org => org.org_type === ORG_TYPES.USER);
    if (userOrg) {
      changeOrganization(userOrg);
    }
  };

  let title = '';
  let text = '';
  let text1 = '';
  let text2 = '';
  let buttonText = '';

  if (orgType === ORG_TYPES.USER || orgType === ORG_TYPES.STANDARD) {
    title = replaceAndHighlight(
      t('dashboard.modal.tryOpschain'),
      '%productName%',
      t(`dashboard.products.${product?.NAME}`),
    );
    text = t('dashboard.modal.testForFree');
    buttonText = t('common.proceed');
  } else if (linkAccess) {
    title = t('dashboard.modal.switchOrg');
    buttonText = t('dashboard.modal.switch');
    text1 = replaceAndHighlight(
      t('dashboard.modal.switchToIndividualOrg'),
      '%productName%',
      t(`dashboard.products.${product?.NAME}`),
    );
    text2 = replaceAndHighlight(
      t('dashboard.modal.requestAdminAccessIndividual'),
      '%productName%',
      t(`dashboard.products.${product?.NAME}`),
    );
  } else {
    title = t('dashboard.modal.requestAccess');
    buttonText = t('dashboard.tryTestnet');
    text1 = replaceAndHighlight(
      t('dashboard.modal.requestAdminAccessOrg'),
      '%productName%',
      t(`dashboard.products.${product?.NAME}`),
    );
    text2 = replaceAndHighlight(
      t('dashboard.modal.switchToIndividualToUseTestnets'),
      '%productName%',
      t(`dashboard.products.${product?.NAME}`),
    );
  }

  const proceed = async () => {
    setButtonDisabled(true);
    if (orgType === ORG_TYPES.USER || orgType === ORG_TYPES.STANDARD) {
      closeModal();
      setButtonDisabled(false);
      const [packages, flags] = await requestTrialPackage();
      if (packages && flags) {
        history.push(product?.START);
      }
    } else {
      closeModal();
      switchToIndividualUserAccount();
    }
    setButtonDisabled(false);
  };

  const generateMessage = () => {
    return product?.REQUIRED_PACKAGES?.map(productName => (
      <>
        {t(`dashboard.modal.multiplePackagesWarning`, {
          productName: t(`dashboard.products.${productName}`),
        })}
      </>
    ));
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModal}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <div className={classes.infoArea}>
          {orgType === ORG_TYPES.USER || orgType === ORG_TYPES.STANDARD ? (
            <Typography variant="body1" className={classes.info}>
              {text}
            </Typography>
          ) : (
            <>
              <Typography variant="body1" className={classes.info}>
                {text1}
              </Typography>
              <Typography variant="body1" className={classes.info}>
                {text2}
              </Typography>
            </>
          )}
          <Typography
            variant="body1"
            className={hideViewCenter ? classes.hiddenLink : classes.link}
            onClick={hideViewCenter ? () => {} : visitHelpCenter}
          >
            {t('dashboard.visitHelpCenterInfo')} <OpenInNewIcon className={classes.linkIcon} />
          </Typography>
        </div>
        {(orgType === ORG_TYPES.STANDARD || orgType === ORG_TYPES.USER) &&
          product?.REQUIRED_PACKAGES?.length > 0 && (
            <Paper className={classes.accordionPaper}>
              <Typography className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {generateMessage()}
              </Typography>
            </Paper>
          )}
        <Divider className={classes.divider} />

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            onClick={closeModal}
          >
            {t('common.cancel')}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.proceedButton}
            onClick={proceed}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button>

          {(orgType === ORG_TYPES.USER || orgType === ORG_TYPES.STANDARD || linkAccess) && (
            <Typography variant="body1" className={classes.link} onClick={requestAccess}>
              {linkAccess ? t('dashboard.requestAccess') : t('dashboard.requestAccessEnterprise')}
            </Typography>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

DashboardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  product: PropTypes.shape({
    NAME: PropTypes.string,
    START: PropTypes.string,
    REQUIRED_PACKAGES: PropTypes.arrayOf(PropTypes.string),
  }),
  requestAccess: PropTypes.func.isRequired,
  requestTrialPackage: PropTypes.func.isRequired,
  orgType: PropTypes.string,
  linkAccess: PropTypes.bool.isRequired,
  visitHelpCenter: PropTypes.func.isRequired,
};

DashboardModal.defaultProps = {
  orgType: ORG_TYPES.USER,
  product: {
    NAME: '',
    START: '/',
    REQUIRED_PACKAGES: [],
  },
};

export default DashboardModal;
