import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { groupBy } from 'lodash';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import NavSection from './subcomponents/NavSection';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  sidebar: ({ variant }) => {
    return {
      backgroundColor: '#FAFAFC',
      width: theme.typography.pxToRem(250),
      minHeight: '100vh',
      boxShadow: 'inset -1px 0 0 0 #E7E7EA',
      padding: variant === 'permanent' ? theme.spacing(2, 0) : theme.spacing(0, 0, 2, 0),
    };
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  toggleIcon: ({ menuOpen, toggleBoxColor }) => {
    return {
      boxShadow: menuOpen
        ? 'inset -1px 0 0 0 #E7E7EA'
        : '0 1px 1px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
      backgroundColor: menuOpen ? '#FAFAFC' : toggleBoxColor,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: menuOpen ? '#FAFAFC' : toggleBoxColor,
      },
    };
  },
}));

/**
 * This component provides access to destinations in your app.
 */
const SideNav = ({
  navigationList,
  selected,
  variant,
  openDefault,
  toggleBoxColor,
  children,
  contentClassName,
  className: classNameProp,
  ...other
}) => {
  const [menuOpen, setMenuOpen] = useState(variant === 'permanent' ? true : openDefault);
  const classes = useStyles({ toggleBoxColor, variant, menuOpen });
  const className = clsx(classes.sidebar, classNameProp);

  const navSections = groupBy(navigationList, 'section');

  const toggleSidebar = () => {
    setMenuOpen(prev => !prev);
  };

  return (
    <>
      {!menuOpen && variant === 'persistent' && (
        <Box>
          <IconButton
            disableRipple
            disableFocusRipple
            className={classes.toggleIcon}
            onClick={toggleSidebar}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      )}
      <div className={classes.root}>
        <Slide direction="right" in={menuOpen} mountOnEnter unmountOnExit>
          <div className={className} {...other}>
            {variant === 'persistent' && (
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={toggleSidebar}
                  className={classes.toggleIcon}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Box>
            )}

            {Object.entries(navSections).map((section, index) => (
              <NavSection
                count={Object.keys(navSections).length}
                key={section[0]}
                index={index}
                items={section[1]}
                selected={selected}
              />
            ))}
          </div>
        </Slide>
        <main className={clsx(classes.content, contentClassName)}>{children}</main>
      </div>
    </>
  );
};

SideNav.defaultProps = {
  className: null,
  contentClassName: null,
  selected: '',
  variant: 'persistent',
  openDefault: true,
  toggleBoxColor: '#FAFAFC',
};

SideNav.propTypes = {
  /**
   * Navigation items
   */
  navigationList: PropTypes.arrayOf(
    PropTypes.shape({
      /** Item label */
      name: PropTypes.string.isRequired,
      /** Url to redirect */
      url: PropTypes.string,
      /** Array for nested items */
      subItems: PropTypes.navigationList,
      /** The section number for this item */
      section: PropTypes.number,
    }),
  ).isRequired,
  /**
   * Name of the selected item
   */
  selected: PropTypes.string,
  /**
   * One of 'permanent' or 'persistent' .
   * Permanent shows a sidebar that is always visible while a persistent one can toggle open or closed.
   */
  variant: PropTypes.oneOf(['permanent', 'persistent']),
  /**
   * Is true the sidebar is open by default. Ignored for persistent variant.
   */
  openDefault: PropTypes.bool,
  /**
   * Background color for the close icon box.
   */
  toggleBoxColor: PropTypes.string,
  /**
   * Additional classes to be applied to root element.
   */
  className: PropTypes.string,
  /**
   * Additional classes to be applied to content root element.
   */
  contentClassName: PropTypes.string,
  /**
   * Child component to be rendered next to the sidebar
   */
  children: PropTypes.node.isRequired,
};

export default SideNav;
