import { CircularProgress } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { CONSTANTS, METADATA_BEHAVIORS } from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';
import { GET_ERC721_TOKEN_LIST } from '../../graphql/Tokenization/token';

const useStyles = makeStyles(() => ({
  renderOptionLabel: {
    margin: 0,
  },
  selectField: {
    minWidth: '10%',
    width: '100%',
    margin: '10px 10px 10px 0px',
  },
  muiAutocompleteOption: {
    display: 'inherit',
  },
  linkERC721InputWrapper: {
    display: 'grid',
    gridTemplateColumns: '68.5% 30%',
    gridGap: '1.5%',
    '& .MuiAutocomplete-input': {
      padding: '5.5px 4px !important',
    },
  },
}));

const LinkERC721Input = ({ field: { name }, form: { values, setFieldValue } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tokenContractsLoading, tokenContracts } = useTokenContext();
  const [tokenOptions, setTokenOptions] = useState([]);
  const { handleNotification } = useNotification();

  const [getErc721TokenList, { loading: tokenListLoadingForErc721 }] = useLazyQuery(
    GET_ERC721_TOKEN_LIST,
    {
      onCompleted: data => {
        const tokensOptions = data?.erc721TokenList
          ?.filter(token => token?.metadataStructure?.behavior === METADATA_BEHAVIORS.STRUCTURED)
          ?.map(token => {
            return {
              label: token?.tokenId,
              value: token?._id,
              key: token?._id,
            };
          });
        setTokenOptions(tokensOptions);
      },
      onError: error => {
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const contractsOptions = tokenContracts
    .filter(token => token?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721)
    .map(token => {
      return {
        label: token?.tokenName,
        value: token?.contract?._id,
        key: token?.contract?._id,
      };
    });

  const requestLoadingOption = () => [
    {
      label: t('common.loading'),
      value: t('common.loading'),
      key: t('common.loading'),
      tokenType: '',
    },
  ];

  const requestLoadingOptionRender = () => (
    <>
      <CircularProgress size={20} thickness={20} className={classes.spinner} />
      {t('common.loading')}
    </>
  );

  const handleERC721ContractChange = option => {
    setFieldValue(`${name}.selectedContractName`, option?.label);
    setFieldValue(`${name}.selectedContractId`, option?.value);
    setFieldValue(`${name}.selectedTokenId`, '');
    setFieldValue(`${name}.selectedTokenObjectId`, '');
    if (option?.value)
      getErc721TokenList({
        variables: {
          smartContractId: option?.value,
        },
      });
    else setTokenOptions([]);
  };

  const handleERC721TokenChange = option => {
    setFieldValue(`${name}.selectedTokenId`, option?.label);
    setFieldValue(`${name}.selectedTokenObjectId`, option?.value);
  };

  return (
    <div className={classes.linkERC721InputWrapper}>
      <Autocomplete
        options={tokenContractsLoading ? requestLoadingOption() : contractsOptions}
        getOptionLabel={option => option?.label}
        onChange={(event, option) => handleERC721ContractChange(option)}
        value={{
          label: values?.[name]?.selectedContractName,
          value: values?.[name]?.selectedContractId,
          key: values?.[name]?.selectedContractId,
        }}
        renderOption={option => (
          <div key={option.key} className={classes.renderOption}>
            {tokenContractsLoading ? (
              requestLoadingOptionRender()
            ) : (
              <>
                <p className={classes.renderOptionLabel}>{option.label}</p>
              </>
            )}
          </div>
        )}
        renderInput={params => (
          <MuiTextField
            {...params}
            label={t('tokens.linkERC721.selectContract')}
            variant="outlined"
            size="small"
            classes={{
              root: classes.selectField,
            }}
          />
        )}
      />

      <Autocomplete
        options={tokenListLoadingForErc721 ? requestLoadingOption() : tokenOptions}
        getOptionLabel={option => option?.label}
        onChange={(event, option) => handleERC721TokenChange(option)}
        value={{
          label: values?.[name]?.selectedTokenId,
          value: values?.[name]?.selectedTokenObjectId,
          key: values?.[name]?.selectedTokenObjectId,
        }}
        renderOption={option => (
          <div key={option.key} className={classes.renderOption}>
            {tokenContractsLoading ? (
              requestLoadingOptionRender()
            ) : (
              <>
                <p className={classes.renderOptionLabel}>{option.label}</p>
              </>
            )}
          </div>
        )}
        renderInput={params => (
          <MuiTextField
            {...params}
            label={t('tokens.linkERC721.selectTokenID')}
            variant="outlined"
            size="small"
            classes={{
              root: classes.selectField,
            }}
          />
        )}
      />
    </div>
  );
};

LinkERC721Input.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default LinkERC721Input;
