import crypto from 'crypto';

/**
 * function to hash a stringified json and return value as hex
 *
 * hex recommended to avoid problems with using base64 values as filenames/URIs
 * @param {string} data - string to hash
 */
export const hashAsHex = data => {
  return crypto
    .createHash('sha256')
    .update(data)
    .digest('hex');
};

export default {
  hashAsHex,
};
