import { Typography, Box, TextField, Button } from '@material-ui/core';

import { FastField } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MandatoryLabel from '../../components/Traceability/WizardV2/MandatoryLabel';

const useStyles = makeStyles(theme => ({
  rowRoot: {
    display: 'grid',
    gridTemplateColumns: '36% 30% 17% 15%',
    margin: '1em 0',
    padding: theme.spacing(1),
    gridGap: '1%',
  },
  deleteButton: {
    height: 'max-content',
  },
  tokenBalanceText: {
    fontSize: '1.2em !important',
  },
  tokenBalanceHeader: {
    px: 2,
    pt: '7px',
    display: 'flex',
    flexDirection: 'row-reverse',
    color: theme.palette.primary.light,
  },
  boldFont: {
    fontWeight: 'bold',
  },
  tokenSelectionRoot: {
    maxHeight: '450px',
    overflowY: 'auto',
  },
  errorMessage: {
    color: 'red',
  },
}));

const RenderText = props => {
  const classes = useStyles();
  const { error, label, errorMessage } = props;
  return (
    <div>
      <TextField
        {...props}
        label={<MandatoryLabel text={label} error={error} />}
        size="small"
        variant="outlined"
        InputProps={{ classes: { input: classes.itemNameInput } }}
      />
      {error && <div className={classes.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

RenderText.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
RenderText.defaultProps = {
  error: false,
  errorMessage: '',
};

const BulkTransferTokenRow = ({ arrayHelpers }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const errors = arrayHelpers.form?.errors?.tokenArray;
  const rows = arrayHelpers.form.values.tokenArray?.map((token, rowIndex) => {
    const rowError = errors ? errors[rowIndex] : {};
    const rowKey = `id-${rowIndex}`;
    return (
      <div key={rowKey} className={classes.rowRoot}>
        <FastField
          name={`tokenArray[${rowIndex}].tokenId`}
          label={t('tokens.tokenId')}
          as={RenderText}
          disabled
        />

        <FastField
          name={`tokenArray[${rowIndex}].amount`}
          as={RenderText}
          label={t('tokens.tokenAmount')}
          error={!!rowError?.amount}
          errorMessage={rowError?.amount}
        />

        <Box sx={{ px: 2, pt: '7px', display: 'flex', flexDirection: 'row-reverse' }}>
          <Typography variant="body2" className={classes.tokenBalanceText}>
            {token?.totalBalance}
          </Typography>
        </Box>

        <Button className={classes.deleteButton} onClick={() => arrayHelpers.remove(rowIndex)}>
          <DeleteIcon />
        </Button>
      </div>
    );
  });

  return (
    <>
      {arrayHelpers.form.values.tokenArray.length > 0 && (
        <div className={classes.rowRoot}>
          <div />
          <div />
          <Box sx={{ pl: 2, pt: '7px' }} className={classes.tokenBalanceHeader}>
            <Typography variant="body2">{t('tokens.available')}</Typography>
          </Box>
          <div />
        </div>
      )}
      <div className={classes.tokenSelectionRoot}>{rows}</div>
    </>
  );
};

BulkTransferTokenRow.propTypes = {
  arrayHelpers: PropTypes.shape({
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.shape({
        tokenArray: PropTypes.array.isRequired,
      }),
      errors: PropTypes.shape({
        tokenArray: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

export default BulkTransferTokenRow;
