import React, { useState } from 'react';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import Table from '@eyblockchain/ey-ui/core/Table';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExpandButton } from 'mui-datatables';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import useListStyles from '../MtrPurchaseOrders/list';
import {
  GET_MTR_SHIPMENTS,
  UPDATE_MTR_SHIPMENTS,
} from '../../../graphql/Procurement/mtrPurchaseOrder';
import LoadingComponent from '../DemandForecast/loaderContainer';
import UploadModal from './upload';
import {
  displayDate,
  ellipsisText,
  getForeignPurchaseNumber,
  getPOReleaseLineNumber,
  makeDownload,
  makeMasterIDs,
  makePayload,
} from './tableUtils';
import EditModal from './EditModal';

export const ShipmentDetails = ({ setShowUploadModal, showUploadModal }) => {
  const history = useHistory();
  const listClasses = useListStyles();
  const { t } = useTranslation('mtr');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const { poId } = queryString.parse(history.location.search);
  const variables = poId
    ? { foreignPurchaseNumber: poId, isOpenOrderOnly: false }
    : { isOpenOrderOnly: true };
  const { data: { shipmentMtrs } = { shipmentMtrs: [] }, loading, refetch } = useQuery(
    GET_MTR_SHIPMENTS,
    {
      variables,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );
  const ShipmentList = () => {
    if (loading) {
      return <LoadingComponent />;
    }
    const tableData = shipmentMtrs.map((i, index) => {
      return {
        index, //
        ...getPOReleaseLineNumber(i.foreignPurchaseNumber),
        foreignPurchaseNumber: i.foreignPurchaseNumber, //
        foreignApprovedAt: i.purchaseOrder?.supplementInfo?.foreignApprovedAt,
        shipmentNumber: i.shipmentNumber, //
        material: i.purchaseOrder?.material, //
        preInspectionDate: i.preInspectionDate,
        sparesCategory:
          i.purchaseOrder?.supplementInfo?.sparesCategory ||
          i.purchaseOrder?.sku.supplementInfo?.sparesCategory, //
        supplierItem:
          i.purchaseOrder?.supplementInfo?.partNumber || i.purchaseOrder?.sku?.supplierSku, //
        description: i.purchaseOrder?.sku?.description, //
        status: i.status,
        promiseDate: i.promiseDate,
        volume: i.volume,
        volumeDue: i.volumeDue,
        unitOfItem: i.purchaseOrder?.supplementInfo?.unitOfItem,
        buyerEmployeeName: i.purchaseOrder?.supplementInfo?.buyerEmployeeName,
        supplierName: i.purchaseOrder?.supplier?.company?.name,
        closeCode: i.closeCode,
        supplierUpdateLast: i.supplierUpdateLast,
        buyerCategory: i.buyerCategory,
        buyerUpdate1: i.buyerUpdate1, // M&SD Remarks
        targetDeliveryDate: i.targetDeliveryDate, // Targeted delivery date
        supplierUpdate: i.supplierUpdate, // Supplier Reply
        rid: i.rid, // RID
        dispatchDate: i.dispatchDate, // Dispatch Date
        exitFactoryDate: i.exitFactoryDate, // Ex-FTY Date
        exitFactoryLocation: i.exitFactoryLocation, // Ex-FTY Location
        waybill: i.waybill, // Waybill No.
        vessel: i.vessel, // Flight/Vessel Name&No.
        etd: i.etd, // ETD
        eta: i.eta, // ETA HK
        ata: i.ata, // ATA
        discrepancyReports: i.discrepancyReports,
      };
    });
    const rowsExpanded = tableData.filter(i => i.discrepancyReports.length).map(i => i.index);
    const dataColumn = [
      { name: 'Po Num', attr: 'poNum', range: 'B' },
      { name: 'Release Num', attr: 'releaseNum', range: 'C' },
      {
        name: 'Order Date',
        attr: 'foreignApprovedAt',
        type: 'timestamp',
        range: 'D',
      },
      { name: 'Line Num', attr: 'lineNum', range: 'E' },
      { name: 'Shipment', attr: 'shipmentNumber', range: 'F' },
      {
        name: 'foreignApprovedAt',
        attr: 'foreignApprovedAt',
        type: 'timestamp',
        range: 'G',
      },
      {
        name: 'Item',
        attr: 'material',
        range: 'H',
      },
      {
        name: 'Item Description',
        attr: 'description',
        range: 'K',
      },
      {
        name: 'Spares Category',
        attr: 'sparesCategory',
        range: 'I',
      },
      {
        name: 'Supplier Item',
        attr: 'supplierItem',
        range: 'J',
      },
      {
        name: 'Status',
        attr: 'status',
        range: 'L',
      },
      {
        name: 'Promised Date',
        type: 'timestamp',
        attr: 'promiseDate',
        range: 'M',
      },
      {
        name: 'Quantity',
        attr: 'volume',
        range: 'N',
      },
      {
        name: 'Quantity Due',
        attr: 'volumeDue',
        range: 'O',
      },
      {
        name: 'Uom',
        attr: 'unitOfItem',
        range: 'P',
      },
      {
        name: t('pos.reportNum'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.reportNum),
        range: 'Q',
      },
      {
        name: t('pos.reportDate'),
        attr: 'discrepancyReports',
        transform: reports =>
          reports.map(i => {
            if (!i.reportDate) return undefined;
            return dayjs(i.reportDate * 1000).format('YYYY/MM/DD');
          }),
        range: 'R',
      },
      {
        name: t('pos.volumeDeliveryNote'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.volumeDeliveryNote),
        range: 'S',
      },
      {
        name: 'Po Receipt Date',
        // todo
        range: 'T',
      },
      {
        name: t('pos.volumeReceived'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.volumeReceived),
        range: 'U',
      },
      {
        name: 'Accepted Rejected Quantity',
        // todo
        range: 'V',
      },
      {
        name: t('pos.volumeSettled'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.volumeSettled),
        range: 'W',
      },
      {
        name: t('pos.settlementDate'),
        attr: 'discrepancyReports',
        transform: reports =>
          reports.map(i => {
            if (!i.settlementDate) {
              return undefined;
            }
            return dayjs(i.settlementDate * 1000).format('YYYY/MM/DD');
          }),
        range: 'X',
      },
      {
        name: t('pos.reason1'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.reason1),
        range: 'Y',
      },
      {
        name: t('pos.reason2'),
        attr: 'discrepancyReports',
        transform: reports => reports.map(i => i.reason2),
        range: 'Z',
      },
      {
        name: 'Poh Buyer Name',
        attr: 'buyerEmployeeName',
        range: 'AA',
      },
      {
        name: 'Supplier Name',
        attr: 'supplierName',
        range: 'AB',
      },
      {
        name: 'Closure Status',
        attr: 'closeCode',
        range: 'AC',
      },
      {
        name: 'Supplier Last Update',
        attr: 'supplierUpdateLast',
        range: 'AD',
      },
      {
        name: 'SOH as at',
        // todo
        range: 'AE',
      },
      {
        name: 'COVERAGE (ILGS)',
        // todo
        range: 'AF',
      },
      {
        name: 'Category',
        attr: 'buyerCategory',
        range: 'AG',
      },
      {
        name: 'M&SD Remarks',
        attr: 'buyerUpdate1',
        range: 'AH',
      },
      {
        name: 'Targeted delivery date',
        type: 'timestamp',
        attr: 'targetDeliveryDate',
        range: 'AI',
      },
      {
        name: 'Supplier Reply',
        attr: 'supplierUpdate',
        range: 'AJ',
      },
      {
        name: 'Date of Notice for RID',
        type: 'timestamp',
        attr: 'rid',
        range: 'AK',
      },
      {
        name: 'Pre-shipment Inspection Report / UKDA issue Date',
        type: 'timestamp',
        attr: 'preInspectionDate',
        range: 'AL',
      },
      {
        name: 'Dispatch Date',
        type: 'timestamp',
        attr: 'dispatchDate',
        range: 'AM',
      },
      {
        name: 'Ex-FTY Date',
        type: 'timestamp',
        attr: 'exitFactoryDate',
        range: 'AN',
      },
      {
        name: 'Ex-FTY Location',
        attr: 'exitFactoryLocation',
        range: 'AO',
      },
      {
        name: 'Waybill  No. / Bill of Lading No.',
        attr: 'waybill',
        range: 'AP',
      },
      {
        name: 'Flight/Vessel Name&No',
        attr: 'vessel',
        range: 'AQ',
      },
      {
        name: 'ETD',
        attr: 'etd',
        type: 'timestamp',
        range: 'AR',
      },
      {
        name: 'ETA HK',
        attr: 'eta',
        type: 'timestamp',
        range: 'AS',
      },
      {
        name: 'ATA',
        attr: 'ata',
        type: 'timestamp',
        range: 'AT',
      },
    ];
    const columns = [
      {
        name: 'Edit',
        label: t('common.edit'),
        options: {
          filter: false,
          sort: false,
          search: false,
          empty: true,
          customBodyRenderLite: dataIndex => {
            return (
              <>
                <IconButton
                  name="icon-button-edit"
                  color="primary"
                  component="span"
                  size="small"
                  onClick={() => {
                    setShowEditModal(true);
                    setEditData(tableData[dataIndex]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </>
            );
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 196,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 296,
              background: '#FFFFFF',
            },
          }),
        },
      },
      {
        name: 'poNum',
        label: t('pos.poId3'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => (
            <Link color="inherit" href={`/purchaseOrders?masterId=${value}`} target="_blank">
              <Tooltip arrow title="View PO Header" placement="top-start">
                <span style={{ color: 'blue', whiteSpace: 'nowrap' }}>{value}</span>
              </Tooltip>
            </Link>
          ),
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '35px',
              zIndex: 197,
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '35px',
              zIndex: 297,
              background: '#F8F8FF',
            },
          }),
        },
      },
      {
        name: 'releaseNum',
        label: t('pos.releaseNum'),
        sortCompare: order => {
          return (obj1, obj2) => {
            const d1 = parseInt(obj1.data, 10);
            const d2 = parseInt(obj2.data, 10);
            return order === 'asc' ? d1 - d2 : d2 - d1;
          };
        },
        options: {
          filter: false,
          search: true,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '135px',
              zIndex: 198,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: '135px',
              zIndex: 298,
              background: '#FFFFFF',
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'lineNum',
        label: t('pos.lineNum'),
        options: {
          filter: false,
          search: true,
          sortCompare: order => {
            return (obj1, obj2) => {
              const d1 = parseInt(obj1.data, 10);
              const d2 = parseInt(obj2.data, 10);
              return order === 'asc' ? d1 - d2 : d2 - d1;
            };
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '215px',
              zIndex: 199,
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: '215px',
              zIndex: 299,
              background: '#F8F8FF',
              whiteSpace: 'nowrap',
            },
          }),
          customBodyRender: (lineNum, { columnIndex, rowData }) => {
            const poNum = rowData[columnIndex - 2];
            const releaseNum = rowData[columnIndex - 1];
            const foreignPurchaseNumber = getForeignPurchaseNumber({ poNum, releaseNum, lineNum });
            return (
              <Link
                color="inherit"
                href={`/purchaseOrdersDetails?poId=${foreignPurchaseNumber}`}
                target="_blank"
              >
                <Tooltip arrow title="View PO Line" placement="top-start">
                  <span style={{ color: 'blue', whiteSpace: 'nowrap' }}>{lineNum}</span>
                </Tooltip>
              </Link>
            );
          },
        },
      },
      {
        name: 'shipmentNumber',
        label: t('pos.number'),
        options: {
          filter: false,
          search: true,
          sortCompare: order => {
            return (obj1, obj2) => {
              const d1 = parseInt(obj1.data, 10);
              const d2 = parseInt(obj2.data, 10);
              return order === 'asc' ? d1 - d2 : d2 - d1;
            };
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '280px',
              zIndex: 200,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: '280px',
              zIndex: 300,
              background: '#FFFFFF',
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'foreignApprovedAt',
        label: t('pos.poOrderDate'),
        options: {
          filter: false,
          // display: !poId,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'material',
        label: t('pos.item'),
        options: {
          display: !poId,
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'description',
        label: t('pos.itemDescription'),
        options: {
          display: !poId,
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value, length: 20, color: 'grey' }),
        },
      },
      {
        name: 'sparesCategory',
        label: t('pos.sparesCategory'),
        options: {
          display: !poId,
          filter: true,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'supplierItem',
        label: t('pos.supplierItem'),
        options: {
          display: !poId,
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'status',
        label: t('pos.status'),
        options: {
          filter: true,
          search: false,
          customBodyRender: value => {
            return t(`pos.${value}`);
          },
        },
      },
      {
        name: 'promiseDate',
        label: t('pos.promisedDeliveryDate'),
        options: { filter: false, customBodyRender: value => displayDate({ value }) },
      },
      {
        name: 'volume',
        label: t('pos.quantity'),
        options: {
          filter: false,
          search: false,
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'volumeDue',
        label: t('pos.quantityDue'),
        options: {
          filter: false,
          search: false,
          setCellProps: () => ({
            style: {
              textAlign: 'right',
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              background: '#F8F8FF',
              whiteSpace: 'nowrap',
            },
          }),
        },
      },
      {
        name: 'unitOfItem',
        label: t('pos.UOM'),
        options: {
          filter: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'buyerEmployeeName',
        label: t('pos.buyerName'),
        options: {
          display: !poId,
          filter: true,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'supplierName',
        label: t('pos.supplierName'),
        options: {
          display: !poId,
          filter: true,
          search: false,
          customBodyRender: value => ellipsisText({ value, length: 20 }),
        },
      },
      {
        name: 'closeCode',
        label: t('pos.closeCode'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'supplierUpdateLast',
        label: t('pos.supplierUpdateLast'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'buyerCategory',
        label: t('pos.buyerCategory'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'buyerUpdate1',
        label: t('pos.buyerUpdate1'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'targetDeliveryDate',
        label: t('pos.targetDeliveryDate'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'supplierUpdate',
        label: t('pos.supplierUpdate'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'rid',
        label: t('pos.rid'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'preInspectionDate',
        label: t('pos.preInspectionDate'),
        options: {
          display: true,
          filter: false,
          search: true,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'dispatchDate',
        label: t('pos.dispatchDate'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'exitFactoryDate',
        label: t('pos.exitFactoryDate'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'exitFactoryLocation',
        label: t('pos.exitFactoryLocation'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'waybill',
        label: t('pos.waybill'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'vessel',
        label: t('pos.vessel'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'etd',
        label: t('pos.etd'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'eta',
        label: t('pos.etaHK'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'ata',
        label: t('pos.ata'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => displayDate({ value }),
        },
      },
      {
        name: 'discrepancyReports',
        label: t('pos.discrepancyReports'),
        options: {
          filter: false,
          display: false,
          search: false,
        },
      },
      {
        name: 'discrepancyReports',
        label: t('pos.withDiscrepancyReports'),
        options: {
          filter: true,
          display: false,
          search: false,
          customBodyRender: value => (value?.length ? 'Yes' : 'No'),
        },
      },
      {
        name: 'material',
        label: t('pos.item'),
        options: {
          display: false,
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      ...new Array(15).fill({
        name: '',
        options: {
          display: false,
          filter: false,
          sort: false,
          search: false,
        },
      }),
    ]
      .map((col, ix) => {
        return ix % 2 && !col.options?.setCellHeaderProps
          ? _.mergeWith(col, {
              options: {
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: 'nowrap',
                    background: '#F8F8FF',
                  },
                }),
              },
            })
          : col;
      })
      .map((col, ix) => {
        return !(ix % 2) && !col.options?.setCellHeaderProps
          ? _.mergeWith(col, {
              options: {
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: 'nowrap',
                  },
                }),
              },
            })
          : col;
      })
      .map((col, ix) => {
        return ix % 2 && !col.options?.setCellProps
          ? _.mergeWith(col, {
              options: {
                setCellProps: () => ({
                  style: {
                    background: '#F8F8FF',
                  },
                }),
              },
            })
          : col;
      });
    return (
      <Grid>
        {!tableData.length && <Typography>{t('pos.error.noShipmentData')}</Typography>}
        {tableData.length > 0 && (
          <Table
            className={listClasses.table}
            options={{
              filter: !poId,
              pagination: true,
              textLabels: {
                body: {
                  noMatch: t('common.noMatchingRecords'),
                },
                pagination: {
                  rowsPerPage: t('common.rowsPerPage'),
                },
              },
              search: !poId,
              download: !poId,
              rowsPerPageOptions: [10, 50, 100],
              downloadOptions: { filename: `Open_Order_Summary_${moment().unix()}.csv` },
              expandableRows: true,
              expandableRowsHeader: false,
              tableBodyMaxHeight: '40rem',
              rowsExpanded: poId ? rowsExpanded : [],
              renderExpandableRow: rowData => {
                const colSpan = rowData.length / 2 + 1;
                const reportIndex = _.findIndex(columns, o => o.name === 'discrepancyReports');
                const data = rowData[reportIndex];
                return (
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Grid className={listClasses.table}>
                        <Table
                          data={data}
                          options={{
                            sort: false,
                            search: false,
                            filter: false,
                            pagination: false,
                            download: false,
                          }}
                          columns={[
                            { name: 'reportNum', label: t('pos.reportNum') },
                            {
                              name: 'reportDate',
                              label: t('pos.reportDate'),
                              options: {
                                customBodyRender: value => displayDate({ value }),
                              },
                            },
                            {
                              name: 'receivedDate',
                              label: t('pos.receivedDate'),
                              options: {
                                customBodyRender: value => displayDate({ value }),
                              },
                            },
                            { name: 'inspectionCode', label: t('pos.inspectionCode') },
                            { name: 'inspectionResult', label: t('pos.inspectionResult') },
                            { name: 'volumeDeliveryNote', label: t('pos.volumeDeliveryNote') },
                            { name: 'volumeReceived', label: t('pos.volumeReceived') },
                            { name: 'volumeDiscrepancy', label: t('pos.volumeDiscrepancy') },
                            { name: 'volumeSettled', label: t('pos.volumeSettled') },
                            { name: 'discrepancyType', label: t('pos.discrepancyType') },
                            {
                              name: 'reason1',
                              label: t('pos.reason1'),
                              options: {
                                customBodyRender: value => ellipsisText({ value }),
                              },
                            },
                            {
                              name: 'reason2',
                              label: t('pos.reason2'),
                              options: {
                                customBodyRender: value => ellipsisText({ value }),
                              },
                            },
                            {
                              name: 'settlementDate',
                              label: t('pos.settlementDate'),
                              options: {
                                customBodyRender: value => {
                                  return displayDate({ value });
                                },
                              },
                            },
                          ]}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              },
              isRowExpandable: dataIndex => rowsExpanded.includes(dataIndex),
              onDownload: (buildHead, buildBody, oldColumns, oldData) => {
                return makeDownload({
                  dataColumn,
                  buildHead,
                  buildBody,
                  oldColumns,
                  oldData,
                });
              },
            }}
            data={tableData}
            columns={columns}
            components={{
              ExpandButton: props => {
                // eslint-disable-next-line react/prop-types
                const { dataIndex } = props;
                if (rowsExpanded.includes(dataIndex)) {
                  return <ExpandButton {...props} />;
                }
                return <div style={{ width: '24px' }} />;
              },
            }}
          />
        )}
      </Grid>
    );
  };
  const Modals = () => {
    const [updateShipment, { loading: updateShipmentLoading }] = useMutation(UPDATE_MTR_SHIPMENTS);
    const makeRequestData = (excelItem, fileTableCol) => {
      const foreignPurchaseNumber = makeMasterIDs({
        idMaps: {
          B: 'poNum',
          C: 'releaseNum',
          E: 'lineNum',
        },
        excelItem,
        fileTableCol,
      });
      const payload = makePayload({
        updateMap: {
          F: 'shipmentNumber',
          AG: 'buyerCategory',
          AH: 'buyerUpdate1',
          AI: 'targetDeliveryDate',
          AJ: 'supplierUpdate',
          AK: 'rid',
          AL: 'preInspectionDate',
          AM: 'dispatchDate',
          AN: 'exitFactoryDate',
          AO: 'exitFactoryLocation',
          AP: 'waybill',
          AQ: 'vessel',
          AR: 'etd',
          AS: 'eta',
          AT: 'ata',
        },
        excelItem,
        fileTableCol,
      });
      return {
        input: {
          foreignPurchaseNumber,
          ...payload,
        },
      };
    };

    const fieldArray = [
      {
        name: 'poNumber',
        label: t('pos.poId3'),
        gridSize: 3,
        type: 'info',
      },
      {
        name: 'releaseNumber',
        label: t('pos.poReleaseNum'),
        gridSize: 3,
        type: 'info',
      },
      {
        name: 'lineNumber',
        label: t('pos.poLineNum'),
        gridSize: 3,
        type: 'info',
      },
      {
        name: 'shipmentNumber',
        label: t('pos.poShipment'),
        gridSize: 3,
        type: 'info',
      },
      {
        name: 'separator-0',
        type: 'separator',
      },
      {
        name: t('pos.mtrUpdateSubheader'),
        type: 'subheader',
      },
      {
        name: 'buyerCategory',
        label: t('pos.buyerCategory'),
        type: 'text',
        gridSize: 6,
      },
      {
        name: 'buyerUpdate1',
        label: t('pos.buyerUpdate1'),
        type: 'text',
        gridSize: 6,
      },
      {
        name: 'separator-1',
        type: 'separator',
      },
      {
        name: t('pos.logisticsPlanSubheader'),
        type: 'subheader',
      },
      {
        name: 'supplierUpdate',
        label: t('pos.supplierUpdate'),
        type: 'text',
        gridSize: 12,
      },
      {
        name: 'targetDeliveryDate',
        label: t('pos.targetDeliveryDate'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'rid',
        label: t('pos.dateRID'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'preInspectionDate',
        label:
          t('pos.preInspectionDate').length > 20
            ? t('pos.preInspectionDate')
                .substring(0, 22)
                .concat('...')
            : t('pos.preInspectionDate'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'dispatchDate',
        label: t('pos.dispatchDate'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'exitFactoryDate',
        label: t('pos.exitFactoryDate'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'exitFactoryLocation',
        label: t('pos.exitFactoryLocation'),
        type: 'text',
        gridSize: 3,
      },
      {
        name: 'waybill',
        label: t('pos.waybill'),
        type: 'text',
        gridSize: 3,
      },
      {
        name: 'vessel',
        label: t('pos.vessel'),
        type: 'text',
        gridSize: 3,
      },
      {
        name: 'etd',
        label: t('pos.etd'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'eta',
        label: t('pos.etaHK'),
        type: 'date',
        gridSize: 3,
      },
      {
        name: 'ata',
        label: t('pos.ata'),
        type: 'date',
        gridSize: 3,
      },
    ];

    const poReleaseLeaseLineNum = editData.foreignPurchaseNumber
      ? getPOReleaseLineNumber(editData.foreignPurchaseNumber)
      : null;

    const initValues = {
      ata: editData.ata,
      buyerCategory: editData.buyerCategory,
      buyerUpdate1: editData.buyerUpdate1,
      dispatchDate: editData.dispatchDate,
      eta: editData.eta,
      etd: editData.etd,
      exitFactoryDate: editData.exitFactoryDate,
      exitFactoryLocation: editData.exitFactoryLocation,
      foreignPurchaseNumber: editData.foreignPurchaseNumber,
      preInspectionDate: editData.preInspectionDate,
      rid: editData.rid,
      shipmentNumber: editData.shipmentNumber,
      supplierUpdate: editData.supplierUpdate,
      targetDeliveryDate: editData.targetDeliveryDate,
      vessel: editData.vessel,
      waybill: editData.waybill,
      poNumber: poReleaseLeaseLineNum?.poNum,
      releaseNumber: poReleaseLeaseLineNum?.releaseNum,
      lineNumber: poReleaseLeaseLineNum?.lineNum,
    };

    const validationSchema = Yup.object().shape({
      ata: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      eta: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      etd: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      exitFactoryDate: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      dispatchDate: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      targetDeliveryDate: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
      preInspectionDate: Yup.date()
        .typeError(t('pos.invalidInput'))
        .nullable(true),
    });

    const makeEditPayload = input => {
      const formmatedInput = { ...input };
      delete formmatedInput.poNumber;
      delete formmatedInput.releaseNumber;
      delete formmatedInput.lineNumber;
      return formmatedInput;
    };

    return (
      <>
        <UploadModal
          action={updateShipment}
          makePayload={makeRequestData}
          open={showUploadModal}
          refetch={refetch}
          onClose={() => setShowUploadModal(false)}
        />
        <EditModal
          action={updateShipment}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          isLoading={updateShipmentLoading}
          fieldArray={fieldArray}
          refetch={refetch}
          module="oos"
          header={t('pos.updateHeader')}
          initValues={initValues}
          makePayload={makeEditPayload}
          validationSchema={validationSchema}
        />
      </>
    );
  };

  return (
    <Grid style={{ width: '100%' }}>
      <ShipmentList />
      <Modals />
    </Grid>
  );
};

ShipmentDetails.propTypes = {
  setShowUploadModal: PropTypes.func.isRequired,
  showUploadModal: PropTypes.bool.isRequired,
};

export default () => {
  const listClasses = useListStyles();
  const { activeOrganization } = useBecOrganizationContext();
  const isOwnerOrAdmin = ['owner', 'admin'].includes(activeOrganization?.organization_role?.name);
  const { t } = useTranslation('mtr');
  const [showUploadModal, setShowUploadModal] = useState(false);
  return (
    <Grid className={listClasses.root}>
      <Grid container>
        <Grid item xs={8}>
          <Typography className={listClasses.title} variant="h3">
            {t('pos.openOrderSummary')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          {isOwnerOrAdmin && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              {t('pos.poUploadShipment')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid>
        <ShipmentDetails
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
        />
      </Grid>
    </Grid>
  );
};
