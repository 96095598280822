import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  additionalInfo: {
    fontWeight: '100',
    color: theme.palette.primary.light,
    display: 'block',
  },
  commonFonts: {
    fontWeight: '100',
    color: theme.palette.primary.dark,
  },
}));

const AutocompleteDropDown = ({
  options,
  field,
  disabled,
  label,
  placeholder,
  isMultipleSelection,
  testid,
  form: { setFieldValue, setFieldTouched, errors, touched },
  disableClearable,
  withCheckboxes,
  startingValue,
  limitTags,
  disableCloseOnSelect,
  withMultiPartyContractDetails,
  disableOptionEnable,
  className,
}) => {
  const hasError = !!(touched[field.name] && errors[field.name]);
  const classes = useStyles();
  const { t } = useTranslation();
  const otherParams = {};

  if (withCheckboxes) {
    otherParams.renderOption = (option, { selected }) => (
      <>
        <Checkbox
          key={option.value}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          checked={selected}
        />
        {option.label}
      </>
    );
  }

  if (withMultiPartyContractDetails) {
    otherParams.renderOption = option => (
      <div>
        <Box component="div" display="inline">
          {option.label}
          {option.parentOrgName && (
            <Box component="span" display="inline" className={classes.additionalInfo}>
              <Box component="span" display="inline" className={classes.commonFonts}>
                {`${t('pos.smartContract')}: `}
              </Box>
              {option.smartContractAddress}
            </Box>
          )}
        </Box>
        {option.parentOrgName && (
          <Box component="div" display="inline">
            <Box component="span" display="inline" className={classes.additionalInfo}>
              <Box component="span" display="inline" className={classes.commonFonts}>
                {`${t('pos.contractOwner')}: `}
              </Box>
              {option.parentOrgName}
            </Box>
          </Box>
        )}
      </div>
    );
  }

  if (limitTags >= 0) {
    otherParams.limitTags = limitTags;
  }

  if (startingValue.length > 0) {
    otherParams.value = startingValue;
  }

  if (disableCloseOnSelect) {
    otherParams.disableCloseOnSelect = true;
  }

  const buildChips = params => {
    return (
      <Field
        component={TextField}
        {...params}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        error={hasError}
      />
    );
  };

  return (
    <Autocomplete
      multiple={isMultipleSelection}
      id={field.name}
      {...field}
      {...otherParams}
      name={field.name}
      disabled={disabled}
      disableClearable={disableClearable}
      options={options}
      renderInput={buildChips}
      getOptionLabel={option => (option.label ? option.label : '')}
      getOptionSelected={(optionToCheck, selectedValue) =>
        optionToCheck.value === selectedValue.value
      }
      getOptionDisabled={option =>
        disableOptionEnable && option.value === 'networkLeaderPartnerEmpty'
      }
      onChange={(e, value) => {
        setFieldValue(field.name, value);
        setFieldTouched(field.name, true, false);
      }}
      onOpen={event => {
        if (event.type !== 'mousedown' && event.type !== 'focus') {
          event.stopPropagation();
        }
      }}
      data-testid={testid}
      groupBy={option => option.groupby}
      className={className}
    />
  );
};

AutocompleteDropDown.propTypes = {
  disabled: PropTypes.bool,
  isMultipleSelection: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.any,
    ]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
  testid: PropTypes.string,
  disableClearable: PropTypes.bool,
  withCheckboxes: PropTypes.bool,
  startingValue: PropTypes.arrayOf(PropTypes.shape({})),
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  withMultiPartyContractDetails: PropTypes.bool,
  disableOptionEnable: PropTypes.bool,
  className: PropTypes.string,
};

AutocompleteDropDown.defaultProps = {
  disabled: false,
  isMultipleSelection: false,
  options: [],
  label: '',
  placeholder: '',
  testid: '',
  disableClearable: false,
  withCheckboxes: false,
  startingValue: [],
  limitTags: -1,
  disableCloseOnSelect: false,
  withMultiPartyContractDetails: false,
  disableOptionEnable: false,
  className: '',
};

export default AutocompleteDropDown;
