import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS, METADATA_BEHAVIORS } from '../../constants';
import {
  SET_TOKEN_METADATA_ERC721,
  SET_TOKEN_METADATA_ERC721_BCOS,
  SET_TOKEN_METADATA_ERC1155,
} from '../../graphql/Tokenization/token';
import BehaviorChip from './MetadataPanel/BehaviorChip';
import NoneMetadataPanel from './MetadataPanel/NoneMetadataPanel';
import DirectMetadataPanel from './MetadataPanel/DirectMetadataPanel';
import StructuredMetadataPanel from './MetadataPanel/StructuredMetadataPanel';
import HashedMetadataPanel from './MetadataPanel/HashedMetadataPanel';
import track from '../../mixpanel';
import LinkedModeMetadataPanel from './MetadataPanel/LinkedModeMetadataPanel';

const useStyles = makeStyles(theme => ({
  TokenMetaDataRoot: {
    '& .MuiButton-outlinedPrimary': {
      border: '0px',
    },
  },
  inProgressBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
  },
  inProgressBanner: {
    marginRight: theme.spacing(1),
  },
  noStructBanner: {
    marginTop: theme.spacing(2),
  },
  header: {
    fontWeight: 700,
  },
  headerArea: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const TokenMetaDataForm = forwardRef(
  ({ selectedToken, selectedContract, batchMetaData, setIsButtonDisable }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { activeWallet } = useBecOrganizationContext();

    const [
      updateERC721TokenMetadataEth,
      { loading: isErc721TokenMetadataUpdateLoading },
    ] = useMutation(SET_TOKEN_METADATA_ERC721, {
      onCompleted: data => {
        const totalMetadata = Object.keys(data.setTokenMetadataERC721?.newMetadata).length || 0;
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedContract?.contract?._id,
          tokenId: data?.setTokenMetadataERC721?.metadataTransaction?.tokenId,
          totalMetadata: totalMetadata,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    });

    const [
      updateERC721TokenMetadataBcos,
      { loading: isErc721TokenMetadataUpdateBcosLoading },
    ] = useMutation(SET_TOKEN_METADATA_ERC721_BCOS);

    const updateERC721TokenMetadata = variables => {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
          return updateERC721TokenMetadataEth(variables);
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.MATIC: {
          return updateERC721TokenMetadataEth(variables);
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          return updateERC721TokenMetadataBcos(variables);
        }
        default:
          return null;
      }
    };

    const [
      updateERC1155TokenMetadata,
      { loading: isErc1155TokenMetadataUpdateLoading },
    ] = useMutation(SET_TOKEN_METADATA_ERC1155, {
      onCompleted: data => {
        const totalMetadata = Object.keys(data.setERC1155Metadata?.newMetadata).length || 0;
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedContract?.contract?._id,
          tokenId: data?.setTokenMetadataERC721?.metadataTransaction?.tokenId,
          totalMetadata: totalMetadata,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    });

    const isFormProcessing =
      isErc721TokenMetadataUpdateLoading ||
      isErc721TokenMetadataUpdateBcosLoading ||
      isErc1155TokenMetadataUpdateLoading;

    const isMintedInLinkedMode =
      selectedContract?.contract?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED;

    let structureToUse = selectedContract?.contract?.metadataStructure;

    if (batchMetaData) {
      if (selectedToken[0]?.metadataStructure) {
        structureToUse = selectedToken[0].metadataStructure;
      }
    } else if (isMintedInLinkedMode) {
      structureToUse = {
        ...(selectedToken?.linkedToken?.metadataStructure ||
          selectedToken?.linkedContract?.metadataStructure),
        behavior: METADATA_BEHAVIORS.LINKED,
      };
    } else if (selectedToken?.metadataStructure) {
      structureToUse = selectedToken.metadataStructure;
    }

    const configToUse = structureToUse?.metadataConfig;

    if (!structureToUse) {
      return (
        <Typography variant="body1" className={classes.noStructBanner}>
          {t('tokens.defineMetadataInstruction')}
        </Typography>
      );
    }

    if (batchMetaData) {
      if (selectedToken[0]?.latestMetadataTransaction?.status === 'pending') {
        return (
          <div className={classes.TokenMetaDataRoot}>
            <div className={classes.inProgressBox}>
              <Typography variant="body1" className={classes.inProgressBanner}>
                {t('tokens.metaDataUpdateProgress')}
              </Typography>
              <CircularProgress size={20} thickness={10} />
            </div>
          </div>
        );
      }
    } else if (selectedToken?.latestMetadataTransaction?.status === 'pending') {
      return (
        <div className={classes.TokenMetaDataRoot}>
          <div className={classes.inProgressBox}>
            <Typography variant="body1" className={classes.inProgressBanner}>
              {t('tokens.metaDataUpdateProgress')}
            </Typography>
            <CircularProgress size={20} thickness={10} />
          </div>
        </div>
      );
    }

    const generateFields = () => {
      let updateMetadataFunction;
      switch (selectedContract?.contract?.tokenType) {
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
          updateMetadataFunction = updateERC1155TokenMetadata;
          break;
        default:
          updateMetadataFunction = updateERC721TokenMetadata;
      }

      switch (structureToUse?.behavior) {
        case METADATA_BEHAVIORS.DIRECT:
          return (
            <DirectMetadataPanel
              selectedToken={selectedToken}
              updateTokenMetadata={updateMetadataFunction}
              isFormProcessing={isFormProcessing}
              batchMetaData={batchMetaData}
              setIsButtonDisable={setIsButtonDisable}
              ref={ref}
            />
          );
        case METADATA_BEHAVIORS.STRUCTURED:
          return (
            <StructuredMetadataPanel
              selectedContract={selectedContract}
              selectedToken={selectedToken}
              metadataConfigs={configToUse}
              updateTokenMetadata={updateMetadataFunction}
              isFormProcessing={isFormProcessing}
              batchMetaData={batchMetaData}
              setIsButtonDisable={setIsButtonDisable}
              ref={ref}
            />
          );
        case METADATA_BEHAVIORS.HASHED:
          return (
            <HashedMetadataPanel
              selectedToken={selectedToken}
              metadataConfigs={configToUse}
              updateTokenMetadata={updateMetadataFunction}
              isFormProcessing={isFormProcessing}
              batchMetaData={batchMetaData}
              setIsButtonDisable={setIsButtonDisable}
              ref={ref}
            />
          );
        case METADATA_BEHAVIORS.LINKED:
          return (
            <LinkedModeMetadataPanel
              selectedContract={selectedContract}
              selectedToken={selectedToken}
              metadataConfigs={configToUse}
            />
          );
        case METADATA_BEHAVIORS.NONE:
        default:
          return <NoneMetadataPanel />;
      }
    };

    return (
      <>
        <div className={classes.headerArea}>
          <BehaviorChip behavior={structureToUse?.behavior} />
        </div>
        {generateFields()}
      </>
    );
  },
);

TokenMetaDataForm.propTypes = {
  selectedToken: PropTypes.oneOfType([
    PropTypes.shape({
      metadata: PropTypes.shape({
        links: PropTypes.shape({}),
      }),
      metadataUrls: PropTypes.shape({
        publicImmutable: PropTypes.string,
        privateImmutable: PropTypes.string,
        publicMutable: PropTypes.string,
        privateMutable: PropTypes.string,
      }),
      tokenId: PropTypes.string.isRequired,
      latestMetadataTransaction: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
      smartContract: PropTypes.shape({
        contractAddress: PropTypes.string.isRequired,
        metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      linkedToken: PropTypes.shape({
        metadataStructure: PropTypes.shape({
          metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
      }),
      linkedContract: PropTypes.shape({
        metadataStructure: PropTypes.shape({
          metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
      }),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        metadata: PropTypes.shape({
          links: PropTypes.shape({}),
        }),
        metadataUrls: PropTypes.shape({
          publicImmutable: PropTypes.string,
          privateImmutable: PropTypes.string,
          publicMutable: PropTypes.string,
          privateMutable: PropTypes.string,
        }),
        tokenId: PropTypes.string.isRequired,
        latestMetadataTransaction: PropTypes.shape({
          status: PropTypes.string.isRequired,
        }),
        smartContract: PropTypes.shape({
          contractAddress: PropTypes.string.isRequired,
          metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        linkedToken: PropTypes.shape({
          metadataStructure: PropTypes.shape({
            metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
          }).isRequired,
        }),
        linkedContract: PropTypes.shape({
          metadataStructure: PropTypes.shape({
            metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
          }).isRequired,
        }),
      }),
    ),
  ]),
  selectedContract: PropTypes.shape({
    contract: PropTypes.shape({
      _id: PropTypes.string,
      metadataStructure: PropTypes.shape({
        metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        behavior: PropTypes.string,
      }).isRequired,
      tokenType: PropTypes.string,
    }),
  }).isRequired,
  batchMetaData: PropTypes.bool,
  setIsButtonDisable: PropTypes.func,
};

TokenMetaDataForm.defaultProps = {
  selectedToken: null,
  batchMetaData: false,
  setIsButtonDisable: () => {},
};

export default TokenMetaDataForm;
