import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  ERC20BalanceCounterRoot: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  labelHeader: {
    fontWeight: '100',
  },
  balanceValue: {
    marginLeft: theme.spacing(2),
    marginTop: '2px',
  },
}));

const ERC20BalanceCounter = ({ isBalanceLoading, balanceValue, tokenType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (tokenType !== CONSTANTS.SMARTCONTRACT_TYPES.ERC20) {
    return <></>;
  }

  if (isBalanceLoading) {
    return (
      <>
        <div className={classes.ERC20BalanceCounterRoot}>
          <Typography>{t('tokens.balance')}: </Typography>
          <CircularProgress className={classes.balanceValue} size={20} thickness={20} />
        </div>
      </>
    );
  }

  return (
    <div className={classes.ERC20BalanceCounterRoot}>
      <Typography className={classes.labelHeader}>{t('tokens.balance')}:</Typography>
      <Typography variant="body2" className={classes.balanceValue}>
        {balanceValue}
      </Typography>
    </div>
  );
};

ERC20BalanceCounter.propTypes = {
  isBalanceLoading: PropTypes.bool,
  balanceValue: PropTypes.number,
  tokenType: PropTypes.string,
};

ERC20BalanceCounter.defaultProps = {
  isBalanceLoading: false,
  balanceValue: 0,
  tokenType: 'NA',
};

export default ERC20BalanceCounter;
