import gql from 'graphql-tag';

export const INGESTION_TYPE_ATTRIBUTES = gql`
  fragment INGESTION_TYPE_ATTRIBUTES on IngestionType {
    _id
    name
  }
`;

export const GET_INGESTION_TYPES = gql`
  query ingestionTypes {
    ingestionTypes {
      ...INGESTION_TYPE_ATTRIBUTES
    }
  }
  ${INGESTION_TYPE_ATTRIBUTES}
`;
