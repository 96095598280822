import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

const TableHeadCell = ({ columnMeta, handleToggleColumn, align }) => {
  return (
    <TableCell
      sortDirection={columnMeta.sortDirection === 'none' ? false : columnMeta.sortDirection}
      align={align}
    >
      <TableSortLabel
        direction={columnMeta.sortDirection === 'none' ? 'desc' : columnMeta.sortDirection}
        onClick={() => handleToggleColumn(columnMeta.index)}
        IconComponent={UnfoldMoreIcon}
        active
      >
        {columnMeta.label}
      </TableSortLabel>
    </TableCell>
  );
};

TableHeadCell.propTypes = {
  columnMeta: PropTypes.shape({
    sortDirection: PropTypes.oneOf(['none', 'asc', 'desc']),
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  handleToggleColumn: PropTypes.func.isRequired,
  align: PropTypes.string,
};

TableHeadCell.defaultProps = {
  align: 'left',
};

export default TableHeadCell;
