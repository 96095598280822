import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { findIndex } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { CONSTANTS, TAB_VALUES } from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import {
  GET_HISTORY_INFO_ERC721,
  GET_HISTORY_INFO_ERC1155,
} from '../../graphql/Shared/tokenHistory';
import useUserInfo from '../../hooks/useUserInfo';
import track from '../../mixpanel';
import { parseGraphqlError } from '../../utils';
import TokenHistoryTimeline from './TokenHistoryTimeline';
import TokenMetaDataForm from './TokenMetaDataForm';

const useStyles = makeStyles(theme => ({
  TokenContentRoot: {
    marginTop: theme.spacing(3),
    borderLeft: 'none',
    borderRight: 'none',
    padding: theme.spacing(2, 1, 0, 1),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'unset',
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
  },
  hiddenTabContent: {
    display: 'none',
  },
}));

const TokenContent = ({ selectedToken, selectedContract }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { visualizerHistoryAccess } = useSubscriptionContext();
  moment.locale(i18n.language);
  const {
    activeWallet: { connectionName },
  } = useBecOrganizationContext();
  const { handleNotification } = useNotification();
  const {
    permissionsFlags: {
      isUserAuthToViewTokenMetadata,
      isUserAuthToViewERC721History,
      isUserAuthToViewERC1155History,
    },
  } = useUserInfo();

  const isERC1155 =
    selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;

  const isUserAuthToViewHistory = isERC1155
    ? isUserAuthToViewERC1155History
    : isUserAuthToViewERC721History;

  const [tokenHistoryInfo, setTokenHistoryInfo] = useState(null);

  const [getHistoryInfoErc721, { loading: loadingErc721History }] = useLazyQuery(
    GET_HISTORY_INFO_ERC721,
    {
      onCompleted: data => {
        setTokenHistoryInfo(data?.tokenHistoryByERC721TokenId);
      },
      onError: err => {
        const errorMessage = parseGraphqlError(err) || err.message;
        handleNotification(
          errorMessage || t('tokenHistoryTimeLine.tokenHIstoryFetchError'),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [getHistoryInfoErc1155, { loading: loadingErc1155History }] = useLazyQuery(
    GET_HISTORY_INFO_ERC1155,
    {
      onCompleted: data => {
        setTokenHistoryInfo(data?.tokenHistoryByERC1155TokenId);
      },
      onError: err => {
        const errorMessage = parseGraphqlError(err) || err.message;
        handleNotification(
          errorMessage || t('tokenHistoryTimeLine.tokenHIstoryFetchError'),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const defaultActiveTab = () => {
    if (isUserAuthToViewTokenMetadata) {
      return TAB_VALUES.TOKEN_PANEL.METADATA;
    }
    if (isUserAuthToViewHistory) {
      return TAB_VALUES.TOKEN_PANEL.HISTORY;
    }
    return null;
  };

  const [activeTab, setActiveTab] = useState(defaultActiveTab());

  const tabs = [
    ...(isUserAuthToViewTokenMetadata
      ? [
          {
            label: t('tokens.tokenMetadata'),
            name: TAB_VALUES.TOKEN_PANEL.METADATA,
          },
        ]
      : []),
    ...(visualizerHistoryAccess && isUserAuthToViewHistory
      ? [
          {
            label: t('tokens.tokenHistory'),
            name: TAB_VALUES.TOKEN_PANEL.HISTORY,
          },
        ]
      : []),
  ];

  const tabChangeHandler = (event, newTabIndex) => {
    event.preventDefault();
    setActiveTab(tabs[newTabIndex]?.name);
    if (newTabIndex === 1) {
      switch (selectedToken?.tokenType) {
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
          getHistoryInfoErc1155({
            variables: {
              erc1155Token: selectedToken?._id,
            },
          });
          break;
        default:
          getHistoryInfoErc721({
            variables: {
              erc721Token: selectedToken?._id,
            },
          });
      }
      track('Token history tab selection', { network: connectionName });
    }
  };

  if (tabs.length < 1) {
    return <Card variant="outlined" className={classes.TokenContentRoot} />;
  }

  return (
    <Card variant="outlined" className={classes.TokenContentRoot}>
      <Tabs
        value={findIndex(tabs, { name: activeTab })}
        onChange={tabChangeHandler}
        classes={{
          indicator: classes.indicator,
        }}
      >
        {tabs.map(({ label }) => (
          <Tab label={label} key={uniqid()} />
        ))}
      </Tabs>
      {visualizerHistoryAccess && (
        <Box
          className={clsx({
            [classes.hiddenTabContent]: activeTab !== TAB_VALUES.TOKEN_PANEL.HISTORY,
          })}
        >
          <TokenHistoryTimeline
            tokenHistoryInfo={tokenHistoryInfo}
            loading={loadingErc721History || loadingErc1155History}
          />
        </Box>
      )}
      <Box
        className={clsx({
          [classes.hiddenTabContent]: activeTab !== TAB_VALUES.TOKEN_PANEL.METADATA,
        })}
      >
        <TokenMetaDataForm selectedToken={selectedToken} selectedContract={selectedContract} />
      </Box>
    </Card>
  );
};

TokenContent.propTypes = {
  selectedToken: PropTypes.shape({
    _id: PropTypes.string,
    tokenId: PropTypes.string,
    tokenType: PropTypes.string,
  }).isRequired,
  selectedContract: PropTypes.shape({
    contract: PropTypes.shape({
      tokenType: PropTypes.string.isRequired,
      contractAddress: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TokenContent;
