import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/react-hooks';
import { find, findKey } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import TableCell from '@material-ui/core/TableCell';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import {
  RBAC_ROLES,
  TRACEABILITY_DISPATCHER_ACTIONS,
  TRACE_WIZARD_PROGRESSION_V2,
  VALUE_CHAIN_FORMS,
  VALUE_CHAIN_FIELDS,
  CONSTANTS,
} from '../../../constants';
import MultiSelectDropDown from '../../Shared/MultiSelectDropDown';
import { getAggregatedDataForValueChainWizard } from '../../../utils';
import WizardButtonArea from './WizardButtonArea';
import { UPDATE_STEPS_COLLABORATORS } from '../../../graphql/Traceability/steps';
import useUserInfo from '../../../hooks/useUserInfo';
import { handleNavigation, useEditMode } from './traceabilityUtils';
import { useNotification } from '../../../contexts/Shared/notification';
import WizardHelper, { getHeaderNameText, getTooltipsText } from './WizardHelper';
import WizardTooltip from './WizardTooltip';
import track from '../../../mixpanel';
import { UPDATE_INSTANCE_PROGRESS } from '../../../graphql/Traceability/instance';

const useStyles = makeStyles(theme => ({
  gridItemTitle: {
    backgroundColor: '#e7e7ea',
  },
  gridStepTitle: {
    alignSelf: 'center',
  },
  stepTitle: {
    textAlign: 'center',
  },
  gridStepSection: {
    minHeight: '50px',
    backgroundColor: theme.palette.primary.contrastText,
    borderLeft: `solid 1px #e7e7ea`,
    borderRight: `solid 1px #e7e7ea`,
    borderBottom: `solid 1px #e7e7ea`,
  },
  stepRoleTitle: {
    textAlign: 'center',
  },
  gridSectionViewer: {
    borderLeft: `solid 1px #e7e7ea`,
    borderBottom: `solid 1px #e7e7ea`,
  },
  gridSectionContributor: {
    borderLeft: `solid 1px #e7e7ea`,
  },
  gridSubSectionViewer: {
    alignSelf: 'center',
  },
  gridSubSectionContributor: {
    alignSelf: 'center',
  },
  gridSubSectionAutocomplete: {
    borderLeft: `solid 1px #e7e7ea`,
    paddingLeft: theme.spacing(1),
  },
  gridPadded: {
    padding: theme.spacing(2),
  },
  itemSection: {
    marginBottom: theme.spacing(1),
  },
  autoCompleteWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  permissionsForm: {
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  removeAllButton: {
    maxWidth: '110px',
    width: 'fit-content',
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  permissionsHelpers: {
    display: 'grid',
    gridTemplateColumns: '24% 18% 50% 5%',
    padding: theme.spacing(1),
    gridGap: '1%',
    alignItems: 'center',
  },
  autoCompleteField: {
    width: '65%',
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
}));

const PermissionsForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { handleNotification } = useNotification();
  const [isProcessing, setProcessing] = useState(false);

  const { activeWallet } = useBecOrganizationContext();

  const {
    instanceDetails,
    instanceId,
    instanceDispatcher,
    lastCompletedIteration,
    partners,
    setIsDataLoading,
  } = useConfigurationWizardContext();
  const {
    allOrgRoles,
    permissionsFlags: { isUserAuthToEditValueChain, isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const userNotAllowedToViewContent =
    lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
    !isUserAuthToViewValueChainDetails;

  const userAllowedToEdit = isUserAuthToEditValueChain && instanceDetails?.content?.isOwner;

  const rolesId = {
    [RBAC_ROLES.VIEWER]: allOrgRoles
      ? allOrgRoles.find(role => role.name === RBAC_ROLES.VIEWER)?._id
      : null,
    [RBAC_ROLES.CONTRIBUTOR]: allOrgRoles
      ? allOrgRoles.find(role => role.name === RBAC_ROLES.CONTRIBUTOR)?._id
      : null,
  };

  const aggregatedData = getAggregatedDataForValueChainWizard(instanceDetails);

  const [isEditMode, setEditMode] = useEditMode(
    TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
    lastCompletedIteration,
  );

  const [updateInstanceStatus] = useMutation(UPDATE_INSTANCE_PROGRESS);
  const [updateStepsCollaborators] = useMutation(UPDATE_STEPS_COLLABORATORS, {
    onCompleted: data => {
      let totalCollaborators = [];
      if (data?.updateStepsCollaborators) {
        data.updateStepsCollaborators.forEach(updatedStep => {
          const collaborators = updatedStep.collaborators.map(collaborator => collaborator.role);
          totalCollaborators = totalCollaborators.concat(collaborators);
        });
      }

      track(CONSTANTS.MIXPANEL_EVENTS.TRACEABILITY.UPDATE_STEPS_COLLABORATORS, {
        blockchainNetwork: activeWallet?.network,
        valueChainId: data?.updateStepsCollaborators?.[0].instance?._id,
        totalSteps: data?.updateStepsCollaborators?.length || 0,
        totalCollaborators: totalCollaborators.length,
        viewers: totalCollaborators.filter(role => role === rolesId[RBAC_ROLES.VIEWER]).length,
        contributors: totalCollaborators.filter(role => role === rolesId[RBAC_ROLES.CONTRIBUTOR])
          .length,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TRACEABILITY.UPDATE_STEPS_COLLABORATORS, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const partnersOptions = partners?.map(partner => ({
    label: partner?.partnerOrganization?.name,
    value: partner?._id,
    key: partner?._id,
  }));

  const generateInitialValues = () => {
    const initialValues = [];

    // Existing collaborator info cannot be parsed without partner info
    if (!partners || partners.length < 1) return [];

    aggregatedData.forEach(item => {
      const stepRows = item.steps.map(itemStep => {
        const stepRow = {
          stepId: itemStep._id,
          stepName: itemStep.name,
          [RBAC_ROLES.CONTRIBUTOR]: [],
          [RBAC_ROLES.VIEWER]: [],
        };

        if (itemStep.collaborators) {
          itemStep.collaborators.forEach(collaborator => {
            const roleText = findKey(rolesId, roleId => roleId === collaborator?.role);
            const requiredPartnerOption = find(
              partnersOptions,
              partnerOption => partnerOption.key === collaborator?.partner?._id,
            );

            if (stepRow[roleText]) {
              stepRow[roleText].push(requiredPartnerOption);
            }
          });
        }

        return stepRow;
      });
      initialValues.push({
        itemId: item._id,
        itemName: item.name,
        stepRows: stepRows || [],
      });
    });
    return initialValues;
  };

  const getAssociatedContractId = stepId => {
    const involvedItem = instanceDetails?.content?.steps.find(step => step._id === stepId)
      .involvedItem;
    const involvedContract = involvedItem?.tokenContract?._id;
    return involvedContract;
  };

  const generateInput = values => {
    let input = [];

    const generateCollaboratorObjList = (stepRow, collaboratorType) =>
      stepRow[collaboratorType].map(partner => ({
        role: rolesId[collaboratorType],
        partnerId: partner.value,
      }));

    values.forEach(item => {
      input = input.concat(
        item.stepRows.map(stepRow => {
          let stepCollabList = [];
          stepCollabList = stepCollabList.concat(
            generateCollaboratorObjList(stepRow, RBAC_ROLES.VIEWER),
          );
          stepCollabList = stepCollabList.concat(
            generateCollaboratorObjList(stepRow, RBAC_ROLES.CONTRIBUTOR),
          );

          return {
            stepId: stepRow.stepId,
            collaboratorsList: stepCollabList,
            involvedContract: getAssociatedContractId(stepRow.stepId),
          };
        }),
      );
    });

    return input;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues(),
    onSubmit: async (values, { resetForm }) => {
      try {
        setProcessing(true);
        setIsDataLoading(true);
        const parsedValue = {
          instanceId,
          newStepInfo: generateInput(values),
        };

        const updatedStepsResponse = await updateStepsCollaborators({
          variables: parsedValue,
        });

        const updatedSteps = updatedStepsResponse?.data?.updateStepsCollaborators;

        if (updatedSteps) {
          updatedSteps.forEach(updatedStep => {
            instanceDispatcher({
              type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_STEPS_PERMISSIONS,
              payload: updatedStep,
            });
          });
        } else {
          // this will update the status if we're editing a finalized VC and didn't apply any changes to the permissions
          await updateInstanceStatus({
            variables: {
              instanceId: instanceId,
              inputIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
            },
          });
          instanceDispatcher({
            type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE_STATUS,
            payload: {
              status: CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION,
              lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
              currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
            },
          });
        }

        handleNotification(t('traceability.permissionsForm.updateSuccess'), 'success');
        resetForm({ values });
        handleNavigation(history, instanceId);
      } catch (err) {
        handleNotification(err?.message, 'error');
      } finally {
        setProcessing(false);
        setIsDataLoading(false);
      }
    },
  });

  const { values: formValues, setFieldValue, setFieldTouched } = formik;

  const removeAllPartners = (itemIndex, stepIndex, collaboratorType) => {
    const fieldName = `${itemIndex}.stepRows.${stepIndex}.${collaboratorType}`;
    setFieldValue(fieldName, []);
    setFieldTouched(fieldName, true, false);
  };

  const filteredPartners = (collaboratorType, stepRow) => {
    let partnersToFilter = [];
    switch (collaboratorType) {
      case RBAC_ROLES.VIEWER:
        partnersToFilter = stepRow[RBAC_ROLES.CONTRIBUTOR];
        break;
      case RBAC_ROLES.CONTRIBUTOR:
        partnersToFilter = stepRow[RBAC_ROLES.VIEWER];
        break;
      default:
        break;
    }

    if (partnersToFilter.length === 0) {
      return partnersOptions;
    }

    partnersToFilter = partnersToFilter.map(partner => partner.value);
    return partnersOptions.filter(partner => !partnersToFilter.includes(partner.value));
  };

  const buildField = (itemIndex, stepRow, stepIndex, collaboratorType) => {
    const labels = stepRow[collaboratorType].map(partner => partner?.label).filter(label => label);
    const partnersRecap = (
      <>
        <Typography variant="body2">
          <strong>{stepRow[collaboratorType].length}</strong>
          {t('traceability.permissionsForm.addedPartners')}
          {labels.length > 0 ? ':' : ''}
        </Typography>
        <Typography variant="body2">{labels.join(', ')}</Typography>
      </>
    );

    if (isEditMode) {
      return (
        <React.Fragment key={`stepField-${collaboratorType}-${stepRow.stepId}`}>
          <div className={classes.autoCompleteWrapper}>
            <div className={classes.autoCompleteField}>
              <Field
                component={MultiSelectDropDown}
                label={t('traceability.permissionsForm.labels.selectPartners')}
                name={`${itemIndex}.stepRows.${stepIndex}.${collaboratorType}`}
                options={filteredPartners(collaboratorType, stepRow)}
                startingValue={stepRow[collaboratorType]}
                withCheckboxes
                limitTags={1}
                size="small"
              />
            </div>
            <Button
              className={classes.removeAllButton}
              size="small"
              onClick={() => removeAllPartners(itemIndex, stepIndex, collaboratorType)}
            >
              <RemoveIcon />
              {t('traceability.permissionsForm.removeAll')}
            </Button>
          </div>
          {stepRow[collaboratorType].length > 0 && partnersRecap}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={`stepField-${collaboratorType}-${stepRow.stepId}`}>
        {stepRow[collaboratorType].length > 0 ? (
          partnersRecap
        ) : (
          <Typography variant="body2">{t('traceability.permissionsForm.NA')}</Typography>
        )}
      </React.Fragment>
    );
  };

  const buildStepField = (itemId, itemIndex, stepRow, stepIndex) => (
    <Grid
      container
      spacing={0}
      className={classes.gridStepSection}
      key={`stepsContainer-${itemId}-${stepRow?.stepId}`}
    >
      <Grid
        item
        xs={3}
        className={`${classes.gridSection} ${classes.gridPadded} ${classes.gridStepTitle}`}
      >
        <Typography variant="body2" className={classes.stepTitle}>
          {stepRow.stepName}
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.gridSectionCollaborator}>
        <Grid container className={classes.gridSectionViewer}>
          <Grid item xs={3} className={`${classes.gridSubSectionViewer} ${classes.gridPadded}`}>
            <Typography variant="body2" className={classes.stepRoleTitle}>
              {t('traceability.permissionsForm.roles.viewer')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            className={`${classes.gridPadded} ${classes.gridSubSectionAutocomplete}`}
          >
            {buildField(itemIndex, stepRow, stepIndex, RBAC_ROLES.VIEWER)}
          </Grid>
        </Grid>

        <Grid container className={classes.gridSectionContributor}>
          <Grid
            item
            xs={3}
            className={`${classes.gridSubSectionContributor} ${classes.gridPadded}`}
          >
            <Typography variant="body2" className={classes.stepRoleTitle}>
              {t('traceability.permissionsForm.roles.contributor')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            className={`${classes.gridPadded} ${classes.gridSubSectionAutocomplete}`}
          >
            {buildField(itemIndex, stepRow, stepIndex, RBAC_ROLES.CONTRIBUTOR)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const buildItemFields = (item, itemIndex) => (
    <Box className={classes.itemSection} key={`itemSection-${item.itemId}`}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={`${classes.gridItemTitle} ${classes.gridPadded}`}>
          <Typography variant="body2">{item.itemName}</Typography>
        </Grid>
        {item?.stepRows?.map((stepRow, stepIndex) =>
          buildStepField(item?.itemId, itemIndex, stepRow, stepIndex),
        )}
      </Grid>
    </Box>
  );

  if (!partners || partners.length < 1) {
    return (
      <div className={classes.permissionsFormRoot} data-testid="define-permission-stage3">
        <Typography variant="body2">{t('traceability.permissionsForm.noPartners')}</Typography>
        <WizardButtonArea
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          errors={formik.errors}
          formik={formik}
          isProcessing={isProcessing}
          optionalStep
          isCancelDisabled={
            lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code
          }
        />
      </div>
    );
  }

  const currentFormName = VALUE_CHAIN_FORMS.PERMISSIONS;

  const headerWithHelperOptions = ({ field }) => ({
    customHeadRender: columnMeta => (
      <TableCell key={uniqid()}>
        {columnMeta?.label}
        <WizardTooltip text={t(getTooltipsText({ formName: currentFormName, field }))}>
          <InfoOutlined className={classes.tooltipIcon} />
        </WizardTooltip>
      </TableCell>
    ),
  });

  const columnDefs = [
    {
      field: VALUE_CHAIN_FIELDS.PERMISSIONS.ITEMS_AND_STEPS,
      name: VALUE_CHAIN_FIELDS.PERMISSIONS.ITEMS_AND_STEPS,
      options: headerWithHelperOptions({ field: VALUE_CHAIN_FIELDS.PERMISSIONS.ITEMS_AND_STEPS }),
    },
    {
      field: VALUE_CHAIN_FIELDS.PERMISSIONS.ROLES,
      name: VALUE_CHAIN_FIELDS.PERMISSIONS.ROLES,
      options: headerWithHelperOptions({ field: VALUE_CHAIN_FIELDS.PERMISSIONS.ROLES }),
    },
    {
      field: VALUE_CHAIN_FIELDS.PERMISSIONS.ADD_PARTNERS,
      name: VALUE_CHAIN_FIELDS.PERMISSIONS.ADD_PARTNERS,
      options: headerWithHelperOptions({ field: VALUE_CHAIN_FIELDS.PERMISSIONS.ADD_PARTNERS }),
    },
  ].map(step => ({
    ...step,
    label: t(getHeaderNameText({ formName: currentFormName, field: step.field })),
  }));

  return (
    <div className={classes.permissionsFormRoot}>
      {userNotAllowedToViewContent ? (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('traceability.forbiddenContent')}
          </Typography>
        </Paper>
      ) : (
        <>
          <div className={classes.permissionsHelpers}>
            <WizardHelper
              formName={currentFormName}
              orderedFields={columnDefs.map(({ field }) => field)}
              centeredFields={columnDefs
                .filter(({ centeredFields }) => centeredFields)
                .map(({ field }) => field)}
            />
            {!isEditMode && userAllowedToEdit && (
              <div className={classes.button}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setEditMode(true)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            )}
          </div>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className={classes.permissionsForm}>
              {formValues?.map((item, itemIndex) => buildItemFields(item, itemIndex))}
            </Form>
          </FormikProvider>
          <WizardButtonArea
            isEditMode={isEditMode}
            setEditMode={setEditMode}
            isCancelDisabled={
              instanceDetails?.content?.lastCompletedIteration <
              TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code
            }
            errors={formik.errors}
            formik={formik}
            isProcessing={isProcessing}
            optionalStep
          />
        </>
      )}
    </div>
  );
};

export default PermissionsForm;
