import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import { useNotarizationContext } from '../../contexts/Notarization/notarizationContext';
import { getPrettifiedEthAddr } from '../../utils';
import NotarizationContractDetails from './NotarizationContractDetails';
import { dateFormatter } from '../../utils/dateUtils';

const useStyles = makeStyles(theme => ({
  NotarizationContractPanelRoot: {
    width: '52%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeaderArea: {
    display: 'flex',
  },
  contractHeader: {
    fontWeight: '400',
  },
  closeIcon: {
    marginRight: -8,
  },
  labelHeader: {
    fontWeight: '100',
    marginRight: theme.spacing(2),
  },
  addrArea: {
    marginLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  dateArea: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const NotarizationContractPanel = ({ handlePanelClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedNotarizationContract } = useNotarizationContext();

  return (
    <>
      <Card variant="outlined" className={classes.NotarizationContractPanelRoot}>
        <div className={classes.headerArea}>
          <Typography
            variant="h5"
            className={classes.contractHeader}
            data-testid={`notarization-contract-panel-banner-${selectedNotarizationContract?._id}`}
          >
            {selectedNotarizationContract?.contractName}
          </Typography>
          <IconButton onClick={handlePanelClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.symbolArea}>
          {selectedNotarizationContract?.contractAddress && (
            <div className={classes.subHeaderArea}>
              <div className={classes.dateArea}>
                <Typography variant="body2" className={classes.labelHeader}>
                  {t('notarization.dateCreated')}:
                </Typography>
                <Typography variant="body2">
                  {dateFormatter(selectedNotarizationContract?.createdAt)}
                </Typography>
              </div>
              <div className={classes.addrArea}>
                <Typography variant="body2" className={classes.labelHeader}>
                  {t('tokens.contractAddress')}:
                </Typography>
                <Typography variant="body2">
                  {getPrettifiedEthAddr(selectedNotarizationContract?.contractAddress)}
                </Typography>
                <CopyContent content={selectedNotarizationContract?.contractAddress} />
              </div>
            </div>
          )}
        </div>
        <NotarizationContractDetails />
      </Card>
    </>
  );
};
NotarizationContractPanel.propTypes = {
  handlePanelClose: PropTypes.func.isRequired,
};

export default NotarizationContractPanel;
