import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import Cookies from 'js-cookie';
import { isExpired, login } from '@eyblockchain/authentication-sdk/browser';
import { useSubscriptionContext } from './subscription';
import { publicRootPaths } from '../../utils';

const ERROR_CODES = {
  GRAPHQL_OPERATION_NAME_NOT_FOUND: 'graphql_operation_name_not_found',
  SUBSCRIPTION_NOT_FOUND: 'subscription_not_found',
  JWT_EXPIRED: 'Context creation failed: jwt expired',
};

// let networkConnection;
// let hasActiveNetworkConnection;
// let networkId;
// let connectionId;
let httpLink;

const uri = `${window.config.apiUrl}/graphql`;
const wsUri = `${window.config.apiUrl}/graphql`;

const getAuthToken = () => {
  const authToken = Cookies.get(`${window.config.cookiePrefix}access_token`);
  return authToken;
};

const isPublicPathProvided = () => {
  const isPublicPath = publicRootPaths.find(path => {
    return window.location.pathname.startsWith(path);
  });
  return isPublicPath;
};

const getNetworkDetails = () => {
  let hasActiveNetworkConnection;
  let networkConnection;
  let networkId;
  let connectionId;
  try {
    networkConnection = atob(
      Cookies.get(`${window.config.cookiePrefix}active_network_connection`),
    ).split('|');
    hasActiveNetworkConnection =
      networkConnection[0] !== 'undefined' && networkConnection[1] !== 'undefined';
    [networkId, connectionId] = networkConnection;
  } catch {
    networkConnection = '';
    hasActiveNetworkConnection = '';
    networkId = '';
    connectionId = '';
  }
  return { hasActiveNetworkConnection, networkId, connectionId };
};

const getWebsocketDetails = () => {
  let wsLink = null;
  let authLink = null;
  const { hasActiveNetworkConnection, networkId, connectionId } = getNetworkDetails();
  if (!isPublicPathProvided()) {
    httpLink = createUploadLink({
      uri: window.config.nodeEnv === 'development' ? `http://${uri}` : `https://${uri}`,
    });

    /**
     *  Declaring outside to avoid creating a new websocket every time
     *  this component re-renders.
     */
    wsLink = new WebSocketLink({
      uri: window.config.nodeEnv === 'development' ? `ws://${wsUri}` : `wss://${wsUri}`,
      options: {
        reconnect: true,
        reconnectionAttempts: 3,
        /**
         * Fix to address known issue causing gql console warnings
         * https://github.com/apollographql/subscriptions-transport-ws/issues/377
         */
        minTimeout: 10000,
        connectionParams: () => {
          const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
          const blockchainInfo = JSON.parse(blockchainCookie);

          return {
            authorization: `Bearer ${getAuthToken()}`,
            address: Cookies.get(`${window.config.cookiePrefix}address`),
            idToken: Cookies.get(`${window.config.cookiePrefix}id_token`),
            organizationid: atob(Cookies.get(`${window.config.cookiePrefix}active_organization`)),
            blockchainNetwork: blockchainInfo.network,
            blockchainType: blockchainInfo.blockchainType,
            activeWallet: atob(Cookies.get(`${window.config.cookiePrefix}active_wallet`) ?? ''),
            ...(hasActiveNetworkConnection && { networkId, connectionId }),
          };
        },
      },
    });

    authLink = setContext((_, { headers }) => {
      const authorization = getAuthToken() ? `Bearer ${getAuthToken()}` : '';
      const address = Cookies.get(`${window.config.cookiePrefix}address`);
      const organizationid = atob(Cookies.get(`${window.config.cookiePrefix}active_organization`));
      const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
      const blockchainInfo = JSON.parse(blockchainCookie);

      return {
        headers: {
          ...headers,
          authorization,
          address,
          organizationid,
          blockchainNetwork: blockchainInfo.network,
          blockchaintype: blockchainInfo.blockchainType,
          activeWallet: atob(Cookies.get(`${window.config.cookiePrefix}active_wallet`) ?? ''),
          idToken: Cookies.get(`${window.config.cookiePrefix}id_token`),
          ...(hasActiveNetworkConnection &&
            blockchainInfo.networkType !== 'public' && { networkId, connectionId }),
        },
      };
    });
  }
  return { wsLink, authLink };
};

const ApolloClientProvider = ({ children }) => {
  const { setSubscription } = useSubscriptionContext();

  if (getAuthToken() && isExpired(getAuthToken())) login(window.location.href, false);

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      for (let i = 0; i < graphQLErrors.length; i += 1) {
        const { message } = graphQLErrors[i];
        if (message.includes(ERROR_CODES.SUBSCRIPTION_NOT_FOUND)) {
          setSubscription(false);
          break;
        } else if (message.includes(ERROR_CODES.GRAPHQL_OPERATION_NAME_NOT_FOUND)) {
          // eslint-disable-next-line no-console
          console.error('Graphql operation name not found in server');
        } else if (message.includes(ERROR_CODES.JWT_EXPIRED)) {
          login(window.location.href, false);
        }
      }
    }
  });
  const { wsLink, authLink } = getWebsocketDetails();

  const terminatingLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const link = ApolloLink.from([errorLink, terminatingLink]);

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloClientProvider;
