import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InvoicePricingTable from '../../components/Procurement/InvoicePricingTable';
import { formatRates, formatCurrencySymbol, getCurrencyName } from '../../utils';
import { useInvoiceContext } from '../../contexts/Procurement/invoice';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';

const useRowStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    color: '#747480',
  },
  status: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '.5rem',
  },
  prices: {
    fontSize: '.875rem',
    color: '#2e2e38',
    marginLeft: '37%',
  },
  SubTotlaprices: {
    fontSize: 13,
    color: '#2e2e38',
    marginLeft: '40%',
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
    marginTop: '50px',
  },
  blackFont: {
    color: theme.palette.primary.main,
  },
  section: {
    paddingTop: theme.spacing(3),
  },
  itemsSection: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  sectionRight: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  titleText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  card: {
    padding: 0,
    width: '289px',
    textAlign: 'right',
  },
  cardTitle: {
    background: '#E7E7EA',
  },
  cardTitleText: {
    fontWeight: 'bold',
  },
  table: {
    boxShadow: '0px 0px 0px 0px',
    marginTop: theme.spacing(3),
  },
  tierComponent: {
    marginTop: 0,
  },
  totalBreakdownContainer: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    width: '331px',
  },
  totalBreakdown: {
    width: '100px',
  },
  lineBreak: {
    width: '330px',
    float: 'right',
    display: 'flex',
  },
  totalContainer: {
    marginLeft: 'auto',
  },
  expandIcon: {
    float: 'right',
    marginTop: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  underlineText: {
    color: theme.palette.info.main,
    fontSize: '.875rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    lineHeight: '1rem',
  },
  openChip: {
    background: '#A9A9A9',
    color: 'white',
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
    fontWeight: 600,
  },
  pendingChip: {
    background: '#FF9831',
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
    fontWeight: 600,
  },
  acceptedChip: {
    background: 'rgb(22 134 54)',
    color: 'white',
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
    fontWeight: 600,
  },
  deliveredChip: {
    background: 'rgb(22 134 54)',
    color: '#fff',
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
    fontWeight: 600,
  },
  loaderContainer: theme.loaderContainer,
  divider: {
    background: '#e0e0e0',
    marginBottom: '.5rem',
    marginTop: '.5rem',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  statusContainer: {
    display: 'flex',
  },
  preTitle: {
    fontWeight: '200',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '.875rem',
  },
  preTitleId: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  invoiceName: {
    fontWeight: 400,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  preTitleIdWithSpace: {
    fontWeight: 'bold',
    marginLeft: 4,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  preTitleRightSide: {
    marginLeft: 730,
  },
  smallHeaders: {
    fontSize: '.875rem',
    fontWeight: 300,
  },
  purchaseOrderDetails: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.5fr 1fr',
    gridGap: '20px',
  },
  buyersAddressBox: {
    marginTop: -20,
  },
  poBuyerDetailsSubHeaders: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  poCostDetails: {
    display: 'grid',
    gridTemplateRows: '0.5fr 1fr',
  },
  poNetValue: {
    background: '#E7E7EA',
    height: 50,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: 30,
  },
  poNetValueHeader: {
    marginLeft: '17.9rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: 15,
  },
  poValue: {
    background: '#FFFFFF',
    fontWeight: 500,
    height: 80,
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginBottom: 20,
  },
  poValueAmtText: {
    fontWeight: 300,
    fontSize: '28px',
    paddingLeft: '100px',
    textAlign: 'end',
    marginRight: 15,
  },
  poMeterialBox: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr',
  },
  tableIcon: {
    fontSize: 40,
    marginTop: 14,
    border: '1px solid',
  },
  tierTablespace: {
    paddingBottom: '100 !important',
  },
  subtotalHeader: {
    fontSize: 13,
    fontWeight: 300,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    textAlign: 'right',
    paddingLeft: 60,
    paddingBottom: 20,
    borderBottom: '1px solid',
    borderBottomColor: '#e0e0e0',
  },
  netValueHeader: {
    fontSize: '.875rem',
    fontWeight: 500,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    textAlign: 'right',
    paddingLeft: 40,
  },
  shortText: {
    fontSize: '.875rem',
    fontWeight: 300,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    paddingLeft: 40,
    textAlign: 'inherit',
  },
}));

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const { id: invoiceId } = useParams();
  const [refreshFlag, setRefreshFlag] = useState(false);
  const materialNames = [];
  let getPODetailsValue = [];
  let poDetailsValue = [];
  const { euroCurrencySymbol } = useSubscriptionContext();

  // let separatedMaterials = '';
  const { invoiceData, invoiceLoading, getInvoiceDetail } = useInvoiceContext();
  useEffect(() => {
    getInvoiceDetail({ variables: { invoiceId } });
    setRefreshFlag(false);
  }, [getInvoiceDetail, invoiceId, refreshFlag]);

  useEffect(() => {
    setRefreshFlag(false);
  }, [getInvoiceDetail]);

  if (invoiceData) {
    poDetailsValue = invoiceData.purchaseOrder.materials.map(material => {
      const materialCopy = { ...material };
      materialCopy.isSender = invoiceData.isSender;
      let isTiered = false;
      const materialIdentified = invoiceData.purchaseOrder.contract.materials.find(
        contractMaterial => contractMaterial.materialName === material.materialName,
      );
      materialNames.push(material.materialName);
      materialCopy.shipment = '-';
      let quantitySet =
        Number(material.volume) + Number(material.oldAccumulatedVolumeInContract || 0);
      materialCopy.sku = materialIdentified.materialName;
      materialCopy.quantity = material.volume;
      materialCopy.netValue = formatCurrencySymbol(`${material.totalPrice}`, euroCurrencySymbol);
      materialCopy.description = materialIdentified.materialDescription;
      materialCopy.tierValue = [];
      materialCopy.tireProgressBar = [];
      materialCopy.contractType = invoiceData.purchaseOrder.contract?.contractType;
      const rates =
        invoiceData.purchaseOrder.contract?.contractType !== 'rebatePricing'
          ? formatRates([materialIdentified])
          : [];
      if (invoiceData.purchaseOrder.contract?.contractType !== 'rebatePricing') {
        materialCopy.tierValue = rates?.map((rate, rateIndex) => {
          let quantityInTier = 0;
          let tempTierValue = {};
          if (quantitySet && !isTiered) {
            if (rate.endRange <= quantitySet) {
              quantityInTier =
                quantitySet > rate.endRange && rateIndex === rates.length - 1
                  ? `${rate.endRange}+`
                  : rate.endRange;
              quantitySet -= rate.endRange;
            } else {
              quantityInTier = quantitySet;
              isTiered = true;
            }
          }
          tempTierValue = {
            quantity:
              rates.length - 1 === rateIndex
                ? `${rate.startRange} - ${rate.endRange}+`
                : `${rate.startRange} - ${rate.endRange}`,
            qtyInTier: quantityInTier,
            netPrice: formatCurrencySymbol(`${rate.price}`, euroCurrencySymbol),
          };
          return tempTierValue;
        });
      } else {
        const rules = materialIdentified.rebateRules;
        if (rules.rebateValue) {
          rules.rebateValue.forEach((value, i) => {
            materialCopy.tierValue.push({
              rebateThreshold: rules.thresholdQty[i],
              rebatePercentage: value,
            });
          });
        }
      }
      return materialCopy;
    });
  }

  // separatedMaterials = materialNames.join(',');

  getPODetailsValue = [...poDetailsValue];

  const history = useHistory();
  const statusBar = () => {
    if (invoiceData.status === 'posted') {
      return <span className={classes.pendingChip}>{capitalize(invoiceData.status)}</span>;
    }
    return <span className={classes.deliveredChip}>{capitalize(invoiceData.status)}</span>;
  };

  return (
    <>
      {!invoiceData ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {invoiceLoading ? (
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          ) : (
            ''
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            px={2}
            className={classes.navContainer}
          >
            {' '}
            <Button
              variant="text"
              color="primary"
              onClick={() => history.replace('/invoices')}
              className={classes.navButton}
              startIcon={<ArrowBackIosIcon />}
            >
              {t('headers.invoice_plural')}
            </Button>
          </Box>
          <Divider classes={{ root: classes.navButtonDivider }} />
          <Grid className={classes.root}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.status}
            >
              <div>
                <Typography className={classes.preTitle} variant="h5">
                  {t('invoices.invoiceID')}
                  <span className={classes.preTitleId}>{` IN-${invoiceData.invoiceId.padStart(
                    10,
                    0,
                  )}`}</span>
                </Typography>
                <Typography className={classes.sectionTitle} variant="h5">
                  {materialNames.join(', ')}
                </Typography>
              </div>
              <div className={classes.statusContainer}>
                <Typography style={{ marginRight: '4px' }}>
                  {t('common.globalStatus')}
                  {statusBar()}
                </Typography>
                {/* <Typography variant="h4" className={classes.invoiceName}>
                  {separatedMaterials.length >= 50
                    ? separatedMaterials.substr(0, 50).concat('...')
                    : separatedMaterials}
                </Typography> */}
              </div>
            </Grid>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <Grid container direction="row" className={classes.section}>
              {/* buyerInfo */}
              <Grid item xs={6}>
                {invoiceData?.isSender ? (
                  <>
                    <Typography>{t('common.businessPartner')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders} variant="h5">
                      {invoiceData.buyer.organization.name}
                    </Typography>
                    <Typography>
                      {invoiceData.buyer.organization.address.city
                        ? `${invoiceData.buyer.organization.address.city},`
                        : ''}
                      {invoiceData.buyer.organization.address.street_line
                        ? `${invoiceData.buyer.organization.address.street_line},`
                        : ''}
                      {invoiceData.purchaseOrder.buyer.organization.address.postal_code
                        ? `${invoiceData.buyer.organization.address.postal_code},`
                        : ''}
                    </Typography>
                    <Typography>{invoiceData.buyer.organization.address.phone}</Typography>
                  </>
                ) : (
                  <>
                    <Typography>{t('common.businessPartner')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders} variant="h5">
                      {invoiceData.supplier.organization.name}
                    </Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders} variant="h5">
                      {invoiceData.supplier.organization.address.street_line}
                    </Typography>
                    <Typography>
                      {' '}
                      {invoiceData.supplier.organization.address.city
                        ? `${invoiceData.supplier.organization.address.city},`
                        : ''}
                      {invoiceData.supplier.organization.address.street_line
                        ? `${invoiceData.supplier.organization.address.street_line},`
                        : ''}
                      {invoiceData.supplier.organization.address.postal_code
                        ? `${invoiceData.supplier.organization.address.postal_code},`
                        : ''}
                    </Typography>
                    <Typography>{invoiceData.supplier.organization.address.phone}</Typography>
                  </>
                )}
              </Grid>

              <Grid item xs={6} className={classes.sectionRight}>
                <div
                  style={{
                    marginRight: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <div className={classes.blackFont}>
                    <Typography>{t('invoices.invoiceDate')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders}>
                      {moment(+invoiceData.invoiceDate).format('D MMM YYYY')}
                    </Typography>
                  </div>
                  <div className={classes.blackFont}>
                    <Typography>{t('invoices.dueDate')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders}>
                      {moment(+invoiceData.dueDate).format('D MMM YYYY')}
                    </Typography>
                  </div>
                </div>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardTitle}
                    titleTypographyProps={{ className: classes.cardTitleText }}
                    title={t('invoices.netValue')}
                  />
                  <CardContent>
                    <Typography variant="h4">
                      {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                        invoiceData.purchaseOrder.totalPrice,
                        euroCurrencySymbol,
                      )}`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <div>
              <InvoicePricingTable key={uniqid()} materials={getPODetailsValue} />
            </div>
            <div />
          </Grid>
        </>
      )}
    </>
  );
};

export default InvoiceDetails;
