import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MemoryIcon = () => {
  return (
    <SvgIcon viewBox="0 0 18 18">
      <title>Memory</title>
      <g
        id="Other-data-types-regrouped"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="RBAC-single-token-1" transform="translate(-111.000000, -415.000000)">
          <g id="Memory" transform="translate(108.000000, 412.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M15,9 L9,9 L9,15 L15,15 L15,9 Z M13,13 L11,13 L11,11 L13,11 L13,13 Z M21,11 L21,9 L19,9 L19,7 C19,5.9 18.1,5 17,5 L15,5 L15,3 L13,3 L13,5 L11,5 L11,3 L9,3 L9,5 L7,5 C5.9,5 5,5.9 5,7 L5,9 L3,9 L3,11 L5,11 L5,13 L3,13 L3,15 L5,15 L5,17 C5,18.1 5.9,19 7,19 L9,19 L9,21 L11,21 L11,19 L13,19 L13,21 L15,21 L15,19 L17,19 C18.1,19 19,18.1 19,17 L19,15 L21,15 L21,13 L19,13 L19,11 L21,11 Z M17,17 L7,17 L7,7 L17,7 L17,17 Z"
              id="Shape"
              fill="#2E2E38"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

MemoryIcon.propTypes = {};

MemoryIcon.defaultProps = {};

export default MemoryIcon;
