import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HistoryIcon from '@material-ui/icons/History';
import LiveNotifications from '@eyblockchain/ey-ui/core/LiveNotifications';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import findIndex from 'lodash/findIndex';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import { useNotification } from '../../contexts/Shared/notification';
import LiveTransactionsItem from './LiveTransactionsItem';
import {
  GET_RECENT_TRANSACTIONS,
  GET_COMPLETED_TRANSACTION,
  CLEAR_RECENT_TRANSACTION,
} from '../../graphql/Shared/transaction';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  badgePosition: {
    top: '35%',
    position: 'absolute',
    right: 0,
  },
  panelStyle: {
    maxWidth: '340px',
  },
  divider: {
    margin: theme.spacing(2, 1),
    height: '1px',
    backgroundColor: '#E7E7EA',
  },
  panelTitle: {
    fontWeight: 'bold',
  },
  transparentButton: {
    background: 'none',
    border: '1px solid #EAEAF2',
    color: '#2E2E38',
    width: '76px',
    height: '32px',
    '&:hover': {
      backgroundColor: '#eaeaea !important',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#eaeaea !important',
      boxShadow: 'none',
    },
  },
  icon: {
    position: 'relative',
    top: theme.spacing(0.5),
    height: '1rem',
  },
  customTooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.lightest,
    fontSize: '12px',
  },
  subSection: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    fontSize: '14px',
    marginBottom: theme.spacing(1.5),
  },
  buttonLabel: {
    paddingLeft: theme.spacing(0.4),
    fontFamily: 'EYInterstate-Light',
    fontSize: '12px',
  },
  buttonIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.primary.light,
  },
}));

/* eslint-disable no-unused-vars, no-nested-ternary, no-console */
const LiveTransactionsPanel = () => {
  const { t } = useTranslation('liveTnxPanel');
  const classes = useStyles();

  const { updateLivePanel, setUpdateLivePanel, finishedTnxs, setFinishedTnxs } = useNotification();
  const { activeWallet } = useBecOrganizationContext();

  const [queueCount, setQueueCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [showBadge, setShowBadge] = useState(false);
  const [expandedItem, setExpandedItem] = useState({
    completedSection: '',
    pendingSection: '',
  });

  const onClickAccordionCompleted = panel => (event, newExpanded) => {
    setExpandedItem(prevState => ({
      ...prevState,
      completedSection: newExpanded ? panel : false,
    }));
  };

  const onClickAccordionPending = panel => (event, newExpanded) => {
    setExpandedItem(prevState => ({
      ...prevState,
      pendingSection: newExpanded ? panel : false,
    }));
  };

  const [getRecentTransactions] = useLazyQuery(GET_RECENT_TRANSACTIONS, {
    onCompleted: data => {
      const pending = data.recentTransactions.filter(x => x.status === 'pending');

      const completed = data.recentTransactions.filter(x => x.status !== 'pending');

      setQueueCount(pending.length);
      setCompletedTransactions(completed);
      setPendingTransactions(pending);
      setLoadingData(false);
      setShowBadge(pending.length !== 0);
      setUpdateLivePanel(false);
    },
    onError: error => {
      console.log(error);
    },
    fetchPolicy: 'no-cache',
  });

  const [updatePendingTransactions] = useLazyQuery(GET_COMPLETED_TRANSACTION, {
    onCompleted: data => {
      const updatedTnx = data.completedTransaction;
      const newPending = [...pendingTransactions];
      const newCompleted = [updatedTnx, ...completedTransactions]; // add pending to completed
      const indexPending = findIndex(newPending, {
        _id: updatedTnx._id,
      });
      newPending.splice(indexPending, 1); // remove pending

      const newFinished = [...finishedTnxs];
      const indexFinished = newFinished.findIndex(x => x === updatedTnx.transactionHash);
      newFinished.splice(indexFinished, 1);

      setFinishedTnxs(newFinished);
      setQueueCount(newPending.length);
      setShowBadge(newPending.length !== 0);
      setPendingTransactions(newPending);
      setCompletedTransactions(newCompleted);
    },
    onError: error => {
      console.log(error);
    },
    fetchPolicy: 'no-cache',
  });

  const [clearRecentTransaction] = useMutation(CLEAR_RECENT_TRANSACTION);

  const clickClearTnx = async transctionId => {
    const newCompleted = [...completedTransactions];

    const itemIndex = findIndex(newCompleted, {
      _id: transctionId,
    });

    if (itemIndex !== -1) {
      newCompleted.splice(itemIndex, 1);
      setCompletedTransactions(newCompleted);

      await clearRecentTransaction({ variables: { transactions: transctionId } });
    }
  };

  useEffect(() => {
    setLoadingData(true);
    getRecentTransactions();
  }, [activeWallet]);

  useEffect(() => {
    if (updateLivePanel) {
      setLoadingData(true);
      getRecentTransactions();
    }
  }, [updateLivePanel]);

  useEffect(() => {
    if (finishedTnxs && finishedTnxs.length > 0) {
      const lastTnx = finishedTnxs.slice(-1)[0];
      updatePendingTransactions({ variables: { transactionHash: lastTnx } });
    }
  }, [finishedTnxs]);

  return (
    <LiveNotifications
      badgeStyle={classes.badgePosition}
      panelStyle={classes.panelStyle}
      showNotificationBadge={showBadge}
    >
      <div>
        <Box display="flex" flexDirection="column" ml={3} mr={2} mt={3}>
          <Box display="flex" mb={3}>
            <Box flexGrow={1}>
              <Typography className={classes.panelTitle}>{t('title')}</Typography>
            </Box>
            <Box>
              <Button
                disableRipple
                disableFocusRipple
                className={classes.transparentButton}
                color="default"
                variant="contained"
                size="small"
                href={`${window.platformConfig.becUrl}/transactions`}
              >
                <HistoryIcon className={classes.buttonIcon} />
                <Typography className={classes.buttonLabel}> {t('btnHistory')} </Typography>
              </Button>
            </Box>
          </Box>
          <Typography component="div">
            <Box mb={2} fontSize="body2.fontSize">
              {t('mainText')}
              {`${
                activeWallet?.network === 'mainnet'
                  ? t('networks.mainnet')
                  : activeWallet?.network
                  ? t('networks.ropsten')
                  : ''
              }`}
              <Tooltip
                arrow
                title={t('ropstenTooltip')}
                placement="bottom"
                classes={{
                  tooltip: classes.customTooltip,
                }}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </Tooltip>
            </Box>
            {!loadingData && (
              <Box fontSize="body2.fontSize">{`${queueCount} ${t('queueText')}`}</Box>
            )}
          </Typography>
        </Box>

        {loadingData ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="column" mb={2}>
              <Typography className={classes.subSection}>
                {t('pendingTransactions.title')}
              </Typography>
              {pendingTransactions.map(item => (
                <LiveTransactionsItem
                  key={item._id}
                  transactionItem={item}
                  isExpanded={expandedItem.pendingSection === `panel_${item._id}`}
                  handleClickItem={onClickAccordionPending}
                  clickClearTnx={clickClearTnx}
                />
              ))}
            </Box>
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="column" mb={4}>
              <Typography className={classes.subSection}>
                {t('completedTransactions.title')}
              </Typography>
              {completedTransactions.map(item => (
                <LiveTransactionsItem
                  key={item._id}
                  transactionItem={item}
                  isExpanded={expandedItem.completedSection === `panel_${item._id}`}
                  handleClickItem={onClickAccordionCompleted}
                  clickClearTnx={clickClearTnx}
                />
              ))}
            </Box>
          </>
        )}
      </div>
    </LiveNotifications>
  );
};

export default LiveTransactionsPanel;
