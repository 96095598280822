import React from 'react';
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { TRACE_WIZARD_PROGRESSION_V2, TRACEABILITY_DISPATCHER_ACTIONS } from '../../../constants';

const ContinueButton = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { instanceId, instanceDispatcher } = useConfigurationWizardContext();

  const goToNextStep = () => {
    const currentPath = (history?.location?.pathname?.split('/'))[2];
    const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: currentPath })?.code;
    const newPath = find(TRACE_WIZARD_PROGRESSION_V2, { code: currPathCode + 1 })?.path;
    history.push(`/traceability/${newPath}/${instanceId}`);
    instanceDispatcher({
      type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_CURRENT_ITERATION,
      payload: currPathCode + 1,
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<ArrowRightAltOutlinedIcon />}
      onClick={goToNextStep}
    >
      {t('traceability.configurationWizard.progress.continue')}
    </Button>
  );
};

export default ContinueButton;
