import React, { useContext, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { findIndex, orderBy, find } from 'lodash';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { GET_INSTANCE_BY_ID } from '../../graphql/Traceability/instance';
import {
  CONSTANTS,
  TRACE_WIZARD_PROGRESSION_V2,
  TRACEABILITY_DISPATCHER_ACTIONS,
} from '../../constants';
import { GET_ITEM_TYPES, GET_ITEM_TYPE_BY_ID } from '../../graphql/Traceability/itemTypes';
import {
  GET_CODIFICATION_TYPES,
  GET_CODIFICATION_TYPE_BY_ID,
} from '../../graphql/Traceability/codificationTypes';
import { GET_INGESTION_TYPES } from '../../graphql/Traceability/ingestionTypes';
import { GET_STEP_DATA_TYPES } from '../../graphql/Traceability/stepDataTypes';
import { GET_TOKEN_METADATA_TYPES } from '../../graphql/Tokenization/tokenMetadataTypes';
import { useSubscriptionContext } from '../Shared/subscription';
import { useNotification } from '../Shared/notification';
import { GET_MY_PARTNERS } from '../../graphql/Procurement/partner';

const ConfigurationWizardContext = React.createContext([{}, () => {}]);

const ConfigurationWizardProvider = ({ children }) => {
  const { t } = useTranslation();
  const { traceabilityAccess } = useSubscriptionContext();
  const { handleNotification } = useNotification();
  const { TRACE_PATHS } = CONSTANTS;
  const { pathname } = useLocation();
  const rootPath = pathname.split('/')[2];
  const client = useApolloClient();
  const history = useHistory();

  const [lastCompletedIteration, setLastCompletedIteration] = useState(
    TRACE_WIZARD_PROGRESSION_V2.NEW_WIZARD.code,
  );

  let instanceId = null;
  let { path } = TRACE_WIZARD_PROGRESSION_V2.NEW_WIZARD;

  if (Object.values(TRACE_PATHS).includes(rootPath)) {
    [, , path, instanceId] = pathname.split('/');
  }

  const isWizardContextNeeded = instanceId && traceabilityAccess;

  const initialState = {
    items: [],
    steps: [],
    currentIteration: find(TRACE_WIZARD_PROGRESSION_V2, { path: path })?.code,
    isOwner: false,
  };

  const instanceReducer = (state, action) => {
    switch (action.type) {
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE: {
        if (lastCompletedIteration < state?.lastCompletedIteration) {
          setLastCompletedIteration(state?.lastCompletedIteration);
        }
        return {
          currentIteration: state.currentIteration,
          ...action.payload,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_ITEMS: {
        setLastCompletedIteration(TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.code);
        return {
          ...state,
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.code,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
          items: action.payload,
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_STEPS: {
        const stepIndex = findIndex(state.steps, { _id: action.payload?._id });
        let steps = [...state.steps];
        steps[stepIndex] = action.payload;
        steps = orderBy(steps, ['order']);
        return {
          ...state,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
          steps: [...steps],
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.ADD_STEPS: {
        let existingSteps = [...state.steps];
        existingSteps.push(action.payload);
        existingSteps = orderBy(existingSteps, ['order']);
        setLastCompletedIteration(TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code);
        return {
          ...state,
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
          steps: [...existingSteps],
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_STEPS_PERMISSIONS: {
        const stepIndex = findIndex(state.steps, { _id: action.payload?._id });
        let steps = [...state.steps];
        steps[stepIndex] = action.payload;
        steps = orderBy(steps, ['order']);
        setLastCompletedIteration(TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code);
        return {
          ...state,
          steps: [...steps],
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_METADATA: {
        const itemToUpdate = findIndex(state.items, { _id: action.payload.itemId });
        const items = [...state.items];
        items[itemToUpdate] = {
          ...items[itemToUpdate],
          tokenContract: {
            ...items[itemToUpdate]?.tokenContract,
            metadataStructure: action.payload.metadataConfig,
          },
          ...(action?.payload?.parentMetadataConfig && {
            parentItem: {
              ...items[itemToUpdate]?.parentItem,
              tokenContract: {
                ...items[itemToUpdate]?.parentItem?.tokenContract,
                metadataStructure: {
                  ...items[itemToUpdate]?.parentItem?.tokenContract?.metadataStructure,
                  metadataConfig: action?.payload?.parentMetadataConfig,
                },
              },
            },
          }),
        };
        setLastCompletedIteration(TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code);
        return {
          ...state,
          items,
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code,
          status: CONSTANTS.INSTANCE_STATUSES.FINALIZE,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.DELETE_ITEMS: {
        const items = [...state.items];
        const steps = [...state.steps];
        const updatedItems = items.filter(item => !action.payload.find(rm => rm === item._id));
        const updatedSteps = steps.filter(
          step => !action.payload.find(rm => rm === step?.involvedItem?._id),
        );
        return {
          ...state,
          items: [...updatedItems],
          steps: [...updatedSteps],
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.DELETE_STEPS: {
        const steps = [...state.steps];
        const updatedSteps = steps.filter(step => !action.payload.find(rm => rm === step._id));
        return {
          ...state,
          steps: [...updatedSteps],
          status:
            state.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE
              ? CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION
              : state.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.RESET_INSTANCE: {
        return action.payload;
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_CURRENT_ITERATION: {
        return {
          ...state,
          currentIteration: action.payload,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE_STATUS: {
        setLastCompletedIteration(action.payload.lastCompletedIteration);
        return {
          ...state,
          lastCompletedIteration: action.payload.lastCompletedIteration,
          currentIteration: action.payload.currentIteration,
          status: action.payload.status,
        };
      }
      case TRACEABILITY_DISPATCHER_ACTIONS.FINALIZE_INSTANCE: {
        return {
          ...state,
          status: action.payload,
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code,
        };
      }
      default: {
        return action.payload;
      }
    }
  };

  // #region INSTANCE DETAILS
  const [instanceDetails, instanceDispatcher] = useReducer(instanceReducer, initialState);
  const [itemTypes, setItemTypes] = useState([]);
  const [metadataTypes, setMetadataTypes] = useState([]);
  const [itemTypeById, setItemTypeById] = useState();
  const [queryItemTypeId, setQueryItemTypeId] = useState('');
  const [stepDataTypes, setStepDataTypes] = useState([]);
  const [codificationTypes, setCodificationTypes] = useState([]);
  const [codificationTypeById, setCodificationTypeById] = useState();
  const [queryCodificationTypeId, setQueryCodificationTypeId] = useState('');
  const [ingestionTypes, setIngestionTypes] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [stepIdsToDelete, setStepIdsToDelete] = useState([]);
  const [partners, setPartners] = useState([]);
  const [errorLoadingInstance, setErrorLoadingInstance] = useState(false);
  const [instanceNotFound, setInstanceNotFound] = useState(false);

  const validateCurrentStep = fetchedInstance => {
    const currentPath = pathname.split('/')[2];
    const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: currentPath })?.code;
    if (
      fetchedInstance?.lastCompletedIteration !== 0 &&
      currPathCode > fetchedInstance?.lastCompletedIteration
    ) {
      history.push(
        `/traceability/${
          find(TRACE_WIZARD_PROGRESSION_V2, { code: fetchedInstance.lastCompletedIteration })?.path
        }/${fetchedInstance._id}`,
      );
    }
  };

  const { activeOrganization } = useBecOrganizationContext();

  const getInstanceDetails = async () => {
    setIsDataLoading(true);
    try {
      setErrorLoadingInstance(false);
      const { data } = await client.query({
        query: GET_INSTANCE_BY_ID,
        variables: { input: instanceId },
        fetchPolicy: 'no-cache',
      });
      setInstanceNotFound(!data?.instance);

      if (data) {
        const fetchedData = data?.instance;
        let steps = fetchedData?.steps;
        const orgId = fetchedData?.organizationId;

        if (!fetchedData) {
          throw new Error('Unable to fetch data as the instance is invalid');
        }

        validateCurrentStep(data?.instance);

        if (steps) {
          steps = orderBy(steps, ['order']);
          fetchedData.steps = steps;
        }

        if (lastCompletedIteration < fetchedData?.lastCompletedIteration) {
          setLastCompletedIteration(fetchedData?.lastCompletedIteration);
        }

        if (orgId) {
          fetchedData.isOwner = orgId === activeOrganization?._id;
        }

        instanceDispatcher({
          type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE,
          payload: fetchedData,
        });
      }
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
      setErrorLoadingInstance(true);
    } finally {
      setIsDataLoading(false);
    }
  };

  const sortItemTypes = itemTypeOptions => {
    const newItemTypeOptions = [...itemTypeOptions];

    const rawMaterialIndex = findIndex(
      newItemTypeOptions,
      item => item.name === 'traceability.itemType.RawMaterial',
    );
    const workInProgressIndex = findIndex(
      newItemTypeOptions,
      item => item.name === 'traceability.itemType.WorkinProgress',
    );
    const finalGoodIndex = findIndex(
      newItemTypeOptions,
      item => item.name === 'traceability.itemType.FinalGood',
    );

    const remainingArray = newItemTypeOptions.filter(
      (_, i) => i !== rawMaterialIndex && i !== workInProgressIndex && i !== finalGoodIndex,
    );

    if (rawMaterialIndex !== -1 && workInProgressIndex !== -1 && finalGoodIndex !== -1)
      return [
        newItemTypeOptions[rawMaterialIndex],
        newItemTypeOptions[workInProgressIndex],
        newItemTypeOptions[finalGoodIndex],
        ...remainingArray,
      ];
    return [...newItemTypeOptions];
  };

  const getItemTypes = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_ITEM_TYPES,
        fetchPolicy: 'no-cache',
      });

      const sortedItemTypes = sortItemTypes(data.itemTypes);

      setItemTypes(
        sortedItemTypes.map(element => Object.assign(element, { name: t(element.name) })),
      );
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    }

    setIsDataLoading(false);
  };

  const getMetadataTypes = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_TOKEN_METADATA_TYPES,
        fetchPolicy: 'no-cache',
      });
      setMetadataTypes(data.tokenMetadataTypes);
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    }

    setIsDataLoading(false);
  };

  const getStepDataTypes = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_STEP_DATA_TYPES,
        fetchPolicy: 'no-cache',
      });
      setStepDataTypes(data.stepDataTypes);
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    }
    setIsDataLoading(false);
  };

  const getCodificationTypes = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_CODIFICATION_TYPES,
        fetchPolicy: 'no-cache',
      });
      setCodificationTypes(
        data.codificationTypes.map(element => Object.assign(element, { name: t(element.name) })),
      );
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    }
    setIsDataLoading(false);
  };

  const getIngestionTypes = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_INGESTION_TYPES,
        fetchPolicy: 'no-cache',
      });
      setIngestionTypes(
        data.ingestionTypes.map(element => Object.assign(element, { name: t(element.name) })),
      );
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    }
    setIsDataLoading(false);
  };

  const getPartnerDetails = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await client.query({
        query: GET_MY_PARTNERS,
        fetchPolicy: 'no-cache',
      });
      setPartners(data?.partners);
    } catch (err) {
      handleNotification(
        err?.message ? err.message : t('traceability.permissionsForm.partnerError'),
        'error',
      );
    }
    setIsDataLoading(false);
  };

  const [
    getItemTypeById,
    { loading: loadingItemTypeById, error: errorItemTypeById },
  ] = useLazyQuery(GET_ITEM_TYPE_BY_ID, {
    variables: { itemTypeId: queryItemTypeId },
    onCompleted: data => setItemTypeById(data),
    onError: err => {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getCodificationTypeById,
    { loading: loadingCodificationTypeById, error: errorCodificationTypeById },
  ] = useLazyQuery(GET_CODIFICATION_TYPE_BY_ID, {
    variables: { codificationTypeId: queryCodificationTypeId },
    onCompleted: data => setCodificationTypeById(data),
    onError: err => {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    },
    fetchPolicy: 'no-cache',
  });

  const resetInstanceDetails = () => {
    instanceDispatcher({
      type: TRACEABILITY_DISPATCHER_ACTIONS.RESET_INSTANCE,
      payload: initialState,
    });
    setLastCompletedIteration(0);
  };

  useEffect(() => {
    if (isWizardContextNeeded) {
      getItemTypes();
      getCodificationTypes();
      getStepDataTypes();
      getIngestionTypes();
      getPartnerDetails();
      getMetadataTypes();
      /**
       * Note: Specifying input variables here to avoid apollo client's
       * automatic-refetch-on-input-change
       */
      getInstanceDetails({ variables: { input: instanceId } });
    }
  }, [isWizardContextNeeded]);

  return (
    <ConfigurationWizardContext.Provider
      value={{
        setQueryItemTypeId,
        queryItemTypeId,
        getItemTypeById,
        itemTypes: {
          itemTypes,
          itemTypeById,
          loadingItemTypeById,
          errorItemTypeById,
        },
        setQueryCodificationTypeId,
        queryCodificationTypeId,
        getCodificationTypeById,
        codificationTypes: {
          codificationTypes,
          codificationTypeById,
          loadingCodificationTypeById,
          errorCodificationTypeById,
        },
        stepDataTypes: {
          stepDataTypes,
        },
        ingestionTypes: {
          ingestionTypes,
        },
        metadataTypes: {
          metadataTypes,
        },
        instanceDetails: {
          content: instanceDetails,
          getInstanceDetails,
        },
        instanceDispatcher,
        instanceId,
        lastCompletedIteration,
        setLastCompletedIteration,
        isDataLoading,
        setIsDataLoading,
        stepIdsToDelete,
        setStepIdsToDelete,
        partners,
        resetInstanceDetails,
        errorLoadingInstance,
        setErrorLoadingInstance,
        instanceNotFound,
        setInstanceNotFound,
      }}
    >
      {children}
    </ConfigurationWizardContext.Provider>
  );
};

const useConfigurationWizardContext = () => useContext(ConfigurationWizardContext);

ConfigurationWizardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useConfigurationWizardContext, ConfigurationWizardProvider };
