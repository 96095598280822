import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import ValueChainDetails from './ValueChainDetails';
import TokenTypeChip from '../../Shared/TokenTypeChip';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    boxShadow: 'none',
    border: '1px solid #E7E7EA',
    marginBottom: theme.spacing(2),
    padding: 0,
    alignItems: 'stretch',
  },
  cardContent: {
    position: 'relative',
    minHeight: '104px',
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  borderSelected: {
    borderLeft: `8px solid ${theme.palette.secondary.main} !important`,
  },
  itemBorder: {
    borderRight: '1px solid #C4C4CD',
  },
  itemBox: {
    padding: theme.spacing(2, 5),
    margin: theme.spacing(2, 0),
  },
  contentDescription: {
    fontWeight: 400,
    marginRight: theme.spacing(1),
  },
  tokenContractDescription: {
    fontWeight: 400,
    marginRight: theme.spacing(1),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  collapse: {
    minWidth: theme.spacing(15),
    backgroundColor: '#F6F6FA',
  },
  contractName: {
    margin: 0,
  },
}));

const CollapseArrow = ({ isOpen, onItemClick }) => {
  return (
    <>
      {isOpen ? (
        <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={onItemClick} />
      ) : (
        <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={onItemClick} />
      )}
    </>
  );
};

const ValueChainSummary = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { instanceDetails } = useConfigurationWizardContext();

  const populateStepLabel = () => {
    const associatedSteps = instanceDetails?.content?.steps?.filter(
      step => step?.involvedItem._id === item._id,
    );
    const stepNames = associatedSteps.map(step => step.name).join('; ');

    return stepNames || t('traceability.instanceSummary.NA');
  };

  const handleClick = () => {
    setOpen(!open);
    setIsActive(!open);
  };

  return (
    <Card className={classes.cardRoot}>
      <CardContent className={classes.cardContent}>
        <Box
          className={clsx({
            [classes.borderSelected]: isActive && classes.borderSelected,
          })}
        />
        <Grid container>
          <Grid item xs={4}>
            <Box className={clsx(classes.itemBox, classes.itemBorder)}>
              <Typography component="div">
                <Box fontFamily="EYInterstate-Light" fontSize="0.75rem" color="#747480">
                  {t('traceability.wizardSummary.item')}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  fontFamily="EYInterstate-Regular"
                  color="primary.main"
                  style={{ minWidth: theme.spacing(35.375) }}
                  data-testid="item-name-text-final"
                >
                  <Typography className={classes.contentDescription}>{item?.name}</Typography>

                  <CollapseArrow isOpen={open} onItemClick={handleClick} />
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={clsx(classes.itemBox, classes.itemBorder)}>
              <Typography component="div">
                <Box fontFamily="EYInterstate-Light" fontSize="0.75rem" color="#747480">
                  {t('traceability.wizardSummary.smartContract')}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  fontFamily="EYInterstate-Regular"
                  color="primary.main"
                  style={{ minWidth: theme.spacing(35.375) }}
                >
                  <Typography
                    className={classes.tokenContractDescription}
                    data-testid="contract-name-text-final"
                  >
                    {item?.tokenContract?.contractName ? (
                      <>
                        <p className={classes.contractName}>{item?.tokenContract?.contractName}</p>
                        <TokenTypeChip tokenType={item?.tokenContract?.tokenType} shortenedLabel />
                      </>
                    ) : (
                      t('traceability.instanceSummary.NA')
                    )}
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.itemBox}>
              <Typography component="div">
                <Box fontFamily="EYInterstate-Light" fontSize="0.75rem" color="#747480">
                  {t('traceability.wizardSummary.steps')}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  fontFamily="EYInterstate-Regular"
                  color="primary.main"
                  style={{ minWidth: theme.spacing(35.375) }}
                >
                  <Typography
                    className={classes.contentDescription}
                    data-testid="step-name-text-final"
                  >
                    {populateStepLabel()}
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={open}>
        <CardContent className={classes.cardContent}>
          <Box
            className={clsx({
              [classes.borderSelected]: isActive && classes.borderSelected,
            })}
          />
          <Grid container>
            <Grid item xs={12}>
              <ValueChainDetails item={item} />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

ValueChainSummary.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tokenContract: PropTypes.shape({
      contractName: PropTypes.string,
      tokenType: PropTypes.string,
    }),
  }).isRequired,
};
CollapseArrow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
};
export default ValueChainSummary;
