import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Select from '@eyblockchain/ey-ui/core/Select';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Field } from 'formik';
import { useMutation, useApolloClient, useSubscription } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import { CONSTANTS } from '../../../constants';
import DeployERCTokenContract from '../../Tokenization/DeployERCTokenContract';
import { useNotification } from '../../../contexts/Shared/notification';
import {
  DEPLOY_ERC721_BCOS,
  DEPLOY_ERC721_BSN,
  DEPLOY_ERC721,
  GET_ERC_TOKENS,
  GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS,
} from '../../../graphql/Tokenization/token';

const useStyles = makeStyles(theme => ({
  InstanceTokenBoxRoot: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  itemBanner: {
    fontWeight: '700',
  },
  separator: {
    color: theme.palette.primary.light,
    marginLeft: '1em',
    marginRight: '1em',
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: '60%',
  },
  selectInput: {
    minWidth: '30%',
  },
  loadBox: {
    minWidth: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  createButton: {
    color: theme.palette.info.main,
    padding: '0px',
  },
  retryButton: {
    color: theme.palette.error.main,
    padding: '0px',
  },
  retryText: {
    color: theme.palette.error.main,
  },
  successText: {
    color: theme.palette.success.main,
  },
  retryIcon: {
    color: theme.palette.error.main,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  disabledButton: {
    '&:disabled': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  bannerBox: {
    display: 'flex',
    marginTop: '0.25em',
  },
  bannerText: {
    marginLeft: '0.25em',
  },
  createArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minWidth: '25%',
  },
  separatorArea: {
    minWidth: '20%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const InstanceTokenBox = ({ item, setFieldValue, dirty, error }) => {
  const { TOKEN_CREATION_STATES } = CONSTANTS;
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const { handleNotification } = useNotification();
  const client = useApolloClient();

  const [tokenOptions, setTokenOptions] = useState([
    { label: t('traceability.dgp.itemNone'), value: '' },
  ]);
  const [creationState, setCreationState] = useState(TOKEN_CREATION_STATES.FRESH);
  const [deployFormOpen, setDeployFormOpen] = useState(false);
  const [retryInput, setRetryInput] = useState(null);
  const [tokensLoading, setTokensLoading] = useState(false);
  const [pendingToken, setPendingToken] = useState();

  useSubscription(GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS, {
    onSubscriptionData: data => {
      const contract = data.subscriptionData?.data?.smartContractDeployed;
      if (pendingToken === contract?._id) {
        if (contract?.transaction?.status === 'completed') {
          setCreationState(TOKEN_CREATION_STATES.SUCCESS);
          setFieldValue(item._id, contract._id || '');
        } else {
          handleNotification(t('tokens.deployError'), 'error');
          setCreationState(TOKEN_CREATION_STATES.ERROR);
        }
      }
    },
  });

  const [deployERC721TokenContract, { loading: isDeployERC721TokenContractLoading }] = useMutation(
    DEPLOY_ERC721,
  );

  const [
    deployERC721TokenContractBCOS,
    { loading: isDeployERC721TokenBcosContractLoading },
  ] = useMutation(DEPLOY_ERC721_BCOS);

  const [
    deployERC721TokenContractBsn,
    { loading: isDeployERC721TokenBsnContractLoading },
  ] = useMutation(DEPLOY_ERC721_BSN);

  let isSelectDisabled = true;
  let isCreateDisabled = true;
  let showBanner = false;
  let banner;
  let bannerIcon;
  let bannerClass = classes.bannerText;
  const currOrgId = atob(Cookies.get(`${window.config.cookiePrefix}active_organization`));

  const loadTokens = async () => {
    setTokensLoading(true);
    const { data } = await client.query({
      query: GET_ERC_TOKENS,
      fetchPolicy: 'no-cache',
    });
    if (data?.ercTokenContracts) {
      const tokens = data?.ercTokenContracts;
      if (tokens.length > 0) {
        /**
         * Only offer self-owned ERC721 tokens as dropdown options
         */
        const newOptions = tokens.reduce(
          (accumulator, token) => {
            if (token?.contract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721) {
              if (token?.contract?.organizationId === currOrgId) {
                accumulator.push({ label: token.tokenName, value: token.contract._id });
              }
            }

            return accumulator;
          },
          [{ label: t('traceability.dgp.itemNone'), value: '' }],
        );

        setTokenOptions(newOptions);
        setTokensLoading(false);
        setFieldValue(item._id, item.tokenContract?._id ? item.tokenContract?._id : '');
      } else {
        setTokensLoading(false);
      }
    }
  };

  const deployContract = async variables => {
    if (variables.variables.contractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721) {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const response = await deployERC721TokenContractBCOS(variables);
          setPendingToken(response?.data?.deployErc721Bcos?._id);
          break;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
        case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
          const response = await deployERC721TokenContract(variables);
          setPendingToken(response?.data?.deployErc721Eth?._id);
          break;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
          const response = await deployERC721TokenContractBsn(variables);
          setPendingToken(response?.data?.deployErc721Bsn?._id);
          break;
        }
        default:
          break;
      }
    }
  };

  const handleCreate = () => {
    setDeployFormOpen(true);
  };

  const handleRecreate = async () => {
    if (retryInput) {
      try {
        setCreationState(TOKEN_CREATION_STATES.PROCESSING);
        await deployContract(retryInput);
      } catch (err) {
        handleNotification(
          err?.message || t('traceability.instanceTokens.tokenCreation.failed'),
          'error',
        );
        setCreationState(TOKEN_CREATION_STATES.ERROR);
      }
    }
  };

  const handleFormSubmit = async variables => {
    try {
      setRetryInput(variables);
      await deployContract(variables);
      setDeployFormOpen(false);
      if (activeWallet.blockchainType === 'ETH') {
        setCreationState(TOKEN_CREATION_STATES.PROCESSING);
      }
      await loadTokens();
    } catch (err) {
      handleNotification(
        err?.message || t('traceability.instanceTokens.tokenCreation.failed'),
        'error',
      );
      setDeployFormOpen(false);
      setCreationState(TOKEN_CREATION_STATES.ERROR);
    }
  };

  switch (creationState) {
    case TOKEN_CREATION_STATES.FRESH:
      isCreateDisabled = false;
      isSelectDisabled = false;
      break;
    case TOKEN_CREATION_STATES.SUCCESS:
      isSelectDisabled = false;
      showBanner = true;
      bannerClass = clsx(classes.bannerText, classes.successText);
      banner = t('traceability.instanceTokens.tokenCreation.success');
      bannerIcon = <DoneIcon className={classes.successIcon} />;
      break;
    case TOKEN_CREATION_STATES.ERROR:
      isCreateDisabled = false;
      showBanner = true;
      bannerClass = clsx(classes.bannerText, classes.retryText);
      banner = t('traceability.instanceTokens.tokenCreation.error');
      bannerIcon = <PriorityHighIcon className={classes.retryIcon} />;
      break;
    case TOKEN_CREATION_STATES.PROCESSING:
      showBanner = true;
      banner = t('traceability.instanceTokens.tokenCreation.wait');
      bannerIcon = <CircularProgress size={20} thickness={20} color="primary" />;
      break;
    default:
      break;
  }

  useEffect(() => {
    loadTokens();
  }, []);

  if (!item?._id) {
    return null;
  }

  return (
    <>
      <div className={classes.InstanceTokenBoxRoot}>
        <Typography variant="body1" className={classes.itemBanner}>
          {item?.name}
        </Typography>
        <div className={classes.actionArea}>
          <div className={classes.createArea}>
            {creationState !== TOKEN_CREATION_STATES.ERROR && (
              <Button
                variant="text"
                color="primary"
                onClick={handleCreate}
                className={classes.createButton}
                classes={{
                  root: classes.disabledButton,
                }}
                disabled={isCreateDisabled}
              >
                {t('traceability.instanceTokens.createToken')}
              </Button>
            )}
            {creationState === TOKEN_CREATION_STATES.ERROR && (
              <Button
                variant="text"
                color="primary"
                onClick={handleRecreate}
                className={classes.retryButton}
                classes={{
                  root: classes.disabledButton,
                }}
                disabled={isCreateDisabled}
              >
                {t('traceability.instanceTokens.tokenCreation.retry')}
              </Button>
            )}
            {showBanner && (
              <div className={classes.bannerBox}>
                {bannerIcon}
                <Typography variant="caption" className={bannerClass}>
                  {banner}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.separatorArea}>
            <Typography variant="body1" className={classes.separator}>
              -{t('traceability.instanceTokens.orSeparator')}-
            </Typography>
          </div>

          {tokensLoading && (
            <div className={classes.loadBox}>
              <Typography variant="caption" className={bannerClass}>
                {t('traceability.instanceTokens.loadingTokens')}
              </Typography>
              <CircularProgress size={20} thickness={20} color="primary" />
            </div>
          )}
          {!tokensLoading && (
            <Field
              name={item._id}
              component={Select}
              label={t('traceability.instanceTokens.selectToken')}
              options={tokenOptions}
              disabled={!tokenOptions.length || isSelectDisabled}
              className={classes.selectInput}
              fullWidth={false}
              margin="none"
              error={dirty && !!error}
              helperText={dirty && error ? error : null}
            />
          )}
        </div>
      </div>
      <DeployERCTokenContract
        open={deployFormOpen}
        closeModal={() => setDeployFormOpen(false)}
        loading={
          isDeployERC721TokenContractLoading ||
          isDeployERC721TokenBcosContractLoading ||
          isDeployERC721TokenBsnContractLoading
        }
        isERC20Disabled
        onSubmit={handleFormSubmit}
      />
    </>
  );
};

InstanceTokenBox.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tokenContract: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  error: PropTypes.string,
};

InstanceTokenBox.defaultProps = {
  dirty: false,
  error: '',
};

export default InstanceTokenBox;
