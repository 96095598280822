import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { Card } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import MUITextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { getPrettifiedEthAddr, parseGraphqlError } from '../../../utils';
import { CONSTANTS, PERMISSIONS } from '../../../constants';
import { HIDE_CERTIFICATE, UPDATE_CERT_DESC } from '../../../graphql/Notarization/certificates';

import { useNotification } from '../../../contexts/Shared/notification';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import useUserInfo from '../../../hooks/useUserInfo';
// import { useSubscriptionContext } from '../../../contexts/Shared/subscription';
import track from '../../../mixpanel';

const useStyles = makeStyles(theme => ({
  docDetailsPaper: {
    top: '56px',
    position: 'absolute',
    width: '60vw',
  },
  CertDetailsModalRoot: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  txHashLabel: {
    fontWeight: '100',
    marginRight: theme.spacing(2),
  },
  txArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: theme.palette.grey[100],
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  descriptionLabel: {
    fontWeight: '800',
    marginTop: theme.spacing(2),
  },
  descriptionDetails: {
    fontWeight: '100',
    marginTop: theme.spacing(1),
  },
  successChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
  failedChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  progressChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  listCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
  },
  errorContentDiv: {
    display: 'flex',
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  completeIcon: {
    color: theme.palette.success.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  completeContent: {
    padding: theme.spacing(2),
    color: theme.palette.success.main,
  },
  errorContent: {
    padding: theme.spacing(2),
    color: theme.palette.error.main,
  },
  infoContent: {
    padding: theme.spacing(2),
    color: theme.palette.info.main,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  },
  downloadButton: {
    marginRight: theme.spacing(2),
  },
  descriptionArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  txtBoxField: {
    minWidth: '10%',
    width: '100%',
    margin: '5px',
  },
  contentDetails: {
    display: 'flex',
  },
  contentItem: {
    paddingRight: theme.spacing(2),
  },
  valueText: {
    fontWeight: '400',
  },
  accordionRoot: {
    '& .MuiPaper-root': {
      padding: '0px',
      margin: '0px',
    },
    '& .MuiAccordionSummary-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  documentContainer: {
    display: 'flex',
    border: `1px solid ${theme.palette.primary.lighter}`,
  },
  documentIcon: {
    padding: theme.spacing(2),
  },
  documentName: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  documentsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  documentDetails: {
    display: 'flex',
    margin: '5px 10px',
    flexDirection: 'column',
  },
}));

const CertDetailsModal = ({ notarizedCertificate, resetCertSelection }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const [viewEditMode, setViewEditMode] = useState(false);
  const {
    removeCertificateFromList,
    selectedNotarizationContract,
    updateCertificateList,
  } = useNotarizationContext();
  // const { storageAccess } = useSubscriptionContext();
  const {
    allOrgPermissions,
    permissionsFlags: {
      // isUserAuthToDownloadCertificate,
      isUserAuthToUnlistTransaction,
      isUserAuthToViewTransactionStatus,
    },
  } = useUserInfo();
  const { activeWallet } = useBecOrganizationContext();

  const isDetailsViewer = allOrgPermissions.some(
    permission =>
      permission?.slug === PERMISSIONS.NOTARIZATION.VIEWTRANSACTIONDETAILS &&
      permission?.viewer === 1,
  );

  const formik = useFormik({
    initialValues: {
      description: notarizedCertificate?.description,
    },
  });
  const { values: formValues } = formik;

  const [updateDesc, { loading: isUpdateDescLoading }] = useMutation(UPDATE_CERT_DESC, {
    onCompleted: data => {
      updateCertificateList(data?.updateCertificateDescription);
      resetCertSelection();
      track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.UPDATE_CERTIFICATE_DESCRIPTION, {
        blockchainNetwork: data?.updateCertificateDescription?.blockchainNetwork,
        transactionStatus: data?.updateCertificateDescription?.transaction?.status,
        certificateId: data?.updateCertificateDescription?._id,
        contractId: data?.updateCertificateDescription?.notarizationContract?._id,
      });
    },
    onError: err => {
      const parsedError = parseGraphqlError(err) || err;
      handleNotification(parsedError, 'error');
      track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.UPDATE_CERTIFICATE_DESCRIPTION, {
        blockchainNetwork: activeWallet?.network,
        error: err.message,
      });
    },
  });

  const [hideCertificate, { loading: isDeleting }] = useMutation(HIDE_CERTIFICATE, {
    onCompleted: data => {
      if (data?.hideCertificate) {
        removeCertificateFromList(data?.hideCertificate?.certificateId);
        const successMessage =
          notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
            ? t('notarization.certificateUnlistSuccess')
            : t('notarization.certificateDeleteSuccess');
        handleNotification(successMessage, 'success');
        resetCertSelection();
        track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.UNLIST_CERTIFICATE, {
          blockchainNetwork: data?.hideCertificate?.blockchainNetwork,
          transactionStatus: data?.hideCertificate?.transaction?.status,
          certificateId: data?.hideCertificate?._id,
          contractId: data?.hideCertificate?.notarizationContract?._id,
        });
      }
    },
    onError: () => {
      const errorMessage =
        notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
          ? t('notarization.certificateUnlistFailed')
          : t('notarization.certificateDeleteFailed');
      handleNotification(errorMessage, 'error');
      track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.UNLIST_CERTIFICATE, {
        blockchainNetwork: activeWallet?.network,
        error: errorMessage,
      });
    },
  });

  const valueChangeHandler = event => {
    const newValue = event?.target?.value;
    formik.setFieldValue('description', newValue);
  };

  const contentDetailsView = () => {
    const contentDetails = JSON.parse(notarizedCertificate?.content);
    if (notarizedCertificate?.recipient) {
      contentDetails.splice(0, 0, {
        key: t('notarization.recipient'),
        value: notarizedCertificate?.recipient,
      });
    }
    return (
      <div className={classes.accordionRoot}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.descriptionLabel}>
              {t('notarization.certificateContent')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {contentDetails.map(contactDetail => (
                <div className={classes.contentDetails} key={uniqid()}>
                  <div className={classes.contentItem}>
                    <Typography> {contactDetail.key} </Typography>
                  </div>
                  <div>
                    <Typography className={classes.valueText}>{contactDetail.value}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const documentDetails = () => {
    return (
      <div className={classes.accordionRoot}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.descriptionLabel}>
              {t('notarization.documents')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.documentsRoot}>
              {notarizedCertificate?.documents?.map(document => (
                <div className={classes.documentDetails} key={uniqid()}>
                  <div className={classes.documentContainer}>
                    <div className={classes.documentIcon}>
                      <DescriptionOutlinedIcon variant="outlined" />
                    </div>
                    <div className={classes.documentName}>
                      <Typography> {document.fileName} </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const descriptionDetails = () => {
    const description = notarizedCertificate?.description;
    return (
      <div>
        <div className={classes.descriptionArea}>
          <Typography className={classes.descriptionLabel}>
            {t('notarization.description')}
          </Typography>
          {notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED &&
            !isDetailsViewer && (
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  setViewEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
        <div>
          {!viewEditMode && (
            <Typography className={classes.descriptionDetails}>{description}</Typography>
          )}
          {viewEditMode &&
            notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED && (
              <MUITextField
                name="description"
                label={t('tokens.writeHere')}
                size="small"
                variant="outlined"
                multiline
                rows={3}
                className={classes.txtBoxField}
                value={formValues.description}
                onChange={valueChangeHandler}
              />
            )}
        </div>
      </div>
    );
  };

  const okButtonClickEvent = () => {
    if (viewEditMode) {
      updateDesc({
        variables: {
          input: {
            certificateId: notarizedCertificate?.certificateId,
            contractId: selectedNotarizationContract?._id,
            description: formValues?.description,
          },
        },
      });
    } else {
      resetCertSelection();
    }
  };

  const hideCertHandler = async () => {
    await hideCertificate({
      variables: {
        certificateId: notarizedCertificate.certificateId,
        contractId: selectedNotarizationContract._id,
      },
    });
  };

  const statusChip = () => {
    switch (notarizedCertificate?.transaction?.status) {
      case CONSTANTS.CONTRACT_STATUSES.FAILED:
        return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
      case CONSTANTS.CONTRACT_STATUSES.PENDING:
        return (
          <Chip label={t('notarization.statusCodes.pending')} className={classes.progressChip} />
        );
      case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
        return (
          <Chip label={t('notarization.statusCodes.completed')} className={classes.successChip} />
        );
      default:
        return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
    }
  };

  const statusDescription = () => {
    switch (notarizedCertificate?.transaction?.status) {
      case CONSTANTS.CONTRACT_STATUSES.FAILED:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography className={classes.errorContent}>
              {t('notarization.docErrorMessage')}
            </Typography>
          </Card>
        );
      case CONSTANTS.CONTRACT_STATUSES.PENDING:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <InfoIcon className={classes.infoIcon} />
            <Typography className={classes.infoContent}>
              {t('notarization.docInprogressMessage')}
            </Typography>
          </Card>
        );
      case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <CheckCircleIcon className={classes.completeIcon} />
            <Typography className={classes.completeContent}>
              {t('notarization.certCompleteMessage')}
            </Typography>
          </Card>
        );
      default:
        return <div />;
    }
  };

  const buttonArea = () => {
    return (
      <div>
        {notarizedCertificate?.transaction?.status !== CONSTANTS.CONTRACT_STATUSES.PENDING &&
          isUserAuthToUnlistTransaction && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={hideCertHandler}
              disabled={isUpdateDescLoading || isDeleting}
            >
              {notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
                ? t('notarization.docButtons.unlist')
                : t('notarization.docButtons.delete')}
            </Button>
          )}
        {/* {notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED &&
          storageAccess &&
          isUserAuthToDownloadCertificate && (
            <Button variant="contained" color="primary" className={classes.downloadButton} disabled>
              {t('notarization.docButtons.download')}
            </Button>
          )} */}
        <Button
          variant="contained"
          color="secondary"
          onClick={okButtonClickEvent}
          disabled={isUpdateDescLoading || isDeleting}
        >
          {notarizedCertificate?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.PENDING
            ? t('notarization.docButtons.ok')
            : t('notarization.docButtons.continue')}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.CertDetailsModalRoot}
      open
      onClose={resetCertSelection}
    >
      <Paper className={classes.docDetailsPaper}>
        <Typography variant="h4" className={classes.title}>
          {notarizedCertificate?.certificateName}
        </Typography>
        <div className={classes.txArea}>
          <Typography variant="body2" className={classes.txHashLabel}>
            {t('notarization.txHashLabel')}
          </Typography>
          <Typography variant="body2">
            {getPrettifiedEthAddr(notarizedCertificate.transaction?.transactionHash)}
          </Typography>
          <CopyContent content={notarizedCertificate.transaction?.transactionHash} />
        </div>
        {contentDetailsView()}
        {descriptionDetails()}
        {notarizedCertificate?.documents?.length > 0 && documentDetails()}
        {isUserAuthToViewTransactionStatus &&
          !isDetailsViewer &&
          statusChip() &&
          statusDescription()}
        <Divider className={classes.divider} />
        {buttonArea()}
        <IconButton aria-label="close" className={classes.closeButton} onClick={resetCertSelection}>
          <CloseIcon />
        </IconButton>
      </Paper>
    </Modal>
  );
};

CertDetailsModal.propTypes = {
  notarizedCertificate: PropTypes.shape({
    certificateName: PropTypes.string.isRequired,
    description: PropTypes.string,
    certificateId: PropTypes.string,
    content: PropTypes.string,
    recipient: PropTypes.string,
    documents: PropTypes.array,
    _id: PropTypes.string,
    transaction: PropTypes.shape({
      transactionHash: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
  }),
  resetCertSelection: PropTypes.func.isRequired,
};

CertDetailsModal.defaultProps = {
  notarizedCertificate: null,
};

export default CertDetailsModal;
