import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { ORG_TYPES } from '../../constants';

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2.5),
    border: `solid 1px ${theme.palette.primary.lightest}`,
    boxShadow: '2px 2px 11px 4px rgba(0,0,0,0.07)',
    maxWidth: '300px',
  },
  icon: {
    height: '120px',
    width: '120px',
  },
  divider: {
    backgroundColor: '#E7E7EA',
    margin: `${theme.spacing(2)}px 0px`,
  },
  link: {
    color: theme.palette.info.main,
    fontSize: '1em',
    whiteSpace: 'no-wrap',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  description: {
    fontSize: '0.9em',
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'justify',
    minHeight: '76px',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 400,
    fontSize: '1.1em',
  },
  requestAccessButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
  },
  hiddenLink: {
    color: theme.palette.background.paper,
    fontSize: '1em',
    whiteSpace: 'no-wrap',
    fontWeight: '400',
  },
}));

const DashboardCard = ({ product, handleButtonClick, handleLinkClick, orgType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { NAME: productName, PAGE: productPage, isPackageOwner } = product;

  const goToProductPage = () => {
    window.open(`${window.config.homeUrl}/${productPage}`, '_blank');
  };

  let linkText = '';
  let buttonText = '';

  // temporarily hides link to help center. To be removed.
  let flagViewCenter = false;

  if (orgType === ORG_TYPES.ENTERPRISE) {
    linkText = isPackageOwner ? t('dashboard.visitHelpCenter') : t('dashboard.tryTestnet');
    buttonText = isPackageOwner ? t('dashboard.start') : t('dashboard.requestAccess');
    flagViewCenter = isPackageOwner;
  } else {
    linkText = t('dashboard.requestAccessEnterprise');
    buttonText = isPackageOwner ? t('dashboard.start') : t('dashboard.try');
  }

  return (
    <Box className={classes.box}>
      <img src={`/static/${productName}.svg`} alt={t(`dashboard.imageAlt.${productName}`)} />
      <Typography variant="h5" align="center" className={classes.title}>
        {t(`dashboard.products.${productName}`)}
      </Typography>
      <Typography align="center" className={classes.description}>
        {t(`dashboard.descriptions.${productName}`)}
      </Typography>

      <Typography
        variant="body1"
        className={classes.link}
        onClick={goToProductPage}
        data-testid={`dashboard-card-viewProductPage-${productName}`}
      >
        {t('dashboard.viewProductPage')}
      </Typography>

      {orgType === ORG_TYPES.ENTERPRISE && !isPackageOwner ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          className={classes.requestAccessButton}
          data-testid={`dashboard-card-button-${productName}`}
        >
          {buttonText}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleButtonClick}
          className={classes.button}
          data-testid={`dashboard-card-button-${productName}`}
        >
          {buttonText}
        </Button>
      )}

      <Divider className={classes.divider} />

      <Typography
        variant="body1"
        className={flagViewCenter ? classes.hiddenLink : classes.link}
        onClick={flagViewCenter ? () => {} : handleLinkClick}
        data-testid={`dashboard-card-link-${productName}`}
      >
        {linkText}
      </Typography>
    </Box>
  );
};

DashboardCard.propTypes = {
  product: PropTypes.shape({
    NAME: PropTypes.string.isRequired,
    PAGE: PropTypes.string.isRequired,
    isPackageOwner: PropTypes.bool.isRequired,
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  orgType: PropTypes.string,
};

DashboardCard.defaultProps = {
  orgType: ORG_TYPES.USER,
};

export default DashboardCard;
