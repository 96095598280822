import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { ethers } from 'ethers';
import { SHA256 } from 'crypto-js';
import axios from 'axios';
import { isEmpty, clone, find, reduce } from 'lodash';
import {
  ERROR_CODES,
  METADATA_STRUCTURE_RELATIONSHIP,
  TOKEN_METADATA_FIELDS,
  CONSTANTS,
} from './constants';

/**
 * Format a number to currency
 * @param {number} number
 * @param {string} currency - The currency code according to ISO 4217
 */
export const formatCurrency = (number, currency = 'USD') =>
  number &&
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  }).format(number);

/**
 * Formats the tierBounds and Prices by tier to a flattened array of objects
 * @param {array} proposals array of proposal objects
 */
export const formatRates = proposals => {
  const rates = proposals.flatMap(proposal => {
    const tiers = [];
    for (let index = 1; index < proposal.tierRules?.tierBounds?.length; index += 1) {
      tiers.push({
        startRange: index === 1 ? 1 : proposal.tierRules.tierBounds[index - 1] + 1,
        endRange: proposal.tierRules.tierBounds[index],
        price: proposal.tierRules.pricesByTier[index - 1],
      });
    }
    return tiers;
  });
  return rates;
};

export const parseGraphqlError = error => error.message.split('GraphQL error: ')[1];

/**
Toal Price in a purchase order is calculated based on the previous accumulatedVolumeOrdered in the contract and the new volume in the purchase order.
*/
export const calculateTotalPrice = (tierBounds, pricesByTier, accumulatedVolumeOrdered, volume) => {
  const newVolume = accumulatedVolumeOrdered + volume;

  const numberOfTiers = tierBounds.length - 1;
  const lowerBoundForTier = new Array(numberOfTiers).fill(0);
  const upperBoundForTier = new Array(numberOfTiers).fill(0);

  let totalPrice = 0;
  for (let i = 0; i < numberOfTiers; i += 1) {
    // Create the upper and lower bounds for each tier:
    lowerBoundForTier[i] = Math.min(
      Math.max(tierBounds[i], accumulatedVolumeOrdered),
      tierBounds[i + 1],
    );
    upperBoundForTier[i] = Math.max(Math.min(tierBounds[i + 1], newVolume), tierBounds[i]);

    // increment the totalPrice by the total price of each tier:
    totalPrice += pricesByTier[i] * (upperBoundForTier[i] - lowerBoundForTier[i]);
  }
  // increment the totalPrice for units beyond the last tier at the last price
  if (newVolume > tierBounds[numberOfTiers]) {
    const lowerBound = Math.max(tierBounds[numberOfTiers], accumulatedVolumeOrdered);
    totalPrice += pricesByTier[pricesByTier.length - 1] * (newVolume - lowerBound);
  }

  return totalPrice;
};

export const createHashFromFile = async file => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = event => {
        const { target } = event;
        const { result: plainData } = target;

        const generatedHash = SHA256(plainData);
        resolve(generatedHash);
      };

      reader.readAsBinaryString(file);
    } catch (err) {
      reject(err);
    }
  });
};

export const contract = async (contractAddress, contractJSON) => {
  if (typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined') {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const contractInstance = new ethers.Contract(contractAddress, contractJSON, signer);

    return { contractInstance };
  }

  throw new Error('Invalid connection parameters');
};

export const getMetamaskInfo = async () => {
  if (typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined') {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const network = window.ethereum.networkVersion;
    const signer = provider.getSigner(0);
    const address = await signer.getAddress();

    return { address, network };
  }

  throw new Error('Invalid connection parameters');
};

export const getBlockchainExplorerUrl = network => {
  switch (network) {
    case CONSTANTS.BLOCKCHAIN_NETWORKS.ROPSTEN: {
      return 'https://ropsten.etherscan.io/tx';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.ECDSA: {
      return `${window.location.origin}/certificate`;
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.MAINNET: {
      return 'https://etherscan.io/tx';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_MAINNET: {
      return 'https://polygonscan.com/tx';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_TESTNET: {
      return 'https://mumbai.polygonscan.com/tx';
    }
    default: {
      // other pages not yet modified
      return 'https://ropsten.etherscan.io/tx';
    }
  }
};

export const getBlockchainExplorerAddressUrl = network => {
  switch (network) {
    case CONSTANTS.BLOCKCHAIN_NETWORKS.ROPSTEN: {
      return 'https://ropsten.etherscan.io/address';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.ECDSA: {
      return `${window.location.origin}/certificate`;
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.MAINNET: {
      return 'https://etherscan.io/tx';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_TESTNET: {
      return 'https://mumbai.polygonscan.com/address';
    }
    case CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_MAINNET: {
      return 'https://polygonscan.com/address';
    }
    default: {
      // other pages not yet modified
      return 'https://ropsten.etherscan.io/address';
    }
  }
};

export const isValidAddress = address => {
  try {
    ethers.utils.getAddress(address);
  } catch (err) {
    return false;
  }
  return true;
};

export const isValidContent = content => {
  try {
    const parsedContent = JSON.parse(content);
    if (isEmpty(parsedContent)) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
};

export const sign = async (
  authorization,
  organizationId,
  walletAddress,
  rawMessage,
  blockchainType,
) => {
  let url;
  if (blockchainType === 'BCOS' || blockchainType === 'BSN') {
    url = `${window.platformConfig.becUrl}/api/organizations/${organizationId}/wallets/${walletAddress}/signMessage`;
  } else {
    url = `${window.platformConfig.authApiUrl}/organizations/${organizationId}/wallets/${walletAddress}/signMessage`;
  }
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const data = { rawMessage, blockchainType };

  try {
    const response = await axios.post(url, data, config);
    return response.data.signedMessage;
  } catch (err) {
    throw new Error(err.message);
  }
};

/*
 *  Returns a new string with the content converted from camelCase, PascalCase or snake_case to Title Case
 *  Redundant whitespace is removed
 */
export const getTitleCase = text => {
  let cleanText = clone(text);

  cleanText = cleanText.replace(/([a-z])([A-Z])/g, '$1 $2');
  cleanText = cleanText.replace(/_/g, ' ');

  cleanText = cleanText.replace(/\s([a-z])/g, match => match.toUpperCase());
  cleanText = cleanText.replace(/^([a-z])/g, match => match.toUpperCase());

  cleanText = cleanText.replace(/(\s)+/g, ' ');
  cleanText = cleanText.trim();

  return cleanText;
};

//  Async delay function
export const delay = t => new Promise(resolve => setTimeout(resolve, t));

export const orderListing = data => {
  const sortedArray = data.sort((x, y) => {
    return Number(y.createdAt) - Number(x.createdAt);
  });
  return sortedArray;
};

export const getIdTokenInfo = () => {
  try {
    const idtoken = Cookies.get(`${window.platformConfig.cookiePrefix}id_token`);
    const tokenInfo = jwtDecode(idtoken);
    return tokenInfo;
  } catch (err) {
    return null;
  }
};

export const getActiveOrganizationId = () =>
  Buffer.from(Cookies.get(`${window.config.cookiePrefix}active_organization`), 'base64').toString();

export const getUserId = () => {
  const tokenInfo = getIdTokenInfo();
  return tokenInfo.sub.replace('auth0|', '');
};

export const publicRootPaths = ['/certificate'];

export const isValidGTIN13 = inpCode => {
  const inpText = inpCode?.toString();

  return !!inpText?.match(/^[0-9]{13}$/);
};

export const getPrettifiedEthAddr = inpAddr => {
  if (!inpAddr || inpAddr?.length < 4) {
    return '';
  }

  const prefix = inpAddr.slice(0, 6);
  const suffix = inpAddr.slice(inpAddr.length - 4);
  return `${prefix}...${suffix}`;
};

/**
 * GeoLocation lookup is disabled for non-secure contexts: https://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
 * For local development, temporarily enabling the flag and adding http://opschain.blockchain.ey.docker/ in the exception list might serve as a workaround: chrome://flags/#unsafely-treat-insecure-origin-as-secure
 */
export const checkGeoLocationSupported = () => {
  return !!navigator?.geolocation && !!window?.isSecureContext;
};

export const getCurrGeoLocation = options => {
  return new Promise((resolve, reject) => {
    if (!checkGeoLocationSupported()) {
      reject(new Error(ERROR_CODES.GEOLOCATION.UNAVAILABLE));
    } else {
      navigator.geolocation.getCurrentPosition(
        pos => {
          if (!pos?.coords?.latitude || !pos?.coords?.longitude) {
            reject(new Error(ERROR_CODES.GEOLOCATION.POS_UNDETERMINED));
          }
          resolve({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        },
        err => reject(err),
        options,
      );
    }
  });
};

export const validateLatitude = lat => {
  try {
    let latTrimmed;
    if (typeof lat === 'string') {
      latTrimmed = lat?.trim();
      if (latTrimmed === '') return false;
    }
    const numValue = Number(latTrimmed || lat);
    return Number.isFinite(numValue) && Math.abs(numValue) <= 90;
  } catch (e) {
    return false;
  }
};

export const validateLongitude = lng => {
  try {
    let lngTrimmed;
    if (typeof lng === 'string') {
      lngTrimmed = lng?.trim();
      if (lngTrimmed === '') return false;
    }
    const numValue = Number(lngTrimmed || lng);
    return Number.isFinite(numValue) && Math.abs(numValue) <= 180;
  } catch (e) {
    return false;
  }
};

export const convertToJsonStr = arrayToConvert => {
  let retObj = {};

  arrayToConvert.forEach(keyValuePair => {
    const { key, value } = keyValuePair;

    if (value && !key) {
      // throw new Error(t('common.errors.errorMissingKey'));
    }

    if (key in retObj) {
      // throw new Error(t('common.errors.errorDuplicateKey'));
    }

    if (key && key !== '') {
      if (value === '' || !value) {
        //   throw new Error(t('common.errors.errorMissingValue'));
      }

      retObj[key] = value;
    }
  });

  if (Object.keys(retObj).length === 0) {
    retObj = {
      '': '',
    };
  }

  return JSON.stringify(retObj, undefined, 2);
};

export const convertToArray = strToConvert => {
  const jsonToConvert = JSON.parse(strToConvert);
  const retArray = [];

  Object.keys(jsonToConvert).forEach(keyValuePair => {
    retArray.push({
      key: keyValuePair,
      value: jsonToConvert[keyValuePair],
    });
  });

  return retArray;
};

export const isMeasurementDataType = (dataTypeId, metaDataTypes) => {
  let isMeasurementType = false;
  isMeasurementType =
    find(metaDataTypes, metadataType => {
      return metadataType.value === dataTypeId;
    })?.key === 'tokens.metaDataTypes.measurement';
  return isMeasurementType;
};

export const isLocationDataType = (dataTypeId, metaDataTypes) => {
  return find(metaDataTypes, ['value', dataTypeId])?.key === TOKEN_METADATA_FIELDS.LOCATION;
};

export const getMetadataTypeName = (metadataId, metaDataTypes) => {
  const metadataTypeName = find(metaDataTypes, metadataType => {
    return metadataType.value === metadataId;
  })?.label;
  return metadataTypeName;
};

export const convertLinksToFields = structuredData => {
  const result = [];
  if (structuredData.parent?.length > 0) {
    structuredData.parent.forEach(parent => {
      const structuredField = {
        smartContract: parent.smartContract,
        tokenId: parent.tokenId,
        description: parent.description,
        relationship: METADATA_STRUCTURE_RELATIONSHIP.PARENT,
        relationshipType: parent.relationshipType,
      };
      result.push(structuredField);
    });
  }
  if (structuredData.children?.length > 0) {
    structuredData.children.forEach(child => {
      const structuredField = {
        smartContract: child.smartContract,
        tokenId: child.tokenId,
        description: child.description,
        relationship: METADATA_STRUCTURE_RELATIONSHIP.CHILD,
        relationshipType: child.relationshipType,
      };
      result.push(structuredField);
    });
  }
  return result;
};

export const isValidateBlockchainAddress = address => {
  let validAddress = false;
  if (address) {
    if (/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      validAddress = true;
    }
  }
  return validAddress;
};

export const generateRoleOptions = rolesList => {
  if (!rolesList) {
    return [];
  }

  return rolesList.map(role => {
    return {
      value: role._id,
      label: role.name,
    };
  });
};

export const getStepDetails = instanceDetails => {
  const stepDetails = [];
  if (instanceDetails?.items?.length > 0) {
    instanceDetails.items.forEach(item => {
      if (item?.step?.length > 0) {
        item.step.forEach(stp => {
          stepDetails.push(stp);
        });
      }
    });
  }
  return stepDetails;
};

export const getStepDataDetails = instanceDetails => {
  const stepData = [];
  if (instanceDetails?.steps?.length > 0) {
    instanceDetails.steps.forEach(step => {
      if (step?.stepData.length > 0) {
        const stepDataDetails = step.stepData;
        stepDataDetails.forEach(details => {
          const dataDetails = details;
          if (dataDetails?._id) {
            dataDetails.step = { _id: step._id };
            stepData.push(dataDetails);
          }
        });
      }
    });
  }
  return stepData;
};

export const getAggregatedDataForValueChainWizard = instanceDetails => {
  const aggregatedDataObj = {};
  instanceDetails.content.items.forEach(itemData => {
    aggregatedDataObj[itemData._id] = {
      ...itemData,
      steps: [],
    };
  });

  instanceDetails.content.steps.forEach(stepData => {
    const involvedItemId = stepData?.involvedItem?._id;
    const involvedItemData = aggregatedDataObj[involvedItemId];
    const stepList = involvedItemData.steps;

    aggregatedDataObj[involvedItemId] = {
      ...involvedItemData,
      steps: stepList.concat(stepData),
    };
  });

  return Object.values(aggregatedDataObj);
};

export const getCurrencySymbol = euroCurrency => {
  return euroCurrency ? '€' : '$';
};

/**
 * Format a number to Euro Currency
 * @param {number} number
 * @param {string} currency - The currency code according to ISO 4217
 */
export const formatEuroCurrency = (number, currency = 'EUR') =>
  number &&
  new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  }).format(number);

export const formatCurrencySymbol = (value, euroCurrency) => {
  return euroCurrency ? formatEuroCurrency(value) : formatCurrency(value);
};

export const getCurrencyName = euroCurrency => {
  return euroCurrency ? `EUR ` : `US `;
};

export const getWalletAddress = (wallets, activeWallet) => {
  if (activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PRIVATE) {
    return wallets.find(wallet => activeWallet.networkId === wallet.networkId).address;
  }
  return wallets.find(wallet => activeWallet.network === wallet.network).address;
};

export const parsePermissionsForRole = (apiResponse, roleId) => {
  const roleEntry = find(apiResponse.roles_list, { _id: roleId });
  const roleName = roleEntry?.name?.toLowerCase();
  const matchingPermissions = reduce(
    apiResponse.permissions_list,
    (result, value) => {
      if (value[roleName] === 1) {
        result.push(value.slug);
      }
      return result;
    },
    [],
  );
  return matchingPermissions;
};

export const getUpdateCollaboratorsMixpanelEvent = (history, error = false) => {
  const location = (history?.location?.pathname?.split('/'))[1];
  const MIXPANEL_EVENT = error ? CONSTANTS.MIXPANEL_ERRORS : CONSTANTS.MIXPANEL_EVENTS;
  switch (location) {
    case 'notarizations':
      return MIXPANEL_EVENT.NOTARIZATION.UPDATE_COLLABORATORS;
    case 'tokens':
      return MIXPANEL_EVENT.TOKENIZATION.UPDATE_COLLABORATORS;
    default:
      return CONSTANTS.MIXPANEL_ERRORS.UNKNOWN_EVENT;
  }
};
