import React, { useEffect, useState } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { makeStyles } from '@material-ui/styles';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import DeleteIcon from '@material-ui/icons/Delete';
import TableSearchBar from '../../components/Shared/TableSearchBar';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import TableActions from '../../components/Shared/TableActions';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';
import { useOrganizationContext } from '../../contexts/Procurement/organization';
import AddOrganization from '../../components/Procurement/AddOrganization';
import DeleteOrganization from '../../components/Procurement/DeleteOrganization';
import { usePartnerContext } from '../../contexts/Procurement/partner';

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
  },
  loaderContainer: theme.loaderContainer,
}));

const Organization = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { activeOrganization } = useBecOrganizationContext();
  const {
    organizations,
    loadingAddOrganization,
    addOrganization,
    removeOrganization,
    getOrganizations,
    getOrganizationsLoading,
  } = useOrganizationContext();

  const { getPartners, partners, getPartnersLoading } = usePartnerContext();

  const { userPermissions } = useUserInfo();
  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;

  const [open, setOpen] = useState(false);
  const [isDeleteOrgOpen, setIsDeleteOrgOpen] = useState(false);
  const [childOrg, setChildOrg] = useState({});

  useEffect(() => {
    getOrganizations();
    getPartners();
  }, []);

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(
        PERMISSIONS.CONTRACT_MANAGER.GETPURCHASEORDERS,
      ) &&
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATEPURCHASEORDER)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  const partnersData = partners?.map(partner => {
    return {
      value: partner.partnerOrganization._id,
      label: partner.partnerOrganization.name,
      partnerOrganization: partner.partnerOrganization,
    };
  });

  const getOrganizationType = type => {
    if (type === 'subsidiary') {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
    if (type === 'authorized_user') {
      return 'Authorized Organization';
    }
    return '';
  };

  const organizationList = organizations.map(org => {
    return {
      organization: org.childOrganization.name,
      type: getOrganizationType(org.type),
      walletAddress: org.childOrganization.wallets[0]?.address,
      orgId: org._id,
      parentId: org.parentOrganizationId,
      childId: org.childOrganization._id,
      name: org.childOrganization.name,
    };
  });

  const columns = [
    {
      name: 'organization',
      label: t('organization.Organization'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return value;
        },
      },
    },
    {
      name: 'type',
      label: t('organization.organizationType'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return value;
        },
      },
    },
    {
      name: 'walletAddress',
      label: t('organization.organizationWalletAddress'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return value;
        },
      },
    },
    {
      name: 'orgId',
      label: t('common.remove'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: selectedOrgId => {
          return (
            <TableActions
              testId={`remove-organization-icon-${selectedOrgId}`}
              options={[
                {
                  label: t('common.remove'),
                  buttonText: '',
                  icon: <DeleteIcon />,
                  handleClick: () => {
                    const selectedOrg = organizationList.find(x => x.orgId === selectedOrgId);
                    if (selectedOrg) {
                      setChildOrg({
                        name: selectedOrg.name,
                        childId: selectedOrg.childId,
                        parentId: selectedOrg.parentId,
                      });
                      setIsDeleteOrgOpen(true);
                    }
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  const onOrgPartnerHandler = async values => {
    await addOrganization({
      variables: {
        input: {
          walletAddress: values?.orgWalletAddress.trim(),
          orgType: values?.organizationType.value,
          partnersHeaderId: values?.partnersHeaderId,
        },
      },
    });
    setOpen(false);
  };

  const removeOrgHandler = async org => {
    await removeOrganization({
      variables: {
        orgId: org.childId,
      },
    });
    setIsDeleteOrgOpen(false);
  };

  return (
    <>
      {!isUserNotAuthorized() && (getOrganizationsLoading || getPartnersLoading) ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {!isUserNotAuthorized() && (
            <>
              <div className="purchase-orders">
                <Table
                  title={t('pos.purchaseOrders')}
                  className={classes.table}
                  columns={columns}
                  data={organizationList}
                  options={{
                    pagination: true,
                    filter: false,
                    search: false,
                    searchOpen: true,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
                      <TableSearchBar
                        searchText={searchText}
                        handleSearch={handleSearch}
                        hideSearch={hideSearch}
                        options={options}
                        placeHolderText={t('organization.searchOrganization')}
                        createButtonText={t('organization.addOrganization')}
                        handleOpenForm={() => {
                          setOpen(true);
                        }}
                        testId="addOrganization"
                      />
                    ),
                  }}
                />
              </div>
              <AddOrganization
                loading={loadingAddOrganization}
                open={open}
                partners={partnersData}
                onOrgPartnerHandler={onOrgPartnerHandler}
                closeModal={() => {
                  setOpen(false);
                }}
              />
              {isDeleteOrgOpen && (
                <DeleteOrganization
                  isDeleteOrgOpen={isDeleteOrgOpen}
                  childOrg={childOrg}
                  removeOrgHandler={removeOrgHandler}
                  closeModal={() => {
                    setIsDeleteOrgOpen(false);
                  }}
                />
              )}
            </>
          )}
          {isUserNotAuthorized()}
        </>
      )}
    </>
  );
};

export default Organization;
