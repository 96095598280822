import React, { useState, useEffect } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { makeStyles } from '@material-ui/styles';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import AddPartner from '../../components/Procurement/AddPartner';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import TableSearchBar from '../../components/Shared/TableSearchBar';
import { useAuth } from '../../contexts/Shared/auth';
import track from '../../mixpanel';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import { PROTabs, PROTab } from '../../components/Procurement/PROTabs';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      boxShadow: '0 -1.75px 0 0 rgb(0 0 0 / 4%)',
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTabs-root': {
      marginBottom: '20px',
      borderBottom: '0px',
      boxShadow: '0px',
    },
    '& .MuiTab-root': {
      minWidth: '0px',
    },
  },
  loaderContainer: theme.loaderContainer,
  partnerHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  partnerListRoot: {
    background: theme.palette.primary.lightest,
    padding: '16px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
}));

const Partners = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { company } = useAuth();
  const [activeTab, setActiveTab] = useState('partners');
  const [filteredBusinessInformation, setFilteredBusinessInformation] = useState([]);
  const history = useHistory();

  const { businessEcosystemsAccess } = useSubscriptionContext();

  const {
    getPartners,
    partners: partnersData,
    getPartnersLoading: loadingFetchPartners,
    addPartner,
    loadingAddPartner,
  } = usePartnerContext();

  useEffect(() => {
    if (!partnersData.length > 0) getPartners();
  }, [company]);

  const partners = partnersData.map(partner => {
    return {
      businessPartner: partner.partnerCompanyCode || partner.partnerOrganization.name,
      status: partner.hasActiveContract,
    };
  });
  const handleChangeTab = (_, newValue) => {
    setActiveTab(newValue);
  };

  const closeModal = () => {
    history.goBack();
  };

  useEffect(() => {
    if (activeTab === 'partners' && partnersData.length > 0) {
      setFilteredBusinessInformation(partners);
    } else if (activeTab === 'businessConsortia' && businessEcosystemsAccess) {
      /**
       * pending : get the businessConsortia records from API and set FilteredBusinessInformation
       */
      setFilteredBusinessInformation([]);
    }
  }, [company, activeTab]);

  /**
   * *  Modified the  businessConsortia column Methods
   *
   */

  const businessConsortiaColumn = [
    {
      name: 'consortiaName',
      label: t('businessConsortia.tableHeaders.consortiaName'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'participants',
      label: t('businessConsortia.tableHeaders.participants'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: "my-Org's",
      label: t("businessConsortia.tableHeaders.my-Org's"),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'actions',
      label: t('businessConsortia.tableHeaders.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];
  /**
   * *  Modified the  businessPartnersColumns
   *
   */
  const businessPartnersColumns = [
    {
      name: 'businessPartner',
      label: t('common.businessPartner'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: t('partners.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return value ? (
            <Chip type="success" label={t('common.active')} />
          ) : (
            <Chip type="warning" label={t('common.inactive')} />
          );
        },
      },
    },
  ];

  const onAddPartnerHandler = async values => {
    await addPartner({
      variables: {
        input: { ...values, partnerWalletAddress: values?.partnerWalletAddress.trim() },
      },
    });
    setOpen(false);
  };

  if (loadingFetchPartners)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  return (
    <div className="partners">
      <>
        <Button
          variant="text"
          color="primary"
          onClick={() => closeModal()}
          className={classes.navButton}
          startIcon={<ArrowBackIcon />}
        >
          {t('common.back')}
        </Button>
        <div className={classes.partnerListRoot}>
          <div className={classes.partnerHeaderArea}>
            <Typography variant="h3">{t('common.businessEcosystem')}</Typography>
          </div>
        </div>
        <Table
          title={t('headers.partner_plural')}
          className={classes.table}
          columns={activeTab === 'partners' ? businessPartnersColumns : businessConsortiaColumn}
          data={activeTab === 'partners' ? partners : filteredBusinessInformation || []}
          options={{
            pagination: true,
            filter: false,
            search: false,
            searchOpen: true,
            elevation: 0,
            textLabels: {
              body: {
                noMatch: t('common.noMatchingRecords'),
              },
              pagination: {
                rowsPerPage: t('common.rowsPerPage'),
              },
            },
            customSearchRender: (searchText, handleSearch, hideSearch, options) => (
              <>
                <PROTabs value={activeTab} onChange={handleChangeTab}>
                  <PROTab label={t('common.businessPartner')} value="partners" />
                  {businessEcosystemsAccess && (
                    <PROTab
                      label={t('common.businessConsortia')}
                      value="businessConsortia"
                      testId="addBusinessConsortia"
                    />
                  )}
                </PROTabs>
                <TableSearchBar
                  searchText={searchText}
                  handleSearch={handleSearch}
                  hideSearch={hideSearch}
                  options={options}
                  placeHolderText={
                    activeTab === 'partners'
                      ? t('partners.searchPartners')
                      : t('businessConsortia.searchBusinessConsortia')
                  }
                  createButtonText={
                    activeTab === 'partners'
                      ? t('partners.addPartner')
                      : t('businessConsortia.addBusinessConsortia')
                  }
                  handleOpenForm={() => {
                    if (activeTab === 'partners') {
                      setOpen(true);
                      track('Open Add Partner Modal');
                    }
                    /**
                     * pending : redirect to create business consortia page
                     */
                  }}
                  testId="addPartner"
                />
              </>
            ),
          }}
        />
        <div className={classes.tableArea}>
          <AddPartner
            loading={loadingAddPartner}
            open={open}
            onAddPartnerHandler={onAddPartnerHandler}
            closeModal={() => {
              setOpen(false);
              track('Cancel Add Partner');
            }}
          />
        </div>
      </>
    </div>
  );
};

export default Partners;
