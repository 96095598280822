import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Select from '@eyblockchain/ey-ui/core/Select';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import { Box, Card, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { isEmpty, keys, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import CloseIcon from '@material-ui/icons/Close';
import uniqid from 'uniqid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import {
  CONSTANTS,
  METADATA_BEHAVIORS,
  METADATA_STRUCTURE_RELATIONSHIP,
  METADATA_STRUCTURE_RELATIONSHIP_TYPE,
} from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import { useIngestionContext } from '../../../contexts/Traceability/ingestion';
import { GET_SINGLE_ERC721_TOKEN } from '../../../graphql/Tokenization/token';
import {
  CREATE_ITEM_CONTENT,
  UPDATE_ITEM_CONTENT,
} from '../../../graphql/Traceability/itemContent';
import useUserInfo from '../../../hooks/useUserInfo';
import {
  generateDefaultMetadataValues,
  prepareMetadataReqObj,
  validateMetadata,
} from '../../../tokenMetadataUtils';
import MultiSelectDropDown from '../../Shared/MultiSelectDropDown';
import TokenMetaData from '../../Shared/TokenMetaData';

const useStyles = makeStyles(theme => ({
  stepMetadataPanelRoot: {
    width: '60%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(76),
  },
  tokenWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gridGap: '2%',
  },
  dependentItemField: {
    marginTop: theme.spacing(2),
  },
  subHeaderName: {
    fontWeight: 400,
  },
  fieldDivider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  subPanelTitle: {
    marginBottom: theme.spacing(2),
  },
  buttonArea: {
    display: 'flex',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  regButton: {
    marginLeft: theme.spacing(3),
  },
  loaderContainer: theme.loaderContainer,
  loader: {
    marginLeft: '2em',
  },
  loaderBox: {
    marginLeft: '3em',
    display: 'flex',
    alignItems: 'center',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '20%',
  },
  parentOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  parentOption: {
    margin: 0,
  },
  muiAutocompleteOption: {
    display: 'grid',
    gridTemplateColumns: '10% 90%',
  },
  parentTokenTable: {
    marginTop: theme.spacing(1),
    maxHeight: '140px',
    overflowY: 'overlay',
  },
  parentTokenAmount: {
    minWidth: '450px',
    display: 'grid',
    gridTemplateColumns: '50% 15% 20% 9%',
    gridGap: '2%',
  },
  availableBalance: {
    alignSelf: 'center',
    fontSize: '1em',
  },
  amountError: {
    fontSize: '1em',
    fontWeight: '400',
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  removeParentTokenIcon: {
    alignSelf: 'center',
    margin: 'auto',
    cursor: 'pointer',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  linkMetadataMessage: {
    color: theme.colors.blue,
  },
}));

const StepMetadataPanel = ({
  stepFormData,
  handleRegister,
  setCurrentFormik,
  isMintOrUpdateOngoing,
  isFormPreparing,
}) => {
  const {
    selectedStep,
    isDraftMode,
    formValidationSchema,
    ownItemOptions,
    parentItemOptions,
    initialValues,
    selectedContract,
    tokenMetadataConfig: initialTokenMetadataConfig,
    isCreateStepWithLinkedMetadata,
  } = stepFormData;

  const { activeWallet } = useBecOrganizationContext();
  const classes = useStyles();

  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const {
    permissionsFlags: { isUserAuthToInsertRecordInDGP },
  } = useUserInfo();

  const [isProcessing, setIsProcessing] = useState(false);
  const { draft } = useIngestionContext();
  const { draftValue, resetDraftValue } = draft;
  const tokenType = selectedStep?.involvedItem?.tokenContract?.tokenType;
  const parentTokenType = selectedStep?.involvedItem?.parentItem?.tokenContract?.tokenType;

  const [createItemContent] = useMutation(CREATE_ITEM_CONTENT);
  const [updateItemContent] = useMutation(UPDATE_ITEM_CONTENT);

  const [tokenMetadataConfig, setTokenMetadataConfig] = useState(initialTokenMetadataConfig);

  useEffect(() => {
    if (initialTokenMetadataConfig?.length > 0) setTokenMetadataConfig(initialTokenMetadataConfig);
  }, [initialTokenMetadataConfig]);

  const searchForMinter = minters => {
    const foundMinter = minters.filter(minter => {
      return minter.companyEthereumAddress === activeWallet?.address;
    });

    return !!foundMinter.length;
  };

  const isMinter = () => {
    return selectedStep?.involvedItem?.tokenContract?.companyEthereumAddress ===
      activeWallet?.address
      ? true
      : searchForMinter(selectedStep?.involvedItem?.tokenContract?.minters);
  };

  const generateDependentItems = values => {
    const dependentItems = values?.parentToken?.map(parentToken => {
      return {
        smartContract: parentToken.value?.contractId,
        tokenId: parentToken.value?._id,
        id: parentToken.value?.serialNumber,
        description: '',
        relationship: METADATA_STRUCTURE_RELATIONSHIP.CHILD,
        relationshipType: METADATA_STRUCTURE_RELATIONSHIP_TYPE.OUTPUT,
        ...(parentTokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && {
          amount: values?.parentTokenAmount.find(
            parentTokenAmount => parentTokenAmount?.tokenId === parentToken?.label,
          )?.amount,
        }),
      };
    });
    return dependentItems;
  };

  const saveDraft = async values => {
    try {
      const { token, parentToken, amount, parentTokenAmount, ...parsedValues } = values;
      const newParsedValues = prepareMetadataReqObj(
        selectedStep?.createToken
          ? selectedContract?.metadataStructure?.metadataConfig
          : tokenMetadataConfig,
        parsedValues,
      );

      if (parentToken?.length > 0 && !isCreateStepWithLinkedMetadata) {
        newParsedValues.dependentItems = generateDependentItems(values);
      }

      const saveReqMetadata = {
        variables: {
          input: {
            item: selectedStep?.involvedItem?._id,
            step: selectedStep?._id,
            content: token,
            metadata: newParsedValues,
            isDraft: true,
            ...(amount && { amount: parseInt(amount, 10) }),
            ...(isCreateStepWithLinkedMetadata && {
              linkedToken: values?.parentToken?._id,
            }),
          },
        },
      };

      await createItemContent({ ...saveReqMetadata });
    } catch (error) {
      handleNotification(t('traceability.dgp.saveError'), 'error');
    }
  };

  const updateDraft = async values => {
    try {
      const { token, parentToken, amount, parentTokenAmount, ...parsedValues } = values;
      const newParsedValues = prepareMetadataReqObj(
        selectedStep?.createToken
          ? selectedContract?.metadataStructure?.metadataConfig
          : tokenMetadataConfig,
        parsedValues,
      );

      if (parentToken?.length > 0 && !isCreateStepWithLinkedMetadata) {
        newParsedValues.dependentItems = generateDependentItems(values);
      }

      await updateItemContent({
        variables: {
          input: {
            _id: draftValue?.itemContentId,
            content: token,
            metadata: newParsedValues,
            isDraft: true,
            ...(amount && { amount: parseInt(amount, 10) }),
            ...(isCreateStepWithLinkedMetadata && {
              linkedToken: values?.parentToken?._id,
            }),
          },
        },
      });
    } catch (error) {
      handleNotification(t('traceability.dgp.saveError'), 'error');
    }
  };

  const validateMetadataFields = values => {
    let errors = {};
    const metadataEntries = [];
    const currentMetadataConfigs = selectedStep?.createToken
      ? selectedContract?.metadataStructure?.metadataConfig
      : tokenMetadataConfig;
    if (!isEmpty(currentMetadataConfigs)) {
      currentMetadataConfigs.forEach(metadataConfig => {
        let entry = {};
        entry = {
          name: metadataConfig.metadataName,
          type: metadataConfig.metadataType.metadataTypeName,
          value: values[metadataConfig.metadataName],
        };
        metadataEntries.push(entry);
      });
    }
    metadataEntries.forEach(entry => {
      const currentErrors = validateMetadata(entry, t);
      errors = { ...errors, ...currentErrors };
    });
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: validateMetadataFields,
    validationSchema: formValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsProcessing(true);
        if (isDraftMode) {
          await updateDraft(values);
          resetDraftValue();
        } else {
          await saveDraft(values);
        }
        resetForm({});
        handleNotification(t('traceability.dgp.saveSuccess'), 'success');
      } catch (err) {
        handleNotification(t('traceability.dgp.saveError'), 'error');
      } finally {
        setIsProcessing(false);
      }
    },
  });

  const { setFieldValue, setFieldTouched, values: formValues } = formik;

  const getMetadataErrors = (dirty, errors) => {
    if (!dirty) return null;
    const errorsToDisplay = [];
    const fieldsWithErrors = Object.keys(errors).filter(
      key =>
        key !== 'token' && key !== 'parentToken' && key !== 'amount' && key !== 'parentTokenAmount',
    );

    if (fieldsWithErrors?.length > 0) {
      fieldsWithErrors.forEach(field => {
        errorsToDisplay.push(
          <Typography key={uniqid()} variant="body2">
            {errors[field]}
          </Typography>,
        );
      });
    }

    if (errorsToDisplay.length < 1) return null;

    return (
      <Box display="flex" flexDirection="row" p={2} my={2}>
        <Box pr={2}>
          <ErrorIcon color="error" />
        </Box>
        <Box>{errorsToDisplay}</Box>
      </Box>
    );
  };

  const prepareAndSetMetadata = data => {
    const allMetadataConfigs = data?.getSingleErc721Token?.metadataStructure?.metadataConfig;
    let metadataConfigsWithoutLinkedType;

    if (!isCreateStepWithLinkedMetadata) {
      const parentToken = data?.getSingleErc721Token?.metadata?.links?.children?.map(child => {
        return {
          label: child?.id,
          value: {
            _id: child?.tokenId,
            contractId: child?.smartContract,
            serialNumber: child?.id,
          },
        };
      });
      formik.values.parentToken = parentToken;

      metadataConfigsWithoutLinkedType = allMetadataConfigs
        ?.filter(config => config.metadataName !== 'dependentItems')
        ?.filter(config => config?.step?._id === selectedStep?._id);
    }

    const curMetadataConfig = metadataConfigsWithoutLinkedType || allMetadataConfigs;

    const currentDefaultMeatdataValues = generateDefaultMetadataValues(
      curMetadataConfig,
      data?.getSingleErc721Token,
    );
    keys(currentDefaultMeatdataValues).forEach(key => {
      formik.setFieldValue([key], currentDefaultMeatdataValues[key]);
    });
    setTokenMetadataConfig(curMetadataConfig);
  };

  const [getSingleErc721Token, { loading: getSingleErc721TokenLoading }] = useLazyQuery(
    GET_SINGLE_ERC721_TOKEN,
    {
      onCompleted: data => {
        prepareAndSetMetadata(data);
      },
      onError: () => {
        handleNotification(t('tokens.tokenMetadataError'), 'error');
      },
      fetchPolicy: 'no-cache',
    },
  );

  const handleFormRegister = () => {
    const fieldValues = omit(formik.values, [
      'token',
      'parentToken',
      'amount',
      'parentTokenAmount',
    ]);
    const newParsedValues = prepareMetadataReqObj(
      selectedStep?.createToken
        ? selectedContract?.metadataStructure?.metadataConfig
        : tokenMetadataConfig,
      fieldValues,
    );

    if (formik.values?.parentToken?.length > 0) {
      newParsedValues.dependentItems = generateDependentItems(formik.values);
    }

    // add metata here
    const modalValues = {
      stepName: selectedStep?.name,
      stepId: selectedStep?._id,
      itemName: selectedStep?.involvedItem?.name,
      parentItemName: selectedStep?.involvedItem?.parentItem?.name,
      itemId: selectedStep?.involvedItem._id,
      token: formik.values.token,
      parentItemContent: selectedStep?.involvedItem?.parentItem ? formik.values.parentToken : null,
      contractAddress: selectedStep?.involvedItem?.tokenContract?.contractAddress,
      metadata: newParsedValues,
      ...(formik.values?.amount && { amount: formik.values.amount }),
      ...(isCreateStepWithLinkedMetadata && {
        behavior: METADATA_BEHAVIORS.LINKED,
        linkedContractId: selectedStep?.involvedItem?.parentItem?.tokenContract?._id,
        linkedTokenId: formik.values?.parentToken?._id,
      }),
    };

    setCurrentFormik(formik);
    handleRegister(modalValues);
  };

  const isDefaultMetadataLoading = metadataConfig =>
    metadataConfig?.some(({ metadataName }) => formValues?.[metadataName] === undefined);

  const getFieldTitle = (subHeaderTitle, subHeaderName) => {
    return (
      <div className={classes.subHeaderBox}>
        <Typography variant="h5" className={classes.subHeaderTitle}>
          {subHeaderTitle}
        </Typography>
        <Typography variant="h5" className={classes.subHeaderName}>
          {subHeaderName}
        </Typography>
      </div>
    );
  };

  const handleTokenChange = event => {
    if (selectedContract?.contractAddress) {
      formik.setFieldValue('token', event.target.value);
      getSingleErc721Token({
        variables: {
          contractAddress: selectedContract.contractAddress,
          tokenId: event.target.value,
        },
      });
    }
  };

  const erc1155MultiSelectParentRenderOption = parentOption => {
    const balance = parentOption?.value?.balance;
    return (
      <div className={classes.parentOptionWrapper} key={parentOption?.value?._id}>
        <p className={classes.parentOption}>{parentOption?.label}</p>
        <p className={classes.parentOption}>
          {t('traceability.dgp.noOfItems')}: {balance || 0}
        </p>
      </div>
    );
  };

  const removeParentToken = parentTokenLabel => {
    const { parentToken } = formValues;
    setFieldValue(
      'parentToken',
      parentToken.filter(token => token.label !== parentTokenLabel),
    );
    setFieldTouched('parentToken', true, false);

    const { parentTokenAmount } = formValues;
    setFieldValue(
      'parentTokenAmount',
      parentTokenAmount.filter(option => option.tokenId !== parentTokenLabel),
    );
    setFieldTouched('parentTokenAmount', true, false);
  };

  const generateErc1155ParentTable = () => {
    return formik.values.parentTokenAmount?.map((parentToken, index) => {
      const balance =
        parentItemOptions.find(parentOption => parentOption?.label === parentToken?.tokenId)?.value
          ?.balance || 0;

      return (
        <div key={`parentTokenAmount-${parentToken.tokenId}`}>
          <div className={classes.parentTokenAmount}>
            <Field
              name={`parentTokenAmount.${index}.parentToken`}
              label={t('traceability.dgp.itemIdSerialNo')}
              variant="outlined"
              component={NumberFormat}
              customInput={TextField}
              InputProps={{
                classes: {
                  input: classes.itemNameInput,
                },
              }}
              value={parentToken?.tokenId}
              disabled
              className={classes.parentTokenDisabledField}
            />
            <Field
              name={`parentTokenAmount.${index}.amount`}
              component={NumberFormat}
              label={t('traceability.dgp.amount')}
              customInput={TextField}
              error={!!formik?.errors?.parentTokenAmount?.[index]?.amount}
              onChange={e =>
                formik.setFieldValue(`parentTokenAmount.${index}.amount`, e.target.value)
              }
              value={parentToken?.amount || ''}
              InputProps={{
                classes: {
                  input: classes.itemNameInput,
                },
              }}
            />
            <Typography variant="body1" className={classes.availableBalance}>
              {t('traceability.dgp.availableBalance')}: {balance}
            </Typography>
            <CloseIcon
              className={classes.removeParentTokenIcon}
              onClick={() => removeParentToken(parentToken?.tokenId)}
            />
          </div>
          {!!formik?.errors?.parentTokenAmount?.[index]?.amount && (
            <Typography variant="body1" className={classes.amountError}>
              {formik?.errors?.parentTokenAmount?.[index]?.amount}
            </Typography>
          )}
        </div>
      );
    });
  };

  const handleErc1155ParentSelection = selectedOptions => {
    setFieldValue('parentToken', selectedOptions);
    setFieldTouched('parentToken', true, false);

    setFieldValue(
      'parentTokenAmount',
      selectedOptions.map(option => ({
        tokenId: option.label,
        amount: option.value?.balance,
      })),
    );
    setFieldTouched('parentTokenAmount', true, false);
  };
  const handleLinkedParentTokenChange = event => {
    if (selectedStep?.involvedItem?.parentItem?.tokenContract?.contractAddress) {
      formik.setFieldValue('parentToken', event.target.value);
      getSingleErc721Token({
        variables: {
          contractAddress: selectedStep?.involvedItem?.parentItem?.tokenContract?.contractAddress,
          tokenId: event.target.value?.serialNumber,
        },
      });
    }
  };

  const buildFields = () => {
    const currentFormFields = [];
    if (selectedStep?.createToken) {
      currentFormFields.push(
        <div key={selectedStep?._id}>
          {getFieldTitle(t('traceability.dgp.headerTitle.item'), selectedStep?.involvedItem?.name)}
          <div className={classes.tokenWrapper}>
            <Field
              name="token"
              label={t('traceability.dgp.itemIdSerialNo')}
              variant="outlined"
              component={NumberFormat}
              customInput={TextField}
              error={!!formik?.errors?.token}
              helperText={formik?.errors?.token}
              disabled={!isUserAuthToInsertRecordInDGP}
              onChange={e => formik.setFieldValue('token', e.target.value)}
              value={formik?.values?.token || ''}
              InputProps={{
                classes: {
                  input: classes.itemNameInput,
                },
              }}
              data-testid="token-id-text"
            />
            {tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && (
              <Field
                name="amount"
                component={NumberFormat}
                label={t('traceability.dgp.amount')}
                customInput={TextField}
                error={!!formik?.errors?.amount}
                helperText={formik?.errors?.amount}
                disabled={!isUserAuthToInsertRecordInDGP}
                onChange={e => formik.setFieldValue('amount', e.target.value)}
                value={formik?.values?.amount || ''}
                InputProps={{
                  classes: {
                    input: classes.itemNameInput,
                  },
                }}
                data-testid="token-amount-text"
              />
            )}
          </div>
        </div>,
      );
    } else {
      currentFormFields.push(
        <div key={selectedStep?._id}>
          {getFieldTitle(t('traceability.dgp.headerTitle.item'), selectedStep?.involvedItem?.name)}
          <Field
            name="token"
            component={Select}
            label={t('traceability.dgp.itemIdSerialNo')}
            options={ownItemOptions}
            disabled={!ownItemOptions?.length || !isUserAuthToInsertRecordInDGP}
            onChange={handleTokenChange}
            onBlur={() => formik.validateField('token')}
            error={!!formik?.errors?.token}
            helperText={formik?.errors?.token}
          />
        </div>,
      );
    }

    if (selectedStep?.involvedItem?.parentItem) {
      if (isCreateStepWithLinkedMetadata) {
        currentFormFields.push(
          <Box className={classes.tokenWrapper} mt={2}>
            <div key={selectedStep.involvedItem.parentItem._id}>
              {getFieldTitle(
                t('traceability.dgp.headerTitle.dependentItem'),
                selectedStep?.involvedItem?.parentItem?.name,
              )}
              <Field
                component={Select}
                name="parentToken"
                label={t('traceability.dgp.selectItemId')}
                options={parentItemOptions}
                disabled={!parentItemOptions.length || !isUserAuthToInsertRecordInDGP}
                onChange={handleLinkedParentTokenChange}
                error={!!formik?.errors?.parentToken}
                helperText={formik?.errors?.parentToken}
              />
            </div>
          </Box>,
        );
      } else {
        const otherParams = {};
        if (parentTokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155) {
          otherParams.renderOption = erc1155MultiSelectParentRenderOption;
          otherParams.classes = {
            option: classes.muiAutocompleteOption,
          };
          otherParams.onChange = (e, value) => {
            handleErc1155ParentSelection(value);
          };
        }

        currentFormFields.push(
          <div key={selectedStep.involvedItem.parentItem._id}>
            {getFieldTitle(
              t('traceability.dgp.headerTitle.dependentItem'),
              selectedStep?.involvedItem?.parentItem?.name,
            )}
            <Field
              component={MultiSelectDropDown}
              name="parentToken"
              label={t('traceability.dgp.selectItemIds')}
              options={parentItemOptions}
              disabled={!parentItemOptions.length}
              startingValue={formValues.parentToken}
              className={classes.dependentItemField}
              withCheckboxes
              size="small"
              limitTags={2}
              {...otherParams}
            />
          </div>,
        );
      }
    }
    return currentFormFields;
  };

  const buildMetadataFields = metadataConfig => {
    return (
      <>
        <Divider className={classes.fieldDivider} />
        <div>
          <Typography variant="h6" className={classes.subPanelTitle}>
            {' '}
            {t('tokens.metadataLabel')}
          </Typography>
        </div>
        <TokenMetaData
          metadataConfigs={metadataConfig}
          hideRedirectionUrl
          readOnly={!isUserAuthToInsertRecordInDGP || isCreateStepWithLinkedMetadata}
        />
      </>
    );
  };

  useEffect(() => {
    return () => {
      resetDraftValue();
    };
  }, []);

  if (!selectedStep || !initialValues || isFormPreparing) {
    return (
      <Card variant="outlined" className={classes.stepMetadataPanelRoot}>
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      </Card>
    );
  }

  const getLinkedMetadataMessage = () => (
    <Box my={2}>
      <Paper>
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <ErrorOutlineIcon className={classes.icon} />
          <Typography className={classes.linkMetadataMessage}>
            {t('traceability.dgp.linkedMetadataInfo', {
              ItemName1155: selectedStep?.involvedItem?.name,
            })}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );

  return (
    <Card variant="outlined" className={classes.stepMetadataPanelRoot}>
      {isCreateStepWithLinkedMetadata && selectedStep?.createToken && getLinkedMetadataMessage()}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={classes.stepItemSelectArea}>{buildFields()}</div>

          {parentTokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && (
            <Box className={classes.parentTokenTable}>{generateErc1155ParentTable()}</Box>
          )}

          {selectedStep?.createToken &&
            !isCreateStepWithLinkedMetadata &&
            selectedContract?.metadataStructure?.metadataConfig?.length > 0 &&
            !isDefaultMetadataLoading(selectedContract?.metadataStructure?.metadataConfig) &&
            buildMetadataFields(selectedContract?.metadataStructure?.metadataConfig)}

          {(!selectedStep?.createToken ||
            (selectedStep?.createToken && isCreateStepWithLinkedMetadata)) &&
            (getSingleErc721TokenLoading ? (
              <div className={classes.loaderContainer}>
                <Loader />
              </div>
            ) : (
              tokenMetadataConfig?.length > 0 &&
              !isDefaultMetadataLoading(tokenMetadataConfig) &&
              buildMetadataFields(tokenMetadataConfig)
            ))}

          {getMetadataErrors(formik?.dirty, formik?.errors)}

          {isUserAuthToInsertRecordInDGP && !getSingleErc721TokenLoading && (
            <div className={classes.buttonArea}>
              <Button
                type="submit"
                variant="outlined"
                disabled={
                  !formik.dirty || !isEmpty(formik.errors) || isProcessing || isMintOrUpdateOngoing
                }
                color="primary"
              >
                {t('traceability.dgp.saveForm')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.regButton}
                onClick={handleFormRegister}
                disabled={
                  (!isDraftMode && !formik.dirty) ||
                  !isEmpty(formik.errors) ||
                  isProcessing ||
                  isMintOrUpdateOngoing ||
                  !isMinter
                }
              >
                {t('traceability.dgp.register')}
              </Button>
              {isProcessing && (
                <div className={classes.loaderBox}>
                  <Typography variant="body2">{t('traceability.dgp.saveInProgress')}</Typography>
                  <CircularProgress
                    size={20}
                    thickness={20}
                    color="primary"
                    className={classes.loader}
                  />
                </div>
              )}
            </div>
          )}
        </Form>
      </FormikProvider>
    </Card>
  );
};

StepMetadataPanel.propTypes = {
  stepFormData: PropTypes.shape({
    selectedStep: PropTypes.shape({
      createToken: PropTypes.bool,
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
      involvedItem: PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
        tokenContract: PropTypes.shape({
          companyEthereumAddress: PropTypes.string,
          minters: PropTypes.shape([]),
          contractAddress: PropTypes.string.isRequired,
          tokenType: PropTypes.string,
        }).isRequired,
        parentItem: PropTypes.shape({
          name: PropTypes.string.isRequired,
          _id: PropTypes.string.isRequired,
          tokenContract: PropTypes.shape({
            contractAddress: PropTypes.string.isRequired,
            tokenType: PropTypes.string,
            _id: PropTypes.string.isRequired,
          }).isRequired,
        }),
      }).isRequired,
    }).isRequired,
    isCreateStepWithLinkedMetadata: PropTypes.bool,
    isDraftMode: PropTypes.bool.isRequired,
    formValidationSchema: PropTypes.shape({}).isRequired,
    ownItemOptions: PropTypes.arrayOf(PropTypes.shape({})),
    parentItemOptions: PropTypes.arrayOf(PropTypes.shape({})),
    initialValues: PropTypes.shape({}).isRequired,
    selectedContract: PropTypes.shape({
      contractAddress: PropTypes.string.isRequired,
      metadataStructure: PropTypes.shape({
        metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
    }).isRequired,
    tokenMetadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleRegister: PropTypes.func.isRequired,
  setCurrentFormik: PropTypes.func.isRequired,
  isMintOrUpdateOngoing: PropTypes.bool.isRequired,
  isFormPreparing: PropTypes.bool.isRequired,
};

export default StepMetadataPanel;
