import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useSubscription } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
  ADD_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_UPDATE,
  REMOVE_ORGANIZATION,
} from '../../graphql/Procurement/organization';
import { useNotification } from '../Shared/notification';

const OrganizationContext = React.createContext([{}, () => {}]);

const OrganizationProvider = ({ children }) => {
  const { handleNotification } = useNotification();
  const { t } = useTranslation();

  const [
    getOrganizations,
    { data: organizations, loading: getOrganizationsLoading, error: getOrganizationsError },
  ] = useLazyQuery(GET_ORGANIZATIONS, {
    onError: error => {
      handleNotification(
        t([
          `partners.error.${error?.graphQLErrors[0]?.errorCode}`,
          'partners.error.addPartnerError',
        ]),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [
    addOrganization,
    { loading: loadingAddOrganization, error: errorAddingPartner },
  ] = useMutation(ADD_ORGANIZATION, {
    onCompleted: () => {
      handleNotification(t('organization.addOrganizationSuccess'), 'success');
    },
    onError: error => {
      handleNotification(
        t([
          `organization.error.${error?.graphQLErrors[0]?.errorCode}`,
          'organization.error.organizationError',
        ]),
        'error',
      );
    },
  });

  const [
    removeOrganization,
    { loading: loadingRemoveOrganization, error: errorRemovingPartner },
  ] = useMutation(REMOVE_ORGANIZATION, {
    onCompleted: () => {
      handleNotification(t('organization.removeOrganizationSuccess'), 'success');
      getOrganizations();
    },
    onError: error => {
      handleNotification(
        t([
          `organization.error.${error?.graphQLErrors[0]?.errorCode}`,
          'organization.error.removeOrganizationError',
        ]),
        'error',
      );
    },
  });

  useSubscription(GET_ORGANIZATION_UPDATE, {
    onSubscriptionData: async () => {
      await getOrganizations();
    },
  });

  return (
    <OrganizationContext.Provider
      value={{
        organizations: organizations?.myOrganizations || [],
        addOrganization,
        removeOrganization,
        loadingRemoveOrganization,
        loadingAddOrganization,
        errorAddingPartner,
        errorRemovingPartner,
        getOrganizations,
        getOrganizationsLoading,
        getOrganizationsError,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

const useOrganizationContext = () => useContext(OrganizationContext);

OrganizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useOrganizationContext, OrganizationProvider };
