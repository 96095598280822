import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  positionElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  },
});

const SubscriptionsPartial = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.positionElement}>
      <Typography variant="h2">{t('common.subscriptionRequired')}</Typography>
    </div>
  );
};

export const subscriptionRequired = (component, hasAccess) =>
  hasAccess ? component : SubscriptionsPartial;

export default SubscriptionsPartial;
