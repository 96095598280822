import gql from 'graphql-tag';
import { CONTRACT_ATTRIBUTES } from './contract';

export const PURCHASE_ORDER_ATTRIBUTES = gql`
  fragment PURCHASE_ORDER_ATTRIBUTES on PurchaseOrder {
    _id
    purchaseOrderId
    status
    organizationId
    totalPrice
    materials {
      smartContractId
      materialName
      tokenIds
      deliveryDate
      oldAccumulatedVolumeInContract
      volume
      totalPrice
    }
    contract {
      ...CONTRACT_ATTRIBUTES
    }
    buyer {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    supplier {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    doesInvoiceExist
    preimage
    isSender
    updatedAt
    tokenizedPOContractAddress
    tokenizedPOTokenId
    tokenizedPOTransactionHash
    transactionHash
    parentOrganizationId
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const SIGN_PURCHASE_ORDER = gql`
  mutation signPurchaseOrder(
    $purchaseOrderId: String!
    $supplierSignature: String!
    $buyerOrganizationId: String!
    $parentOrganizationId: String
    $supplierParentOrgId: String
  ) {
    signPurchaseOrder(
      purchaseOrderId: $purchaseOrderId
      supplierSignature: $supplierSignature
      buyerOrganizationId: $buyerOrganizationId
      parentOrganizationId: $parentOrganizationId
      supplierParentOrgId: $supplierParentOrgId
    ) {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const GET_PURCHASE_ORDER_BY_ID = gql`
  query getPurchaseOrderById($purchaseOrderId: String!) {
    purchaseOrderById(purchaseOrderId: $purchaseOrderId) {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder($input: purchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
      _id
    }
  }
`;

export const PURCHASE_ORDER_LISTING = gql`
  query getPurchaseOrders($input: getPurchaseOrders) {
    purchaseOrders(input: $input) {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const PURCHASE_ORDER_LISTING_INVOICE = gql`
  query getPurchaseOrdersForInvoice($buyerOrganizationId: String!) {
    purchaseOrdersNotInvoiced(buyerOrganizationId: $buyerOrganizationId) {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const GET_PURCHASE_ORDER_UPDATE = gql`
  subscription onNewPurchaseOrder {
    newPO {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;
export const PURCHASE_ORDER_SIGNED = gql`
  subscription onPoSigned {
    poSigned {
      _id
      purchaseOrderId
      status
    }
  }
`;
