import React from 'react';
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentPath, getCurrPathCode } from './traceabilityUtils';

const SaveAndContinueButton = ({ disabled, onSubmit }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentPath = getCurrentPath(history);
  const currPathCode = getCurrPathCode(currentPath);
  let disableNavButton = false;
  if (!currPathCode) {
    disableNavButton = true;
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        endIcon={<ArrowRightAltOutlinedIcon />}
        disabled={disableNavButton || disabled}
        onClick={async () => {
          await onSubmit();
        }}
      >
        {t('traceability.configurationWizard.progress.saveAndContinue')}
      </Button>
    </>
  );
};

SaveAndContinueButton.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

SaveAndContinueButton.defaultProps = {
  disabled: false,
  onSubmit: () => {},
};

export default SaveAndContinueButton;
