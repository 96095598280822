import gql from 'graphql-tag';

export const DOCUMENT_ATTRIBUTES = gql`
  fragment DOCUMENT_ATTRIBUTES on Document {
    _id
    documentId
    hash
    txHash
    date
    filename
    issuer
    description
    organizationId
    blockchainNetwork
    notarizationContract {
      _id
      contractAddress
      contractName
    }
    certificates {
      certificateId
      recipient
      date
      content
      issuer
      hash
      txHash
    }
    transaction {
      status
      transactionHash
    }
  }
`;

export const DOCUMENT_VERIFICATION_ATTRIBUTES = gql`
  fragment DOCUMENT_VERIFICATION_ATTRIBUTES on VerificationResult {
    status
    transaction {
      status
      transactionHash
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query documents {
    documents {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const UPDATE_DESC = gql`
  mutation updateDocumentDescription($input: inputUpdateDocumentDescription!) {
    updateDocumentDescription(input: $input) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const UPDATE_DOC_CERT = gql`
  mutation updateDocumentCertificates($input: inputUpdateDocumentCertificates!) {
    updateDocumentCertificates(input: $input) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const NOTARIZE_DOCUMENT_ETH = gql`
  mutation notarizeDocument($input: inputDocument!) {
    notarizeDocument(input: $input) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

// Seperate mutation path for different types of keys
export const NOTARIZE_DOCUMENT_BCOS = gql`
  mutation notarizeDocumentBcos($input: inputDocument!) {
    notarizeDocumentBcos(input: $input) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

// Seperate mutation path for different types of keys
// so that it will easier to provider separate API service in the future
export const CHECK_DOCUMENT_EXIST_BCOS = gql`
  mutation checkNotarizationBcos($documentHash: String!, $contractId: String!) {
    checkNotarizationBcos(documentHash: $documentHash, contractId: $contractId) {
      ...DOCUMENT_VERIFICATION_ATTRIBUTES
    }
  }
  ${DOCUMENT_VERIFICATION_ATTRIBUTES}
`;

export const CHECK_DOCUMENT_EXIST_ETH = gql`
  mutation checkNotarizationEth($documentHash: String!, $contractId: String!) {
    checkNotarizationEth(documentHash: $documentHash, contractId: $contractId) {
      ...DOCUMENT_VERIFICATION_ATTRIBUTES
    }
  }
  ${DOCUMENT_VERIFICATION_ATTRIBUTES}
`;

export const NOTARIZE_DOCUMENT_BSN = gql`
  mutation notarizeDocumentBsn($input: inputDocumentBsn!) {
    notarizeDocumentBsn(input: $input) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const CHECK_DOCUMENT_EXIST_BSN = gql`
  mutation checkDocumentExistInBsn($documentHash: String!) {
    checkDocumentExistInBsn(documentHash: $documentHash)
  }
`;

export const DOCUMENT_NOTARIZED = gql`
  subscription documentNotarized {
    documentNotarized {
      document {
        ...DOCUMENT_ATTRIBUTES
      }
      status
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const GET_DOCS_FOR_CONTRACT = gql`
  query documentsForContract($contractId: String!) {
    documentsForContract(contractId: $contractId) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;

export const HIDE_DOCUMENT = gql`
  mutation hideDocument($documentId: String!, $contractId: String!) {
    hideDocument(documentId: $documentId, contractId: $contractId) {
      ...DOCUMENT_ATTRIBUTES
    }
  }
  ${DOCUMENT_ATTRIBUTES}
`;
