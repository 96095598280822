import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationVariant, setNotificationVariant] = useState('success');
  const [showFaucetModal, setShowFaucetModal] = useState(false);

  // live transactions panel
  const [updateLivePanel, setUpdateLivePanel] = useState(false);
  const [finishedTnxs, setFinishedTnxs] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setNotificationMessage('');
    setNotificationVariant('success');
  };

  const handleNotification = (message, variant) => {
    setNotificationMessage(message);
    setNotificationVariant(variant);
    setOpen(true);
  };

  useEffect(() => {
    handleClose();
  }, [showFaucetModal]);

  return (
    <NotificationContext.Provider
      value={{
        handleNotification,
        displayNotification: open,
        closeNotification: handleClose,
        notificationMessage,
        notificationVariant,
        showFaucetModal,
        setShowFaucetModal,
        updateLivePanel,
        setUpdateLivePanel,
        finishedTnxs,
        setFinishedTnxs,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useNotification, NotificationProvider };
