import React from 'react';
import WelcomeScreen from './Screens/WelcomeScreen';
import FundYourWallet from './Screens/FundYourWallet';
import MainnetInfo from './Screens/MainnetInfo';

const screens = (
  screenSelected,
  setScreenSelected,
  testWalletAddress,
  setOpen,
  copied,
  setCopied,
  userModalPreference,
) => {
  const nextOnClick = () => setScreenSelected(screenSelected + 1);
  const backOnClick = () => setScreenSelected(screenSelected - 1);

  return [
    {
      showCloseIcon: false,
      isComplete: false,
      actions: {
        left: {
          hide: true,
        },
        right: {
          hide: true,
        },
      },
      content: (
        <>
          <WelcomeScreen nextOnClick={nextOnClick} />
        </>
      ),
    },
    {
      showCloseIcon: false,
      isComplete: false,
      actions: {
        left: {
          hide: true,
        },
        right: {
          hide: true,
        },
      },
      content: (
        <>
          <FundYourWallet
            copied={copied}
            setCopied={setCopied}
            nextOnClick={nextOnClick}
            backOnClick={backOnClick}
            testWalletAddress={testWalletAddress}
          />
        </>
      ),
    },
    {
      showCloseIcon: true,
      isComplete: false,
      actions: {
        left: {
          hide: true,
        },
        right: {
          hide: true,
        },
      },
      content: (
        <MainnetInfo
          userModalPreference={userModalPreference}
          backOnClick={backOnClick}
          setOpenModal={setOpen}
        />
      ),
    },
  ];
};

export default screens;
