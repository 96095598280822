import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import { CONSTANTS } from '../../constants';
import { getPrettifiedEthAddr } from '../../utils';

const useStyles = makeStyles(theme => ({
  listCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
    },
  },
  disabledCard: {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.primary.lightest,
  },
  hiddenCard: {
    display: 'none',
  },
  minCard: {
    width: '40%',
  },
  selectedCard: {
    zIndex: '1',
  },
  imageArea: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  headerArea: {
    display: 'flex',
    alignItems: 'center',
  },
  headerName: {
    padding: '0px 0px 0px 14px',
  },
  symbolText: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '22rem',
  },
  minChipArea: {
    justifyContent: 'space-between',
    width: '100%',
  },
  selectedBox: {
    width: '4px',
    backgroundColor: theme.palette.secondary.main,
  },
  minBalanceArea: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    alignItems: 'baseline',
  },
  balanceArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  metaArea: {
    marginLeft: theme.spacing(2),
  },
  addrArea: {
    display: 'flex',
    alignItems: 'center',
  },
  chipBox: {
    width: '5rem',
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  balanceLabel: {
    color: theme.palette.primary.light,
    marginRight: '1px',
  },
}));

const NotarizationContractListCard = ({ isMinimized, contractDetails, handleMinimize }) => {
  const classes = useStyles();
  const isCompleted = contractDetails?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED;
  const showLoader = contractDetails?.status !== CONSTANTS.CONTRACT_STATUSES.COMPLETED;
  const cardClass = clsx(classes.listCardRoot, {
    [classes.disabledCard]: !isCompleted,
  });
  const metaAreaClass = clsx(classes.chipArea, { [classes.minChipArea]: isMinimized });

  const handleContentClick = () => {
    if (isCompleted) {
      handleMinimize(contractDetails?._id);
    }
  };

  const getMetaContent = () => {
    return (
      <div className={classes.addrArea}>
        {contractDetails?.address && (
          <>
            <Typography variant="body2">
              {getPrettifiedEthAddr(contractDetails?.address)}
            </Typography>
            <CopyContent content={contractDetails?.address} />
          </>
        )}
      </div>
    );
  };

  return (
    <Card variant="outlined" className={cardClass} onClick={handleContentClick}>
      <div className={classes.bannerArea}>
        <div className={classes.headerArea}>
          <Typography
            className={classes.headerName}
            variant="h5"
            data-testid={`notarization-contract-banner-${contractDetails?.name}`}
          >
            {contractDetails?.name}
          </Typography>
          {isCompleted && contractDetails?.symbol && (
            <Typography variant="h5">({contractDetails?.symbol})</Typography>
          )}
          {!isCompleted && showLoader && (
            <CircularProgress
              size={20}
              thickness={20}
              className={classes.spinner}
              data-testid={`notarization-contract-spinner-${contractDetails?._id}`}
            />
          )}
        </div>
        <div className={metaAreaClass}>
          <div className={classes.metaArea}>{getMetaContent()}</div>
        </div>
      </div>
    </Card>
  );
};

NotarizationContractListCard.propTypes = {
  isMinimized: PropTypes.bool.isRequired,
  contractDetails: PropTypes.shape({
    _id: PropTypes.string,
    address: PropTypes.string,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string,
    status: PropTypes.string,
  }),
  handleMinimize: PropTypes.func.isRequired,
};

NotarizationContractListCard.defaultProps = {
  contractDetails: {
    address: '',
    symbol: '',
  },
};

export default NotarizationContractListCard;
