import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { FastField, useFormikContext } from 'formik';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import MetaDataFields from '../Shared/MetaDataFormFields/MetaDataFormFields';
import { METADATA_BEHAVIORS } from '../../constants';
import LinkERC721Input from './LinkERC721Input';

const useStyles = makeStyles(theme => ({
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  accordion: {
    margin: '0',
    paddingRight: '0',
  },
  accordionDetails: {
    display: 'list-item',
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 32px 0 0',
    '&::after': {
      display: 'block',
      content: '""',
      height: '50px',
    },
  },
  accordionSummary: {
    display: '',
    margin: '0',
    paddingLeft: '0',
  },
  accordionTitle: {
    fontWeight: '500',
    margin: '0',
  },
  fieldDivider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '16px 10px',
  },
  accordionPaper: {
    margin: '0 10px',
    width: '100%',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
  boxStyle: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '15px',
  },
  unrestricted: {
    minWidth: '100px',
    marginRight: '10px',
    borderColor: '#155CB4',
    backgroundColor: '#155CB4',
    color: 'white',
  },
  restricted: {
    minWidth: '100px',
    color: 'white',
    marginRight: '10px',
    borderColor: '#747480',
    backgroundColor: '#747480',
  },
  defaultChipStyle: {
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const MintPanelMetadata = ({
  metadataMode,
  metadataConfigs,
  admittedMetadata,
  initialValues,
  isContractOwner,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const [isCompiled, setCompiled] = useState(false);

  const populateDynamicFields = () => {
    switch (metadataMode) {
      case METADATA_BEHAVIORS.STRUCTURED:
      case METADATA_BEHAVIORS.HASHED:
      case METADATA_BEHAVIORS.DIRECT:
        return admittedMetadata.map((metadataConfig, index) => (
          <React.Fragment key={`Fragment_${metadataConfig.metadataName}`}>
            <FastField
              name={metadataConfig.metadataName}
              component={MetaDataFields}
              fieldMetadataConfig={metadataConfig}
              index={index}
              className={classes.metadataField}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
            <Divider
              className={classes.fieldDivider}
              key={`Divider_${metadataConfig.metadataName}`}
            />
          </React.Fragment>
        ));
      default:
        return <></>;
    }
  };

  const populateStatusChip = metadataConfig => {
    let labelContent;
    let restrictionChipVisibility = false;
    let restrictionChipLabel;
    const chipStyle = classes.defaultChipStyle;
    let restrictionChipStyle;
    switch (metadataMode) {
      case METADATA_BEHAVIORS.DIRECT:
        labelContent = t('tokens.metadataModes.direct');
        break;
      case METADATA_BEHAVIORS.HASHED:
        labelContent = t('tokens.metadataModes.hashed');
        restrictionChipVisibility = true;
        restrictionChipLabel = t(
          `tokens.metaDataStructure.permissions.${metadataConfig?.metadataPermission}`,
        );
        restrictionChipStyle = classes[metadataConfig?.metadataPermission];
        break;
      case METADATA_BEHAVIORS.STRUCTURED:
        labelContent = t('tokens.metadataModes.structured');
        break;
      case METADATA_BEHAVIORS.LINKED:
        labelContent = t('tokens.metadataModes.linked');
        break;
      default:
        labelContent = '';
        break;
    }
    if (!metadataMode) {
      return <></>;
    }
    return (
      <Box className={classes.boxStyle}>
        <Chip className={chipStyle} label={labelContent} />
        {restrictionChipVisibility && (
          <Chip className={restrictionChipStyle} label={restrictionChipLabel} />
        )}
      </Box>
    );
  };

  const generateMessage = () => {
    if (admittedMetadata.length > 0) {
      return isCompiled
        ? `${t('tokens.mintingCompiledMetadataForm')} ${t('tokens.mintingMetadataInstr')}`
        : `${t('tokens.mintingEmptyMetadataForm')} ${t('tokens.mintingMetadataInstr')}`;
    }
    return isContractOwner
      ? `${t('tokens.mintingAddMetadata') + t('tokens.mintingNoMetadataConfiguredAdmin')} ${t(
          'tokens.mintingMetadataInstr',
        )}`
      : `${t('tokens.mintingAddMetadata') + t('tokens.mintingNoMetadataConfiguredMember')} ${t(
          'tokens.mintingMetadataInstr',
        )}`;
  };

  const MetadataFieldCheck = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      let compiled = false;

      admittedMetadata.forEach(metadataConfig => {
        const { metadataName } = metadataConfig;
        if (values[metadataName] !== initialValues[metadataName]) {
          compiled = true;
        }
      });

      setCompiled(compiled);
    }, [values]);

    return null;
  };

  let content = null;

  switch (metadataMode) {
    case METADATA_BEHAVIORS.STRUCTURED:
      content = (
        <Accordion className={classes.accordion} expanded={isExpanded}>
          <AccordionSummary
            className={classes.accordionSummary}
            onClick={() => setExpanded(!isExpanded)}
            expandIcon={isExpanded ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
          >
            <Typography className={classes.accordionTitle}>
              {t('tokens.insertTokenMetadata')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {metadataMode === METADATA_BEHAVIORS.STRUCTURED && (
              <Paper className={classes.accordionPaper}>
                <Typography className={classes.message}>
                  <ErrorIcon className={classes.icon} />
                  {generateMessage()}
                </Typography>
              </Paper>
            )}
            {populateStatusChip(metadataConfigs[0])}
            {populateDynamicFields()}
          </AccordionDetails>
        </Accordion>
      );
      break;
    case METADATA_BEHAVIORS.NONE:
      content = (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('tokens.directMintMessage')}
          </Typography>
        </Paper>
      );
      break;
    case METADATA_BEHAVIORS.LINKED:
      content = (
        <Box mt={4}>
          <Box
            sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}
            mb={2}
          >
            <Typography variant="h6">{t('tokens.insertTokenMetadata')}</Typography>
            {populateStatusChip()}
          </Box>
          <FastField name="linkERC721Input" component={LinkERC721Input} />
        </Box>
      );
      break;
    default:
      content = (
        <>
          {populateStatusChip(metadataConfigs[0])}
          {populateDynamicFields()}
        </>
      );
      break;
  }

  return (
    <>
      {content}
      <MetadataFieldCheck />
    </>
  );
};

MintPanelMetadata.propTypes = {
  admittedMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  metadataConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  metadataMode: PropTypes.string,
  isContractOwner: PropTypes.bool,
  initialValues: PropTypes.shape({}),
};

MintPanelMetadata.defaultProps = {
  admittedMetadata: [],
  metadataConfigs: [],
  metadataMode: '',
  isContractOwner: false,
  initialValues: {},
};

export default MintPanelMetadata;
