import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  searchButton: {
    height: '2.5rem',
    alignItems: 'center',
    borderRadius: 0,
    padding: 0,
    width: '2.5rem',
  },
  searchInput: {
    height: '2.5rem',
    border: '1px solid #2E2E38',
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingRight: 0,
  },
  searchBar: {
    maxHeight: '2.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70vw',
  },
  button: {
    height: '2.5rem',
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    minWidth: '11.125rem',
  },
}));

const InstanceSearchBar = ({
  handleSearch,
  placeHolderText,
  handleOpenForm,
  createButtonText,
  buttonDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    permissionsFlags: { isUserAuthToCreateValueChain },
  } = useUserInfo();

  return (
    <div className={classes.searchBar}>
      <Typography variant="h4">{t('traceability.landingHeader')}</Typography>
      <div className={classes.buttonBox}>
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder={placeHolderText}
          onChange={e => handleSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton className={classes.searchButton} variant="contained">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {isUserAuthToCreateValueChain && (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleOpenForm}
            disabled={buttonDisabled}
            data-testid="create-chain-button"
          >
            {createButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

InstanceSearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  placeHolderText: PropTypes.string,
  handleOpenForm: PropTypes.func.isRequired,
  createButtonText: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
};

InstanceSearchBar.defaultProps = {
  placeHolderText: 'Search',
  buttonDisabled: false,
};

export default InstanceSearchBar;
