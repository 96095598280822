import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import TableHeadCell from '../Shared/TableHeadCell';
import ContractProgressBar from './PurchaseOrderProgressBar/ContractProgressBar';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: '40px',
    marginTop: '40px',
    padding: 0,
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tiertable: {
    width: '100%',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.lightest,
      width: '10%',
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& tbody.MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tierComponent: {
    marginTop: '5rem',
    marginBottom: '2rem',
    padding: theme.spacing(2),
  },
  closedTable: {
    '& tbody.MuiTableBody-root': {
      borderLeft: 'none',
    },
  },
}));

const ContractPricingRebateTable = ({ materials, purchaseOrderVolume }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(!!purchaseOrderVolume);

  const columns = [
    {
      name: 'title',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'pricings',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'accumulatedVolume',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
  ];

  const tierColumns = [
    {
      name: 'RebateThreshold',
      label: t('contracts.rebateThreshold'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'RebatePercentage',
      label: t('contracts.rebatePercentage'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const Row = props => {
    const { row } = props;
    const theresholdArr = [0];
    const percentageArr = [0];

    if (row[1] && row[1].length > 0) {
      row[1].forEach(data => {
        theresholdArr.push(data.RebateThreshold);
        percentageArr.push(
          Number(data.RebatePercentage.substring(0, data.RebatePercentage.length - 1)),
        );
      });
    }
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row[0]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  title=""
                  className={classes.tiertable}
                  columns={tierColumns}
                  data={row[1]}
                  options={{
                    pagination: false,
                    filter: false,
                    search: false,
                    searchOpen: false,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                  }}
                />
              </Box>
              <ContractProgressBar
                key={uniqid()}
                threshold={theresholdArr}
                accumulatedVolume={row[2]}
                purchaseOrderVolume={purchaseOrderVolume}
                percentage={percentageArr}
                className={classes.tierComponent}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  Row.propTypes = {
    row: PropTypes.oneOfType([PropTypes.array]),
  };

  Row.defaultProps = {
    row: [],
  };

  return (
    <Table
      title=""
      className={clsx(classes.table, open ? '' : classes.closedTable)}
      columns={columns}
      data={materials}
      key={uniqid()}
      options={{
        pagination: false,
        filter: false,
        customRowRender: data => data && data.length > 0 && <Row key={uniqid()} row={data} />,
        search: false,
        searchOpen: false,
        elevation: 0,
        textLabels: {
          body: {
            noMatch: t('common.noMatchingRecords'),
          },
          pagination: {
            rowsPerPage: t('common.rowsPerPage'),
          },
        },
      }}
    />
  );
};

ContractPricingRebateTable.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  purchaseOrderVolume: PropTypes.number,
};

ContractPricingRebateTable.defaultProps = {
  materials: [],
  purchaseOrderVolume: 0,
};

export default ContractPricingRebateTable;
