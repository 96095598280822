import { useApolloClient, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { bigNumberify } from 'ethers/utils';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Breadcrumbs from '../../components/Shared/Breadcrumbs';
import PageLoader from '../../components/Shared/PageLoader';
import PortalRegisterModal from '../../components/Traceability/DataGatheringPortal/PortalRegisterModal';
import StepListCard from '../../components/Traceability/WizardV2/StepListCard';
import StepMetadataPanel from '../../components/Traceability/WizardV2/StepMetadataPanel';
import { CONSTANTS, METADATA_BEHAVIORS, TRACEABILITY_ROUTE } from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { useIngestionContext } from '../../contexts/Traceability/ingestion';
import {
  GET_ERC721_TOKEN_LIST,
  GET_ERC1155_TOKEN_LIST,
  GET_SINGLE_ERC721_TOKEN,
  GET_SINGLE_ERC1155_TOKEN,
  MINT_ERC721,
  MINT_ERC1155,
  SET_TOKEN_METADATA_ERC721,
  SET_TOKEN_METADATA_ERC1155,
} from '../../graphql/Tokenization/token';
import useUserInfo from '../../hooks/useUserInfo';
import track from '../../mixpanel';
import { generateDefaultMetadataValues } from '../../tokenMetadataUtils';
import DGPHeaderTitle from './DGPHeaderTitle';
import InstanceError from './InstanceError';

const useStyles = makeStyles(theme => ({
  portalLandingRoot: {
    background: theme.palette.primary.lightest,
    minHeight: '100vh',
  },
  dgpContainer: {
    padding: theme.spacing(2),
  },
  nameBanner: {
    fontWeight: '400',
  },
  instanceTitle: {
    fontWeight: '100',
  },
  stepListHeader: {
    fontWeight: '200',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  fieldDivider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  divider: {
    background: '#c4c4cd57',
    width: '100%',
    margin: '0',
    marginTop: '0.5rem',
  },
  dgpHeaderRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  breadcrumbsContainer: {
    marginTop: theme.spacing(1),
  },
  accordionPaper: {
    margin: theme.spacing(3),
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.info.main,
    paddingLeft: '0.2rem',
  },
}));

const PortalLanding = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);
  const { handleNotification, showFaucetModal, setShowFaucetModal } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormPreparing, setIsFormPreparing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('-1');
  const [currentFormik, setCurrentFormik] = useState(null);
  const [isCreateToken, setIsCreateToken] = useState(null);
  const { activeWallet, activeOrganization } = useBecOrganizationContext();
  const [currentWallet] = useState(activeWallet);
  const [stepFormData, setStepFormData] = useState({});
  const { selectedContract: selectedTokenContract } = useTokenContext();
  const {
    permissionsFlags: {
      isUserAuthToViewRecordInDGP,
      isUserAuthToViewAllRecordsInDGP,
      isUserAuthToInsertRecordInDGP,
    },
  } = useUserInfo();

  const tokenType = stepFormData?.selectedStep?.involvedItem?.tokenContract?.tokenType;
  const isERC1155 = tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155;

  const {
    draft: { draftValue, resetDraftValue },
  } = useIngestionContext();
  const {
    instanceDetails,
    isDataLoading,
    instanceId,
    instanceNotFound,
    errorLoadingInstance,
  } = useConfigurationWizardContext();
  const [stepRows, setStepRows] = useState([]);
  const { items } = instanceDetails?.content;

  const valueChainStatus = instanceDetails?.content?.status;

  const handleClose = () => {
    setModalOpen(false);
    setSelectedFormData(null);
  };

  const [mintErc721TokenEth, { loading: isErc721MintLoading }] = useMutation(MINT_ERC721, {
    onCompleted: () => {
      handleClose();
      if (currentFormik) currentFormik.resetForm({});
      if (draftValue?.itemContentId) {
        if (stepFormData?.selectedStep?._id === draftValue.stepId) {
          resetDraftValue();
        }
      }
      handleNotification(t('tokens.transactionSubmitted'), 'success');
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [mintErc1155TokenEth, { loading: isErc1155MintLoading }] = useMutation(MINT_ERC1155, {
    onCompleted: () => {
      handleClose();
      if (currentFormik) currentFormik.resetForm({});
      if (draftValue?.itemContentId) {
        if (stepFormData?.selectedStep?._id === draftValue.stepId) {
          resetDraftValue();
        }
      }
      handleNotification(t('tokens.transactionSubmitted'), 'success');
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [updateERC721TokenMetadata, { loading: isUpdateERC721TokenMetadataLoading }] = useMutation(
    SET_TOKEN_METADATA_ERC721,
    {
      onCompleted: data => {
        const totalMetadata = Object.keys(data.setTokenMetadataERC721?.newMetadata || []).length;
        handleClose();
        if (currentFormik) currentFormik.resetForm({});
        if (draftValue?.itemContentId) {
          if (stepFormData?.selectedStep?._id === draftValue.stepId) {
            resetDraftValue();
          }
        }
        if (data?.setTokenMetadataERC721?.updateTriggeredOnChain) {
          handleNotification(t('tokens.transactionSubmitted'), 'success');
        } else {
          handleNotification(t('tokens.transactionSuccess'), 'success');
        }
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedTokenContract?.contract?._id,
          tokenId: data?.setTokenMetadataERC721?.metadataTransaction?.tokenId,
          totalMetadata: totalMetadata,
          transactionHash: data?.transactionHash,
        });
      },
      onError: error => {
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
          'error',
        );
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.UPDATE_METADATA, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );

  const [
    updateERC1155TokenMetadata,
    { loading: isErc1155TokenMetadataUpdateLoading },
  ] = useMutation(SET_TOKEN_METADATA_ERC1155, {
    onCompleted: data => {
      const totalMetadata = Object.keys(data.setERC1155Metadata?.newMetadata).length || 0;
      handleClose();
      if (currentFormik) currentFormik.resetForm({});
      if (draftValue?.itemContentId) {
        if (stepFormData?.selectedStep?._id === draftValue.stepId) {
          resetDraftValue();
        }
      }
      if (data?.setERC1155Metadata?.updateTriggeredOnChain) {
        handleNotification(t('tokens.transactionSubmitted'), 'success');
      } else {
        handleNotification(t('tokens.transactionSuccess'), 'success');
      }
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.UPDATE_METADATA, {
        blockchainNetwork: activeWallet?.network,
        contractId: selectedTokenContract?.contract?._id,
        tokenId: data?.setERC1155Metadata?.metadataTransaction?.tokenId,
        totalMetadata: totalMetadata,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.UPDATE_METADATA, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const [
    checkIfErc721TokenAlreadyExist,
    { loading: isCheckErc721TokenAlreadyExistLoading, data: erc721tokenAlreadyMinted },
  ] = useLazyQuery(GET_SINGLE_ERC721_TOKEN, {
    fetchPolicy: 'no-cache',
  });

  const [
    checkIfErc1155TokenAlreadyExist,
    { loading: isCheckErc1155TokenAlreadyExistLoading, data: erc1155tokenAlreadyMinted },
  ] = useLazyQuery(GET_SINGLE_ERC1155_TOKEN, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const { steps } = instanceDetails?.content;
    setStepRows(steps);
  }, [instanceDetails]);

  const handleHistoryNav = () => {
    history.push(`/traceability/ingestion-history/${instanceId}`);
  };

  useEffect(() => {
    setIsLoading(
      isErc721MintLoading ||
        isErc1155MintLoading ||
        isUpdateERC721TokenMetadataLoading ||
        isErc1155TokenMetadataUpdateLoading ||
        isCheckErc721TokenAlreadyExistLoading,
    );
  }, [
    isCheckErc721TokenAlreadyExistLoading,
    isErc721MintLoading,
    isErc1155MintLoading,
    isUpdateERC721TokenMetadataLoading,
    isErc1155TokenMetadataUpdateLoading,
  ]);

  const generateVariables = () => {
    const isMintingOnLinkedMetadata =
      tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 &&
      selectedFormData.linkedContractId &&
      selectedFormData.linkedTokenId &&
      selectedFormData.behavior === METADATA_BEHAVIORS.LINKED;
    return {
      variables: {
        input: {
          contractAddress: selectedFormData.contractAddress,
          tokenId: selectedFormData.token,
          ...(!isMintingOnLinkedMetadata && {
            metadata: selectedFormData.metadata,
            behavior: METADATA_BEHAVIORS.STRUCTURED,
          }),
          itemContentDetails: {
            item: selectedFormData.itemId,
            step: selectedFormData.stepId,
            content: selectedFormData.token,
            isDraft: false,
          },
          ...(isERC1155 && {
            amount: parseInt(selectedFormData.amount, 10),
            ...(isMintingOnLinkedMetadata && {
              behavior: METADATA_BEHAVIORS.LINKED,
              linkedContractId: selectedFormData.linkedContractId,
              linkedTokenId: selectedFormData.linkedTokenId,
            }),
          }),
        },
      },
    };
  };

  useEffect(() => {
    const updateToken = async () => {
      if (
        isCheckErc721TokenAlreadyExistLoading ||
        isCheckErc1155TokenAlreadyExistLoading ||
        !selectedFormData
      )
        return;

      let tokenAlreadyMinted;
      let updateTokenMetadata;

      switch (tokenType) {
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
          tokenAlreadyMinted = erc721tokenAlreadyMinted?.getSingleErc721Token;
          updateTokenMetadata = updateERC721TokenMetadata;
          break;
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
          tokenAlreadyMinted = erc1155tokenAlreadyMinted?.getSingleTokenInERC1155Contract;
          updateTokenMetadata = updateERC1155TokenMetadata;
          break;
        default:
          return;
      }

      const tokenAlreadyExist = tokenAlreadyMinted !== null;
      if (tokenAlreadyExist) {
        if (tokenAlreadyMinted.latestMetadataTransaction?.status === 'pending') {
          handleNotification(t('tokens.metaDataUpdateProgress'), 'error');
        } else {
          const updateReqMetadata = generateVariables();
          const updateReqMetadataWithAppendMode = {
            ...updateReqMetadata,
            variables: {
              ...updateReqMetadata.variables,
              input: {
                ...updateReqMetadata.variables.input,
                isAppend: true,
              },
            },
          };
          await updateTokenMetadata(updateReqMetadataWithAppendMode);
        }
      } else {
        handleNotification(t('traceability.dgp.tokenNotFoundError'), 'error');
      }
    };
    updateToken();
  }, [isCheckErc721TokenAlreadyExistLoading, isCheckErc1155TokenAlreadyExistLoading]);

  const generateTokenOptions = async (tokenContract, onlyStructureTypeERC721) => {
    let tokens = [];
    switch (tokenContract?.tokenType) {
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC721: {
        const { data } = await client.query({
          query: GET_ERC721_TOKEN_LIST,
          variables: {
            smartContractId: tokenContract?._id,
          },
          fetchPolicy: 'no-cache',
        });
        tokens = data?.erc721TokenList?.filter(token =>
          onlyStructureTypeERC721
            ? token?.metadataStructure?.behavior === METADATA_BEHAVIORS.STRUCTURED
            : true,
        );
        break;
      }
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155: {
        const { data } = await client.query({
          query: GET_ERC1155_TOKEN_LIST,
          variables: {
            contractAddress: tokenContract?.contractAddress,
          },
          fetchPolicy: 'no-cache',
        });
        tokens = data?.getTokensInERC1155Contract;
        break;
      }
      default:
        return [];
    }

    const tokenData = tokens.map(token => {
      return {
        label: token?.tokenId,
        value: {
          _id: token?._id,
          contractId: token?.smartContract?._id,
          serialNumber: token?.tokenId,
          ...(tokenContract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && {
            balance: token?.owners1155 && token?.owners1155[0]?.balance,
          }),
        },
      };
    });
    return tokenData;
  };

  const getSingleTokenData = async tokenContract => {
    switch (tokenContract?.tokenType) {
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC721: {
        const { data: erc721data } = await client.query({
          query: GET_SINGLE_ERC721_TOKEN,
          variables: {
            contractAddress: tokenContract?.contractAddress,
            tokenId: draftValue?.tokenId,
          },
          fetchPolicy: 'no-cache',
        });
        return erc721data?.getSingleErc721Token;
      }
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155: {
        const { data: erc1155data } = await client.query({
          query: GET_SINGLE_ERC1155_TOKEN,
          variables: {
            contractAddress: tokenContract?.contractAddress,
            tokenId: draftValue?.tokenId,
          },
          fetchPolicy: 'no-cache',
        });
        return erc1155data?.getSingleTokenInERC1155Contract;
      }
      default:
        return null;
    }
  };

  const prepareStepFormData = async currentStep => {
    try {
      if (currentStep) {
        setIsFormPreparing(true);
        let currentFormValidationSchema = Yup.object().shape({});
        let ownItemOptions;
        let parentItemOptions;
        let selectedContract;
        let itemContentDetails;
        let isDraftMode = false;
        const initialTokenValues = {};
        let initialMetadataValues = {};
        let tokenMetadataConfig;

        if (draftValue?.itemContentId) {
          if (currentStep?._id === draftValue.stepId) {
            isDraftMode = true;
          }
        }
        const { tokenContract } = find(items, item => item._id === currentStep.involvedItem._id);
        selectedContract = { ...tokenContract };

        const isCreateStepWithLinkedMetadata =
          selectedContract?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED &&
          currentStep?.createToken;

        if (isCreateStepWithLinkedMetadata && isDraftMode && currentStep?.createToken) {
          tokenMetadataConfig = draftValue?.linkedTokenDetails?.metadataStructure?.metadataConfig;
          initialMetadataValues = generateDefaultMetadataValues(
            tokenMetadataConfig,
            draftValue?.metadata,
          );
        } else if (currentStep.involvedItem?._id && currentStep?.createToken) {
          const currentStepMetadataConfig = tokenContract?.metadataStructure?.metadataConfig?.filter(
            config => config?.step?._id === currentStep?._id,
          );
          if (currentStepMetadataConfig) {
            initialMetadataValues = isDraftMode
              ? generateDefaultMetadataValues(currentStepMetadataConfig, draftValue?.metadata)
              : generateDefaultMetadataValues(currentStepMetadataConfig);
            selectedContract = {
              ...selectedContract,
              metadataStructure: {
                ...selectedContract?.metadataStructure,
                metadataConfig: [...currentStepMetadataConfig],
              },
            };
          }
        } else if (isDraftMode && draftValue?.tokenId && !currentStep?.createToken) {
          const data = getSingleTokenData(tokenContract);
          tokenMetadataConfig = data?.metadataStructure?.metadataConfig
            ?.filter(config => config.metadataName !== 'dependentItems')
            ?.filter(config => config?.step?._id === currentStep?._id);

          initialMetadataValues = generateDefaultMetadataValues(
            tokenMetadataConfig,
            draftValue?.metadata,
          );
        }

        if (currentStep?.createToken) {
          initialTokenValues.token = isDraftMode ? draftValue?.tokenId : '';
          currentFormValidationSchema = currentFormValidationSchema.concat(
            Yup.object().shape({
              token: Yup.number()
                .required(t('traceability.dgp.formErrors.tokenRequired'))
                .test('maxValue', t('traceability.dgp.formErrors.tokenIDLimitExceeded'), number => {
                  try {
                    bigNumberify(number);
                    return true;
                  } catch {
                    return false;
                  }
                }),
            }),
          );
          if (
            currentStep?.involvedItem?.tokenContract?.tokenType ===
            CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
          ) {
            initialTokenValues.amount = isDraftMode ? draftValue?.amount : '';
            currentFormValidationSchema = currentFormValidationSchema.concat(
              Yup.object().shape({
                amount: Yup.number()
                  .required(t('traceability.dgp.formErrors.amountRequired'))
                  .min(1, t('tokens.validation.minAmount'))
                  .max(100000000, t('tokens.validation.maxAmount'))
                  .integer(t('tokens.validation.integerValue')),
              }),
            );
          }
          setIsCreateToken(true);
        } else if (currentStep?.involvedItem?._id) {
          initialTokenValues.token = isDraftMode ? draftValue?.tokenId : '';
          currentFormValidationSchema = currentFormValidationSchema.concat(
            Yup.object().shape({
              token: Yup.string().required(t('traceability.dgp.formErrors.tokenRequired')),
            }),
          );

          const res = await generateTokenOptions(
            currentStep.involvedItem.tokenContract,
            isCreateStepWithLinkedMetadata,
          );
          ownItemOptions = res.map(item => {
            return { ...item, ...{ value: item?.value?.serialNumber } };
          });
          setIsCreateToken(false);
        }

        if (currentStep?.involvedItem?.parentItem) {
          parentItemOptions = await generateTokenOptions(
            currentStep.involvedItem.parentItem?.tokenContract,
            isCreateStepWithLinkedMetadata,
          );

          if (
            currentStep?.involvedItem?.parentItem?.tokenContract?.tokenType ===
            CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
          ) {
            currentFormValidationSchema = currentFormValidationSchema.concat(
              Yup.object().shape({
                parentToken: Yup.string().required(
                  t('traceability.dgp.formErrors.parentTokenRequired'),
                ),
                parentTokenAmount: Yup.array().of(
                  Yup.object().shape({
                    amount: Yup.number()
                      .required(t('traceability.dgp.formErrors.parentTokenAmountRequired'))
                      .min(1, t('tokens.validation.minAmount'))
                      .integer(t('tokens.validation.integerValue'))
                      .test({
                        name: 'max',
                        message: t('traceability.dgp.formErrors.maxAmountExceeded'),
                        test: function(value) {
                          // eslint-disable-next-line
                          const parentItem = parentItemOptions.find(option => option.label === this.parent.tokenId);
                          return value <= parentItem?.value?.balance;
                        },
                      }),
                  }),
                ),
              }),
            );
            initialTokenValues.parentTokenAmount = isDraftMode ? draftValue?.parentTokenAmount : [];
          } else {
            currentFormValidationSchema = currentFormValidationSchema.concat(
              Yup.object().shape({
                parentToken: Yup.string().required(
                  t('traceability.dgp.formErrors.parentTokenRequired'),
                ),
              }),
            );
          }

          if (isDraftMode && isCreateStepWithLinkedMetadata) {
            initialTokenValues.parentToken = parentItemOptions.filter(
              ({ value: { _id: parentTokenID } }) =>
                draftValue?.linkedTokenDetails._id === parentTokenID,
            )?.[0]?.value;
          } else {
            initialTokenValues.parentToken = isDraftMode
              ? parentItemOptions.filter(({ label }) =>
                  draftValue.parentItemContentId?.includes(label),
                )
              : [];
          }
        }
        setStepFormData({
          initialValues: { ...initialTokenValues, ...initialMetadataValues },
          selectedStep: currentStep,
          isDraftMode,
          formValidationSchema: currentFormValidationSchema,
          ownItemOptions,
          parentItemOptions,
          selectedContract,
          itemContentDetails,
          tokenMetadataConfig,
          isCreateStepWithLinkedMetadata,
        });
        if (selectedIndex === '-1') {
          setSelectedIndex(currentStep?._id);
        }
      }
    } catch (err) {
      handleNotification(err?.message ? err.message : t('common.error'), 'error');
    } finally {
      setIsFormPreparing(false);
    }
  };

  const findStepAndPrepareMetadataForm = currentId => {
    stepRows.forEach(step => {
      if (currentId === step?._id) {
        prepareStepFormData(step);
      }
    });
  };

  useEffect(() => {
    if (stepRows && stepRows.length > 0) {
      if (draftValue && selectedIndex === '-1') {
        findStepAndPrepareMetadataForm(draftValue?.stepId);
      } else if (selectedIndex !== '-1') {
        findStepAndPrepareMetadataForm(selectedIndex);
      } else {
        setSelectedIndex(stepRows[0]?._id);
        findStepAndPrepareMetadataForm(stepRows[0]?._id);
      }
    }
  }, [stepRows, draftValue]);

  const handleStepClick = stepId => {
    setSelectedIndex(stepId);
    findStepAndPrepareMetadataForm(stepId);
  };

  useEffect(() => {
    if (currentWallet !== activeWallet) {
      history.push(TRACEABILITY_ROUTE);
    }
  }, [activeWallet]);

  const instanceName = instanceDetails?.content?.name;

  const generateBreadCrumbs = () => {
    const breadCrumbsData =
      activeOrganization?._id === instanceDetails?.content?.organizationId
        ? [
            {
              name: instanceName,
              path: `/traceability/instance-review/${instanceId}`,
            },
          ]
        : [
            {
              name: 'Back',
              path: `/traceability/overview`,
            },
          ];
    return <Breadcrumbs breadCrumbsData={breadCrumbsData} />;
  };

  const handleRegisterOnBlockchain = async () => {
    if (isLoading) return;
    let variables;
    let mintToken;
    let checkIfTokenExists;

    switch (tokenType) {
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
        mintToken = mintErc721TokenEth;
        checkIfTokenExists = checkIfErc721TokenAlreadyExist;
        break;
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
        mintToken = mintErc1155TokenEth;
        checkIfTokenExists = checkIfErc1155TokenAlreadyExist;
        break;
      default:
        handleNotification(t('traceability.dgp.formErrors.smartContractTypeNotSupported'), 'error');
        return;
    }

    if (isCreateToken) {
      variables = generateVariables();
      await mintToken(variables);
    } else {
      checkIfTokenExists({
        variables: {
          contractAddress: selectedFormData.contractAddress,
          tokenId: selectedFormData.token,
        },
      });
    }
  };

  const handleRegister = async formValues => {
    setSelectedFormData(formValues);
    setModalOpen(true);
  };

  const blockedDGP = message => {
    return (
      <div className={classes.portalLandingRoot}>
        <div className={classes.breadcrumbsContainer}>{generateBreadCrumbs()}</div>
        <Divider className={classes.divider} />
        <div className={classes.dgpContainer}>
          <Box className={classes.dgpHeaderRoot}>
            <DGPHeaderTitle
              instanceName={instanceName}
              subHeaderTitle={t('traceability.dgp.insertMetadata')}
            />
          </Box>
          <Divider className={classes.fieldDivider} />
          <Paper className={classes.accordionPaper}>
            <Typography className={classes.message}>
              <ErrorIcon className={classes.icon} />
              {message}
            </Typography>
          </Paper>
        </div>
      </div>
    );
  };

  if (errorLoadingInstance || instanceNotFound) return <InstanceError />;

  if (isDataLoading || !valueChainStatus) {
    return <PageLoader />;
  }

  if (valueChainStatus !== CONSTANTS.INSTANCE_STATUSES.ACTIVE) {
    const message =
      valueChainStatus === CONSTANTS.INSTANCE_STATUSES.PASSIVE ? (
        t('traceability.valueChainNotActive')
      ) : (
        <Trans i18nKey="traceability.valueChainNotFinalized">
          <Link
            className={classes.link}
            target="_blank"
            href={`/traceability/instance-review/${instanceId}`}
          >
            {t('traceability.valueChainNotFinalized')}
          </Link>
        </Trans>
      );
    return blockedDGP(message);
  }
  if (!stepRows?.length > 0) {
    return blockedDGP(t('traceability.missingSteps'));
  }

  return (
    <div className={classes.portalLandingRoot}>
      <div className={classes.breadcrumbsContainer}>{generateBreadCrumbs()}</div>
      <Divider className={classes.divider} />
      <div className={classes.dgpContainer}>
        <Box className={classes.dgpHeaderRoot}>
          <DGPHeaderTitle
            instanceName={instanceName}
            subHeaderTitle={t('traceability.dgp.insertMetadata')}
          />
          <div>
            {(isUserAuthToViewAllRecordsInDGP || isUserAuthToViewRecordInDGP) && (
              <Button variant="outlined" color="primary" onClick={handleHistoryNav}>
                {t('traceability.dgp.registeredData')}
              </Button>
            )}
          </div>
        </Box>
        <Divider className={classes.fieldDivider} />
        {!isUserAuthToInsertRecordInDGP ? (
          <Paper className={classes.accordionPaper}>
            <Typography className={classes.message}>
              <ErrorIcon className={classes.icon} ƒ />
              {t('traceability.forbiddenDGP')}
            </Typography>
          </Paper>
        ) : (
          <>
            <div>
              <Typography variant="h6" className={classes.stepListHeader}>
                {t('headers.steps')}
              </Typography>
            </div>
            <Box display="flex" flexGrow={1} overflow="auto">
              <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
                {stepRows.map(step => (
                  <StepListCard
                    handleStepClick={handleStepClick}
                    stepName={step.name}
                    key={step._id}
                    stepId={step._id}
                    selectedIndex={selectedIndex}
                    isFormPreparing={isFormPreparing}
                  />
                ))}
              </Box>

              <StepMetadataPanel
                handleRegister={handleRegister}
                stepFormData={stepFormData}
                setCurrentFormik={setCurrentFormik}
                isMintOrUpdateOngoing={isLoading}
                isFormPreparing={isFormPreparing}
              />
            </Box>
          </>
        )}
      </div>
      {!showFaucetModal && isModalOpen && (
        <>
          <PortalRegisterModal
            isModalOpen={isModalOpen}
            handleClose={handleClose}
            formData={selectedFormData}
            handleSubmit={handleRegisterOnBlockchain}
            loading={isLoading}
            smartContractType={tokenType}
          />
        </>
      )}
    </div>
  );
};

export default PortalLanding;
