import gql from 'graphql-tag';

export const STEP_DATA_TYPE_ATTRIBUTES = gql`
  fragment STEP_DATA_TYPE_ATTRIBUTES on StepDataType {
    _id
    name
    config
  }
`;

export const GET_STEP_DATA_TYPES = gql`
  query stepDataTypes {
    stepDataTypes {
      ...STEP_DATA_TYPE_ATTRIBUTES
    }
  }
  ${STEP_DATA_TYPE_ATTRIBUTES}
`;
