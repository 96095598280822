import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { Typography, Card } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNotification } from '../../../contexts/Shared/notification';
import {
  GET_ERC721_TOKEN_LIST,
  GET_SINGLE_TOKEN_CONTRACT,
} from '../../../graphql/Tokenization/token';
import PageLoader from '../PageLoader';

import {
  METADATA_STRUCTURE_RELATIONSHIP,
  METADATA_STRUCTURE_RELATIONSHIP_TYPE,
} from '../../../constants';

const useStyles = makeStyles(() => ({
  itemsFormRoot: {
    width: '100%',
  },
  accordionBodyRow: {
    width: '100%',
    display: 'flex',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '20%',
  },
  selectField: {
    minWidth: '10%',
    width: '100%',
    margin: '10px 10px 10px 0px',
  },
  description: {
    margin: '10px 0px 10px 0px',
  },
  accordion: {
    '& .MuiAccordionSummary-root': {
      padding: '0px',
      display: 'inline-flex',
    },
  },
  accordionSummary: {
    '& .MuiAccordion-root': {
      padding: '0px',
    },
  },
  accordionRoot: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  spanStyle: {
    color: 'blue',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  TokenListRoot: {},
}));

const LinkedItem = ({
  name,
  index,
  linkedTokenDetail,
  setFieldValue,
  contractCollection,
  isContractCollectionLoading,
  handleDelete,
  readOnly,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const parentArrayName = `${name}.linkedTokenDetails`;
  const fieldNames = {
    relationship: `linkedToken-${index}-relationship`,
    relationshipType: `linkedToken-${index}-relationshipType`,
    smartContract: `linkedToken-${index}-smartContract`,
    tokenId: `linkedToken-${index}-tokenId`,
    description: `linkedToken-${index}-description`,
  };
  const { handleNotification } = useNotification();
  const [tokenList, setTokenList] = useState([]);

  const emptyOption = {
    label: '',
    value: '',
    key: '',
  };
  const [selectedContract, setSelectedContract] = useState(emptyOption);
  const [selectedTokenId, setSelectedTokenId] = useState(emptyOption);

  const [getSingleTokenContract, { loading: isTokenLoading }] = useLazyQuery(
    GET_SINGLE_TOKEN_CONTRACT,
    {
      onCompleted: data => {
        setSelectedContract({
          label: data?.getSingleTokenContract?.contractName,
          value: linkedTokenDetail?.smartContract,
          key: linkedTokenDetail?.smartContract,
        });
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [getErc721TokenList, { loading: tokenListLoading }] = useLazyQuery(GET_ERC721_TOKEN_LIST, {
    onCompleted: data => {
      const list = data?.erc721TokenList?.map(token => {
        const option = {
          label: token.tokenId,
          value: token._id,
          key: token._id,
        };
        if (token._id === linkedTokenDetail.tokenId) {
          setSelectedTokenId(option);
        }
        return option;
      });

      setTokenList(list);
    },
    onError: error => {
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (linkedTokenDetail?.smartContract) {
      getErc721TokenList({ variables: { smartContractId: linkedTokenDetail?.smartContract } });
    }
  }, [linkedTokenDetail, contractCollection]);

  useEffect(() => {
    if (linkedTokenDetail?.smartContract) {
      getSingleTokenContract({ variables: { smartContractId: linkedTokenDetail?.smartContract } });
    }
  }, [getSingleTokenContract]);

  const relationship = [
    {
      label: t('tokens.metaDataStructure.ralationship.parent'),
      value: METADATA_STRUCTURE_RELATIONSHIP.PARENT,
    },
    {
      label: t('tokens.metaDataStructure.ralationship.child'),
      value: METADATA_STRUCTURE_RELATIONSHIP.CHILD,
    },
  ];

  const relationshipType = [
    {
      label: t('tokens.metaDataStructure.relatioshipType.input'),
      value: METADATA_STRUCTURE_RELATIONSHIP_TYPE.INPUT,
    },
    {
      label: t('tokens.metaDataStructure.relatioshipType.output'),
      value: METADATA_STRUCTURE_RELATIONSHIP_TYPE.OUTPUT,
    },
  ];

  const linkChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    if (!modifiedField) {
      throw new Error('Invalid field or value');
    }
    switch (modifiedField) {
      case fieldNames.relationship:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...linkedTokenDetail,
          relationship: newValue,
          relationshipType:
            newValue === METADATA_STRUCTURE_RELATIONSHIP.PARENT
              ? METADATA_STRUCTURE_RELATIONSHIP_TYPE.INPUT
              : METADATA_STRUCTURE_RELATIONSHIP_TYPE.OUTPUT,
          dirty: true,
        });
        break;
      case fieldNames.relationshipType:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...linkedTokenDetail,
          relationship:
            newValue === METADATA_STRUCTURE_RELATIONSHIP_TYPE.INPUT
              ? METADATA_STRUCTURE_RELATIONSHIP.PARENT
              : METADATA_STRUCTURE_RELATIONSHIP.CHILD,
          relationshipType: newValue,
          dirty: true,
        });
        break;
      case fieldNames.description:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...linkedTokenDetail,
          description: newValue,
          dirty: true,
        });
        break;
      default:
        break;
    }
  };

  const updateSmartContract = option => {
    setFieldValue(`${parentArrayName}.${index}`, {
      ...linkedTokenDetail,
      smartContract: option?.value,
      tokenId: '',
      dirty: true,
    });
    setSelectedContract(option);
    setSelectedTokenId(emptyOption);
  };

  const updateTokenId = option => {
    setFieldValue(`${parentArrayName}.${index}`, {
      ...linkedTokenDetail,
      tokenId: option?.value,
      dirty: true,
    });
    setSelectedTokenId(option);
  };
  const buildOptions = option => (
    <MenuItem key={option.value} value={option.value} type={option.key}>
      {option.label}
    </MenuItem>
  );

  const requestLoadingOption = () => [
    {
      label: t('common.loading'),
      value: t('common.loading'),
      key: t('common.loading'),
    },
  ];

  const requestLoadingOptionRender = () => (
    <>
      <CircularProgress size={20} thickness={20} className={classes.spinner} />
      {t('common.loading')}
    </>
  );

  if (isTokenLoading && tokenListLoading) {
    return (
      <Card variant="outlined" className={classes.TokenListRoot}>
        <PageLoader />
      </Card>
    );
  }

  return (
    <div>
      <div className={classes.contentHeader}>
        <Typography variant="button">Linked Token</Typography>
        {!readOnly && (
          <Tooltip title={t('tokens.validation.deleteLinkedToken')}>
            <IconButton>
              <DeleteIcon
                onClick={() => {
                  handleDelete(index);
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.itemsFormRoot}>
        <div className={classes.accordionBodyRow}>
          <TextField
            select
            name={fieldNames.relationship}
            label={t('tokens.relationship')}
            size="small"
            variant="outlined"
            value={linkedTokenDetail.relationship}
            onChange={linkChangeHandler}
            disabled={readOnly}
            classes={{
              root: classes.selectField,
            }}
            InputProps={{
              classes: {
                input: classes.itemNameInput,
              },
            }}
          >
            {relationship?.map(buildOptions)}
          </TextField>

          <TextField
            select
            size="small"
            variant="outlined"
            label={t('tokens.relationshipType')}
            name={fieldNames.relationshipType}
            onChange={linkChangeHandler}
            value={linkedTokenDetail?.relationshipType}
            disabled={readOnly}
            classes={{
              root: classes.selectField,
            }}
          >
            {relationshipType?.map(buildOptions)}
          </TextField>
        </div>
        <div className={classes.accordionBodyRow}>
          <Autocomplete
            className={classes.selectField}
            options={isContractCollectionLoading ? requestLoadingOption() : contractCollection}
            name={fieldNames.smartContract}
            disableClearable
            getOptionLabel={option => (option?.label ? option.label : selectedContract?.label)}
            onChange={(event, option) => updateSmartContract(option)}
            value={selectedContract}
            renderOption={option => (
              <div key={option.key}>
                {isContractCollectionLoading ? requestLoadingOptionRender() : option.label}
              </div>
            )}
            disabled={readOnly}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tokens.tokenContractName')}
                variant="outlined"
                size="small"
                classes={{
                  root: classes.selectField,
                }}
              />
            )}
          />
          <Autocomplete
            className={classes.selectField}
            options={tokenListLoading ? requestLoadingOption() : tokenList}
            name={fieldNames.tokenId}
            getOptionLabel={option => (option?.label ? option.label : selectedTokenId?.label)}
            onChange={(event, option) => updateTokenId(option)}
            value={selectedTokenId}
            disableClearable
            disabled={readOnly}
            renderOption={option => (
              <div key={option.key}>
                {tokenListLoading ? requestLoadingOptionRender() : option.label}
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tokens.tokenId')}
                variant="outlined"
                size="small"
                classes={{
                  root: classes.selectField,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.accordionRoot}>
        <div className={classes.accordionSummary}>
          <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <span className={classes.spanStyle}>
                  <u>{readOnly ? t('common.description') : t('tokens.addDescription')}</u>
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                name={fieldNames.description}
                label={!readOnly ? t('tokens.writeHere') : ''}
                size="small"
                variant="outlined"
                multiline
                rows={3}
                disabled={readOnly}
                className={classes.selectField}
                value={linkedTokenDetail?.description}
                onChange={linkChangeHandler}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

LinkedItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  linkedTokenDetail: PropTypes.shape({
    smartContract: PropTypes.string.isRequired,
    tokenId: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    relationshipType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  contractCollection: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDelete: PropTypes.func.isRequired,
  isContractCollectionLoading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

LinkedItem.defaultProps = {
  readOnly: false,
};

export default LinkedItem;
