import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const SignButton = ({ onSign, className, label, testId, disabled }) => {
  return (
    <Button
      className={className}
      variant="contained"
      color="secondary"
      onClick={() => onSign()}
      data-testid={testId}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

SignButton.propTypes = {
  onSign: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

SignButton.defaultProps = {
  onSign: null,
  className: null,
  testId: '',
  disabled: false,
};

export default SignButton;
