import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  mandatoryFieldMarker: {
    color: theme.palette.error.main,
    padding: 0,
    margin: 0,
  },
  hidden: {
    visibility: 'hidden',
    padding: 0,
    margin: 0,
  },
}));

const MandatoryLabel = ({ text, error }) => {
  const classes = useStyles();

  return error ? (
    <>
      {text}
      <span className={classes.mandatoryFieldMarker}>*</span>
    </>
  ) : (
    <>
      {text}
      <span className={classes.hidden}>*</span>
    </>
  );
};

MandatoryLabel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  error: PropTypes.bool,
};

MandatoryLabel.defaultProps = {
  error: false,
};

export default MandatoryLabel;
