import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import PropTypes from 'prop-types';
import { CONSTANTS } from '../../../constants';
import { convertToJsonStr, convertToArray } from '../../../utils';
import FormikSwitch from '../../Shared/FormikSwitch';
import MultiKeyArray from './MultiKeyArray';

const useStyles = makeStyles(theme => ({
  rawModeSwitch: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  jsonArea: {
    display: 'grid',
  },
}));

const CertificateMetadataControl = ({ form: { values, setFieldValue, setFieldError } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const JSON_KEY_LIMIT = CONSTANTS.JSON_PAIR_LIMIT.TOKEN_METADATA;
  const viewSwitchOptions = {
    trueLabel: t('common.jsonMode'),
    falseLabel: t('common.structuredMode'),
  };
  const changeInputMode = () => {
    const { rawMode } = values;
    if (!rawMode) {
      try {
        const parsedString = convertToJsonStr(values.fieldArray);

        setFieldValue(`content`, parsedString);
      } catch (err) {
        setFieldError(`fieldArray`, err.message);
        throw err;
      }
    } else {
      try {
        const parsedArray = convertToArray(values.content);

        setFieldValue(`fieldArray`, parsedArray);
      } catch (err) {
        setFieldError(`content`, err.message);
        throw err;
      }
    }
  };

  return (
    <div className={classes.jsonArea}>
      <Field
        label="Mode"
        name="rawMode"
        color="primary"
        className={classes.rawModeSwitch}
        component={FormikSwitch}
        beforeChange={changeInputMode}
        labels={viewSwitchOptions}
      />

      {!values.rawMode && (
        <Field name="fieldArray" component={MultiKeyArray} limit={JSON_KEY_LIMIT} />
      )}
      {values.rawMode && (
        <Field
          name="content"
          component={TextField}
          className={classes.contentField}
          multiline
          rows={4}
        />
      )}
    </div>
  );
};

CertificateMetadataControl.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    values: PropTypes.shape({
      rawMode: PropTypes.bool,
      content: PropTypes.string,
      fieldArray: PropTypes.array,
    }),
  }).isRequired,
};

export default CertificateMetadataControl;
