import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  successChip: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
  failedChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  progressChip: {
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const NotarizeStatusChip = ({ statusField }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  switch (statusField) {
    case CONSTANTS.CONTRACT_STATUSES.FAILED:
      return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
    case CONSTANTS.CONTRACT_STATUSES.PENDING:
      return (
        <Chip label={t('notarization.statusCodes.pending')} className={classes.progressChip} />
      );
    case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
      return (
        <Chip label={t('notarization.statusCodes.completed')} className={classes.successChip} />
      );
    default:
      return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
  }
};

NotarizeStatusChip.propTypes = {
  statusField: PropTypes.string,
};

NotarizeStatusChip.defaultProps = {
  statusField: '',
};

export default NotarizeStatusChip;
