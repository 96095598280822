import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import { filter, includes, orderBy } from 'lodash';
import PageLoader from '../../components/Shared/PageLoader';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';
import ContractListCard from '../../components/Tokenization/ContractListCard';
import ContractPanel from '../../components/Tokenization/ContractPanel';
import DeployERCTokenContract from '../../components/Tokenization/DeployERCTokenContract';
import BulkMinting from './BulkMinting';
import BulkTransfer from './BulkTransfer';

const useStyles = makeStyles(theme => ({
  TokenListRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  listHeaderArea: {
    marginTop: theme.spacing(1),
    boxShadow: '0 -1.75px 0 0 rgba(0,0,0,.04)',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  searchBox: {
    minWidth: '30%',
  },
  tokenFooterArea: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  zeroTokensContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  zeroMainBanner: {
    marginTop: '20vh',
    fontWeight: '400',
  },
  zeroDescLine: {
    marginTop: '5px',
  },
  zeroStartBanner: {
    marginTop: '2rem',
    fontWeight: 'bold',
  },
  zeroDeployButton: {
    marginTop: '2rem',
  },
}));

const ContractForm = ({
  setSelectedTokens,
  purchaseOrder,
  isBuyer,
  materialId,
  updateBatchMetadataHandler,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    tokenContracts,
    setTokenContracts,
    totalTokenCount,
    fetchMoreTokenContractsByPage,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setSelectedTokenBatchID,
    setTokenBatches,
  } = useTokenContext();
  const { showFaucetModal } = useNotification();
  const [pageLoading, setPageLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('-1');
  const [searchText, setSearchText] = useState('');
  const [deployFormOpen, setDeployFormOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [bulkMintErc721FormOpen, setBulkMintErc721FormOpen] = useState(false);
  const [bulkTransferErc1155Data, setBulkTransferErc1155Data] = useState({
    display: false,
    tokens: [],
    processType: '',
  });

  const rowsPerPageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
  ];

  const handleDeploy = () => {
    setDeployFormOpen(true);
  };

  const resetContractPanel = () => {
    setIsMinimized(false);
    setSelectedIndex('-1');
    setSelectedToken(null);
  };

  const handlePageChange = async (event, value) => {
    resetContractPanel();
    setPageLoading(true);
    const moreTokensResponse = await fetchMoreTokenContractsByPage({
      variables: {
        pageNumber: value,
        contractsPerPage: rowsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...fetchMoreResult };
      },
    });

    const newContracts = [...moreTokensResponse.data.getTokenContractsByPage.tokenContracts];
    setTokenContracts(newContracts);
    setPage(value);
    setPageLoading(false);
  };

  const handleRowsPerPageChange = event => {
    resetContractPanel();
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  let contracts = tokenContracts?.map(el => {
    return {
      _id: el.contract?._id,
      name: el.contract?.contractName,
      symbol: el.tokenSymbol,
      address: el.contract?.contractAddress,
      type: el.contract?.tokenType,
      balance: el.balance,
      status: el.contract?.transaction?.status,
    };
  });

  if (searchText) {
    contracts = filter(
      contracts,
      el =>
        includes(el?.name?.toLowerCase(), searchText?.toLowerCase()) ||
        includes(el?.symbol?.toLowerCase(), searchText?.toLowerCase()),
    );
  }

  contracts = orderBy(contracts, ['_id'], ['desc']);
  const pagedContracts = contracts;

  const handleMinimize = tokenId => {
    if (selectedIndex === tokenId) {
      resetContractPanel();
    } else {
      setIsMinimized(true);
      setSelectedIndex(tokenId);
      setSelectedToken(null);
      setSelectedTokenBatchID(null);
      setTokenBatches([]);
    }
  };

  const replicateClickActiveContract = tokenId => {
    setIsMinimized(true);
    setSelectedIndex(tokenId);
    setSelectedToken(null);
  };

  const closeForm = () => {
    setDeployFormOpen(false);
  };

  const contractValidationForBuyer = contract => {
    if (isBuyer) {
      return contract._id === purchaseOrder.materials[0]?.smartContractId;
    }
    return true;
  };

  if (bulkMintErc721FormOpen) {
    return (
      <BulkMinting
        hideBulkMinting={setBulkMintErc721FormOpen}
        displayActiveContract={replicateClickActiveContract}
      />
    );
  }

  if (bulkTransferErc1155Data?.display) {
    return (
      <BulkTransfer
        setBulkTransferErc1155Data={setBulkTransferErc1155Data}
        selectedTokensForTransfer={bulkTransferErc1155Data.tokens}
        displayActiveContract={replicateClickActiveContract}
        processType={bulkTransferErc1155Data.processType}
      />
    );
  }
  if (pageLoading) {
    return (
      <div className={classes.TokenListRoot}>
        <PageLoader />
      </div>
    );
  }

  if (tokenContracts?.length < 1 && !isBuyer) {
    return (
      <>
        <div className={classes.zeroTokensContainer}>
          <Typography variant="h2" className={classes.zeroMainBanner}>
            {t('tokens.zeroTokens.mainBanner')}
          </Typography>
          <Typography variant="body1" className={classes.zeroDescLine}>
            {t('tokens.zeroTokens.descCaption')}
          </Typography>
          <Typography variant="h5" className={classes.zeroStartBanner} id="welcome_caption">
            {t('tokens.zeroTokens.startCaption')}
          </Typography>
          <Button
            className={classes.zeroDeployButton}
            variant="contained"
            color="secondary"
            onClick={handleDeploy}
          >
            {t('tokens.deployContract')}
          </Button>
        </div>
        {!showFaucetModal && (
          <>
            <DeployERCTokenContract open={deployFormOpen} closeModal={closeForm} />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className={classes.TokenListRoot}>
        <div className={classes.listHeaderArea}>
          <TextField
            className={classes.searchBox}
            id="search-tokens"
            variant="outlined"
            placeholder={t('tokens.searchContracts')}
            value={searchText}
            onChange={event => {
              setSearchText(event?.target?.value);
              if (event?.target?.value && isMinimized) {
                resetContractPanel();
              }
              setPage(1);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="large" color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box display="flex" flexDirection="column" flexGrow={1} mt={3} overflow="hidden">
          <Box display="flex" flexGrow={1} overflow="auto">
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              overflow="auto"
              id="contract_list"
            >
              {pagedContracts.map(contract => (
                <ContractListCard
                  isMinimized={isMinimized}
                  contractDetails={contract}
                  key={contract._id}
                  handleMinimize={handleMinimize}
                  selectedIndex={selectedIndex}
                  contractDisable={
                    purchaseOrder?.materials?.length > 0 &&
                    purchaseOrder.materials[materialId].smartContractId
                      ? contract._id.toString() !==
                        purchaseOrder.materials[materialId].smartContractId?.toString() // update this when multiple sku
                      : false
                  }
                  hideContract={purchaseOrder ? contractValidationForBuyer(contract) : true}
                  testId={`contractCard-${contract._id}`}
                />
              ))}
            </Box>
            {selectedIndex !== '-1' && (
              <ContractPanel
                selectedIndex={selectedIndex}
                handlePanelClose={resetContractPanel}
                selectedToken={selectedToken}
                setSelectedToken={setSelectedToken}
                isMultipleSelection={!!purchaseOrder}
                getSelectedTokens={setSelectedTokens}
                preSelectTokens={purchaseOrder?.materials}
                purchaseOrder={purchaseOrder}
                hideTokens={isBuyer}
                materialId={materialId}
                bulkMintingHandler={setBulkMintErc721FormOpen}
                bulkTransferHandler={setBulkTransferErc1155Data}
                updateBatchMetadataHandler={updateBatchMetadataHandler}
              />
            )}
          </Box>
        </Box>
        <div className={classes.tokenFooterArea}>
          <Select
            labelId="contracts-per-page"
            id="contracts-per-page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            {rowsPerPageOptions.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              );
            })}
          </Select>
          <Pagination
            count={Math.ceil(totalTokenCount / rowsPerPage) || 1}
            page={page}
            onChange={handlePageChange}
            disabled={totalTokenCount < 1}
            variant="text"
          />
        </div>
      </div>
    </>
  );
};

ContractForm.propTypes = {
  setSelectedTokens: PropTypes.func,
  purchaseOrder: PropTypes.shape({
    purchaseOrderId: PropTypes.string.isRequired,
    materials: PropTypes.array.isRequired,
  }),
  materialId: PropTypes.number,
  isBuyer: PropTypes.bool,
  updateBatchMetadataHandler: PropTypes.func,
};

ContractForm.defaultProps = {
  setSelectedTokens: () => {},
  updateBatchMetadataHandler: () => {},
  purchaseOrder: null,
  isBuyer: false,
  materialId: null,
};

export default ContractForm;
