import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { METADATA_STRUCTURE_PERMISSIONS, TOKEN_METADATA_FIELDS } from '../../../constants';
import FormikRadioGroup from '../../Shared/FormikRadioGroup';

const useStyles = makeStyles(theme => ({
  hashModeFieldsRoot: {
    backgroundColor: theme.palette.primary.lightest,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerLine: {
    backgroundColor: theme.palette.primary.lighter,
  },
}));

const HashedModeFields = ({ metaDataTypes }) => {
  const classes = useStyles();

  if (!metaDataTypes) {
    return <></>;
  }

  const typeOptions = filter(
    metaDataTypes,
    metaDataType =>
      metaDataType.key === TOKEN_METADATA_FIELDS.FREE_TEXT ||
      metaDataType.key === TOKEN_METADATA_FIELDS.JSON,
  ).map(filteredType => ({
    label: filteredType.key,
    value: filteredType.value,
  }));

  const permissionOptions = [
    {
      label: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
      value: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
    },
    {
      label: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
      value: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
    },
  ];

  return (
    <div className={classes.hashModeFieldsRoot}>
      <Field
        component={FormikRadioGroup}
        name="hashModeType"
        options={typeOptions}
        useI18n
        row
        radioProps={{
          color: 'primary',
        }}
      />

      <Divider orientation="vertical" flexItem classes={{ root: classes.dividerLine }} />

      <Field
        component={FormikRadioGroup}
        name="hashModePermission"
        options={permissionOptions}
        row
        radioProps={{
          color: 'primary',
        }}
      />
    </div>
  );
};

HashedModeFields.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  metaDataTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

HashedModeFields.defaultProps = {
  metaDataTypes: null,
};

export default HashedModeFields;
