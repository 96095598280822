import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FileDropZone from '@eyblockchain/ey-ui/core/FileDropZone';
import { Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LocationFields from '../LocationFields';
import KeyArray from '../JsonViewKeyArray';
import FormikSwitch from '../FormikSwitch';
import { CONSTANTS, TOKEN_METADATA_FIELDS } from '../../../constants';
import { convertToJsonStr, convertToArray } from '../../../utils';
import LinkedTokenField from './LinkedTokenField';

const useStyles = makeStyles(theme => ({
  chipContainer: {
    display: 'flex',
    marginTop: '10px',
  },
  chip: {
    marginRight: '10px',
  },
  perChip: {
    marginRight: '10px',
    borderColor: '#DCE7F4',
    backgroundColor: '#DCE7F4',
  },
  mutableChip: {
    marginRight: '10px',
    borderColor: '#DCEDE1',
    backgroundColor: '#DCEDE1',
  },
  immutableChip: {
    marginRight: '10px',
    borderColor: '#F5DFDD',
    backgroundColor: '#F5DFDD',
  },
  selectField: {
    minWidth: '10%',
    width: '100%',
    marginTop: '10px',
  },
  measurementField: {
    display: 'flex',
  },
  measurementControl: {
    width: '25%',
    marginTop: '10px',
  },
  measurementLabel: {
    marginTop: '18px',
    paddingLeft: '10px',
    fontSize: 'medium',
  },
  fieldRoot: {
    marginTop: '2em',
  },
  switchBox: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  rawModeSwitch: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  jsonArea: {
    display: 'grid',
  },
}));

const MetadataFields = ({
  field: { name, value: metadataField },
  form: { errors, values, setFieldValue, setFieldError },
  fieldMetadataConfig,
  readOnly,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const JSON_KEY_LIMIT = CONSTANTS.JSON_PAIR_LIMIT.TOKEN_METADATA;

  const fieldItemChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;

    if (!modifiedField) {
      throw new Error(t('tokens.errors.invalidField'));
    }

    setFieldValue(name, newValue);
  };

  const booleanField = () => {
    const handleChange = () => {
      const newValue = metadataField !== true;
      setFieldValue(name, newValue);
    };

    return (
      <FormControlLabel
        control={
          <Field
            name={name}
            component={Switch}
            disabled={readOnly}
            onChange={handleChange}
            checked={metadataField}
            value={metadataField}
            color="primary"
          />
        }
        labelPlacement="end"
        label={metadataField ? t('tokens.yes') : t('tokens.no')}
      />
    );
  };

  const freeTextField = () => {
    return (
      <MUITextField
        name={name}
        label={!readOnly ? t('tokens.writeHere') : ''}
        size="small"
        variant="outlined"
        multiline
        rows={3}
        className={classes.selectField}
        value={metadataField || ''}
        onChange={fieldItemChangeHandler}
        error={errors && !!errors[name]}
        helperText={errors && errors[name]}
        data-testid="metaDataInput"
        disabled={readOnly}
      />
    );
  };

  const measurementField = () => {
    return (
      <div className={classes.measurementField}>
        <MUITextField
          name={name}
          label={t('tokens.measurement')}
          size="small"
          variant="outlined"
          disabled={readOnly}
          className={classes.measurementControl}
          value={metadataField || ''}
          onChange={fieldItemChangeHandler}
        />
        <div className={classes.measurementLabel}>
          {t(fieldMetadataConfig?.chosenMetadataOption)}
        </div>
      </div>
    );
  };

  const fileUploadField = () => {
    return <Field component={FileDropZone} name="documentUpload" />;
  };

  const locationField = () => {
    return (
      <LocationFields
        isMapDisabled={false}
        locationDetails={metadataField}
        setFieldValue={setFieldValue}
        readOnly={readOnly}
        name={name}
      />
    );
  };

  const jsonField = () => {
    const viewSwitchOptions = {
      trueLabel: t('common.jsonMode'),
      falseLabel: t('common.structuredMode'),
    };

    const changeInputMode = () => {
      const { rawMode } = values[name];
      if (!rawMode) {
        try {
          const parsedString = convertToJsonStr(values[name].fieldArray);

          setFieldValue(`${name}.content`, parsedString);
        } catch (err) {
          setFieldError(`${name}.fieldArray`, err.message);
          throw err;
        }
      } else {
        try {
          const parsedArray = convertToArray(values[name].content);

          setFieldValue(`${name}.fieldArray`, parsedArray);
        } catch (err) {
          setFieldError(`${name}.content`, err.message);
          throw err;
        }
      }
    };

    return (
      <div className={classes.jsonArea}>
        <Field
          label="Mode"
          name={`${name}.rawMode`}
          color="primary"
          className={classes.rawModeSwitch}
          component={FormikSwitch}
          beforeChange={changeInputMode}
          labels={viewSwitchOptions}
        />

        {!values[name].rawMode && (
          <Field
            name={`${name}.fieldArray`}
            component={KeyArray}
            readOnly={readOnly}
            limit={JSON_KEY_LIMIT}
          />
        )}
        {values[name].rawMode && (
          <Field
            name={`${name}.content`}
            component={TextField}
            disabled={readOnly}
            className={classes.contentField}
            multiline
            rows={4}
          />
        )}
      </div>
    );
  };

  const linkedToken = () => {
    return (
      <LinkedTokenField
        name={name}
        linkedTokens={metadataField}
        setFieldValue={setFieldValue}
        readOnly={readOnly}
      />
    );
  };

  const populateField = () => {
    switch (fieldMetadataConfig?.metadataType?.metadataTypeName) {
      case TOKEN_METADATA_FIELDS.BOOLEAN:
        return booleanField();
      case TOKEN_METADATA_FIELDS.FREE_TEXT:
        return freeTextField();
      case TOKEN_METADATA_FIELDS.MEASUREMENT:
        return measurementField();
      case TOKEN_METADATA_FIELDS.LOCATION:
        return locationField();
      case TOKEN_METADATA_FIELDS.FILE:
        return fileUploadField();
      case TOKEN_METADATA_FIELDS.JSON:
        return jsonField();
      case TOKEN_METADATA_FIELDS.LINKED_TOKEN:
        return linkedToken();
      default:
        return '';
    }
  };

  if (!fieldMetadataConfig) {
    return null;
  }

  return (
    <div className={classes.fieldRoot}>
      <Typography variant="h5">{fieldMetadataConfig?.metadataName}</Typography>
      {populateField()}
    </div>
  );
};

MetadataFields.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
    values: PropTypes.shape({}),
    setFieldError: PropTypes.func,
  }).isRequired,
  fieldMetadataConfig: PropTypes.shape({
    metadataType: PropTypes.shape({
      metadataTypeName: PropTypes.string.isRequired,
    }),
    chosenMetadataOption: PropTypes.string,
    metadataName: PropTypes.string,
    metadataPermission: PropTypes.string,
    metadataMutability: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};

MetadataFields.defaultProps = {
  fieldMetadataConfig: null,
  readOnly: false,
};

export default MetadataFields;
