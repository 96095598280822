import gql from 'graphql-tag';

export const TOKEN_HISTORY_ATTRIBUTES = gql`
  fragment TOKEN_HISTORY_ATTRIBUTES on TokenHistory {
    date
    transferReason
    transaction {
      transactionType
      createdAt
      transactionHash
      contractAddress
      status
      timestamp
      from
      to
    }
    organizationDetails {
      name
      wallets {
        blockchainType
        network
        address
      }
    }
    balance
    amount
    tokenType
  }
`;

export const GET_HISTORY_INFO_ERC721 = gql`
  query tokenHistoryByERC721TokenId($erc721Token: String!) {
    tokenHistoryByERC721TokenId(erc721Token: $erc721Token) {
      ...TOKEN_HISTORY_ATTRIBUTES
    }
  }
  ${TOKEN_HISTORY_ATTRIBUTES}
`;

export const GET_HISTORY_INFO_ERC1155 = gql`
  query tokenHistoryByERC1155TokenId($erc1155Token: String!) {
    tokenHistoryByERC1155TokenId(erc1155Token: $erc1155Token) {
      ...TOKEN_HISTORY_ATTRIBUTES
    }
  }
  ${TOKEN_HISTORY_ATTRIBUTES}
`;
