import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PaymentIcon from '@material-ui/icons/Payment';
import { useTranslation } from 'react-i18next';
import useDetailStyles from './detail';
import { computePercentage } from '../MtrOpenOrderSummary/tableUtils';

export const formatNumber = number => {
  const pattern = /(?!^)(?=(\d{3})+$)/g;
  return `${number}`.replace(pattern, ',');
};

const VolumeInfo = ({ volume, volumeReceived, volumeAccepted, volumeBilled, unitOfItem }) => {
  const detailsClasses = useDetailStyles();
  const { t } = useTranslation('mtr');

  return (
    <Grid container className={detailsClasses.data}>
      <Grid item xs={12}>
        <Typography className={detailsClasses.tableHead}>
          {`${t('pos.totalOrdered')} `}
          {formatNumber(volume)} {unitOfItem}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid direction="row" container style={{ color: 'gray' }}>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem' }}
          >
            <DoneIcon />
          </Grid>
          <Grid item xs={8}>
            {t('pos.totalReceived')}
            {volumeReceived} ({computePercentage(volumeReceived, volume)})
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid direction="row" container style={{ color: 'green' }}>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem' }}
          >
            <DoneAllIcon />
          </Grid>
          <Grid item xs={8}>
            {t('pos.totalAccepted')}
            {volumeAccepted} ({computePercentage(volumeAccepted, volume)})
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid direction="row" container style={{ color: '#336699' }}>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem' }}
          >
            <PaymentIcon />
          </Grid>
          <Grid item xs={8}>
            {t('pos.totalBilled')}
            {volumeBilled} ({computePercentage(volumeBilled, volume)})
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

VolumeInfo.defaultProps = {
  volume: 0,
  volumeReceived: 0,
  volumeAccepted: 0,
  volumeBilled: 0,
  unitOfItem: '',
};

VolumeInfo.propTypes = {
  volume: PropTypes.number,
  volumeReceived: PropTypes.number,
  volumeAccepted: PropTypes.number,
  volumeBilled: PropTypes.number,
  unitOfItem: PropTypes.string,
};

export default VolumeInfo;
