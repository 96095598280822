import gql from 'graphql-tag';

export const MY_ORGANIZATION_ATTRIBUTES = gql`
  fragment MY_ORGANIZATION_ATTRIBUTES on MyOrganization {
    _id
    auth0Id
    parentOrganizationId
    childOrganizationId
    type
    organizationId
    childOrganization {
      _id
      name
      address {
        street_line
        country
        city
        postal_code
        state
        phone
      }
      wallets {
        name
        network
        networkId
        blockchainType
        networkType
        address
      }
    }
  }
`;

export const ADD_ORGANIZATION = gql`
  mutation createMyOrganization($input: myOrganizationInput!) {
    createMyOrganization(input: $input) {
      ...MY_ORGANIZATION_ATTRIBUTES
    }
  }
  ${MY_ORGANIZATION_ATTRIBUTES}
`;
export const REMOVE_ORGANIZATION = gql`
  mutation removeOrganization($orgId: String!) {
    removeOrganization(orgId: $orgId)
  }
`;

export const GET_ORGANIZATIONS = gql`
  query myOrganizations {
    myOrganizations {
      ...MY_ORGANIZATION_ATTRIBUTES
    }
  }
  ${MY_ORGANIZATION_ATTRIBUTES}
`;

export const GET_ORGANIZATION_UPDATE = gql`
  subscription onNewOrganization {
    newOrganization {
      auth0Id
      childOrganizationId
      organizationId
    }
  }
`;
