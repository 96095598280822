import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import IsolatedMenu from '../Shared/IsolatedMenu';
import { CONSTANTS } from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { HIDE_DOCUMENT } from '../../graphql/Notarization/documents';
import { useNotarizationContext } from '../../contexts/Notarization/notarizationContext';
import { getBlockchainExplorerUrl } from '../../utils';

const DocumentMenu = ({ notarizedDoc, handleMoreBtnClick }) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const { selectedNotarizationContract, removeDocFromList } = useNotarizationContext();
  const { activeWallet } = useBecOrganizationContext();

  const [hideDocument] = useMutation(HIDE_DOCUMENT, {
    onCompleted: data => {
      if (data?.hideDocument) {
        removeDocFromList(data?.hideDocument?.documentId);
        handleNotification(t('notarization.documentDeleteSuccess'), 'success');
      }
    },
    onError: () => {
      handleNotification(t('notarization.documentDeleteFailed'), 'error');
    },
  });

  const menuItems = [
    {
      label: t('notarization.docMenu.viewMore'),
      icon: <VisibilityIcon />,
      clickAction: () => {
        handleMoreBtnClick(notarizedDoc);
      },
    },
  ];

  switch (notarizedDoc.transaction?.status) {
    case CONSTANTS.CONTRACT_STATUSES.FAILED:
      menuItems.push({
        label: t('notarization.docMenu.delete'),
        icon: <DeleteIcon />,
        clickAction: () => {
          hideDocument({
            variables: {
              documentId: notarizedDoc.documentId,
              contractId: selectedNotarizationContract._id,
            },
          });
        },
      });
      break;
    case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
      menuItems.push({
        label: t('notarization.docMenu.explore'),
        icon: <OpenInNewIcon />,
        clickAction: () => {
          window.open(
            `${getBlockchainExplorerUrl(activeWallet?.network)}/${
              notarizedDoc?.transaction?.transactionHash
            }`,
            '_blank',
          );
        },
      });
      break;

    default:
      break;
  }

  return <IsolatedMenu key={notarizedDoc?._id} menuItems={menuItems} />;
};

DocumentMenu.propTypes = {
  notarizedDoc: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    documentId: PropTypes.string,
    transaction: PropTypes.shape({
      status: PropTypes.string.isRequired,
      transactionHash: PropTypes.string.isRequired,
    }),
  }),
  handleMoreBtnClick: PropTypes.func.isRequired,
};

DocumentMenu.defaultProps = {
  notarizedDoc: null,
};

export default DocumentMenu;
