import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableHeadCell from '../Shared/TableHeadCell';
import TableActions from '../Shared/TableActions';
import { useTokenContext } from '../../contexts/Tokenization/token';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: 40,
    overflow: 'visible',
    boxShadow: '0px 0px 0px 0px',
    marginTop: theme.spacing(3),
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      borderLeft: '0.5rem solid rgba(247, 218, 11, 11)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tokentable: {
    width: '58%',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.lightest,
      width: '10%',
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tierComponentSubTable: {
    marginTop: 0,
    width: '50%',
  },
  assoicatedButton: {
    width: 120,
    fontSize: '10px !important',
  },
  assoicatedButtonFont: {
    fontSize: '10px !important',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  acceptedChip: {
    background: 'white',
    color: theme.palette.info.main,
    fontWeight: 300,
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
  },
  icon: {
    height: '15px',
    width: '15px',
    color: 'inherit',
  },
  activeChip: { background: 'rgb(22 134 54)', color: '#fff' },
  pendingChip: { background: '#FF9831' },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  countNumbers: {
    fontWeight: 'lighter',
    paddingLeft: '10px',
  },
  partiallyChip: {
    color: '#155CB4',
    borderColor: '#155CB4',
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  updatingMetadataChip: {
    border: '1px solid black',
    backgroundColor: 'unset',
  },
}));

const TokenBatchTable = ({ batchData, updateBatchMetadataHandler, isUserAuthToSetERCMetadata }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { selectedTokenBatchID, tokenBatchMetadataUpdating } = useTokenContext();

  const columns = [
    {
      name: 'batchUniqueNo',
      label: t('tokens.batchNumber'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return ` Batch ${value}`;
        },
      },
    },
    {
      name: 'status',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: (value, data) => {
          let labelStatus = t('common.minted');
          if (value.batchStatus === 'pending') {
            labelStatus = t('common.pending');
          } else if (value.batchStatus === 'action-required') {
            labelStatus = t('common.actionRequired');
          } else if (value.batchStatus === 'deploying') {
            labelStatus = t('common.deployingContract');
          } else if (value.batchStatus === 'in-active') {
            labelStatus = t('common.inactive');
          }
          return (data.rowData[1] &&
            data.rowData[1].batchStatus &&
            data.rowData[1].batchStatus === 'minted') ||
            tokenBatchMetadataUpdating ? (
            <div className={classes.status}>
              <Chip
                className={
                  value.batchStatus === 'minted' ? classes.activeChip : classes.pendingChip
                }
                label={labelStatus}
                icon={<CheckIcon className={classes.icon} />}
              />
              <span
                className={classes.countNumbers}
                variant="h6"
              >{`${value.successCount}/${value.totalCount}`}</span>
            </div>
          ) : (
            <div className={classes.associatedBox}>
              <Chip
                label={t('tokens.mintingInProgress', {
                  tokens: value.totalCount,
                })}
                variant="outlined"
                icon={<CircularProgress color="primary" size={15} thickness={2} />}
                classes={{
                  root: classes.partiallyChip,
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'batchNumber',
      label: t('tokens.metadataUpdate'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: (value, data) => {
          if (data.rowData[1].batchStatus && data.rowData[1].batchStatus === 'minted') {
            return (
              <TableActions
                options={[
                  {
                    buttonText:
                      data && data.rowData
                        ? `${t('tokens.updateMetadata')} (${data.rowData[1].successCount})`
                        : t('tokens.updateMetadata'),
                    label: t('tokens.updateMetadata'),
                    testId: `batch-${value}`,
                    handleClick: () => updateBatchMetadataHandler(true, data.rowData[3]),
                    diabled: !isUserAuthToSetERCMetadata,
                  },
                ]}
                testId={`batch-${value}`}
              />
            );
          }
          if (data.rowData[1].batchStatus && data.rowData[1].batchStatus === 'pending') {
            if (
              tokenBatchMetadataUpdating &&
              selectedTokenBatchID === data.rowData[3][0].tokenBatchId
            ) {
              return (
                <Chip
                  label={t('tokens.updatingMetadata')}
                  className={classes.updatingMetadataChip}
                  icon={
                    <CircularProgress
                      size={20}
                      thickness={20}
                      color="primary"
                      className={classes.loader}
                    />
                  }
                />
              );
            }
          }
          return <span>{t('tokens.pleaseWait')}</span>;
        },
      },
    },
    {
      name: 'metadataUpdate',
      label: t('tokens.batchNumber'),
      options: {
        display: false,
      },
    },
  ];

  const tokenColumns = [
    {
      name: 'tokenId',
      label: t('tokens.tokenId'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: (value, data) => {
          let labelStatus = t('common.minted');
          if (value === 'PENDING') {
            labelStatus = t('common.pending');
          } else if (value === 'ACTION-REQUIRED') {
            labelStatus = t('common.actionRequired');
          } else if (value === 'DEPLOYING') {
            labelStatus = t('common.deployingContract');
          } else if (value === 'IN-ACTIVE') {
            labelStatus = t('common.inactive');
          }

          return data.rowData[2] &&
            data.rowData[2].status &&
            data.rowData[2].status !== 'pending' ? (
            <div className={classes.status}>
              <Chip
                className={value === 'MINTED' ? classes.activeChip : classes.pendingChip}
                label={labelStatus}
                icon={<CheckIcon className={classes.icon} />}
              />
            </div>
          ) : (
            <div className={classes.associatedBox}>
              <Chip
                label={
                  data.rowData[2].transactionType === 'updateMetadata'
                    ? t('tokens.updatingMetaDataInProgress')
                    : t('tokens.mintingtokenInProgress')
                }
                variant="outlined"
                icon={<CircularProgress color="primary" size={15} thickness={2} />}
                classes={{
                  root: classes.partiallyChip,
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'latestTransaction',
      label: t('common.latestTransaction'),
      options: {
        display: false,
      },
    },
  ];

  const Row = props => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow data-testid={`batch-table-row-${row[0].replace(/ /g, '')}`}>
          <TableCell component="th" scope="row">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row[0]}
          </TableCell>
          <TableCell>{row[1]}</TableCell>
          <TableCell>{row[2]}</TableCell>
        </TableRow>
        {row[3] ? (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={2}>
                  <Table
                    className={classes.tokentable}
                    columns={tokenColumns}
                    data={row[3]}
                    options={{
                      pagination: false,
                      filter: false,
                      search: false,
                      searchOpen: false,
                      elevation: 0,
                      textLabels: {
                        body: {
                          noMatch: t('common.noMatchingRecords'),
                        },
                        pagination: {
                          rowsPerPage: t('common.rowsPerPage'),
                        },
                      },
                    }}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          ''
        )}
      </>
    );
  };

  Row.propTypes = {
    row: PropTypes.oneOfType([PropTypes.array]),
  };

  Row.defaultProps = {
    row: [],
  };

  return (
    <Table
      className={classes.table}
      columns={columns}
      data={batchData}
      options={{
        pagination: true,
        filter: false,
        search: false,
        searchOpen: false,
        customRowRender: data => data && data.length > 0 && <Row key={uniqid()} row={data} />,
        elevation: 0,
        textLabels: {
          body: {
            noMatch: t('common.noMatchingRecords'),
          },
        },
      }}
    />
  );
};

TokenBatchTable.propTypes = {
  batchData: PropTypes.arrayOf(PropTypes.shape({})),
  updateBatchMetadataHandler: PropTypes.func,
  isUserAuthToSetERCMetadata: PropTypes.bool.isRequired,
};

TokenBatchTable.defaultProps = {
  batchData: [],
  updateBatchMetadataHandler: () => {},
};

export default TokenBatchTable;
