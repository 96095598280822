import PropTypes from 'prop-types';
import React from 'react';
import PageLoader from '../../components/Shared/PageLoader';

const LoadingContentContainer = ({ content, loading }) => (loading ? <PageLoader /> : content());

LoadingContentContainer.propTypes = {
  content: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoadingContentContainer;
