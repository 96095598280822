import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import Paper from '@material-ui/core/Paper';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import * as Yup from 'yup';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { useTranslation } from 'react-i18next';
import {
  GET_MTR_PURCHASE,
  UPDATE_MTR_PURCHASES,
} from '../../../graphql/Procurement/mtrPurchaseOrder';
import LoadingComponent from '../DemandForecast/loaderContainer';
import useDetailStyles from './detail';
import useListStyles from '../MtrPurchaseOrders/list';
import colorMapping from './colorMap';
import VolumeInfo from './step';
import { ShipmentDetails } from '../MtrOpenOrderSummary';
import { displayDate, getPOReleaseLineNumber } from '../MtrOpenOrderSummary/tableUtils';
import EditModal from '../MtrOpenOrderSummary/EditModal';

export const CompanyInfo = ({ supplier, buyer, isSender }) => {
  const detailClasses = useDetailStyles();
  return (
    <Grid className={detailClasses.companyInfo}>
      {!isSender && <Grid className={detailClasses.companyName}>{buyer}</Grid>}
      {isSender && <Grid className={detailClasses.companyName}>{supplier}</Grid>}
    </Grid>
  );
};

export const StatusChip = ({ signature }) => {
  const detailClasses = useDetailStyles();
  const { t } = useTranslation('mtr');

  return signature ? (
    <Chip className={detailClasses.acknowledgedChip} label={t('pos.acknowledged')} />
  ) : (
    <Chip className={detailClasses.pendingChip} label={t('pos.notAcknowledged')} />
  );
};

StatusChip.propTypes = {
  signature: PropTypes.bool.isRequired,
};

CompanyInfo.defaultProps = {
  buyer: null,
  supplier: null,
};

CompanyInfo.propTypes = {
  buyer: PropTypes.string,
  supplier: PropTypes.string,
  isSender: PropTypes.bool.isRequired,
};

export const PoHead = ({ masterId, poId }) => {
  const detailClasses = useDetailStyles();
  const { t } = useTranslation('mtr');
  return (
    <Grid className={detailClasses.header} container direction="row" justify="space-between">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/purchaseOrders">
          {t('pos.poList')}
        </Link>
        {masterId && (
          <Link color="inherit" href={`/purchaseOrders?masterId=${masterId}`}>
            {masterId}
          </Link>
        )}
        {poId && (
          <Link href={`/purchaseOrders?poId=${poId}`} color="inherit">
            {poId}
          </Link>
        )}
      </Breadcrumbs>
    </Grid>
  );
};

PoHead.defaultProps = {
  poId: null,
  masterId: null,
};

PoHead.propTypes = {
  // background: PropTypes.string.isRequired,
  poId: PropTypes.string,
  masterId: PropTypes.string,
  // customizedStatus: PropTypes.string.isRequired,
};

export default () => {
  const history = useHistory();
  const detailsClasses = useDetailStyles();
  const listClasses = useListStyles();
  const { poId } = queryString.parse(history.location.search);
  const { t } = useTranslation('mtr');

  const { loading, data, refetch } = useQuery(GET_MTR_PURCHASE, {
    variables: { foreignPurchaseNumber: poId },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <LoadingComponent />;
  }

  if (!data.purchaseOrderMtr) {
    return t('pos.poNotFound');
  }

  const {
    purchaseOrderMtr: {
      isSender,
      supplementInfo: {
        partNumber,
        foreignPurchaseNumberMaster,
        customizedStatus,
        unitOfItem,
        volumeAccepted,
        volumeBilled,
        volumeReceived,
        volumeRejected,
        //
        countryOrigin,
        plantLocation,
        manufacturer,
        sparesCategory,
        //
      },
      supplier: {
        company: { name: supplierName },
      },
      buyer: {
        company: { name: buyerName },
      },
      supplierInput,
      volume,
      material: itemCode,
      poHeader: {
        supplier: { signature, acknowledgedAt },
        elapsedSinceCreation,
      },
      sku: {
        description,
        supplierSku,
        supplementInfo: {
          countryOrigin: countryOrigin2,
          plantLocation: plantLocation2,
          manufacturer: manufacturer2,
          sparesCategory: sparesCategory2,
        },
      },
    },
  } = data;

  const { poNum, releaseNum, lineNum } = getPOReleaseLineNumber(poId);

  const pendingPeriod = elapsedSinceCreation && Math.ceil(elapsedSinceCreation / 36000 / 24);

  const Details = () => {
    const DetailsInfo = () => {
      return (
        <Grid container className={detailsClasses.data}>
          <Grid item xs={6}>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.poNum')}
              </Grid>
              <Grid item xs={6}>
                {poNum}
              </Grid>
            </Grid>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.releaseNum2')}
              </Grid>
              <Grid item xs={6}>
                {releaseNum}
              </Grid>
            </Grid>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.lineNum2')}
              </Grid>
              <Grid item xs={6}>
                {lineNum}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.itemCode')}
              </Grid>
              <Grid item xs={6}>
                {itemCode}
              </Grid>
            </Grid>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.supplierPartNumber')}
              </Grid>
              <Grid item xs={6}>
                {partNumber || supplierSku}
              </Grid>
            </Grid>
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={6} className={detailsClasses.rowHead}>
                {t('pos.UOP')}
              </Grid>
              <Grid item xs={6}>
                {unitOfItem}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    };
    const Attachment = () => {
      return (
        <Grid className={detailsClasses.data}>
          <Grid direction="row" container className={detailsClasses.row}>
            <Grid item xs={3} className={detailsClasses.rowHead}>
              {t('pos.poAttachment')}
            </Grid>
            <Grid item xs={3} className={detailsClasses.rowHead}>
              {t('pos.poDescription')}
            </Grid>
            <Grid item xs={6} className={detailsClasses.rowHead}>
              {t('pos.poValue')}
            </Grid>
          </Grid>
          <Grid direction="row" container className={detailsClasses.row}>
            <Grid item xs={3}>
              {t('pos.poSupplement')}
            </Grid>
            <Grid item xs={3}>
              {t('pos.poCOO')}
            </Grid>
            <Grid item xs={6}>
              {countryOrigin2}
            </Grid>
          </Grid>
          <Grid direction="row" container className={detailsClasses.row}>
            <Grid item xs={3}>
              {t('pos.poSupplement')}
            </Grid>
            <Grid item xs={3}>
              {t('pos.poManufacturer')}
            </Grid>
            <Grid item xs={6}>
              {manufacturer2}
            </Grid>
          </Grid>
          <Grid direction="row" container className={detailsClasses.row}>
            <Grid item xs={3}>
              {t('pos.poSupplement')}
            </Grid>
            <Grid item xs={3}>
              {t('pos.poPLocation')}
            </Grid>
            <Grid item xs={6}>
              {plantLocation2}
            </Grid>
          </Grid>
          <Grid direction="row" container className={detailsClasses.row}>
            <Grid item xs={3}>
              {t('pos.poSupplement')}
            </Grid>
            <Grid item xs={3}>
              {t('pos.poSCategory')}
            </Grid>
            <Grid item xs={6}>
              {sparesCategory2}
            </Grid>
          </Grid>
          {countryOrigin && (
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={3}>
                {t('pos.poToSupplier')}
              </Grid>
              <Grid item xs={3}>
                {t('pos.poCOO')}
              </Grid>
              <Grid item xs={6}>
                {countryOrigin}
              </Grid>
            </Grid>
          )}
          {manufacturer && (
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={3}>
                {t('pos.poToSupplier')}
              </Grid>
              <Grid item xs={3}>
                {t('pos.poManufacturer')}
              </Grid>
              <Grid item xs={6}>
                {manufacturer}
              </Grid>
            </Grid>
          )}
          {plantLocation && (
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={3}>
                {t('pos.poToSupplier')}
              </Grid>
              <Grid item xs={3}>
                {t('pos.poPLocation')}
              </Grid>
              <Grid item xs={6}>
                {plantLocation}
              </Grid>
            </Grid>
          )}
          {sparesCategory && (
            <Grid direction="row" container className={detailsClasses.row}>
              <Grid item xs={3}>
                {t('pos.poToSupplier')}
              </Grid>
              <Grid item xs={3}>
                {t('pos.poSCategory')}
              </Grid>
              <Grid item xs={6}>
                {sparesCategory}
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    };

    const SupplierInputContainer = () => {
      const [showPODetailEditModal, setShowPODetailEditModal] = useState(false);
      const [updatePo, { loading: updatePoLoading }] = useMutation(UPDATE_MTR_PURCHASES);
      const fieldArray = [
        {
          name: 'poNum',
          label: t('pos.poId3'),
          gridSize: 4,
          type: 'info',
        },
        {
          name: 'releaseNum',
          label: t('pos.poReleaseNum'),
          gridSize: 4,
          type: 'info',
        },
        {
          name: 'lineNum',
          label: t('pos.poLineNum'),
          gridSize: 4,
          type: 'info',
        },
        {
          name: 'separator-0',
          type: 'separator',
        },
        {
          name: 'hasRawMaterial',
          label: t('pos.poHasRawMaterial'),
          type: 'text',
          gridSize: 4,
        },
        {
          name: 'inventory',
          label: t('pos.poInventory'),
          type: 'number',
          gridSize: 4,
          min: 0,
          max: Infinity,
        },
        {
          name: 'leadtime',
          label: t('pos.poLeadtime'),
          type: 'number',
          gridSize: 4,
          min: 0,
          max: Infinity,
        },
        {
          name: 'materialStartDate',
          label: t('pos.poProductionReady'),
          type: 'date',
          gridSize: 4,
        },
        {
          name: 'productionStartDate',
          label: t('pos.poProductionStart'),
          type: 'date',
          gridSize: 4,
        },
        {
          name: 'productionCompleteDate',
          label: t('pos.poProductionComplete'),
          type: 'date',
          gridSize: 4,
        },
      ];

      const validationSchema = Yup.object().shape({
        inventory: Yup.number()
          .min(0, t('pos.nonNegativeNumber'))
          .integer(t('pos.integer'))
          .nullable(true),
        leadtime: Yup.number()
          .min(0, t('pos.nonNegativeNumber'))
          .integer(t('pos.integer'))
          .nullable(true),
        productionCompleteDate: Yup.date()
          .typeError(t('pos.invalidInput'))
          .nullable(true),
        productionStartDate: Yup.date()
          .typeError(t('pos.invalidInput'))
          .nullable(true),
        materialStartDate: Yup.date()
          .typeError(t('pos.invalidInput'))
          .nullable(true),
      });

      const makeEditFormPayload = input => {
        const formmatedInput = {
          ...input,
          hasRawMaterial: input.hasRawMaterial.toString().length ? input.hasRawMaterial : null,
          inventory: input.inventory.toString().length ? input.inventory : null,
          leadtime: input.leadtime.toString().length ? input.leadtime : null,
        };

        delete formmatedInput.poNum;
        delete formmatedInput.releaseNum;
        delete formmatedInput.lineNum;

        return {
          supplementInfo: { foreignPurchaseNumber: poId },
          supplierInput: formmatedInput,
        };
      };

      const {
        hasRawMaterial,
        inventory,
        leadtime,
        materialStartDate,
        productionCompleteDate,
        productionStartDate,
      } = supplierInput || {};

      const initialValues = {
        poNum,
        releaseNum,
        lineNum,
        hasRawMaterial,
        inventory,
        leadtime,
        materialStartDate,
        productionCompleteDate,
        productionStartDate,
      };

      const timelineDotClass = labelDate => {
        if (labelDate > moment().unix()) {
          return 'secondary';
        }
        return 'primary';
      };

      const { __typename, ...filtered } = supplierInput || {};
      const isSupplierInputEmpty = filtered ? Object.values(filtered).every(i => i === null) : true;

      return (
        <Grid style={{ width: '100%' }}>
          <Grid container justify="space-between">
            <Grid item>
              {!supplierInput || isSupplierInputEmpty ? t('pos.noProdSchedule') : ''}
            </Grid>
            <Grid item>
              <IconButton
                name="icon-button-edit"
                color="primary"
                component="span"
                size="small"
                onClick={() => {
                  setShowPODetailEditModal(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
          {supplierInput && !isSupplierInputEmpty && (
            <>
              <Grid direction="row" container className={detailsClasses.row}>
                <Grid item xs={5} className={detailsClasses.rowNumber}>
                  {t('pos.poHasRawMaterial')}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  {hasRawMaterial ?? t('pos.noData')}
                </Grid>
              </Grid>
              <Grid direction="row" container className={detailsClasses.row}>
                <Grid item xs={5} className={detailsClasses.rowNumber}>
                  {t('pos.poInventory')}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  {inventory ?? t('pos.noData')}
                </Grid>
              </Grid>
              <Grid direction="row" container className={detailsClasses.row}>
                <Grid item xs={5} className={detailsClasses.rowNumber}>
                  {t('pos.poLeadtime')}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  {leadtime ?? t('pos.noData')}
                </Grid>
              </Grid>
              <Grid direction="row" container>
                <Timeline className={detailsClasses.timeline}>
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography className={detailsClasses.timelineLabel}>
                        {t('pos.poProductionReady')}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={timelineDotClass(materialStartDate)} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        {displayDate({ value: materialStartDate }) || t('pos.noData')}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography className={detailsClasses.timelineLabel}>
                        {t('pos.poProductionStart')}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={timelineDotClass(productionStartDate)} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        {displayDate({ value: productionStartDate }) || t('pos.noData')}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography className={detailsClasses.timelineLabel}>
                        {t('pos.poProductionComplete') || t('pos.noData')}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={timelineDotClass(productionCompleteDate)} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        {displayDate({ value: productionCompleteDate }) || t('pos.noData')}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Grid>
            </>
          )}
          <EditModal
            action={updatePo}
            makePayload={makeEditFormPayload}
            open={showPODetailEditModal}
            onClose={() => setShowPODetailEditModal(false)}
            isLoading={updatePoLoading}
            fieldArray={fieldArray}
            refetch={refetch}
            module="podetail"
            header={t('pos.updatePOHeader')}
            validationSchema={validationSchema}
            initValues={initialValues}
          />
        </Grid>
      );
    };
    return (
      <Grid>
        <Grid container direction="row" className={detailsClasses.details} spacing={1}>
          <Grid item xs={8} className={detailsClasses.left}>
            <Paper>
              <Grid className={detailsClasses.tableHead}>{t('pos.poDetails')}</Grid>
              <Grid className={detailsClasses.skuDesc}>{description}</Grid>
              <Grid>
                <div>
                  <DetailsInfo />
                  <hr />
                  <Attachment />
                </div>
              </Grid>
            </Paper>
            <Paper>
              <Grid>
                <VolumeInfo
                  {...{
                    volume,
                    volumeAccepted,
                    volumeBilled,
                    volumeReceived,
                    volumeRejected,
                    unitOfItem,
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4} className={detailsClasses.right}>
            <Paper className={detailsClasses.section}>
              <Grid className={detailsClasses.tableHead}>{t('pos.supplierResponse')}</Grid>
              <Accordion defaultExpanded={!signature} className={detailsClasses.section}>
                <AccordionSummary
                  expandIcon={
                    !signature && <ExpandMoreIcon className={detailsClasses.attentionIcon} />
                  }
                  aria-controls="panel1a-content"
                  id="Supplier acknowledgement"
                >
                  <Typography className={detailsClasses.dataTitle}>
                    {signature && (
                      <span style={{ color: 'green' }}>
                        {t('pos.acknowledgedOnTime', {
                          acknowledgeTime: dayjs(acknowledgedAt * 1000).format('YYYY-MM-DD'),
                        })}
                      </span>
                    )}
                    {!signature && (
                      <span style={{ color: 'red' }}>
                        {t('pos.notAcknowledgedOnTime', { pendingPeriod })}
                      </span>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>
                    {signature ? (
                      <span />
                    ) : (
                      <Grid container>
                        <span style={{ marginBottom: '2rem' }}>
                          {isSender ? t('pos.detailConfirmY') : t('pos.detailConfirmN')}
                        </span>
                        <Grid
                          container
                          onClick={() =>
                            history.push(`/purchaseOrders?masterId=${foreignPurchaseNumberMaster}`)
                          }
                        >
                          <Grid item xs={1}>
                            <OpenInNewIcon style={{ cursor: 'pointer' }} />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography style={{ textDecoration: 'underline' }}>
                              {t('pos.visitPoHeader')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Paper className={detailsClasses.section}>
                <SupplierInputContainer />
              </Paper>
            </Paper>
          </Grid>
        </Grid>
        <Grid>
          <Paper className={detailsClasses.section}>
            <Typography className={detailsClasses.tableHead}>{t('pos.shipmentDetails')}</Typography>
            <ShipmentDetails setShowUploadModal={() => {}} showUploadModal={false} />
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={listClasses.root}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Grid>
          <PoHead
            background={colorMapping[customizedStatus]}
            poId={poId}
            masterId={foreignPurchaseNumberMaster}
            customizedStatus={customizedStatus}
          />
          <Grid container>
            <Grid item xs={8}>
              <CompanyInfo supplier={supplierName} buyer={buyerName} isSender={isSender} />
            </Grid>
            <Grid item xs={4} className={listClasses.chip}>
              <StatusChip signature={signature} />
            </Grid>
          </Grid>
          <Details />
        </Grid>
      )}
    </Grid>
  );
};
