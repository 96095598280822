import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { CONSTANTS, METADATA_BEHAVIORS, TOKEN_METADATA_FIELDS } from '../../constants';
import { convertToJsonStr, isValidGTIN13 } from '../../utils';
import BlockchainInfo from '../Shared/BlockchainInfo';
import FormikSwitch from '../Shared/FormikSwitch';
import MintPanelMetadata from './MintPanelMetadata';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  mintButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  formArea: {
    marginTop: '2em',
  },
  inputWrapper: {
    display: 'flex',
  },
  inputAmount: {
    maxWidth: '30%',
    marginLeft: theme.spacing(2),
  },
}));

const MintNonFungibleToken = ({
  open,
  closeModal,
  onSubmit,
  loading,
  contractAddress,
  metadataConfigs,
  metadataMode,
  isContractOwner,
  smartContractType,
  setRemoveBurnedTokenPopupData,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();

  const initialValuesERC721 = {
    serialNo: '',
    gtinValidationNeeded: false,
  };

  const initialValuesERC1155 = {
    serialNo: '',
    gtinValidationNeeded: false,
    amount: 0,
    ...(metadataMode === METADATA_BEHAVIORS.LINKED && {
      linkERC721Input: {
        selectedContractName: '',
        selectedContractId: '',
        selectedTokenId: '',
        selectedTokenObjectId: '',
      },
    }),
  };

  const initialValues =
    smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721
      ? initialValuesERC721
      : initialValuesERC1155;

  const admittedFields = [
    TOKEN_METADATA_FIELDS.BOOLEAN,
    TOKEN_METADATA_FIELDS.FREE_TEXT,
    TOKEN_METADATA_FIELDS.JSON,
  ];
  let admittedMetadata = [];
  let metaConfig;
  switch (metadataMode) {
    case METADATA_BEHAVIORS.STRUCTURED:
      admittedMetadata = metadataConfigs.filter(metadataConfig => {
        return admittedFields.includes(metadataConfig.metadataType.metadataTypeName);
      });
      break;
    case METADATA_BEHAVIORS.HASHED:
      admittedMetadata = metadataConfigs.filter(metadataConfig => {
        const config = metadataConfig;
        config.metadataName = t('tokens.lblMetadata');
        return admittedFields.includes(config.metadataType.metadataTypeName);
      });
      break;
    case METADATA_BEHAVIORS.DIRECT:
      metaConfig = {
        metadataName: t('tokens.lblMetadata'),
        metadataType: {
          metadataTypeName: 'tokens.metaDataTypes.freeText',
        },
      };
      admittedMetadata.push(metaConfig);
      break;
    default:
      break;
  }

  if (admittedMetadata.length > 0) {
    admittedMetadata.forEach(metadataConfig => {
      let defaultValue;
      switch (metadataConfig.metadataType.metadataTypeName) {
        case TOKEN_METADATA_FIELDS.JSON:
          defaultValue = {
            rawMode: false,
            fieldArray: [],
            content: JSON.stringify({ '': '' }),
          };
          break;
        case TOKEN_METADATA_FIELDS.BOOLEAN:
          defaultValue = false;
          break;
        default:
          defaultValue = '';
      }
      initialValues[metadataConfig.metadataName] = defaultValue;
    });
  }

  const filterMetadataValues = values => {
    const filteredMetadataValues = {};
    switch (metadataMode) {
      case METADATA_BEHAVIORS.STRUCTURED:
      case METADATA_BEHAVIORS.HASHED:
      case METADATA_BEHAVIORS.DIRECT:
        admittedMetadata.forEach(metadataConfig => {
          const { metadataName } = metadataConfig;
          if (values[metadataName] !== initialValues[metadataName]) {
            if (metadataConfig.metadataType.metadataTypeName === TOKEN_METADATA_FIELDS.JSON) {
              const parsed = JSON.parse(convertToJsonStr(values[metadataName].fieldArray));
              filteredMetadataValues[metadataName] = parsed;
            } else {
              filteredMetadataValues[metadataName] = values[metadataName];
            }
          }
        });
        if (metadataMode === METADATA_BEHAVIORS.STRUCTURED) {
          return Object.keys(filteredMetadataValues).length > 0 ? filteredMetadataValues : null;
        }
        return filteredMetadataValues[t('tokens.lblMetadata')];
      default:
        return {};
    }
  };

  const validationForERC721 = Yup.object().shape({
    serialNo: Yup.string().when('gtinValidationNeeded', {
      is: true,
      then: Yup.string().test('GTIN13', t('tokens.validation.gtinFormat'), value =>
        isValidGTIN13(value),
      ),
      otherwise: Yup.string().required(t('tokens.validation.serialRequired')),
    }),
  });

  const validationForERC1155 = Yup.object().shape({
    serialNo: Yup.string().when('gtinValidationNeeded', {
      is: true,
      then: Yup.string().test('GTIN13', t('tokens.validation.gtinFormat'), value =>
        isValidGTIN13(value),
      ),
      otherwise: Yup.string().required(t('tokens.validation.serialRequired')),
    }),
    amount: Yup.number()
      .min(1, t('tokens.validation.minAmount'))
      .max(100000000, t('tokens.validation.maxAmount'))
      .required(t('tokens.validation.required'))
      .integer(t('tokens.validation.integerValue')),
    ...(metadataMode === METADATA_BEHAVIORS.LINKED && {
      linkERC721Input: Yup.object({
        selectedContractId: Yup.string().required(),
        selectedTokenObjectId: Yup.string().required(),
      }),
    }),
  });

  const getInput = values => {
    switch (smartContractType) {
      case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
        return {
          tokenId: values.serialNo,
          amount: parseInt(values.amount, 10),
          contractAddress,
          behavior: metadataMode,
          ...(metadataMode === METADATA_BEHAVIORS.LINKED && {
            linkedContractId: values?.linkERC721Input?.selectedContractId,
            linkedTokenId: values?.linkERC721Input?.selectedTokenObjectId,
          }),
        };
      default:
        return {
          tokenId: values.serialNo,
          contractAddress,
          behavior: metadataMode,
        };
    }
  };

  const generateRemoveBurnedTokenPopupData = (serialNo, input) => {
    const title = t('tokens.removeToken');
    const content = <Typography>{t('tokens.removeBurnedTokenProceed')}</Typography>;
    const confirmation = (
      <Trans i18nKey="tokens.removeBurnedTokenWithSameSerialNo" values={{ serialNo }}>
        <Typography>{t('tokens.removeBurnedTokenWithSameSerialNo', { serialNo })}</Typography>
      </Trans>
    );

    const warning = t('tokens.removeBurnedTokenWarning');
    const executeAction = onSubmit;
    const actionParameters = {
      variables: {
        input: {
          ...input,
          overwriteBurnedToken: true,
        },
      },
    };
    setRemoveBurnedTokenPopupData({
      title,
      confirmation,
      warning,
      content,
      actionParameters,
      executeAction,
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      // change input depending on smartcontractype
      const input = getInput(values);
      const metadata = filterMetadataValues(values);
      if (metadata) {
        input.metadata = metadata;
      }

      if (loading) return;

      if (smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721) {
        generateRemoveBurnedTokenPopupData(values.serialNo, input);
      }

      const res = await onSubmit({
        variables: {
          input: input,
        },
      });
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema:
      smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721
        ? validationForERC721
        : validationForERC1155,
  });

  const closeModalFn = () => {
    closeModal();
    formik.resetForm();
  };

  const gtinMode = formik.values.gtinValidationNeeded;

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.mintTokens')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <div className={classes.formArea}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Field
                component={FormikSwitch}
                name="gtinValidationNeeded"
                labels={{
                  falseLabel: t('tokens.gtinModes.free'),
                  trueLabel: t('tokens.gtinModes.gtin13'),
                }}
              />
              <div className={classes.inputWrapper}>
                <Field
                  component={NumberFormat}
                  label={t('tokens.serialNo')}
                  value={formik.values.serialNo || ''}
                  name="serialNo"
                  customInput={TextField}
                  variant="outlined"
                  onValueChange={vals => formik.setFieldValue('serialNo', vals.value, true)}
                  format={gtinMode ? '######### - ### - #' : null}
                  allowEmptyFormatting
                  mask="#"
                  isNumericString={!gtinMode}
                />
                {smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && (
                  <Field
                    component={NumberFormat}
                    label={t('tokens.amount')}
                    value={formik.values.amount || ''}
                    name="amount"
                    customInput={TextField}
                    onValueChange={vals => formik.setFieldValue('amount', vals.value)}
                    thousandSeparator
                    isNumericString
                    className={classes.inputAmount}
                  />
                )}
              </div>

              <MintPanelMetadata
                metadataMode={metadataMode}
                admittedMetadata={admittedMetadata}
                initialValues={initialValues}
                isContractOwner={isContractOwner}
                metadataConfigs={metadataConfigs}
              />

              <FormErrors form={formik} />

              {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                <BlockchainInfo
                  validateForm={formik.validateForm}
                  values={formik.values}
                  contractAddress={contractAddress}
                  method={CONSTANTS.TRANSACTION_TYPES.MINT}
                  methodArgs={
                    smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721
                      ? [activeWallet?.address, '', 'url']
                      : [activeWallet?.address, '', '', 'url']
                  }
                />
              ) : (
                ''
              )}
              <Divider className={classes.divider} />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!formik?.isValid || loading}
              >
                {t('tokens.mint')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.mintButton}
                onClick={closeModalFn}
              >
                {t('common.cancel')}
              </Button>
            </Form>
          </FormikProvider>
        </div>
      </Paper>
    </Modal>
  );
};

MintNonFungibleToken.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  loading: PropTypes.bool,
  metadataConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  metadataMode: PropTypes.string,
  isContractOwner: PropTypes.bool,
  smartContractType: PropTypes.oneOf([
    CONSTANTS.SMARTCONTRACT_TYPES.ERC721,
    CONSTANTS.SMARTCONTRACT_TYPES.ERC1155,
  ]),
  setRemoveBurnedTokenPopupData: PropTypes.func,
};

MintNonFungibleToken.defaultProps = {
  contractAddress: '',
  metadataConfigs: [],
  metadataMode: '',
  isContractOwner: false,
  loading: false,
  smartContractType: CONSTANTS.SMARTCONTRACT_TYPES.ERC721,
  setRemoveBurnedTokenPopupData: () => {},
};

export default MintNonFungibleToken;
