import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { find, filter } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import uniqid from 'uniqid';
import PageLoader from '../../components/Shared/PageLoader';
import ValueChainSummary from '../../components/Traceability/WizardV2/ValueChainSummary';
import {
  CONSTANTS,
  TRACEABILITY_DISPATCHER_ACTIONS,
  TRACEABILITY_ROUTE,
  TRACE_WIZARD_PROGRESSION_V2,
  PERMISSIONS,
} from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { UPDATE_INSTANCE } from '../../graphql/Traceability/instance';
import { dateFormatter } from '../../utils/dateUtils';
import api from '../../utils/api';
import { parsePermissionsForRole } from '../../utils';
import InstanceError from './InstanceError';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  titleDescription: {
    color: theme.palette.primary.light,
  },
  bodyDescription: {
    fontWeight: 'bold',
  },
  paper: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  verticalDivider: {
    borderLeft: `1px dotted ${theme.palette.primary.light}`,
  },
  traceabilityLandingRoot: {
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.lightest,
  },
  innerProgress: {
    position: 'relative',
    background: '#fff',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  itemsDescription: {
    position: 'relative',
    background: '#fff',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  title: {
    marginTop: '20px',
    marginBottom: '40px',
    fontSize: '27px',
    paddingLeft: '10px',
  },
  description: {
    display: 'flex',
    paddingLeft: '10px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    maxHeight: '90px',
  },
  footer: {
    display: 'flex',
    width: '100%',
  },
  gatheringButton: {
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  mainHeader: {
    fontWeight: 700,
    marginTop: '1.5em',
    flexDirection: 'row-reverse',
  },
  bodyBanner: {
    marginTop: '0.75em',
  },
  accordionArea: {
    marginTop: '1.5em',
  },
  link: {
    display: 'flex',
    textDecoration: 'underline',
    color: '#155CB4',
    position: 'relative',
  },
  deployArea: {
    marginTop: '2em',
  },
  accordionRoot: {},
  buildNoContent: {
    textAlign: 'left',
    fontSize: '15px',
    width: '60%',
    marginTop: '17%',
    fontWeight: '100',
  },
  stepContent: {
    marginTop: '4em',
    marginLeft: '-9em',
  },
  buildItems: {
    textAlign: 'left',
    marginLeft: '70%',
    fontSize: '15px',
    fontWeight: '100',
    width: '100%',
    marginTop: '10px',
  },
  menuItem: {
    fontSize: '12px',
  },
  buildParentContainer: {
    width: '100%',
    marginLeft: '70%',
    marginBottom: '20px',
    zIndex: '-6',
  },
  buildChildContainer: {
    display: 'flex',
    textAlign: 'right',
    marginTop: '10px',
    marginLeft: '-20px',
  },
  buildNumberBubble: {
    border: '0.1em solid grey',
    borderRadius: '100%',
    height: '1.4em',
    width: '1.4em',
    textAlign: 'center',
    marginRight: '10px',
  },
  buildNumber: {
    marginTop: '0em',
    fontSize: '1em',
    color: 'grey',
  },
  buildStepText: {
    fontSize: '15px',
  },
  buildSubTitles: {
    fontSize: '12px',
    color: 'grey',
    marginTop: '10px',
    display: 'flex',
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.8em',
  },
  buildChip: {
    fontSize: '10px',
    marginLeft: '5px',
    marginTop: '7px',
  },
  closeButton: {
    marginTop: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
    color: 'rgb(21, 92, 180)',
    textDecorationLine: 'underline',
  },
  contractAddress: {
    fontWeight: 'bold',
    color: 'rgb(21, 92, 180)',
    textDecorationLine: 'underline',
  },
  popperStyle: {
    margin: '0px',
    padding: '0px',
    zIndex: '1',
    width: '200px',
    marginLeft: '-60px',
  },
  growStyles: {
    padding: '0px',
    zIndex: '1',
  },
  zeroStyle: {
    margin: '0px',
    padding: '0px',
  },
  innerDescription: {
    marginLeft: '1em',
  },
  descriptionInnerDiv: {
    paddingRight: '10px',
    borderRight: '1px solid #C4C4CD',
  },
  successChip: {
    backgroundColor: theme.palette.success.main,
  },
  configChip: {
    backgroundColor: theme.palette.warning.lightest,
  },
  deactivatedChip: {
    backgroundColor: theme.palette.primary,
  },
  finalizeChip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  progressChip: {
    backgroundColor: theme.palette.primary,
  },
  itemsDiv: {
    paddingTop: '10px',
  },
  cardContent: {
    display: 'flex',
  },
  createdByDiv: {
    paddingLeft: '10px',
  },
  statusDiv: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '25px',
    paddingTop: '10px',
  },
}));

const InstanceLanding = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { handleNotification } = useNotification();

  const { activeWallet, organizationsList, activeOrganization } = useBecOrganizationContext();
  const {
    permissionsFlags: {
      isUserAuthToViewValueChainDetails,
      isUserAuthToInsertRecordInDGP,
      isUserAuthToViewRecordInDGP,
      isUserAuthToViewAllRecordsInDGP,
    },
  } = useUserInfo();

  const enableButton =
    isUserAuthToInsertRecordInDGP || isUserAuthToViewRecordInDGP || isUserAuthToViewAllRecordsInDGP;

  const {
    instanceId,
    instanceDetails,
    errorLoadingInstance,
    instanceNotFound,
    instanceDispatcher,
    lastCompletedIteration,
  } = useConfigurationWizardContext();

  const isUserAllowedToViewValueChainDetails =
    isUserAuthToViewValueChainDetails ||
    lastCompletedIteration !== TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code;

  const { getRolesAndPermissionsFromBEC } = api.userAPI({ handleNotification, t });
  const [currentWallet] = useState(activeWallet);
  const [isDeploymentProcessing, setIsDeploymentProcessing] = useState(false);
  const [partnerDetailsLoading, setPartnerDetailsLoading] = useState(false);
  const [updateInstance] = useMutation(UPDATE_INSTANCE);

  const createdOrgName = find(organizationsList, orgList => {
    return orgList?._id === instanceDetails?.content?.organizationId;
  })?.name;

  const manageDGPNavigation = async () => {
    let isAllowed = false;
    const organizationId = instanceDetails?.content?.organizationId;
    if (organizationId) {
      const orgRolls = await getRolesAndPermissionsFromBEC({ organizationId });
      const collaborators = filter(
        instanceDetails?.content?.steps.map(step => step?.collaborators).flat(),
        { partner: { partnerOrganization: { _id: activeOrganization?._id } } },
      );
      collaborators.forEach(collaborator => {
        const permissionList = parsePermissionsForRole(orgRolls, collaborator?.role);
        const isMinter = permissionList.includes(PERMISSIONS.TOKENIZATION.MINTERC721);
        const isUpdater = permissionList.includes(PERMISSIONS.TOKENIZATION.SETERC721METADATA);
        isAllowed = isMinter || isUpdater;
      });
      setPartnerDetailsLoading(false);
      if (isAllowed)
        history.push(`/traceability/data-gathering-portal/${instanceDetails?.content?._id}`);
      else history.push(`/traceability/ingestion-history/${instanceDetails?.content?._id}`);
    }
  };

  const partnerItemPopulation = async () => {
    await manageDGPNavigation();
  };

  const populateItems = () => {
    if (activeOrganization?._id === instanceDetails?.content?.organizationId) {
      const items = instanceDetails?.content?.items;
      if (items?.length === 0 && !!instanceDetails?.content?._id) {
        history.push(`/traceability/items/${instanceDetails?.content?._id}`);
      }
      return items?.map(item => <ValueChainSummary item={item} key={uniqid()} />);
    }
    setPartnerDetailsLoading(true);
    return partnerItemPopulation();
  };

  useEffect(() => {
    if (currentWallet !== activeWallet) {
      history.push(TRACEABILITY_ROUTE);
    }
  }, [activeWallet]);

  const statusComponent = () => {
    const status = instanceDetails?.content?.status;
    switch (status) {
      case CONSTANTS.INSTANCE_STATUSES.ACTIVE:
        return (
          <Chip label={t('traceability.instanceStatus.active')} className={classes.successChip} />
        );
      case CONSTANTS.INSTANCE_STATUSES.NEEDS_CONFIGURATION:
        return (
          <Chip label={t('traceability.instanceStatus.config')} className={classes.configChip} />
        );
      case CONSTANTS.INSTANCE_STATUSES.FINALIZE:
        return (
          <Chip
            label={t('traceability.instanceStatus.finalize')}
            className={classes.finalizeChip}
          />
        );
      case CONSTANTS.INSTANCE_STATUSES.PROGRESS:
        return (
          <Chip
            label={t('traceability.instanceStatus.progress')}
            className={classes.deactivatedChip}
            icon={<CircularProgress size={20} thickness={20} color="primary" />}
          />
        );
      default:
        return (
          <Chip label={t('traceability.instanceStatus.config')} className={classes.configChip} />
        );
    }
  };

  if (errorLoadingInstance || instanceNotFound) return <InstanceError />;

  if (!instanceDetails?.content?._id || isDeploymentProcessing || partnerDetailsLoading) {
    return <PageLoader />;
  }

  const handlePortalNav = () => {
    if (isUserAuthToInsertRecordInDGP) {
      history.push(`/traceability/data-gathering-portal/${instanceId}`);
    } else {
      history.push(`/traceability/ingestion-history/${instanceId}`);
    }
  };
  const finalizeValueChain = async () => {
    try {
      setIsDeploymentProcessing(true);
      if (instanceId) {
        await updateInstance({
          variables: {
            instanceId: instanceId,
            status: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
            name: instanceDetails?.content?.name,
          },
        });
        instanceDispatcher({
          type: TRACEABILITY_DISPATCHER_ACTIONS.FINALIZE_INSTANCE,
          payload: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
        });
        setIsDeploymentProcessing(false);
      }
    } catch (error) {
      setIsDeploymentProcessing(false);
      handleNotification(error, 'error');
    }
  };

  return (
    <div className={classes.traceabilityLandingRoot}>
      <div className={classes.header}>
        <div className={classes.header}>
          <Typography variant="h1" color="primary" className={classes.title}>
            {instanceDetails.content?.name}
          </Typography>

          <Button className={classes.closeButton} onClick={() => history.push(TRACEABILITY_ROUTE)}>
            {t('traceability.instanceReview.close')}
          </Button>
        </div>
      </div>
      <Grid item xs={12} className={classes.cardContent}>
        <Grid item xs={6}>
          <div className={classes.description}>
            <div className={classes.descriptionInnerDiv}>
              <Typography variant="subtitle2" className={classes.titleDescription}>
                {t('traceability.instanceSummary.createdBy')}
              </Typography>
              <Typography variant="body2" className={classes.bodyDescription}>
                {createdOrgName}
              </Typography>
            </div>
            <div className={classes.createdByDiv}>
              <Typography variant="subtitle2" className={classes.titleDescription}>
                {t('traceability.instanceReview.creation')}:
              </Typography>
              <Typography variant="body2" className={classes.bodyDescription}>
                {dateFormatter(moment(instanceDetails.content?.date * 1000).format('YYYY/MM/DD'))}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.statusDiv}>{statusComponent()}</div>
        </Grid>
      </Grid>

      {isUserAllowedToViewValueChainDetails && (
        <div className={classes.itemsDiv}>{populateItems()}</div>
      )}

      <div className={classes.footer}>
        {instanceDetails?.content?.lastCompletedIteration ===
          TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
          enableButton && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.gatheringButton}
              onClick={handlePortalNav}
            >
              {isUserAuthToInsertRecordInDGP
                ? t('traceability.instanceReview.dataGatheringTool')
                : t('traceability.dgp.registeredData')}
            </Button>
          )}

        {instanceDetails?.content?.lastCompletedIteration ===
          TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.gatheringButton}
            onClick={finalizeValueChain}
          >
            {t('traceability.btnFinalize')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default InstanceLanding;
