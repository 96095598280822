import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Table from '@eyblockchain/ey-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useFileToData, FileToDataProvider } from '../../../contexts/Procurement/FileToData';
import { useBatchUpload, BatchUploadProvider } from '../../../contexts/Procurement/BatchUpload';
import LoadingComponent from '../DemandForecast/loaderContainer';
import { displayDate, ellipsisText } from './tableUtils';

const ExcelUploader = ({ makePayload, action }) => {
  const { t } = useTranslation('mtr');
  const classes = makeStyles(() => ({
    fileUploader: {
      margin: '1rem',
    },
    result: {
      margin: '1rem',
    },
    fileTable: {
      '& .MuiTableCell-root': {
        padding: '8px',
      },
    },
    red: {
      color: 'red',
    },
    green: {
      color: 'green',
    },
    pointer: {
      cursor: 'pointer',
    },
  }))();

  const {
    convertFile,
    convertFileToData,
    resetFileTable,
    fileTableData,
    // fileTableHeader,
    fileTableCol,
    loadingConvertFileToData,
  } = useFileToData();
  const { batchUploadLoading, batchUploadResult, batchUpload, resetBatchUpload } = useBatchUpload();
  const FileUploader = () => {
    const patchUpload = async () => {
      batchUpload({
        jobs: fileTableData.map(item => action({ variables: makePayload(item, fileTableCol) })),
      });
    };
    return (
      <Grid className={classes.fileUploader}>
        <Grid container alignItems="center" spacing={3}>
          {!convertFile && (
            <Grid item>
              <input
                style={{ display: 'none' }}
                type="file"
                id="contained-button-file"
                onChange={event => {
                  const {
                    files: [file],
                  } = event.target;
                  convertFileToData(file);
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<AttachFileIcon />}
                >
                  {t('pos.selectFile')}
                </Button>
              </label>
            </Grid>
          )}
          {convertFile && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  disabled={batchUploadLoading || loadingConvertFileToData}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    patchUpload();
                  }}
                >
                  {`${t('common.upload')} ${convertFile?.name}`}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={batchUploadLoading || loadingConvertFileToData}
                  onClick={() => {
                    resetFileTable();
                    resetBatchUpload();
                  }}
                >
                  {t('pos.clear')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const Result = ({ result }) => {
    const failed = result.filter(i => i.status === 'rejected');
    const success = result.filter(i => i.status === 'fulfilled');
    return (
      <div className={classes.result}>
        <Grid container spacing={3}>
          <Grid item>Total: {result.length}</Grid>
          <Grid item className={classes.green}>
            {t('common.success')}: {success.length}
          </Grid>
          <Grid item className={classes.red}>
            {t('common.failed')}: {failed.length}
          </Grid>
        </Grid>
      </div>
    );
  };

  Result.propTypes = {
    result: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  const FileTable = () => {
    const dataWithResult = fileTableData.map((item, index) => {
      return {
        ...item,
        uploadStatus: batchUploadResult[index],
      };
    });
    let column = fileTableCol.map(i => {
      return {
        name: i.name,
        label: i.range,
        options: {
          customHeadLabelRender: colItem => {
            return (
              <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                <div style={{ color: 'grey' }}>{colItem.label}</div>
                <div>
                  {colItem.name.length <= 25
                    ? colItem.name
                    : colItem.name.substring(0, 25).concat('...')}
                </div>
              </div>
            );
          },
          customBodyRender: value => {
            if (value === 0) {
              return value;
            }
            if (!value) return null;
            if (typeof value === 'number' && `${value}`.length > 8) {
              return displayDate({ value });
            }
            return ellipsisText({ value });
          },
        },
      };
    });
    column = batchUploadResult.length
      ? [
          {
            name: 'uploadStatus',
            label: t('pos.uploadStatus'),
            options: {
              customBodyRender: value => {
                if (!value) return 'not yet upload';
                if (value.status === 'fulfilled') {
                  return <CheckCircleOutlineIcon className={classes.green} />;
                }
                if (value.status === 'rejected') {
                  return (
                    <Tooltip title={value.reason?.message} placement="top" arrow>
                      <ErrorOutlineIcon className={`${classes.red} ${classes.pointer}`} />
                    </Tooltip>
                  );
                }
                return null;
              },
            },
          },
        ].concat(column)
      : column;
    return (
      <Grid className={classes.fileTable}>
        <Table
          options={{
            filter: false,
            pagination: true,
            sort: false,
            search: false,
            rowsPerPageOptions: [10, 50, 100],
            textLabels: {
              body: {
                noMatch: t('common.noMatchingRecords'),
              },
              pagination: {
                rowsPerPage: t('common.rowsPerPage'),
              },
            },
          }}
          data={dataWithResult}
          columns={column}
        />
      </Grid>
    );
  };

  return (
    <>
      <FileUploader />
      {batchUploadResult?.length > 0 && <Result result={batchUploadResult} />}
      {batchUploadLoading ? <LoadingComponent /> : <FileTable />}
    </>
  );
};

ExcelUploader.propTypes = {
  action: PropTypes.func.isRequired,
  makePayload: PropTypes.func.isRequired,
};

const UploadModal = ({ action, makePayload, open, onClose, refetch }) => {
  const uploadModalClasses = makeStyles(theme => ({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    modalContainer: {
      width: '80%',
      height: '80%',
      position: 'relative',
      overflow: 'auto',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  }))();

  return (
    <Modal className={uploadModalClasses.modal} open={open}>
      <Paper className={uploadModalClasses.modalContainer}>
        <BatchUploadProvider>
          <FileToDataProvider>
            <IconButton
              aria-label="close"
              className={uploadModalClasses.closeButton}
              onClick={() => {
                onClose();
                refetch();
              }}
            >
              <CloseIcon />
            </IconButton>
            <ExcelUploader action={action} makePayload={makePayload} />
          </FileToDataProvider>
        </BatchUploadProvider>
      </Paper>
    </Modal>
  );
};

UploadModal.propTypes = {
  action: PropTypes.func.isRequired,
  makePayload: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default UploadModal;
