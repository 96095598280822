import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  addArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: theme.palette.primary.light,
    padding: '1em',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: '1em',
  },
}));

const AddArea = ({ onClick, bannerText }) => {
  const classes = useStyles();

  return (
    <div className={classes.addArea}>
      <IconButton
        onClick={onClick}
        className={classes.addButton}
        size="small"
        data-testid="add-item-button"
      >
        <AddIcon />
      </IconButton>
      <Typography variant="body1">{bannerText}</Typography>
    </div>
  );
};

AddArea.propTypes = {
  onClick: PropTypes.func.isRequired,
  bannerText: PropTypes.string,
};

AddArea.defaultProps = {
  bannerText: '',
};

export default AddArea;
