export const OPSCHAIN_NOTARIZATION = 'opschain_notarization';
export const OPSCHAIN_TOKENS = 'opschain_tokens';
export const OPSCHAIN_TRACEABILITY = 'opschain_traceability';
export const OPSCHAIN_WALLET = 'wallet_management_temp';
export const OPSCHAIN_STORAGE = 'opschain_storage';
export const OPSCHAIN_ZKP = 'opschain_zkp';
export const OPSCHAIN_ORG_MANAGEMENT = 'opschain_org_management_temp';
export const MTR_PROCUREMENT = 'mtr_procurement';
export const OPSCHAIN_MAP_VIEW = 'opschain_traceability_map_view';
export const VISUALIZER_DASHBOARD = 'token_dashboard';
export const VISUALIZER_HISTORY = 'token_ownership';
export const LIVE_TRANSACTIONS_PANEL = 'temp_live_transaction_UI';
export const EURO_CURRENCY = 'euro_currency';
export const REBATE_DISCOUNT = 'rebate_discount';
export const AUTHORIZED_PARTIES = 'authorized_parties';
export const OPSCHAIN_BUSINESS_ECOSYSTEMS = 'opschain_business_ecosystems';

export const ETHEREUM_CONTRACT_URL_PREFIX = 'https://ropsten.etherscan.io/address/';

export const STEP_FIELDS = {
  TYPE: 'type',
  TYPE_ADD: 'add',
  TYPE_INPUT: 'inputType',
  TYPE_ID: 'id',
  TYPE_FIELD_ID: 'stepid',
  TYPE_STRING: 'String',
  TYPE_NUMBER: 'number',
  TYPE_DATE: 'date',
  TYPE_SELECT: 'select',
  NAME: 'name',
  MAX_LENGTH: 'maxlength',
  PLACEHOLDER: 'placeholder',
  ISREQUIRED: 'required',
  AMOUNT_MAX: 'max',
  AMOUNT_MIN: 'min',
  STEP: 'step',
  SELECT_OPTIONS: 'selectOptions',
  MULTIPLE_SELECTION: 'multipleSelection',
  CLOSE_ON_SELECT: 'closeOnSelect',
  ALLOW_SEARCHING: 'allowSearching',
  CLEAR_ON_SELECT: 'clearOnSelect',
};

export const TRACE_WIZARD_PROGRESSION_V2 = {
  NEW_WIZARD: { code: 0, path: 'overview' },
  VALUE_CHAIN_ITEMS: { code: 1, path: 'items' },
  VALUE_CHAIN_STEPS: { code: 2, path: 'steps' },
  VALUE_CHAIN_PERMISSIONS: { code: 3, path: 'permissions' },
  VALUE_CHAIN_METADATA: { code: 4, path: 'metadata' },
  FINALIZE: { code: 5, path: 'finalize' },
};

export const CONSTANTS = {
  INSTANCE_STATUSES: {
    ACTIVE: 1,
    PASSIVE: 2,
    NEEDS_CONFIGURATION: 3,
    FINALIZE: 4,
    PROGRESS: 5,
  },
  TOKEN_CREATION_STATES: {
    FRESH: 0,
    DISABLED: 1,
    PROCESSING: 2,
    SUCCESS: 3,
    ERROR: 4,
  },
  CONFIGURATION_WIZARD_BOX_STATES: {
    disabled: 'disabled',
    enabled: 'enabled',
    hasError: 'hasError',
    completed: 'completed',
  },
  TRACE_PATHS: {
    items: 'items',
    steps: 'steps',
    permissions: 'permissions',
    metadata: 'metadata',
    instanceReview: 'instance-review',
    dataGatheringPortal: 'data-gathering-portal',
    ingestionHistory: 'ingestion-history',
    finalize: 'finalize',
  },
  SIGNING_MODE: {
    METAMASK_ETHEREUM_ROPSTEN: 0,
    MANAGED_ETHEREUM_ROPSTEN: 1,
    MANAGED_BCOS: 2,
    MANAGED_BSN: 3,
  },
  MIXPANEL_EVENTS: {
    LAUNCH_OPSCHAIN_EVENT: 'Launch Opschain',
    OPSCHAIN_NAVIGATION_VIA_URL: 'OpsChain navigation via URL',
    OPSCHAIN_NAVIGATION_VIA_THE_PRODUCT_PAGE: 'OpsChain navigation via the product page',
    OPSCHAIN_NAVIGATION_VIA_THE_RIGHT_HAND_MENU: 'OpsChain navigation via the right hand menu',
    COPYING_WALLET_ADDRESS: 'Copying Wallet Address',
    COPYING_CONTRACT_ADDRESS: 'Copying Contract Address',
    UPDATE_ROLE: 'Update role',
    ADD_BUSINESS_PARTNER: 'Add business partner',
    REMOVE_BUSINESS_PARTNER: 'Remove business partner',
    TRACEABILITY: {
      NEW_VALUE_CHAIN: 'New Value Chain',
      VALUE_CHAIN_FINALIZATION: 'Value Chain finalized',
      NEW_SMART_CONTRACT: 'New Smart Contract (Value Chain)',
      SET_METADATA_STRUCTURE: 'Set Metadata structure (Value Chain)',
      UPDATE_STEPS_COLLABORATORS: 'Permissions update (Value Chain)',
    },
    NOTARIZATION: {
      DEPLOY_CONTRACT: 'New Notarization contract',
      UPDATE_COLLABORATORS: 'Update Notarization permissions',
      REGISTER_DOCUMENT: 'New document',
      VERIFY_DOCUMENT: 'Document verification',
      UPDATE_DOCUMENT_DESCRIPTION: 'Update document description',
      UNLIST_DOCUMENT: 'Document unlisted',
      NOTARIZE_CERTIFICATE: 'New certificate',
      UNLIST_CERTIFICATE: 'Certificate unlisted',
      UPDATE_CERTIFICATE_DESCRIPTION: 'Update certificate description',
    },
    TOKENIZATION: {
      UPDATE_COLLABORATORS: 'Update Tokenization permissions',
      DEPLOY_ERC721: 'Deploy ERC721 Smart Contract',
      MINT_ERC721: 'Mint ERC721 token',
      BURN_ERC721: 'Burn ERC721 token',
      SET_METADATA_STRUCTURE: 'Set Metadata structure',
      UPDATE_METADATA: 'ERC721 Token metadata has been updated',
      ACCESS_BATCH_DETAILS: 'Access to batch details',
      BATCH_MINTING: 'ERC721 Batch minting',
      BATCH_METADATA_UPDATE: 'ERC721 Batch metadata update',
      TRANSFER_ERC721: 'ERC721 Token Transfer transaction Submitted',
      TRANSFER_ERC1155: 'ERC1155 Token Transfer transaction Submitted',
      VIEW_HISTORY: 'Direct to Token Dashboard',
      DEPLOY_ERC20: 'Deploy ERC20 Smart Contract',
      MINT_ERC20: 'Mint ERC20 token',
      BURN_ERC20: 'Burn ERC20 token',
      TRANSFER_ERC20: 'ERC20 Token Transfer transaction Submitted',
      DEPLOY_ERC1155: 'Deploy ERC1155 Smart Contract',
    },
  },
  MIXPANEL_ERRORS: {
    UNKNOWN_EVENT: 'Unknown event',
    TRACEABILITY: {
      NEW_VALUE_CHAIN: 'Error during value chain creation',
      VALUE_CHAIN_FINALIZATION: 'Error during value chain finalization',
      NEW_SMART_CONTRACT: 'Error during Smart Contract creation (Value Chain)',
      SET_METADATA_STRUCTURE: 'Error while setting Metadata structure (Value Chain)',
      UPDATE_STEPS_COLLABORATORS: 'Error while updating permissions (Value Chain)',
    },
    NOTARIZATION: {
      DEPLOY_CONTRACT: 'Error in Notarization contract creation',
      UPDATE_COLLABORATORS: 'Error during Notarization permissions update',
      REGISTER_DOCUMENT: 'Error during new document notarization',
      VERIFY_DOCUMENT: 'Error during verifying process',
      UPDATE_DOCUMENT_DESCRIPTION: 'Error while updating document description',
      UNLIST_DOCUMENT: 'Error while unlisting a document',
      NOTARIZE_CERTIFICATE: 'Error during new certificate notarization',
      UNLIST_CERTIFICATE: 'Error while unlisting a certificate',
      UPDATE_CERTIFICATE_DESCRIPTION: 'Error while updating certificate description',
    },
    TOKENIZATION: {
      UPDATE_COLLABORATORS: 'Error during Tokenization permissions update',
      DEPLOY_ERC721: 'Error while deploying ERC721 Smart Contract',
      MINT_ERC721: 'Error while minting ERC721 token',
      BURN_ERC721: 'Burn ERC721 error',
      SET_METADATA_STRUCTURE: 'Error while setting Metadata structure',
      UPDATE_METADATA: 'Error during Metadata update',
      ACCESS_BATCH_DETAILS: 'Error while accessing batch details',
      BATCH_MINTING: 'Batch minting error',
      BATCH_METADATA_UPDATE: 'Batch metadata update error',
      TRANSFER_ERC721: 'ERC721 Token Transfer Error',
      TRANSFER_ERC1155: 'ERC1155 Token Transfer Error',
      DEPLOY_ERC20: 'Error while deploying ERC20 Smart Contract',
      MINT_ERC20: 'Error while minting ERC20 token',
      TRANSFER_ERC20: 'ERC20 Token Transfer transaction Error',
      DEPLOY_ERC1155: 'Error while deploying ERC1155 Smart Contract',
    },
  },
  SMARTCONTRACT_TYPES: {
    ERC1155: 'ERC1155',
    ERC721: 'ERC721',
    ERC20: 'ERC20',
    NOTARIZATION: 'NOTARIZATION',
    TRACEABILITY: 'TRACEABILITY',
    TRACEABILITY_IDENTITY: 'TRACEABILITY_IDENTITY',
    TRACEABILITY_REGISTRY: 'TRACEABILITY_REGISTRY',
  },
  BLOCKCHAIN_TYPES: {
    ETH: 'ETH',
    BCOS: 'BCOS',
    BSN: 'BSN',
    MATIC: 'MATIC',
  },
  NETWORK_TYPES: {
    PUBLIC: 'public',
    PRIVATE: 'private',
  },
  TRANSACTION_TYPES: {
    DEPLOY: 'deploy',
    MINT: 'mint',
    BURN: 'burn',
    TRANSFER: 'transfer',
    BATCH_TRANSFER: 'batchTransfer',
    BATCH_BURN: 'batchBurn',
    NOTARIZATION: 'notarizeDocument',
    NOTARIZE_DOCUMENT: 'notarizeDocument',
    CREATE_CERTIFICATE: 'createCertificate',
    RECEIVE: 'received',
    UPDATE_METADATA_ON_CHAIN: 'setTokenMetadata',
    UPDATE_METADATA: 'updateMetadata',
    SET_MINTERS: 'setMinters',
    SET_DOCUMENT: 'setDocument',
    SET_CERTIFICATE: 'setCertificate',
    BATCH_MINT: 'batchMint',
    WITHDRAW: 'withdraw',
    DEPOSIT: 'deposit',
  },
  TRANSACTION_STATUS: {
    COMPLETED: 'completed',
    FAILED: 'failed',
    PENDING: 'pending',
  },
  CONTRACT_STATUSES: {
    COMPLETED: 'completed',
    FAILED: 'failed',
    PENDING: 'pending',
    DEPLOYING: 'deploying',
  },
  JSON_PAIR_LIMIT: {
    TOKEN_METADATA: 5,
  },
  NOTARIZATION_STATUS: {
    VERIFIED: 'verified',
    NOT_VERIFIED: 'not_verified',
  },
  BLOCKCHAIN_NETWORKS: {
    ROPSTEN: 'ropsten',
    ECDSA: 'ecdsa',
    MAINNET: 'mainnet',
    POLYGON_MAINNET: 'polygon_mainnet',
    POLYGON_TESTNET: 'polygon_testnet',
  },
  NETWORK_FAUCETS: {
    ROPSTEN: 'https://faucet.dimensions.network/',
    POLYGON: 'https://faucet.polygon.technology/',
  },
  DOCS: {
    ROPSTEN: 'https://ethereum.org/en/developers/#testnets-and-faucets',
    POLYGON: 'https://docs.polygon.technology/docs/develop/oracles/getting-started/',
  },
  DOCUMENTS_FILTER: {
    WEEKDAYS: 7,
    THIRTYDAYS: 30,
  },
  DATEPICKERS: {
    MINDATE: '01.01.1970',
    DATE_FORMAT: 'YYYY/MM/DD',
  },
  PARTNER_UPDATE_TYPE: {
    ADD: 'add',
    REMOVE: 'remove',
    UPDATE: 'update',
  },
  METADATA_CONTENT_PAGE: {
    MAX_TOKENID_LENGTH: 16,
    PUBLIC_METADATA: 'publicTokenMetadata',
    PRIVATE_METADATA: 'privateTokenMetadata',
  },
  RESTRICTIONS: {
    PUBLIC: 'public',
    PRIVATE: 'private',
  },
};

export const TAB_VALUES = {
  PERMISSIONS: 'PERMISSIONS',
  CONTRACT_PANEL: {
    LIST: 'TOKEN_LIST',
    BATCHES: 'BATCHES',
    METADATA_STRUCTURE: 'METADATA_STRUCTURE',
  },
  TOKEN_PANEL: {
    HISTORY: 'TOKEN_HISTORY',
    METADATA: 'METADATA',
  },
  NOTARIZATION_CONTRACT: {
    REG_DOCUMENT: 'REG_DOCUMENT',
    ISSUE_CERTIFICATE: 'ISSUE_CERTIFICATE',
  },
};

export const RBAC_ACTIONS = {
  ADD_BUSINESS_PARTNER: 'ADD_BUSINESS_PARTNER',
  UPDATE_ROLE: 'UPDATE_ROLE',
  REMOVE_BUSINESS_PARTNER: 'REMOVE_BUSINESS_PARTNER',
};

export const RBAC_ROLES = {
  VIEWER: 'Viewer',
  CONTRIBUTOR: 'Contributor',
};

export const ETHEREUM_ERROR_TYPES = [
  'insufficient_funds',
  'intrinsic_gas_too_low',
  'exceeds_block_gas_limit',
];

export const ERROR_CODES = {
  GEOLOCATION: {
    UNAVAILABLE: 'GeoLocation from browser is currently unavailable',
    POS_UNDETERMINED: 'Unable to determine position',
  },
  RBAC: {
    DEFINITION_MISSING: 'RBAC definitions not found for this organization',
  },
  UPDATE_PACKAGE: 'Unable to request trial access',
};

export const METADATA_TYPES = {
  PARENT: 'parent',
  CHILD: 'child',
  INPUT: 'input',
  OUTPUT: 'output',
};

export const METADATA_STRUCTURE_PERMISSIONS = {
  UNRESTRICTED: 'unrestricted',
  RESTRICTED: 'restricted',
};

export const METADATA_STRUCTURE_DATA_MUTABILITY = {
  MUTABLE: 'mutable',
  IMMUTABLE: 'immutable',
};

export const METADATA_STRUCTURE_RELATIONSHIP = {
  PARENT: 'parent',
  CHILD: 'child',
};

export const METADATA_STRUCTURE_RELATIONSHIP_TYPE = {
  INPUT: 'input',
  OUTPUT: 'output',
};

export const METADATA_BEHAVIORS = {
  NONE: 'NONE',
  DIRECT: 'DIRECT',
  STRUCTURED: 'STRUCTURED',
  HASHED: 'HASHED',
  LINKED: 'LINKED',
};

export const TOKEN_METADATA_FIELDS = {
  LOCATION: 'tokens.metaDataTypes.location',
  BOOLEAN: 'tokens.metaDataTypes.boolean',
  FREE_TEXT: 'tokens.metaDataTypes.freeText',
  FILE: 'tokens.metaDataTypes.file',
  JSON: 'tokens.metaDataTypes.json',
  MEASUREMENT: 'tokens.metaDataTypes.measurement',
  LINKED_TOKEN: 'tokens.metaDataTypes.linkedToken',
};

export const DATE_PATTERNS = {
  DEFAULT: 'DD MMM YYYY',
};

export const PAGE_HEADERS = {
  default: 'headers.opschain',
  traceability: 'headers.traceabilityFull',
  tokens: 'headers.tokensFull',
  notarizations: 'headers.notarizationsFull',
};

export const PERMISSIONS = {
  NOTARIZATION: {
    DEPLOYNOTARIZATION: 'notarization:deployNotarization',
    NOTARIZEDOCUMENT: 'notarization:notarizeDocument',
    NOTARIZECERTIFICATE: 'notarization:notarizeCertificate',
    RESTRICTNOTARIZATIONCONTENT: 'notarization:restrictNotarizationContent',
    VIEWTRANSACTIONDETAILS: 'notarization:viewTransactionDetails',
    VIEWCERTIFICATECONTENT: 'notarization:viewCertificateContent',
    VERIFYDOCUMENT: 'notarization:verifyDocument',
    VIEWTRANSACTIONSTATUS: 'notarization:viewTransactionStatus',
    UNLISTTRANSACTION: 'notarization:unlistTransaction',
    DOWNLOADCERTIFICATE: 'notarization:downloadCertificate',
    DOWNLOADDOCUMENT: 'notarization:downloadDocument',
  },
  TOKENIZATION: {
    DEPLOYERC721: 'tokenization:deployErc721',
    MINTERC721: 'tokenization:mintErc721',
    BURNERC721: 'tokenization:burnErc721',
    VIEWERC721HISTORY: 'tokenization:viewErc721History',
    SETERC721METADATA: 'tokenization:setErc721Metadata',
    TRANSFERERC721: 'tokenization:transferErc721',
    DEPLOYERC1155: 'tokenization:deployErc1155',
    MINTERC1155: 'tokenization:mintErc1155',
    BURNERC1155: 'tokenization:burnErc1155',
    VIEWERC1155HISTORY: 'tokenization:viewErc1155History',
    SETERC1155METADATA: 'tokenization:setErc1155Metadata',
    TRANSFERERC1155: 'tokenization:transferErc1155',
    DEPLOYERC20: 'tokenization:deployErc20',
    MINTERC20: 'tokenization:mintErc20',
    BURNERC20: 'tokenization:burnErc20',
    TRANSFERERC20: 'tokenization:transferErc20',
    VIEWHISTORY: 'tokenization:viewHistory',
    VIEWFULLLISTOFTOKENS: 'tokenization:viewFullListofTokens',
    VIEWORGANIZATIONTOKENS: 'tokenization:viewOrganizationTokens',
    VIEWTOKENOWNER: 'tokenization:viewTokenOwner',
    VIEWDEPLOYEDTOKENS: 'tokenization:viewDeployedTokens',
    VIEWTOKENMETADATA: 'tokenization:viewTokenMetadata',
  },
  TRACEABILITY: {
    CREATEVALUECHAIN: 'traceability:createValueChain',
    EDITVALUECHAIN: 'traceability:editValueChain',
    VIEWVALUECHAINDETAILS: 'traceability:viewValueChainDetails',
    INSERTRECORDDGP: 'traceability:insertrecordsonDataGatheringPortal',
    VIEWRECORDDGP: 'traceability:viewrecordsonDataGatheringPortal',
    VIEWALLRECORDSDGP: 'traceability:viewallrecordsonDataGatheringPortal',
  },
  CONTRACT_MANAGER: {
    PARTNERS: 'contract_manager:partners',
    CREATEPARTNER: 'contract_manager:createPartner',
    GETRFQLISTING: 'contract_manager:getRfqListing',
    CREATERFQ: 'contract_manager:createRFQ',
    UPDATERFQSUPPLIER: 'contract_manager:updateRFQSupplier',
    GETCONTRACTLISTING: 'contract_manager:getContractListing',
    CREATECONTRACT: 'contract_manager:createContract',
    SIGNCONTRACT: 'contract_manager:signContract',
    GETPURCHASEORDERS: 'contract_manager:getPurchaseOrders',
    CREATEPURCHASEORDER: 'contract_manager:createPurchaseOrder',
    SIGNPURCHASEORDER: 'contract_manager:signPurchaseOrder',
    ERCTOKENCONTRACTS: 'contract_manager:ercTokenContracts',
    ASSOCIATEERC721TOKENPURCHASEORDER: 'contract_manager:associateERC721TokenPurchaseOrder',
    DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER:
      'contract_manager:dissociateERC721SmartContractPurchaseOrder',
    INVOICES: 'contract_manager:invoices',
    CREATEINVOICE: 'contract_manager:createInvoice',
  },
};

export const TRACEABILITY_ROUTE = '/traceability/overview';

export const VALUE_CHAIN_FIELDS = {
  ITEMS: {
    ITEM_NAME: 'itemName',
    ITEM_TYPE: 'itemType',
    COD_WITH: 'codType',
    TOKENIZE: 'tokenize',
    SMARTCONTRACT: 'smartContract',
    DEPENDS_ON: 'dependsOn',
    ACTIONS: 'actions',
  },
  STEPS: {
    STEP: 'step',
    INVOLVED_ITEM: 'involvedItem',
    CREATE_TOKEN: 'createToken',
    UPDATED_DATA_WITH: 'updatedDataWith',
    ACTIONS: 'actions',
  },
  PERMISSIONS: {
    ITEMS_AND_STEPS: 'itemsAndSteps',
    ROLES: 'roles',
    ADD_PARTNERS: 'addPartners',
  },
  METADATA: {
    METADATA_NAME: 'metadataName',
    DATA_TYPE: 'dataType',
    PERMISSION: 'permission',
    MUTABILITY: 'mutability',
    VALUE_CHAIN_STEP: 'valueChainStep',
    ACTIONS: 'actions',
  },
};

export const VALUE_CHAIN_FORMS = {
  ITEM: 'traceItemForm',
  STEP: 'valueChainStepsForm',
  PERMISSIONS: 'permissionsForm',
  METADATA: 'metadataForm',
};

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const TRACEABILITY_FORM = {
  CREATE_CONTRACT: 'createContract',
  SELECT_CONTRACT: 'selectContract',
  TOKEN_CONTRACT: 'tokenContract',
  CONTRACT_SELECTION: 'contractSelection',
  TOKEN_TYPE: 'selectedTokenType',
};

export const TRACEABILITY_DISPATCHER_ACTIONS = {
  UPDATE_INSTANCE: 'instance',
  UPDATE_ITEMS: 'items',
  UPDATE_STEPS: 'updateStep',
  ADD_STEPS: 'addedStep',
  ADD_STEP_DATA: 'addStepData',
  UPDATE_STEP_DATA: 'updateStepData',
  UPDATE_STEPS_PERMISSIONS: 'updateStepsPermissions',
  DELETE_ITEMS: 'deleteItems',
  DELETE_STEPS: 'deleteSteps',
  RESET_INSTANCE: 'resetInstance',
  UPDATE_CURRENT_ITERATION: 'updateCurrentIteration',
  UPDATE_INSTANCE_STATUS: 'updateInstanceStatus',
  UPDATE_METADATA: 'updateMetadata',
  FINALIZE_INSTANCE: 'finalizeInstance',
};

export const MIME_TYPES = {
  JSON: 'application/json',
};

export const CONTENT_TYPES = {
  JSON: 'application/json; charset=utf-8',
};

export const DASHBOARD_PRODUCTS = {
  TOKENIZATION: {
    NAME: 'tokenization',
    PAGE: 'products/traceability',
    START: '/tokens',
    REQUIRED_PACKAGES: [],
  },
  NOTARIZATION: {
    NAME: 'notarization',
    PAGE: 'products/traceability',
    START: '/notarizations',
    REQUIRED_PACKAGES: [],
  },
  TRACEABILITY: {
    NAME: 'traceability',
    PAGE: 'products/traceability',
    START: '/traceability/overview',
    REQUIRED_PACKAGES: ['tokenization'],
  },
  CONTRACT_MANAGER: {
    NAME: 'contractManager',
    PAGE: 'products/contract-manager',
    START: '/contracts',
    REQUIRED_PACKAGES: ['tokenization'],
  },
};

export const ORG_TYPES = {
  USER: 'user',
  STANDARD: 'standard',
  ENTERPRISE: 'enterprise',
};

export const TRIAL_PACKAGES = {
  development: {
    TOKENIZATION: ['621f8b500f1fc3cffb9e2069'],
    NOTARIZATION: ['621f8b310f1fc3cffb9e205f'],
    TRACEABILITY: ['621f8bb20f1fc3cffb9e2072', '621f8b500f1fc3cffb9e2069'],
    CONTRACT_MANAGER: ['6241f4d51f8e6746bc66bdab', '621f8b500f1fc3cffb9e2069'],
  },
  stage: {
    TOKENIZATION: ['6227908e03051795f277d1e8'],
    NOTARIZATION: ['62279061e3118358000d426b'],
    TRACEABILITY: ['622790b1de8975db83998d06', '6227908e03051795f277d1e8'],
    CONTRACT_MANAGER: ['6241c5d487011ebe0127df67', '6227908e03051795f277d1e8'],
  },
  production: {
    TOKENIZATION: ['6214bfeb01c3b49c05762639'],
    NOTARIZATION: ['6214c0365d1a24960589c534'],
    TRACEABILITY: ['6214c05ae9a225c84fecb01e', '6214bfeb01c3b49c05762639'],
    CONTRACT_MANAGER: ['623b594cf622112845e5c229', '6214bfeb01c3b49c05762639'],
  },
};

export const ENTERPRISE_PACKAGES = {
  development: '6228d7300f1fc3cffb9e20a2',
  stage: '6228dcdbde8975db83998d0d',
  production: '6228deb8e0528d16ed569e17',
};
