import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../constants';
import BlockchainInfo from '../Shared/BlockchainInfo';

const useStyles = makeStyles(theme => ({
  paper: {
    top: theme.spacing(10),
    position: 'absolute',
    width: '60%',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  button: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    height: '10px',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  formArea: {
    paddingTop: theme.spacing(2),
  },
}));

const BatchTransferErc1155Token = ({
  open,
  onSubmit,
  closeModal,
  contractAddress,
  tokens,
  loading,
  isPrivate,
  smartContractName,
  processType,
  recipientOrganizationName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();

  const handleClose = () => {
    closeModal();
  };

  const values = useMemo(() => {}, []);

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose}>
      <Paper className={classes.paper}>
        {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER && (
          <>
            <Typography variant="h4" className={classes.title}>
              {t('tokens.batchTransferModal.header')}
            </Typography>
            <Box component="div" my={2}>
              <Typography variant="body1">
                {t('tokens.batchTransferModal.subHeader.part1')} <strong>{tokens.length}</strong>{' '}
                {tokens.length > 1 ? t('common.tokens') : t('common.token')} {t('common.to')}{' '}
                <strong>{recipientOrganizationName}</strong>{' '}
                {t('tokens.batchTransferModal.subHeader.part2')}{' '}
                <strong>{smartContractName}</strong>{' '}
                {t('tokens.batchTransferModal.subHeader.part3')}
              </Typography>
            </Box>
          </>
        )}
        {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_BURN && (
          <>
            <Typography variant="h4" className={classes.title}>
              {t('tokens.deleteTokensFees')}
            </Typography>
          </>
        )}
        <Box component="div" mb={2}>
          <Typography variant="body1" component="div" mb={2}>
            {t('tokens.wantToProceed')}
          </Typography>
        </Box>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC && !isPrivate ? (
          <BlockchainInfo
            validateForm={() => {}}
            values={values}
            contractAddress={contractAddress}
            method={
              processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
                ? CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
                : CONSTANTS.TRANSACTION_TYPES.BATCH_BURN
            }
            methodArgs={
              processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
                ? [activeWallet?.address, activeWallet?.address, '[]', '[]']
                : [activeWallet?.address, '[]', '[]']
            }
            byPassValidation
          />
        ) : (
          ''
        )}
        <Divider className={classes.divider} />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={onSubmit}
        >
          {processType === CONSTANTS.TRANSACTION_TYPES.BATCH_TRANSFER
            ? t('tokens.transfer')
            : t('tokens.delete')}
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          className={classes.cancelButton}
        >
          {t('common.cancel')}
        </Button>
      </Paper>
    </Modal>
  );
};

BatchTransferErc1155Token.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  tokens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  smartContractName: PropTypes.string.isRequired,
  recipientOrganizationName: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  processType: PropTypes.string.isRequired,
};

BatchTransferErc1155Token.defaultProps = {
  contractAddress: '',
  loading: false,
  isPrivate: false,
};

export default BatchTransferErc1155Token;
