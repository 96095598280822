import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  emptyBannerRoot: {
    background: theme.palette.primary.lightest,
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33em',
    flexDirection: 'column',
    border: `1px dotted ${theme.palette.primary.lighter}`,
    marginTop: '1em',
    marginBottom: '1em',
  },
  banner: {
    marginTop: '1em',
  },
}));

const EmptyBanner = ({ bannerText, onButtonClick, headerText, buttonText }) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyBannerRoot}>
      <div className={classes.headerArea}>
        <Typography variant="h4">{headerText}</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={onButtonClick}
          data-testid="create-chain-button"
        >
          {buttonText}
        </Button>
      </div>
      <div className={classes.contentArea}>
        <img src="/static/valueChainSystem.svg" alt="" />
        <Typography variant="h5" className={classes.banner} data-testid="empty-banner-text">
          {bannerText}
        </Typography>
      </div>
    </div>
  );
};

EmptyBanner.propTypes = {
  bannerText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
};

EmptyBanner.defaultProps = {};

export default EmptyBanner;
