import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import * as Yup from 'yup';
import { CONSTANTS, METADATA_BEHAVIORS } from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import useUserInfo from '../../../hooks/useUserInfo';
import track from '../../../mixpanel';
import PaymentConfirmation from '../../Shared/PaymentConfirmation';
import { useTokenContext } from '../../../contexts/Tokenization/token';

const useStyles = makeStyles(theme => ({
  TokenMetaDataRoot: {
    '& .MuiButton-outlinedPrimary': {
      border: '0px',
    },
  },
  rawModeSwitch: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  divider: {
    background: theme.palette.primary.lighter,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  errorDisplay: {
    boxShadow: 'none',
  },
  submitButton: {
    marginBottom: theme.spacing(2),
  },
  inProgressBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
  },
  inProgressBanner: {
    marginRight: theme.spacing(1),
  },
  addrErrBox: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  buttonArea: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: theme.spacing(1),
  },
  loaderArea: {
    display: 'flex',
    marginLeft: '2em',
  },
  CardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    paddingLeft: '10px',
    marginTop: '5px',
    paddingRight: theme.spacing(2),
    boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
    marginBottom: theme.spacing(2),
  },
  bannerArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  headerArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  perChip: {
    marginRight: '10px',
    borderColor: '#DCE7F4',
    backgroundColor: '#DCE7F4',
  },
  mutableChip: {
    marginRight: '10px',
    borderColor: '#DCEDE1',
    backgroundColor: '#DCEDE1',
  },
  immutableChip: {
    marginRight: '10px',
    borderColor: '#F5DFDD',
    backgroundColor: '#F5DFDD',
  },
  downloadButton: {
    height: '25px',
  },
  noStructBanner: {
    marginTop: theme.spacing(2),
  },
  buttonAreaHidden: {
    visibility: 'hidden',
  },
}));

const DirectMetadataPanel = forwardRef(
  (
    { selectedToken, updateTokenMetadata, isFormProcessing, batchMetaData, setIsButtonDisable },
    ref,
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { handleNotification } = useNotification();
    const {
      permissionsFlags: { isUserAuthToSetERC721Metadata, isUserAuthToSetERC1155Metadata },
    } = useUserInfo();
    const { activeWallet } = useBecOrganizationContext();
    const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
    const { batchMetadataUpdateErc721TokenEth } = useTokenContext();

    const isERC1155 =
      selectedToken?.smartContract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;

    const isUserAuthToSetERCMetadata = isERC1155
      ? isUserAuthToSetERC1155Metadata
      : isUserAuthToSetERC721Metadata;

    const submitForm = async values => {
      try {
        let inputMetaData;
        let response;
        let metaDataResponse;
        if (batchMetaData) {
          const tokenList = selectedToken.map(token => {
            return token.tokenId;
          });

          response = await batchMetadataUpdateErc721TokenEth({
            variables: {
              input: {
                contractAddress: selectedToken[0]?.smartContract?.contractAddress,
                tokenId: tokenList,
                metadata: values.newMetadata,
                tokenBatchId: selectedToken[0]?.tokenBatchId,
                behavior: METADATA_BEHAVIORS.DIRECT,
              },
            },
          });
          metaDataResponse = response?.data?.setTokenBulkMetadataERC721;
        } else {
          inputMetaData = {
            variables: {
              input: {
                contractAddress: selectedToken?.smartContract?.contractAddress,
                ...(selectedToken?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
                  ? {
                      tokens: [selectedToken?.tokenId],
                    }
                  : {
                      tokenId: selectedToken?.tokenId,
                    }),
                metadata: values.newMetadata,
                behavior: METADATA_BEHAVIORS.DIRECT,
              },
            },
          };
          response = await updateTokenMetadata(inputMetaData);
          metaDataResponse = response?.data?.setTokenMetadataERC721;
        }

        if (metaDataResponse?.updateTriggeredOnChain) {
          handleNotification(t('tokens.transactionSubmitted'), 'success');
          track('ERC721 Token metadata has been updated', {
            contract: metaDataResponse?.metadataTransaction?.contractAddress,
            transactionHash: metaDataResponse?.metadataTransaction?.transactionHash,
          });
        } else {
          handleNotification(t('tokens.success.tokenMetadataSaveSuccess'), 'success');
        }
      } catch (error) {
        const errorString = error?.graphQLErrors
          ? t(`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`)
          : t('tokens.transactionError');
        handleNotification(errorString, 'error');
      }
    };

    const formik = useFormik({
      initialValues: {
        newMetadata: batchMetaData
          ? selectedToken[0]?.metadata || null
          : selectedToken?.metadata || null,
      },
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        newMetadata: Yup.string().required(t('tokens.validation.metadataValueRequired')),
      }),
      onSubmit: submitForm,
    });

    const { values } = formik;

    const getErrors = (dirty, errors) => {
      if (!dirty) return null;
      const errorsToDisplay = [];
      const fieldsWithErrors = Object.keys(errors);

      if (fieldsWithErrors?.length > 0) {
        fieldsWithErrors.forEach(field => {
          errorsToDisplay.push(
            <Typography key={uniqid()} variant="body2">
              {errors[field]}
            </Typography>,
          );
        });
      }

      if (errorsToDisplay.length < 1) return null;

      return (
        <Box display="flex" flexDirection="row" p={2} my={2}>
          <Box pr={2}>
            <ErrorIcon color="error" />
          </Box>
          <Box>{errorsToDisplay}</Box>
        </Box>
      );
    };

    const handleSubmit = e => {
      if (!batchMetaData) {
        if (
          activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.ETH ||
          activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.MATIC
        ) {
          e.preventDefault();
          setIsPaymentPopupOpen(true);
        } else {
          e.preventDefault();
          formik.handleSubmit();
        }
      } else {
        e.preventDefault();
        formik.handleSubmit();
      }
    };

    useEffect(() => {
      if (batchMetaData) {
        if (!formik.dirty || !formik.isValid || isFormProcessing) {
          setIsButtonDisable(true);
        } else {
          setIsButtonDisable(false);
        }
      }
    }, [formik.dirty, formik.isValid, isFormProcessing]);

    return (
      <div className={classes.TokenMetaDataRoot}>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Field name="newMetadata" component={TextField} multiline rows={3} />
            {getErrors(formik?.dirty, formik?.errors)}

            <PaymentConfirmation
              open={isPaymentPopupOpen}
              closeModal={() => setIsPaymentPopupOpen(false)}
              contractAddress={selectedToken?.smartContract?.contractAddress}
              formik={formik}
              method={CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA_ON_CHAIN}
              methodArgs={[selectedToken?.tokenId?.toString(), JSON.stringify(values)]}
            />

            {isUserAuthToSetERCMetadata && !batchMetaData && (
              <div className={classes.buttonArea}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.submitButton}
                  disabled={!formik.dirty || !formik.isValid || isFormProcessing}
                >
                  {t('tokens.updateMetadata')}
                </Button>
                {isFormProcessing && (
                  <div className={classes.loaderArea}>
                    <Typography variant="body2">{t('tokens.metaDataUpdateProgress')}</Typography>
                    <CircularProgress size={20} thickness={20} />
                  </div>
                )}
              </div>
            )}
            {batchMetaData && (
              <div className={classes.buttonAreaHidden}>
                <button type="submit" ref={ref} aria-label="hidden-button" />
              </div>
            )}
          </Form>
        </FormikProvider>
      </div>
    );
  },
);

DirectMetadataPanel.propTypes = {
  selectedToken: PropTypes.oneOfType([
    PropTypes.shape({
      metadata: PropTypes.shape({
        links: PropTypes.shape({}),
      }),
      metadataUrls: PropTypes.shape({
        publicImmutable: PropTypes.string,
        privateImmutable: PropTypes.string,
        publicMutable: PropTypes.string,
        privateMutable: PropTypes.string,
      }),
      tokenId: PropTypes.string.isRequired,
      latestMetadataTransaction: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
      smartContract: PropTypes.shape({
        contractAddress: PropTypes.string.isRequired,
        tokenType: PropTypes.string.isRequired,
        metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        metadata: PropTypes.shape({
          links: PropTypes.shape({}),
        }),
        metadataUrls: PropTypes.shape({
          publicImmutable: PropTypes.string,
          privateImmutable: PropTypes.string,
          publicMutable: PropTypes.string,
          privateMutable: PropTypes.string,
        }),
        tokenId: PropTypes.string.isRequired,
        latestMetadataTransaction: PropTypes.shape({
          status: PropTypes.string.isRequired,
        }),
        smartContract: PropTypes.shape({
          contractAddress: PropTypes.string.isRequired,
          metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    ),
  ]),
  updateTokenMetadata: PropTypes.func.isRequired,
  isFormProcessing: PropTypes.bool,
  batchMetaData: PropTypes.bool,
  setIsButtonDisable: PropTypes.func,
};

DirectMetadataPanel.defaultProps = {
  selectedToken: null,
  isFormProcessing: false,
  batchMetaData: false,
  setIsButtonDisable: () => {},
};

export default DirectMetadataPanel;
