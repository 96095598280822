import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { GET_COMPANY, CREATE_COMPANY, UPDATE_COMPANY } from '../../graphql/Procurement/company';
import { useNotification } from './notification';
import track from '../../mixpanel';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [company, setCompany] = useState({});
  const { handleNotification } = useNotification();

  const { t } = useTranslation();

  const [getCurrentUser, { loading }] = useLazyQuery(GET_COMPANY, {
    onCompleted: data => {
      setCompany(data.getCompanyByAuth0Id || {});
    },
    fetchPolicy: 'no-cache',
  });

  const [registerCompany] = useMutation(CREATE_COMPANY, {
    onCompleted: data => {
      setCompany(data.createCompany);
      handleNotification(t('myCompany.registerCompanySuccess'), 'success');
      track('Organization Registration status received', { OrganizationRegistrationSuccess: true });
    },
    onError: () => {
      handleNotification(t('myCompany.registerCompanyError'), 'error');
      track('Organization Registration status received', {
        OrganizationRegistrationSuccess: false,
      });
    },
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: data => {
      setCompany(data.updateCompany);
      handleNotification(t('myCompany.updateSuccess'), 'success');
    },
    onError: () => {
      handleNotification(t('myCompany.updateError'), 'error');
    },
  });

  return (
    <AuthContext.Provider
      value={{
        company,
        loading,
        getCurrentUser,
        registerCompany,
        updateCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useAuth, AuthProvider };
