import React, { useEffect } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { makeStyles } from '@material-ui/styles';
import uniqid from 'uniqid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import capitalize from 'lodash/capitalize';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import TableSearch from '../../components/Shared/TableSearch';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import { useAuth } from '../../contexts/Shared/auth';
import { usePurchaseOrderContext } from '../../contexts/Procurement/purchaseOrder';
import TableActions from '../../components/Shared/TableActions';
import track from '../../mixpanel';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import { formatCurrencySymbol } from '../../utils';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      boxShadow: '0 -1.75px 0 0 rgb(0 0 0 / 4%)',
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  positionElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  openChip: {
    background: '#A9A9A9',
  },
  pendingChip: { background: '#FF9831' },
  acceptedChip: { background: 'rgb(22 134 54)', color: '#fff' },
  deliveredChip: { background: 'rgb(22 134 54)', color: '#fff' },
  failedChip: {
    background: theme.colors.red,
    color: theme.colors.white,
  },
  loaderContainer: theme.loaderContainer,
  purchaseOrderListRoot: {
    background: theme.palette.primary.lightest,
    padding: '16px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  purchaseOrderHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addressesLinks: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      color: theme.colors.blue,
      letterSpacing: 0,
      lineHeight: `${theme.spacing(3)}px`,
    },
    cursor: 'pointer',
  },
}));

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { company } = useAuth();
  const {
    purchaseOrders,
    purchaseOrderLoading: loading,
    getPurchaseOrders,
  } = usePurchaseOrderContext();
  const { euroCurrencySymbol } = useSubscriptionContext();
  const { activeOrganization } = useBecOrganizationContext();
  const { userPermissions } = useUserInfo();
  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;
  useEffect(() => {
    getPurchaseOrders();
  }, [getPurchaseOrders, company]);

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(
        PERMISSIONS.CONTRACT_MANAGER.GETPURCHASEORDERS,
      ) &&
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATEPURCHASEORDER)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  const columns = [
    {
      name: 'purchaseOrderId',
      label: t('pos.poId'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return `PO-${value.padStart(10, 0)}`;
        },
      },
    },
    {
      name: 'contractDetails',
      label: t('pos.contractId'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return (
            <div
              className={classes.addressesLinks}
              onClick={() => {
                history.push(`/contracts/${value._id}`);
              }}
              aria-hidden="true"
              data-testid={`poId-${value.poId}`}
            >
              <Typography variant="body2">{`CT-${value.contractId.padStart(10, 0)}`}</Typography>
            </div>
          );
        },
        sortCompare: order => {
          return (a, b) => {
            const val1 = parseInt(a.data.contractId, 10);
            const val2 = parseInt(b.data.contractId, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'businessPartnerBuyer',
      label: t('common.businessPartnerBuyer'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'businessPartnerSupplier',
      label: t('common.businessPartnerSupplier'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'material',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          const materialArr = value ? value.split(',') : [];
          const totalMaterial = materialArr.length;
          if (materialArr.length > 5) {
            materialArr.splice(6);
            materialArr.push(t('common.more'));
          }
          return (
            <div className={classes.associatedBox}>
              <Typography>{materialArr[0]}</Typography>
              {materialArr.length > 1 ? (
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={
                    <span>
                      {materialArr.map((material, key) => {
                        return key !== 0 ? <p key={uniqid()}>{` - ${material}`}</p> : '';
                      })}
                    </span>
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <Chip
                      label={materialArr.length > 0 ? `+${totalMaterial - 1}` : ''}
                      variant="outlined"
                      classes={{
                        root: classes.chipData,
                        label: classes.chipLabel,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    },
    // {
    //   name: 'poQuantity',
    //   label: t('common.quantity'),
    //   options: {
    //     customHeadRender: (columnMeta, handleToggleColumn) => (
    //       <TableHeadCell
    //         key={uniqid()}
    //         columnMeta={columnMeta}
    //         handleToggleColumn={handleToggleColumn}
    //       />
    //     ),
    //     customBodyRender: value => {
    //       return (
    //         <div>{value === 'Multi' ? <span>{t('common.multi')}</span> : <span>{value}</span>}</div>
    //       );
    //     },
    //   },
    // },
    {
      name: 'netValue',
      label: t('pos.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          if (value === 'Action-required') {
            return <Chip className={classes.pendingChip} label={t('common.actionRequired')} />;
          }
          if (value === 'Deploying') {
            return <Chip className={classes.pendingChip} label={t('pos.comparingPricing')} />;
          }
          if (value === 'Open') {
            return <Chip className={classes.openChip} label={t('common.open')} />;
          }
          if (value === 'Accepted') {
            return <Chip className={classes.acceptedChip} label={t('common.accepted')} />;
          }
          if (value === 'Failed') {
            return <Chip className={classes.failedChip} label={t('common.failed')} />;
          }
          return <Chip className={classes.deliveredChip} label={t('common.delivered')} />;
        },
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="left"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return (
            <TableActions
              options={[
                {
                  label: t('pos.viewPO'),
                  buttonText: t('pos.viewPO'),
                  testId: `purchase-order-${value}`,
                  handleClick: () => {
                    history.push(`/purchase-orders/${value}`);
                  },
                },
              ]}
              testId={`purchase-order-${value}`}
            />
          );
        },
      },
    },
  ];

  const businessPartner = purchaseOrder => {
    return purchaseOrder.isSender === true
      ? purchaseOrder.supplier.organization.name
      : purchaseOrder.buyer.organization.name;
  };

  const businessPartnerBuyer = purchaseOrder => {
    return purchaseOrder.buyer.organization.name;
  };

  const businessPartnerSupplier = purchaseOrder => {
    return purchaseOrder.supplier.organization.name;
  };

  const getFormattedMaterial = materials => {
    const materialArr = materials.map(material => material.materialName);
    const materialComma = materialArr.toString();

    return materialComma;
  };

  const formatRow = purchaseOrder => {
    return {
      id: purchaseOrder._id,
      contractDetails: {
        contractId: purchaseOrder.contract.contractId,
        _id: purchaseOrder.contract._id,
        poId: purchaseOrder._id,
      },
      purchaseOrderId: purchaseOrder.purchaseOrderId || '',
      businessPartner: businessPartner(purchaseOrder),
      businessPartnerBuyer: businessPartnerBuyer(purchaseOrder),
      businessPartnerSupplier: businessPartnerSupplier(purchaseOrder),
      material: getFormattedMaterial(purchaseOrder.materials),
      poQuantity:
        purchaseOrder.materials.length > 1 ? 'Multi' : purchaseOrder.materials[0].volume.toString(),
      netValue: `${formatCurrencySymbol(purchaseOrder.totalPrice, euroCurrencySymbol)}`,
      status: capitalize(purchaseOrder.status),
    };
  };

  const handleDeploy = () => {
    history.push(`/purchase-order/create`);
  };

  return (
    <>
      {!isUserNotAuthorized() && loading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {!isUserNotAuthorized() && (
            <>
              <div className={classes.purchaseOrderListRoot}>
                <div className={classes.purchaseOrderHeaderArea}>
                  <Typography variant="h3">{t('pos.purchaseOrders')}</Typography>

                  {userPermissions?.contract_manager?.includes(
                    PERMISSIONS.CONTRACT_MANAGER.CREATEPURCHASEORDER,
                  ) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<PostAddIcon />}
                      onClick={handleDeploy}
                      data-testid="createPurchaseOrder"
                    >
                      {t('pos.createPurchaseOrder')}
                    </Button>
                  )}
                </div>
              </div>
              <div className="purchase-orders">
                <Table
                  title={t('pos.purchaseOrders')}
                  className={classes.table}
                  columns={columns}
                  data={
                    userPermissions?.contract_manager?.includes(
                      PERMISSIONS.CONTRACT_MANAGER.GETPURCHASEORDERS,
                    )
                      ? [...(purchaseOrders?.map(purchaseOrder => formatRow(purchaseOrder)) || [])]
                      : []
                  }
                  options={{
                    pagination: true,
                    filter: false,
                    search: false,
                    searchOpen: true,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                    onRowClick: row => {
                      track('View Purchase Order Detailed View', { status: row[6]?.props?.label });
                    },
                    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
                      <TableSearch
                        searchText={searchText}
                        handleSearch={handleSearch}
                        hideSearch={hideSearch}
                        options={options}
                        placeHolderText={t('pos.searchPurchaseOrders')}
                        createButtonText={t('pos.createPurchaseOrder')}
                        testId="createPurchaseOrder"
                      />
                    ),
                  }}
                />
              </div>
            </>
          )}
          {isUserNotAuthorized()}
        </>
      )}
    </>
  );
};

export default PurchaseOrders;
