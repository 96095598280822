import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useNotification } from '../../contexts/Shared/notification';
import PageLoader from '../Shared/PageLoader';
import { useTokenContext } from '../../contexts/Tokenization/token';
import {
  ASSOCIATE_TOKEN_LIST_TO_PO,
  DISSOCIATE_SMART_CONTRACT_TO_PO,
} from '../../graphql/Tokenization/token';
import ContractForm from '../../pages/Tokenization/ContractForm';
import BulkMinting from '../../pages/Tokenization/BulkMinting';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import { formatCurrencySymbol, getCurrencyName } from '../../utils';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    minWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  divider: {
    background: '#e0e0e0',
    marginBottom: '.5rem',
    marginTop: '.5rem',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  title: {
    fontWeight: '400',
    paddingBottom: theme.spacing(1.5),
    fontSize: '1.5rem',
  },
  subTitle: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  materialTitle: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: '500',
    color: theme.palette.primary.light,
  },
  infoContainer: {
    padding: '1rem',
    display: 'flex',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '1rem',
  },
  descriptionViewCard: {
    padding: '0 1rem',
  },
  goodDetails: {
    marginTop: '-2rem',
    padding: '1rem',
    height: theme.spacing(16),
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  iconContainer: {
    paddingTop: '1.5rem',
  },
  message: {
    display: 'flex',
    fontSize: '1rem',
    paddingBottom: '1rem',
    paddingTop: '1.5rem',
  },
  descriptor: {
    color: theme.palette.primary.main,
    fontFamily: 'EYInterstate',
    fontSize: '12px',
    fontWeight: '300',
  },
  descriptorDark: {
    fontFamily: 'EYInterstate',
    fontSize: '14px',
    fontWeight: '400',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  mainButtonBox: {
    padding: '1rem',
  },
  mainButton: {
    margin: '0 0.5rem',
  },
  associatedCounter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '1rem',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  button: {
    margin: theme.spacing(2),
  },
  errorMessage: {
    color: 'red',
  },
}));

const AssociateToken = ({ purchaseOrder, setAssociateTokensView, setRefreshFlag, materialId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getTokenContracts,
    tokenContracts,
    getTokenContractsFromPO,
    tokenContractsLoading,
    tokenContractsByPageLoading,
  } = useTokenContext();

  const [selectedTokens, setSelectedTokens] = useState([]);
  const [disableTokenAssociation, setDisableTokenAssociation] = useState(true);
  const [hideMoreQtyLabel, setHideMoreQtyLabel] = useState(true);
  const { activeWallet } = useBecOrganizationContext();
  const [bulkMintErc721FormOpen, setBulkMintErc721FormOpen] = useState(false);
  const { euroCurrencySymbol } = useSubscriptionContext();
  const { handleNotification } = useNotification();
  const { userPermissions } = useUserInfo();

  const [associateERC721TokenPurchaseOrder] = useMutation(ASSOCIATE_TOKEN_LIST_TO_PO, {
    onCompleted: data => {
      const { associateERC721TokenPurchaseOrder: poData } = data;
      handleNotification(
        t('tokenizedGoods.associationSuccess', {
          quantity: poData.length,
          contract: poData[0]?.smartContract?.contractName,
          material: purchaseOrder?.materials[materialId]?.materialName,
        }),
        'success',
      );
      setAssociateTokensView(false);
      setRefreshFlag(true);
    },
    onError: () => {
      handleNotification(t('tokenizedGoods.associationFailure'), 'error');
    },
    fetchPolicy: 'no-cache',
  });

  const [dissociateERC721SmartContractPurchaseOrder] = useMutation(
    DISSOCIATE_SMART_CONTRACT_TO_PO,
    {
      onCompleted: () => {
        handleNotification(
          t('tokenizedGoods.dissociationSuccess', {
            material: purchaseOrder?.materials[materialId]?.materialName,
          }),
          'success',
        );
        setAssociateTokensView(false);
        setRefreshFlag(true);
      },
      onError: () => {
        handleNotification(t('tokenizedGoods.associationFailure'), 'error');
      },
      fetchPolicy: 'no-cache',
    },
  );

  const handleAssociate = async () => {
    if (
      !userPermissions?.contract_manager?.includes(
        PERMISSIONS.CONTRACT_MANAGER.DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER,
      ) &&
      selectedTokens.length === 0
    ) {
      handleNotification(t('common.errors.unauthorized_user'), 'error');
      return;
    }
    if (selectedTokens.length === 0 && purchaseOrder.materials[materialId]?.smartContractId) {
      await dissociateERC721SmartContractPurchaseOrder({
        variables: {
          input: {
            tokens: [
              {
                _id: '',
                latestOwner: '',
                blockchainType: '',
                tokenType: '',
                tokenId: '',
                smartContractId: purchaseOrder.materials[materialId].smartContractId,
              },
            ],
            purchaseOrderId: purchaseOrder.purchaseOrderId,
          },
        },
      });
      return;
    }
    const formatList = selectedTokens.map(oneSelectedToken => {
      const newToken = {
        _id: oneSelectedToken._id,
        smartContractId: oneSelectedToken.smartContract._id,
      };
      return newToken;
    });
    await associateERC721TokenPurchaseOrder({
      variables: {
        input: {
          tokens: formatList,
          purchaseOrderId: purchaseOrder.purchaseOrderId,
          buyerOrganizationId: purchaseOrder.buyer.id,
          sku: purchaseOrder.contract.materials[materialId].sku,
        },
      },
    });
  };
  useEffect(() => {
    setHideMoreQtyLabel(false);
    if (
      (selectedTokens.length === 0 && !purchaseOrder.materials[materialId]?.smartContractId) ||
      selectedTokens.length > purchaseOrder.volume
    ) {
      if (selectedTokens.length > purchaseOrder.volume) setHideMoreQtyLabel(true);
      setDisableTokenAssociation(true);
    } else {
      setDisableTokenAssociation(false);
    }
  }, [selectedTokens]);

  useEffect(() => {
    if (purchaseOrder.isSender) {
      getTokenContractsFromPO({
        variables: {
          purchaseOrderId: purchaseOrder?._id,
          buyerOrganizationId: purchaseOrder.buyer.id,
          supplierOrganizationId: purchaseOrder.supplier.id,
          smartContractId: purchaseOrder?.materials[materialId]?.smartContractId,
        },
      });
    } else {
      getTokenContracts();
    }
  }, [getTokenContracts, activeWallet]);

  if (tokenContractsLoading || tokenContractsByPageLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  if (bulkMintErc721FormOpen) {
    return <BulkMinting hideBulkMinting={setBulkMintErc721FormOpen} />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.navContainer}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => setAssociateTokensView(false)}
          className={classes.navButton}
          startIcon={<ArrowBackIosIcon />}
        >
          {`Purchase Order PO-${purchaseOrder.purchaseOrderId.padStart(10, 0)}`}
        </Button>
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Card className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <div className={classes.descriptionViewCard}>
            <Typography variant="h5" classes={{ root: classes.title }}>
              {t('tokenizedGoods.associateGoods')}
            </Typography>
          </div>
          <div className={classes.associatedCounter}>
            <Typography variant="h5" className={classes.materialTitle}>
              {t('tokenizedGoods.associated')}
            </Typography>
            <Typography variant="h5" classes={{ root: classes.title }}>
              {purchaseOrder.materials[materialId]
                ? purchaseOrder.materials[materialId].tokenIds.length
                : 0}
              /{purchaseOrder.materials[materialId].volume}
            </Typography>
          </div>
        </Box>
        <Grid container className={classes.goodDetails}>
          <Grid item xs={2}>
            <Typography variant="h5" className={classes.materialTitle}>
              {t('tokenizedGoods.material')}
            </Typography>
            <Typography variant="body1" display="inline" className={classes.title}>
              {purchaseOrder.materials[materialId].materialName}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.infoContainer}>
            <div className={classes.infoBlock}>
              <Typography variant="body1" display="inline" className={classes.descriptor}>
                {t('tokenizedGoods.description')}
              </Typography>
              <Typography variant="body1" display="inline" className={classes.descriptor}>
                {t('tokenizedGoods.quantity')}
              </Typography>
            </div>
            <div className={classes.infoBlock}>
              <Typography variant="body1" display="inline" className={classes.descriptorDark}>
                {purchaseOrder.contract.materials[materialId].materialDescription}
              </Typography>
              <Typography variant="body1" display="inline" className={classes.descriptorDark}>
                {purchaseOrder.materials[materialId].volume}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} className={classes.infoContainer}>
            <div className={classes.infoBlock}>
              <Typography variant="body1" display="inline" className={classes.descriptor}>
                {t('tokenizedGoods.netValue')}
              </Typography>
              {purchaseOrder.contract.contractType !== 'rebatePricing' ? (
                <Typography variant="body1" display="inline" className={classes.descriptor}>
                  {t('tokenizedGoods.netPrice')}
                </Typography>
              ) : (
                ''
              )}
            </div>
            <div className={classes.infoBlock}>
              <Typography variant="body1" display="inline" className={classes.descriptorDark}>
                {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                  purchaseOrder.materials[materialId].totalPrice,
                  euroCurrencySymbol,
                )}`}
              </Typography>
              {purchaseOrder.contract.contractType !== 'rebatePricing' ? (
                <Typography variant="body1" display="inline" className={classes.descriptorDark}>
                  {purchaseOrder.contract.materials[materialId].tierRules.pricesByTier.map(
                    price =>
                      `${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                        price,
                        euroCurrencySymbol,
                      )}   `,
                  )}
                </Typography>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>

        <Divider classes={{ root: classes.divider }} />

        <Box display="flex">
          <div className={classes.descriptionViewCard}>
            <Typography variant="body1" display="inline" className={classes.message}>
              {t('tokenizedGoods.goodsViewInstruction')}
            </Typography>
          </div>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            title={
              <span>
                <p>{t('tokenizedGoods.goodsInfoCard')}</p>
                <p>{t('tokenizedGoods.learnMore')}</p>
              </span>
            }
            placement="top"
            arrow
          >
            <div className={classes.iconContainer}>
              <InfoIcon className={classes.icon} />
            </div>
          </Tooltip>
        </Box>
        <Typography variant="h5" classes={{ root: classes.subTitle }}>
          {' '}
          {t('tokenizedGoods.goodsType')}{' '}
        </Typography>
        <ContractForm
          setSelectedTokens={setSelectedTokens}
          purchaseOrder={purchaseOrder}
          materialId={materialId}
          tokenList={tokenContracts}
          isBuyer={purchaseOrder?.isSender}
          bulkMintingHandler={setBulkMintErc721FormOpen}
        />
      </Card>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.mainButtonBox}
      >
        {!purchaseOrder.isSender &&
          (userPermissions?.contract_manager?.includes(
            PERMISSIONS.CONTRACT_MANAGER.ASSOCIATEERC721TOKENPURCHASEORDER,
          ) ||
            userPermissions?.contract_manager?.includes(
              PERMISSIONS.CONTRACT_MANAGER.DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER,
            )) && (
            <>
              <Button
                onClick={() => setAssociateTokensView(false)}
                variant="outlined"
                color="primary"
                className={classes.mainButton}
              >
                {t('common.cancel')}
              </Button>
              <Button
                className={clsx(classes.button, classes.mainButton)}
                variant="contained"
                color="secondary"
                disabled={disableTokenAssociation}
                onClick={handleAssociate}
                data-testid="associateGoods-button"
              >
                {t('tokenizedGoods.button.associate')}
              </Button>
            </>
          )}
        {hideMoreQtyLabel && (
          <span className={classes.errorMessage}>{t('tokenizedGoods.noMoreQty')}</span>
        )}
      </Box>
    </>
  );
};

AssociateToken.propTypes = {
  purchaseOrder: PropTypes.shape({
    purchaseOrderId: PropTypes.string.isRequired,
    volume: PropTypes.string,
    material: PropTypes.array,
    _id: PropTypes.string.isRequired,
    contract: PropTypes.shape({
      materialDescription: PropTypes.string,
      pricesByTier: PropTypes.array,
      contractType: PropTypes.string,
      materials: PropTypes.any,
    }).isRequired,
    buyer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    totalPrice: PropTypes.number.isRequired,
    materials: PropTypes.array,
    isSender: PropTypes.bool,
    supplier: PropTypes.object,
  }).isRequired,
  setAssociateTokensView: PropTypes.func.isRequired,
  setRefreshFlag: PropTypes.func.isRequired,
  materialId: PropTypes.number.isRequired,
};

export default AssociateToken;
