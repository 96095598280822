import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ethers } from 'ethers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';
import { getWalletAddress } from '../../utils';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';
import TransferToolTip from './TransferTooltip';

const useStyles = makeStyles(theme => ({
  paper: {
    top: theme.spacing(10),
    position: 'absolute',
    width: '60%',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  button: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    height: '10px',
  },
  submitButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  formArea: {
    paddingTop: theme.spacing(2),
  },
  checkField: {
    marginTop: '1rem',
    alignItems: 'center',
  },
}));

const TransferErc721Token = ({
  open,
  closeModal,
  onSubmit,
  contractAddress,
  currentUserEthAddress,
  tokenId,
  loading,
  isPrivate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  const { getPartners, partners } = usePartnerContext();

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    const companySelectOptionsTemp = partners.map(partner => {
      return {
        label: partner.partnerOrganization.name,
        value: getWalletAddress(partner.partnerOrganization.wallets, activeWallet),
      };
    });
    setCompanySelectOptions(companySelectOptionsTemp);
  }, [partners, activeWallet]);

  const formik = useFormik({
    initialValues: {
      recipientEthereumAddress: {
        label: '',
        value: '',
      },
      transferReason: '',
      externalRecipientAddress: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      let res;
      if (formik.values.externalRecipient) {
        res = await onSubmit({
          variables: {
            input: {
              contractAddress,
              tokenId,
              recipient: values?.externalRecipientAddress,
              reason: values?.transferReason,
              becOverride: true,
            },
          },
        });
      } else {
        res = await onSubmit({
          variables: {
            input: {
              contractAddress,
              tokenId,
              recipient: values?.recipientEthereumAddress?.value,
              reason: values?.transferReason,
            },
          },
        });
      }
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema: Yup.object().shape({
      transferReason: Yup.string().required(t('tokens.validation.transfer_reason')),
      recipientEthereumAddress: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().when('externalRecipient', {
          is: false,
          then: Yup.string()
            .required(t('tokens.validation.recipientEthereumAddress'))
            .test('is-valid-eth-address', t('tokens.validation.ethereumAddress'), val => {
              try {
                return ethers.utils.getAddress(val) && true;
              } catch (e) {
                return false;
              }
            })
            .test(
              'is-not-own-eth-address',
              t('tokens.validation.sameEthereumAddress'),
              val => val !== currentUserEthAddress,
            ),
        }),
      }),
      externalRecipientAddress: Yup.string().when('externalRecipient', {
        is: true,
        then: Yup.string()
          .required(t('tokens.validation.recipientEthereumAddress'))
          .matches(/^0x[a-fA-F0-9]{40}$/, t('tokens.validation.validEthereumAddress')),
      }),
    }),
  });

  // flatten recipientEthereumAddress errors to avoid conflict within FormErrors component
  if (formik.errors) {
    const recipientErrors = formik.errors.recipientEthereumAddress;
    if (recipientErrors?.value) {
      formik.errors = {
        ...formik.errors,
        recipientEthereumAddress: recipientErrors.value,
      };
    }
  }

  const handleClose = () => {
    formik.resetForm();
    closeModal();
  };
  const valueChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    switch (modifiedField) {
      case 'externalRecipient':
        formik.setFieldValue('externalRecipient', event.target.checked);
        if (event.target.checked) {
          formik.setFieldValue('recipientEthereumAddress', { label: '', value: '' });
        }
        break;
      case 'externalRecipientAddress':
        formik.setFieldValue('externalRecipientAddress', newValue);
        break;
      default:
        break;
    }
  };
  const isButtonDisabled = () => {
    let isDisabled = true;
    if (!loading) {
      if (
        formik.values.transferReason &&
        (formik.values.recipientEthereumAddress?.value || formik.values.externalRecipientAddress) &&
        formik.isValid
      ) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <Modal className={classes.modal} open={open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.transferTokens')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className={classes.formArea}>
            <div className={classes.checkField}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="externalRecipient"
                    checked={formik.values.externalRecipient}
                    color="primary"
                    onChange={valueChangeHandler}
                  />
                }
                label={
                  <>
                    {t('tokens.externalRecipient')}
                    <TransferToolTip />
                  </>
                }
              />
            </div>

            {!formik.values.externalRecipient && (
              <Field
                component={AutocompleteDropDown}
                label={t('common.recipient')}
                options={companySelectOptions}
                value={formik.values.recipientEthereumAddress}
                name="recipientEthereumAddress"
                disabled={!companySelectOptions.length || formik.values.externalRecipient}
                disableClearable
              />
            )}

            {formik.values.externalRecipient && (
              <Field
                component={TextField}
                label="Recipient address"
                value={formik.values.externalRecipientAddress}
                name="externalRecipientAddress"
                customInput={TextField}
                onChange={valueChangeHandler}
              />
            )}

            <Field
              component={TextField}
              label={t('tokens.transferReason')}
              value={formik.values.transferReason || ''}
              name="transferReason"
            />
            <FormErrors form={formik} />
            {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC && !isPrivate ? (
              <BlockchainInfo
                validateForm={formik.validateForm}
                values={formik.values}
                contractAddress={contractAddress}
                method={CONSTANTS.TRANSACTION_TYPES.TRANSFER}
                methodArgs={[activeWallet?.address, tokenId?.toString()]}
              />
            ) : (
              ''
            )}
            <Divider className={classes.divider} />
            <Button
              className={classes.submitButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isButtonDisabled()}
            >
              {t('tokens.transfer')}
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={classes.cancelButton}
            >
              {t('common.cancel')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

TransferErc721Token.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  currentUserEthAddress: PropTypes.string.isRequired,
  tokenId: PropTypes.string,
  loading: PropTypes.bool,
  isPrivate: PropTypes.bool.isRequired,
};

TransferErc721Token.defaultProps = {
  contractAddress: '',
  loading: false,
  tokenId: null,
};

export default TransferErc721Token;
