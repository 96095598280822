import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import uniqid from 'uniqid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 0,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
    },
    height: 32,
    width: 32,
  },
  buttonWithText: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
    },
    height: 32,
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
  disabledButton: {
    background: theme.palette.primary.lightest,
  },
  buttonText: {
    fontSize: 10,
    color: theme.palette.primary.main,
  },
  disabledButtonText: {
    fontSize: 12,
  },
  buttonContainer: {
    maxHeight: 32,
    minWidth: 32,
  },
}));

const TableActions = ({ options, testId }) => {
  const classes = useStyles();

  return (
    <Grid container>
      {options.map(option => (
        <Tooltip key={uniqid()} title={option.label} disableHoverListener={option.disabled}>
          <Grid
            item
            className={clsx(classes.buttonContainer, {
              [classes.disabledButton]: option.disabled,
            })}
            data-testid={testId}
          >
            <IconButton
              className={clsx(classes.button, {
                [classes.buttonWithText]: option.buttonText,
                [classes.disabledButton]: option.disabled,
              })}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={option.handleClick}
              disabled={option.disabled}
            >
              {option.icon}
              {option.buttonText && (
                <Typography
                  className={option.disabled ? classes.disabledButtonText : classes.buttonText}
                >
                  {option.buttonText}
                </Typography>
              )}
            </IconButton>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

TableActions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  testId: PropTypes.string,
};

TableActions.defaultProps = {
  testId: '',
};

export default TableActions;
