import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';

const localeMap = {
  zh: 'zh-cn',
};

const DatePickerField = ({
  field,
  form,
  label,
  inputVariant,
  className,
  minDate,
  format,
  allowEmpty,
  disabled,
  testid,
}) => {
  const { t, i18n } = useTranslation();
  const today = moment(Date.now());

  moment.locale(localeMap[i18n.language]);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={localeMap[i18n.language]}>
      <KeyboardDatePicker
        clearable
        label={label}
        value={field.value === null ? null : field.value * 1000}
        onChange={date =>
          form.setFieldValue(
            field.name,
            date === null || date === undefined ? date : moment(date).unix(),
          )
        }
        minDate={minDate || today}
        format={format}
        minDateMessage={t('common.validation.date')}
        inputVariant={inputVariant}
        className={className}
        cancelLabel={t('common.datePicker.cancelLabel')}
        okLabel={t('common.datePicker.okLabel')}
        clearLabel={t('common.datePicker.clearLabel')}
        placeholder={label}
        invalidDateMessage={allowEmpty ? '' : t('common.datePicker.invalidDate')}
        disabled={disabled}
        data-testid={testid}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  inputVariant: PropTypes.string,
  className: PropTypes.string,
  minDate: PropTypes.string,
  format: PropTypes.string,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

DatePickerField.defaultProps = {
  inputVariant: 'standard',
  className: null,
  minDate: null,
  format: 'YYYY/MM/DD',
  allowEmpty: false,
  disabled: false,
  testid: '',
};

export default DatePickerField;
