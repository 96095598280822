import { configure, login } from '@eyblockchain/authentication-sdk/browser';
import Snackbar from '@eyblockchain/ey-ui/core/Snackbar/Snackbar';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ENTERPRISE_PACKAGES, ERROR_CODES, PERMISSIONS, TRIAL_PACKAGES } from '../constants';
import { getUserId } from '../utils';
import api from '../utils/api';
import { NotificationProvider, useNotification } from './Shared/notification';

export const UserContext = React.createContext({
  userInfoData: {},
  userInfoLoading: false,
});

const UserInfoProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  const [permissionsFlags, setPermissionsFlags] = useState({});
  const [allOrgPermissions, setAllOrgPermissions] = useState(null);
  const [allOrgRoles, setAllOrgRoles] = useState(null);
  const [user, setUser] = useState();
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [userInfoData, setUserInfoData] = useState({});
  const [userPackages, setUserPackages] = useState({
    tokenization: false,
    notarization: false,
    traceability: false,
    contractManager: false,
  });
  const [orgPackages, setOrgPackages] = useState([]);
  const { handleNotification } = useNotification();
  const {
    displayNotification,
    closeNotification,
    notificationMessage,
    notificationVariant,
    showFaucetModal,
  } = useNotification();
  const { t } = useTranslation();

  const trialPackagesIds = {
    TOKENIZATION: TRIAL_PACKAGES[window.config.nodeEnv].TOKENIZATION,
    NOTARIZATION: TRIAL_PACKAGES[window.config.nodeEnv].NOTARIZATION,
    TRACEABILITY: TRIAL_PACKAGES[window.config.nodeEnv].TRACEABILITY,
    CONTRACT_MANAGER: TRIAL_PACKAGES[window.config.nodeEnv].CONTRACT_MANAGER,
  };
  const enterprisePackageId = ENTERPRISE_PACKAGES[window.config.nodeEnv];

  const {
    getRolesAndPermissionsFromBEC,
    getUserData,
    getUserPackages,
    getOrgPackages,
    updatePackageForUser,
    updatePackageForOrg,
  } = api.userAPI({ handleNotification, t });

  configure({
    cookiePrefix: window.platformConfig.cookiePrefix,
    cookieDomain: window.platformConfig.cookieDomain,
    authDomain: window.platformConfig.becUrl,
    fallbackRedirect: window.location.href,
    authApiUrl: window.platformConfig.authApiUrl,
  });

  const getPreferenceLanguage = async () => {
    if (
      userInfoData?.user_metadata?.language &&
      i18next.language !== userInfoData?.user_metadata?.language
    ) {
      await i18next.changeLanguage(userInfoData.user_metadata.language);
    }
  };

  const getUserInfo = async () => {
    const userId = getUserId();
    setUserInfoLoading(true);
    const userInfo = await getUserData({ userId });
    setUserInfoData(userInfo);
    getPreferenceLanguage();
    setUserInfoLoading(false);
    return userInfo;
  };

  const updatePermissionsFlags = userPermissionsResult => {
    const newPermissionsFlags = {
      // tokenization
      isUserAuthToDeployERC20:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.DEPLOYERC20) ||
        false,
      isUserAuthToMintERC20:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.MINTERC20) || false,
      isUserAuthToBurnERC20:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.BURNERC20) || false,
      isUserAuthToTransferERC20:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.TRANSFERERC20) ||
        false,
      isUserAuthToDeployERC721:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.DEPLOYERC721) ||
        false,
      isUserAuthToMintERC721:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.MINTERC721) || false,
      isUserAuthToBurnERC721:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.BURNERC721) || false,
      isUserAuthToViewERC721History:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.VIEWERC721HISTORY) ||
        false,
      isUserAuthToSetERC721Metadata:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.SETERC721METADATA) ||
        false,
      isUserAuthToTransferERC721:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.TRANSFERERC721) ||
        false,
      isUserAuthToDeployERC1155:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.DEPLOYERC1155) ||
        false,
      isUserAuthToMintERC1155:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.MINTERC1155) ||
        false,
      isUserAuthToBurnERC1155:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.BURNERC1155) ||
        false,
      isUserAuthToViewERC1155History:
        userPermissionsResult?.tokenization?.includes(
          PERMISSIONS.TOKENIZATION.VIEWERC1155HISTORY,
        ) || false,
      isUserAuthToSetERC1155Metadata:
        userPermissionsResult?.tokenization?.includes(
          PERMISSIONS.TOKENIZATION.SETERC1155METADATA,
        ) || false,
      isUserAuthToTransferERC1155:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.TRANSFERERC1155) ||
        false,
      isUserAuthToViewFullTokenList:
        userPermissionsResult?.tokenization?.includes(
          PERMISSIONS.TOKENIZATION.VIEWFULLLISTOFTOKENS,
        ) || false,
      isUserAuthToViewOrgTokens:
        userPermissionsResult?.tokenization?.includes(
          PERMISSIONS.TOKENIZATION.VIEWORGANIZATIONTOKENS,
        ) || false,
      isUserAuthToViewTokenOwner:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.VIEWTOKENOWNER) ||
        false,
      isUserAuthToViewDeployedTokens:
        userPermissionsResult?.tokenization?.includes(
          PERMISSIONS.TOKENIZATION.VIEWDEPLOYEDTOKENS,
        ) || false,
      isUserAuthToViewTokenMetadata:
        userPermissionsResult?.tokenization?.includes(PERMISSIONS.TOKENIZATION.VIEWTOKENMETADATA) ||
        false,
      // traceability
      isUserAuthToCreateValueChain:
        userPermissionsResult?.traceability?.includes(PERMISSIONS.TRACEABILITY.CREATEVALUECHAIN) ||
        false,
      isUserAuthToEditValueChain:
        userPermissionsResult?.traceability?.includes(PERMISSIONS.TRACEABILITY.EDITVALUECHAIN) ||
        false,
      isUserAuthToViewValueChainDetails:
        userPermissionsResult?.traceability?.includes(
          PERMISSIONS.TRACEABILITY.VIEWVALUECHAINDETAILS,
        ) || false,
      isUserAuthToInsertRecordInDGP:
        userPermissionsResult?.traceability?.includes(PERMISSIONS.TRACEABILITY.INSERTRECORDDGP) ||
        false,
      isUserAuthToViewRecordInDGP:
        userPermissionsResult?.traceability?.includes(PERMISSIONS.TRACEABILITY.VIEWRECORDDGP) ||
        false,
      isUserAuthToViewAllRecordsInDGP:
        userPermissionsResult?.traceability?.includes(PERMISSIONS.TRACEABILITY.VIEWALLRECORDSDGP) ||
        false,
      // notarization
      isUserAuthToDeployNotarizationContract:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.DEPLOYNOTARIZATION,
        ) || false,
      isUserAuthToNotarizeDocuments:
        userPermissionsResult?.notarization?.includes(PERMISSIONS.NOTARIZATION.NOTARIZEDOCUMENT) ||
        false,
      isUserAuthToNotarizeCertificates:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.NOTARIZECERTIFICATE,
        ) || false,
      isUserAuthToRestrictNotarizationContent:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.RESTRICTNOTARIZATIONCONTENT,
        ) || false,
      isUserAuthToViewTransactionDetails:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.VIEWTRANSACTIONDETAILS,
        ) || false,
      isUserAuthToViewCertificateContent:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.VIEWCERTIFICATECONTENT,
        ) || false,
      isUserAuthToVerifyDocuments:
        userPermissionsResult?.notarization?.includes(PERMISSIONS.NOTARIZATION.VERIFYDOCUMENT) ||
        false,
      isUserAuthToViewTransactionStatus:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.VIEWTRANSACTIONSTATUS,
        ) || false,
      isUserAuthToUnlistTransaction:
        userPermissionsResult?.notarization?.includes(PERMISSIONS.NOTARIZATION.UNLISTTRANSACTION) ||
        false,
      isUserAuthToDownloadCertificate:
        userPermissionsResult?.notarization?.includes(
          PERMISSIONS.NOTARIZATION.DOWNLOADCERTIFICATE,
        ) || false,
      isUserAuthToDownloadDocument:
        userPermissionsResult?.notarization?.includes(PERMISSIONS.NOTARIZATION.DOWNLOADDOCUMENT) ||
        false,
    };
    setPermissionsFlags(newPermissionsFlags);
  };

  const getRolesAndPermissions = async () => {
    const userId = getUserId();
    setUserInfoLoading(true);
    const [organizationPermissionsResult, userPermissionsResult] = await Promise.all([
      getRolesAndPermissionsFromBEC({}),
      getRolesAndPermissionsFromBEC({ userId }),
    ]);

    if (
      !organizationPermissionsResult ||
      !organizationPermissionsResult.roles_list ||
      !organizationPermissionsResult.permissions_list
    ) {
      throw Error(ERROR_CODES.RBAC.DEFINITION_MISSING);
    }
    setAllOrgRoles(organizationPermissionsResult.roles_list);
    setAllOrgPermissions(organizationPermissionsResult.permissions_list);
    setUserPermissions(userPermissionsResult);
    updatePermissionsFlags(userPermissionsResult);
    setUserInfoLoading(false);
  };

  const elaboratePackages = res => {
    const currentPackagesList = res?.subscriptions.map(sub => sub._id);

    // check if currentPackagesList contains every package needed for the component
    const verifyPackage = trialPackageIds => {
      const verifiedTrial = trialPackageIds.every(pack => currentPackagesList.includes(pack));
      const verifiedEnterprise = currentPackagesList.includes(enterprisePackageId);
      return verifiedTrial || verifiedEnterprise;
    };

    const packages = {
      tokenization: verifyPackage(trialPackagesIds.TOKENIZATION),
      notarization: verifyPackage(trialPackagesIds.NOTARIZATION),
      traceability: verifyPackage(trialPackagesIds.TRACEABILITY),
      contractManager: verifyPackage(trialPackagesIds.CONTRACT_MANAGER),
    };
    return packages;
  };

  const getPackagesForUser = async () => {
    const userId = getUserId();
    setUserInfoLoading(true);
    const res = await getUserPackages({ userId });
    const packages = elaboratePackages(res);
    setUserPackages(packages);
    setUserInfoLoading(false);
    return packages;
  };

  const getPackagesForOrg = async () => {
    setUserInfoLoading(true);
    const res = await getOrgPackages();
    const packages = elaboratePackages(res);
    setOrgPackages(packages);
    setUserInfoLoading(false);
    return packages;
  };

  const updateUserPackages = async packages => {
    const userId = getUserId();
    setUserInfoLoading(true);
    const multipleUpdates = packages.map(async packageId => {
      return updatePackageForUser(userId, packageId);
    });
    const responses = await Promise.all(multipleUpdates);
    const success = responses.every(res => res?.subscriptions);

    if (success) {
      const newPackages = await getPackagesForUser();
      return newPackages;
    }
    setUserInfoLoading(false);
    return userPackages;
  };

  const updateOrgPackages = async packages => {
    setUserInfoLoading(true);
    const multipleUpdates = packages.map(async packageId => {
      return updatePackageForOrg(packageId);
    });
    const responses = await Promise.all(multipleUpdates);
    const success = responses.every(res => res?.subscriptions);

    if (success) {
      const newPackages = await getPackagesForOrg();
      return newPackages;
    }
    setUserInfoLoading(false);
    return orgPackages;
  };

  useEffect(() => {
    const loginAndGetToken = login(window.location.href, false);
    if (!user) {
      setUser(loginAndGetToken);
    } else {
      getUserInfo();
      getPackagesForUser();
      getPackagesForOrg();
      getRolesAndPermissions();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        userPermissions,
        permissionsFlags,
        allOrgRoles,
        allOrgPermissions,
        getRolesAndPermissions,
        updateUserPackages,
        updateOrgPackages,
        userPackages,
        orgPackages,
        userInfoLoading,
        userInfoData,
      }}
    >
      {children}
      <Snackbar
        open={!showFaucetModal && displayNotification}
        onClose={closeNotification}
        message={notificationMessage}
        variant={notificationVariant}
      />
    </UserContext.Provider>
  );
};

const UserInfoProviderContainer = ({ children }) => (
  <NotificationProvider>
    <UserInfoProvider>{children}</UserInfoProvider>
  </NotificationProvider>
);

UserInfoProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

UserInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserInfoProviderContainer;
