import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Box } from '@material-ui/core';
import CategoryOutlined from '@material-ui/icons/CategoryOutlined';
import EventOutlined from '@material-ui/icons/EventOutlined';
import StorageOutlined from '@material-ui/icons/StorageOutlined';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import ValueChainItemSummary from './ValueChainItemSummary';
import { CONSTANTS, METADATA_BEHAVIORS, TRACE_WIZARD_PROGRESSION_V2 } from '../../../constants';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { isUnfinishedStep } from './traceabilityUtils';
import InfoMessage from './InfoMessage';

const useStyles = makeStyles(theme => ({
  connector: {
    position: 'absolute',
    top: '40px',
    left: 'calc(-40%)',
    right: 'calc(60%)',
  },
  wizardStepperRoot: {
    width: '100%',
  },
  stepper: {
    backgroundColor: theme.palette.primary.lightest,
  },
  step: {},
  iconContainer: {
    width: '50px',
    height: '50px',
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32%',
    borderRadius: '32px',
    color: 'grey',
    backgroundColor: '#EAEAEE',
  },
  icon: {
    height: '30px',
    width: '30px',
  },
  iconCompleted: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  iconActive: {
    color: 'white',
    backgroundColor: '#FF9831',
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: '-80px',
    },
  },
  alternativeLabel: {},
  labelContent: {
    display: 'flex',
    paddingLeft: '20px',
    paddingTop: '5px',
  },
  labelDisabled: {
    color: 'grey',
  },
}));

const ValueChainDetails = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { instanceDetails } = useConfigurationWizardContext();

  const steps = [
    {
      label: t('traceability.instanceSummary.itemInformation'),
      key: CONSTANTS.TRACE_PATHS.items,
      Icon: CategoryOutlined,
    },
    {
      label: t('traceability.instanceSummary.itemsStep'),
      key: CONSTANTS.TRACE_PATHS.steps,
      Icon: EventOutlined,
    },
    {
      label: t('traceability.instanceSummary.permissions'),
      key: CONSTANTS.TRACE_PATHS.permissions,
      Icon: PeopleAltOutlined,
    },
    {
      label: t('traceability.instanceSummary.metaData'),
      key: CONSTANTS.TRACE_PATHS.metadata,
      Icon: StorageOutlined,
    },
  ];

  const identifyStepIconStyle = stepKey => {
    const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: stepKey })?.code;
    let selectedStyle;
    if (currPathCode <= instanceDetails.content.lastCompletedIteration) {
      selectedStyle = classes.iconCompleted;
    }
    if (currPathCode === instanceDetails.content.lastCompletedIteration + 1) {
      selectedStyle = classes.iconActive;
    }
    return selectedStyle;
  };

  const isLinkedMetadata =
    item?.tokenContract?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED;

  return (
    <div className={classes.wizardStepperRoot}>
      <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
        {steps.map(step => (
          <Step key={step.label} active>
            <StepLabel
              StepIconComponent={() => {
                return (
                  <div className={clsx(classes.iconContainer, identifyStepIconStyle(step.key))}>
                    <step.Icon className={classes.icon} />
                  </div>
                );
              }}
            >
              <div
                className={clsx(
                  classes.labelContent,
                  isUnfinishedStep(step.key, instanceDetails) && classes.labelDisabled,
                )}
              >
                {step.label}

                {isLinkedMetadata && step.label === t('traceability.instanceSummary.metaData') && (
                  <Box ml={3}>
                    <InfoMessage
                      messageText={t('traceability.metadataForm.linkedMetadata.info', {
                        parentItemName: item?.parentItem?.name,
                      })}
                    />
                  </Box>
                )}
              </div>
            </StepLabel>
            <StepContent>
              <ValueChainItemSummary category={step.key} item={item} />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

ValueChainDetails.propTypes = {
  item: PropTypes.shape({
    parentItem: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    tokenContract: PropTypes.shape({
      metadataStructure: PropTypes.shape({
        behavior: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default ValueChainDetails;
