import React, { useEffect, useState } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Tooltip from '@material-ui/core/Tooltip';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import { useContractContext } from '../../contexts/Procurement/contract';
import { useAuth } from '../../contexts/Shared/auth';
import TableSearch from '../../components/Shared/TableSearch';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import { PROTabs, PROTab } from '../../components/Procurement/PROTabs';
import track from '../../mixpanel';
import TableActions from '../../components/Shared/TableActions';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      boxShadow: '0 -1.75px 0 0 rgb(0 0 0 / 4%)',
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTabs-root': {
      marginBottom: '20px',
      borderBottom: '0px',
      boxShadow: '0px',
    },
    '& .MuiTab-root': {
      minWidth: '0px',
    },
  },
  loaderContainer: theme.loaderContainer,
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chipData: {
    color: '#2E2E38',
    borderColor: '#2E2E38',
    marginLeft: '3px',
    height: '18px',
  },
  chipLabel: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  activeChip: { background: 'rgb(22 134 54)', color: '#fff' },
  pendingChip: { background: '#FF9831' },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  ContractListRoot: {
    background: theme.palette.primary.lightest,
    padding: '16px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  contractHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Contracts = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { contracts, contractLoading: loading, getContracts } = useContractContext();
  const { company } = useAuth();
  const [activeTab, setActiveTab] = useState('All');
  const [filteredContracts, setFilteredContracts] = useState([]);

  const { activeOrganization } = useBecOrganizationContext();
  const { userPermissions } = useUserInfo();

  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;

  useEffect(() => {
    getContracts();
  }, [getContracts, company, activeOrganization]);

  useEffect(() => {
    setFilteredContracts(contracts);
  }, [contracts]);

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATECONTRACT) &&
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.GETCONTRACTLISTING)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  useEffect(() => {
    if (activeTab === 'Issued' && contracts.length > 0) {
      const formattedContract = contracts.filter(contract => contract.isSender === true);
      setFilteredContracts(formattedContract);
    } else if (activeTab === 'Received' && contracts.length > 0) {
      const formattedContract = contracts.filter(contract => contract.isSender === false);
      setFilteredContracts(formattedContract);
    } else if (activeTab === 'All' && contracts.length > 0) {
      setFilteredContracts(contracts);
    }
  }, [contracts, activeTab]);

  const columns = [
    {
      name: 'contractId',
      label: t('contracts.contractId'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return `CT-${value.padStart(10, 0)}`;
        },
      },
    },
    // buyer
    {
      name: 'businessPartnerBuyer',
      label: t('common.businessPartnerBuyer'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    // supplier
    {
      name: 'businessPartnerSupplier',
      label: t('common.businessPartnerSupplier'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'materials',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          const materialArr = value ? value.split(';') : [];
          const totalMaterial = materialArr.length;
          if (materialArr.length > 5) {
            materialArr.splice(6);
            materialArr.push(t('common.more'));
          }
          return (
            <div className={classes.associatedBox}>
              <Typography>{materialArr[0]}</Typography>
              {materialArr.length > 1 ? (
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={
                    <span>
                      {materialArr.map((material, key) => {
                        return key !== 0 ? <p key={uniqid()}>{` - ${material}`}</p> : '';
                      })}
                    </span>
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <Chip
                      label={materialArr.length > 0 ? `+${totalMaterial - 1}` : ''}
                      variant="outlined"
                      classes={{
                        root: classes.chipData,
                        label: classes.chipLabel,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'contractStartDate',
      label: t('contracts.contractStartDate'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'contractEndDate',
      label: t('contracts.contractEndDate'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          let labelStatus = t('common.active');
          if (value === 'pending') {
            labelStatus = t('common.pending');
          } else if (value === 'action-required') {
            labelStatus = t('common.actionRequired');
          } else if (value === 'deploying') {
            labelStatus = t('common.deployingContract');
          } else if (value === 'in-active') {
            labelStatus = t('common.inactive');
          }
          return (
            <Chip
              className={value === 'active' ? classes.activeChip : classes.pendingChip}
              label={labelStatus}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="left"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return (
            <TableActions
              options={[
                {
                  label: t('contracts.viewContact'),
                  buttonText: t('contracts.viewContact'),
                  testId: `contract-${value}`,
                  handleClick: () => {
                    history.push(`/contracts/${value}`);
                  },
                },
              ]}
              testId={`contract-${value}`}
            />
          );
        },
      },
    },
  ];

  const getFormattedMaterial = materials => {
    const materialArr = materials.map(material => material.materialName);
    const materialComma = materialArr.join(';');

    return materialComma;
  };
  const formatRow = contract => ({
    id: contract._id,
    contractId: contract.contractId || '',
    businessPartnerBuyer: contract.buyer.organization.name,
    businessPartnerSupplier: contract.supplier.organization.name,
    materials: getFormattedMaterial(contract.materials),
    contractStartDate: moment(+contract.startDate).format('D MMM YYYY'),
    contractEndDate: moment(+contract.endDate).format('D MMM YYYY'),
    status: contract.status,
  });

  const handleDeploy = () => {
    history.push(`/contract/create`);
  };

  const handleChangeTab = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {!isUserNotAuthorized() && loading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        !isUserNotAuthorized() && (
          <>
            <div className={classes.ContractListRoot}>
              <div className={classes.contractHeaderArea}>
                <Typography variant="h3">{t('common.contracts')}</Typography>
                {userPermissions?.contract_manager?.includes(
                  PERMISSIONS.CONTRACT_MANAGER.CREATECONTRACT,
                ) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PostAddIcon />}
                    onClick={handleDeploy}
                    data-testid="searchButton"
                  >
                    {t('common.createContract')}
                  </Button>
                )}
              </div>
            </div>
            <div className="contracts">
              <Table
                title={t('common.contracts')}
                className={classes.table}
                columns={columns}
                data={
                  userPermissions?.contract_manager?.includes(
                    PERMISSIONS.CONTRACT_MANAGER.GETCONTRACTLISTING,
                  )
                    ? [...(filteredContracts?.map(contract => formatRow(contract)) || [])]
                    : []
                }
                options={{
                  pagination: true,
                  filter: false,
                  onRowClick: row => {
                    track('Select Contract View Its Details', {
                      ContractActive: row[6].props.label === 'active',
                    });
                  },
                  search: false,
                  searchOpen: true,
                  elevation: 0,
                  textLabels: {
                    body: {
                      noMatch: t('common.noMatchingRecords'),
                    },
                    pagination: {
                      rowsPerPage: t('common.rowsPerPage'),
                    },
                  },
                  customSearchRender: (searchText, handleSearch, hideSearch, options) => (
                    <>
                      <PROTabs value={activeTab} onChange={handleChangeTab}>
                        <PROTab label={t('All')} value="All" />
                        <PROTab label={t('Issued')} value="Issued" />
                        <PROTab label={t('Received')} value="Received" />
                      </PROTabs>
                      <TableSearch
                        searchText={searchText}
                        handleSearch={handleSearch}
                        hideSearch={hideSearch}
                        options={options}
                        placeHolderText={t('contracts.searchContracts')}
                        createButtonText={t('common.createContract')}
                      />
                    </>
                  ),
                }}
              />
            </div>
          </>
        )
      )}
      {isUserNotAuthorized()}
    </>
  );
};

export default Contracts;
