import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import LocationButton from './LocationButton';
import TokenMap from './TokenMap';

const useStyles = makeStyles(theme => ({
  LocationFieldsRoot: {
    padding: theme.spacing(1),
  },
  locNameArea: {
    marginTop: theme.spacing(1),
  },
  locDataArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  locationBanner: {
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(2),
  },
  addressGrid: {
    margin: 0,
  },
  ordinateBanner: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  addressBanner: {
    fontWeight: 700,
  },
  divider: {
    background: theme.palette.primary.lighter,
    height: '87%',
  },
  latInput: {
    marginBottom: theme.spacing(2),
  },
  custDivider: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `0px ${theme.spacing(4)}px`,
  },
  orBox: {
    border: `1px solid ${theme.palette.primary.lighter}`,
    borderRadius: '50%',
    padding: `8px 10px`,
  },
  orText: {
    color: theme.palette.primary.light,
  },
  mapQnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CustomDivider = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.custDivider}>
      <div className={classes.orBox}>
        <Typography variant="caption" className={classes.orText}>
          {t('common.or')}
        </Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" />
    </div>
  );
};

const LocationFields = ({ locationDetails, isMapDisabled, setFieldValue, name, readOnly }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleLocationValue = locData => {
    if (locData?.latitude && locData?.longitude) {
      setFieldValue(`${name}.latitude`, locData?.latitude);
      setFieldValue(`${name}.longitude`, locData?.longitude);
    }
  };

  const getMapButton = () => {
    if (isMapDisabled) {
      return null;
    }

    return <LocationButton disabled={isMapDisabled} onLocationReceived={handleLocationValue} />;
  };

  const itemChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;

    if (!modifiedField) {
      throw new Error(t('tokens.errors.invalidField'));
    }

    setFieldValue(modifiedField, newValue);
  };
  return (
    <div className={classes.LocationFieldRoot}>
      <div className={classes.locNameArea}>
        {!readOnly && (
          <Typography variant="body1" className={classes.locationBanner}>
            {t('tokens.location.banner')}
          </Typography>
        )}
        <div className={classes.mapQnContainer}>
          <Field
            component={TextField}
            name={`${name}.locationName`}
            label={t('tokens.location.name')}
            size="small"
            variant="outlined"
            disabled={readOnly}
            value={locationDetails.locationName}
            onChange={itemChangeHandler}
            InputProps={{
              endAdornment: getMapButton(),
            }}
          />
          <TokenMap
            longitude={locationDetails.longitude}
            latitude={locationDetails.latitude}
            zoomValue={12}
          />
        </div>
      </div>
      <div className={classes.locDataArea}>
        <div className={classes.ordinateArea}>
          <Typography variant="body1" className={classes.ordinateBanner}>
            {t('tokens.location.ordinates')}
          </Typography>
          <Field
            component={TextField}
            name={`${name}.latitude`}
            label={t('tokens.location.latitude')}
            size="small"
            variant="outlined"
            disabled={readOnly}
            className={classes.latInput}
            value={locationDetails.latitude}
            onChange={itemChangeHandler}
          />
          <Field
            component={TextField}
            name={`${name}.longitude`}
            label={t('tokens.location.longitude')}
            size="small"
            disabled={readOnly}
            variant="outlined"
            value={locationDetails.longitude}
            onChange={itemChangeHandler}
          />
        </div>

        <CustomDivider />
        <Grid container spacing={2} className={classes.addressGrid}>
          <Typography variant="body1" className={classes.addressBanner}>
            {t('tokens.location.address')}
          </Typography>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name={`${name}.streetAddress`}
              label={t('tokens.location.address')}
              disabled={readOnly}
              size="small"
              variant="outlined"
              value={locationDetails.streetAddress}
              onChange={itemChangeHandler}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name={`${name}.addrCountry`}
                label={t('tokens.location.country')}
                size="small"
                disabled={readOnly}
                variant="outlined"
                value={locationDetails.addrCountry}
                onChange={itemChangeHandler}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name={`${name}.addressRegion`}
                label={t('tokens.location.state')}
                size="small"
                variant="outlined"
                disabled={readOnly}
                value={locationDetails.addressRegion}
                onChange={itemChangeHandler}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name={`${name}.addressLocality`}
                label={t('tokens.location.city')}
                size="small"
                variant="outlined"
                disabled={readOnly}
                value={locationDetails.addressLocality}
                onChange={itemChangeHandler}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name={`${name}.postalCode`}
                label={t('tokens.location.zipcode')}
                size="small"
                variant="outlined"
                disabled={readOnly}
                value={locationDetails.postalCode}
                onChange={itemChangeHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

LocationFields.propTypes = {
  locationDetails: PropTypes.shape({
    locationMode: PropTypes.string,
    locationName: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    streetAddress: PropTypes.string,
    addrCountry: PropTypes.string,
    addressRegion: PropTypes.string,
    addressLocality: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
  isMapDisabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

LocationFields.defaultProps = {
  isMapDisabled: true,
  readOnly: false,
};

export default LocationFields;
