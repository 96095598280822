import React, { useState } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import useListStyles from './list';
import useDetailStyles from '../MtrPurchaseOrderDetails/detail';
import {
  GET_PURCHASE_MASTER,
  GET_MTR_PURCHASES,
  UPDATE_PURCHASE_MASTER,
  UPDATE_MTR_PURCHASES,
} from '../../../graphql/Procurement/mtrPurchaseOrder';
import { CompanyInfo, PoHead, StatusChip } from '../MtrPurchaseOrderDetails';
import SignButton from '../../../components/Shared/SignButton';
import LoadingComponent from '../DemandForecast/loaderContainer';
import {
  displayDate,
  ellipsisText,
  getForeignPurchaseNumber,
  getPOReleaseLineNumber,
  makeDownload,
  makeMasterIDs,
  makePayload,
} from '../MtrOpenOrderSummary/tableUtils';
import UploadModal from '../MtrOpenOrderSummary/upload';

dayjs.extend(relativeTime);

const statusEnum = {
  Open: true,
  Closed: false,
};

export const convertPoItem = i => {
  const { sku } = i;

  return {
    ...i,
    ...getPOReleaseLineNumber(i.supplementInfo.foreignPurchaseNumber),
    // po header
    foreignPurchaseNumberMaster: i.supplementInfo.foreignPurchaseNumberMaster,
    // po number
    foreignPurchaseNumber: i.supplementInfo.foreignPurchaseNumber,
    // uom
    unitOfItem: i.supplementInfo.unitOfItem,
    // sparesCategory
    sparesCategory: sku.supplementInfo?.sparesCategory,
    // supplierSku
    supplierSku: i.supplementInfo?.partNumber || sku.supplierSku,
    // description
    description: sku.description,
    buyerCompanyName: i.buyer?.company.name,
    supplierCompanyName: i.supplier?.company.name,
    elapsedSinceCreation: i.poHeader?.elapsedSinceCreation,
    signature: i.poHeader?.supplier?.signature,
    acknowledgedAt: i.poHeader?.supplier?.acknowledgedAt,
    ...i.supplierInput,
  };
};

const PoMasterDetails = ({ masterId }) => {
  const detailsClasses = useDetailStyles();
  const listClasses = useListStyles();
  const { t } = useTranslation('mtr');

  const { refetch: refetchPOMasterDetails, data, loading } = useQuery(GET_PURCHASE_MASTER, {
    variables: { foreignPurchaseNumberMaster: masterId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [sign] = useMutation(UPDATE_PURCHASE_MASTER, {
    variables: {
      foreignPurchaseNumberMaster: masterId,
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  if (!data) {
    return 'Not Found';
  }

  const {
    purchaseOrderHeaderMtr: { supplementInfo, supplier, buyer, isSender, elapsedSinceCreation },
  } = data;

  const pendingPeriod = elapsedSinceCreation && Math.ceil(elapsedSinceCreation / 36000 / 24);

  const onSign = async () => {
    await sign();
    await refetchPOMasterDetails();
  };

  const tableColumn = [
    {
      name: 'buyerSku',
      label: t('pos.supplierItem'),
    },
    {
      name: 'description',
      label: t('pos.itemDescription'),
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <CompanyInfo
            supplier={supplier?.company?.name}
            buyer={buyer?.company?.name}
            isSender={isSender}
          />
        </Grid>
        <Grid item xs={4} className={listClasses.chip}>
          <StatusChip signature={supplier?.signature} />
        </Grid>
      </Grid>

      <Grid className={listClasses.poMasterDetails}>
        <Accordion defaultExpanded={!supplier.signature}>
          <AccordionSummary
            expandIcon={!supplier.signature && <ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="Supplier acknowledgement"
          >
            <Typography className={detailsClasses.dataTitle}>
              {t('pos.supplierAcknowledgement')}
              {!supplier.signature && (
                <span style={{ color: 'red', marginLeft: '1rem' }}>
                  ({t('pos.notAcknowledgedOnTime', { pendingPeriod })})
                </span>
              )}
              {supplier.signature && (
                <span style={{ color: 'green', marginLeft: '1rem' }}>
                  (
                  {t('pos.acknowledgedOnTime', {
                    acknowledgeTime: dayjs(supplier.acknowledgedAt * 1000).format('YYYY-MM-DD'),
                  })}
                  )
                </span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              {supplier.signature ? (
                <span />
              ) : (
                <>
                  <span style={{ marginRight: '2rem' }}>
                    {isSender ? t('pos.detailConfirmY') : t('pos.detailConfirmN')}
                  </span>
                  <SignButton onSign={onSign} label={t('pos.accept')} disabled={isSender} />
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={(supplementInfo.ukda || []).length > 0}>
          <AccordionSummary
            expandIcon={(supplementInfo.ukda || []).length > 0 && <ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="Preshipment inspection"
          >
            <Typography className={detailsClasses.dataTitle}>
              {t('pos.preShipInspection')}
              {(supplementInfo.ukda || []).length > 0 && (
                <span style={{ color: 'red', marginLeft: '1rem' }}>
                  (
                  {t('pos.foundUKDAReport', {
                    ukda_length: supplementInfo.ukda.length,
                  })}
                  )
                </span>
              )}
              {(supplementInfo.ukda || []).length === 0 && (
                <span style={{ color: 'green', marginLeft: '1rem' }}>
                  ({t('pos.noUKDAReport')})
                </span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <Table
                options={{
                  filter: false,
                  pagination: false,
                  search: false,
                  download: false,
                  rowsPerPageOptions: [10, 50, 100],
                }}
                data={supplementInfo.ukda || []}
                columns={tableColumn}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

PoMasterDetails.defaultProps = {
  masterId: null,
};

PoMasterDetails.propTypes = {
  masterId: PropTypes.string,
};

export default () => {
  const { t } = useTranslation('mtr');
  const listClasses = useListStyles();
  const history = useHistory();
  const { masterId } = queryString.parse(history.location.search);
  const [updatePo] = useMutation(UPDATE_MTR_PURCHASES);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { activeOrganization } = useBecOrganizationContext();
  const isOwnerOrAdmin = ['owner', 'admin'].includes(activeOrganization?.organization_role?.name);
  const TableComponent = ({ enableFilter }) => {
    const [state, setState] = useState(masterId ? { Open: true, Closed: true } : statusEnum);
    const { Open, Closed } = state;
    const { data: { purchaseOrderMtrs } = { purchaseOrderMtrs: [] }, loading } = useQuery(
      GET_MTR_PURCHASES,
      {
        variables: {
          foreignPurchaseNumberMaster: masterId,
          isClosed: Closed,
          isOpen: Open,
        },
        fetchPolicy: 'no-cache',
      },
    );
    const tableData = purchaseOrderMtrs.map(i => convertPoItem(i));
    const dataColumn = [
      { name: 'PO Header', attr: 'poNum', range: 'B' },
      { name: 'Release', attr: 'releaseNum', range: 'C' },
      { name: 'Line', attr: 'lineNum', range: 'D' },
      { name: 'Item Code', attr: 'material', range: 'E' },
      { name: 'Description', attr: 'description', range: 'G' },
      { name: 'Part Number', attr: 'supplierSku', range: 'F' },
      { name: 'Quantity', attr: 'volume', range: 'H' },
      { name: 'UOM', attr: 'unitOfItem', range: 'I' },
      {
        name: 'Acknowledgement',
        attr: 'signature',
        range: 'J',
      },
      { name: 'Supplier', attr: 'supplierCompanyName', range: 'K' },
      // edit column
      { name: 'Lead Time (Days)', attr: 'leadtime', range: 'L' },
      { name: 'Inventory', attr: 'inventory', range: 'M' },
      { name: 'Has Raw Material', attr: 'hasRawMaterial', range: 'N' },
      {
        name: 'Planned Material Ready Date',
        attr: 'materialStartDate',
        type: 'timestamp',
        range: 'O',
      },
      {
        name: 'Production Start Date',
        attr: 'productionStartDate',
        type: 'timestamp',
        range: 'P',
      },
      {
        name: 'Production Complete Date',
        attr: 'productionCompleteDate',
        type: 'timestamp',
        range: 'Q',
      },
    ];
    const tableColumn = [
      {
        name: 'poNum',
        label: t('pos.poHeader'),
        options: {
          filter: false,
          search: true,
          customBodyRender: value => (
            <Link color="inherit" href={`/purchaseOrders?masterId=${value}`} target="_blank">
              <Tooltip arrow title="View PO Header" placement="top-start">
                <span style={{ color: 'blue', whiteSpace: 'nowrap' }}>{value}</span>
              </Tooltip>
            </Link>
          ),
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 197,
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              zIndex: 297,
              background: '#F8F8FF',
            },
          }),
        },
      },
      {
        name: 'releaseNum',
        label: t('pos.poReleaseNum'),
        options: {
          filter: false,
          search: true,
          sortCompare: order => {
            return (obj1, obj2) => {
              const d1 = parseInt(obj1.data, 10);
              const d2 = parseInt(obj2.data, 10);
              return order === 'asc' ? d1 - d2 : d2 - d1;
            };
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '100px',
              zIndex: 198,
              background: '#FFFFFF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '100px',
              zIndex: 298,
              background: '#FFFFFF',
            },
          }),
        },
      },
      {
        name: 'lineNum',
        label: t('pos.poLineNum'),
        options: {
          filter: false,
          search: true,
          sortCompare: order => {
            return (obj1, obj2) => {
              const d1 = parseInt(obj1.data, 10);
              const d2 = parseInt(obj2.data, 10);
              return order === 'asc' ? d1 - d2 : d2 - d1;
            };
          },
          customBodyRender: (lineNum, { columnIndex, rowData }) => {
            const poNum = rowData[columnIndex - 2];
            const releaseNum = rowData[columnIndex - 1];
            const foreignPurchaseNumber = getForeignPurchaseNumber({ poNum, releaseNum, lineNum });
            return (
              <Link
                color="inherit"
                href={`/purchaseOrdersDetails?poId=${foreignPurchaseNumber}`}
                target="_blank"
              >
                <Tooltip arrow title="View PO Line" placement="top-start">
                  <span style={{ color: 'blue', whiteSpace: 'nowrap' }}>{lineNum}</span>
                </Tooltip>
              </Link>
            );
          },
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '140px',
              zIndex: 199,
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '140px',
              zIndex: 299,
              background: '#F8F8FF',
            },
          }),
        },
      },
      {
        name: 'material',
        label: t('pos.itemCode'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'description',
        label: t('pos.itemDescription'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value, length: 20 }),
        },
      },
      {
        name: 'supplierSku',
        label: t('pos.supplierPartNumber'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'volume',
        label: t('pos.quantity'),
        options: {
          filter: false,
          search: false,
          setCellProps: () => ({
            style: {
              textAlign: 'right',
              background: '#F8F8FF',
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              background: '#F8F8FF',
            },
          }),
        },
      },
      {
        name: 'unitOfItem',
        label: t('pos.UOM'),
        options: {
          filter: false,
          search: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'elapsedSinceCreation',
        label: t('pos.acknowledged'),
        options: {
          filter: false,
          search: false,
          display: !masterId,
          sortCompare: order => {
            return (obj1, obj2) => {
              let date1 = obj1.rowData[8];
              let date2 = obj2.rowData[8];
              const signature1 = obj1.rowData[9];
              const signature2 = obj2.rowData[9];
              const acknowledgedAt1 = obj1.rowData[10];
              const acknowledgedAt2 = obj2.rowData[10];

              const current = moment().unix();
              if (signature1) {
                date1 = acknowledgedAt1 - current;
              }
              if (signature2) {
                date2 = acknowledgedAt2 - current;
              }
              return order === 'asc' ? date1 - date2 : date2 - date1;
            };
          },
          customBodyRender: (value, { rowData, columnIndex }) => {
            if (rowData[columnIndex + 1]) {
              return displayDate({ value: rowData[columnIndex + 2] });
            }
            return ellipsisText({
              value: `Pending ${Math.ceil(value / 36000 / 24)} Day(s)`,
              color: 'red',
            });
          },
        },
      },
      {
        name: 'signature',
        label: t('pos.acknowledged'),
        options: {
          filter: true,
          search: true,
          display: false,
          customBodyRender: value => (value ? 'Yes' : 'No'),
        },
      },
      {
        name: 'acknowledgedAt',
        label: t('pos.acknowledgedAt'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'supplierCompanyName',
        label: t('pos.supplier'),
        options: {
          display: !masterId,
          filter: true,
          search: false,
          customBodyRender: value => ellipsisText({ value, length: 20 }),
        },
      },
      {
        name: 'sparesCategory',
        label: t('pos.sparesCategory'),
        options: {
          filter: true,
          search: true,
          display: false,
          customBodyRender: value => ellipsisText({ value }),
        },
      },
      {
        name: 'leadtime',
        label: t('pos.poLeadtime'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'inventory',
        label: t('pos.poInventory'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'hasRawMaterial',
        label: t('pos.poHasRawMaterial'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'materialStartDate',
        label: t('pos.poProductionReady'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'productionCompleteDate',
        label: t('pos.poProductionComplete'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      {
        name: 'productionStartDate',
        label: t('pos.poProductionStart'),
        options: {
          filter: false,
          search: false,
          display: false,
        },
      },
      ...new Array(10).fill({
        name: '',
        options: {
          display: false,
          filter: false,
          sort: false,
          search: false,
        },
      }),
    ]
      .map((col, ix) => {
        return ix % 2 === 0 && !col.options?.setCellHeaderProps
          ? _.mergeWith(col, {
              options: {
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: 'nowrap',
                    background: '#F8F8FF',
                  },
                }),
              },
            })
          : col;
      })
      .map((col, ix) => {
        return ix % 2 !== 0 && !col.options?.setCellHeaderProps
          ? _.mergeWith(col, {
              options: {
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: 'nowrap',
                  },
                }),
              },
            })
          : col;
      })
      .map((col, ix) => {
        return ix % 2 === 0 && !col.options?.setCellProps
          ? _.mergeWith(col, {
              options: {
                setCellProps: () => ({
                  style: {
                    background: '#F8F8FF',
                  },
                }),
              },
            })
          : col;
      });

    const CustomToolbar = () => {
      const statusFilterChange = event =>
        setState({ ...state, [event.target.name]: event.target.checked });
      return (
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={3}>
            <Typography className={listClasses.title} variant="h3">
              {t('pos.poId1')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid
              className={listClasses.typeFilter}
              container
              direction="row"
              justify="center"
              spacing={1}
            >
              <FormGroup row>
                {Object.keys(statusEnum).map(statusKeys => {
                  return (
                    <FormControlLabel
                      key={statusKeys}
                      control={
                        <Checkbox
                          checked={state[statusKeys]}
                          onChange={statusFilterChange}
                          name={statusKeys}
                          color="primary"
                        />
                      }
                      label={t(`common.${statusKeys}`)}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            {!masterId && isOwnerOrAdmin && (
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setShowUploadModal(true);
                }}
              >
                {t('pos.uploadPo')}
              </Button>
            )}
          </Grid>
        </Grid>
      );
    };

    if (loading) {
      return <LoadingComponent />;
    }
    return (
      <Grid className={listClasses.table}>
        {enableFilter && <CustomToolbar />}
        <Table
          options={{
            filter: true,
            pagination: true,
            search: true,
            download: !masterId,
            downloadOptions: { filename: `PO_Lines_${moment().unix()}.csv` },
            rowsPerPageOptions: [10, 50, 100],
            textLabels: {
              body: {
                noMatch: t('common.noMatchingRecords'),
              },
              pagination: {
                rowsPerPage: t('common.rowsPerPage'),
              },
            },
            tableBodyMaxHeight: '40rem',
            onDownload: (buildHead, buildBody, oldColumns, oldData) => {
              return makeDownload({
                dataColumn,
                buildHead,
                buildBody,
                oldColumns,
                oldData,
              });
            },
          }}
          data={tableData}
          columns={tableColumn}
        />
      </Grid>
    );
  };

  TableComponent.propTypes = {
    enableFilter: PropTypes.bool,
  };

  TableComponent.defaultProps = {
    enableFilter: true,
  };

  const makeRequestData = (excelItem, fileTableCol) => {
    const foreignPurchaseNumber = makeMasterIDs({
      idMaps: {
        B: 'poNum',
        C: 'releaseNum',
        D: 'lineNum',
      },
      excelItem,
      fileTableCol,
    });
    const supplierInput = makePayload({
      updateMap: {
        L: 'leadtime',
        M: 'inventory',
        N: 'hasRawMaterial',
        O: 'materialStartDate',
        P: 'productionStartDate',
        Q: 'productionCompleteDate',
      },
      excelItem,
      fileTableCol,
    });
    return {
      input: {
        supplementInfo: { foreignPurchaseNumber },
        supplierInput,
      },
    };
  };

  return (
    <Grid className={listClasses.root}>
      {masterId && <PoHead masterId={masterId} customizedStatus="" background="" />}
      {masterId && <PoMasterDetails masterId={masterId} />}
      <TableComponent enableFilter={!masterId} />
      <UploadModal
        action={updatePo}
        makePayload={makeRequestData}
        open={showUploadModal}
        // todo
        refetch={() => {}}
        onClose={() => setShowUploadModal(false)}
      />
    </Grid>
  );
};
