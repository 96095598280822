import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { useHistory } from 'react-router';
import PageLoader from '../../components/Shared/PageLoader';
import StepForm from '../../components/Traceability/WizardV2/StepForm';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import WizardProgressBar from '../../components/Traceability/WizardV2/WizardProgressBar';
import { TRACEABILITY_ROUTE } from '../../constants';

const useStyles = makeStyles(theme => ({
  stepsRoot: {
    padding: theme.spacing(1),
    minHeight: '100vh',
  },
  mainHeader: {
    fontWeight: 700,
    marginTop: '1.5em',
  },
  itemLabel: {
    marginTop: '0.75em',
  },
}));

const Steps = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { instanceId, isDataLoading } = useConfigurationWizardContext();
  const { activeWallet } = useBecOrganizationContext();
  const [currentWallet] = useState(activeWallet);
  const history = useHistory();

  useEffect(() => {
    if (currentWallet !== activeWallet) {
      history.push(TRACEABILITY_ROUTE);
    }
  }, [activeWallet]);

  if (isDataLoading) {
    return <PageLoader />;
  }

  if (!instanceId) {
    return (
      <div className={classes.stepsRoot}>
        <Typography variant="body2" className={classes.bodyBanner}>
          {t('traceability.traceWizardError')}
        </Typography>
      </div>
    );
  }

  return (
    <WizardProgressBar>
      <div className={classes.stepsRoot}>
        <div className={classes.formArea}>
          <StepForm />
        </div>
      </div>
    </WizardProgressBar>
  );
};

export default Steps;
