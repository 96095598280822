import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BecFramework from '@eyblockchain/ey-ui/core/BecFramework';
import LandingPage from './pages/Shared/LandingPage';
import i18n from './i18n';

function PublicApp() {
  return (
    <>
      <BecFramework appName="OpsChain" LogoProps={{ href: '/' }} defaultLanguage={i18n.language} />
      <Switch>
        <Route exact path="/certificate/:txHash" component={LandingPage} />
      </Switch>
    </>
  );
}

export default PublicApp;
