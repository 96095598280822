import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import { filter, includes, orderBy, find } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { useNotarizationContext } from '../../contexts/Notarization/notarizationContext';
import NotarizationContractListCard from './NotarizationContractListCard';
import NotarizationContractPanel from './NotarizationContractPanel';

const useStyles = makeStyles(theme => ({
  ContractListRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  listHeaderArea: {
    marginTop: theme.spacing(1),
    boxShadow: '0 -1.75px 0 0 rgba(0,0,0,.04)',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  searchBox: {
    minWidth: '30%',
  },
  contractFooterArea: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  rootBox: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    mt: 3,
  },
}));

const NotarizationContractList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { notarizationContracts, setSelectedNotarizationContract } = useNotarizationContext();
  const [isMinimized, setIsMinimized] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('-1');

  const rowsPerPageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
  ];

  let contracts = notarizationContracts?.map(el => {
    return {
      _id: el?._id,
      name: el?.contractName,
      address: el?.contractAddress,
      balance: 0,
      status: el?.transaction?.status,
    };
  });

  if (searchText) {
    contracts = filter(
      contracts,
      el =>
        includes(el?.name?.toLowerCase(), searchText?.toLowerCase()) ||
        includes(el?.symbol?.toLowerCase(), searchText?.toLowerCase()),
    );
  }
  contracts = orderBy(contracts, ['_id'], ['desc']);
  const pagedContracts = contracts.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const resetContractPanel = () => {
    setIsMinimized(false);
    setSelectedIndex('-1');
  };

  const handlePageChange = (event, value) => {
    resetContractPanel();
    setPage(value);
  };

  const handleRowsPerPageChange = event => {
    resetContractPanel();
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleMinimize = contractId => {
    let selectedContract = {};
    if (notarizationContracts?.length && notarizationContracts?.length > 0) {
      selectedContract = find(notarizationContracts, notarizationContract => {
        return notarizationContract._id === contractId;
      });
      setSelectedNotarizationContract(selectedContract);
    }
    if (selectedIndex === contractId) {
      resetContractPanel();
    } else {
      setIsMinimized(true);
      setSelectedIndex(contractId);
    }
  };

  return (
    <>
      <div className={classes.ContractListRoot}>
        <div className={classes.listHeaderArea}>
          <TextField
            className={classes.searchBox}
            id="search-contract"
            variant="outlined"
            placeholder={t('contracts.searchContracts')}
            value={searchText}
            onChange={event => {
              setSearchText(event?.target?.value);
              if (event?.target?.value && isMinimized) {
                resetContractPanel();
              }
              setPage(1);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="large" color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box className={classes.rootBox}>
          <Box display="flex" flexGrow={1} overflow="auto">
            <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
              {pagedContracts?.map(contract => (
                <NotarizationContractListCard
                  isMinimized={isMinimized}
                  contractDetails={contract}
                  key={contract._id}
                  selectedIndex={selectedIndex}
                  handleMinimize={handleMinimize}
                />
              ))}
            </Box>
            {selectedIndex !== '-1' && (
              <NotarizationContractPanel
                selectedIndex={selectedIndex}
                handlePanelClose={resetContractPanel}
              />
            )}
          </Box>
        </Box>
        <div className={classes.contractFooterArea}>
          <Select
            labelId="contracts-per-page"
            id="contracts-per-page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            {rowsPerPageOptions?.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              );
            })}
          </Select>
          <Pagination
            count={Math.ceil(contracts?.length / rowsPerPage) || 1}
            page={page}
            onChange={handlePageChange}
            disabled={contracts?.length < 1}
            variant="text"
          />
        </div>
      </div>
    </>
  );
};
export default NotarizationContractList;
