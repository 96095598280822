import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik, Form, Field, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import FormErrors from '../Shared/FormErrors';
import { useTransaction } from '../../contexts/Procurement/transaction';
import { useContractContext } from '../../contexts/Procurement/contract';
import { usePurchaseOrderContext } from '../../contexts/Procurement/purchaseOrder';
import { useAuth } from '../../contexts/Shared/auth';
import DatePickerField from '../Shared/DatePickerField';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';
import ContractPricingTierTable from './ContractPricingTierTable';
import {
  calculateTotalPrice,
  formatRates,
  formatCurrencySymbol,
  getCurrencyName,
  getPrettifiedEthAddr,
} from '../../utils';
import track from '../../mixpanel';
import PurchaseOrderPricingTable from './PurchaseOrderPricingTable';
import ContractPricingRebateTable from './ContractPricingRebateTable';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import { PERMISSIONS } from '../../constants';
import OcmAccessDeniedLandingPage from '../../pages/Procurement/OcmAccesDeniedLandingPage';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  fields: {
    paddingTop: theme.spacing(2),
    flexDirection: 'column',
  },
  tierComponent: {
    marginTop: 0,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    display: 'block',
  },
  warning: {
    paddingTop: theme.spacing(2),
    color: '#B9251C',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  initialFields: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '& > div:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  dateSchedule: {
    width: '100%',
  },
  signingBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 30,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(3)}px`,
    boxShadow: `-8px 8px 8px 0px rgba(0,0,0,.25)`,
  },
  materialHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    paddingLeft: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  tableData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  materialName: {
    padding: 0,
    fontWeight: 'bold',
  },
  totalPriceBox: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(2),
  },
  totalPrice: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  materialsBlock: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  materialsTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  netValue: {
    color: theme.colors.gray,
    textTransform: 'uppercase',
  },
  loaderContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
  },
}));

const CreatePurchaseOrder = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    contracts: myContracts,
    contractLoading,
    getContractsByBusinessPartner,
  } = useContractContext();
  const { createPurchaseOrder, purchaseOrderLoading } = usePurchaseOrderContext();
  const [open, setOpen] = useState(false);
  const { signData } = useTransaction();
  const { company } = useAuth();
  const { activeOrganization } = useBecOrganizationContext();
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [purchaseOrderVolume, setPurchaseOrderVolume] = useState([]);
  const history = useHistory();
  const { allPartners, getAllPartnersLoading, getAllPartners } = usePartnerContext();
  const { euroCurrencySymbol } = useSubscriptionContext();

  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;
  const { userPermissions } = useUserInfo();

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATEPURCHASEORDER)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  const NETWORK_LEADER_PARTNER = 'NETWORK_LEADER_PARTNER';
  const OWN_PARTNER = 'OWN_PARTNER';

  useEffect(() => {
    getAllPartners();
  }, [getAllPartners]);

  useEffect(() => {
    if (myContracts && myContracts.length > 0)
      setContracts(
        myContracts.filter(
          myContract => myContract.supplier.organization._id !== activeOrganization._id,
        ),
      );
  }, [myContracts, company]);

  useEffect(() => {
    setContract({});
  }, [open]);

  let partnersData = [];
  let disableOptionEnable = false;
  /** Note:
   * ------
   * check networkLeaderPartners &  ownPartners array
   * both are empty  its return none
   * if any one have the value return the group name
   */
  const setGroupNameInDropdown = (partnersArray, groupName) => {
    if (partnersArray?.networkLeaderPartners.length < 0 && partnersArray?.ownPartners.length < 0) {
      return '';
    }
    return groupName;
  };

  if (allPartners.networkLeaderPartners || allPartners.ownPartners) {
    /** Note:
     * ------
     * check networkLeaderPartners &  ownPartners array
     * if networkLeader or ownPartners array is empty  return the value as none
     * also its disable.
     */
    if (allPartners.networkLeaderPartners.length === 0 || allPartners.ownPartners.length === 0) {
      disableOptionEnable = true;
    }
    let ownPartners = [];
    if (allPartners?.ownPartners.length > 0) {
      ownPartners = allPartners?.ownPartners.map(p => {
        return {
          value: p.partnerOrganization._id,
          label: p.partnerOrganization.name,
          from: OWN_PARTNER,
          groupby: setGroupNameInDropdown(allPartners, t('pos.ownPartners')),
        };
      });
    } else if (
      allPartners?.ownPartners.length === 0 &&
      allPartners?.networkLeaderPartners.length > 0
    ) {
      ownPartners = [
        {
          value: 'networkLeaderPartnerEmpty',
          label: `${t('pos.none')}`,
          from: OWN_PARTNER,
          groupby: setGroupNameInDropdown(allPartners, t('pos.ownPartners')),
        },
      ];
    }
    let networkLeaderPartners = [];
    if (allPartners?.networkLeaderPartners.length > 0) {
      networkLeaderPartners = allPartners?.networkLeaderPartners.map(p => {
        return {
          value: p.partnerOrganization._id,
          label: p.partnerOrganization.name,
          from: NETWORK_LEADER_PARTNER,
          groupby: setGroupNameInDropdown(allPartners, t('pos.networkLeaderPartners')),
        };
      });
    } else if (
      allPartners?.ownPartners.length > 0 &&
      allPartners?.networkLeaderPartners.length === 0
    ) {
      networkLeaderPartners = [
        {
          value: 'networkLeaderPartnerEmpty',
          label: `${t('pos.none')}`,
          from: NETWORK_LEADER_PARTNER,
          groupby: setGroupNameInDropdown(allPartners, t('pos.networkLeaderPartners')),
        },
      ];
    }

    partnersData = ownPartners.concat(networkLeaderPartners);
  }

  const formik = useFormik({
    initialValues: {
      supplier: null,
      contractId: null,
      buyerContractHeaderId: null,
      materials: [],
      price: 0,
    },
    onSubmit: async (values, { resetForm }) => {
      track('Click Submit PO');
      const supplier = {
        organizationId: values.supplierCompany._id,
      };

      const input = {
        contractId: values.contractId.value,
        buyerContractHeaderId: values.buyerContractHeaderId,
        materials: [],
        supplier,
      };
      values.materials.forEach(material => {
        if (Number(material.volume) > 0) {
          const materialInput = {
            sku: material.sku,
            materialName: material.materialName,
            materialDescription: material.materialDescription,
            volume: Number(material.volume) || 0,
            deliveryDate: moment(material.deliveryDate * 1000).format(),
          };
          input.materials.push(materialInput);
        }
      });

      const preimage = JSON.stringify(input);

      const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
      const { blockchainType } = JSON.parse(blockchainCookie);
      const buyerSignature = await signData(preimage, blockchainType);

      const valued = {
        ...input,
        parentOrganizationId:
          activeOrganization._id === values.contractId.parentOrganizationId
            ? null
            : values.contractId.parentOrganizationId,
        buyerSignature,
        buyerContractHeaderId: values.buyerContractHeaderId,
      };

      await createPurchaseOrder({
        variables: {
          input: {
            ...valued,
          },
        },
      });
      setOpen(false);
      resetForm();
    },
    validationSchema: Yup.object().shape({
      supplier: Yup.mixed().test('', t('pos.validation.supplier'), val => {
        return !!(val !== null);
      }),
      contractId: Yup.mixed().test('', t('pos.validation.contractId'), val => {
        return !!(val !== null);
      }),
      materials: Yup.array()
        .of(
          Yup.object().shape({
            materialName: Yup.string().required(t('rfqs.validation.material')),
            materialDescription: Yup.string().required(t('rfqs.validation.description')),
            rates: Yup.array().of(
              Yup.object().shape({
                quantity: Yup.number().required('Quantity required'),
                price: Yup.number().required('Price required'),
                volume: Yup.number().required(t('pos.validation.poOrderQuantity')),
              }),
            ),
            volume: Yup.number(),
            price: Yup.number(),
            deliveryDate: Yup.string().required(
              t('pos.validation.purchaseOrderMaterialDeliveryDate'),
            ),
          }),
        )
        .compact(value => {
          return !(value.volume && value.volume > 0);
        })
        .required(t('pos.validation.poOrderQuantityRequired')),
      price: Yup.number().required(t('pos.validation.poOrderOneQuantity')),
    }),
  });

  const setContractId = contractId => {
    formik.setFieldValue('contractId', contractId);
    const contractTemp =
      contractId.value && contractId && contracts.length > 0
        ? contracts.find(obj => obj.contractId === contractId.value)
        : { contractId: { lablel: '', value: '' }, material: '' };
    setContract(contractTemp);
    setTotalPrice(0);
    setPurchaseOrderVolume([]);
    contractTemp.materials.forEach(material => {
      // eslint-disable-next-line no-param-reassign
      material.deliveryDate = moment().unix();
      // eslint-disable-next-line no-param-reassign
      material.price = 0;
      // eslint-disable-next-line no-param-reassign
      material.volume = '';
    });
    formik.setFieldValue('materials', contractTemp.materials);
    formik.setFieldValue('supplierCompany', contractTemp.supplier.organization);
    formik.setFieldValue('buyerContractHeaderId', contractId.contractHeaderId);
  };

  useEffect(() => {
    if (
      formik.values.contractId &&
      Object.keys(formik.values.contractId).length > 0 &&
      formik.values.contractId.value !== ''
    ) {
      setContractId(formik.values.contractId);
    }
  }, [formik?.values?.contractId]);

  useEffect(() => {
    formik.values.contractId = null;
    formik.values.materials = [];
    if (
      formik.values.supplier &&
      Object.keys(formik.values.supplier).length > 0 &&
      formik.values.supplier.value !== ''
    ) {
      getContractsByBusinessPartner({
        variables: {
          businessPartner: formik.values.supplier.value,
          status: 'active',
        },
      });
    }
  }, [formik?.values?.supplier]);

  const items = [];

  if (contract && contract.materials) {
    contract.materials.forEach((material, index) => {
      let rates;
      let quantitySet;
      if (contract.contractType !== 'rebatePricing') {
        rates = formatRates([material]);
        quantitySet =
          Number(formik?.values.materials[index]?.volume) + Number(material.accumulatedVolume || 0);
      }
      let isTiered = false;
      const item = {
        title: contract.contractType === 'rebatePricing' ? 'Rebate thresholds' : 'Tier prices',
        isRebateContract: contract.contractType === 'rebatePricing',
        tierBounds:
          contract.contractType !== 'rebatePricing'
            ? material.tierRules.tierBounds
            : material.rebateRules.thresholdQty,
        pricesByTier:
          contract.contractType !== 'rebatePricing'
            ? material.tierRules.pricesByTier
            : material.rebateRules.rebateValue,
        accumulatedVolume: material.accumulatedVolume,
        materialName: material.materialName || '',
        netValue: formatCurrencySymbol(
          `${formik?.values?.materials[index]?.price || 0}`,
          euroCurrencySymbol,
        ),
        pricings:
          contract.contractType !== 'rebatePricing'
            ? rates.map((rate, rateIndex) => {
                let quantityInTier = 0;
                if (quantitySet && !isTiered) {
                  if (rate.endRange <= quantitySet) {
                    quantityInTier =
                      quantitySet > rate.endRange && rateIndex === rates.length - 1
                        ? `${rate.endRange}+`
                        : rate.endRange;
                    quantitySet -= rate.endRange;
                  } else {
                    quantityInTier = quantitySet;
                    isTiered = true;
                  }
                }
                return {
                  Quantity:
                    rates.length - 1 === rateIndex
                      ? `${rate.startRange} - ${rate.endRange}+`
                      : `${rate.startRange} - ${rate.endRange}`,
                  QuantityInTier: `${quantityInTier}`,
                  NetPrice: formatCurrencySymbol(`${rate.price}`, euroCurrencySymbol),
                };
              })
            : material.rebateRules?.thresholdQty?.map((value, valueIndex) => {
                return {
                  RebateThreshold: value,
                  RebatePercentage: `${material.rebateRules.rebateValue[valueIndex]}%`,
                };
              }),
      };
      items.push(item);
    });
  }

  const setVolume = (e, material, index, rebateContract) => {
    const value = Number(e.target.value);
    formik.setFieldValue(`materials[${index}].volume`, value || 0);
    const volumeList = [...purchaseOrderVolume];
    volumeList[index] = value;
    setPurchaseOrderVolume(volumeList);
    let materialPriceTemp;
    if (rebateContract) {
      materialPriceTemp = Number(e.target.value) * material.rebateRules.unitPrice;
    } else {
      materialPriceTemp = calculateTotalPrice(
        material.tierRules.tierBounds,
        material.tierRules.pricesByTier,
        material.tierRules.accumulatedVolume,
        Number(e.target.value),
      );
    }

    formik.setFieldValue(`materials[${index}].price`, materialPriceTemp || 0);

    let sumPrices = 0;
    formik.values.materials.forEach((item, i) => {
      if (i !== index) {
        sumPrices += item.price;
      } else {
        sumPrices += materialPriceTemp;
      }
    });
    setTotalPrice(sumPrices || 0);
    formik.setFieldValue('price', totalPrice || 0);
  };

  const getParentOrganizationName = contractDetail => {
    if (contractDetail.organizationId !== activeOrganization._id) {
      return contractDetail?.buyer?.organization?.name;
    }
    return '';
  };

  const getContractsForDropDown = () => {
    const temp =
      formik.values.supplier && Object.keys(formik.values.supplier).length > 0 && contracts.length
        ? contracts.filter(obj => obj.supplier.organization._id === formik.values.supplier.value)
        : [];
    return temp.length
      ? temp
          .map(obj => {
            return {
              label: `CT-${obj.contractId.padStart(10, 0)}`,
              value: obj.contractId,
              contractHeaderId: obj._id,
              parentOrganizationId: obj.organizationId,
              parentOrgName: getParentOrganizationName(obj),
              smartContractAddress: getPrettifiedEthAddr(obj?.smartContract?.contractAddress),
            };
          })
          .filter(item => {
            if (formik?.values?.supplier.from === 'NETWORK_LEADER_PARTNER') {
              return item !== undefined && item.parentOrganizationId !== activeOrganization._id;
            }
            return item !== undefined && item.parentOrganizationId === activeOrganization._id;
          })
      : [];
  };

  const closeModal = () => {
    history.goBack();
  };

  return isUserNotAuthorized() ? (
    isUserNotAuthorized()
  ) : (
    <>
      {getAllPartnersLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {purchaseOrderLoading || contractLoading ? (
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          ) : (
            ''
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            px={2}
            className={classes.navContainer}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => closeModal()}
              className={classes.navButton}
              startIcon={<ArrowBackIosIcon />}
            >
              {t('pos.purchaseOrders')}
            </Button>
          </Box>
          <Divider classes={{ root: classes.navButtonDivider }} />
          <Card className={classes.root}>
            <Typography variant="h4" className={classes.title}>
              {t('pos.createPurchaseOrder')}
            </Typography>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} className={classes.form}>
                <FormErrors form={formik} />
                <Grid container className={classes.fields}>
                  <Grid container className={classes.initialFields}>
                    <Grid item xs={3}>
                      <Typography className={classes.subtitle} variant="subtitle1">
                        {t('common.businessPartner')}
                      </Typography>
                      <Field
                        component={AutocompleteDropDown}
                        label={t('common.businessPartner')}
                        onChange={formik.handleChange}
                        name="supplier"
                        options={partnersData}
                        disableOptionEnable={disableOptionEnable}
                        testid="createPO-dropdown-partners"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.subtitle} variant="subtitle1">
                        {t('headers.contract')}
                      </Typography>
                      <Field
                        component={AutocompleteDropDown}
                        label={t('pos.contractId')}
                        value={formik.values.contractId}
                        withMultiPartyContractDetails
                        name="contractId"
                        options={getContractsForDropDown()}
                        disabled={!formik?.values?.supplier?.value}
                        testid="createPO-dropdown-contracts"
                      />
                    </Grid>
                  </Grid>
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  {formik.values.materials && formik.values.materials.length ? (
                    <Typography variant="h4" className={classes.materialsTitle}>
                      {t('common.materials')}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {formik.values.materials && formik.values.materials.length ? (
                    formik.values.materials.map((material, index) => {
                      return (
                        <div>
                          <PurchaseOrderPricingTable
                            materials={[items[index]]}
                            key={`pricing-table-${material.materialName}-${material._id}`}
                          >
                            <Grid container direction="column" className={classes.materialsBlock}>
                              <Grid container>
                                <Grid item xs={12} className={classes.tableData}>
                                  <Grid item xs={4} className={classes.materialHeader}>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle1"
                                        className={classes.materialDescription}
                                      >
                                        {material.materialDescription}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.quantityField}>
                                      <Field
                                        component={TextField}
                                        label={t('tokenizedGoods.quantity')}
                                        onChange={e =>
                                          formik.values.supplier &&
                                          setVolume(
                                            e,
                                            material,
                                            index,
                                            items[index].isRebateContract,
                                          )
                                        }
                                        name={`materials[${index}].volume`}
                                        data-testid={`po-quantity-field-${index}`}
                                        key={`input-${material._id}`}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Field
                                        component={DatePickerField}
                                        label={t('pos.deliveryDate')}
                                        onChange={formik.handleChange}
                                        name={`materials[${index}].deliveryDate`}
                                        className={classes.dateSchedule}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={7}>
                                    {items && !items[index].isRebateContract && (
                                      <ContractPricingTierTable
                                        key={uniqid()}
                                        materials={[items[index]]}
                                        purchaseOrderVolume={purchaseOrderVolume[index]}
                                      />
                                    )}
                                    {items &&
                                      items[index].isRebateContract &&
                                      items[index].tierBounds && (
                                        <ContractPricingRebateTable
                                          key={uniqid()}
                                          materials={[items[index]]}
                                          purchaseOrderVolume={purchaseOrderVolume[index]}
                                        />
                                      )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </PurchaseOrderPricingTable>
                          <Divider classes={{ root: classes.navButtonDivider }} />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Grid>
                {formik.values.materials && formik.values.materials.length ? (
                  <Grid
                    item
                    container
                    alignItems="flex-end"
                    direction="column"
                    xs={12}
                    className={classes.totalPriceBox}
                  >
                    <Typography variant="subtitle1" className={classes.netValue}>
                      {t('pos.totalNetValue')}
                    </Typography>
                    <Typography variant="h4" className={classes.totalPrice}>
                      {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                        totalPrice,
                        euroCurrencySymbol,
                      )}`}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid container className={classes.signingBox}>
                  <Button onClick={() => closeModal()} variant="outlined" color="primary">
                    {t('common.cancel')}
                  </Button>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    data-testid="create-po-button"
                  >
                    {t('pos.submitPo')}
                  </Button>
                </Grid>
              </Form>
            </FormikProvider>
          </Card>
        </>
      )}
    </>
  );
};

export default CreatePurchaseOrder;
