import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  rootDark: {
    border: '1px solid transparent',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  selected: {
    border: '1px solid transparent',
  },
  selectedPrimaryText: {
    fontWeight: 'bold',
  },
  selectedLight: {
    backgroundColor: '#FFFF !important',
    boxShadow: 'inset -1px 0 0 0 #E7E7EA',
  },
  selectedDark: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#1a1a24',
    boxShadow: `inset -1px 0 0 0 ${theme.palette.secondary.contrastText}`,
  },
  hover: {
    '&:hover': {
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
    },
  },
  hoverLight: {
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.secondary.contrastText}`,
    },
  },
  hoverDark: {
    '&:hover': {
      backgroundColor: '#1a1a24',
      border: `1px solid ${theme.palette.primary.contrastText}`,
    },
  },
  itemText: {
    fontWeight: 'bold !important',
  },
  categorySubItems: {
    paddingLeft: theme.spacing(1.5),
  },
  categoryInsideListSubItems: {
    paddingLeft: theme.spacing(2.5),
  },
}));

const NavItem = ({ item, selected, variant, hasCategory, isItemInsideList, classes }) => {
  const { icon, name, url, onClick, component } = item;
  const defaultClasses = useStyles();
  const isSelected = name === selected;

  let listItemProps = {};
  if (onClick) listItemProps = { onClick };
  else if (component) listItemProps = { to: url };
  else if (url) listItemProps = { href: url };

  return (
    <ListItem
      button
      component={!component ? 'a' : component}
      selected={isSelected}
      classes={{
        root: clsx({
          [classes.root]: classes.root,
          [defaultClasses.rootDark]: variant === 'dark',
          [defaultClasses.hover]: true,
          [defaultClasses.hoverLight]: variant === 'light',
          [defaultClasses.hoverDark]: variant === 'dark',
        }),
        selected: clsx({
          [classes.selected]: classes.selected,
          [defaultClasses.selected]: true,
          [defaultClasses.selectedLight]: variant === 'light',
          [defaultClasses.selectedDark]: variant === 'dark',
        }),
      }}
      {...listItemProps}
    >
      {icon && <Box pr={2}>{icon}</Box>}
      <ListItemText
        primary={name}
        classes={{
          primary: clsx({
            [classes.primaryText]: classes.primaryText,
            [classes.selectedPrimaryText]: isSelected && classes.selectedPrimaryText,
            [defaultClasses.selectedPrimaryText]: isSelected,
            [defaultClasses.categorySubItems]:
              hasCategory && !isItemInsideList && defaultClasses.categorySubItems,
            [defaultClasses.categoryInsideListSubItems]:
              hasCategory && isItemInsideList && defaultClasses.categoryInsideListSubItems,
          }),
        }}
      />
    </ListItem>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    subItems: PropTypes.arrayOf(PropTypes.shape({})),
    onClick: PropTypes.func,
    component: PropTypes.elementType,
  }).isRequired,
  selected: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  classes: PropTypes.shape({
    root: PropTypes.string,
    selected: PropTypes.string,
    selectedPrimaryText: PropTypes.string,
    primaryText: PropTypes.string,
  }),
  hasCategory: PropTypes.bool,
  isItemInsideList: PropTypes.bool,
};

NavItem.defaultProps = {
  variant: 'light',
  classes: {},
  hasCategory: false,
  isItemInsideList: false,
};

export default NavItem;
