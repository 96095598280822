import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  hasError: {
    backgroundColor: theme.palette.error.lightest,
    '&:hover': {
      backgroundColor: theme.palette.error.lightest,
    },
  },
  buttonRoot: {
    borderColor: theme.palette.primary.lighter,
    width: theme.spacing(20),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      boxShadow: `inset 0.5rem 0rem ${theme.palette.secondary.main}`,
    },
  },
  grouped: {
    backgroundColor: theme.palette.primary.contrastText,
    '&:not(:first-child)': {
      borderRadius: 0,
      borderLeft: `1px solid ${theme.palette.primary.lighter}`,
    },
    '&:first-child': {
      borderRadius: 0,
    },
  },
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  captionText: {
    fontWeight: 100,
    color: theme.palette.primary.light,
  },
}));

const FormikSwitch = ({
  field: { value, name },
  form: { errors, setFieldError, setFieldTouched, setFieldValue },
  className: classNameProp,
  labels,
  ...rest
}) => {
  const hasError = errors[name] !== undefined;
  const classes = useStyles();
  const { t } = useTranslation();

  const groupClassName = clsx(classes.root, classNameProp);
  const buttonClassName = clsx(classes.buttons, { [classes.hasError]: hasError });

  const handleToggleChange = async (event, newValues) => {
    if (newValues !== null) {
      setFieldTouched(name, true, false);
      try {
        setFieldValue(name, newValues);
      } catch (err) {
        setFieldError(name, `${t('common.modeSwitchFailed')}: ${err.message}`);
      }
    }
  };

  const toggleButtons = labels.map(label => {
    return (
      <ToggleButton
        key={`behavior-${label}`}
        value={label}
        classes={{
          sizeSmall: classes.buttonRoot,
          selected: classes.selectedRadio,
        }}
        className={buttonClassName}
        data-testid="metadata-type-button"
      >
        {t(`tokens.metaDataStructure.behavior.${label}.label`)}
      </ToggleButton>
    );
  });

  const getExplanationText = () => {
    if (value) {
      return t(`tokens.metaDataStructure.behavior.${value}.explanation`);
    }
    return null;
  };

  return (
    <div className={classes.selectBox}>
      <Typography variant="caption" className={classes.captionText}>
        {t('tokens.metaDataStructure.behavior.helperText')}
      </Typography>

      <ToggleButtonGroup
        className={groupClassName}
        classes={{ grouped: classes.grouped }}
        exclusive
        size="small"
        name={name}
        value={value}
        {...rest}
        onChange={handleToggleChange}
      >
        {toggleButtons}
      </ToggleButtonGroup>

      <Typography variant="caption" className={classes.captionText}>
        {getExplanationText()}
      </Typography>
    </div>
  );
};

FormikSwitch.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FormikSwitch.defaultProps = {
  className: null,
};

export default FormikSwitch;
