import gql from 'graphql-tag';

export const STEPS_ATTRIBUTES = gql`
  fragment STEPS_ATTRIBUTES on Step {
    _id
    name
    order
    isActive
    createToken
    involvedItem {
      _id
      name
      parentItem {
        _id
        name
        tokenContract {
          _id
          contractAddress
          tokenType
        }
      }
      tokenContract {
        _id
        contractAddress
        tokenType
        minters {
          _id
        }
        transaction {
          _id
          transactionHash
          status
          transactionType
          chainId
        }
      }
    }
    ingestionType {
      _id
      name
    }
    instance {
      _id
      name
    }
    stepData {
      _id
      name
      inputConfig
      outputConfig
      order
      notarize
      stepDataType {
        _id
        name
      }
    }
    collaborators {
      partner {
        _id
        partnerOrganization {
          _id
        }
      }
      role
    }
  }
`;

export const GET_STEPS_BY_INSTANCE_ID = gql`
  query stepsByInstance($instance: String!) {
    stepsByInstance(instance: $instance) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const CREATE_STEP = gql`
  mutation createStep($input: inputStep!) {
    createStep(input: $input) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const CREATE_STEPS = gql`
  mutation createStep($input: [inputStep!]!) {
    createSteps(input: $input) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const UPDATE_STEP = gql`
  mutation updateStep($input: inputUpdateStep!) {
    updateStep(input: $input) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const UPDATE_STEPS = gql`
  mutation updateStep($input: [inputUpdateStep!]!) {
    updateStep(input: $input) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const GET_STEP_UPDATE = gql`
  subscription onNewStep {
    onNewStep {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const UPDATE_STEPS_COLLABORATORS = gql`
  mutation updateStepsCollaborators(
    $instanceId: String!
    $newStepInfo: [inputUpdateStepsCollaborators]!
  ) {
    updateStepsCollaborators(instanceId: $instanceId, newStepInfo: $newStepInfo) {
      ...STEPS_ATTRIBUTES
    }
  }
  ${STEPS_ATTRIBUTES}
`;

export const DELETE_STEPS = gql`
  mutation deleteSteps($input: inputDeleteSteps!) {
    deleteSteps(input: $input)
  }
`;
