import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import { FastField } from 'formik';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  METADATA_STRUCTURE_DATA_MUTABILITY,
  METADATA_STRUCTURE_PERMISSIONS,
} from '../../constants';
import BlockChainIcon from './icons/BlockChainIcon';
import MemoryIcon from './icons/MemoryIcon';
import MetaDataFields from './MetaDataFormFields/MetaDataFormFields';

const useStyles = makeStyles(theme => ({
  CardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    paddingLeft: '10px',
    marginTop: '5px',
    paddingRight: theme.spacing(2),
    boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
    marginBottom: theme.spacing(2),
  },
  bannerArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  headerArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  perChip: {
    marginRight: '10px',
    borderColor: '#DCE7F4',
    backgroundColor: '#DCE7F4',
  },
  mutableChip: {
    marginRight: '10px',
    borderColor: '#DCEDE1',
    backgroundColor: '#DCEDE1',
  },
  immutableChip: {
    marginRight: '10px',
    borderColor: '#F5DFDD',
    backgroundColor: '#F5DFDD',
  },
  downloadButton: {
    height: '25px',
  },
}));

const TokenMetaData = ({ selectedToken, metadataConfigs, readOnly, hideRedirectionUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleRedirect = redirectionUrl => {
    window.open(redirectionUrl);
  };

  const groupFields = (configFields, mutability, restriction, redirectionUrl) => {
    return (
      <Card variant="outlined" className={classes.CardRoot}>
        <div className={classes.bannerArea}>
          <div className={classes.headerArea}>
            <div>
              <Chip
                className={classes.perChip}
                label={t(`tokens.metaDataStructure.permissions.${restriction}`)}
              />
              <Chip
                color="secondary"
                className={
                  mutability === t('tokens.mutable') ? classes.mutableChip : classes.immutableChip
                }
                label={t(`tokens.metaDataStructure.dataMutability.${mutability}`)}
              />
            </div>
            {!hideRedirectionUrl && !readOnly && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleRedirect(redirectionUrl);
                }}
                startIcon={mutability === t('tokens.mutable') ? <MemoryIcon /> : <BlockChainIcon />}
                className={classes.downloadButton}
                disabled={!redirectionUrl}
              >
                {mutability === t('tokens.mutable') ? t('tokens.getData') : t('tokens.getProof')}
              </Button>
            )}
          </div>
          {configFields.map((metadataConfig, index) => (
            <FastField
              name={metadataConfig.metadataName}
              component={MetaDataFields}
              fieldMetadataConfig={metadataConfig}
              readOnly={readOnly}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
        </div>
      </Card>
    );
  };

  const populateDynamicFields = () => {
    const publicImmutableFields = filter(metadataConfigs, {
      metadataMutability: METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
      metadataPermission: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
    });
    const privateImmutableFields = filter(metadataConfigs, {
      metadataMutability: METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
      metadataPermission: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
    });
    const publicMutableFields = filter(metadataConfigs, {
      metadataMutability: METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
      metadataPermission: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
    });
    const privateMutableFields = filter(metadataConfigs, {
      metadataMutability: METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
      metadataPermission: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
    });
    return (
      <>
        {publicImmutableFields?.length > 0 &&
          groupFields(
            publicImmutableFields,
            METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
            METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
            selectedToken?.metadataUrls?.publicImmutable,
          )}
        {privateImmutableFields?.length > 0 &&
          groupFields(
            privateImmutableFields,
            METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
            METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
            selectedToken?.metadataUrls?.privateImmutable,
          )}
        {publicMutableFields?.length > 0 &&
          groupFields(
            publicMutableFields,
            METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
            METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
            selectedToken?.metadataUrls?.publicMutable,
          )}
        {privateMutableFields?.length > 0 &&
          groupFields(
            privateMutableFields,
            METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
            METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
            selectedToken?.metadataUrls?.privateMutable,
          )}
      </>
    );
  };

  return <>{populateDynamicFields()}</>;
};

TokenMetaData.propTypes = {
  selectedToken: PropTypes.shape({
    metadata: PropTypes.shape({
      links: PropTypes.shape({}),
    }),
    metadataUrls: PropTypes.shape({
      publicImmutable: PropTypes.string,
      privateImmutable: PropTypes.string,
      publicMutable: PropTypes.string,
      privateMutable: PropTypes.string,
    }),
    tokenId: PropTypes.string.isRequired,
    latestMetadataTransaction: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
    smartContract: PropTypes.shape({
      contractAddress: PropTypes.string.isRequired,
      metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  metadataConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.bool,
  hideRedirectionUrl: PropTypes.bool,
};

TokenMetaData.defaultProps = {
  selectedToken: null,
  readOnly: false,
  hideRedirectionUrl: false,
};

export default TokenMetaData;
