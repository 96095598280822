import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import SaveAndContinueButton from './SaveAndContinueButton';
import ContinueButton from './ContinueButton';
import BackButton from '../BackButton';
import { getCurrentPath } from './traceabilityUtils';
import { TRACE_WIZARD_PROGRESSION_V2 } from '../../../constants';

const useStyles = makeStyles(theme => ({
  buttonArea: {
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
}));

const WizardButtonArea = ({
  isEditMode,
  setEditMode,
  formik,
  isCancelDisabled,
  isProcessing,
  optionalStep = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const currentPath = getCurrentPath(history);

  const SaveContinueButtonDisable = () => {
    let isDisabled = ((!formik.dirty || !isEmpty(formik.errors)) && isEditMode) || isProcessing;
    if (!formik.dirty) {
      if (optionalStep) isDisabled = false;
    }
    return isDisabled;
  };

  return (
    <div className={classes.buttonArea}>
      {!isEditMode && currentPath !== TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_ITEMS.path && (
        <div className={classes.backButton}>
          <BackButton />
        </div>
      )}
      {isEditMode && (
        <SaveAndContinueButton
          disabled={SaveContinueButtonDisable()}
          onSubmit={formik.submitForm}
        />
      )}
      {!isEditMode && <ContinueButton />}
      {isEditMode && (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.cancelButton}
            disabled={isCancelDisabled}
            onClick={async () => {
              setEditMode(false);
              formik.resetForm();
            }}
          >
            {t('traceability.configurationWizard.progress.cancel')}
          </Button>
        </>
      )}
    </div>
  );
};

WizardButtonArea.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  isCancelDisabled: PropTypes.bool,
  formik: PropTypes.shape({
    submitForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.shape({}).isRequired,
  }).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  optionalStep: PropTypes.bool,
};

WizardButtonArea.defaultProps = {
  isCancelDisabled: false,
  optionalStep: false,
};
export default WizardButtonArea;
