import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(() => ({
  nonFungibleChip: {
    color: '#109090',
    borderColor: '#109090',
  },
  fungibleChip: {
    color: '#724BC3',
    borderColor: '#724BC3',
  },
  semiFungibleChip: {
    color: '#35A4E8',
    borderColor: '#35A4E8',
  },
  shortenedChip: {
    maxWidth: '90px',
  },
}));

const TokenTypeChip = ({ tokenType, shortenedLabel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let chipLabel;
  let chipClass;

  switch (tokenType) {
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
      chipLabel = shortenedLabel ? t('tokens.erc1155short') : t('tokens.erc1155');
      chipClass = shortenedLabel
        ? `${classes.semiFungibleChip} ${classes.shortenedChip}`
        : classes.semiFungibleChip;
      break;
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
      chipLabel = shortenedLabel ? t('tokens.erc721short') : t('tokens.erc721');
      chipClass = shortenedLabel
        ? `${classes.nonFungibleChip} ${classes.shortenedChip}`
        : classes.nonFungibleChip;
      break;
    case CONSTANTS.SMARTCONTRACT_TYPES.ERC20:
      chipLabel = shortenedLabel ? t('tokens.erc20short') : t('tokens.erc20');
      chipClass = shortenedLabel
        ? `${classes.fungibleChip} ${classes.shortenedChip}`
        : classes.fungibleChip;
      break;
    default:
      return null;
  }
  return (
    <Chip
      label={chipLabel}
      variant="outlined"
      classes={{
        root: chipClass,
      }}
    />
  );
};

TokenTypeChip.propTypes = {
  tokenType: PropTypes.string,
  shortenedLabel: PropTypes.bool,
};

TokenTypeChip.defaultProps = {
  tokenType: '',
  shortenedLabel: false,
};

export default TokenTypeChip;
