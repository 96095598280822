import { Box, makeStyles, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
  infoText: {
    color: theme.colors.blue,
  },
}));

const InfoMessage = ({ messageText }) => {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} mb={4} ml={1.2}>
      <InfoIcon className={classes.icon} />
      <Typography variant="subtitle1" className={classes.infoText}>
        {messageText}
      </Typography>
    </Box>
  );
};

InfoMessage.propTypes = {
  messageText: PropTypes.string.isRequired,
};

export default InfoMessage;
