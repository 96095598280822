import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    color: '#747480',
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '8px',
    },
    '@global': {
      '*::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .5)',
        borderRadius: '10px',
        border: '1px solid white',
      },
      '*::-webkit-scrollbar:horizontal': {
        height: '10px',
      },
    },
  },
  loaderContainer: theme.loaderContainer,
  toolbarContainer: {
    padding: theme.spacing(3),
  },
  dateSelector: {
    width: '8rem',
  },
  partnerSelector: {
    width: '25rem',
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
  showWarning: {
    color: theme.palette.error.light,
  },
  pointer: {
    cursor: 'pointer',
  },
}));
