import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, FormikProvider, useFormik, FastField } from 'formik';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import MenuItem from '@material-ui/core/MenuItem';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    marginLeft: theme.spacing(2),
  },
  formArea: {
    marginTop: '2em',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  fieldDivider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '16px 10px',
  },
  accordionPaper: {
    margin: '0 10px',
    width: '100%',
  },
  selectField: {
    minWidth: '100%',
  },
}));

const buildOptions = options =>
  options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
const RenderSelect = props => {
  const classes = useStyles();
  const { options } = props;
  return (
    <TextField
      {...props}
      select
      size="small"
      variant="outlined"
      classes={{ root: classes.selectField }}
      disabled={options.length === 0}
    >
      {buildOptions(options)}
    </TextField>
  );
};

RenderSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const useBurnToken = ({ tokenList, contractAddress, loading, onSubmit, closeModal }) => {
  const [tokens, setTokens] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!tokenList?.length) {
      setTokens([]);
    }

    setTokens(
      tokenList
        ?.filter(
          token => token?.latestTransaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED,
        )
        ?.map(token => ({ value: token?.tokenId, label: token?.tokenId })),
    );
  }, [contractAddress, JSON.stringify(tokenList)]);

  const initialValues = {
    tokenId: '',
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const input = {
        tokenId: values.tokenId,
        contractAddress,
      };
      if (loading) return;
      const res = await onSubmit({
        variables: {
          input,
        },
      });
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema: Yup.object().shape({
      tokenId: Yup.string().required(t('tokens.validation.tokenId')),
    }),
  });

  const closeModalFn = () => {
    closeModal();
    formik.resetForm();
  };

  return {
    tokens,
    setTokens,
    closeModalFn,
    formik,
  };
};

const BurnErc721Token = ({ open, closeModal, onSubmit, loading, contractAddress, tokenList }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const { tokens, formik, closeModalFn } = useBurnToken({
    tokenList,
    contractAddress,
    onSubmit,
    loading,
    closeModal,
  });

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.burnTokens')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <div className={classes.formArea}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FastField
                name="tokenId"
                label={t('tokens.tokenId')}
                as={RenderSelect}
                options={tokens}
              />

              <FormErrors form={formik} />

              {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                <BlockchainInfo
                  validateForm={formik.validateForm}
                  values={formik.values}
                  contractAddress={contractAddress}
                  method={CONSTANTS.TRANSACTION_TYPES.BURN}
                  methodArgs={[formik.values.tokenId]}
                />
              ) : (
                ''
              )}
              <Divider className={classes.divider} />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!formik.values.tokenId || loading}
              >
                {t('tokens.burn')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={closeModalFn}
              >
                {t('common.cancel')}
              </Button>
            </Form>
          </FormikProvider>
        </div>
      </Paper>
    </Modal>
  );
};

BurnErc721Token.propTypes = {
  tokenList: PropTypes.arrayOf(
    PropTypes.shape({
      smartContract: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

BurnErc721Token.defaultProps = {
  contractAddress: '',
};

export default BurnErc721Token;
