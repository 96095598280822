import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import MUITextField from '@material-ui/core/TextField';
import { find, filter, isEmpty, findIndex } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Form, FormikProvider, useFormik, Field } from 'formik';
import { SHA256 } from 'crypto-js';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import CertificateMetadataControl from './CertificateMetadataControl';
import {
  NOTARIZE_CERTIFICATE,
  NOTARIZE_CERTIFICATE_BCOS,
} from '../../../graphql/Notarization/certificates';
import { CONSTANTS } from '../../../constants';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import PaymentConfirmation from '../../Shared/PaymentConfirmation';
import { isValidateBlockchainAddress, createHashFromFile, convertToArray } from '../../../utils';
import PageLoader from '../../Shared/PageLoader';
import { useNotification } from '../../../contexts/Shared/notification';
import AddAttachment from './AddAttachment';
import track from '../../../mixpanel';

const useStyles = makeStyles(theme => ({
  CertUploadRoot: {
    padding: theme.spacing(1, 0),
    minWidth: '60%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    minHeight: theme.spacing(68),
  },
  navButton: {
    color: theme.palette.info.main,
  },
  closeIcon: {
    marginRight: -8,
  },
  contractHeader: {
    fontWeight: '400',
    fontSize: 'initial',
  },
  selectField: {
    minWidth: '10%',
    width: '60%',
    marginTop: '10px',
  },
  decField: {
    minWidth: '10%',
    width: '100%',
    marginTop: '10px',
  },

  controlDiv: {
    marginLeft: '23px',
  },
  controlInnerDiv: {
    paddingTop: theme.spacing(3),
  },
  descriptionField: {
    fontWeight: 'initial',
    fontSize: 'initial',
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  addAttachment: {
    marginTop: theme.spacing(2),
  },
}));
const CertificateUpload = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedNotarizationContract, setCertUploadVisible } = useNotarizationContext();
  const { activeWallet } = useBecOrganizationContext();
  const { handleNotification } = useNotification();
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [generatedHash, setGeneratedHash] = useState('0x0000');
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [notarizeCertificateEth, { loading: documentUploadingEth }] = useMutation(
    NOTARIZE_CERTIFICATE,
    {
      onCompleted: async data => {
        if (data.notarizeCertificate) {
          setCertUploadVisible(false);
          handleNotification(t('notarization.certificateSaveSuccess'), 'success');
          track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.NOTARIZE_CERTIFICATE, {
            blockchainNetwork: data?.notarizeCertificate?.blockchainNetwork,
            transactionStatus: data?.notarizeCertificate?.transaction?.status,
            certificateId: data?.notarizeCertificate?._id,
            contractId: data?.notarizeCertificate?.notarizationContract?._id,
          });
        }
      },
      onError: async err => {
        handleNotification(t(`notarization.certificateSaveError : ${err}`), 'error');
        track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.NOTARIZE_CERTIFICATE, {
          blockchainNetwork: activeWallet?.network,
          error: err.message,
        });
      },
    },
  );

  const [notarizeCertificateBcos, { loading: documentUploadingBcos }] = useMutation(
    NOTARIZE_CERTIFICATE_BCOS,
    {
      onCompleted: async data => {
        if (data.notarizeCertificateBcos) {
          setCertUploadVisible(false);
          handleNotification(t('notarization.certificateSaveSuccess'), 'success');
        }
      },
      onError: async err => {
        handleNotification(t(`notarization.certificateSaveError : ${err}`), 'error');
      },
    },
  );

  const notarizeCertificate = async variables => {
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
      case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
        await notarizeCertificateEth(variables);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        await notarizeCertificateBcos(variables);
        break;
      }
      default:
    }
  };

  const defaultFormvalues = () => {
    return {
      certificateName: '',
      rawMode: false,
      fieldArray: [{ key: '', value: '' }],
      content: JSON.stringify({ '': '' }),
      description: '',
      uploadView: true,
      documentUpload: null,
    };
  };

  function oneRecipientValidation(arrayValues) {
    if (arrayValues?.length > 1) {
      const recipient = filter(arrayValues, { key: t('notarization.recipient') });
      if (recipient?.length > 1) {
        const index = findIndex(arrayValues, { key: t('notarization.recipient') }, 0);
        const { createError } = this;
        return createError({
          path: `[fieldArray[${index}].key`,
          message: t('notarization.validation.errorUniqueRecipient'),
        });
      }
      return true;
    }
    return true;
  }

  function validateRecipient(arrayValues) {
    if (arrayValues?.length > 0) {
      const recipient = filter(arrayValues, { key: t('notarization.recipient') });
      if (recipient?.length > 0) {
        const index = findIndex(arrayValues, { key: t('notarization.recipient') }, 0);
        const { createError } = this;
        if (!isValidateBlockchainAddress(arrayValues[index]?.value)) {
          return createError({
            path: `[fieldArray[${index}].value`,
            message: t('notarization.validation.errorValidAddress'),
          });
        }
        return true;
      }
      return true;
    }
    return true;
  }

  const certificateValidationSchema = Yup.object().shape({
    certificateName: Yup.string()
      .required(t('notarization.validation.errorCertificateName'))
      .test('len', t('notarization.validation.errorCretificateLength'), val => val?.length < 32),
    fieldArray: Yup.array().when('rawMode', {
      is: false,
      then: Yup.array()
        .of(
          Yup.object().shape({
            key: Yup.string().required(t('notarization.validation.errorName')),
            value: Yup.string().required(t('notarization.validation.errorValue')),
          }),
        )
        .test(
          'singleRecipient',
          t('notarization.validation.errorUniqueRecipient'),
          oneRecipientValidation,
        )
        .test(
          'recipientAddress',
          t('notarization.validation.errorValidAddress'),
          validateRecipient,
        ),
      otherwise: Yup.array().nullable(),
    }),
    content: Yup.array().when('rawMode', {
      is: true,
      then: Yup.array()
        .transform((value, originalValue) => {
          try {
            return convertToArray(originalValue);
          } catch (err) {
            return [];
          }
        })
        .of(
          Yup.object().shape({
            key: Yup.string().required(t('notarization.validation.errorName')),
            value: Yup.string().required(t('notarization.validation.errorValue')),
          }),
        )
        .test({
          message: t('notarization.validation.errorValidJson'),
          test: array => array.length > 0 && !isEmpty(array[0]),
        })
        .test(
          'singleRecipient',
          t('notarization.validation.errorUniqueRecipient'),
          oneRecipientValidation,
        )
        .test(
          'recipientAddress',
          t('notarization.validation.errorValidAddress'),
          validateRecipient,
        ),
      otherwise: Yup.array().nullable(),
    }),
  });

  const submitForm = async values => {
    try {
      const documentDetails = [];

      const content = values.rawMode ? convertToArray(values.content) : values.fieldArray;

      const recipientValue = find(content, item => {
        return item.key === t('notarization.recipient');
      })?.value;
      const contentDetails = filter(content, item => {
        return item.key !== t('notarization.recipient');
      });

      if (values?.documentUpload) {
        contentDetails.push({
          key: values?.documentUpload?.name,
          value: `0x${await createHashFromFile(values?.documentUpload)}`,
        });

        documentDetails.push({
          fileName: values?.documentUpload?.name,
          hash: `0x${await createHashFromFile(values?.documentUpload)}`,
        });
      }
      selectedDocuments.forEach(doc => documentDetails.push(doc?.value));

      await notarizeCertificate({
        variables: {
          input: {
            content: contentDetails.length > 0 ? JSON.stringify(contentDetails) : '',
            recipient: recipientValue,
            certificateName: values?.certificateName,
            contractId: selectedNotarizationContract?._id,
            description: values?.description,
            documents: documentDetails,
          },
        },
      });
    } catch (err) {
      handleNotification(t('notarization.certificateSaveError'), 'error');
    }
  };

  const formik = useFormik({
    initialValues: defaultFormvalues(),
    validationSchema: certificateValidationSchema,
    onSubmit: submitForm,
  });

  const { values } = formik;

  const handleSubmit = e => {
    const contentsToFilter = values?.rawMode ? convertToArray(values?.content) : values?.fieldArray;
    const contentDetails = filter(contentsToFilter, item => {
      return item.key !== t('notarization.recipient');
    });
    setGeneratedHash(`0x${SHA256(JSON.stringify(contentDetails))}`);

    if (
      activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.ETH ||
      activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.MATIC
    ) {
      e.preventDefault();
      setIsPaymentPopupOpen(true);
    } else {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const itemChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    switch (modifiedField) {
      case 'certificateName':
        formik.setFieldValue(`certificateName`, newValue);
        break;
      case 'description':
        formik.setFieldValue(`description`, newValue);
        break;
      default:
        break;
    }
  };
  const handleBack = () => {
    setCertUploadVisible(false);
  };
  if (documentUploadingEth || documentUploadingBcos) {
    return (
      <Card variant="outlined">
        <PageLoader />
      </Card>
    );
  }
  return (
    <>
      <Card variant="outlined" className={classes.CertUploadRoot}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} px={2}>
          <Button
            variant="text"
            color="primary"
            onClick={handleBack}
            className={classes.navButton}
            startIcon={<ArrowBackIosIcon />}
          >
            {t('notarization.back')}
          </Button>
        </Box>
        <Box
          overflow="auto"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          flexBasis={0}
          px={2}
        >
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <div className={classes.controlDiv}>
                <div>
                  <Typography variant="h5" className={classes.contractHeader}>
                    {t('notarization.issueNewCertificate')}
                  </Typography>
                </div>
                <div>
                  <MUITextField
                    name="certificateName"
                    label="Certificate name"
                    size="small"
                    variant="outlined"
                    className={classes.selectField}
                    value={values.certificateName}
                    onChange={itemChangeHandler}
                    error={formik.errors && !!formik.errors.certificateName}
                    helperText={formik.errors && formik.errors.certificateName}
                  />
                </div>
                <div className={classes.controlInnerDiv}>
                  <Field
                    name="metadataConfigField"
                    component={CertificateMetadataControl}
                    form={formik}
                  />
                </div>
                <div className={classes.addAttachment}>
                  <Field
                    name="addAttachment"
                    component={AddAttachment}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                  />
                </div>
                <div className={classes.controlInnerDiv}>
                  <Typography variant="h5" className={classes.descriptionField}>
                    {t('notarization.addDescription')}
                  </Typography>
                </div>
                <div>
                  <MUITextField
                    name="description"
                    label={t('notarization.writeHere')}
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                    className={classes.decField}
                    value={values.description}
                    onChange={itemChangeHandler}
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                    data-testid="notarization-certificates-updateButton"
                    disabled={
                      !formik.dirty ||
                      !isEmpty(formik.errors || documentUploadingEth || documentUploadingBcos)
                    }
                  >
                    {t('notarization.issueCertificate')}
                  </Button>
                </div>
              </div>
              <div>
                <PaymentConfirmation
                  open={isPaymentPopupOpen}
                  closeModal={() => setIsPaymentPopupOpen(false)}
                  contractAddress={selectedNotarizationContract?.contractAddress}
                  contractName={CONSTANTS.SMARTCONTRACT_TYPES.NOTARIZATION}
                  formik={formik}
                  method={CONSTANTS.TRANSACTION_TYPES.SET_CERTIFICATE}
                  methodArgs={[generatedHash]}
                />
              </div>
            </Form>
          </FormikProvider>
        </Box>
      </Card>
    </>
  );
};
export default CertificateUpload;
