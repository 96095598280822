import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  moreButton: {
    borderRadius: '0',
    border: `1px solid ${theme.palette.primary.lighter}`,
  },
  iconArea: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const IsolatedMenu = ({ menuItems, disabled }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        id={uniqid()}
        onClick={handleMenuClick}
        disabled={disabled}
        className={classes.moreButton}
        size="small"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        key={uniqid()}
        id={`menu-${uniqid()}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(item => (
          <MenuItem
            key={uniqid()}
            className={classes.menuItem}
            onClick={event => {
              handleClose(event);
              item.clickAction();
            }}
          >
            {!!item.icon && <div className={classes.iconArea}>{item.icon}</div>}

            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

IsolatedMenu.propTypes = {
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      clickAction: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

IsolatedMenu.defaultProps = {
  disabled: false,
};

export default IsolatedMenu;
