import { login } from '@eyblockchain/authentication-sdk/browser';
import CustomError from '@eyblockchain/ey-ui/core/BecErrorPages/CustomError';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import uniqid from 'uniqid';
import OnboardingModal from './components/OnboardingModal';
import CreateContract from './components/Procurement/CreateContractWithoutRFQ';
import CreateInvoice from './components/Procurement/CreateInvoice';
import CreatePurchaseOrder from './components/Procurement/CreatePurchaseOrder';
import PageLayout from './components/Shared/PageLayout';
import { subscriptionRequired } from './components/Shared/SubscriptionsPartial';
import { CONSTANTS } from './constants';
import AppProviders from './contexts/Shared/appIndex';
import { useSubscriptionContext } from './contexts/Shared/subscription';
import useUserInfo from './hooks/useUserInfo';
import track from './mixpanel';
import Notarizations from './pages/Notarization/Notarizations';
import ContractDetails from './pages/Procurement/ContractDetails';
// uncomment to enable rfq - https://eyblockchain.atlassian.net/browse/O40-2129
// import RFQ from './pages/Procurement/RFQ';
// import RFQDetails from './pages/Procurement/RFQDetails';
import Contracts from './pages/Procurement/Contracts';
import Dashboard from './pages/Procurement/Dashboard';
import DemandForecast from './pages/Procurement/DemandForecast';
import InvoiceDetails from './pages/Procurement/InvoiceDetails';
import Invoices from './pages/Procurement/Invoices';
import MtrOpenOrderSummary from './pages/Procurement/MtrOpenOrderSummary';
import MtrPurchaseOrderDetails from './pages/Procurement/MtrPurchaseOrderDetails';
import MtrPurchaseOrders from './pages/Procurement/MtrPurchaseOrders';
import Organization from './pages/Procurement/Organization';
import Partners from './pages/Procurement/Partners';
import PurchaseOrderDetails from './pages/Procurement/PurchaseOrderDetails';
import PurchaseOrders from './pages/Procurement/PurchaseOrders';
import MetadataContentPage from './pages/Shared/MetadataContentPage';
import BulkMinting from './pages/Tokenization/BulkMinting';
import ContractList from './pages/Tokenization/ContractList';
import ListingERC721 from './pages/Tokenization/ListingERC721';
// new Traceability components
import traceabilityRoutes from './routers/traceabilityRoute';
import { getIdTokenInfo, getUserId } from './utils';
import api from './utils/api';

const LogLaunchOpschainRef = search => {
  if (sessionStorage.getItem('launch_opschain_navigated_from_logged')) return;
  const query = new URLSearchParams(search);
  const ref = query.get('ref');
  let eventName = '';
  if (!ref) {
    eventName = CONSTANTS.MIXPANEL_EVENTS.OPSCHAIN_NAVIGATION_VIA_URL;
  } else if (
    ref === 'product_procurement_top' ||
    ref === 'product_procurement_bottom' ||
    ref === 'product_traceability_bottom' ||
    ref === 'product_traceability_top'
  ) {
    eventName = CONSTANTS.MIXPANEL_EVENTS.OPSCHAIN_NAVIGATION_VIA_THE_PRODUCT_PAGE;
  } else if (ref === 'bec_nav') {
    eventName = CONSTANTS.MIXPANEL_EVENTS.OPSCHAIN_NAVIGATION_VIA_THE_RIGHT_HAND_MENU;
  }
  const tokenInfo = getIdTokenInfo();
  track(eventName, {
    auth0Id: tokenInfo.sub,
  });
  sessionStorage.setItem('launch_opschain_navigated_from_logged', true);
};

function App() {
  const { pathname, search } = useLocation();
  const [showModal, setShowModal] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { userInfoLoading } = useUserInfo();
  const { t } = useTranslation();
  const { checkUserPreferences } = api.userAPI({ handleNotification: () => {}, t });

  const loadPreferences = useCallback(async () => {
    const userId = getUserId();
    const queryParams = Object.fromEntries(
      new URLSearchParams(window.location.search.replace('?', '')),
    );
    if (queryParams.showModal) {
      setShowModal(queryParams.showModal);
    } else {
      const userPreferences = await checkUserPreferences({ userId });

      if (userPreferences?.show_wallet_modal !== undefined) {
        setShowModal(userPreferences.show_wallet_modal);
      } else setShowModal(true);
    }
  });

  useEffect(() => {
    const cookies = login('', true);
    setIsLoggedIn(!!cookies.accessToken);
    if (isLoggedIn) {
      loadPreferences();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const tokenInfo = getIdTokenInfo();
    const eventId = uniqid();

    LogLaunchOpschainRef(search);

    return () => {
      track('User leaves page', {
        url: pathname,
        date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        auth0Id: tokenInfo?.sub,
        eventId,
      });
    };
  }, [pathname]);
  const {
    notarizationAccess,
    tokensAccess,
    traceabilityAccess,
    mtrProcurement,
    subscriptionLoading,
  } = useSubscriptionContext();
  const {
    activeWallet,
    walletsList,
    changeWallet,
    loading: becOrganizationLoading,
    contextError,
    organizationsError,
    walletError,
  } = useBecOrganizationContext();
  const { cookiePrefix: prefix, cookieDomain: domain } = window.config;
  useEffect(() => {
    if (activeWallet?.network) {
      const { blockchainType, network, networkType } = activeWallet;
      Cookies.set(
        `${prefix}blockchainInfo`,
        btoa(JSON.stringify({ blockchainType, network, networkType })),
        { domain },
      );
    } else if (!activeWallet && walletsList.length) {
      const wallet = walletsList.find(walletTemp => walletTemp.network === 'goerli');
      changeWallet(wallet.connectionId, wallet.availableWallets[0].address);
    }
  }, [activeWallet, walletsList]);

  if (showModal && activeWallet?.networkType === 'public')
    return <OnboardingModal setOpen={setShowModal} open={showModal} />;

  const getTokenPage = () => {
    return subscriptionRequired(ContractList, tokensAccess);
  };

  const loading = subscriptionLoading || userInfoLoading || becOrganizationLoading;

  // will change ey-ui to get the error instead of error.response
  const isOrganizationsError = organizationsError || organizationsError === undefined;
  const isWalletError = walletError || walletError === undefined;
  const hasError = contextError || isOrganizationsError || isWalletError;

  return (
    <PageLayout>
      <MaskedLoader loading={loading} />
      {hasError ? (
        <CustomError
          title={t('errors.500')}
          subtitle={t('errorMsgBootstrap')}
          callToAction={t('common.refreshPage')}
          callToActionBtnProps={{ href: window.location.href }}
        />
      ) : (
        isLoggedIn &&
        activeWallet && (
          <AppProviders>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              {/* <Route exact path="/rfq" component={RFQ} /> */}
              <Route exact path="/contracts/:id" component={ContractDetails} />
              <Route
                exact
                path="/demand"
                component={subscriptionRequired(DemandForecast, mtrProcurement)}
              />
              <Route
                exact
                path="/schedule"
                component={subscriptionRequired(DemandForecast, mtrProcurement)}
              />
              <Route exact path="/businessEcosystem" component={Partners} />
              <Route exact path="/organization" component={Organization} />
              <Route exact path="/purchase-orders/:id" component={PurchaseOrderDetails} />
              <Route exact path="/purchase-orders" component={PurchaseOrders} />
              <Route exact path="/purchase-order/create" component={CreatePurchaseOrder} />
              <Route exact path="/purchaseOrders" component={MtrPurchaseOrders} />
              <Route exact path="/openOrderSummary" component={MtrOpenOrderSummary} />
              <Route exact path="/purchaseOrdersDetails" component={MtrPurchaseOrderDetails} />
              {/* <Route exact path="/rfq/:id" component={RFQDetails} /> -- https://eyblockchain.atlassian.net/browse/O40-2129 */}
              <Route exact path="/contracts" component={Contracts} />
              <Route exact path="/contract/create" component={CreateContract} />
              <Route
                exact
                path="/notarizations"
                component={subscriptionRequired(Notarizations, notarizationAccess)}
              />
              <Route exact path="/invoices" component={Invoices} />
              <Route exact path="/invoice/create" component={CreateInvoice} />
              <Route exact path="/invoices/:id" component={InvoiceDetails} />
              <Route exact path="/tokens" component={getTokenPage()} />
              <Route exact path="/tokens/bulkMinting" component={BulkMinting} />
              <Route
                exact
                path="/tokens/details/:id"
                component={subscriptionRequired(ListingERC721, tokensAccess)}
              />
              <Route
                exact
                path="/tokens/:restriction/:contractAddress/:mutability/metadata/:tokenId/:fileName"
                component={MetadataContentPage}
              />
              <Route
                exact
                path="/tokens/:restriction/:contractAddress/:mutability/metadata/:tokenId"
                component={MetadataContentPage}
              />
              {traceabilityRoutes(traceabilityAccess)}
            </Switch>
          </AppProviders>
        )
      )}
    </PageLayout>
  );
}

export default App;
