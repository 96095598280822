import gql from 'graphql-tag';

export const RFQ_ATTRIBUTES = gql`
  fragment RFQ_ATTRIBUTES on RFQ {
    organizationId
    rfqId
    quotationDeadline
    material
    isSender
    materialDescription
    sender {
      organizationId
      name
      _id
    }
    recipients {
      organizationId
      name
      _id
    }
    status
    doesContractExist
    updatedAt
  }
`;

export const RFQ_LISTING = gql`
  query getRfqListing {
    rfqs {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const ADD_RFQ = gql`
  mutation createRFQ($input: inputRFQ!) {
    createRFQ(input: $input) {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const DELETE_RFQ = gql`
  mutation deleteRFQ($rfqId: String!) {
    deleteRFQ(rfqId: $rfqId) {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const GET_RFQ = gql`
  query getRFQ($rfqId: String!) {
    rfq(rfqId: $rfqId) {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const GET_RFQ_UPDATE = gql`
  subscription onNewRFQ {
    newRFQ {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const UPDATE_RFQ_AFTER_DELETE = gql`
  subscription deletedRFQ {
    deletedRFQ {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;

export const UPDATE_RFQ_STATUS_ON_DELETE_SUPPLIER = gql`
  subscription updateRFQSupplier {
    updateRFQSupplier {
      ...RFQ_ATTRIBUTES
    }
  }
  ${RFQ_ATTRIBUTES}
`;
