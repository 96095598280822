import gql from 'graphql-tag';

export const CERTIFICATE_ATTRIBUTES = gql`
  fragment CERTIFICATE_ATTRIBUTES on Certificate {
    _id
    recipient
    date
    content
    issuer
    hash
    txHash
    createdAt
    certificateName
    certificateId
    blockchainNetwork
    notarizationContract {
      _id
      contractAddress
      contractName
    }
    transaction {
      status
      transactionHash
    }
    description
    documents {
      fileName
      hash
      documentId
      document {
        _id
      }
    }
  }
`;

export const GET_CERTIFICATES = gql`
  query certificates {
    certificates {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const NOTARIZE_CERTIFICATE = gql`
  mutation notarizeCertificate($input: inputCertificate!) {
    notarizeCertificate(input: $input) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const CERTIFICATE_NOTARIZED = gql`
  subscription certificateNotarized {
    certificateNotarized {
      transaction {
        transactionHash
        contractAddress
        status
      }
      date
    }
  }
`;

export const NOTARIZE_CERTIFICATE_BCOS = gql`
  mutation notarizeCertificateBcos($input: inputCertificate!) {
    notarizeCertificateBcos(input: $input) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const NOTARIZE_CERTIFICATE_BSN = gql`
  mutation notarizeCertificateBsn($input: inputCertificate!) {
    notarizeCertificateBsn(input: $input) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const GET_CERTS_FOR_CONTRACT = gql`
  query certificatesForContract($contractId: String!) {
    certificatesForContract(contractId: $contractId) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const HIDE_CERTIFICATE = gql`
  mutation hideCertificate($certificateId: String!, $contractId: String!) {
    hideCertificate(certificateId: $certificateId, contractId: $contractId) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;

export const UPDATE_CERT_DESC = gql`
  mutation updateCertificateDescription($input: inputUpdateCertificateDescription!) {
    updateCertificateDescription(input: $input) {
      ...CERTIFICATE_ATTRIBUTES
    }
  }
  ${CERTIFICATE_ATTRIBUTES}
`;
