import CustomError from '@eyblockchain/ey-ui/core/BecErrorPages/CustomError';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';

const InstanceError = () => {
  const { t } = useTranslation();
  const { instanceNotFound, instanceId } = useConfigurationWizardContext();

  if (instanceNotFound) {
    return (
      <div>
        <CustomError
          errorCode="404"
          title={t('errors.404')}
          subtitle={t('traceability.instanceNotFound')}
          callToAction={t('common.refreshPage')}
          callToActionBtnProps={{ href: `/traceability/overview` }}
        />
      </div>
    );
  }

  return (
    <div>
      <CustomError
        errorCode="500"
        title={t('errors.500')}
        subtitle={t('traceability.errorLoadingInstance')}
        callToAction={t('common.refreshPage')}
        callToActionBtnProps={{ href: `/traceability/instance-review/${instanceId}` }}
      />
    </div>
  );
};

export default InstanceError;
