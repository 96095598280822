import gql from 'graphql-tag';

export const TOKEN_METADATA_TYPE_ATTRIBUTES = gql`
  fragment TOKEN_METADATA_TYPE_ATTRIBUTES on TokenMetadataType {
    metadataTypeName
    metadataTypeOptions
    _id
  }
`;

export const GET_TOKEN_METADATA_TYPES = gql`
  query tokenMetadataTypes {
    tokenMetadataTypes {
      ...TOKEN_METADATA_TYPE_ATTRIBUTES
    }
  }
  ${TOKEN_METADATA_TYPE_ATTRIBUTES}
`;
