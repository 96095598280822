import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import clsx from 'clsx';
import SignButton from '../../components/Shared/SignButton';
import PurchaseOrderDetailsTable from '../../components/Procurement/PurchaseOrderDetailsTable';
import {
  formatCurrencySymbol,
  formatRates,
  getPrettifiedEthAddr,
  getBlockchainExplorerUrl,
  getCurrencyName,
  getBlockchainExplorerAddressUrl,
} from '../../utils';
import { useTransaction } from '../../contexts/Procurement/transaction';
import { usePurchaseOrderContext } from '../../contexts/Procurement/purchaseOrder';
import AssociateToken from '../../components/Procurement/AssociateToken';
import BlockchainInfo from '../../components/Shared/BlockchainInfo';
import { CONSTANTS, PERMISSIONS } from '../../constants';
import { useContractContext } from '../../contexts/Procurement/contract';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import useUserInfo from '../../hooks/useUserInfo';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';

const useRowStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    color: '#747480',
  },
  status: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '.5rem',
  },
  prices: {
    fontSize: '.875rem',
    color: '#2e2e38',
    marginLeft: '37%',
  },
  SubTotlaprices: {
    fontSize: 13,
    color: '#2e2e38',
    marginLeft: '40%',
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
    marginTop: '50px',
  },
  txnHash: {
    color: theme.palette.primary.main,
    marginTop: '25px',
    textAlign: 'left',
  },
  blackFont: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  section: {
    paddingTop: theme.spacing(3),
  },
  itemsSection: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  sectionRight: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  titleText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  card: {
    padding: 0,
    width: '289px',
    textAlign: 'right',
  },
  cardTitle: {
    background: '#E7E7EA',
  },
  cardTitleText: {
    fontWeight: 300,
  },
  table: {
    boxShadow: '0px 0px 0px 0px',
    marginTop: theme.spacing(3),
  },
  tierComponent: {
    marginTop: 0,
  },
  totalBreakdownContainer: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    width: '331px',
  },
  totalBreakdown: {
    width: '100px',
  },
  lineBreak: {
    width: '330px',
    float: 'right',
    display: 'flex',
  },
  totalContainer: {
    marginLeft: 'auto',
  },
  expandIcon: {
    float: 'right',
    marginTop: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  underlineText: {
    color: theme.palette.info.main,
    fontSize: '.875rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    lineHeight: '1rem',
  },
  chip: {
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
    fontWeight: 600,
  },
  failedChip: {
    background: theme.colors.red,
    color: theme.colors.white,
  },
  openChip: {
    background: '#A9A9A9',
    color: theme.colors.white,
  },
  pendingChip: {
    background: '#FF9831',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  acceptedChip: {
    background: 'rgb(22 134 54)',
    color: theme.colors.white,
  },
  deliveredChip: {
    background: 'rgb(22 134 54)',
    color: theme.colors.white,
  },
  loaderContainer: theme.loaderContainer,
  divider: {
    background: '#e0e0e0',
    marginBottom: '.5rem',
    marginTop: '.5rem',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  statusContainer: {
    display: 'flex',
  },
  preTitle: {
    fontWeight: '200',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '.875rem',
  },
  preTitleId: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  purchaseOrderName: {
    fontWeight: 400,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  preTitleIdWithSpace: {
    fontWeight: 'bold',
    marginLeft: 4,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  preTitleRightSide: {
    marginLeft: 730,
  },
  smallHeaders: {
    fontSize: '.875rem',
    fontWeight: 300,
  },
  purchaseOrderDetails: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.5fr 1fr',
    gridGap: '20px',
  },
  buyersAddressBox: {
    marginTop: -20,
  },
  poBuyerDetailsSubHeaders: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  poCostDetails: {
    display: 'grid',
    gridTemplateRows: '0.5fr 1fr',
  },
  poNetValue: {
    background: '#E7E7EA',
    height: 50,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: 30,
  },
  poNetValueHeader: {
    marginLeft: '17.9rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: 15,
  },
  poValue: {
    background: '#FFFFFF',
    fontWeight: 500,
    height: 80,
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginBottom: 20,
  },
  poValueAmtText: {
    fontWeight: 300,
    fontSize: '28px',
    paddingLeft: '100px',
    textAlign: 'end',
    marginRight: 15,
  },
  poMeterialBox: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr',
  },
  tableIcon: {
    fontSize: 40,
    marginTop: 14,
    border: '1px solid',
  },
  tierTablespace: {
    paddingBottom: '100 !important',
  },
  subtotalHeader: {
    fontSize: 13,
    fontWeight: 300,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    textAlign: 'right',
    paddingLeft: 60,
    paddingBottom: 20,
    borderBottom: '1px solid',
    borderBottomColor: '#e0e0e0',
  },
  netValueHeader: {
    fontSize: '.875rem',
    fontWeight: 500,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    textAlign: 'right',
    paddingLeft: 40,
  },
  shortText: {
    fontSize: '.875rem',
    fontWeight: 300,
    display: 'flex',
    color: '#2e2e38',
    paddingTop: theme.spacing(3),
    width: '331px',
    paddingLeft: 40,
    textAlign: 'inherit',
  },
  associatedItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    paddingBottom: 40,
  },
  associatedItemsDesc: {
    display: 'grid',
    gridTemplateRows: '50px',
    gridAutoRows: '1fr',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: 'none',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
    padding: theme.spacing(3),
  },
  modalTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  message: {
    paddingTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  sectionData: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  signCard: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  cardText: {
    color: theme.colors.blue,
    fontSize: theme.spacing(2.2),
    letterSpacing: 0,
    lineHeight: 'normal',
  },
  signButton: {
    margin: theme.spacing(1),
  },
  icon: {
    height: '30px',
    width: '30px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  processing: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  circularLoader: {
    marginRight: theme.spacing(3),
  },
  addressesLinks: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      color: theme.colors.blue,
      letterSpacing: 0,
      lineHeight: `${theme.spacing(3)}px`,
    },
    cursor: 'pointer',
  },
}));

const PurchaseOrderModal = ({
  open,
  closeModal,
  purchaseOrder,
  handleSignPO,
  processing,
  processed,
}) => {
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const classes = useRowStyles();

  const formik = useFormik({
    initialValues: {
      purchaseOrderId: purchaseOrder.purchaseOrderId,
    },
    validationSchema: Yup.object().shape({
      purchaseOrderId: Yup.string().required(),
    }),
  });

  return (
    <>
      {!processing ? (
        <Modal className={classes.modal} open={open} onClose={closeModal}>
          <Paper className={classes.paper}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('contracts.transactionReminder')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  {/* Uncomment when implementing dynamic messages with variables */}
                  {/* <Typography variant="body1" display="inline" className={classes.message}>
                    <Trans i18nKey="contracts.transactionCost">
                      <b> eth fee</b>
                      <b> usd </b>
                    </Trans>
                  </Typography> */}
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.wantToProceed')}
                  </Typography>
                  {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                    <BlockchainInfo
                      validateForm={formik.validateForm}
                      values={formik.values}
                      contractAddress=""
                      contractName={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                      method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                      methodArgs={['2', purchaseOrder.contract.contractId]}
                    />
                  ) : (
                    ''
                  )}
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.gasExplanation')}
                  </Typography>
                  {/* Uncomment when implementing dynamic messages with variables */}
                  {/* <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.transactionPrivacy')}
                  </Typography> */}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.sectionData}>
                    <Button
                      className={classes.signButton}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.cancel')}
                    </Button>
                    <SignButton
                      onSign={() => handleSignPO(formik.values.purchaseOrderId)}
                      className={classes.signButton}
                      label={t('common.proceed')}
                      testId="signPOAndValidatepricing"
                    />
                  </div>
                </Grid>
              </Form>
            </FormikProvider>
          </Paper>
        </Modal>
      ) : (
        <Modal className={classes.modal} open={open && !processed} onClose={closeModal}>
          <Paper className={classes.paper}>
            <Grid container direction="row" justify="space-between">
              <Typography variant="h4" className={classes.modalTitle}>
                {t('pos.comparePricingWithSmartContact')}
              </Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container direction="column">
              <>
                <Grid container direction="row" alignItems="center" className={classes.processing}>
                  <CircularProgress
                    color="primary"
                    size={30}
                    thickness={2}
                    className={classes.circularLoader}
                  />
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('pos.comparingPricingWithSmartContact')}
                  </Typography>
                </Grid>
                <Card className={classes.signCard}>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.iconContainer}>
                      <InfoIcon className={classes.icon} />
                    </div>
                    <Typography variant="h4" className={classes.cardText}>
                      {t('contracts.processingSigning')}
                    </Typography>
                  </CardContent>
                </Card>
              </>
              <Divider classes={{ root: classes.navButtonDivider }} />
              <div className={classes.section}>
                <Button
                  className={classes.submit}
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  {t('common.close')}
                </Button>
              </div>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
};

PurchaseOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  purchaseOrder: PropTypes.shape({
    purchaseOrderId: PropTypes.string,
    address: PropTypes.string,
    contract: PropTypes.shape({
      contractId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  handleSignPO: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  processed: PropTypes.bool.isRequired,
};

const PurchaseOrderDetails = () => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const { signData } = useTransaction();
  let getPurchaseOrderId;
  const { id: purchaseOrderId } = useParams();
  const [assoicatedId, setAssoicatedId] = useState(null);
  const [associateGoodsView, setAssociateGoodsView] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processed, setProcessed] = useState(false);
  const { activeWallet, activeOrganization } = useBecOrganizationContext();
  const baseUrl = getBlockchainExplorerUrl(activeWallet.network);
  const baseAddressUrl = getBlockchainExplorerAddressUrl(activeWallet.network);
  const { euroCurrencySymbol } = useSubscriptionContext();
  const materialNames = [];
  let getPODetailsValue = [];
  let poDetailsValue = [];
  const { userPermissions } = useUserInfo();
  const {
    purchaseOrderData,
    getPurchaseOrderDetail,
    signPurchaseOrder,
    signDelivery,
    purchaseOrderLoading,
    poSignedSubscription,
    onpDeploymentFailureTrigger,
  } = usePurchaseOrderContext();
  const { showFaucetModal, setShowFaucetModal } = useContractContext();

  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.GETPURCHASEORDERS)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  const isNetworkLeader = poData => {
    if (poData.supplier.id === activeOrganization._id) {
      return true;
    }
    return false;
  };

  const isAuthorisedUser = poData => {
    const isAuthorised = poData.contract.myOrganizations.find(
      x => x.organizationId && x.organizationId === activeOrganization._id,
    );
    if (isAuthorised) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getPurchaseOrderDetail({ variables: { purchaseOrderId } });
    setRefreshFlag(false);
  }, [getPurchaseOrderDetail, purchaseOrderId, refreshFlag]);

  useEffect(() => {
    setProcessing(false);
    setOpen(false);
  }, [onpDeploymentFailureTrigger]);

  useEffect(() => {
    const poStatus = poSignedSubscription.data?.poSigned;
    if (
      poStatus?.status === 'accepted' &&
      poStatus?.purchaseOrderId === purchaseOrderData?.purchaseOrderId
    ) {
      setProcessed(true);
    }
  }, [poSignedSubscription]);

  useEffect(() => {
    if (purchaseOrderData?.status === 'deploying') setProcessing(true);
    if (purchaseOrderData?.status === 'action-required' || purchaseOrderData?.status === 'failed')
      setProcessed(false);
  }, [purchaseOrderData]);

  useEffect(() => {
    setRefreshFlag(false);
  }, [getPurchaseOrderDetail]);

  if (purchaseOrderData) {
    getPurchaseOrderId = purchaseOrderData._id;
    poDetailsValue = purchaseOrderData.materials.map((material, index) => {
      const materialCopy = { ...material };
      materialCopy.isSender = purchaseOrderData.isSender;
      let isTiered = false;
      const materialIdentified = purchaseOrderData.contract.materials.find(
        contractMaterial => contractMaterial.materialName === material.materialName,
      );
      materialNames.push(material.materialName);
      materialCopy.shipment = '-';
      let quantitySet =
        Number(material.volume) + Number(material.oldAccumulatedVolumeInContract || 0);
      materialCopy.deliveryDate = moment.unix(material.deliveryDate / 1000).format('YYYY-MM-DD');
      materialCopy.netPrice = formatCurrencySymbol(
        material.totalPrice / material.volume,
        euroCurrencySymbol,
      );
      materialCopy.netValue = formatCurrencySymbol(material.totalPrice, euroCurrencySymbol);
      materialCopy.associatedTokens = [material.tokenIds, index];
      materialCopy.description = materialIdentified.materialDescription;
      materialCopy.tierValue = [];
      materialCopy.tireProgressBar = [];
      materialCopy.index = index;
      materialCopy.isRebateContract = purchaseOrderData.contract.contractType === 'rebatePricing';
      const rates =
        purchaseOrderData.contract.contractType !== 'rebatePricing'
          ? formatRates([materialIdentified])
          : [];
      if (purchaseOrderData.contract.contractType !== 'rebatePricing') {
        materialCopy.tierValue = rates?.map((rate, rateIndex) => {
          let quantityInTier = 0;
          let tempTierValue = {};
          if (quantitySet && !isTiered) {
            if (rate.endRange <= quantitySet) {
              quantityInTier =
                quantitySet > rate.endRange && rateIndex === rates.length - 1
                  ? `${rate.endRange}+`
                  : rate.endRange;
              quantitySet -= rate.endRange;
            } else {
              quantityInTier = quantitySet;
              isTiered = true;
            }
          }
          tempTierValue = {
            quantity:
              rates.length - 1 === rateIndex
                ? `${rate.startRange} - ${rate.endRange}+`
                : `${rate.startRange} - ${rate.endRange}`,
            netPrice: formatCurrencySymbol(`${rate.price}`, euroCurrencySymbol),
            qualityInTier: quantityInTier,
          };
          return tempTierValue;
        });
        materialCopy.tireProgressBar.push(materialIdentified.tierRules?.tierBounds);
        materialCopy.tireProgressBar.push(material.volume);
        materialCopy.tireProgressBar.push(materialIdentified.tierRules?.pricesByTier);
      } else {
        const rules = materialIdentified.rebateRules;
        if (rules.rebateValue) {
          rules.rebateValue.forEach((value, i) => {
            materialCopy.tierValue.push({
              rebateThreshold: rules.thresholdQty[i],
              rebatePercentage: value,
            });
          });
        }
      }
      return materialCopy;
    });
  }
  getPODetailsValue = [...poDetailsValue];
  const history = useHistory();
  const statusBar = () => {
    if (purchaseOrderData.status === 'open') {
      return (
        <span className={clsx(classes.openChip, classes.chip)}>
          {capitalize(purchaseOrderData.status)}
        </span>
      );
    }
    if (purchaseOrderData.status === 'action-required') {
      return (
        <span className={clsx(classes.pendingChip, classes.chip)}>
          {capitalize(purchaseOrderData.status)}
        </span>
      );
    }
    if (purchaseOrderData.status === 'deploying') {
      return (
        <span className={clsx(classes.pendingChip, classes.chip)}>{t('pos.comparingPricing')}</span>
      );
    }
    if (purchaseOrderData.status === 'accepted') {
      return (
        <span className={clsx(classes.acceptedChip, classes.chip)}>
          {capitalize(purchaseOrderData.status)}
        </span>
      );
    }
    if (purchaseOrderData.status === 'failed') {
      return (
        <span className={clsx(classes.failedChip, classes.chip)}>
          {capitalize(purchaseOrderData.status)}
        </span>
      );
    }
    return (
      <span className={clsx(classes.pendingChip, classes.chip)}>
        {capitalize(purchaseOrderData.status)}
      </span>
    );
  };

  const handleDeliverySignature = async () => {
    const actualDeliveryDate = moment().format();
    const preimageInput = {
      purchaseOrderId: purchaseOrderData.purchaseOrderId,
      // materials: materialInput,
      actualDeliveryDate,
    };
    const preimage = JSON.stringify(preimageInput);
    const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
    const { blockchainType } = JSON.parse(blockchainCookie);
    const buyerSignature = await signData(preimage, blockchainType);
    await signDelivery({
      variables: {
        purchaseOrderId: purchaseOrderData.purchaseOrderId,
        supplierOrganizationId: purchaseOrderData.supplier.id,
        buyerSignature,
        preimage,
        actualDeliveryDate,
      },
    });
  };

  const nagvigatePurchaseOrderPage = () => {
    if (assoicatedId !== null) {
      setAssoicatedId(null);
      history.push(`/purchase-orders/${getPurchaseOrderId}`);
    } else {
      history.replace('/purchase-orders');
    }
  };

  const purchaseBackOrderButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          nagvigatePurchaseOrderPage();
        }}
        className={classes.navButton}
        startIcon={<ArrowBackIcon />}
      >
        {t('common.purchaseOrder')}
      </Button>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (associateGoodsView) {
    return (
      <AssociateToken
        purchaseOrder={purchaseOrderData}
        setAssociateTokensView={setAssociateGoodsView}
        materialId={assoicatedId}
        setRefreshFlag={setRefreshFlag}
      />
    );
  }

  const getSupplierParentOrgId = poData => {
    if (poData.contract.contractType === 'tierPricing' || isNetworkLeader(poData)) {
      return null;
    }
    return poData.supplier.id;
  };

  const handleSignPO = async () => {
    setProcessing(true);
    const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
    const { blockchainType } = JSON.parse(blockchainCookie);
    const supplierSignature = await signData(purchaseOrderData.preimage, blockchainType);
    await signPurchaseOrder({
      variables: {
        supplierSignature,
        purchaseOrderId: purchaseOrderData.purchaseOrderId,
        buyerOrganizationId: purchaseOrderData.buyer.organization._id,
        parentOrganizationId: purchaseOrderData.parentOrganizationId,
        supplierParentOrgId: getSupplierParentOrgId(purchaseOrderData),
      },
    });
  };

  return isUserNotAuthorized() ? (
    isUserNotAuthorized()
  ) : (
    <>
      {!purchaseOrderData ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {purchaseOrderLoading && !processing ? (
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          ) : (
            ''
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            px={2}
            className={classes.navContainer}
          >
            {purchaseBackOrderButton()}
          </Box>
          <Divider classes={{ root: classes.navButtonDivider }} />
          {/* <Card className={classes.root}> */}
          <Grid className={classes.root}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.status}
            >
              <div>
                <Typography className={classes.preTitle} variant="h5">
                  {`${t('pos.poId')} `}
                  <span
                    className={classes.preTitleId}
                  >{`PO-${purchaseOrderData.purchaseOrderId.padStart(10, 0)}`}</span>
                </Typography>
                <Typography className={classes.sectionTitle} variant="h5">
                  {materialNames.join(', ')}
                </Typography>
              </div>
              <div className={classes.statusContainer}>
                <Typography style={{ marginRight: '4px' }}>{t('common.globalStatus')} </Typography>
                {statusBar()}
              </div>
            </Grid>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <Grid container direction="row" className={classes.section}>
              <Grid item xs={4}>
                {!purchaseOrderData?.isSender ? (
                  <>
                    <Typography>{t('pos.buyer')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders} variant="h5">
                      {purchaseOrderData.buyer.organization.name}
                    </Typography>
                    <Typography>
                      {purchaseOrderData.buyer.organization.city
                        ? `${purchaseOrderData.buyer.organization.city},`
                        : ''}
                      {purchaseOrderData.buyer.organization.street_line
                        ? `${purchaseOrderData.buyer.organization.street_line},`
                        : ''}
                      {purchaseOrderData.buyer.organization.postal_code
                        ? `${purchaseOrderData.buyer.organization.postal_code},`
                        : ''}
                    </Typography>
                    <Typography>{purchaseOrderData.buyer.organization.phone}</Typography>
                  </>
                ) : (
                  <>
                    <Typography>{t('common.supplier')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders} variant="h5">
                      {purchaseOrderData.supplier.organization.name}
                    </Typography>
                    <Typography>{purchaseOrderData.supplier.organization.street_line}</Typography>
                    <Typography>
                      {purchaseOrderData.buyer.organization.city
                        ? `${purchaseOrderData.buyer.organization.city},`
                        : ''}
                      {purchaseOrderData.buyer.organization.street_line
                        ? `${purchaseOrderData.buyer.organization.street_line},`
                        : ''}
                      {purchaseOrderData.buyer.organization.postal_code
                        ? `${purchaseOrderData.buyer.organization.postal_code},`
                        : ''}
                    </Typography>
                    <Typography>{purchaseOrderData.supplier.organization.phone}</Typography>
                  </>
                )}
              </Grid>

              <Grid item xs={4} className={classes.sectionRight}>
                <div
                  style={{
                    marginRight: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className={classes.blackFont}>
                    <Typography>{t('pos.contractId')}</Typography>
                    <div
                      className={classes.addressesLinks}
                      onClick={() => {
                        history.push(`/contracts/${purchaseOrderData?.contract._id}`);
                      }}
                      aria-hidden="true"
                    >
                      <Typography variant="body2">
                        {`CT-${purchaseOrderData.contract.contractId.padStart(10, 0)}`}
                      </Typography>
                    </div>
                  </div>
                  {purchaseOrderData.contract?.smartContract?.contractAddress !== undefined && (
                    <div className={classes.txnHash}>
                      <Typography>{t('pos.contractAddress')}</Typography>
                      <div
                        className={classes.addressesLinks}
                        onClick={() => {
                          window.open(
                            `${baseAddressUrl}/${purchaseOrderData.contract?.smartContract?.contractAddress}`,
                          );
                        }}
                        aria-hidden="true"
                      >
                        <Typography variant="body2">
                          {getPrettifiedEthAddr(
                            purchaseOrderData.contract?.smartContract?.contractAddress,
                          )}
                        </Typography>
                        <CopyContent
                          content={purchaseOrderData.contract?.smartContract?.contractAddress}
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.blackFont} />
                </div>
              </Grid>

              <Grid item xs={4} className={classes.sectionRight}>
                <div
                  style={{
                    marginRight: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className={classes.blackFont}>
                    <Typography>{t('pos.poId')}</Typography>
                    <Typography className={classes.poBuyerDetailsSubHeaders}>
                      {`PO-${purchaseOrderData.purchaseOrderId.padStart(10, 0)}`}
                    </Typography>
                  </div>
                  {(purchaseOrderData.status === 'accepted' ||
                    purchaseOrderData.status === 'delivered') &&
                  purchaseOrderData?.transactionHash ? (
                    <div className={classes.txnHash}>
                      <Typography>{t('pos.txHashLabel')}</Typography>
                      <div
                        className={classes.addressesLinks}
                        onClick={() => {
                          window.open(`${baseUrl}/${purchaseOrderData?.transactionHash}`);
                        }}
                        aria-hidden="true"
                      >
                        <Typography variant="body2">
                          {getPrettifiedEthAddr(purchaseOrderData?.transactionHash)}
                        </Typography>
                        <CopyContent content={purchaseOrderData?.transactionHash} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className={classes.blackFont} />
                </div>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardTitle}
                    titleTypographyProps={{ className: classes.cardTitleText }}
                    title={t('pos.netValue')}
                  />
                  <CardContent>
                    <Typography variant="h4">
                      {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                        purchaseOrderData.totalPrice,
                        euroCurrencySymbol,
                      )}`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <div>
              <PurchaseOrderDetailsTable
                key={uniqid()}
                materials={getPODetailsValue}
                contract={purchaseOrderData?.contract}
                getAssociatedId={getMetrialId => {
                  setAssoicatedId(getMetrialId);
                }}
                setAssociateGoodsView={setAssociateGoodsView}
              />
            </div>
            <div>
              <Grid container direction="column" alignItems="flex-end">
                <Grid item className={classes.subtotalHeader}>
                  {t('pos.subtotal')}
                  <Typography className={classes.SubTotlaprices} variant="h6">
                    {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                      purchaseOrderData.totalPrice,
                      euroCurrencySymbol,
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" alignItems="flex-end">
                <Grid item className={classes.netValueHeader}>
                  {t('pos.netValue')}
                  <Typography className={classes.prices} variant="h6">
                    {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                      purchaseOrderData.totalPrice,
                      euroCurrencySymbol,
                    )}`}
                  </Typography>
                </Grid>
                {!purchaseOrderData.isSender &&
                  (userPermissions?.contract_manager?.includes(
                    PERMISSIONS.CONTRACT_MANAGER.ASSOCIATEERC721TOKENPURCHASEORDER,
                  ) ||
                    userPermissions?.contract_manager?.includes(
                      PERMISSIONS.CONTRACT_MANAGER.DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER,
                    )) && (
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid>
                        <Typography className={classes.shortText} variant="h6">
                          {t('pos.poIntro')}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
              {userPermissions?.contract_manager?.includes(
                PERMISSIONS.CONTRACT_MANAGER.SIGNPURCHASEORDER,
              ) && (
                <>
                  {purchaseOrderData?.contract.contractType === 'tierPricing' && (
                    <Grid container direction="row" alignItems="flex-end">
                      {purchaseOrderData.isSender === false &&
                      (purchaseOrderData?.status === 'action-required' ||
                        purchaseOrderData?.status === 'deploying' ||
                        purchaseOrderData?.status === 'failed') ? (
                        <SignButton
                          className={classes.button}
                          onSign={() => {
                            if (activeWallet.balance === 0) {
                              setShowFaucetModal(true);
                            } else {
                              setShowFaucetModal(false);
                              setOpen(true);
                            }
                          }}
                          label={t('pos.acceptPurchaseOrder')}
                          testId="acceptPurchaseOrder"
                        />
                      ) : (
                        purchaseOrderData.status !== 'delivered' &&
                        purchaseOrderData.status !== 'open' &&
                        activeOrganization._id === purchaseOrderData?.contract?.buyer?.id && (
                          <SignButton
                            className={classes.button}
                            onSign={handleDeliverySignature}
                            label={t('pos.confirmReceipt')}
                            testId="confirmReceipt"
                          />
                        )
                      )}
                    </Grid>
                  )}

                  {purchaseOrderData?.contract.contractType === 'rebatePricing' && (
                    <Grid container direction="row" alignItems="flex-end">
                      {purchaseOrderData.isSender === false
                        ? (((isNetworkLeader(purchaseOrderData) ||
                            isAuthorisedUser(purchaseOrderData)) &&
                            purchaseOrderData?.status === 'action-required') ||
                            purchaseOrderData?.status === 'deploying' ||
                            purchaseOrderData?.status === 'failed') && (
                            <SignButton
                              className={classes.button}
                              onSign={() => {
                                if (activeWallet.balance === 0) {
                                  setShowFaucetModal(true);
                                } else {
                                  setShowFaucetModal(false);
                                  setOpen(true);
                                }
                              }}
                              label={t('pos.acceptPurchaseOrder')}
                              testId="acceptPurchaseOrder"
                            />
                          )
                        : purchaseOrderData.status !== 'delivered' &&
                          purchaseOrderData.status !== 'open' &&
                          activeOrganization._id === purchaseOrderData.organizationId && (
                            <SignButton
                              className={classes.button}
                              onSign={handleDeliverySignature}
                              label={t('pos.confirmReceipt')}
                              testId="confirmReceipt"
                            />
                          )}
                    </Grid>
                  )}
                </>
              )}
            </div>
          </Grid>
          {!showFaucetModal && (
            <PurchaseOrderModal
              open={open}
              closeModal={handleClose}
              purchaseOrder={purchaseOrderData}
              handleSignPO={handleSignPO}
              processing={processing}
              processed={processed}
            />
          )}
        </>
      )}
    </>
  );
};

export default PurchaseOrderDetails;
