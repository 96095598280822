import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik, Form, Field, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import FormErrors from '../Shared/FormErrors';
import { useTransaction } from '../../contexts/Procurement/transaction';
import { useAuth } from '../../contexts/Shared/auth';
import DatePickerField from '../Shared/DatePickerField';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';
import { usePurchaseOrderContext } from '../../contexts/Procurement/purchaseOrder';
import { useInvoiceContext } from '../../contexts/Procurement/invoice';
import InvoicePricingTable from './InvoicePricingTable';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import { formatRates, formatCurrencySymbol, getCurrencyName } from '../../utils';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  fields: {
    paddingTop: theme.spacing(2),
    flexDirection: 'column',
  },
  tierComponent: {
    marginTop: 0,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    display: 'block',
  },
  warning: {
    paddingTop: theme.spacing(2),
    color: '#B9251C',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  initialFields: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '& > div:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  subtitle: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  dateSchedule: {
    width: '100%',
  },
  signingBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 30,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(3)}px`,
    boxShadow: `-8px 8px 8px 0px rgba(0,0,0,.25)`,
  },
  materialHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    paddingLeft: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  tableData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  materialName: {
    padding: 0,
    fontWeight: 'bold',
  },
  totalPriceBox: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingRight: 17,
  },
  totalPrice: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  materialsBlock: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  materialsTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  netValue: {
    fontWeight: 300,
    color: '#747480',
    textTransform: 'uppercase',
  },
  loaderContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
  },
  dueDateInfo: {
    fontSize: 12,
    fontWeight: 300,
    color: '#747480',
  },
}));

const CreateInvoice = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { company } = useAuth();
  const history = useHistory();
  const { signData } = useTransaction();
  const [isGetPODetails, setIsGetPODetails] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(false);
  const [selectedPO, setSelectedPO] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({});
  const { getPartners, partners, getPartnersLoading } = usePartnerContext();
  const { postInvoice, invoiceLoading } = useInvoiceContext();
  let poDetailsValue = [];
  const selectedPartnerId = '';
  const { euroCurrencySymbol } = useSubscriptionContext();

  const formik = useFormik({
    initialValues: {
      buyerId: '',
      purchaseOrder: '',
      invoiceDate: moment().unix(),
      dueDate: moment(
        moment(moment(moment(moment().unix() * 1000).format()).add(120, 'days')).format(),
      ).unix(),
    },
    onSubmit: async (values, { resetForm }) => {
      if (selectedPurchaseOrder && Object.keys(selectedPurchaseOrder).length > 0) {
        const preimage = JSON.stringify({
          purchaseOrderId: values.purchaseOrder.value,
          invoiceDate: moment(moment.unix(values.invoiceDate).utc()).format(),
          dueDate: moment(moment.unix(values.dueDate).utc()).format(),
          totalPrice: Number(selectedPurchaseOrder.totalPrice),
        });

        const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
        const { blockchainType } = JSON.parse(blockchainCookie);

        const supplierSignature = await signData(preimage, blockchainType);

        const res = await postInvoice({
          variables: {
            input: {
              purchaseOrderId: values.purchaseOrder.value,
              invoiceDate: moment(moment.unix(values.invoiceDate).utc()).format(),
              dueDate: moment(moment.unix(values.dueDate).utc()).format(),
              buyerId: values.buyerId.value,
              supplierSignature,
              totalPrice: Number(selectedPurchaseOrder.totalPrice),
            },
          },
        });
        if (res) {
          resetForm();
        }
      }
    },
    validationSchema: Yup.object().shape({
      buyerId: Yup.mixed().test('', t('invoices.validation.businessPartner'), val => {
        return !!(val !== null);
      }),

      purchaseOrder: Yup.mixed().test('', t('invoices.validation.purchaseOrder'), val => {
        return !!(val !== null);
      }),

      invoiceDate: Yup.mixed().test('', t('invoices.validation.invoiceDate'), val => {
        return !!(val !== null);
      }),

      dueDate: Yup.mixed().test('', t('invoices.validation.dueDate'), val => {
        return !!(val !== null);
      }),
    }),
  });

  useEffect(() => {
    getPartners();
  }, [getPartners, company]);

  const partnersData = partners?.map(partner => {
    return {
      value: partner.partnerOrganization._id,
      label: partner.partnerOrganization.name,
    };
  });

  const {
    purchaseOrders,
    purchaseOrderLoading,
    getPurchaseOrdersForInvoice,
  } = usePurchaseOrderContext();
  useEffect(() => {
    if (
      formik.values.buyerId &&
      Object.keys(formik.values.buyerId).length > 0 &&
      formik.values.buyerId.value !== ''
    ) {
      setSelectedPartner(true);
      getPurchaseOrdersForInvoice({
        variables: { buyerOrganizationId: formik.values.buyerId.value },
      });
    } else {
      setSelectedPartner(false);
    }
  }, [formik?.values?.buyerId]);

  const getPOListDropDown = () => {
    const temp =
      formik.values.buyerId &&
      Object.keys(formik.values.buyerId).length > 0 &&
      purchaseOrders.length
        ? purchaseOrders
        : [];
    return temp.length
      ? temp
          .map(obj => {
            return {
              label: `PO-${obj.purchaseOrderId.padStart(10, 0)}`,
              value: obj.purchaseOrderId,
            };
          })
          .filter(item => item !== undefined)
      : [];
  };

  useEffect(() => {
    if (formik?.values?.purchaseOrder?.value && formik?.values?.purchaseOrder?.value !== null) {
      const getPODetails = purchaseOrders.filter(
        items => items.purchaseOrderId === formik?.values?.purchaseOrder?.value,
      );
      if (getPODetails.length > 0) {
        setSelectedPurchaseOrder(getPODetails[0]);
        poDetailsValue = getPODetails[0].materials.map(material => {
          const materialCopy = { ...material };
          materialCopy.isSender = purchaseOrders.isSender;
          let isTiered = false;
          const materialIdentified = getPODetails[0].contract.materials.find(
            contractMaterial => contractMaterial.materialName === material.materialName,
          );
          materialCopy.shipment = '-';
          let quantitySet =
            Number(material.volume) + Number(material.oldAccumulatedVolumeInContract || 0);
          materialCopy.sku = materialIdentified.materialName;
          materialCopy.quantity = material.volume;
          materialCopy.netValue = formatCurrencySymbol(
            `${material.totalPrice}`,
            euroCurrencySymbol,
          );
          materialCopy.description = materialIdentified.materialDescription;
          materialCopy.tierValue = [];
          materialCopy.tireProgressBar = [];
          materialCopy.contractType = getPODetails[0].contract?.contractType;
          const rates =
            getPODetails[0].contract?.contractType !== 'rebatePricing'
              ? formatRates([materialIdentified])
              : [];
          if (getPODetails[0].contract?.contractType !== 'rebatePricing') {
            materialCopy.tierValue = rates?.map((rate, rateIndex) => {
              let quantityInTier = 0;
              let tempTierValue = {};
              if (quantitySet && !isTiered) {
                if (rate.endRange <= quantitySet) {
                  quantityInTier =
                    quantitySet > rate.endRange && rateIndex === rates.length - 1
                      ? `${rate.endRange}+`
                      : rate.endRange;
                  quantitySet -= rate.endRange;
                } else {
                  quantityInTier = quantitySet;
                  isTiered = true;
                }
              }
              tempTierValue = {
                quantity:
                  rates.length - 1 === rateIndex
                    ? `${rate.startRange} - ${rate.endRange}+`
                    : `${rate.startRange} - ${rate.endRange}`,
                qtyInTier: quantityInTier,
                netPrice: formatCurrencySymbol(`${rate.price}`, euroCurrencySymbol),
              };
              return tempTierValue;
            });
          } else {
            const rules = materialIdentified.rebateRules;
            rules.rebateValue.forEach((value, i) => {
              materialCopy.tierValue.push({
                rebateThreshold: rules.thresholdQty[i],
                rebatePercentage: value,
              });
            });
          }
          return materialCopy;
        });
        setSelectedPO(poDetailsValue);
        setIsGetPODetails(true);
      }
    } else {
      setIsGetPODetails(false);
      setSelectedPO([]);
    }
  }, [formik?.values?.purchaseOrder]);

  useEffect(() => {
    if (formik?.values?.invoiceDate)
      formik.values.dueDate = moment(
        moment(
          moment(moment(moment.unix(formik.values.invoiceDate).utc()).format()).add(120, 'days'),
        ).format(),
      ).unix();
  }, [formik?.values?.invoiceDate]);

  const closeModal = () => {
    history.goBack();
  };

  const getPurchaseOrderList = () => {
    if (purchaseOrders?.length > 0 && selectedPartner) {
      return (
        <Field
          component={AutocompleteDropDown}
          label={t('invoices.selectpurchaseorder')}
          value={formik.values.purchaseOrder}
          name="purchaseOrder"
          options={getPOListDropDown()}
          disabled={!formik?.values?.buyerId?.value}
          testid="createinvoice-dropdown-PO"
        />
      );
    }
    if (!purchaseOrders.length && selectedPartner) {
      return <Typography className={classes.noPurchaseOrders}>{t('common.awaiting')}</Typography>;
    }
    return (
      <Field
        component={AutocompleteDropDown}
        label={t('invoices.selectpurchaseorder')}
        value={formik.values.purchaseOrder}
        name="purchaseOrder"
        disabled={!formik?.values?.buyerId?.value}
        testid="createinvoice-dropdown-PO"
      />
    );
  };

  return (
    <>
      {getPartnersLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {purchaseOrderLoading || invoiceLoading ? (
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          ) : (
            ''
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            px={2}
            className={classes.navContainer}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => closeModal()}
              className={classes.navButton}
              startIcon={<ArrowBackIosIcon />}
            >
              {t('headers.invoice_plural')}
            </Button>
          </Box>
          <Divider classes={{ root: classes.navButtonDivider }} />
          <Card className={classes.root}>
            <Typography variant="h4" className={classes.title}>
              {t('invoices.issueInvoice')}
            </Typography>
            <Divider classes={{ root: classes.navButtonDivider }} />
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} className={classes.form}>
                <FormErrors form={formik} />
                <Grid container className={classes.fields}>
                  <Grid container className={classes.initialFields}>
                    <Grid item xs={3}>
                      <Typography className={classes.subtitle} variant="subtitle1">
                        {t('common.businessPartner')}
                      </Typography>
                      <Field
                        component={AutocompleteDropDown}
                        label={t('invoices.selectBusinessPartner')}
                        value={selectedPartnerId}
                        onChange={formik.handleChange}
                        name="buyerId"
                        options={partnersData}
                        testid="createinvoice-dropdown-buyerId"
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.initialFields}>
                    <Grid item xs={3}>
                      <Typography className={classes.subtitle} variant="subtitle1">
                        {t('common.purchaseOrder')}
                      </Typography>
                      {getPurchaseOrderList()}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.fields}>
                    <Grid container className={classes.initialFields}>
                      <Grid item xs={3}>
                        <Field
                          disabled={!formik?.values?.purchaseOrder?.value}
                          component={DatePickerField}
                          label={t('invoices.invoiceDate')}
                          onChange={formik.handleChange}
                          value={parseInt(10, formik.values.invoiceDate)}
                          name="invoiceDate"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          disabled={!formik?.values?.purchaseOrder?.value}
                          component={DatePickerField}
                          label={t('invoices.dueDate')}
                          onChange={formik.handleChange}
                          value={parseInt(10, formik.values.dueDate)}
                          name="dueDate"
                        />
                        <p className={classes.dueDateInfo}>{t('invoices.dueDateDetails')}</p>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.initialFields} />
                  </Grid>
                  {isGetPODetails ? (
                    <>
                      <InvoicePricingTable key={uniqid()} materials={selectedPO} />
                      <Grid
                        item
                        container
                        alignItems="flex-end"
                        direction="column"
                        xs={12}
                        className={classes.totalPriceBox}
                      >
                        <Typography variant="subtitle1" className={classes.netValue}>
                          TOTAL NET VALUE
                        </Typography>
                        <Typography variant="h4" className={classes.totalPrice}>
                          {`${getCurrencyName(euroCurrencySymbol)}${formatCurrencySymbol(
                            selectedPurchaseOrder.totalPrice,
                            euroCurrencySymbol,
                          )}`}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <Grid container className={classes.signingBox}>
                    <Button onClick={() => closeModal()} variant="outlined" color="primary">
                      {t('common.cancel')}
                    </Button>
                    <Button
                      className={classes.submitButton}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!selectedPO.length}
                      data-testid="submitInvoice-button"
                    >
                      {t('invoices.issueInvoice')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Card>
        </>
      )}
    </>
  );
};

export default CreateInvoice;
