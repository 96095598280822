import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { sign } from '../../utils';

const TransactionContext = React.createContext([{}, () => {}]);

const TransactionProvider = ({ children }) => {
  const { activeOrganization, activeWallet } = useBecOrganizationContext();

  async function signData(preimage, blockchainType) {
    const signature = await sign(
      `Bearer ${Cookies.get(`${window.config.cookiePrefix}access_token`)}`,
      activeOrganization._id,
      activeWallet.address,
      preimage,
      blockchainType,
    );

    return signature;
  }

  return <TransactionContext.Provider value={{ signData }}>{children}</TransactionContext.Provider>;
};

const useTransaction = () => useContext(TransactionContext);

TransactionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useTransaction, TransactionProvider };
