import gql from 'graphql-tag';
import { TOKEN_CONTRACT_ATTRIBUTES } from '../Tokenization/token';

export const CONTRACT_ATTRIBUTES = gql`
  fragment CONTRACT_ATTRIBUTES on Contract {
    _id
    organizationId
    contractId
    contractType
    materials {
      _id
      sku
      partNumber
      materialName
      materialDescription
      accumulatedVolume
      tierRules {
        tierBounds
        pricesByTier
        accumulatedVolume
      }
      rebateRules {
        thresholdQty
        rebateValue
        accumulatedVolume
        unitPrice
      }
      minVolume
      maxVolume
    }
    startDate
    endDate
    buyer {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    supplier {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    preimage
    status
    updatedAt
    isSender
    myOrganizationstatus
    myOrganizations {
      myOrgHeaderId
      role
      address
      status
      createdBy
      organizationId
    }
    smartContract {
      _id
      contractAddress
      transaction {
        transactionHash
      }
    }
  }
`;
export const CREATE_CONTRACT = gql`
  mutation createContract($input: inputContract!) {
    createContract(input: $input) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const CONTRACT_LISTING = gql`
  query getContractListing {
    contracts {
      _id
      organizationId
      contractId
      startDate
      endDate
      status
      isSender
      buyer {
        organization {
          name
        }
      }
      supplier {
        organization {
          name
        }
      }
      materials {
        materialName
      }
    }
  }
`;

export const CONTRACT_LISTING_PARTNER_BASED = gql`
  query contractsByBusinessPartner($businessPartner: String!, $status: String!) {
    contractsByBusinessPartner(businessPartner: $businessPartner, status: $status) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const GET_CONTRACT_BY_RFQ = gql`
  query contracts($rfqId: String!) {
    contractsByRFQId(rfqId: $rfqId) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const GET_CONTRACT_UPDATE = gql`
  subscription onNewContract {
    newContract {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const GET_CONTRACT = gql`
  query contract($contractId: String!) {
    contract(contractId: $contractId) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const SIGN_CONTRACT = gql`
  mutation signContract($input: signContractInput!) {
    signContract(input: $input) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const CONTRACT_SIGNED = gql`
  subscription onContractSigned {
    contractSigned {
      _id
    }
  }
`;

export const GET_ONP_CONTRACT_DEPLOYED_STATUS = gql`
  subscription onONPContractDeployed {
    contractONPDeployed {
      ...TOKEN_CONTRACT_ATTRIBUTES
      contractId
    }
  }
  ${TOKEN_CONTRACT_ATTRIBUTES}
`;

export const GET_REBATE_VALUE = gql`
  query getRebateValueForContract($smartContractId: String!) {
    getRebateValueForContract(smartContractId: $smartContractId) {
      sku
      rebateDiscount
      discountPercentage
      smartContractId
    }
  }
`;

export const UPDATE_AUTHORIZED_ORGANIZATIONS = gql`
  mutation updateAuthorizedOrganizations($input: updateAuthorizedOrganizationInput!) {
    updateAuthorizedOrganizations(input: $input) {
      ...CONTRACT_ATTRIBUTES
    }
  }
  ${CONTRACT_ATTRIBUTES}
`;

export const UPDATED_AUTHORIZED_ORGANIZATIONS = gql`
  subscription onUpdatedMyOrganizations {
    updatedMyOrganizations {
      _id
      myOrganizationstatus
    }
  }
`;
