import gql from 'graphql-tag';

export const GET_IMPORTED = gql`
  query getImport($getImport: getImport!) {
    import(input: $getImport) {
      data
      inventory
      timestamp
      location
    }
  }
`;

export const GET_KEYS = gql`
  query getKeys {
    keys {
      key
    }
  }
`;
