import React, { useContext, useState } from 'react';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useSubscription, useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import {
  GET_ERC_TOKENS,
  GET_ERC_TOKENS_FROM_PO,
  GET_ERC_TOKENS_BY_PAGE,
  GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS,
  TRANSACTION_STATUS,
  SMART_CONTRACT_CONFIG_STRUCTURE_UPDATED,
  BATCH_MINT_ERC721,
  GET_ERC721_BATCH_TOKEN_LIST,
  SET_TOKEN_BULK_METADATA_ERC721,
  GET_SINGLE_TOKEN_CONTRACT_DETAILS,
  TRANSFER_ERC1155,
  BURN_ERC1155,
} from '../../graphql/Tokenization/token';
import { useNotification } from '../Shared/notification';
import track from '../../mixpanel';
import { CONSTANTS } from '../../constants';

const TokenContext = React.createContext([{}, () => {}]);

const TokenProvider = ({ children }) => {
  const [tokenContracts, setTokenContracts] = useState([]);
  const [tokenBatches, setTokenBatches] = useState([]);
  const [totalTokenCount, setTotalTokenCount] = useState(0);
  const [pendingTransactionsCount, setPendingTransactionsCount] = useState([]);
  const [pendingTransfersCount, setPendingTransfersCount] = useState([]);
  const [pendingDepositsCount, setPendingDepositsCount] = useState({});
  const [pendingWithdrawsCount, setPendingWithdrawsCount] = useState({});
  const [pendingZkpSingleTransferCount, setPendingZkpSingleTransferCount] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedTokenBatchID, setSelectedTokenBatchID] = useState(null);
  const [tokenBatchMetadataUpdating, setTokenBatchMetadataUpdating] = useState(false);
  const [chainTransactionProgressing, setChainTransactionProgressing] = useState(false);
  const [erc20BalanceDetails, setErc20BalanceDetails] = useState(0);

  const {
    handleNotification,
    finishedTnxs,
    setFinishedTnxs,
    showFaucetModal,
    setShowFaucetModal,
  } = useNotification();
  const { t } = useTranslation();

  const { activeWallet } = useBecOrganizationContext();

  const incrementPendingTransactions = contractAddress => {
    const pendingTransactions = [...pendingTransactionsCount];
    const tempIndex = findIndex(pendingTransactions, {
      contractAddress,
    });
    if (pendingTransactions[tempIndex]) {
      pendingTransactions[tempIndex].pending += 1;
    } else {
      pendingTransactions.push({
        contractAddress,
        pending: 1,
      });
    }
    setPendingTransactionsCount(pendingTransactions);
  };

  const decrementPendingTransactions = contractAddress => {
    const pendingTransactions = [...pendingTransactionsCount];
    const tempIndex = findIndex(pendingTransactions, {
      contractAddress,
    });
    if (pendingTransactions[tempIndex]?.pending > 0) {
      pendingTransactions[tempIndex].pending -= 1;
      setPendingTransactionsCount(pendingTransactions);
    }
  };

  const hasPendingTransactions = contractAddress => {
    const index = findIndex(pendingTransactionsCount, { contractAddress });
    return pendingTransactionsCount[index]?.pending > 0;
  };

  const incrementPendingTransfers = transactionHash => {
    const pendingTransfers = [...pendingTransfersCount];
    const tempIndex = findIndex(pendingTransfers, {
      transactionHash,
    });
    if (pendingTransfers[tempIndex]) {
      pendingTransfers[tempIndex].pending += 1;
    } else {
      pendingTransfers.push({
        transactionHash,
        pending: 1,
      });
    }
    setPendingTransfersCount(pendingTransfers);
  };

  const decrementPendingTransfers = transactionHash => {
    const pendingTransfers = [...pendingTransfersCount];
    const tempIndex = findIndex(pendingTransfers, {
      transactionHash,
    });
    if (pendingTransfers[tempIndex]?.pending > 0) {
      pendingTransfers[tempIndex].pending -= 1;
      setPendingTransfersCount(pendingTransfers);
    }
  };

  const hasPendingTransfers = transactionHash => {
    const index = findIndex(pendingTransfersCount, { transactionHash });
    return pendingTransfersCount[index]?.pending > 0;
  };

  const [
    getTokenContracts,
    { loading: tokenContractsLoading, error: errorGetTokenContracts },
  ] = useLazyQuery(GET_ERC_TOKENS, {
    onCompleted: data => {
      const temp = data.ercTokenContracts.map(token => {
        return {
          contractAddress: token?.contract?.contractAddress || '',
          pending: 0,
        };
      });
      setPendingTransactionsCount(temp);
      setTokenContracts(data.ercTokenContracts);
    },
    onError: err => {
      handleNotification(
        t('tokens.errorGetTokenContracts', {
          contractError: err?.message || '',
        }),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getTokenContractsFromPO,
    { loading: loadingFromSupplier, error: errorFromSupplier },
  ] = useLazyQuery(GET_ERC_TOKENS_FROM_PO, {
    onCompleted: data => {
      const temp = data.ercTokenContractsFromPurchaseOrder.map(token => {
        return {
          contractAddress: token?.contract?.contractAddress || '',
          pending: 0,
        };
      });
      setPendingTransactionsCount(temp);
      setTokenContracts(data.ercTokenContractsFromPurchaseOrder);
    },
    fetchPolicy: 'no-cache',
  });

  const [getErc721BatchTokenList, { loading: tokenListLoading }] = useLazyQuery(
    GET_ERC721_BATCH_TOKEN_LIST,
    {
      onCompleted: data => {
        setTokenBatches(data.erc721BatchTokenList);
      },
      onError: error => {
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [
    getTokenContractsByPage,
    {
      loading: tokenContractsByPageLoading,
      error: tokenContractsByPageError,
      fetchMore: fetchMoreTokenContractsByPage,
    },
  ] = useLazyQuery(GET_ERC_TOKENS_BY_PAGE, {
    onCompleted: data => {
      const temp = data.getTokenContractsByPage.tokenContracts.map(token => {
        return {
          contractAddress: token?.contract?.contractAddress || '',
          pending: 0,
        };
      });
      setPendingTransactionsCount(temp);
      setTokenContracts(data.getTokenContractsByPage.tokenContracts);
      setTotalTokenCount(data.getTokenContractsByPage.total);
    },
    onError: err => {
      handleNotification(
        t('tokens.errorGetTokenContracts', {
          contractError: err?.message || '',
        }),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getSingleTokenContractInDetails,
    { loading: singleTokenContractLoading, error: errorGetSingleTokenContract },
  ] = useLazyQuery(GET_SINGLE_TOKEN_CONTRACT_DETAILS, {
    onCompleted: data => {
      const singleContract = data?.getSingleTokenContractInDetails;

      setPendingTransactionsCount(
        pendingTransactionsCount.map(contractTransactionCount => {
          if (
            contractTransactionCount.contractAddress === singleContract?.contract?.contractAddress
          ) {
            return {
              contractAddress: singleContract?.contract?.contractAddress || '',
              pending: 0,
            };
          }
          return contractTransactionCount;
        }),
      );

      setTokenContracts(
        tokenContracts.map(tokenContract =>
          tokenContract.contract?.contractAddress === singleContract?.contract?.contractAddress
            ? singleContract
            : tokenContract,
        ),
      );
    },
    onError: err => {
      handleNotification(
        t('tokens.errorGetSingleTokenContract', {
          contractError: err?.message || '',
        }),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  useSubscription(GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS, {
    onSubscriptionData: data => {
      const newState = [...tokenContracts];
      const contract = data.subscriptionData?.data?.smartContractDeployed;
      const tnxHash = contract?.transaction?.transactionHash || '';

      const newFinishedTransactions = [...finishedTnxs];
      newFinishedTransactions.push(tnxHash);
      setFinishedTnxs(newFinishedTransactions);

      if (contract?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
        const index = findIndex(newState, {
          contract: {
            transaction: { transactionHash: tnxHash },
          },
        });
        newState.splice(index, 1, {
          ...newState[index],
          contract,
        });
        if (index === -1) {
          getTokenContracts();
        } else {
          setTokenContracts(newState);
        }
        handleNotification(t('tokens.deploySuccess'), 'success');
      } else if (
        data?.subscriptionData?.data?.smartContractDeployed?.transaction?.status ===
        CONSTANTS.CONTRACT_STATUSES.FAILED
      ) {
        handleNotification(t('tokens.deployError'), 'error');
        setTokenContracts(stateTokens =>
          stateTokens.filter(
            token =>
              token.contract.transaction?.transactionHash !== contract.transaction.transactionHash,
          ),
        );
      }
    },
  });

  useSubscription(SMART_CONTRACT_CONFIG_STRUCTURE_UPDATED, {
    onSubscriptionData: data => {
      const newState = [...tokenContracts];
      const responseContract = data.subscriptionData?.data?.smartContractConfigUpdated;
      const index = findIndex(newState, {
        contract: { contractAddress: responseContract?.contractAddress || '' },
      });

      if (newState[index] && newState[index].contract) {
        newState[index].contract.metadataStructure = responseContract.metadataStructure;
        setTokenContracts(newState);
        handleNotification(
          t('tokens.metadataConfigUpdateSuccess', {
            contractName: responseContract?.contractName || '',
          }),
          'success',
        );
      }
    },
  });

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      if (
        data.subscriptionData?.data?.transactionStatus?.transactionType ===
        CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA
      ) {
        const contract = data.subscriptionData?.data?.transactionStatus;
        if (contract?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          handleNotification(
            t([`tokens.${contract.transactionType}Success`, 'tokens.transactionSuccess']),
            'success',
          );
          if (selectedContract?.contract?.contractAddress) {
            getSingleTokenContractInDetails({
              variables: {
                contractAddress: selectedContract?.contract?.contractAddress,
              },
            });
          }
        } else if (contract?.status === CONSTANTS.CONTRACT_STATUSES.FAILED) {
          handleNotification(
            t([`tokens.${contract.transactionType}Error`, 'tokens.transactionFailure']),
            'error',
          );
          track('Token transaction failure', {
            tokenType: contract.smartcontractType || 'Unknown token type',
            transactionType: contract.transactionType || 'Unknown transaction type',
          });
        }
      } else if (
        data.subscriptionData?.data?.transactionStatus?.transactionType ===
        CONSTANTS.TRANSACTION_TYPES.BATCH_MINT
      ) {
        const contract = data.subscriptionData?.data?.transactionStatus;
        if (contract.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          handleNotification(
            t([`tokens.${contract.transactionType}Success`, 'tokens.transactionSuccess']),
            'success',
          );
          setTokenBatchMetadataUpdating(false);
          if (selectedContract?.contract?.contractAddress) {
            getSingleTokenContractInDetails({
              variables: {
                contractAddress: selectedContract?.contract?.contractAddress,
              },
            });
          }
        }
        if (contract.status === CONSTANTS.CONTRACT_STATUSES.PENDING) {
          setTokenBatchMetadataUpdating(true);
        }
      } else {
        const newState = [...tokenContracts];
        const contract = data.subscriptionData?.data?.transactionStatus;
        const index = findIndex(newState, {
          contract: { contractAddress: contract?.contractAddress || '' },
        });

        const tnxHash = data.subscriptionData?.data?.transactionStatus.transactionHash;
        if (tnxHash) {
          const newFinishedTransactions = [...finishedTnxs];
          newFinishedTransactions.push(tnxHash);
          setFinishedTnxs(newFinishedTransactions);
        }

        if (contract?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          const parsedData = parseInt(data.subscriptionData.data.transactionStatus.amount, 10);
          if (
            contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER ||
            contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.BURN
          ) {
            if (newState[index]) {
              newState[index].balance -= parsedData;
            }
          } else if (
            newState[index] &&
            contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.MINT
          ) {
            newState[index].balance += parsedData;
          }
          handleNotification(
            t([`tokens.${contract.transactionType}Success`, 'tokens.transactionSuccess']),
            'success',
          );
          track('Token transaction success', {
            tokenType: contract.smartcontractType || 'Unknown token type',
            transactionType: contract.transactionType || 'Unknown transaction type',
            quantity: contract.amount || 'Amount Unknown',
          });
        } else if (contract?.status === CONSTANTS.CONTRACT_STATUSES.FAILED) {
          handleNotification(
            t([`tokens.${contract.transactionType}Error`, 'tokens.transactionFailure']),
            'error',
          );
          track('Token transaction failure', {
            tokenType: contract.smartcontractType || 'Unknown token type',
            transactionType: contract.transactionType || 'Unknown transaction type',
          });
        }
        setTokenContracts(newState);
        if (
          contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER ||
          contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.MINT ||
          contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.BURN
        ) {
          decrementPendingTransactions(contract.contractAddress);
        } else if (
          contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.RECEIVE ||
          contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.SET_MINTERS
        ) {
          getTokenContracts();
        }
      }
    },
  });

  const [batchMintErc721TokenEth] = useMutation(BATCH_MINT_ERC721, {
    onCompleted: data => {
      incrementPendingTransactions(data.batchMintErc721Tokens.contractAddress);
      handleNotification(t('tokens.transactionSubmitted'), 'success');
      if (
        tokenBatches &&
        tokenBatches[0] &&
        tokenBatches[0].tokens &&
        tokenBatches[0].tokens[0].smartContract?._id
      ) {
        getErc721BatchTokenList({
          variables: {
            smartContractId: tokenBatches[0].tokens[0].smartContract?._id,
          },
        });
      } else {
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        getTokenContracts();
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.BATCH_MINT_ERC721, {
          blockchainNetwork: activeWallet?.network,
          tokenIds: data.batchMintErc721Tokens?.tokenIds,
          tokenCount: data.batchMintErc721Tokens?.tokenIds?.length || 0,
          contractId: selectedContract?.contract?._id,
        });
      }
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
      if (error?.graphQLErrors[0]?.errorCode === 'request_already_sent') {
        handleNotification(t('tokens.bulkMintAlreadyExits'), 'error');
      }
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.BATCH_MINT_ERC721, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const [transferErc1155TokenEth, { loading: isTransferErc1155Processing }] = useMutation(
    TRANSFER_ERC1155,
    {
      onCompleted: data => {
        setChainTransactionProgressing(true);
        incrementPendingTransfers(data?.transferERC1155Token?.transactionHash);
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.TRANSFER_ERC1155, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedContract?.contract?._id,
          contract: data?.transferERC1155Token?.contractAddress,
          transactionHash: data?.transferERC1155Token?.transactionHash,
        });
        return data;
      },
      onError: errorTransfer => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.TRANSFER_ERC1155, {
          blockchainNetwork: activeWallet?.network,
          error: errorTransfer.message,
        });
        if (errorTransfer?.graphQLErrors[0]?.errorCode === 'insufficient_funds')
          setShowFaucetModal(true);
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [burnErc1155Tokens, { loading: isErc1155BurnLoading }] = useMutation(BURN_ERC1155, {
    onCompleted: data => {
      setChainTransactionProgressing(true);
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.BURN_ERC1155, {
        blockchainNetwork: activeWallet?.network,
        tokenId: data.burnERC1155Token?.tokenId,
        contractId: selectedContract?.contract?._id,
        status: data.burnERC1155Token?.status,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.BURN_ERC1155, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.burnError']),
        'error',
      );
    },
  });

  const [batchMetadataUpdateErc721TokenEth, { loading: isBulkMetaUpdateProcessing }] = useMutation(
    SET_TOKEN_BULK_METADATA_ERC721,
    {
      onCompleted: data => {
        if (data.setTokenBulkMetadataERC721.metadataTransaction && data !== null) {
          const totalMetadata =
            Object.keys(data.setTokenBulkMetadataERC721.newMetadata).length || 0;
          incrementPendingTransactions(
            data.setTokenBulkMetadataERC721.metadataTransaction.contractAddress,
          );
          handleNotification(t('tokens.transactionSubmitted'), 'success');
          getTokenContracts();
          track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.BATCH_METADATA_UPDATE, {
            blockchainNetwork: activeWallet?.network,
            contractId: selectedContract?.contract?._id,
            tokenIds: data.setTokenBulkMetadataERC721?.metadataTransaction?.tokenIds,
            tokenCount: data.setTokenBulkMetadataERC721?.tokenIds?.length || 0,
            totalMetadata: totalMetadata,
          });
        }
      },
      onError: error => {
        if (error?.graphQLErrors[0]?.errorCode === 'exceeds_block_gas_limit') {
          handleNotification(t('common.errors.exceeds_block_gas_limit'), 'error');
        }
        if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') {
          setShowFaucetModal(true);
          handleNotification(
            t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
            'error',
          );
        }
        if (error?.graphQLErrors[0]?.errorCode === 'blockchain_transaction_pending') {
          setShowFaucetModal(true);
          handleNotification(
            t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
            'error',
          );
        }
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.BATCH_METADATA_UPDATE, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );

  return (
    <TokenContext.Provider
      value={{
        tokenContracts,
        tokenContractsLoading,
        loadingFromSupplier,
        errorGetTokenContracts,
        errorFromSupplier,
        getTokenContracts,
        getTokenContractsFromPO,
        tokenBatches,
        getErc721BatchTokenList,
        setTokenContracts,
        incrementPendingTransactions,
        decrementPendingTransactions,
        pendingTransactionsCount,
        hasPendingTransactions,
        incrementPendingTransfers,
        decrementPendingTransfers,
        pendingTransfersCount,
        hasPendingTransfers,
        pendingDepositsCount,
        setPendingDepositsCount,
        pendingWithdrawsCount,
        setPendingWithdrawsCount,
        setPendingZkpSingleTransferCount,
        pendingZkpSingleTransferCount,
        totalTokenCount,
        setTotalTokenCount,
        getTokenContractsByPage,
        tokenContractsByPageLoading,
        tokenContractsByPageError,
        fetchMoreTokenContractsByPage,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        selectedContract,
        setSelectedContract,
        batchMintErc721TokenEth,
        showFaucetModal,
        setShowFaucetModal,
        tokenListLoading,
        batchMetadataUpdateErc721TokenEth,
        isBulkMetaUpdateProcessing,
        selectedTokenBatchID,
        setSelectedTokenBatchID,
        tokenBatchMetadataUpdating,
        setTokenBatchMetadataUpdating,
        getSingleTokenContract: getSingleTokenContractInDetails,
        singleTokenContractLoading,
        errorGetSingleTokenContract,
        transferErc1155TokenEth,
        isTransferErc1155Processing,
        chainTransactionProgressing,
        setChainTransactionProgressing,
        burnErc1155Tokens,
        isErc1155BurnLoading,
        setTokenBatches,
        erc20BalanceDetails,
        setErc20BalanceDetails,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

const useTokenContext = () => useContext(TokenContext);

TokenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useTokenContext, TokenProvider };
