import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { isEmpty, trim } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CONSTANTS, METADATA_BEHAVIORS } from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import { SET_TOKEN_METADATA_CONFIG } from '../../../graphql/Tokenization/token';
import track from '../../../mixpanel';
import { isMeasurementDataType } from '../../../utils';
import PageLoader from '../../Shared/PageLoader';
import HashedModeFields from './HashedModeFields';
import MetadataBehaviorSelect from './MetadataBehaviorSelect';
import MetadataStructureFieldArray from './MetadataStructureFieldArray';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      padding: '0.1rem',
    },
  },

  buttonArea: {
    marginTop: theme.spacing(3),
  },
  saveButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const MetaDataStructure = ({
  selectedContract,
  isMetadataTypesLoading,
  metaDataTypes,
  measurementOptions,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleNotification } = useNotification();

  const { activeWallet } = useBecOrganizationContext();

  const isERC1155 =
    selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;

  const [setTokenMetadataConfig, { loading: isUpdateRunning }] = useMutation(
    SET_TOKEN_METADATA_CONFIG,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.SET_METADATA_STRUCTURE, {
          blockchainNetwork: activeWallet?.network,
          contractId: data.setMetadataConfig?._id,
          totalMetadata: data.setMetadataConfig?.metadataStructure?.metadataConfig?.length || 0,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.SET_METADATA_STRUCTURE, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );

  const [isEditMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(prevMode => !prevMode);
  };

  const submitForm = async values => {
    try {
      let inputConfig = [];

      switch (values.behavior) {
        case METADATA_BEHAVIORS.STRUCTURED:
          inputConfig = values.metadataArray?.map(metadataConfigRecord => ({
            metadataName: metadataConfigRecord.metadataName,
            metadataPermission: metadataConfigRecord.metadataPermission,
            metadataMutability: metadataConfigRecord.metadataMutability,
            metadataType: metadataConfigRecord.metadataType,
            chosenMetadataOption: isMeasurementDataType(
              metadataConfigRecord.metadataType,
              metaDataTypes,
            )
              ? metadataConfigRecord.chosenMetadataOption
              : '',
          }));
          break;
        case METADATA_BEHAVIORS.HASHED:
          inputConfig = [
            {
              metadataPermission: values.hashModePermission,
              metadataType: values.hashModeType,
            },
          ];
          break;
        default:
          break;
      }

      const inputMetaDataConfig = {
        variables: {
          input: {
            contractAddress: selectedContract?.contract?.contractAddress,
            behavior: values.behavior,
            metadataConfig: inputConfig,
          },
        },
      };

      await setTokenMetadataConfig(inputMetaDataConfig);
      handleNotification(t('tokens.success.tokenMetadataSaveSuccess'), 'success');
      setEditMode(false);
    } catch (err) {
      handleNotification(t('tokens.errors.tokenMetadataSaveFail'), 'error');
    }
  };

  const getExistingStructuredArray = () => {
    const initMetadataArray = [];
    const existingStructure = selectedContract?.contract?.metadataStructure;
    const existingConfig = existingStructure?.metadataConfig;

    if (
      existingStructure?.behavior === METADATA_BEHAVIORS.STRUCTURED &&
      existingConfig?.length > 0
    ) {
      existingConfig.forEach(metadataConfigRow => {
        initMetadataArray.push({
          metadataName: metadataConfigRow.metadataName,
          metadataType: metadataConfigRow.metadataType._id,
          measurement: '',
          metadataPermission: metadataConfigRow.metadataPermission,
          metadataMutability: metadataConfigRow.metadataMutability,
          chosenMetadataOption: metadataConfigRow.chosenMetadataOption,
          dirty: false,
        });
      });
    }

    return initMetadataArray;
  };

  const getInitialBehavior = () => {
    if (selectedContract?.contract?.metadataStructure?.behavior) {
      return selectedContract.contract.metadataStructure.behavior;
    }
    return METADATA_BEHAVIORS.NONE;
  };

  const getInitialType = () => {
    if (selectedContract?.contract?.metadataStructure?.behavior === METADATA_BEHAVIORS.HASHED) {
      return selectedContract.contract.metadataStructure.metadataConfig[0]?.metadataType._id;
    }

    return null;
  };

  const getInitialPermission = () => {
    if (selectedContract?.contract?.metadataStructure?.behavior === METADATA_BEHAVIORS.HASHED) {
      return selectedContract.contract.metadataStructure.metadataConfig[0]?.metadataPermission;
    }

    return null;
  };

  function uniqueName(value) {
    const mapper = metaData => trim(metaData.metadataName);
    const set = [...new Set(value.map(mapper))];
    const isUnique = value.length === set.length;
    if (isUnique) return true;
    const metaDataIndex = value.findIndex((l, i) => mapper(l) !== set[i]);
    const { createError } = this;
    return createError({
      path: `metadataArray[${metaDataIndex}].metadataName`,
      message: t('tokens.validation.uniqueMetadataNameRequired'),
    });
  }

  const metadataValidationSchema = Yup.object().shape({
    metadataArray: Yup.mixed().when('behavior', {
      is: METADATA_BEHAVIORS.STRUCTURED,
      then: Yup.array()
        .of(
          Yup.object().shape({
            metadataName: Yup.string().required(t('tokens.validation.metadataNameRequired')),
            metadataType: Yup.string().required(t('tokens.validation.dataTypeRequired')),
            metadataPermission: Yup.string().required(t('tokens.validation.permissionRequired')),
            metadataMutability: Yup.string().required(
              t('tokens.validation.dataMutabilityRequired'),
            ),
            chosenMetadataOption: Yup.mixed().when('metadataType', {
              is: value => isMeasurementDataType(value, metaDataTypes),
              then: Yup.string().required('required'),
            }),
          }),
        )
        .test('uniqueMetaDataName', t('tokens.validation.uniqueMetadataNameRequired'), uniqueName)
        .min(1, t('tokens.validation.structureFieldRequired')),
    }),
    hashModeType: Yup.mixed().when('behavior', {
      is: METADATA_BEHAVIORS.HASHED,
      then: Yup.string().required('required'),
    }),
    hashModePermission: Yup.mixed().when('behavior', {
      is: METADATA_BEHAVIORS.HASHED,
      then: Yup.string().required('required'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      metadataArray: getExistingStructuredArray(),
      behavior: getInitialBehavior(),
      hashModeType: getInitialType(),
      hashModePermission: getInitialPermission(),
    },
    validationSchema: metadataValidationSchema,
    onSubmit: submitForm,
    enableReinitialize: true,
  });

  const { values: formValues } = formik;

  const getFieldsForMode = () => {
    switch (formValues?.behavior) {
      case METADATA_BEHAVIORS.STRUCTURED:
        return (
          <MetadataStructureFieldArray
            formValues={formValues}
            metaDataTypes={metaDataTypes}
            measurementOptions={measurementOptions}
            isEditMode={isEditMode}
            toggleEditMode={toggleEditMode}
          />
        );
      case METADATA_BEHAVIORS.HASHED:
        return <HashedModeFields metaDataTypes={metaDataTypes} />;
      default:
        return <></>;
    }
  };

  const getMetadataBehaviours = () =>
    Object.values(METADATA_BEHAVIORS).filter(behaviourType =>
      isERC1155 ? true : behaviourType !== METADATA_BEHAVIORS.LINKED,
    );

  if (isMetadataTypesLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className={classes.root}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Field
              label="behavior"
              name="behavior"
              component={MetadataBehaviorSelect}
              labels={getMetadataBehaviours()}
            />

            {getFieldsForMode()}

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.saveButton}
              disabled={!formik.dirty || !isEmpty(formik.errors) || isUpdateRunning}
            >
              {t('tokens.saveMetadata')}
            </Button>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

MetaDataStructure.propTypes = {
  selectedContract: PropTypes.shape({
    contract: PropTypes.shape({
      tokenType: PropTypes.string,
      contractAddress: PropTypes.string,
      metadataStructure: PropTypes.shape({
        metadataConfig: PropTypes.arrayOf(
          PropTypes.shape({
            metadataType: PropTypes.shape({
              _id: PropTypes.string,
            }),
            metadataPermission: PropTypes.string,
          }),
        ),
        behavior: PropTypes.string,
      }).isRequired,
    }),
  }).isRequired,
  isMetadataTypesLoading: PropTypes.bool.isRequired,
  metaDataTypes: PropTypes.arrayOf(PropTypes.shape({})),
  measurementOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

MetaDataStructure.defaultProps = {
  metaDataTypes: null,
  measurementOptions: null,
};

export default MetaDataStructure;
