import dayjs from 'dayjs';

export const months36 = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
];

export const getMonthLabel = (item, date) => {
  // const monthLabels = [
  //   t('common.monthLabel.Dec'),
  //   t('common.monthLabel.Jan'),
  //   t('common.monthLabel.Feb'),
  //   t('common.monthLabel.Mar'),
  //   t('common.monthLabel.Apr'),
  //   t('common.monthLabel.May'),
  //   t('common.monthLabel.Jun'),
  //   t('common.monthLabel.Jul'),
  //   t('common.monthLabel.Aug'),
  //   t('common.monthLabel.Sep'),
  //   t('common.monthLabel.Oct'),
  //   t('common.monthLabel.Nov'),
  // ];

  const label = dayjs(date)
    .add(item, 'M')
    .format('YYYY/MM');
  return label;
};

export const dateFormatter = date => {
  let formattedDate = '';
  if (date !== null) {
    formattedDate = dayjs(date).format('DD MMM YYYY');
  }
  return formattedDate;
};
