import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, FormikProvider, useFormik, FastField } from 'formik';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import NumberFormat from 'react-number-format';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  tokenId: {
    width: '20%',
    paddingRight: '10px',
  },
  tokenAmount: {
    width: '20%',
  },
  availableAmount: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    paddingLeft: '10px',
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    marginLeft: theme.spacing(2),
  },
  formArea: {
    marginTop: '2em',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  fieldDivider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '16px 10px',
  },
  accordionPaper: {
    margin: '0 10px',
    width: '100%',
  },
  balance: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  outerGrid: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useBurnToken = ({ selectedToken, contractAddress, loading, onSubmit, closeModal }) => {
  const [tokens, setTokens] = useState([]);
  const { t } = useTranslation();
  const initialValues = {
    amount: selectedToken?.owners1155[0]?.balance,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      const res = await onSubmit({
        variables: {
          tokens: [{ tokenId: selectedToken?.tokenId, amount: parseInt(values.amount, 10) }],
          contractAddress,
        },
      });
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(1, t('tokens.validation.minAmount'))
        .max(100000000, t('tokens.validation.maxAmount'))
        .required(t('tokens.validation.required'))
        .integer(t('tokens.validation.integerValue')),
    }),
  });

  const closeModalFn = () => {
    closeModal();
    formik.resetForm();
  };

  return {
    tokens,
    setTokens,
    closeModalFn,
    formik,
  };
};

const BurnErc1155Token = ({
  open,
  selectedToken,
  closeModal,
  onSubmit,
  loading,
  contractAddress,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const { formik, closeModalFn } = useBurnToken({
    selectedToken,
    contractAddress,
    onSubmit,
    loading,
    closeModal,
  });

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.burnTokens')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <div className={classes.formArea}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <div className={classes.outerGrid}>
                <Typography variant="body1" className={classes.tokenId}>
                  <span>Token Id : </span>
                  <span className={classes.balance}>{selectedToken?.tokenId}</span>
                </Typography>

                <FastField
                  component={NumberFormat}
                  label={t('tokens.amount')}
                  value={formik.values.amount || ''}
                  name="amount"
                  customInput={TextField}
                  onValueChange={vals => formik.setFieldValue('amount', vals.value)}
                  thousandSeparator
                  isNumericString
                  className={classes.tokenAmount}
                />
                <Typography variant="body1" className={classes.availableAmount}>
                  <span>{t('tokens.available')}:</span>
                  <span className={classes.balance}>{selectedToken?.owners1155[0]?.balance}</span>
                </Typography>
              </div>

              <FormErrors form={formik} />

              {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                <BlockchainInfo
                  validateForm={formik.validateForm}
                  values={formik.values}
                  contractAddress={contractAddress}
                  method={CONSTANTS.TRANSACTION_TYPES.BURN}
                  methodArgs={[activeWallet?.address, '', '']}
                  className={classes.tokenAmount}
                />
              ) : (
                ''
              )}
              <Divider className={classes.divider} />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={
                  !formik.values.amount ||
                  formik.values.amount === 0 ||
                  formik.values.amount > selectedToken?.owners1155[0]?.balance ||
                  loading
                }
              >
                {t('tokens.burn')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={closeModalFn}
              >
                {t('common.cancel')}
              </Button>
            </Form>
          </FormikProvider>
        </div>
      </Paper>
    </Modal>
  );
};

BurnErc1155Token.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  selectedToken: PropTypes.shape({
    tokenId: PropTypes.string.isRequired,
    owners1155: PropTypes.shape({
      balance: PropTypes.string,
    }),
  }).isRequired,
};

BurnErc1155Token.defaultProps = {
  contractAddress: '',
};

export default BurnErc1155Token;
