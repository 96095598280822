import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isEqual } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import BlockchainInfo from '../Shared/BlockchainInfo';
import { CONSTANTS } from '../../constants';
import { isValidContent } from '../../utils';
import KeyArray from '../Shared/JsonViewKeyArray';
import FormikSwitch from '../Shared/FormikSwitch';

const useStyles = makeStyles(theme => ({
  paper: {
    top: theme.spacing(10),
    position: 'absolute',
    width: '60%',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  submitButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginRight: '5px',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  switchBox: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  contentArea: {
    minHeight: '150px',
  },
  errorDisplay: {
    boxShadow: 'none',
  },
}));

const MetadataErc721Token = ({ open, closeModal, onSubmit, contractAddress, token, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const [isInitialContentPushed, setIsInitialContentPushed] = useState(false);

  const viewSwitchOptions = {
    trueLabel: t('common.jsonMode'),
    falseLabel: t('common.structuredMode'),
  };

  const convertToJsonStr = arrayToConvert => {
    let retObj = {};

    arrayToConvert.forEach(keyValuePair => {
      const { key, value } = keyValuePair;

      if (value && !key) {
        throw new Error(t('common.errors.errorMissingKey'));
      }

      if (key in retObj) {
        throw new Error(t('common.errors.errorDuplicateKey'));
      }

      if (key && key !== '') {
        if (value === '' || !value) {
          throw new Error(t('common.errors.errorMissingValue'));
        }

        retObj[key] = value;
      }
    });

    if (Object.keys(retObj).length === 0) {
      retObj = {
        '': '',
      };
    }

    return JSON.stringify(retObj, undefined, 2);
  };

  const convertToArray = strToConvert => {
    const jsonToConvert = JSON.parse(strToConvert);
    const retArray = [];

    Object.keys(jsonToConvert).forEach(keyValuePair => {
      retArray.push({
        key: keyValuePair,
        value: jsonToConvert[keyValuePair],
      });
    });

    return retArray;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: token?.metadata ? token?.metadata : JSON.stringify({ '': '' }),
      rawMode: false,
      linkMode: false,
      fieldArray: [
        {
          key: '',
          value: '',
        },
      ],
    },
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      const { rawMode } = values;

      const updatedMetadata = rawMode
        ? convertToJsonStr(convertToArray(values.content))
        : convertToJsonStr(values.fieldArray);

      const res = await onSubmit({
        variables: {
          input: {
            contractAddress,
            tokenId: token?.tokenId,
            metadata: updatedMetadata,
          },
        },
      });
      if (res) {
        resetForm();
        closeModal();
      }
    },
    validationSchema: Yup.object().shape({
      content: Yup.mixed().when('rawMode', {
        is: true,
        then: Yup.string().test(
          'Valid JSON Content',
          t('common.errors.errorContentInvalid'),
          value => {
            try {
              convertToArray(value);
              if (isValidContent(value) && !isEqual(JSON.parse(value), { '': '' })) {
                return true;
              }
              return false;
            } catch (err) {
              return false;
            }
          },
        ),
      }),
      fieldArray: Yup.mixed().when('rawMode', {
        is: false,
        then: Yup.mixed().test(
          'Valid key/field pairs',
          t('common.errors.errorContentInvalid'),
          value => {
            try {
              const parsedStr = convertToJsonStr(value);
              if (parsedStr === JSON.stringify({ '': '' }, undefined, 2)) {
                return false;
              }
              return true;
            } catch (err) {
              return false;
            }
          },
        ),
      }),
    }),
  });

  const { values, setFieldValue, setFieldError } = formik;

  useEffect(() => {
    if (token.tokenId && token.metadata && !isInitialContentPushed) {
      const parsedArray = convertToArray(token?.metadata);
      setFieldValue('fieldArray', parsedArray);
      setFieldValue('content', convertToJsonStr(parsedArray));
      setIsInitialContentPushed(true);
    }
  }, [token]);

  const changeInputMode = () => {
    const { rawMode } = values;

    if (!rawMode) {
      try {
        const parsedString = convertToJsonStr(values.fieldArray);
        setFieldValue('content', parsedString);
      } catch (err) {
        setFieldError('fieldArray', err.message);
        throw err;
      }
    } else {
      try {
        const parsedArray = convertToArray(values.content);
        setFieldValue('fieldArray', parsedArray);
      } catch (err) {
        setFieldError('content', err.message);
        throw err;
      }
    }
  };

  const handleClose = () => {
    formik.resetForm();
    closeModal();
  };
  return (
    <Modal className={classes.modal} open={open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.metadataHeader') + token?.tokenId}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className={classes.switchBox}>
              <Field
                label="Mode"
                name="rawMode"
                color="primary"
                className={classes.rawModeSwitch}
                component={FormikSwitch}
                beforeChange={changeInputMode}
                labels={viewSwitchOptions}
              />
            </div>
            <div className={classes.contentArea}>
              {!values.rawMode && <Field name="fieldArray" component={KeyArray} limit={5} />}
              {values.rawMode && (
                <Field
                  name="content"
                  component={TextField}
                  className={classes.contentField}
                  multiline
                  rows={4}
                />
              )}
            </div>
            <FormErrors form={formik} className={classes.errorDisplay} bgcolor="none" />
            {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
              <BlockchainInfo
                validateForm={formik.validateForm}
                values={formik.values}
                contractAddress={contractAddress}
                method={CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA_ON_CHAIN}
                methodArgs={[activeWallet?.address, token?.tokenId?.toString(), values?.content]}
              />
            ) : (
              ''
            )}
            <Divider className={classes.divider} />
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              disabled={!formik.dirty || loading || !formik.isValid}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {t('tokens.updateMetadata')}
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={classes.cancelButton}
            >
              {t('common.cancel')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

MetadataErc721Token.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  loading: PropTypes.bool,
  token: PropTypes.shape({
    tokenId: PropTypes.number,
    metadata: PropTypes.string,
  }).isRequired,
};

MetadataErc721Token.defaultProps = {
  contractAddress: '',
  loading: false,
};

export default MetadataErc721Token;
