import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import DialogTitle from '@eyblockchain/ey-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { join, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS, METADATA_BEHAVIORS } from '../../../constants';
import BlockchainInfo from '../../Shared/BlockchainInfo';

const useStyles = makeStyles(theme => ({
  portalRegisterModalRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(1),
  },
  loaderBox: {
    display: 'flex',
  },
  loader: {
    marginLeft: '2em',
  },
  contentArea: {
    marginTop: theme.spacing(3),
  },
  bannerArea: {
    marginTop: theme.spacing(3),
  },
  stepContentArea: {
    marginTop: theme.spacing(3),
  },
  itemSubHeader: {
    color: theme.palette.primary.light,
  },
  itemHeader: {
    fontWeight: '400',
  },
  dependantItems: {
    fontWeight: '400',
    wordBreak: 'break-all',
  },
  stepContentTextArea: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  stepContextText: {
    marginLeft: theme.spacing(2),
    fontWeight: '400',
  },
  itemArea: {
    display: 'flex',
    alignItems: 'center',
  },
  itemBox: {
    marginRight: '5em',
  },
  boldText: {
    fontWeight: 'bold',
  },
  confirmationBox: {
    margin: '1rem 0',
  },
}));

const PortalRegisterModal = ({
  isModalOpen,
  handleClose,
  handleSubmit,
  loading,
  formData,
  smartContractType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const byPassValidation = true;
  const [isBlockchainInfoLoading, setIsBlockchainInfoLoading] = useState(false);

  const isReady = (!!formData && !!formData?.stepName) || loading;
  const modalTitle = formData?.stepName
    ? `${t('traceability.dgp.registerModal.title')}"${formData?.stepName}"`
    : '';

  const formik = useFormik({
    initialValues: {
      tokenId: formData?.token?.serialNumber,
      contractAddress: formData?.contractAddress,
    },
  });

  const listParentSerialNumbers = (parentItemContent, behavior) =>
    behavior === METADATA_BEHAVIORS.LINKED
      ? parentItemContent?.serialNumber
      : join(
          map(parentItemContent, parentToken => parentToken.label),
          ',',
        );

  return (
    <div className={classes.portalRegisterModalRoot}>
      <FormikProvider value={formik}>
        <Form>
          <Dialog open={isModalOpen} onClose={handleClose} fullWidth>
            <DialogTitle onClose={handleClose}>{modalTitle}</DialogTitle>
            <DialogContent dividers>
              {!isReady && (
                <div className={classes.loaderBox}>
                  <Typography variant="h5">{t('common.loading')}</Typography>
                  <CircularProgress
                    size={30}
                    thickness={20}
                    color="primary"
                    className={classes.loader}
                  />
                </div>
              )}
              {isReady && (
                <div className={classes.contentArea}>
                  <div className={classes.itemArea}>
                    <div className={classes.itemBox}>
                      <Typography variant="body1" className={classes.itemSubHeader}>
                        {t('traceability.dgp.headerTitle.item')}
                      </Typography>
                      <Typography variant="body1" className={classes.itemSubHeader}>
                        {formData?.itemName}
                      </Typography>
                      <Typography variant="h5" className={classes.itemHeader}>
                        {formData?.token}
                      </Typography>
                    </div>
                    <div className={classes.itemBox}>
                      {!!formData?.parentItemName && (
                        <>
                          <Typography variant="body1" className={classes.itemSubHeader}>
                            {t('traceability.dgp.headerTitle.dependentItem')}
                          </Typography>
                          <Typography variant="body1" className={classes.itemSubHeader}>
                            {formData?.parentItemName}
                          </Typography>
                          <Typography variant="h5" className={classes.dependantItems}>
                            {listParentSerialNumbers(
                              formData?.parentItemContent,
                              formData?.behavior,
                            )}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                  <Grid container direction="column" className={classes.confirmationBox}>
                    <Typography variant="body1">
                      {t('traceability.dgp.registerModal.confirmationText')}
                      <span className={classes.boldText}>{formData?.itemName}</span>
                    </Typography>
                    <Typography variant="body1">{t('contracts.wantToProceed')}</Typography>
                  </Grid>

                  {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                    <BlockchainInfo
                      validateForm={async () => {}}
                      values={formik.values}
                      contractAddress={formData?.contractAddress}
                      method={CONSTANTS.TRANSACTION_TYPES.MINT}
                      methodArgs={
                        smartContractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721
                          ? [activeWallet?.address, '', 'url']
                          : [activeWallet?.address, '', '', 'url']
                      }
                      byPassValidation={byPassValidation}
                      setIsLoading={setIsBlockchainInfoLoading}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              {isReady ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    disabled={loading}
                  >
                    {t('traceability.dgp.registerModal.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={loading || isBlockchainInfoLoading}
                  >
                    {t('traceability.dgp.registerModal.register')}
                  </Button>
                </>
              ) : (
                ''
              )}
            </DialogActions>
          </Dialog>
        </Form>
      </FormikProvider>
    </div>
  );
};

PortalRegisterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  formData: PropTypes.shape({
    stepName: PropTypes.string.isRequired,
    fieldValues: PropTypes.shape({}).isRequired,
    itemName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    parentItemName: PropTypes.string,
    parentItemContent: PropTypes.arrayOf(PropTypes.shape({})),
    contractAddress: PropTypes.string,
    behavior: PropTypes.string,
  }),
  smartContractType: PropTypes.string,
};

PortalRegisterModal.defaultProps = {
  formData: null,
  loading: false,
  smartContractType: CONSTANTS.SMARTCONTRACT_TYPES.ERC721,
};

export default PortalRegisterModal;
