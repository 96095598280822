import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '@material-ui/core/TextField';
import { useFormik, Form, FormikProvider } from 'formik';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Table from '@eyblockchain/ey-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { sortBy, words, some } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import uniqid from 'uniqid';
import { useNotification } from '../../contexts/Shared/notification';
import FormErrors from '../../components/Shared/FormErrors';
import SignButton from '../../components/Shared/SignButton';
import TableActions from '../../components/Shared/TableActions';
import { useTokenContext } from '../../contexts/Tokenization/token';
import { CONSTANTS } from '../../constants';
import BlockchainInfo from '../../components/Shared/BlockchainInfo';
import TableHeadCell from '../../components/Shared/TableHeadCell';

const useRowStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    color: theme.palette.primary.light,
  },
  status: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '.5rem',
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
    marginTop: '50px',
  },
  subTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  divider: {
    background: '#e0e0e0',
    marginBottom: '.5rem',
    marginTop: '.5rem',
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  statusContainer: {
    display: 'block',
  },
  preTitle: {
    fontWeight: '200',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '.875rem',
  },
  preTitleName: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  message: {
    display: 'flex',
    color: theme.colors.blue,
    marginLeft: 10,
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  mintDescription: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  preTitleRightSide: {
    marginLeft: 730,
  },
  inputContainer: {
    display: 'block',
    marginBottom: theme.spacing(5),
  },
  textfieldInstructions: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.8),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    fontWeight: 200,
  },
  signingBox: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(2)}px`,
    boxShadow: `-8px 8px 8px 4px rgba(0,0,0,.25)`,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    display: 'block',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: 'none',
  },
  paperModel: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
    padding: theme.spacing(3),
  },
  modalTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  mintTokenMessage: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 200,
  },
  sectionData: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  signCard: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  cardText: {
    color: theme.colors.blue,
    fontSize: theme.spacing(2.2),
    letterSpacing: 0,
    lineHeight: 'normal',
  },
  signButton: {
    margin: theme.spacing(1),
  },
  section: {
    paddingTop: theme.spacing(3),
  },
  processing: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  circularLoader: {
    marginRight: theme.spacing(3),
  },
  stagedSerialNo: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingLeft: '25px',
  },
  removeDuplicate: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingRight: '25px',
    textAlign: 'Right',
  },
  duplicateButton: {
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    width: 217,
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  statusIcon: {
    height: '20px',
    width: '20px',
    color: 'inherit',
  },
  readyChip: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  duplicateChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  duplicateSerial: {
    color: theme.palette.error.main,
  },
  loaderContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
  },
  countHighlighter: {
    color: '#B9251B',
  },
  gridSpace: {
    paddingLeft: 14,
  },
}));

const BulkMintModel = ({
  open,
  closeModal,
  mintValues,
  handleMintBatchTokens,
  isBulkMintTokenProcessing,
  isTokenProcessCompleted,
  tokenCount,
}) => {
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const classes = useRowStyles();
  const { selectedContract } = useTokenContext();
  const formik = useFormik({
    initialValues: {
      mintValues: mintValues,
    },
    validationSchema: Yup.object().shape({
      mintValues: Yup.string().required(),
    }),
  });

  return (
    <>
      {!isBulkMintTokenProcessing ? (
        <Modal className={classes.modal} open={open} onClose={closeModal}>
          <Paper className={classes.paperModel}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('contracts.mintFeeConfirmation')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  <Typography variant="h6" className={classes.mintTokenMessage}>
                    {t('contracts.mintFeeTokenIntro')}{' '}
                    <strong>
                      {`${tokenCount}`} {t('contracts.token')}
                    </strong>{' '}
                    {t('contracts.fromThe')}
                    <strong>{` ${selectedContract.tokenName}`}</strong>{' '}
                    {t('contracts.tokenSmartContract')}
                  </Typography>
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.wantToProceed')}
                  </Typography>
                  {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                    <BlockchainInfo
                      validateForm={formik.validateForm}
                      values={formik.values}
                      contractAddress=""
                      contractName={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                      method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                      methodArgs={['2', '']}
                    />
                  ) : (
                    ''
                  )}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.sectionData}>
                    <Button
                      className={classes.signButton}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.cancel')}
                    </Button>
                    <SignButton
                      onSign={() => handleMintBatchTokens(formik.values.purchaseOrderId)}
                      className={classes.signButton}
                      label={t('tokens.mint')}
                      testId="signPOAndValidatepricing"
                    />
                  </div>
                </Grid>
              </Form>
            </FormikProvider>
          </Paper>
        </Modal>
      ) : (
        <Modal
          className={classes.modal}
          open={open && !isTokenProcessCompleted}
          onClose={closeModal}
        >
          <Paper className={classes.paperModel}>
            <Grid container direction="row" justify="space-between">
              <Typography variant="h4" className={classes.modalTitle}>
                {t('contracts.bulkMintTokens')}
              </Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container direction="column">
              <>
                <Grid container direction="row" alignItems="center" className={classes.processing}>
                  <CircularProgress
                    color="primary"
                    size={30}
                    thickness={2}
                    className={classes.circularLoader}
                  />
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.mintingCount')} {`${tokenCount}`} {t('contracts.tokensCount')}
                  </Typography>
                </Grid>
                <Card className={classes.signCard}>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.iconContainer}>
                      <InfoIcon className={classes.icon} />
                    </div>
                    <Typography variant="h4" className={classes.cardText}>
                      {t('contracts.processingSigning')}
                    </Typography>
                  </CardContent>
                </Card>
              </>
              <Divider classes={{ root: classes.navButtonDivider }} />
              <div className={classes.section}>
                <Button
                  className={classes.submit}
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  {t('common.close')}
                </Button>
              </div>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
};

BulkMintModel.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  mintValues: PropTypes.shape({
    serialNo: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  handleMintBatchTokens: PropTypes.func.isRequired,
  isBulkMintTokenProcessing: PropTypes.bool.isRequired,
  isTokenProcessCompleted: PropTypes.bool.isRequired,
  tokenCount: PropTypes.number.isRequired,
};

const BulkMinting = ({ hideBulkMinting, displayActiveContract }) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const { selectedContract, batchMintErc721TokenEth } = useTokenContext();
  const [mintTokenNos, setMintTokenNos] = useState([]);
  const [insertedTokenNos, setInsertedTokenNos] = useState([]);
  const [tokenListLoading, setTokenListLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isBulkMintTokenProcessing, setIsBulkMintTokenProcessing] = useState(false);
  const isTokenProcessCompleted = false;
  const allowedTokens = 100;
  const { handleNotification, setUpdateLivePanel } = useNotification();

  const handleClose = () => {
    setOpen(false);
    hideBulkMinting(false);
  };

  const handleClick = () => {
    hideBulkMinting(false);
    if (!!handleClick && typeof handleClick === 'function')
      displayActiveContract(selectedContract?.contract?._id);
  };
  const handleMintBatchTokens = async () => {
    setIsBulkMintTokenProcessing(true);
    const tokendIds = [];
    mintTokenNos.forEach(token => {
      tokendIds.push(token.serialNo);
    });
    await batchMintErc721TokenEth({
      variables: {
        input: {
          contractAddress: selectedContract.contract.contractAddress,
          tokenIds: tokendIds,
        },
      },
    });
    handleClose();
    setUpdateLivePanel(true);
  };
  const serialNumbersFormation = serialnos => {
    const serialNoArray = [];
    const itemsInserted = [];
    serialnos.forEach(items => {
      const tempObj = {
        status: itemsInserted.includes(items) ? 'duplicate' : 'ready',
        serialNo: items,
        id: uniqid(),
        serialNum: Number(items),
      };
      if (insertedTokenNos.includes(items)) {
        tempObj.status = 'duplicate';
      }
      serialNoArray.push(tempObj);
      itemsInserted.push(items);
    });
    setInsertedTokenNos(insertedTokenNos.concat(itemsInserted));
    return serialNoArray;
  };
  const formik = useFormik({
    initialValues: {
      serialNo: '',
    },
    onSubmit: async (values, { resetForm }) => {
      setTokenListLoading(true);
      if (values.serialNo !== '' && values.serialNo !== undefined) {
        const tempArray = words(values.serialNo.replace(/\s*,\s*/g, ',').trim());
        if (tempArray.length > 0) {
          const formattedSerialNos = serialNumbersFormation(tempArray);
          const mergedSerialNos = mintTokenNos.concat(formattedSerialNos);
          setMintTokenNos(sortBy(mergedSerialNos, ['status']));
          if (some(mergedSerialNos, ['status', 'duplicate'])) {
            handleNotification(t('tokens.duplicateBulkMintError'), 'error');
          }
          if (mergedSerialNos?.length > allowedTokens) {
            const excessTokenCount = Number(mergedSerialNos.length) - allowedTokens;
            if (excessTokenCount > 0) {
              handleNotification(
                t('tokens.maxMintError', {
                  nos: excessTokenCount,
                }),
                'error',
              );
            }
          }
        }
      }
      resetForm();
      setTokenListLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.lazy(() => {
      return Yup.object().shape({
        serialNo: Yup.string()
          .required(t('contracts.validation.serialNo'))
          .test('', t('contracts.validation.serialNo'), value => {
            let modifiedValue = value;
            modifiedValue = modifiedValue.replace(/ +/g, '');
            modifiedValue = modifiedValue.replace(/\n/g, '');
            const numberExp = /^[0-9\b]+$/;
            const characterExp = /^[0-9,;]*$/;
            if (numberExp.test(modifiedValue) || characterExp.test(modifiedValue)) {
              return true;
            }
            return false;
          }),
      });
    }),
  });

  const uniqueSerialNos = (uniqueKey, uniqueValue) => {
    const temptokenNos = mintTokenNos;
    const deleteIndex = temptokenNos.findIndex(data => data.id === uniqueKey);
    if (deleteIndex !== -1) {
      const insertedTokenNosIndex = insertedTokenNos.findIndex(
        value => Number(value) === uniqueValue,
      );
      insertedTokenNos.splice(insertedTokenNosIndex, 1);
      temptokenNos.splice(deleteIndex, 1);
      setInsertedTokenNos(insertedTokenNos);
      const formattedSerialNo = temptokenNos.map(serialNoObj => {
        const findObj = temptokenNos.filter(serial => serial.serialNo === serialNoObj.serialNo);
        if (findObj.length === 1) {
          return { ...serialNoObj, status: 'ready' };
        }
        return { ...serialNoObj, status: 'duplicate' };
      });
      return formattedSerialNo;
    }
    return temptokenNos;
  };

  const removeallDuplicates = () => {
    const ids = mintTokenNos.map(seriaNos => seriaNos.serialNo);
    const filtered = mintTokenNos.filter((serial, index) => {
      return !ids.includes(serial.serialNo, index + 1);
    });
    const activeSerialNos = filtered.map(serialNumber => ({ ...serialNumber, status: 'ready' }));
    setMintTokenNos(activeSerialNos);
  };

  const doUpdate = (column, direction) => {
    const columnFomat = column.toString();

    if (direction === 'asc') {
      setMintTokenNos(
        mintTokenNos.sort((a, b) => {
          return a[columnFomat] - b[columnFomat];
        }),
      );
    } else if (direction === 'desc') {
      setMintTokenNos(
        mintTokenNos.sort((a, b) => {
          return b[columnFomat] - a[columnFomat];
        }),
      );
    }
  };

  const mintcolumns = [
    {
      name: 'serialNum',
      label: t('tokens.serialNumber'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: (value, data) => {
          if (data?.rowData[1] === 'duplicate') {
            return <span className={classes.duplicateSerial}>{data?.rowData[0]}</span>;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: 'status',
      label: t('tokens.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          if (value === 'ready') {
            return (
              <div className={classes.associatedBox}>
                <Chip
                  label={t('tokens.readyForMint')}
                  variant="outlined"
                  icon={<CheckIcon className={classes.statusIcon} />}
                  classes={{
                    root: classes.readyChip,
                  }}
                />
              </div>
            );
          }
          if (value === 'duplicate') {
            return (
              <div className={classes.associatedBox}>
                <Chip
                  label={t('tokens.duplicate')}
                  classes={{
                    root: classes.duplicateChip,
                  }}
                />
              </div>
            );
          }
          return '';
        },
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        customBodyRender: (value, data) => {
          return (
            <TableActions
              options={[
                {
                  label: t('tokens.delete'),
                  buttonText: t(''),
                  icon: <DeleteOutlinedIcon />,
                  handleClick: () => {
                    setMintTokenNos(uniqueSerialNos(data.rowData[2], data.rowData[0]));
                  },
                },
              ]}
              testId={`serialno-${value}`}
            />
          );
        },
      },
    },
  ];

  const mintTokenButtonLabel = () => {
    if (mintTokenNos.length > 0 && mintTokenNos.length < 501) {
      return `${t('tokens.mint')} (${mintTokenNos.length}) `;
    }
    return `${t('tokens.mint')}`;
  };

  return (
    <>
      {tokenListLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        ''
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.navContainer}
      >
        <Button
          variant="text"
          color="primary"
          onClick={handleClick}
          className={classes.navButton}
          startIcon={<ArrowBackIosIcon />}
        >
          {t('headers.token_plural')}
        </Button>
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Grid className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.status}
        >
          <div>
            <Typography className={classes.preTitle} variant="h5">
              {t('headers.token')}
              <span className={classes.preTitleName}>{` ${selectedContract.tokenName}`}</span>
            </Typography>
            <Typography className={classes.sectionTitle} variant="h5">
              {t('tokens.bulkMinting')}
            </Typography>
          </div>
        </Grid>
        <Divider classes={{ root: classes.navButtonDivider }} />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.status}
        >
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {t('common.100TokensOnlyMint')}
              </Typography>
            </Paper>
            <Typography className={classes.mintDescription}>
              {t('tokens.mintTokenIntro')}
            </Typography>
            <Typography variant="h6" className={classes.subTitle}>
              {t('tokens.textboxHeader')}
            </Typography>
            <div className={classes.inputContainer}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className={classes.form}>
                  <FormErrors form={formik} />
                  <Grid container>
                    <TextField
                      id="serialNo"
                      label="Serial Numbers"
                      multiline
                      rows={5}
                      placeholder="e.g. 1967, 00001"
                      name="serialNo"
                      variant="outlined"
                      value={formik.values.serialNo || ''}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.serialNo && Boolean(formik.errors.serialNo)}
                    />
                  </Grid>
                  <Typography variant="h6" className={classes.textfieldInstructions}>
                    {t('tokens.textBoxGuide')}
                  </Typography>
                  <Button
                    onClick={() => 1}
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIosIcon />}
                    type="submit"
                    id="addSerialsBtn"
                  >
                    {t('tokens.add')}
                  </Button>
                </Form>
              </FormikProvider>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.gridSpace}>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item xs={8} className={classes.stagedSerialNo}>
                <Typography variant="h6" className={classes.subTitle}>
                  {t('tokens.serialNosStaged')}
                  <strong
                    className={mintTokenNos.length > allowedTokens ? classes.countHighlighter : ''}
                  >
                    {' '}
                    {mintTokenNos.length}
                  </strong>{' '}
                  /{allowedTokens}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.removeDuplicate}>
                <Button
                  className={classes.duplicateButton}
                  variant="outlined"
                  onClick={removeallDuplicates}
                  startIcon={<DeleteOutlinedIcon />}
                  id="removeAllDuplicateBtn"
                >
                  {t('tokens.removeDuplicates')}
                </Button>
              </Grid>
            </Grid>
            <Table
              className={classes.table}
              columns={mintcolumns}
              data={mintTokenNos}
              options={{
                pagination: mintTokenNos.length > 0,
                filter: false,
                search: false,
                searchOpen: false,
                elevation: 0,
                sort: true,
                onColumnSortChange: (column, direction) => doUpdate(column, direction),
                textLabels: {
                  body: {
                    noMatch: t('common.noMatchingRecords'),
                  },
                  pagination: {
                    rowsPerPage: t('common.rowsPerPage'),
                  },
                },
              }}
            />
          </Grid>

          <Grid container className={classes.signingBox}>
            <Button onClick={() => hideBulkMinting(false)} variant="outlined" color="primary">
              {t('common.cancel')}
            </Button>
            <Button
              className={classes.submitButton}
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
              disabled={
                mintTokenNos.length > allowedTokens ||
                mintTokenNos.length === 0 ||
                some(mintTokenNos, ['status', 'duplicate'])
              }
              data-testid="bulkmintsubmit-button"
            >
              {mintTokenButtonLabel()}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <BulkMintModel
        open={open}
        closeModal={handleClose}
        mintValues={selectedContract}
        handleMintBatchTokens={handleMintBatchTokens}
        isBulkMintTokenProcessing={isBulkMintTokenProcessing}
        isTokenProcessCompleted={isTokenProcessCompleted}
        tokenCount={mintTokenNos.length}
      />
    </>
  );
};

BulkMinting.propTypes = {
  hideBulkMinting: PropTypes.func.isRequired,
  displayActiveContract: PropTypes.func,
};

BulkMinting.defaultProps = {
  displayActiveContract: () => {},
};

export default BulkMinting;
