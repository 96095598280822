import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import track from '../../mixpanel';
import { CONSTANTS, DASHBOARD_PRODUCTS, TRIAL_PACKAGES, ORG_TYPES } from '../../constants';
import { getIdTokenInfo } from '../../utils';
import DashboardCard from '../../components/Shared/DashboardCard';
import DashboardModal from '../../components/Shared/DashboardModal';
import useUserInfo from '../../hooks/useUserInfo';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '60vh',
    [theme.breakpoints.up('md')]: {
      minHeight: '90vh',
    },
    float: 'left',
    textAlign: 'center',
    align: 'center',
  },
  header: {
    paddingTop: '30px',
    paddingBottom: '30px',
    textAlign: 'center',
    align: 'center',
    marginTop: '5px',
    color: theme.palette.primary.lightest,
    backgroundColor: theme.palette.primary.dark,
    height: '150px',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 400,
  },
  subTitle: {
    fontWeight: 300,
    width: 'revert',
  },
  gridContainer: {
    padding: `0px ${theme.spacing(1)}px`,
    minWidth: '1100px',
    minHeight: '450px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const logLaunchOpschain = () => {
  if (sessionStorage.getItem('launch_opschain_logged')) return;
  const tokenInfo = getIdTokenInfo();
  track(CONSTANTS.MIXPANEL_EVENTS.LAUNCH_OPSCHAIN_EVENT, {
    date: moment().format('MMMM Do YYYY, h:mm:ss a'),
    unique_id: tokenInfo.sub,
  });

  sessionStorage.setItem('launch_opschain_logged', true);
};

const buildProductsList = packages => {
  const packagesIds = {
    TOKENIZATION: TRIAL_PACKAGES[window.config.nodeEnv].TOKENIZATION,
    NOTARIZATION: TRIAL_PACKAGES[window.config.nodeEnv].NOTARIZATION,
    TRACEABILITY: TRIAL_PACKAGES[window.config.nodeEnv].TRACEABILITY,
    CONTRACT_MANAGER: TRIAL_PACKAGES[window.config.nodeEnv].CONTRACT_MANAGER,
  };

  return [
    {
      ...DASHBOARD_PRODUCTS.TOKENIZATION,
      packageId: packagesIds.TOKENIZATION,
      isPackageOwner: packages.tokenization,
    },
    {
      ...DASHBOARD_PRODUCTS.NOTARIZATION,
      packageId: packagesIds.NOTARIZATION,
      isPackageOwner: packages.notarization,
    },
    {
      ...DASHBOARD_PRODUCTS.TRACEABILITY,
      packageId: packagesIds.TRACEABILITY,
      isPackageOwner: packages.traceability,
    },
    {
      ...DASHBOARD_PRODUCTS.CONTRACT_MANAGER,
      packageId: packagesIds.CONTRACT_MANAGER,
      isPackageOwner: packages.contractManager,
    },
  ];
};

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [linkAccess, setLinkAccess] = useState(false);
  const history = useHistory();
  const { userPackages, orgPackages, updateUserPackages, updateOrgPackages } = useUserInfo();
  const { activeOrganization } = useBecOrganizationContext();
  const orgType = activeOrganization?.org_type;
  const { requestSubscriptionsCheck } = useSubscriptionContext();

  const productsList = buildProductsList(orgType === ORG_TYPES.USER ? userPackages : orgPackages);

  useEffect(() => {
    logLaunchOpschain();
  }, []);

  const handleButtonClick = product => {
    if (product.isPackageOwner) {
      history.push(product.START);
    } else {
      setSelectedProduct(product);
      setOpenModal(true);
    }
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setLinkAccess(false);
    setOpenModal(false);
  };

  const requestEnterpriseAccess = () => {
    window.open(`${window.config.homeUrl}/enterprise`, '_blank');
  };

  const visitHelpCenter = () => {
    window.open(`${window.config.homeUrl}`, '_blank');
  };

  const requestTrialPackage = async product => {
    let packages = null;
    if (orgType === ORG_TYPES.USER) {
      packages = await updateUserPackages(product?.packageId);
    } else {
      packages = await updateOrgPackages(product?.packageId);
    }
    const flags = await requestSubscriptionsCheck();
    return [packages, flags];
  };

  const handleLinkClick = product => {
    if (orgType === ORG_TYPES.USER || orgType === ORG_TYPES.STANDARD) {
      requestEnterpriseAccess();
    } else if (product?.isPackageOwner) {
      visitHelpCenter();
    } else {
      // open modal
      setLinkAccess(true);
      setSelectedProduct(product);
      setOpenModal(true);
    }
  };

  const generateCards = () => {
    return productsList.map(product => (
      <Grid
        item
        xs={3}
        className={classes.grid}
        key={`dashboard-product-${product.NAME}`}
        data-testid={`dashboard-card-${product.NAME}`}
      >
        <DashboardCard
          product={product}
          handleButtonClick={() => handleButtonClick(product)}
          handleLinkClick={() => handleLinkClick(product)}
          orgType={orgType}
        />
      </Grid>
    ));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="h3"
          align="center"
          data-testid="welcome-heading"
          className={classes.title}
        >
          {t('dashboard.welcomeTitle')}
        </Typography>
        <Typography data-testid="welcome-body" className={classes.subTitle}>
          {t('dashboard.welcomeSubtitle')}
        </Typography>
      </div>
      <DashboardModal
        open={openModal}
        closeModal={closeModal}
        product={selectedProduct}
        requestAccess={requestEnterpriseAccess}
        requestTrialPackage={() => requestTrialPackage(selectedProduct)}
        orgType={orgType}
        linkAccess={linkAccess}
        visitHelpCenter={visitHelpCenter}
        data-testid="dashboard-modal"
      />
      <Grid container className={classes.gridContainer} spacing={1}>
        {generateCards()}
      </Grid>
    </div>
  );
};

export default Dashboard;
