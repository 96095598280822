import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import Modal from '@eyblockchain/ey-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';

import DatePickerField from '../../../components/Shared/DatePickerField';
import { useNotification } from '../../../contexts/Shared/notification';

const EditModal = ({
  header,
  open,
  onClose,
  action,
  makePayload,
  isLoading,
  fieldArray,
  refetch,
  module,
  initValues,
  validationSchema,
}) => {
  const { t } = useTranslation('mtr');
  const { handleNotification } = useNotification();

  const messageLib = {
    oos: {
      success: t('pos.updateShipmentSuccess'),
      error: t('pos.updateShipmentFail'),
    },
    podetail: {
      success: t('pos.updatePOSuccess'),
      error: t('pos.updatePOFail'),
    },
  };

  const editModalClasses = makeStyles(theme => ({
    modal: {
      '& .MuiDialog-container': {
        '& .MuiDialog-paperFullWidth': {
          maxWidth: '100%',
          width: '70%',
        },
      },
      '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
      },
    },
    modalContainer: {
      marginTop: '1rem',
      '::placeholder': {
        fontSize: '0.5rem',
      },
      overflow: 'hidden !important',
    },
    inputFeedback: {
      color: 'rgb(185, 37, 28)',
      marginTop: '.25rem',
    },
    divider: {
      background: theme.palette.primary.light,
      width: '100%',
      margin: '0 auto',
      marginTop: '8px',
      marginBottom: '8px',
      backgroundColor: '#E7E7EA',
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: '0.8rem',
    },
    subheader: {
      margin: theme.spacing(0),
      paddingBottom: 0,
      paddingTop: 0,
    },
    inputRoot: {
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        fontSize: '0.5rem',
      },
    },
    labelRoot: {
      fontSize: '0.8rem',
      width: '100%',
    },
  }))();

  const onSubmit = async (values, { resetForm }) => {
    try {
      await action({
        variables: {
          input: makePayload(values),
        },
      });
      handleNotification(messageLib[module].success || 'Success', 'success');
      resetForm();
      onClose();
      refetch();
    } catch (err) {
      handleNotification(messageLib[module].error || 'Error', 'error');
    }
  };

  const initialValues = { ...initValues };

  Object.entries(initValues).forEach(pair => {
    const [key, val] = pair;
    const fieldItem = fieldArray.find(i => i.name === key);
    const { type } = fieldItem || {};

    if (val === undefined && type === 'date') {
      initialValues[key] = null;
    } else if ((val === null || val === undefined) && (type === 'text' || type === 'number')) {
      initialValues[key] = '';
    }
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
  });

  const {
    values,
    errors,
    submitForm,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
  } = formik;

  const displayFieldItem = fieldItem => {
    const { gridSize, name, label, type } = fieldItem;

    const value = values[name];
    const error = errors[name];

    const fieldTypeMap = {
      date: () => (
        <Grid item xs={gridSize} key={name}>
          <Field
            component={DatePickerField}
            onBlur={handleBlur}
            onChange={handleChange}
            label={label}
            value={value}
            name={name}
            format="YYYY-MM-DD"
            minDate="1970-01-01"
            className={editModalClasses.labelRoot}
            form={formik}
            error={error}
          />
          {/* {error && <div className={editModalClasses.inputFeedback}>{error}</div>} */}
        </Grid>
      ),
      text: () => {
        return (
          <Grid item xs={gridSize} key={name}>
            <Field
              value={value}
              component={TextField}
              onBlur={handleBlur}
              onChange={handleChange}
              error={error}
              className={editModalClasses.labelRoot}
              key={name}
              name={name}
              label={label}
              type={type}
              InputProps={{ classes: { root: editModalClasses.inputRoot } }}
            />
            {error && <div className={editModalClasses.inputFeedback}>{error}</div>}
          </Grid>
        );
      },
      number: () => {
        return (
          <Grid item xs={gridSize} key={name}>
            <Field
              value={value}
              component={TextField}
              onBlur={handleBlur}
              onChange={handleChange}
              error={error}
              className={editModalClasses.labelRoot}
              key={name}
              name={name}
              label={label}
              type={type}
              InputProps={{
                classes: { root: editModalClasses.inputRoot },
                inputProps: { min: fieldItem.min, max: fieldItem.max },
              }}
            />
            {error && <div className={editModalClasses.inputFeedback}>{error}</div>}
          </Grid>
        );
      },
      separator: () => <Divider className={editModalClasses.divider} />,
      subheader: () => (
        <Grid item xs={12}>
          <Typography className={editModalClasses.subheader} variant="h5" align="left">
            {name}
          </Typography>
        </Grid>
      ),
      info: () => (
        <Grid item className={editModalClasses.paper} xs={gridSize} key={`header-grid-${name}`}>
          {label}: {value}
        </Grid>
      ),
    };

    return fieldTypeMap[type](fieldItem);
  };

  return (
    <>
      <Modal
        className={editModalClasses.modal}
        open={open}
        onClose={onClose}
        title={header}
        splitActions={false}
        showCloseIcon
        rightActionOptions={{
          name: t('pos.updateButton'),
          color: 'secondary',
          variant: 'contained',
          startIcon: isLoading && <CircularProgress size={20} thickness={20} color="primary" />,
          disabled: !dirty || !isValid || isLoading,
          onClick: submitForm,
        }}
        leftActionOptions={{
          name: t('pos.cancelButton'),
          variant: 'outlined',
          color: 'primary',
          startIcon: null,
          onClick: onClose,
        }}
      >
        <Grid className={editModalClasses.modalContainer}>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {fieldArray.map(fieldItem => {
                  const { name, type } = fieldItem;

                  return (
                    <React.Fragment key={`grid-frag-${type}-${name}`}>
                      {displayFieldItem(fieldItem)}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      </Modal>
    </>
  );
};

EditModal.propTypes = {
  action: PropTypes.func.isRequired,
  makePayload: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fieldArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  module: PropTypes.string,
  header: PropTypes.string,
  initValues: PropTypes.shape({}),
  validationSchema: PropTypes.shape({}),
};

EditModal.defaultProps = {
  module: 'oos',
  header: '',
  makePayload: val => val,
  initValues: {},
  validationSchema: {},
};

export default EditModal;
