import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import WizardTooltip from './WizardTooltip';

const useStyles = makeStyles(() => ({
  fieldName: {
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
  },
  tooltipIcon: {
    height: '15px',
    width: '15px',
  },
  centeredField: {
    textAlign: 'center',
  },
}));

export const getHeaderNameText = ({ formName, field }) =>
  `traceability.${formName}.labels.${field}`;

export const getTooltipsText = ({ formName, field }) =>
  `traceability.${formName}.tooltips.${field}`;

const WizardHelper = ({ formName, orderedFields, centeredFields }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return orderedFields.map(field => (
    <Typography
      variant="body1"
      className={`${classes.fieldName} ${
        centeredFields?.includes(field) ? classes.centeredField : ''
      }`}
      key={`traceability-tooltips-${field}`}
    >
      {t(getHeaderNameText({ formName, field }))}
      <WizardTooltip text={t(getTooltipsText({ formName, field }))}>
        <InfoOutlined className={classes.tooltipIcon} />
      </WizardTooltip>
    </Typography>
  ));
};

WizardHelper.propTypes = {
  formName: PropTypes.string.isRequired,
  orderedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  centeredFields: PropTypes.arrayOf(PropTypes.string),
};
export default WizardHelper;
