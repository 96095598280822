import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { isEqual } from 'lodash';

const MultiSelectDropDown = ({
  options,
  field,
  disabled,
  label,
  form: { setFieldValue, setFieldTouched, errors, touched },
  startingValue,
  withCheckboxes,
  limitTags,
  size,
  className,
  renderOption,
  classes,
  onChange,
}) => {
  const hasError = !!(touched[field.name] && errors[field.name]);

  const otherParams = {
    ...(size.length > 0 && { size }),
    ...(limitTags >= 0 && { limitTags }),
    ...(startingValue && { value: startingValue }),
    ...(classes && { classes }),
    ...(renderOption && { renderOption }),
  };

  if (withCheckboxes) {
    otherParams.renderOption = (option, { selected }) => (
      <>
        <Checkbox
          key={option.value}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          checked={selected}
        />
        {renderOption ? renderOption(option) : option.label}
      </>
    );
  }

  const buildChips = params => {
    return (
      <Field
        component={TextField}
        {...params}
        label={label}
        variant="outlined"
        fullWidth
        error={hasError}
        helperText={hasError ? errors[field.name] : ''}
      />
    );
  };

  if (onChange) {
    otherParams.onChange = onChange;
  } else {
    otherParams.onChange = (e, value) => {
      setFieldValue(field.name, value);
      setFieldTouched(field.name, true, false);
    };
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      id={field.name}
      {...field}
      {...otherParams}
      name={field.name}
      disabled={disabled}
      options={options}
      renderInput={buildChips}
      getOptionLabel={option => option.label}
      getOptionSelected={(optionToCheck, selectedValue) =>
        isEqual(optionToCheck.value, selectedValue.value)
      }
      className={className}
    />
  );
};

MultiSelectDropDown.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    }),
  ),
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
  startingValue: PropTypes.arrayOf(PropTypes.shape({})),
  withCheckboxes: PropTypes.bool,
  limitTags: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
  renderOption: PropTypes.func,
  classes: PropTypes.shape({}),
  onChange: PropTypes.func,
};

MultiSelectDropDown.defaultProps = {
  disabled: false,
  options: [],
  label: '',
  withCheckboxes: false,
  limitTags: -1,
  size: '',
  startingValue: [],
  className: '',
  renderOption: undefined,
  classes: undefined,
  onChange: undefined,
};

export default MultiSelectDropDown;
