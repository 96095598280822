import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  legendArea: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: '1rem',
  },
  completedBanner: {
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.primary.light}`,
    width: '14%',
  },
  inProgressBanner: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    width: '17%',
  },
  failedBanner: {
    paddingLeft: theme.spacing(3),
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    justifyContent: 'center',
    width: '10%',
  },
  legendText: {
    color: theme.palette.primary.light,
    whiteSpace: 'pre',
  },
  failedLegendText: {
    color: theme.palette.error.main,
    whiteSpace: 'pre',
  },
}));

const StatusBanner = ({ countPerStatus }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.legendArea}>
      <div className={classes.completedBanner}>
        <Typography variant="body2" className={classes.legendText}>
          {t('notarization.statusCodes.completed')}{' '}
          {countPerStatus[CONSTANTS.CONTRACT_STATUSES.COMPLETED] || 0}
        </Typography>
      </div>
      <div className={classes.inProgressBanner}>
        <Typography variant="body2" className={classes.legendText}>
          {t('notarization.statusCodes.pending')}{' '}
          {countPerStatus[CONSTANTS.CONTRACT_STATUSES.PENDING] || 0}
        </Typography>
      </div>
      {countPerStatus[CONSTANTS.CONTRACT_STATUSES.FAILED] > 0 && (
        <div className={classes.failedBanner}>
          <Typography variant="body2" className={classes.failedLegendText}>
            {t('notarization.statusCodes.failed')}{' '}
            {countPerStatus[CONSTANTS.CONTRACT_STATUSES.FAILED]}
          </Typography>
        </div>
      )}
    </div>
  );
};

StatusBanner.propTypes = {
  countPerStatus: PropTypes.shape({
    [CONSTANTS.CONTRACT_STATUSES.COMPLETED]: PropTypes.number,
    [CONSTANTS.CONTRACT_STATUSES.PENDING]: PropTypes.number,
    [CONSTANTS.CONTRACT_STATUSES.FAILED]: PropTypes.number,
  }),
};

StatusBanner.defaultProps = {
  countPerStatus: {
    [CONSTANTS.CONTRACT_STATUSES.COMPLETED]: 0,
    [CONSTANTS.CONTRACT_STATUSES.PENDING]: 0,
    [CONSTANTS.CONTRACT_STATUSES.FAILED]: 0,
  },
};

export default StatusBanner;
