import { Modal, Typography, Paper, IconButton, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  },
  body: {
    margin: theme.spacing(1),
    fontWeight: 'bold',
  },
  message: {
    paddingTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  note: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    margin: theme.spacing(1),
  },
  removeOrgButton: {
    margin: theme.spacing(1),
  },
  navButtonDivider: {
    background: '#e0e0e0',
  },
  sectionData: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const DeleteOrganization = ({ isDeleteOrgOpen, closeModal, childOrg, removeOrgHandler }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal className={classes.modal} open={isDeleteOrgOpen} onClose={closeModal}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.modalTitle}>
          {t('organization.deleteOrganizationTitle', {
            orgName: childOrg.name,
          })}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body1" className={classes.message}>
          {t('organization.deleteOrganizationPrompt', {
            orgName: childOrg.name,
          })}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {t('organization.deleteOrganizationAlert')}
        </Typography>
        <Divider classes={{ root: classes.navButtonDivider }} />
        <div className={classes.sectionData}>
          <Button
            type="cancel"
            variant="outlined"
            color="primary"
            id="cancel-button"
            className={classes.cancelButton}
            onClick={() => {
              closeModal();
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="save"
            variant="contained"
            color="secondary"
            id="remove-organization-button"
            className={classes.removeOrgButton}
            onClick={() => {
              removeOrgHandler(childOrg);
              closeModal();
            }}
          >
            {t('organization.removeOrganization')}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

DeleteOrganization.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isDeleteOrgOpen: PropTypes.bool.isRequired,
  childOrg: PropTypes.shape({
    childId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  removeOrgHandler: PropTypes.func.isRequired,
};

export default DeleteOrganization;
