import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Modal from '@eyblockchain/ey-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import track from '../../mixpanel';
import screens from './Screens';
import api from '../../utils/api';
import { useNotification } from '../../contexts/Shared/notification';
import { getUserId } from '../../utils';

const OnboardingModal = ({ screen, open, setOpen }) => {
  const { handleNotification } = useNotification();
  const [screenSelected, setScreenSelected] = useState(screen);
  const [copied, setCopied] = useState(false);
  const [testWalletAddress, setTestWalletAddress] = useState();
  const [userModalPreference, setUserModalPreference] = useState();
  const { t } = useTranslation();

  const { checkUserPreferences } = api.userAPI({ handleNotification, t });

  const { activeWallet, walletsList } = useBecOrganizationContext();

  const useStyles = makeStyles({
    modal: {
      '& .MuiDialogContent-dividers': {
        borderBottom: '0px',
        padding: '0.5rem 0 0',
      },
      '& .MuiDialogActions-root': {
        padding: '0',
      },
      '& .MuiTypography-h4': {
        fontWeight: '400',
      },
      '& .MuiTypography-button': {
        fontWeight: '300',
      },
    },
    loadingModal: {
      minHeight: '2000px',
    },
  });

  useEffect(() => {
    track('View Set Up Instructions');
    async function fetchData() {
      const userId = getUserId();
      let testNetWallet = walletsList.find(item => item.network === 'ropsten');

      if (!testNetWallet) {
        // Turn to default wallet when Chinese user doesn't have ropsten wallet
        testNetWallet = activeWallet;
        setTestWalletAddress(activeWallet.address);
      } else {
        setTestWalletAddress(testNetWallet.availableWallets?.[0]?.address);
      }

      if (testNetWallet?.networkType === 'private') {
        setOpen(false); // Disable Modal for private network
      } else {
        const userPreferences = await checkUserPreferences({ userId });
        if (userPreferences) setUserModalPreference(userPreferences.show_wallet_modal);
      }
    }
    fetchData();
  }, [activeWallet, walletsList]);

  const {
    showCloseIcon,
    actions: { left, right },
    content,
    isComplete,
  } = screens(
    screenSelected,
    setScreenSelected,
    testWalletAddress,
    setOpen,
    copied,
    setCopied,
    userModalPreference,
  )[screenSelected];

  const classes = useStyles();

  return (
    <>
      {testWalletAddress ? (
        <Modal
          className={classes.modal}
          maxWidth="lg"
          title={t('OnboardingModal.welcome')}
          showCloseIcon={showCloseIcon}
          disableBackdropClick={!showCloseIcon}
          disableEscapeKeyDown={!showCloseIcon}
          open={open}
          onClose={() => {
            setOpen(false);
            if (!isComplete) {
              track('Onboarding canceled');
            }
          }}
          leftActionOptions={left}
          rightActionOptions={right}
        >
          {content}
        </Modal>
      ) : (
        <MaskedLoader loading={!testWalletAddress} />
      )}
    </>
  );
};

OnboardingModal.propTypes = {
  screen: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

OnboardingModal.defaultProps = {
  screen: 0,
  open: false,
  setOpen: null,
};

export default OnboardingModal;
