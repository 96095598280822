import dayjs from 'dayjs';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import _ from 'lodash';

// eslint-disable-next-line react/prop-types
export const displayDate = ({ value, format = 'YYYY-MM-DD' }) => {
  if (!value) {
    return null;
  }
  return (
    <span style={{ whiteSpace: 'nowrap', color: 'grey' }}>
      {dayjs(value * 1000).format(format)}
    </span>
  );
};

// eslint-disable-next-line react/prop-types
export const displayToolTip = ({ value = '', displayValue = '', color = 'inherit' }) => {
  const cellStyle = {
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color,
  };

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip arrow title={value} placement="top-start">
        <div style={cellStyle}>{displayValue}</div>
      </Tooltip>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const ellipsisText = ({ value = '', length = 100, color = 'inherit' }) => {
  if (!value) {
    return null;
  }
  let displayValue = `${value}`;

  if (displayValue.length >= length) {
    displayValue = displayValue.substring(0, length).concat('...');
    return displayToolTip({ value, displayValue, color });
  }

  return <div style={{ whiteSpace: 'nowrap', color }}>{displayValue}</div>;
};

export const getPOReleaseLineNumber = foreignPurchaseNumber => {
  const numberArray = foreignPurchaseNumber?.split('-');
  const poNum = numberArray[0];
  const releaseAndLine = numberArray[1];
  const releaseAndLineArray = releaseAndLine?.split('/');
  const releaseNum = releaseAndLineArray[0];
  const lineNum = releaseAndLineArray[1];

  return {
    poNum,
    releaseNum,
    lineNum,
  };
};

export const getForeignPurchaseNumber = ({ poNum, releaseNum, lineNum }) => {
  return `${poNum}-${releaseNum || '0'}/${lineNum}`;
};

export const computePercentage = (numerator, denominator) => {
  return `${Math.round((numerator / denominator) * 10000) / 100}%`;
};

export const makeDownload = ({ dataColumn, buildHead, buildBody, oldColumns, oldData }) => {
  const newColumn = dataColumn.map(i => {
    const transformMap = {
      timestamp: value => {
        if (!value) {
          return value;
        }
        return dayjs(value * 1000).format('YYYY/MM/DD');
      },
    };
    const label = _.find(oldColumns, oldColumnItem => oldColumnItem.name === i.attr)?.label;
    return {
      ...i,
      name: i.transform ? i.name : label || i.name,
      download: true,
      transform: i.transform || transformMap[i.type],
    };
  });
  const newData = oldData.map((oldDataItem, rowIndex) => {
    const rowData = newColumn.map(newColumnItem => {
      const index = _.findIndex(oldColumns, i => i.name === newColumnItem.attr);
      const value =
        oldDataItem.data[index] || oldDataItem.data[index] === 0
          ? oldDataItem.data[index]
          : undefined;
      return newColumnItem.transform ? newColumnItem.transform(value) : value;
    });
    return {
      ...oldDataItem,
      data: [rowIndex + 1, ...rowData],
    };
  });
  const finalColumn = [{ name: '#', download: true }].concat(newColumn);
  return `\uFEFF${buildHead(finalColumn)}${buildBody(newData)}`;
};

export const makeMasterIDs = ({ idMaps, excelItem, fileTableCol }) => {
  const masterIDs = {};
  Object.keys(idMaps).forEach(key => {
    const { name } = _.find(fileTableCol, item => item.range === key);
    const value = excelItem[name];
    const attr = idMaps[key];
    Object.assign(masterIDs, { [attr]: value });
  });
  return getForeignPurchaseNumber(masterIDs);
};
export const makePayload = ({ updateMap, excelItem, fileTableCol }) => {
  const payload = {};
  Object.keys(updateMap).forEach(key => {
    const { name } = _.find(fileTableCol, item => item.range === key);
    const value = excelItem[name];
    const attr = updateMap[key];
    Object.assign(payload, { [attr]: value === '' ? null : value });
  });
  return payload;
};
