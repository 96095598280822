import gql from 'graphql-tag';

export const ITEM_TYPES_ATTRIBUTES = gql`
  fragment ITEM_TYPES_ATTRIBUTES on ItemType {
    _id
    name
  }
`;

export const GET_ITEM_TYPES = gql`
  query itemTypes {
    itemTypes {
      ...ITEM_TYPES_ATTRIBUTES
    }
  }
  ${ITEM_TYPES_ATTRIBUTES}
`;

export const GET_ITEM_TYPE_BY_ID = gql`
  query itemType($itemTypeId: String!) {
    itemType(_id: $itemTypeId) {
      ...ITEM_TYPES_ATTRIBUTES
    }
  }
  ${ITEM_TYPES_ATTRIBUTES}
`;
