import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import AutocompleteDropDown from '../Shared/AutocompleteDropDown';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  autoCompleteFiled: {
    margin: '15px 0px',
  },
}));

const UpdateAuthorizedParties = ({
  open,
  closeModal,
  loading,
  organizationList,
  addedAuthorizedParties,
  onUpdateAuthorizedPartiesHandler,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formikOrgAccess = useFormik({
    initialValues: {
      authorizedParties: addedAuthorizedParties,
    },
    onSubmit: async (values, { resetForm }) => {
      const { authorizedParties } = values;
      const updatedAuthorizedParties = [];

      authorizedParties.forEach(o => {
        const newlyAddedParty = addedAuthorizedParties.find(
          x => JSON.stringify(x.value) === JSON.stringify(o.value),
        );
        if (!newlyAddedParty) {
          updatedAuthorizedParties.push({
            myOrgHeaderId: o.value,
            organizationId: o.organizationId,
            role: o.role,
            address: o.address,
            action: 'NEW',
          });
        }
      });

      addedAuthorizedParties.forEach(x => {
        const checkForDeletedParties = authorizedParties.find(
          o => JSON.stringify(o.value) === JSON.stringify(x.value),
        );
        if (!checkForDeletedParties) {
          updatedAuthorizedParties.push({
            myOrgHeaderId: x.value,
            organizationId: x.organizationId,
            role: x.role,
            address: x.address,
            action: 'REMOVE',
          });
        }
      });

      await onUpdateAuthorizedPartiesHandler(updatedAuthorizedParties);
      resetForm();
    },
  });
  const disabled = !formikOrgAccess.dirty || !formikOrgAccess.isValid;
  return (
    <Modal className={classes.modal} open={open} onClose={closeModal}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('common.authorizedParties')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            formikOrgAccess.resetForm();
            closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formikOrgAccess}>
          <Form>
            <div className={classes.autoCompleteFiled}>
              <Field
                component={AutocompleteDropDown}
                isMultipleSelection
                onChange={formikOrgAccess.handleChange}
                name="authorizedParties"
                placeholder="Authorized Parties"
                options={organizationList}
                size="small"
                withCheckboxes
                disableCloseOnSelect
                startingValue={formikOrgAccess.values.authorizedParties}
                limitTags={2}
                testid="update-authorizedParties-dropdown"
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="update-authorizedParties-button"
              disabled={disabled || loading}
            >
              {t('common.updateAuthorizedParties')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

UpdateAuthorizedParties.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addedAuthorizedParties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdateAuthorizedPartiesHandler: PropTypes.func.isRequired,
};

export default UpdateAuthorizedParties;
