import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import uniqid from 'uniqid';
import VolumeMarker from './VolumeMarker';

const useStyles = makeStyles(() => ({
  progressContainer: {
    width: 'inherit',
    height: '1.125rem',
    backgroundColor: '#EAEAEE',
    borderRadius: 8,
    margin: '0 auto',
    top: '50%',
    marginTop: '-.5625rem',
    position: 'absolute',
  },
  bar: {
    height: '1.125rem',
    display: 'inline-block',
    position: 'absolute',
    borderRadius: 8,
  },
  text: {
    color: '#fff',
    fontSize: '12px',
    lineHeight: '18px',
    letter: '0.04px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  arrowIcon: {
    color: '#000000',
    marginLeft: '-25px',
    height: '18px',
    position: 'absolute',
    zIndex: 10,
  },
}));

const ProgressBar = props => {
  const classes = useStyles();
  const { tierBounds, accumulatedVolume, purchaseOrderVolume, rebateContract } = props;

  const calculatePercent = (tiers, current, min, max) => {
    const percent = Math.abs(current - min) / (max - min);
    const value = percent * Math.round(100 / tiers.length);
    return value > 100 ? 100 : value;
  };

  const findMax = (tiers, current) => {
    return tiers.find(number => number >= current) || tiers[tiers.length - 1];
  };
  const findMin = (tiers, current) => {
    let min = 0;
    for (let i = 0; i < tiers.length; i += 1) {
      if (tiers[i] >= current) break;
      min = tiers[i];
    }
    return min;
  };

  const generateBarValues = (total, accumulatedVol, tiers) => {
    const barValues = [];
    let accumulator = 0;
    for (let i = 0; i < tiers.length; i += 1) {
      if (i === 0) {
        barValues.push(accumulatedVol);
        accumulator += accumulatedVol;
      }
      if (tiers[i] > accumulatedVol && tiers[i] < total) {
        const current = tiers[i] - accumulator;
        barValues.push(current);
        accumulator += current;
      }
      if (tiers[i] > total) {
        barValues.push(total - accumulator);
        break;
      }
      if (i === tiers.length - 1 && tiers[i] <= total) {
        barValues.push(total - accumulator);
        break;
      }
    }

    return barValues;
  };

  const createBars = (tiers, accumulatedVol, purchaseOrderVol, rebate) => {
    const colors = ['#155CB4', '#56AE7D', '#FAB17D', '#DDD3EE'];
    const markerColors = ['#168736', '#FF6D00', '#724BC3'];
    const total = accumulatedVol + purchaseOrderVol;
    const barValues = generateBarValues(total, accumulatedVol, tiers);

    let widthAccumulator = 0;
    let leftAccumulator = 0;
    let current;
    let finalBar = false;
    const bars = barValues.map((bar, i) => {
      if (bar === accumulatedVol && i === 0) {
        if (bar === 0) {
          current = 0;
        } else if (bar > tiers[tiers.length - 1]) {
          const min = findMin(tiers, bar);
          const minTierIndex = tiers.indexOf(min);
          widthAccumulator = Math.round(100 / tiers.length) * minTierIndex;
          widthAccumulator +=
            calculatePercent(tiers, bar, min, findMax(tiers, bar)) -
            100 +
            Math.round(100 / tiers.length) / 4;
          current = accumulatedVol;
        } else {
          const min = findMin(tiers, bar);
          const minTierIndex = tiers.indexOf(min);
          widthAccumulator = Math.round(100 / tiers.length) * minTierIndex;
          widthAccumulator += calculatePercent(tiers, bar, min, findMax(tiers, bar));
          current = accumulatedVol;
        }
      } else if (bar + current > tiers[tiers.length - 1]) {
        leftAccumulator += bar === 0 ? widthAccumulator : widthAccumulator - 2;
        widthAccumulator = bar === 0 ? 0 : Math.round(100 / tiers.length) / 4 + 2;
        current += bar;
        finalBar = true;
      } else {
        const width = barValues[0] === 0 ? widthAccumulator : widthAccumulator - 2;
        leftAccumulator += width;
        const min = findMin(tiers, bar + current);
        const widthPercentage = calculatePercent(
          tiers,
          bar + min,
          min,
          bar + current === total ? findMax(tiers, bar + current) : bar + current,
        );
        widthAccumulator = barValues[0] === 0 ? widthPercentage : widthPercentage + 2;
        current += bar;
      }

      let height = '';

      if (rebate === true && purchaseOrderVol === 0) {
        height = current === total ? '120px' : '70px';
      } else {
        height = current === total ? '98px' : '62px';
      }
      const markerStyle = {
        value: bar,
        style: {
          background:
            i === 0
              ? colors[i]
              : `repeating-linear-gradient(120deg, ${colors[i]} 7px, ${colors[i]} 10px, #747480 1px, #747480 10.5px)`,
          left: i === 0 ? 0 : `${leftAccumulator}%`,
          width: `${widthAccumulator}%`,
          zIndex: tiers.length - i,
        },
        markerStyles: {
          background: colors[i] || 'none',
          color: markerColors[i - 1],
          left: `${leftAccumulator + widthAccumulator}%`,
          zIndex: tiers.length + i,
          height: height,
        },
        arrowStyle: {
          left: `${leftAccumulator + widthAccumulator}%`,
          zIndex: tiers.length + i + 1,
        },
        final: finalBar,
      };

      return markerStyle;
    });
    return bars;
  };

  const bars = createBars(tierBounds, accumulatedVolume, purchaseOrderVolume, rebateContract);
  const nextTier = tierBounds.find(tier => tier > accumulatedVolume);
  let quantitiesNeeded = 0;
  if (nextTier && nextTier > 0) {
    quantitiesNeeded = nextTier - accumulatedVolume;
  }
  return (
    <div className={classes.progressContainer}>
      {bars.map((bar, i) => {
        if (i === 0) {
          return (
            <div key={uniqid()} className={classes.bar} style={bar.style}>
              {rebateContract ? (
                <Typography
                  className={classes.text}
                >{`${accumulatedVolume} quantity ordered`}</Typography>
              ) : (
                <Typography
                  className={classes.text}
                >{`${accumulatedVolume} units ordered`}</Typography>
              )}
            </div>
          );
        }
        if (bar.final) {
          return (
            <React.Fragment key={uniqid()}>
              <div className={classes.bar} style={bar.style} />
              <ArrowForwardIcon className={classes.arrowIcon} style={bar.arrowStyle} />
              {rebateContract ? (
                <VolumeMarker
                  markerStyles={bar.markerStyles}
                  label={bar.value}
                  rebateContract
                  final
                  purchaseOrderFound={purchaseOrderVolume > 0}
                />
              ) : (
                <VolumeMarker markerStyles={bar.markerStyles} label={bar.value} />
              )}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={uniqid()}>
            <div className={classes.bar} style={bar.style} />
            {rebateContract ? (
              <>
                {i === bars.length - 1 && (
                  <ArrowForwardIcon className={classes.arrowIcon} style={bar.arrowStyle} />
                )}
                <VolumeMarker
                  markerStyles={bar.markerStyles}
                  label={
                    i === bars.length - 1 && purchaseOrderVolume === 0
                      ? quantitiesNeeded
                      : bar.value
                  }
                  final={i === bars.length - 1 && purchaseOrderVolume === 0}
                  rebateContract
                />
              </>
            ) : (
              <>
                <VolumeMarker
                  markerStyles={bar.markerStyles}
                  label={bar.value}
                  purchaseOrderFound={purchaseOrderVolume > 0}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  tierBounds: PropTypes.arrayOf(PropTypes.number).isRequired,
  accumulatedVolume: PropTypes.number.isRequired,
  purchaseOrderVolume: PropTypes.number.isRequired,
  rebateContract: PropTypes.bool,
};

ProgressBar.defaultProps = {
  rebateContract: false,
};

export default ProgressBar;
