import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import TokenContent from './TokenContent';
import track from '../../mixpanel';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import useUserInfo from '../../hooks/useUserInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  TokenPanelRoot: {
    padding: theme.spacing(1, 0),
    minWidth: '60%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    minHeight: theme.spacing(60),
  },
  TokenPanelBox: {
    overflow: 'auto',
  },
  contractHeader: {
    fontWeight: '400',
  },
  labelHeader: {
    fontWeight: '100',
    marginRight: theme.spacing(2),
  },
  navButton: {
    color: theme.palette.info.main,
  },
  closeIcon: {
    marginRight: -8,
  },
  buttonSpace: {
    marginRight: theme.spacing(2),
  },
}));

const TokenPanel = ({
  selectedToken,
  selectedContract,
  handlePanelClose,
  handleTokenClose,
  handleTransferClick,
  handleBurnClick,
  tokenList,
  isBuyer,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { visualizerDashboardAccess } = useSubscriptionContext();
  const {
    permissionsFlags: {
      isUserAuthToViewERC721History,
      isUserAuthToTransferERC721,
      isUserAuthToViewERC1155History,
    },
  } = useUserInfo();

  const isERC1155 =
    selectedContract?.contract?.tokenType === CONSTANTS?.SMARTCONTRACT_TYPES.ERC1155;

  const isUserAuthToViewHistory = isERC1155
    ? isUserAuthToViewERC1155History
    : isUserAuthToViewERC721History;

  const { activeWallet } = useBecOrganizationContext();

  return (
    <>
      <Card variant="outlined" className={classes.TokenPanelRoot}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} px={2}>
          <Button
            variant="text"
            color="primary"
            onClick={handleTokenClose}
            className={classes.navButton}
            startIcon={<ArrowBackIosIcon />}
          >
            {t('tokens.tokenList')}
          </Button>
          <IconButton onClick={handlePanelClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          overflow="auto"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          flexBasis={0}
          px={2}
        >
          <Box pl={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" className={classes.contractHeader}>
                {selectedToken?.tokenId}
              </Typography>
              {visualizerDashboardAccess && isUserAuthToViewHistory && (
                <Button
                  variant="text"
                  color="primary"
                  target="_blank"
                  onClick={() => {
                    track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.VIEW_HISTORY, {
                      blockchainNetwork: activeWallet?.network,
                      contractId: selectedToken?.smartContract?._id,
                      tokenType: selectedToken?.tokenType,
                    });
                  }}
                  href={`${window.config.vizualizerTokensUrl}${selectedToken?.tokenId}/dashboard?sc=${selectedToken?.smartContract?.contractAddress}`}
                  className={classes.navButton}
                  endIcon={<OpenInNewIcon />}
                >
                  {t('tokens.explore')}
                </Button>
              )}
            </Box>
            {selectedToken?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155 && (
              <Box mt={1} display="flex" alignItems="center">
                <Typography variant="body2" className={classes.labelHeader}>
                  {t('tokens.balance')}:
                </Typography>
                <Typography variant="body2">{selectedToken?.owners1155[0]?.balance}</Typography>
              </Box>
            )}
            <Box mt={1} display="flex" alignItems="center">
              <Typography variant="body2" className={classes.labelHeader}>
                {t('tokens.tokenContract')}:
              </Typography>
              <Typography variant="body2">{selectedToken?.smartContract?.contractName}</Typography>
            </Box>
            {!isBuyer && (
              <Box mt={3} display="flex">
                {isUserAuthToTransferERC721 && (
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleTransferClick}
                      startIcon={<CallMadeIcon />}
                      className={classes.buttonSpace}
                    >
                      {t('tokens.transfer')}
                    </Button>
                  </Box>
                )}
                {/* need to implement the checek for burn permission */}
                {isUserAuthToTransferERC721 && (
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleBurnClick}
                      startIcon={<DeleteForeverIcon />}
                      className={classes.buttonSpace}
                    >
                      {t('tokens.burn')}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <TokenContent
            selectedToken={selectedToken}
            selectedContract={selectedContract}
            tokenList={tokenList}
          />
        </Box>
      </Card>
    </>
  );
};

TokenPanel.propTypes = {
  selectedToken: PropTypes.shape({
    smartContract: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      contractAddress: PropTypes.string.isRequired,
      contractName: PropTypes.string.isRequired,
    }),
    metadata: PropTypes.shape({}),
    tokenId: PropTypes.string.isRequired,
    tokenType: PropTypes.string.isRequired,
    owners1155: PropTypes.arrayOf(
      PropTypes.shape({
        owner: PropTypes.string,
        balance: PropTypes.number,
      }),
    ).isRequired,
  }),
  selectedContract: PropTypes.shape({
    contract: PropTypes.shape({
      tokenType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handlePanelClose: PropTypes.func.isRequired,
  handleTokenClose: PropTypes.func.isRequired,
  handleTransferClick: PropTypes.func.isRequired,
  handleBurnClick: PropTypes.func.isRequired,
  tokenList: PropTypes.arrayOf(PropTypes.shape({})),
  isBuyer: PropTypes.bool,
};

TokenPanel.defaultProps = {
  selectedToken: {},
  tokenList: [],
  isBuyer: false,
};

export default TokenPanel;
