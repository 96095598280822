import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReactJson from 'react-json-view';
import { saveAs } from 'file-saver';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { getPrettifiedEthAddr } from '../../utils';
import { useNotification } from '../../contexts/Shared/notification';
import {
  GET_SINGLE_TOKEN_CONTRACT_DETAILS,
  PUBLIC_TOKEN_METADATA,
  PRIVATE_TOKEN_METADATA,
  TOKEN_URI,
} from '../../graphql/Tokenization/token';
import { CONSTANTS, METADATA_STRUCTURE_DATA_MUTABILITY } from '../../constants';
import PageLoader from '../../components/Shared/PageLoader';
import { hashAsHex } from '../../utils/crypto';

const MetadataContentPage = () => {
  const smallScreen = useMediaQuery('(max-width:400px)');

  const useStyles = makeStyles(theme => ({
    metaArea: {
      marginLeft: theme.spacing(2),
    },
    addrArea: {
      display: 'flex',
      alignItems: 'center',
    },
    metadataWrapper: {
      margin: theme.spacing(4),
    },
    link: {
      display: 'flex',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
      marginBottom: theme.spacing(4),
    },
    divider: {
      background: '#d4d4ce',
      width: '100%',
      margin: '0 auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    contractName: {
      marginTop: theme.spacing(1),
    },
    tokenId: {
      textTransform: 'uppercase',
      color: theme.palette.primary.light,
    },
    poster: {
      margin: smallScreen ? `${theme.spacing(1)} 0px 36px 0px` : `${theme.spacing(1)} 36px 36px`,
    },
    innerPoster: {
      border: '1px solid',
      color: theme.palette.primary.main,
      position: 'relative',
    },
    container: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    downloadButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    downloadIcon: {
      marginRight: '10px',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    description: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    immutableChip: {
      marginLeft: theme.spacing(1),
      marginRight: '10px',
      borderColor: '#F5DFDD',
      backgroundColor: '#F5DFDD',
    },
    mutableChip: {
      marginRight: '10px',
      borderColor: '#DCEDE1',
      backgroundColor: '#DCEDE1',
    },
    perChip: {
      marginLeft: '10px',
      marginRight: '10px',
      borderColor: '#DCE7F4',
      backgroundColor: '#DCE7F4',
    },
    metadataTitleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    metadataHashContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    metadataHashTitle: {
      marginRight: theme.spacing(1),
    },
    spinner: {
      marginLeft: theme.spacing(2),
    },
    metadataCheck: {
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    successIcon: {
      marginRight: '10px',
      color: theme.palette.success.main,
    },
    failedIcon: {
      marginRight: '10px',
      color: theme.palette.error.main,
    },
    metadataFailure: {
      color: theme.palette.primary.light,
      padding: theme.spacing(3),
      textAlign: 'center',
    },
    tokenRetrieveFailure: {
      color: theme.palette.primary.light,
      padding: theme.spacing(5),
      textAlign: 'center',
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const { contractAddress, mutability, tokenId, fileName, restriction } = useParams();
  const { handleNotification } = useNotification();
  const [metadataRetrieveFailure, setMetadataRetrieveFailure] = useState(false);
  const [tokenRetrieveFailure, setTokenRetrieveFailure] = useState(false);

  const TOKEN_METADATA_QUERY =
    restriction === CONSTANTS.RESTRICTIONS.PUBLIC ? PUBLIC_TOKEN_METADATA : PRIVATE_TOKEN_METADATA;

  const [
    getTokenMetadata,
    { loading: isTokenMetadataLoading, data: tokenMetadata = {} },
  ] = useLazyQuery(TOKEN_METADATA_QUERY, {
    onError: () => {
      handleNotification(t('tokens.tokenMetadataError'), 'error');
      setMetadataRetrieveFailure(true);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getTokenMetadata({
      variables: {
        contractAddress,
        tokenId,
        mutability,
      },
    });
  }, [getTokenMetadata]);

  const [
    getSingleTokenContractInDetails,
    { loading: isTokenLoading, data: contractData = {} },
  ] = useLazyQuery(GET_SINGLE_TOKEN_CONTRACT_DETAILS, {
    onError: () => {
      handleNotification(t('tokens.contractDataError'), 'error');
      setTokenRetrieveFailure(true);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getSingleTokenContractInDetails({
      variables: {
        contractAddress,
      },
    });
  }, [getSingleTokenContractInDetails]);

  const [getTokenURI, { loading: isTokenUriLoading, data: dataOnChain }] = useLazyQuery(TOKEN_URI, {
    onError: () => {
      handleNotification(t('tokens.contractDataError'), 'error');
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getTokenURI({
      variables: {
        contractAddress: contractAddress,
        tokenId,
      },
    });
  }, [getTokenURI]);

  const metadataKey =
    restriction === CONSTANTS.RESTRICTIONS.PUBLIC
      ? CONSTANTS.METADATA_CONTENT_PAGE.PUBLIC_METADATA
      : CONSTANTS.METADATA_CONTENT_PAGE.PRIVATE_METADATA;

  const contractName = contractData?.getSingleTokenContractInDetails?.tokenName;
  const metadata = tokenMetadata[metadataKey]?.metadata ? tokenMetadata[metadataKey]?.metadata : {};
  const metadataHash = metadata ? hashAsHex(JSON.stringify(metadata)) : '';

  let isFileNameMatchedWithHashOnChain = false;
  let isDataInDbMatchedWithHashOnChain = false;
  let hashValueInFileName = fileName;

  if (mutability === METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE) {
    hashValueInFileName = fileName.replace('.json', '');
    const tokenUri = dataOnChain?.tokenURI?.tokenUri;

    if (tokenUri && metadata) {
      if (tokenUri.includes('{')) {
        const { privateImmutable, publicImmutable } = JSON.parse(tokenUri);
        if (privateImmutable === window.location.href || publicImmutable === window.location.href) {
          isFileNameMatchedWithHashOnChain = true;
          if (metadataHash === hashValueInFileName) {
            isDataInDbMatchedWithHashOnChain = true;
          }
        }
      } else if (hashValueInFileName === tokenUri) {
        isFileNameMatchedWithHashOnChain = true;
        if (metadataHash === hashValueInFileName) {
          isDataInDbMatchedWithHashOnChain = true;
        }
      }
    }
  }

  const downloadButton = (
    <Typography
      variant="body2"
      className={classes.downloadButton}
      onClick={() => {
        const blob = new Blob([JSON.stringify(metadata)], {
          type: 'text/json;charset=utf-8',
        });
        saveAs(blob, fileName);
      }}
    >
      <CloudDownloadIcon className={classes.downloadIcon} />
      {t('tokens.downloadMetadata')}
    </Typography>
  );

  const metadataContainer = () => (
    <div className={classes.metadataWrapper}>
      <Typography variant="h3" className={classes.contractName}>
        {contractName}
      </Typography>
      <div className={classes.metaAreaClass}>
        <div className={classes.addrArea}>
          {contractAddress && (
            <>
              <Typography variant="subtitle1">{contractAddress}</Typography>
              <CopyContent content={contractAddress} />
            </>
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
      <Typography variant="h4" className={classes.tokenId}>
        {t('tokens.tokenId')}
      </Typography>
      <div className={classes.metaAreaClass}>
        <div className={classes.addrArea}>
          {tokenId && (
            <>
              <Typography variant="subtitle1">
                {tokenId.length > CONSTANTS.METADATA_CONTENT_PAGE.MAX_TOKENID_LENGTH
                  ? getPrettifiedEthAddr(tokenId)
                  : tokenId}
              </Typography>
              <CopyContent content={tokenId} />
            </>
          )}
        </div>
        <Typography variant="body1" className={classes.description}>
          {t('tokens.metadataDescription')}
        </Typography>
      </div>
      <Box className={classes.posterWrapper}>
        <div className={classes.metadataTitleContainer}>
          <Typography variant="h6" className={classes.metadataTitle}>
            {t('tokens.tokenMetadata')}
          </Typography>
          <Chip
            className={classes.perChip}
            label={
              restriction === CONSTANTS.RESTRICTIONS.PUBLIC
                ? t('tokens.metaDataStructure.permissions.unrestricted')
                : t('tokens.metaDataStructure.permissions.restricted')
            }
          />
          <Chip
            color="secondary"
            className={
              mutability === METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE
                ? classes.mutableChip
                : classes.immutableChip
            }
            label={t(`tokens.metaDataStructure.dataMutability.${mutability}`)}
          />
          {mutability === METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE && isTokenUriLoading && (
            <CircularProgress className={classes.spinner} size={30} thickness={20} />
          )}
        </div>
      </Box>
      {mutability === METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE &&
        isFileNameMatchedWithHashOnChain && (
          <div className={classes.metadataHashContainer}>
            <Typography variant="body2" className={classes.metadataHashTitle}>
              {t('tokens.metadataHash')}
            </Typography>
            <Typography variant="body2">{hashValueInFileName}</Typography>
            <CopyContent content={contractAddress} />
          </div>
        )}
      {metadataRetrieveFailure ? (
        <Box className={classes.poster}>
          <Box className={classes.innerPoster}>
            <Typography variant="h4" className={classes.metadataFailure}>
              {t('tokens.tokenMetadataErrorExtended')}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box className={classes.poster}>
            <Box className={classes.innerPoster}>
              <Grid container className={classes.container}>
                <Grid item xs={9}>
                  <ReactJson src={metadata} displayDataTypes={false} name="metadata" />
                </Grid>
              </Grid>
              <Paper className={classes.paper}>{downloadButton}</Paper>
            </Box>
          </Box>
          {mutability === METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE && (
            <Typography variant="body2" className={classes.metadataCheck}>
              {isDataInDbMatchedWithHashOnChain ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <ErrorIcon className={classes.failedIcon} />
              )}
              {isDataInDbMatchedWithHashOnChain
                ? t('tokens.onChainAndOffchainDataMatched')
                : t('tokens.onChainAndOffchainDataNotMatched')}
            </Typography>
          )}
        </>
      )}
    </div>
  );

  if (tokenRetrieveFailure) {
    return (
      <Box className={classes.metadataWrapper}>
        <Typography variant="h2" className={classes.tokenRetrieveFailure}>
          {t('tokens.contractDataErrorExtended')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.metadataWrapper}>
      {isTokenMetadataLoading || isTokenLoading ? <PageLoader /> : metadataContainer}
    </Box>
  );
};

export default MetadataContentPage;
