import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  METADATA_STRUCTURE_DATA_MUTABILITY,
  METADATA_STRUCTURE_PERMISSIONS,
} from '../../../constants';
import { isLocationDataType, isMeasurementDataType } from '../../../utils';

const useStyles = makeStyles(theme => ({
  accordionBody: {
    background: theme.palette.primary.contrastText,
    padding: '0px',
  },
  customAccordionRoot: {
    backgroundColor: theme.palette.primary.lightest,
    '& ::before': {
      background: 'none',
    },
    padding: '0px',
  },
  headerAccordion: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  itemsFormRoot: {
    width: '100%',
  },
  accordionBodyRow: {
    width: '100%',
    display: 'flex',
  },
  selectField: {
    minWidth: '10%',
    width: '100%',
    margin: '10px',
  },
  smallField: {
    width: '50px',
    minWidth: '10%',
    margin: '10px',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '20%',
  },
  accordionSummary: {
    padding: '0px',
  },
  errorHeader: {
    color: theme.palette.error.main,
  },
}));

const MetaDataItems = ({
  field: { name, value: metadataRow },
  form: { errors, setFieldValue },
  index,
  handleDelete,
  metaDataTypes,
  measurementOptions,
}) => {
  const [controlVisible, setControlVisible] = useState(
    isMeasurementDataType(metadataRow.metadataType, metaDataTypes),
  );
  const [disableMetaDataName, setDisableMetaDataName] = useState(
    isLocationDataType(metadataRow.metadataType, metaDataTypes),
  );
  const classes = useStyles();
  const buildOptions = option => (
    <MenuItem key={option.value} value={option.value} type={option.key}>
      {option.label}
    </MenuItem>
  );

  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const parentArrayName = name && name.split('.')[0];

  let currItemErrors;
  currItemErrors = null;
  const { t } = useTranslation();

  const permissions = [
    {
      label: t('tokens.metaDataStructure.permissions.unrestricted'),
      value: METADATA_STRUCTURE_PERMISSIONS.UNRESTRICTED,
    },
    {
      label: t('tokens.metaDataStructure.permissions.restricted'),
      value: METADATA_STRUCTURE_PERMISSIONS.RESTRICTED,
    },
  ];
  const mutability = [
    {
      label: t('tokens.metaDataStructure.dataMutability.mutable'),
      value: METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE,
    },
    {
      label: t('tokens.metaDataStructure.dataMutability.immutable'),
      value: METADATA_STRUCTURE_DATA_MUTABILITY.IMMUTABLE,
    },
  ];

  if (!isEmpty(errors) && errors[parentArrayName]) {
    currItemErrors = errors[parentArrayName][index];
  }
  const headerClassName = clsx({ [classes.errorHeader]: !!currItemErrors });

  const fieldNames = {
    metadataName: `metadata-${index}-metadataName`,
    metadataType: `metadata-${index}-metadataType`,
    metadataPermission: `metadata-${index}-metadataPermission`,
    metadataMutability: `metadata-${index}-metadataMutability`,
    chosenMetadataOption: `metadata-${index}-chosenMetadataOption`,
  };

  const metadataChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    if (!modifiedField) {
      throw new Error('Invalid field or value');
    }
    let isLocation = false;
    switch (modifiedField) {
      case fieldNames.metadataName:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...metadataRow,
          metadataName: newValue,
          dirty: true,
        });
        break;
      case fieldNames.metadataType:
        isLocation = isLocationDataType(newValue, metaDataTypes);
        setFieldValue(`${parentArrayName}.${index}`, {
          ...metadataRow,
          ...(isLocation && {
            metadataName: 'location',
          }),
          metadataType: newValue,
          dirty: true,
        });
        setDisableMetaDataName(isLocation);
        if (isMeasurementDataType(newValue, metaDataTypes)) {
          setControlVisible(true);
        } else {
          setControlVisible(false);
        }
        break;
      case fieldNames.chosenMetadataOption:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...metadataRow,
          chosenMetadataOption: newValue,
          dirty: true,
        });
        break;
      case fieldNames.metadataPermission:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...metadataRow,
          metadataPermission: newValue,
          dirty: true,
        });
        break;
      case fieldNames.metadataMutability:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...metadataRow,
          metadataMutability: newValue,
          dirty: true,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customAccordionRoot}>
      <Accordion
        elevation={0}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          classes={{ root: classes.accordionSummary }}
          expandIcon={<ExpandMoreIcon />}
          id={index}
        >
          <div className={classes.headerAccordion}>
            <Typography variant="body1" className={headerClassName}>
              {t('tokens.metadataLabel') + (index + 1)}
            </Typography>
            <DeleteIcon
              onClick={() => {
                handleDelete(index);
              }}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <div className={classes.itemsFormRoot}>
              <div className={classes.accordionBodyRow}>
                <TextField
                  name={fieldNames.metadataName}
                  label={t('tokens.metadataNameLabel')}
                  size="small"
                  variant="outlined"
                  value={metadataRow.metadataName}
                  onChange={metadataChangeHandler}
                  error={currItemErrors && !!currItemErrors.metadataName}
                  helperText={currItemErrors && currItemErrors.metadataName}
                  classes={{
                    root: classes.selectField,
                  }}
                  InputProps={{
                    classes: {
                      input: classes.itemNameInput,
                    },
                  }}
                  disabled={disableMetaDataName}
                />

                <TextField
                  select
                  size="small"
                  variant="outlined"
                  label={t('tokens.dataTypeLabel')}
                  name={fieldNames.metadataType}
                  onChange={metadataChangeHandler}
                  error={currItemErrors && !!currItemErrors.metadataType}
                  helperText={currItemErrors && currItemErrors.metadataType}
                  disabled={!metaDataTypes?.length}
                  value={metadataRow?.metadataType}
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  {metaDataTypes?.map(buildOptions)}
                </TextField>
                {controlVisible && (
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    label=""
                    name={fieldNames.chosenMetadataOption}
                    onChange={metadataChangeHandler}
                    error={currItemErrors && !!currItemErrors.chosenMetadataOption}
                    helperText={currItemErrors && currItemErrors.chosenMetadataOption}
                    disabled={!measurementOptions?.length}
                    value={metadataRow?.chosenMetadataOption}
                    classes={{
                      root: classes.smallField,
                    }}
                  >
                    {measurementOptions.map(buildOptions)}
                  </TextField>
                )}
              </div>
              <div className={classes.accordionBodyRow}>
                <TextField
                  select
                  size="small"
                  variant="outlined"
                  label={t('tokens.permissionLabel')}
                  name={fieldNames.metadataPermission}
                  onChange={metadataChangeHandler}
                  error={currItemErrors && !!currItemErrors.metadataPermission}
                  helperText={currItemErrors && currItemErrors.metadataPermission}
                  disabled={!permissions?.length}
                  value={metadataRow.metadataPermission}
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  {permissions.map(buildOptions)}
                </TextField>
                <TextField
                  select
                  size="small"
                  variant="outlined"
                  label={t('tokens.dataMutabilityLabel')}
                  name={fieldNames.metadataMutability}
                  onChange={metadataChangeHandler}
                  error={currItemErrors && !!currItemErrors.metadataMutability}
                  helperText={currItemErrors && currItemErrors.metadataMutability}
                  disabled={!mutability?.length}
                  value={metadataRow.metadataMutability}
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  {mutability.map(buildOptions)}
                </TextField>
              </div>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

MetaDataItems.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({}),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  metaDataTypes: PropTypes.arrayOf(PropTypes.shape({})),
  measurementOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

MetaDataItems.defaultProps = {
  metaDataTypes: null,
  measurementOptions: null,
};

export default MetaDataItems;
