import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  dragBoardRoot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dragItemRoot: {
    background: theme.palette.primary.contrastText,
    padding: '1em',
    margin: '0.15em',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dragHandle: {
    padding: '0.25em',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '7%',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightest,
    },
  },
  dragHoverDisabled: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  dragIcon: {
    alignSelf: 'center',
  },
  childrenArea: {
    justifyContent: 'center',
    width: '100%',
  },
  dragActive: {
    backgroundColor: theme.palette.secondary.lightest,
  },
  dragError: {
    backgroundColor: theme.palette.primary.lighter,
  },
  upArrow: {
    marginBottom: '-0.6em',
  },
  downArrow: {
    marginTop: '-0.6em',
  },
}));

const DragItem = ({ children, itemName, index, isDragDisabled, totalItemCount }) => {
  const classes = useStyles();

  const dragIconColor = isDragDisabled ? 'disabled' : 'primary';

  return (
    <Draggable draggableId={`drag-item-${itemName}`} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => {
        const displayUpArrow = index > 0 && !snapshot?.isDragging;
        const displayDownArrow = index < totalItemCount - 1 && !snapshot?.isDragging;
        const dragHandleClass = clsx(classes.dragHandle, {
          [classes.dragHoverDisabled]: isDragDisabled,
        });

        return (
          <Paper
            className={classes.dragItemRoot}
            ref={provided.innerRef}
            {...provided.draggableProps}
            elevation={0}
          >
            <div className={classes.childrenArea}>{children}</div>

            <div className={dragHandleClass} {...provided.dragHandleProps}>
              {displayUpArrow && <ArrowDropUpIcon classes={{ root: classes.upArrow }} />}
              <DragHandleOutlinedIcon color={dragIconColor} className={classes.dragIcon} />
              {displayDownArrow && <ArrowDropDownIcon classes={{ root: classes.downArrow }} />}
            </div>
          </Paper>
        );
      }}
    </Draggable>
  );
};

DragItem.propTypes = {
  isDragDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  itemName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  totalItemCount: PropTypes.number.isRequired,
};

DragItem.defaultProps = {
  isDragDisabled: false,
};

const DragBoard = ({
  boardItems,
  boardName,
  onChange,
  onStartDrag,
  refDataLoading,
  loadingMessage,
  noItemsMessage,
  isDropDisabled,
}) => {
  const classes = useStyles();
  const [isDragActive, setDragActive] = useState(false);

  const dragRoot = clsx(
    classes.dragBoardRoot,
    { [classes.dragActive]: isDragActive },
    { [classes.dragError]: isDropDisabled },
  );

  if (refDataLoading) {
    return <div className={classes.dragBoardRoot}>{loadingMessage}</div>;
  }

  if (isEmpty(boardItems)) {
    return <div className={classes.dragBoardRoot}>{noItemsMessage}</div>;
  }

  const dragStartHandler = dragStartResult => {
    setDragActive(true);

    const { source } = dragStartResult;
    if (source === null || source === undefined) return;
    const { index: sourceIndex } = source;
    if (sourceIndex === null || sourceIndex === undefined) return;

    if (onStartDrag) {
      onStartDrag(sourceIndex, dragStartResult);
    }
  };

  const dragEndHandler = dragResult => {
    setDragActive(false);

    const { source, destination } = dragResult;
    if (
      source === null ||
      source === undefined ||
      destination === null ||
      destination === undefined
    )
      return;

    const { index: sourceIndex } = source;
    const { index: destIndex } = destination;
    if (
      sourceIndex === null ||
      sourceIndex === undefined ||
      destIndex === null ||
      destIndex === undefined
    )
      return;

    [...boardItems].splice(destIndex, 0, [...boardItems].splice(sourceIndex, 1));
    onChange(boardItems, dragResult);
  };

  return (
    <DragDropContext onDragEnd={dragEndHandler} onDragStart={dragStartHandler}>
      <Droppable droppableId={`drag-board-${boardName}`} isDropDisabled={isDropDisabled}>
        {provided => (
          <div className={dragRoot} {...provided.droppableProps} ref={provided.innerRef}>
            {boardItems.map((item, index) => (
              <DragItem
                key={item.keyValue}
                index={index}
                itemName={item.name}
                isDragDisabled={item.isDragDisabled}
                totalItemCount={boardItems.length}
              >
                {item.component}
              </DragItem>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragBoard.propTypes = {
  /**
   * When true, items can still be dragged but dropping them on the board does not trigger a reorder
   */
  isDropDisabled: PropTypes.bool,
  /**
   * Board items can be any valid React component, a name&keyValue need to mandatorily provided. isDragDisabled can be used to disable the drag handle on individual pieces. Note that this does not lock the items' position, other items can still be dragged into it's slot.
   */
  boardItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      keyValue: PropTypes.string.isRequired,
      isDragDisabled: PropTypes.bool,
    }),
  ),
  /**
   * Identifier for the board
   */
  boardName: PropTypes.string.isRequired,
  /**
   * DragBoard is a controlled component that needs two-way binding, implement setState calls for the boardItems prop here. onChange receives two arguments:
   *  tempBoardRows - the updated list of items
   *  dragResult    - the drag object exposed by the framework
   */
  onChange: PropTypes.func.isRequired,
  /**
   * onStartDrag receives two arguments:
   *  sourceIndex   - index of the element being dragged
   *  dragStartResult    - the drag object exposed by the framework
   */
  onStartDrag: PropTypes.func,
  /**
   * Boolean used to control the loading placeholder in the list
   */
  refDataLoading: PropTypes.bool,
  /**
   * Loading message for the board
   */
  loadingMessage: PropTypes.string,
  /**
   * No Items message for the board
   */
  noItemsMessage: PropTypes.string,
};

DragBoard.defaultProps = {
  isDropDisabled: false,
  onStartDrag: null,
  refDataLoading: false,
  loadingMessage: '',
  noItemsMessage: '',
  boardItems: {
    isDragDisabled: false,
  },
};

export default DragBoard;
