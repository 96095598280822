import React, { useState, useEffect } from 'react';
import FileDropZone from '@eyblockchain/ey-ui/core/FileDropZone';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUITextField from '@material-ui/core/TextField';
import { Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { createHashFromFile, getBlockchainExplorerUrl } from '../../../utils';
import {
  NOTARIZE_DOCUMENT_ETH,
  NOTARIZE_DOCUMENT_BCOS,
  CHECK_DOCUMENT_EXIST_ETH,
  CHECK_DOCUMENT_EXIST_BCOS,
} from '../../../graphql/Notarization/documents';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import PageLoader from '../../Shared/PageLoader';
import { useNotification } from '../../../contexts/Shared/notification';
import { CONSTANTS } from '../../../constants';
import PaymentConfirmation from '../../Shared/PaymentConfirmation';
import { useSubscriptionContext } from '../../../contexts/Shared/subscription';
import useUserInfo from '../../../hooks/useUserInfo';
import track from '../../../mixpanel';

const useStyles = makeStyles(theme => ({
  navButton: {
    color: theme.palette.info.main,
  },
  docHeader: {
    marginTop: '1rem',
    fontWeight: '400',
  },
  docUploadDiv: {
    marginTop: '1rem',
  },
  checkField: {
    marginTop: '1rem',
    alignItems: 'center',
  },
  txtBoxField: {
    minWidth: '10%',
    width: '100%',
    margin: '5px',
  },
  decHeader: {
    marginTop: '1rem',
    fontWeight: '700',
  },
  progressHeader: {
    paddingTop: '6px',
    paddingLeft: '6px',
  },
  registerButton: {
    marginTop: '2rem',
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  verifyDocumentRoot: {
    display: 'flex',
  },
  verifyDocumentProgress: {
    marginTop: '2rem',
    display: 'flex',
    paddingTop: '8px',
  },
  verifyComplete: {
    marginTop: '2rem',
    display: 'flex',
    paddingTop: '5px',
    paddingLeft: '10px',
  },
  registeredHeader: {
    paddingTop: '10px',
  },
  registeredSubHeader: {
    paddingTop: '10px',
    fontSize: 'small',
  },
  verifiedMessageContainer: {
    display: 'flex',
    alignItems: 'baseline'
  }
}));

const DocumentUpload = () => {
  const { setDocumentUploadVisible, selectedNotarizationContract } = useNotarizationContext();
  const { storageAccess } = useSubscriptionContext();
  const { activeWallet } = useBecOrganizationContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const [loadProgress, setLoadProgress] = useState(-1);
  const { handleNotification } = useNotification();
  const [verifyComplete, setVerifyComplete] = useState(false);
  const [verified, setVerified] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState('');
  const [disableRedirectionUrl, setDisableRedirectionUrl] = useState(true);
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [generatedHash, setGeneratedHash] = useState('0x0000');
  const { permissionsFlags: { isUserAuthToVerifyDocuments, isUserAuthToNotarizeDocuments } } = useUserInfo();

  const [registerNotarizationDocumentEth, { loading: isDocumentUploadingEth }] = useMutation(
    NOTARIZE_DOCUMENT_ETH,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.REGISTER_DOCUMENT, {
          blockchainNetwork: data?.notarizeDocument?.blockchainNetwork,
          transactionStatus: data?.notarizeDocument?.transaction?.status,
          documentId: data?.notarizeDocument?._id,
          contractId: data?.notarizeDocument?.notarizationContract?._id,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.REGISTER_DOCUMENT, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );

  const [registerNotarizationDocumentBcos, { loading: isDocumentUploadingBcos }] = useMutation(
    NOTARIZE_DOCUMENT_BCOS,
  );

  const registerNotarizationDocument = async variables => {
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
      case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
        await registerNotarizationDocumentEth(variables);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        await registerNotarizationDocumentBcos(variables);
        break;
      }
      default:
    }
  };

  const [checkNotarizationEth, { loading: verifyProgressEth }] = useMutation(
    CHECK_DOCUMENT_EXIST_ETH,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.VERIFY_DOCUMENT, {
          blockchainNetwork: activeWallet?.network,
          contractId: selectedNotarizationContract?._id,
          verificationStatus: data?.checkNotarizationEth?.status,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.VERIFY_DOCUMENT, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );
  const [checkNotarizationBcos, { loading: verifyProgressBcos }] = useMutation(
    CHECK_DOCUMENT_EXIST_BCOS,
  );

  const verifyText = verified ? t('notarization.verified') : t('notarization.verify');

  const submitForm = async (values, { resetForm }) => {
    try {
      if (values) {
        const { fileToNotarize, description, uploadToBlob } = values;
        const regDocumentDetails = {
          filename: fileToNotarize?.name,
          description: description,
          ...(uploadToBlob && { fileToUpload: fileToNotarize }),
          hash: generatedHash,
          contractId: selectedNotarizationContract?._id,
          date: moment().unix(),
        };

        await registerNotarizationDocument({
          variables: {
            input: regDocumentDetails,
          },
        });
        handleNotification(t('notarization.documentSaveSuccess'), 'success');
        resetForm();
        setDocumentUploadVisible(false);
      }
    } catch (err) {
      handleNotification(t('notarization.documentSaveError'), 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      fileToNotarize: null,
      description: '',
      uploadToBlob: false,
    },
    onSubmit: submitForm,
  });

  const { values: formValues } = formik;

  useEffect(() => {
    async function setHash() {
      let hash = '0000';
      if (formValues.fileToNotarize) {
        hash = await createHashFromFile(formValues.fileToNotarize);
      }
      setGeneratedHash(`0x${hash}`);
    }
    setHash();
  }, [formValues]);

  const navBack = () => {
    setDocumentUploadVisible(false);
  };

  const valueChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    switch (modifiedField) {
      case 'uploadToBlob':
        formik.setFieldValue('uploadToBlob', event.target.checked);
        break;
      case 'description':
        formik.setFieldValue('description', newValue);
        break;
      case 'fileToNotarize':
        formik.setFieldValue('fileToNotarize', newValue);
        break;
      default:
        break;
    }
  };
  const handleFileUpload = async () => {
    setLoadProgress(100);
  };

  const resetForm = () => {
    setLoadProgress(0);
    setVerifyComplete(false);
    setVerified(false);
    formik.resetForm();
  };

  const handleCancelUpload = () => {
    resetForm();
  };

  const enableRedirection = () => {
    if (activeWallet?.network) {
      if (
        activeWallet?.network === CONSTANTS.BLOCKCHAIN_NETWORKS.MAINNET ||
        activeWallet?.network === CONSTANTS.BLOCKCHAIN_NETWORKS.ROPSTEN ||
        activeWallet?.network === CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_MAINNET ||
        activeWallet?.network === CONSTANTS.BLOCKCHAIN_NETWORKS.POLYGON_TESTNET
      ) {
        setDisableRedirectionUrl(false);
      }
    }
  };

  const verifyDocument = async () => {
    try {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
        case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
          const { data } = await checkNotarizationEth({
            variables: {
              documentHash: `0x${await createHashFromFile(formValues.fileToNotarize)}`,
              contractId: selectedNotarizationContract._id,
            },
          });
          setVerifyComplete(true);
          if (data?.checkNotarizationEth?.status === CONSTANTS.NOTARIZATION_STATUS.VERIFIED) {
            enableRedirection();
            setRedirectionUrl(
              `${getBlockchainExplorerUrl(activeWallet?.network)}/${data?.checkNotarizationEth?.transaction?.transactionHash
              }`,
            );
            setVerified(true);
          } else if (
            data?.checkNotarizationEth?.status === CONSTANTS.NOTARIZATION_STATUS.NOT_VERIFIED
          ) {
            setVerified(false);
          }
          break;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const { data } = await checkNotarizationBcos({
            variables: {
              documentHash: `0x${await createHashFromFile(formValues.fileToNotarize)}`,
              contractId: selectedNotarizationContract._id,
            },
          });
          setVerifyComplete(true);
          if (data?.checkNotarizationBcos?.status === CONSTANTS.NOTARIZATION_STATUS.VERIFIED) {
            enableRedirection();
            setRedirectionUrl(
              `${getBlockchainExplorerUrl(activeWallet?.network)}/${data?.checkNotarizationBcos?.transaction?.transactionHash
              }`,
            );
            setVerified(true);
          } else if (
            data?.checkNotarizationBcos?.status === CONSTANTS.NOTARIZATION_STATUS.NOT_VERIFIED
          ) {
            setVerified(false);
          }
          break;
        }
        default:
      }
    } catch (err) {
      handleNotification(t('notarization.validation.verificationError'), 'error');
    }
  };

  const uploadOnStorage = () => {
    return (
      <div className={classes.checkField}>
        <FormControlLabel
          control={
            <Checkbox
              name="uploadToBlob"
              checked={formValues.uploadToBlob}
              color="primary"
              onChange={valueChangeHandler}
            />
          }
          label={t('notarization.uploadDocument')}
        />
      </div>
    );
  };

  const registerDocumentView = () => {
    return (
      <div>
        {storageAccess && uploadOnStorage()}
        <div>
          <div>
            <Typography className={classes.decHeader}>
              {t('notarization.addDescription')}
            </Typography>
          </div>
          <div>
            <MUITextField
              name="description"
              label={t('tokens.writeHere')}
              size="small"
              variant="outlined"
              multiline
              rows={3}
              className={classes.txtBoxField}
              value={formValues.description}
              onChange={valueChangeHandler}
            />
          </div>
        </div>
        <div>
          <Button
            type="submit"
            className={classes.registerButton}
            variant="contained"
            color="secondary"
          >
            {t('notarization.register')}
          </Button>
        </div>
      </div>
    );
  };

  const verifyDocumentView = () => {
    return (
      <div className={classes.verifyDocumentRoot}>
        {!verifyComplete &&
          <div>
            <Button
              className={classes.registerButton}
              variant="contained"
              color="primary"
              onClick={verifyDocument}
              disabled={verifyComplete}
              data-testid="notarization-document-verifyButton"
            >
              {verifyText}
            </Button>
          </div>
        }
        {(verifyProgressEth || verifyProgressBcos) && (
          <div className={classes.verifyDocumentProgress}>
            <CircularProgress className={classes.spinner} size={30} thickness={20} />
            <Typography className={classes.progressHeader}>
              {t('notarization.verifyDocument')}
            </Typography>
          </div>
        )}
        {verifyComplete && verified && (
          <div className={classes.verifyComplete} data-testid="notarization-registeredHeader-text">
            <Box className={classes.verifiedMessageContainer}>
              <Typography className={classes.registeredHeader}>
                {t('notarization.documentRegistered')}
              </Typography>
              {!disableRedirectionUrl &&
                <Button
                  variant="text"
                  color="primary"
                  target="_blank"
                  href={redirectionUrl}
                  className={classes.navButton}
                  endIcon={<OpenInNewIcon />}
                >
                  {t('tokens.explore')}
                </Button>
              }
            </Box>
          </div>
        )}

        {verifyComplete && !verified && (
          <div className={classes.verifyComplete}>
            <Typography>{t('notarization.documentNotVerified')}</Typography>
          </div>
        )}
      </div>
    );
  };

  if (isDocumentUploadingEth || isDocumentUploadingBcos) {
    return (
      <Card variant="outlined">
        <PageLoader />
      </Card>
    );
  }

  const handleSubmit = e => {
    if (
      activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.ETH ||
      activeWallet.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.MATIC
    ) {
      e.preventDefault();
      setIsPaymentPopupOpen(true);
    } else {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <div>
      <div>
        <Button
          variant="text"
          color="primary"
          className={classes.navButton}
          startIcon={<ArrowBackIosIcon />}
          onClick={navBack}
          data-testid="notarization-document-back"
        >
          {t('notarization.back')}
        </Button>
      </div>
      <div>
        <Typography variant="h5" className={classes.docHeader}>
          {isUserAuthToNotarizeDocuments
            ? t('notarization.RegisterDocument')
            : t('notarization.VerifyDocument')}
        </Typography>
        <Typography className={classes.registeredSubHeader}>
          {isUserAuthToNotarizeDocuments
            ? t('notarization.verifyRegisterSubHeader')
            : t('notarization.verifySubHeader')}
        </Typography>
      </div>
      <div className={classes.docUploadDiv}>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Field
              component={FileDropZone}
              label={t('notarization.fileZoneLabel')}
              name="fileToNotarize"
              acceptedFormats=".png,.jpg,.jpeg,.docx,.pdf,.xls"
              secondaryText={t('notarization.fileUploadSecondaryText')}
              maxSizeInMB={2}
              onUpload={handleFileUpload}
              loadProgress={loadProgress}
              onCancelUpload={handleCancelUpload}
            />
            {isUserAuthToVerifyDocuments && loadProgress === 100 && <div>{verifyDocumentView()}</div>}
            {isUserAuthToNotarizeDocuments && verifyComplete && !verified && registerDocumentView()}
            <PaymentConfirmation
              open={isPaymentPopupOpen}
              closeModal={() => setIsPaymentPopupOpen(false)}
              contractAddress={selectedNotarizationContract?.contractAddress}
              contractName={CONSTANTS.SMARTCONTRACT_TYPES.NOTARIZATION}
              formik={formik}
              method={CONSTANTS.TRANSACTION_TYPES.SET_DOCUMENT}
              methodArgs={[generatedHash]}
            />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};
export default DocumentUpload;
