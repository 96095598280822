import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { AuthProvider } from './auth';
import ApolloClientProvider from './apollo';
import { RFQProvider } from '../Procurement/rfq';
import { ContractProvider } from '../Procurement/contract';
import { PurchaseOrderProvider } from '../Procurement/purchaseOrder';
import { TransactionProvider } from '../Procurement/transaction';
import { InvoiceProvider } from '../Procurement/invoice';
import { TokenProvider } from '../Tokenization/token';
import { PartnerProvider } from '../Procurement/partner';
import { OrganizationProvider } from '../Procurement/organization';

function AppProviders({ children }) {
  return (
    <ApolloClientProvider>
      <AuthProvider>
        {Cookies.get(`${window.config.cookiePrefix}id_token`) && (
          <PartnerProvider>
            <OrganizationProvider>
              <RFQProvider>
                <ContractProvider>
                  <PurchaseOrderProvider>
                    <InvoiceProvider>
                      <TokenProvider>
                        <TransactionProvider>{children}</TransactionProvider>
                      </TokenProvider>
                    </InvoiceProvider>
                  </PurchaseOrderProvider>
                </ContractProvider>
              </RFQProvider>
            </OrganizationProvider>
          </PartnerProvider>
        )}
      </AuthProvider>
    </ApolloClientProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
