import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Card } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { find, chain, filter, includes } from 'lodash';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import Chip from '@material-ui/core/Chip';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import useUserInfo from '../../../hooks/useUserInfo';
import {
  TRACE_WIZARD_PROGRESSION_V2,
  CONSTANTS,
  METADATA_STRUCTURE_DATA_MUTABILITY,
  METADATA_STRUCTURE_PERMISSIONS,
  TOKEN_METADATA_FIELDS,
  METADATA_BEHAVIORS,
} from '../../../constants';
import { isUnfinishedStep } from './traceabilityUtils';
import TokenTypeChip from '../../Shared/TokenTypeChip';
import WizardTooltip from './WizardTooltip';

const useStyles = makeStyles(theme => ({
  itemRoot: {
    width: '100%',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  cardContent: {
    display: 'flex',
    paddingLeft: '49px',
  },
  outerGrid: {
    borderBottom: '1px solid #C4C4CD',
  },
  itemLabel: {
    fontFamily: 'EYInterstate-Light',
    fontSize: '0.9rem',
    color: '#747480',
    paddingRight: '8px',
  },
  itemContent: {
    fontFamily: 'EYInterstate-Regular',
    color: 'primary.main',
  },
  contractNameWrapper: {
    fontFamily: 'EYInterstate-Regular',
    color: 'primary.main',
    display: 'flex',
    marginRight: theme.spacing(1),
    alignItems: 'center',
  },
  contractName: {
    margin: `0 ${theme.spacing(1)}px 0 0`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemBorder: {
    paddingLeft: '5px',
    borderLeft: '1px solid #C4C4CD',
    marginTop: '4px',
    marginBottom: '4px',
  },
  infoCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  infoContent: {
    padding: theme.spacing(2),
    color: theme.palette.info.main,
  },
  innerGrid: {
    display: 'flex',
  },
  unRestrictedChip: {
    minWidth: '100px',
    marginRight: '10px',
    borderColor: '#155CB4',
    backgroundColor: '#155CB4',
    color: 'white',
  },
  restrictedChip: {
    minWidth: '100px',
    color: 'white',
    marginRight: '10px',
    borderColor: '#747480',
    backgroundColor: '#747480',
  },
  mutableChip: {
    minWidth: '100px',
    marginRight: '10px',
    borderColor: '#DCEDE1',
    backgroundColor: '#DCEDE1',
  },
  immutableChip: {
    minWidth: '100px',
    marginRight: '10px',
    borderColor: '#F5DFDD',
    backgroundColor: '#F5DFDD',
  },
}));

const ValueChainItemSummary = ({ category, item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { instanceDetails, partners, lastCompletedIteration } = useConfigurationWizardContext();
  const {
    allOrgRoles,
    permissionsFlags: { isUserAuthToEditValueChain },
  } = useUserInfo();
  const history = useHistory();

  const isUserAllowedToEditValueChain =
    isUserAuthToEditValueChain ||
    lastCompletedIteration !== TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code;

  const isShowInfoBox = stepKey => {
    let isVisible = false;
    const currPathCode = find(TRACE_WIZARD_PROGRESSION_V2, { path: stepKey })?.code;
    if (currPathCode === instanceDetails.content.lastCompletedIteration + 1) {
      isVisible = true;
    }
    return isVisible;
  };

  const editButtonView = (isEditMode, stepKey) => {
    const handleNavigation = () => {
      const instanceId = instanceDetails.content._id;
      switch (stepKey) {
        case CONSTANTS.TRACE_PATHS.items:
          history.push(`/traceability/items/${instanceId}`);
          break;
        case CONSTANTS.TRACE_PATHS.steps:
          history.push(`/traceability/steps/${instanceId}`);
          break;
        case CONSTANTS.TRACE_PATHS.permissions:
          history.push(`/traceability/permissions/${instanceId}`);
          break;
        case CONSTANTS.TRACE_PATHS.metadata:
          history.push(`/traceability/metadata/${instanceId}`);
          break;
        default:
          history.push(`/traceability/instance-review/${instanceId}`);
      }
    };
    return (
      <Grid item xs={1}>
        <div className={classes.button}>
          {isEditMode === true && isUserAllowedToEditValueChain && (
            <IconButton
              color="primary"
              aria-label="edit data"
              component="span"
              onClick={handleNavigation}
            >
              <EditIcon />
            </IconButton>
          )}
          {isEditMode === false && (
            <Button variant="contained" color="secondary" onClick={handleNavigation}>
              {stepKey === CONSTANTS.TRACE_PATHS.permissions
                ? t('traceability.instanceSummary.btnContinue')
                : t('traceability.instanceSummary.btnComplete')}
            </Button>
          )}
        </div>
      </Grid>
    );
  };
  const getOrnNameById = orgId => {
    const orgName = find(partners, partner => {
      return partner._id === orgId;
    })?.partnerOrganization?.name;
    return orgName;
  };
  const getRoleNameById = roleId => {
    const roleName = find(allOrgRoles, role => {
      return role._id === roleId;
    })?.name;
    return roleName;
  };
  const populateSingleItem = (label, content, borderRequired) => {
    const labelContent = content || t('traceability.instanceSummary.NA');
    return (
      <Box
        className={clsx({
          [classes.itemBorder]: borderRequired && classes.itemBorder,
        })}
      >
        <Typography component="div" className={classes.innerItem}>
          <Box className={classes.itemLabel}> {label} </Box>
          <Box className={classes.itemContent}> {labelContent}</Box>
        </Typography>
      </Box>
    );
  };

  const populateContractNameItem = (label, contractName, tokenType) => {
    const labelContent = contractName || t('traceability.instanceSummary.NA');
    return (
      <Box className={classes.itemBorder}>
        <Typography component="div" className={classes.innerItem}>
          <Box className={classes.itemLabel}> {label} </Box>
          <Box className={classes.contractNameWrapper}>
            <WizardTooltip text={contractName}>
              <p className={classes.contractName}>{labelContent}</p>
            </WizardTooltip>
            <TokenTypeChip tokenType={tokenType} shortenedLabel className />
          </Box>
        </Typography>
      </Box>
    );
  };

  const populateChipItem = (content, chipStyle, borderRequired) => {
    const labelContent = content || t('traceability.instanceSummary.NA');
    return (
      <Box
        className={clsx({
          [classes.itemBorder]: borderRequired && classes.itemBorder,
        })}
      >
        <Chip className={chipStyle} label={labelContent} />
      </Box>
    );
  };

  const populateMetadataItem = metadataConfigItem => {
    if (metadataConfigItem?.metadataType?.metadataTypeName === TOKEN_METADATA_FIELDS.MEASUREMENT) {
      return `${t(metadataConfigItem?.metadataType?.metadataTypeName)} (${t(
        metadataConfigItem?.chosenMetadataOption,
      )})`;
    }
    return t(metadataConfigItem?.metadataType?.metadataTypeName);
  };

  const messageBox = instanceCategory => {
    return (
      <Grid item xs={10} className={classes.cardContent}>
        <Grid item xs={12}>
          <Card variant="outlined" className={classes.infoCardRoot}>
            <InfoOutlined className={classes.infoIcon} />
            <Typography className={classes.infoContent}>
              {instanceCategory === CONSTANTS.TRACE_PATHS.permissions
                ? t('traceability.instanceSummary.continueMessage')
                : t('traceability.instanceSummary.completeMessage')}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const populateItemsDetails = (instanceItem, instanceCategory) => {
    const itemType = t(instanceItem?.itemType?.name);
    const dependsOn = instanceItem?.parentItem
      ? item?.parentItem?.name
      : t('traceability.instanceSummary.none');
    const dependsOnTokenType = instanceItem?.parentItem?.tokenContract?.tokenType;
    const contractName = instanceItem?.tokenContract?.contractName;
    const tokenType = instanceItem?.tokenContract?.tokenType;

    return (
      <>
        <Grid item xs={11} className={classes.cardContent}>
          <Grid item xs={4}>
            {populateSingleItem(t('traceability.instanceSummary.labelType'), itemType, false)}
          </Grid>
          <Grid item xs={4}>
            {populateContractNameItem(
              t('traceability.instanceSummary.labelDependsOn'),
              dependsOn,
              dependsOnTokenType,
            )}
          </Grid>
          <Grid item xs={4}>
            {populateContractNameItem(
              t('traceability.instanceSummary.labelTokenize'),
              contractName,
              tokenType,
            )}
          </Grid>
        </Grid>
        {editButtonView(true, instanceCategory)}
      </>
    );
  };

  const populateStepDetails = (instanceItem, instanceCategory, infoBoxVisible) => {
    const steps = instanceDetails?.content?.steps?.filter(step => {
      return step?.involvedItem?._id === instanceItem?._id;
    });
    if (infoBoxVisible) {
      return (
        <>
          {messageBox(instanceCategory)}
          {editButtonView(false, instanceCategory)}
        </>
      );
    }
    return (
      <>
        <Grid item xs={11}>
          {steps.map(step => (
            <Grid item xs={12} className={classes.cardContent} key={uniqid()}>
              <Grid item xs={4}>
                {populateSingleItem(t('traceability.instanceSummary.labelStep'), step.name, false)}
              </Grid>
              <Grid item xs={4}>
                {populateSingleItem(
                  t('traceability.instanceSummary.involvedItem'),
                  step?.involvedItem?.name,
                  true,
                )}
              </Grid>
              <Grid item xs={4}>
                {populateSingleItem(
                  t('traceability.instanceSummary.uploadData'),
                  t(step?.ingestionType?.name),
                  true,
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {steps.length > 0 && editButtonView(true, instanceCategory)}
      </>
    );
  };

  const populatePermissionDetails = (instanceItem, instanceCategory, infoBoxVisible) => {
    const partnerCollection = [];
    const partnerDetailsArray = [];
    const steps = instanceDetails?.content?.steps?.filter(step => {
      return step?.involvedItem?._id === instanceItem?._id;
    });
    steps.forEach(step => {
      if (step?.collaborators) {
        step.collaborators.forEach(collab => {
          partnerCollection.push({
            stepId: step._id,
            stepName: step.name,
            partnerId: collab?.partner?._id,
            partnerName: getOrnNameById(collab?.partner?._id),
            roleId: collab?.role,
            roleName: getRoleNameById(collab?.role),
          });
        });
      }
    });

    const groupByOrg = chain(partnerCollection)
      .groupBy('partnerId')
      .map((value, key) => ({ partner: key, details: value }))
      .value();

    groupByOrg.forEach(value => {
      const { partnerName } = value.details[0];
      const groupByRole = chain(value.details)
        .groupBy('roleName')
        .map((itemDetails, key) => ({ role: key, details: itemDetails }))
        .value();
      groupByRole.forEach(roleDetails => {
        const roleName = roleDetails.role;
        const stepNames = roleDetails.details.map(detail => detail.stepName).join('; ');

        const partnerDetailsString = `${partnerName} - ${roleName} - (${stepNames})`;
        partnerDetailsArray.push(partnerDetailsString);
      });
    });
    if (infoBoxVisible) {
      return (
        <>
          {messageBox(instanceCategory)}
          {editButtonView(false, instanceCategory)}
        </>
      );
    }
    return (
      <>
        <Grid item xs={11}>
          {partnerDetailsArray.map(itemDetails => (
            <Grid item xs={12} className={classes.cardContent} key={uniqid()}>
              {populateSingleItem(
                t('traceability.instanceSummary.lblOrganization'),
                itemDetails,
                false,
              )}
            </Grid>
          ))}
        </Grid>
        {instanceDetails?.content?.lastCompletedIteration >=
          TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_STEPS.code &&
          editButtonView(true, instanceCategory)}
      </>
    );
  };

  const populateMetadataDetails = (instanceItem, instanceCategory, infoBoxVisible) => {
    let metadataConfigs = [];
    if (instanceItem?.tokenContract?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED) {
      metadataConfigs = instanceItem?.parentItem?.tokenContract?.metadataStructure?.metadataConfig;
    } else if (instanceDetails?.content?.steps?.length > 0) {
      const itemSpecificSteps = filter(instanceDetails?.content?.steps, {
        involvedItem: { _id: instanceItem?._id },
      }).map(stp => stp._id);

      metadataConfigs = filter(
        instanceItem?.tokenContract?.metadataStructure?.metadataConfig,
        conf => includes(itemSpecificSteps, conf?.step?._id),
      );
    }

    if (infoBoxVisible) {
      return (
        <>
          {messageBox(instanceCategory)}
          {editButtonView(false, instanceCategory)}
        </>
      );
    }

    return (
      <>
        <Grid item xs={11}>
          {metadataConfigs.map(metadataConfig => (
            <Grid item xs={12} className={classes.cardContent} key={uniqid()}>
              <Grid item xs={3}>
                {populateSingleItem(
                  t('traceability.instanceSummary.name'),
                  metadataConfig?.metadataName,
                  false,
                )}
              </Grid>
              <Grid item xs={3}>
                {populateSingleItem(
                  t('traceability.instanceSummary.labelType'),
                  populateMetadataItem(metadataConfig),
                  true,
                )}
              </Grid>
              <Grid item xs={3}>
                {populateChipItem(
                  t(`tokens.metaDataStructure.permissions.${metadataConfig?.metadataPermission}`),
                  metadataConfig?.metadataPermission === METADATA_STRUCTURE_PERMISSIONS.RESTRICTED
                    ? classes.restrictedChip
                    : classes.unRestrictedChip,
                  true,
                )}
              </Grid>
              <Grid item xs={3}>
                {populateChipItem(
                  t(
                    `tokens.metaDataStructure.dataMutability.${metadataConfig?.metadataMutability}`,
                  ),
                  metadataConfig?.metadataMutability === METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE
                    ? classes.mutableChip
                    : classes.immutableChip,
                  true,
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {instanceDetails?.content?.lastCompletedIteration >=
          TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code &&
          editButtonView(true, instanceCategory)}
      </>
    );
  };

  const populateCategoryItems = (instanceCategory, instanceItem) => {
    const infoBoxVisible = isShowInfoBox(instanceCategory);
    switch (category) {
      case CONSTANTS.TRACE_PATHS.items:
        return populateItemsDetails(instanceItem, instanceCategory);
      case CONSTANTS.TRACE_PATHS.steps:
        return populateStepDetails(instanceItem, instanceCategory, infoBoxVisible);
      case CONSTANTS.TRACE_PATHS.permissions:
        return populatePermissionDetails(instanceItem, instanceCategory, infoBoxVisible);
      case CONSTANTS.TRACE_PATHS.metadata:
        return populateMetadataDetails(instanceItem, instanceCategory, infoBoxVisible);
      default:
        return <></>;
    }
  };
  return (
    <div className={classes.itemRoot}>
      <Grid
        container
        className={clsx({
          [classes.outerGrid]: !isUnfinishedStep(category, instanceDetails) && classes.outerGrid,
        })}
      >
        {populateCategoryItems(category, item)}
      </Grid>
    </div>
  );
};

ValueChainItemSummary.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemType: PropTypes.shape({
      name: PropTypes.string,
    }),
    parentItem: PropTypes.shape({
      name: PropTypes.string,
    }),
    codificationType: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  category: PropTypes.string.isRequired,
};
export default ValueChainItemSummary;
