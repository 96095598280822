import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Link from '@eyblockchain/ey-ui/core/Link';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import MessageBox from '@eyblockchain/ey-ui/core/MessageBox';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import ContentCopyIcon from './icons/ContentCopy';
import { useNotification } from '../../contexts/Shared/notification';
import track from '../../mixpanel';
import { getIdTokenInfo } from '../../utils';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    maxHeight: 'fit-content',
  },
  modal: {
    boxShadow:
      '0 9px 46px 0 rgba(0,0,0,0.12), 0 24px 38px 0 rgba(0,0,0,0.12), 0 11px 15px 0 rgba(0,0,0,0.12)',
  },
  insufficientFunds: {
    color: theme.colors.errorMessageRed,
    lineHeight: '24px',
    fontFamily: 'EYInterstate',
  },
  icon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fill: theme.colors.hyperLinkBlue,
    color: theme.colors.hyperLinkBlue,
  },
  modalElement: {
    marginLeft: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ethereumAddress: {
    fontSize: '.875rem',
    fontFamily: 'EYInterstate',
  },
  copySection: {
    display: 'flex',
    flexDirection: 'row',
  },
  tooltip: {
    backgroundColor: '#2E2E38',
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '12px',
    color: '#fff',
  },
  tooltipArrow: {
    color: '#2E2E38',
  },
  title: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  label: {
    marginLeft: theme.spacing(1),
    fontSize: '.75em',
    marginTop: theme.spacing(3),
    fontFamily: 'EYInterstate Light',
  },
  messageBox: {
    maxWidth: '95%',
    width: '34rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  currentBalance: {
    color: '#B9251C',
    lineHeight: '24px',
    fontFamily: 'EYInterstate',
  },
  link: {
    color: theme.colors.hyperLinkBlue,
  },
  linkContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  iconContainer: {
    display: 'inline-block',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  chooseFaucet: {
    fontFamily: 'EYInterstate',
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '1px',
    backgroundColor: theme.palette.primary.lighter,
  },
  closeButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    height: '2.5rem',
    width: '5.625rem',
  },
}));

const InsufficientFunds = () => {
  const [copied, setCopied] = useState(false);
  const [clickedFaucetLink, setClickedFaucetLink] = useState(false);
  const { activeWallet } = useBecOrganizationContext();
  const { showFaucetModal, setShowFaucetModal } = useNotification();
  const classes = useStyles();
  const { t } = useTranslation();
  const faucets = [{ name: t('tokens.ropstenFaucet'), url: CONSTANTS.NETWORK_FAUCETS.ROPSTEN }];

  const handleClose = () => {
    if (!clickedFaucetLink) {
      const tokenInfo = getIdTokenInfo();
      track('Faucet drop-off', { auth0Id: tokenInfo.sub });
    }
    setShowFaucetModal(false);
  };

  useEffect(() => {
    if (showFaucetModal) {
      const tokenInfo = getIdTokenInfo();
      track('Faucet modal', { auth0Id: tokenInfo.sub });
    }
  }, [showFaucetModal]);

  return (
    <Dialog
      className={classes.modal}
      open={showFaucetModal}
      PaperProps={{ className: classes.paper }}
      fullWidth
    >
      <Typography variant="h4" className={classes.title}>
        {t('tokens.notEnoughFunds')}
      </Typography>
      <IconButton aria-label="close" className={classes.closeIcon} onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
      <MessageBox className={classes.messageBox} variant="error">
        <div>
          <Typography className={classes.insufficientFunds}>
            {t('tokens.faucetModalWarning')}
          </Typography>
          <Typography className={classes.currentBalance}>
            {t('tokens.currentBalance', {
              ether: activeWallet?.balance || 0,
              blockchainType:
                activeWallet?.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.MATIC
                  ? t('tokens.blockchainTypes.MATIC')
                  : t('tokens.blockchainTypes.ETH'),
              gwei: activeWallet?.balance ? `or ${activeWallet.balance * 10 ** 9} GWEI` : '',
            })}
          </Typography>
        </div>
      </MessageBox>
      <Typography className={classes.modalElement}>
        {t('tokens.faucetModalInstructions')}
      </Typography>
      <Typography className={classes.label}>{t('tokens.yourCurrentAddress')}</Typography>
      <div
        aria-hidden="true"
        onClick={event => {
          event.stopPropagation();
        }}
        className={classes.copySection}
      >
        <Typography className={`${classes.modalElement} ${classes.ethereumAddress}`}>
          {activeWallet?.address}
        </Typography>
        <CopyToClipboard
          text={activeWallet?.address}
          onCopy={() => {
            if (copied) return;
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 1500);
          }}
        >
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            title={t('common.copied')}
            placement="top"
            open={copied}
            arrow
          >
            <div className={classes.iconContainer}>
              <ContentCopyIcon className={classes.icon} />
            </div>
          </Tooltip>
        </CopyToClipboard>
      </div>
      {activeWallet?.blockchainType === CONSTANTS.BLOCKCHAIN_TYPES.ETH && (
        <>
          <Typography className={`${classes.chooseFaucet} ${classes.modalElement}`}>
            {t('tokens.chooseAFaucet')}
          </Typography>
          {faucets.map(faucet => (
            <Typography key={uniqid()} className={classes.linkContainer}>
              <Link
                className={classes.link}
                onClick={() => setClickedFaucetLink(true)}
                to={faucet.url}
                target="_blank"
              >
                {faucet.name}
              </Link>
              <span className={classes.iconContainer}>
                <LaunchIcon
                  className={classes.icon}
                  onClick={() => window.open(faucet.url, '_blank')}
                />
              </span>
            </Typography>
          ))}
        </>
      )}
      <Divider className={classes.divider} />
      <Button
        className={classes.closeButton}
        color="inherit"
        variant="outlined"
        onClick={() => handleClose()}
      >
        {t('common.close')}
      </Button>
    </Dialog>
  );
};

export default InsufficientFunds;
