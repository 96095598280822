import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Line, Column } from '@ant-design/charts';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { getMonthLabel, months36 } from '../../utils/dateUtils';

const useStyles = makeStyles(theme => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bolder',
    position: 'relative',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '85%',
    padding: theme.spacing(5),
    color: theme.palette.primary.light,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '2rem',
    cursor: 'pointer',
  },
  details: {
    marginBottom: theme.spacing(3),
  },
  desc: {
    marginTop: theme.spacing(1),
  },
  key: {
    color: theme.palette.primary.lighter,
    fontSize: '1rem',
  },
  value: {
    fontSize: '1rem',
    paddingLeft: theme.spacing(1),
  },
  chart: {
    border: '1px solid',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chartContainer: {
    width: '100%',
    height: '260px',
  },
  chartTitle: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    marginBottom: theme.spacing(3),
  },
}));

const LineConfigs = [
  {
    seriesField: 'type',
    xField: 'label',
    yField: 'value',
    point: { shape: 'circle', size: 3 },
    color: '#ffe600',
    fontFamily: 'EYInterstate,Roboto,sans-serif',
    tooltip: { formatter: datum => ({ name: datum.label, value: datum.value }) },
  },
];

const ChartModal = ({ chart, closeModal, data: rowData, defaultDate, type, xUnit, yUnit }) => {
  const classes = useStyles();
  const { t } = useTranslation('mtr');

  const data = months36
    .map(item => ({
      value: parseInt(rowData[`MONTH_${item}`], 10),
      label: getMonthLabel(item, defaultDate),
      type,
    }))
    .filter(i => i.value || i.value === 0);
  const inventoryData = rowData.inventory
    .map(item => JSON.parse(item))
    .map(i => ({ ...i, QUANTITY: parseInt(i.QUANTITY, 10) }));
  return (
    <Modal className={classes.modal} open={!!chart} onClose={closeModal}>
      <Paper className={classes.paper}>
        <CloseIcon className={classes.close} onClick={closeModal} />
        <Grid className={classes.details}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container>
                <Grid item className={classes.key}>
                  {t('pos.itemCode')}:
                </Grid>
                <Grid item className={classes.value}>
                  {rowData.sku?.buyerSku}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item className={classes.key}>
                  {t('pos.part')}:
                </Grid>
                <Grid item className={classes.value}>
                  {rowData.sku?.supplierSku}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.desc}>
            <Grid item xs={6}>
              <Grid container>
                <Grid className={classes.key}>{t('pos.UOM')}:</Grid>
                <Grid className={classes.value}>{rowData.UOM}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid className={classes.key}>{t('pos.itemDescription')}:</Grid>
                <Grid className={classes.value}>{rowData.sku.description}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {chart === 'chart' && (
          <Grid className={classes.chart}>
            <Box className={classes.chartTitle}>
              {type === 'demand'
                ? t('pos.monthlyDemandForecast')
                : t('pos.monthlyProductionSchedule')}
            </Box>
            <Grid className={classes.chartContainer}>
              <Line
                {...LineConfigs[0]}
                data={data}
                xAxis={{
                  label: {
                    formatter: v => `${v} ${xUnit}`,
                  },
                }}
                yAxis={{
                  label: {
                    formatter: v => `${v} ${yUnit}`,
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {chart === 'inventory' && (
          <Grid className={classes.chart}>
            <Box className={classes.chartTitle}>Current Inventory By Location</Box>
            <Grid className={classes.chartContainer}>
              <Column
                data={inventoryData}
                xField="LOCATION"
                yField="QUANTITY"
                color="#ffe600"
                fontFamily="EYInterstate,Roboto,sans-serif"
                tooltip={{ formatter: datum => ({ name: datum.LOCATION, value: datum.QUANTITY }) }}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Modal>
  );
};

ChartModal.defaultProps = {
  data: [],
  chart: '',
  xUnit: '',
  yUnit: '',
};

ChartModal.propTypes = {
  chart: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  defaultDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  xUnit: PropTypes.string,
  yUnit: PropTypes.string,
};

export default ChartModal;
