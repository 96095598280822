import React from 'react';
import { Breadcrumbs as MuiBreadCrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  navigationLink: {
    color: theme.colors.hyperLinkBlue,
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fill: theme.colors.hyperLinkBlue,
    color: theme.colors.hyperLinkBlue,
  },
}));

const Breadcrumbs = ({ breadCrumbsData }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = path => {
    history.push(path);
  };

  return (
    <div>
      <div>
        <MuiBreadCrumbs aria-label="breadcrumb" separator="">
          {breadCrumbsData?.map(({ name, path }) => (
            <Typography
              key={name}
              className={classes.navigationLink}
              onClick={() => handleClick(path)}
              data-testid="navigate-back-ingestion-history"
            >
              <ArrowBackIcon className={classes.icon} />
              {name}
            </Typography>
          ))}
        </MuiBreadCrumbs>
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadCrumbsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Breadcrumbs;
