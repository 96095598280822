import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateDefaultMetadataValues } from '../../../tokenMetadataUtils';
import TokenMetaData from '../../Shared/TokenMetaData';

const useStyles = makeStyles(theme => ({
  TokenMetaDataRoot: {
    '& .MuiButton-outlinedPrimary': {
      border: '0px',
    },
  },

  buttonArea: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: theme.spacing(1),
  },

  noStructBanner: {
    marginTop: theme.spacing(2),
  },
}));

const LinkedModeMetadataPanel = ({ selectedToken, metadataConfigs, batchMetaData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: generateDefaultMetadataValues(metadataConfigs, selectedToken, batchMetaData),
  });

  if (!metadataConfigs || metadataConfigs.length < 1) {
    return (
      <Typography variant="body1" className={classes.noStructBanner}>
        {t('tokens.addMetadataInstruction')}
      </Typography>
    );
  }

  return (
    <div className={classes.TokenMetaDataRoot}>
      <FormikProvider value={formik}>
        <Form>
          <TokenMetaData selectedToken={selectedToken} metadataConfigs={metadataConfigs} readOnly />
        </Form>
      </FormikProvider>
    </div>
  );
};

LinkedModeMetadataPanel.propTypes = {
  selectedToken: PropTypes.oneOfType([
    PropTypes.shape({
      metadata: PropTypes.shape({
        links: PropTypes.shape({}),
      }),
      metadataUrls: PropTypes.shape({
        publicImmutable: PropTypes.string,
        privateImmutable: PropTypes.string,
        publicMutable: PropTypes.string,
        privateMutable: PropTypes.string,
      }),
      tokenId: PropTypes.string.isRequired,
      latestMetadataTransaction: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
      smartContract: PropTypes.shape({
        contractAddress: PropTypes.string.isRequired,
        metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        metadata: PropTypes.shape({
          links: PropTypes.shape({}),
        }),
        metadataUrls: PropTypes.shape({
          publicImmutable: PropTypes.string,
          privateImmutable: PropTypes.string,
          publicMutable: PropTypes.string,
          privateMutable: PropTypes.string,
        }),
        tokenId: PropTypes.string.isRequired,
        latestMetadataTransaction: PropTypes.shape({
          status: PropTypes.string.isRequired,
        }),
        smartContract: PropTypes.shape({
          contractAddress: PropTypes.string.isRequired,
          metadataConfig: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    ),
  ]),
  metadataConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  batchMetaData: PropTypes.bool,
};

LinkedModeMetadataPanel.defaultProps = {
  selectedToken: null,
  batchMetaData: false,
};

export default LinkedModeMetadataPanel;
