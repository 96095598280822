import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import { getUserId } from '../../../utils';
import api from '../../../utils/api';
import { useNotification } from '../../../contexts/Shared/notification';

const Typography = withStyles(theme => ({
  body2: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
}))(MuiTypography);

export const updatePreferenceForModal = async (showModal, handleNotification, t) => {
  const accessToken = Cookies.get(`${window.config.cookiePrefix}access_token`);
  const userId = getUserId();
  const { checkUserPreferences } = api.userAPI({ handleNotification, t });
  const preferences = await checkUserPreferences({ userId });

  const body = {
    user_metadata: {
      preferences: {
        ...preferences,
        show_wallet_modal: showModal,
      },
    },
  };

  return axios.put(`${window.platformConfig.authApiUrl}/users/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const useStyles = makeStyles(theme => ({
  arrows: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  body1: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: '0.5rem',
  },
  body2: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: '0.7rem',
  },
  body3: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: '0.1rem',
  },
  body4: {
    fontSize: theme.typography.pxToRem(16),
  },
  circle2: {
    width: '200px',
    height: '200px',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  coins: {
    width: '125px',
    height: '125px',
    margin: '2.5rem 0 0 0',
  },
  logo: {
    width: '97.5px',
    height: '97.5px',
    marginTop: '20px',
  },
  checkbox: {
    height: '20px',
    width: '10px',
    marginTop: theme.spacing(0.3),
    marginRight: theme.spacing(1),
  },
  circle: {
    width: '150px',
    height: '150px',
    marginTop: '80px',
    marginRight: '40px',
    textAlign: 'center',
    backgroundColor: '#EAEAF2',
    borderRadius: '50%',
  },
  activeButton: {
    '&:hover': { backgroundColor: theme.palette.primary.lightest },
    padding: '0 10px 0 0',
    marginRight: '10px',
  },
  disabledButton: {
    '&:hover': { backgroundColor: theme.palette.common.white },
    padding: '0 0 0 10px',
    marginLeft: '10px',
    color: theme.palette.primary.lighter,
  },
  lightNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.lighter,
  },
  darkNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.main,
  },
  inactiveArrow: {
    color: theme.palette.primary.lighter,
  },
}));

const MainnetInfo = ({ backOnClick, setOpenModal, userModalPreference }) => {
  const [displayModal, setDisplayModal] = useState(userModalPreference);
  const { handleNotification } = useNotification();
  const { t } = useTranslation();

  const handleChange = event => {
    setDisplayModal(!event.target.checked);
  };
  const classes = useStyles();

  return (
    <>
      <Box style={{ height: '250px' }} display="flex" justifyContent="center">
        <span className={classes.circle2}>
          <img alt="" src="static/decentralizedIcon.png" className={classes.coins} />
        </span>
      </Box>
      <Typography component="div">
        <Box className={classes.body1} display="flex" justifyContent="center" variant="body2">
          {i18n.t('OnboardingModal.ScreenThree.body1')}
        </Box>
        <Box className={classes.body2} display="flex" justifyContent="center" variant="body2">
          {i18n.t('OnboardingModal.ScreenThree.body2')}
        </Box>
        <Box style={{ height: '12px' }} />

        <Box className={classes.body3} display="flex" justifyContent="center" variant="body1">
          {i18n.t('OnboardingModal.ScreenThree.body3')}
        </Box>
        <Box className={classes.body3} display="flex" justifyContent="center" variant="body1">
          {i18n.t('OnboardingModal.ScreenThree.body4')}
        </Box>
      </Typography>
      <Box display="flex" marginBottom="0.3rem" justifyContent="center">
        <Typography className={classes.body4} variant="body1" gutterBottom>
          <Trans i18nKey="OnboardingModal.ScreenThree.body5">
            <Link
              style={{ color: '#155CB4' }}
              underline="always"
              target="_blank"
              href={`${window.platformConfig.becUrl}/support`}
            >
              {i18n.t('OnboardingModal.ScreenThree.contactUs')}
            </Link>
          </Trans>
        </Typography>
      </Box>
      <Box height="14px" />
      <Box display="flex" justifyContent="center">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              className={classes.checkbox}
              defaultChecked={!userModalPreference}
              onChange={handleChange}
              name="showModal"
            />
          }
          label={
            <Typography
              style={{ marginBottom: '0.1rem', marginTop: '3px' }}
              variant="body1"
              onClick={handleChange}
              gutterBottom
            >
              {i18n.t('OnboardingModal.ScreenThree.checkbox')}
            </Typography>
          }
        />
      </Box>
      <Box style={{ height: '25px' }} />

      <Box display="flex" justifyContent="center">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            updatePreferenceForModal(displayModal, handleNotification, t);
            setOpenModal(false);
          }}
        >
          {i18n.t('OnboardingModal.ScreenThree.start')}
        </Button>
      </Box>
      <Box style={{ height: '11px' }} />

      <Box className={classes.arrows} display="flex" justifyContent="center">
        <Button
          className={classes.activeButton}
          variant="outlined"
          color="primary"
          onClick={backOnClick}
        >
          <KeyboardArrowLeftIcon fontSize="large" />
          {i18n.t('OnboardingModal.back')}
        </Button>

        <FiberManualRecordIcon className={classes.lightNavCircle} />
        <FiberManualRecordIcon className={classes.lightNavCircle} />
        <FiberManualRecordIcon className={classes.darkNavCircle} />

        <Button className={classes.disabledButton} variant="contained" disabled>
          {i18n.t('OnboardingModal.next')}
          <KeyboardArrowRightIcon className={classes.inactiveArrow} fontSize="large" />
        </Button>
      </Box>
    </>
  );
};

MainnetInfo.propTypes = {
  backOnClick: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  userModalPreference: PropTypes.bool,
};

MainnetInfo.defaultProps = {
  userModalPreference: true,
};

export default MainnetInfo;
