import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { CONSTANTS } from '../../constants';
import BlockchainInfo from '../Shared/BlockchainInfo';
import {
  DEPLOY_ERC20_BCOS,
  DEPLOY_ERC721_BCOS,
  DEPLOY_ERC20_BSN,
  DEPLOY_ERC721_BSN,
  DEPLOY_ERC20,
  DEPLOY_ERC721,
  DEPLOY_ERC1155,
} from '../../graphql/Tokenization/token';
import track from '../../mixpanel';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  mintButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    size: 'small',
  },
  disabledText: {
    color: theme.palette.primary.lighter,
  },
  contractInfo: {
    display: 'flex',
  },
  contractName: {
    width: '70%',
    paddingRight: '10px',
  },
  contractSymbol: {
    width: '30%',
  },
  contractType: {
    marginTop: '18px',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  selectField: {
    '& .MuiSelect-selectMenu': {
      minHeight: '1.5em',
    },
  },
  fullWidthTextField: {
    width: '100%',
  },
  infoText: {
    color: theme.palette.primary.light,
  },
  optionalHeaderText: {
    color: theme.palette.primary.lighter,
  },
  icon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fill: theme.colors.hyperLinkBlue,
    color: theme.colors.hyperLinkBlue,
    cursor: 'pointer',
  },
  linkText: {
    color: theme.colors.hyperLinkBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  baseURIInfoContainer: {
    marginBottom: theme.spacing(2),
  },
  contractTypeInfoContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const DeployERCTokenContract = ({
  open,
  closeModal,
  isERC20Disabled,
  isERC721Disabled,
  isERC1155Disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();

  const {
    tokenContracts,
    setTokenContracts,
    totalTokenCount,
    setTotalTokenCount,
    setPage,
    rowsPerPage,
  } = useTokenContext();
  const { handleNotification, setShowFaucetModal, setUpdateLivePanel } = useNotification();

  const [deployERC20TokenContract, { loading: isDeployERC20TokenContractLoading }] = useMutation(
    DEPLOY_ERC20,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.DEPLOY_ERC20, {
          blockchainNetwork: activeWallet?.network,
          contractId: data?.deployErc20Eth?._id,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.DEPLOY_ERC20, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
        throw error;
      },
    },
  );

  const [
    deployERC20TokenContractBCOS,
    { loading: isDeployERC20TokenBcosContractLoading },
  ] = useMutation(DEPLOY_ERC20_BCOS);
  const [
    deployERC20TokenContractBsn,
    { loading: isDeployERC20TokenBsnContractLoading },
  ] = useMutation(DEPLOY_ERC20_BSN);

  const [deployERC721TokenContract, { loading: isDeployERC721TokenContractLoading }] = useMutation(
    DEPLOY_ERC721,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.DEPLOY_ERC721, {
          blockchainNetwork: activeWallet?.network,
          contractId: data.deployErc721Eth?._id,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.DEPLOY_ERC721, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
        throw error;
      },
    },
  );

  const [
    deployERC1155TokenContract,
    { loading: isDeployERC1155TokenContractLoading },
  ] = useMutation(DEPLOY_ERC1155, {
    onCompleted: data => {
      track(CONSTANTS.MIXPANEL_EVENTS.TOKENIZATION.DEPLOY_ERC1155, {
        blockchainNetwork: activeWallet?.network,
        contractId: data.deployERC1155Contract?._id,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TOKENIZATION.DEPLOY_ERC1155, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
      throw error;
    },
  });

  const [
    deployERC721TokenContractBCOS,
    { loading: isDeployERC721TokenBcosContractLoading },
  ] = useMutation(DEPLOY_ERC721_BCOS);
  const [
    deployERC721TokenContractBsn,
    { loading: isDeployERC721TokenBsnContractLoading },
  ] = useMutation(DEPLOY_ERC721_BSN);

  const loading =
    isDeployERC20TokenContractLoading ||
    isDeployERC721TokenContractLoading ||
    isDeployERC20TokenBcosContractLoading ||
    isDeployERC721TokenBcosContractLoading ||
    isDeployERC20TokenBsnContractLoading ||
    isDeployERC721TokenBsnContractLoading ||
    isDeployERC1155TokenContractLoading;

  const deployFunction = async (contractType, variables) => {
    if (contractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC20) {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const {
            data: { deployErc20Bcos: contractResponse },
          } = await deployERC20TokenContractBCOS(variables);
          return contractResponse;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
          const {
            data: { deployErc20Bsn: contractResponse },
          } = await deployERC20TokenContractBsn(variables);
          return contractResponse;
        }
        default: {
          const {
            data: { deployErc20Eth: contractResponse },
          } = await deployERC20TokenContract(variables);
          return contractResponse;
        }
      }
    } else if (contractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721) {
      switch (activeWallet.blockchainType) {
        case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
          const {
            data: { deployErc721Bcos: contractResponse },
          } = await deployERC721TokenContractBCOS(variables);
          return contractResponse;
        }
        case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
          const {
            data: { deployErc721Bsn: contractResponse },
          } = await deployERC721TokenContractBsn(variables);
          return contractResponse;
        }
        default: {
          const {
            data: { deployErc721Eth: contractResponse },
          } = await deployERC721TokenContract(variables);
          return contractResponse;
        }
      }
    } else if (contractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155) {
      const {
        data: { deployERC1155Contract: contractResponse },
      } = await deployERC1155TokenContract(variables);
      return contractResponse;
    }
    return null;
  };

  const deployERCTokenContract = async variables => {
    try {
      const contractResponse = await deployFunction(variables.variables.contractType, variables);
      const newContract = {
        contract: contractResponse,
        tokenName: contractResponse?.contractName,
        tokenSymbol: contractResponse?.tokenSymbol,
        balance: 0,
      };
      const newContracts = [newContract, ...tokenContracts];
      if (tokenContracts.length !== 0 && tokenContracts.length % rowsPerPage === 0) {
        newContracts.pop();
      }
      setTokenContracts(newContracts);
      setUpdateLivePanel(true);
      handleNotification(t('tokens.contractSubmitSuccess'), 'success');
      closeModal();
      setTotalTokenCount(totalTokenCount + 1);
      setPage(1);
    } catch (error) {
      track('Contract Deployment Error', {
        error: error,
      });
      if (error?.graphQLErrors) {
        if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
        // For known errors, use the predefined message. Fallback to interpolated for unknown errors
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.deployError'], {
            error: error?.graphQLErrors[0]?.errorCode,
          }),
          'error',
        );
      } else {
        handleNotification(
          t([`tokens.errors.${error?.message}`, 'tokens.deployError'], {
            error: error?.message,
          }),
          'error',
        );
      }
    }
  };

  const combinedSmartContractTypes = [
    !isERC20Disabled && {
      key: 'fungible',
      value: t('contracts.behaviour.fungible'),
    },
    !isERC721Disabled && {
      key: 'nonFungible',
      value: t('contracts.behaviour.nonFungible'),
    },
    !isERC1155Disabled && {
      key: 'semiFungible',
      value: t('contracts.behaviour.semiFungible'),
    },
  ];

  const smartContractTypes = combinedSmartContractTypes;

  const contractBehaviourToSatndardMap = {
    [t('contracts.behaviour.fungible')]: CONSTANTS.SMARTCONTRACT_TYPES.ERC20,
    [t('contracts.behaviour.nonFungible')]: CONSTANTS.SMARTCONTRACT_TYPES.ERC721,
    [t('contracts.behaviour.semiFungible')]: CONSTANTS.SMARTCONTRACT_TYPES.ERC1155,
  };

  const formik = useFormik({
    initialValues: {
      tokenName: '',
      tokenSymbol: '',
      contractType: '',
      baseURI: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (loading) return;
      const modifiedValues = {
        ...values,
        contractType: contractBehaviourToSatndardMap[values.contractType],
      };
      const res = await deployERCTokenContract({ variables: modifiedValues });
      if (res) {
        closeModal();
      }
      resetForm();
    },

    /* eslint-disable no-useless-escape */
    validationSchema: Yup.object().shape({
      tokenName: Yup.string().required(t('tokens.validation.name')),
      tokenSymbol: Yup.string().required(t('tokens.validation.symbol')),
      contractType: Yup.string().required(t('tokens.validation.contractType')),
      baseURI: Yup.string()
        .matches(
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
          t('tokens.msgValidUrl'),
        )
        .test('valid-url', '', value => value !== null),
    }),
  });

  const { setFieldValue } = formik;

  const handleChange = event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const closeModalFn = () => {
    formik.resetForm();
    closeModal();
  };

  const isBaseURIApplicable = () =>
    formik.values.contractType === t('contracts.behaviour.nonFungible') ||
    formik.values.contractType === t('contracts.behaviour.semiFungible');

  // const helpCenter = () => {
  //   return (
  //     <span>
  //       <Typography variant="caption" className={classes.linkText}>
  //         {t('common.helpCenter')}
  //       </Typography>
  //       <LaunchIcon className={classes.icon} />
  //     </span>
  //   );
  // };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModalFn}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {`${t('tokens.deployContract')}`}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModalFn}>
          <CloseIcon />
        </IconButton>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className={classes.contractInfo}>
              <TextField
                label={t('tokens.tokenContractName')}
                value={formik.values.tokenName || ''}
                name="tokenName"
                className={classes.contractName}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                label={t('tokens.tokenContractSymbol')}
                value={formik.values.tokenSymbol || ''}
                name="tokenSymbol"
                className={classes.contractSymbol}
                variant="outlined"
                onChange={handleChange}
              />
            </div>

            <div>
              <TextField
                id="selectContractType"
                name="contractType"
                label="Select contract type"
                value={formik.values.contractType}
                className={clsx(classes.contractType, classes.selectField)}
                variant="outlined"
                select
                onChange={handleChange}
              >
                {smartContractTypes?.map(option => {
                  return (
                    <MenuItem key={option.key} value={option.value}>
                      {option.value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>

            {isBaseURIApplicable() && (
              <div>
                <Box component="div" sx={{ display: 'flex', gap: '5px' }}>
                  <Typography variant="subtitle1">{`${t('tokens.baseURISubTitle')}`}</Typography>
                  <Typography variant="subtitle1" className={classes.optionalHeaderText}>
                    ({`${t('common.optional')}`})
                  </Typography>
                </Box>

                <Typography
                  component="div"
                  variant="caption"
                  className={clsx(classes.infoText, classes.baseURIInfoContainer)}
                >
                  {`${t('tokens.baseURIInfo')}`}
                </Typography>

                <TextField
                  label={t('tokens.addUri')}
                  value={formik.values.baseURI || ''}
                  name="baseURI"
                  className={classes.fullWidthTextField}
                  variant="outlined"
                  onChange={handleChange}
                  error={formik?.errors?.baseURI}
                  helperText={formik?.errors?.baseURI}
                />
              </div>
            )}

            <FormErrors form={formik} />
            {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
              <BlockchainInfo
                validateForm={formik.validateForm}
                values={formik.values}
                contractAddress=""
                contractName={contractBehaviourToSatndardMap[formik.values.contractType]}
                method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                methodArgs={[formik.values.tokenName, formik.values.tokenSymbol]}
              />
            ) : (
              ''
            )}
            <Divider className={classes.divider} />
            <Button
              variant="contained"
              onClick={closeModalFn}
              color="primary"
              className={classes.mintButton}
            >
              {t('common.cancel')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.mintButton}
              disabled={
                !formik.values.tokenName ||
                !formik.values.tokenSymbol ||
                !formik.values.contractType ||
                !!formik?.errors?.baseURI ||
                loading
              }
            >
              {t('tokens.deploy')}
            </Button>
          </Form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

DeployERCTokenContract.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isERC20Disabled: PropTypes.bool,
  isERC721Disabled: PropTypes.bool,
  isERC1155Disabled: PropTypes.bool,
};

DeployERCTokenContract.defaultProps = {
  isERC20Disabled: false,
  isERC721Disabled: false,
  isERC1155Disabled: false,
};

export default DeployERCTokenContract;
