import { Paper, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  NoneMetadataPanelRoot: {
    display: 'flex',
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
  bannerText: {
    color: theme.palette.info.main,
  },
}));

const NoneMetadataPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.NoneMetadataPanelRoot}>
      <InfoOutlinedIcon className={classes.infoIcon} />
      <Typography variant="body1" className={classes.bannerText}>
        {t(`tokens.metaDataStructure.behavior.NONE.banner`)}
      </Typography>
    </Paper>
  );
};

NoneMetadataPanel.propTypes = {};

NoneMetadataPanel.defaultProps = {};

export default NoneMetadataPanel;
