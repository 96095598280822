import React from 'react';
import clsx from 'clsx';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  hasError: {
    backgroundColor: theme.palette.error.lightest,
    '&:hover': {
      backgroundColor: theme.palette.error.lightest,
    },
  },
  buttonRoot: {
    height: '30px',
  },
  grouped: {
    minHeight: '0px',
  },
}));

const FormikSwitch = ({
  field: { value, name },
  form: { setFieldValue, setFieldError, setFieldTouched, errors },
  className: classNameProp,
  beforeChange,
  labels,
  ...rest
}) => {
  const hasError = errors[name] !== undefined;
  const classes = useStyles();
  const { t } = useTranslation();

  const groupClassName = clsx(classes.root, classNameProp);
  const buttonClassName = clsx(classes.buttons, { [classes.hasError]: hasError });

  const handleToggleChange = async (event, newValues) => {
    if (newValues !== null) {
      setFieldTouched(name, true, false);
      try {
        if (beforeChange) {
          await beforeChange(event);
        }
        setFieldValue(name, newValues);
      } catch (err) {
        setFieldError(name, `${t('common.modeSwitchFailed')}: ${err.message}`);
      }
    }
  };

  return (
    <ToggleButtonGroup
      className={groupClassName}
      classes={{ grouped: classes.grouped }}
      exclusive
      size="small"
      name={name}
      value={value}
      onChange={handleToggleChange}
      {...rest}
    >
      <ToggleButton
        value={false}
        classes={{ root: classes.buttonRoot }}
        className={buttonClassName}
      >
        {' '}
        {labels.falseLabel}
      </ToggleButton>
      <ToggleButton value classes={{ root: classes.buttonRoot }} className={buttonClassName}>
        {' '}
        {labels.trueLabel}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

FormikSwitch.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  beforeChange: PropTypes.func,
  labels: PropTypes.shape({
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string,
  }).isRequired,
};

FormikSwitch.defaultProps = {
  className: null,
  beforeChange: null,
};

export default FormikSwitch;
