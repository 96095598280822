import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Grade from '@material-ui/icons/Grade';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import TierMarker from './TierMarker';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: '5rem',
    marginBottom: '1rem',
    display: 'flex',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  allContainer: {
    marginBottom: '1rem',
    padding: '1rem',
  },
  labelContainer: {
    width: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '.70rem',
    paddingRight: '15px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  starIcon: {
    height: '11.08px',
    width: '11.67px',
    color: '#FFE600',
    stroke: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  legend: {
    verticalAlign: 'middle',
  },
  legendContainer: {
    padding: theme.spacing(1),
  },
}));

const PurchaseOrderProgressBar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { tierBounds, accumulatedVolume, purchaseOrderVolume, pricesByTier } = props;
  const total = accumulatedVolume + purchaseOrderVolume;

  return (
    <div className={classes.allContainer}>
      <div className={classes.root}>
        <div className={classes.labelContainer}>
          <Typography className={classes.label}>
            {t('purchaseOrderProgressBar.quantity')}
          </Typography>
          <Typography className={classes.label}>
            {t('purchaseOrderProgressBar.netPrice')}
          </Typography>
        </div>
        <div className={classes.container}>
          <ProgressBar
            tierBounds={tierBounds}
            accumulatedVolume={accumulatedVolume}
            purchaseOrderVolume={purchaseOrderVolume}
          />
          {tierBounds.map((tier, i) => (
            <TierMarker
              key={uniqid()}
              tierBounds={tierBounds}
              pricesByTier={pricesByTier}
              tier={tier}
              index={i}
              total={total}
            />
          ))}
        </div>
      </div>
      <span className={classes.legendContainer}>
        <span>
          <Grade className={`${classes.starIcon} ${classes.legend}`} />
        </span>
        <span>
          <Typography variant="caption" className={classes.legend}>
            {t('purchaseOrderProgressBar.tierPricing')}
          </Typography>
        </span>
      </span>
    </div>
  );
};

PurchaseOrderProgressBar.propTypes = {
  tierBounds: PropTypes.arrayOf(PropTypes.number).isRequired,
  accumulatedVolume: PropTypes.number.isRequired,
  purchaseOrderVolume: PropTypes.number.isRequired,
  pricesByTier: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PurchaseOrderProgressBar;
