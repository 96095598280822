import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UpdateMetadataTokenIcon = () => {
  return (
    <SvgIcon>
      <g id="update-metadata-icon" stroke="none" strokeWidth="1">
        <path d="M9.55 15.925 11.375 14 9.425 12.05 11.375 10.1 9.55 8.175 5.675 12.05ZM14.45 15.925 18.325 12.05 14.45 8.175 12.625 10.1 14.575 12.05 12.625 14ZM4.85 21.825Q3.725 21.825 2.963 21.062Q2.2 20.3 2.2 19.175V4.925Q2.2 3.8 2.963 3.037Q3.725 2.275 4.85 2.275H8.45Q9.025 1.35 9.963 0.787Q10.9 0.225 12 0.225Q13.1 0.225 14.038 0.787Q14.975 1.35 15.55 2.275H19.15Q20.275 2.275 21.038 3.037Q21.8 3.8 21.8 4.925V19.175Q21.8 20.3 21.038 21.062Q20.275 21.825 19.15 21.825ZM12 4.025Q12.325 4.025 12.538 3.812Q12.75 3.6 12.75 3.275Q12.75 2.95 12.538 2.737Q12.325 2.525 12 2.525Q11.675 2.525 11.463 2.737Q11.25 2.95 11.25 3.275Q11.25 3.6 11.463 3.812Q11.675 4.025 12 4.025ZM4.85 19.175H19.15Q19.15 19.175 19.15 19.175Q19.15 19.175 19.15 19.175V4.925Q19.15 4.925 19.15 4.925Q19.15 4.925 19.15 4.925H4.85Q4.85 4.925 4.85 4.925Q4.85 4.925 4.85 4.925V19.175Q4.85 19.175 4.85 19.175Q4.85 19.175 4.85 19.175ZM4.85 4.925Q4.85 4.925 4.85 4.925Q4.85 4.925 4.85 4.925V19.175Q4.85 19.175 4.85 19.175Q4.85 19.175 4.85 19.175Q4.85 19.175 4.85 19.175Q4.85 19.175 4.85 19.175V4.925Q4.85 4.925 4.85 4.925Q4.85 4.925 4.85 4.925Z" />
      </g>
    </SvgIcon>
  );
};

UpdateMetadataTokenIcon.propTypes = {};

UpdateMetadataTokenIcon.defaultProps = {};

export default UpdateMetadataTokenIcon;
