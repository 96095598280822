import gql from 'graphql-tag';

const METADTA_STRUCTURE_ATTRIBUTES = gql`
  fragment METADTA_STRUCTURE_ATTRIBUTES on MetadataStructureType {
    behavior
    version
    createdAt
    updatedAt
    metadataConfig {
      metadataName
      metadataMutability
      metadataPermission
      step {
        _id
        name
        involvedItem {
          _id
        }
      }
      metadataType {
        _id
        metadataTypeName
        metadataTypeOptions
      }
      chosenMetadataOption
    }
  }
`;

export const ITEMS_ATTRIBUTES = gql`
  fragment ITEMS_ATTRIBUTES on Item {
    _id
    name
    tokenize
    isActive
    instance {
      _id
      name
    }
    itemType {
      _id
      name
    }
    codificationType {
      _id
      name
    }
    parentItem {
      _id
      name
      tokenContract {
        _id
        tokenType
        metadataStructure {
          ...METADTA_STRUCTURE_ATTRIBUTES
        }
      }
    }
    tokenContract {
      _id
      contractName
      contractAddress
      tokenType
      minters {
        _id
      }
      transaction {
        _id
        transactionHash
        status
        transactionType
        chainId
      }
      metadataStructure {
        ...METADTA_STRUCTURE_ATTRIBUTES
      }
    }
  }
  ${METADTA_STRUCTURE_ATTRIBUTES}
`;

export const GET_ITEMS_BY_INSTANCE_ID = gql`
  query itemsByInstanceId($instance: String!) {
    itemsByInstanceId(instance: $instance) {
      ...ITEMS_ATTRIBUTES
    }
  }
  ${ITEMS_ATTRIBUTES}
`;

export const CREATE_ITEM = gql`
  mutation items($input: inputItem!) {
    createItem(input: $input) {
      ...ITEMS_ATTRIBUTES
    }
  }
  ${ITEMS_ATTRIBUTES}
`;

export const UPDATE_ITEM = gql`
  mutation items($input: inputUpdateItem!) {
    updateItem(input: $input) {
      ...ITEMS_ATTRIBUTES
    }
  }
  ${ITEMS_ATTRIBUTES}
`;

export const DELETE_ITEMS = gql`
  mutation items($input: inputDeleteItems!) {
    deleteItems(input: $input)
  }
`;

export const GET_ITEM_UPDATE = gql`
  subscription onNewItem {
    onNewItem {
      ...ITEMS_ATTRIBUTES
    }
  }
  ${ITEMS_ATTRIBUTES}
`;
