import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const CustomExpansionPanel = withStyles(theme => ({
  root: {
    padding: 0,
    boxShadow: 'none',
    border: '1px solid #E5E5EA',
    '&$expanded': {
      backgroundColor: theme.palette.primary.lightest,
      margin: '0 0',
    },
    '&:not(:last-child)': {
      borderBottomWidth: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
}))(Accordion);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    minHeight: theme.typography.pxToRem(38),
    height: theme.typography.pxToRem(38),
    paddingLeft: theme.typography.pxToRem(15),
    paddingRight: theme.typography.pxToRem(15),
    '&$expanded': {
      minHeight: theme.typography.pxToRem(38),
    },
  },
  expanded: {},
}))(AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    backgroundColor: theme.palette.background.paper,
  },
}))(AccordionDetails);

const Typography = withStyles({
  button: {
    fontSize: '0.875rem',
  },
})(MuiTypography);

const ExpansionPanel = ({ summary, children, className }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExtendOnClick = () => setExpanded(!expanded);

  return (
    <CustomExpansionPanel className={className}>
      <ExpansionPanelSummary
        onClick={handleExtendOnClick}
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        IconButtonProps={{
          onClick: handleExtendOnClick,
          color: 'primary',
        }}
        id="panel-header"
      >
        <Typography variant="button">{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </CustomExpansionPanel>
  );
};

ExpansionPanel.defaultProps = {
  className: null,
};

ExpansionPanel.propTypes = {
  /**
   * The summary label.
   */
  summary: PropTypes.string.isRequired,
  /**
   * Additional classes to be applied to root element.
   */
  className: PropTypes.string,
  /**
   * Child components to be rendered within the content.
   */
  children: PropTypes.node.isRequired,
};

export default ExpansionPanel;
