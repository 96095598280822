import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import SideBar from './ey-ui-clone/SideBarComponent/SideBar';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import track from '../../mixpanel';
import { getIdTokenInfo } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.typography.pxToRem(300),
    backgroundColor: '#F6F6FA',
    float: 'left',
  },
}));

const SideNavComponent = ({ children, contentClassName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    notarizationAccess,
    tokensAccess,
    traceabilityAccess,
    mtrProcurement,
    authorizedPartiesAccess,
  } = useSubscriptionContext();
  const history = useHistory();
  const handleClick = url => () => {
    const tokenInfo = getIdTokenInfo();
    track('User clicks on side nav', {
      navigatingTo: url,
      navigatingFrom: pathname,
      date: moment().format('MMMM Do YYYY, h:mm:ss a'),
      auth0Id: tokenInfo.sub,
    });
    history.push(url);
  };

  const subscriptions = {
    '/organization': authorizedPartiesAccess,
    '/businessEcosystem': true,
    '/notarizations': notarizationAccess,
    '/tokens': tokensAccess,
    '/traceability/overview': traceabilityAccess,
    '/': true,
    '/demand': mtrProcurement,
    '/schedule': mtrProcurement,
    '/purchaseOrders': mtrProcurement,
    '/openOrderSummary': mtrProcurement,
  };

  const NAV_ITEMS = [
    {
      name: t('headers.landingPage'),
      url: '/',
      section: 1,
      onClick: handleClick('/'),
      selected: true,
    },
    {
      name: t('headers.myCompany'),
      url: '/myCompany',
      section: 1,
      onClick: handleClick('/myCompany'),
    },
    {
      name: t('headers.businessEcosystem'),
      url: '/businessEcosystem',
      onClick: handleClick('/businessEcosystem'),
      selected: false,
      section: 1,
    },
    {
      name: t('headers.contractManager'),
      url: '/',
      section: 2,
      selected: false,
      subItems: [
        // Uncomment to enable rfq -- https://eyblockchain.atlassian.net/browse/O40-2129
        // {
        //   name: t('rfqs.rfq'),
        //   url: '/rfq',
        //   onClick: handleClick('/rfq'),
        // },
        {
          name: t('headers.myOrganizations'),
          url: '/organization',
          onClick: handleClick('/organization'),
          selected: false,
          section: 1,
        },
        {
          name: t('common.contracts'),
          url: '/contracts',
          onClick: handleClick('/contracts'),
        },
        {
          name: t('headers.demand_forecast'),
          url: '/demand',
          onClick: handleClick('/demand'),
        },
        {
          name: t('pos.purchaseOrders'),
          url: '/purchase-orders',
          onClick: handleClick('/purchase-orders'),
        },
        {
          name: t('pos.purchaseOrders'),
          url: '/purchaseOrders',
          onClick: handleClick('/purchaseOrders'),
        },
        {
          name: t('pos.openOrderSummary'),
          url: '/openOrderSummary',
          onClick: handleClick('/openOrderSummary'),
        },
        {
          name: t('headers.production_schedule'),
          url: '/schedule',
          onClick: handleClick('/schedule'),
        },
        {
          name: t('headers.invoice_plural'),
          url: '/invoices',
          onClick: handleClick('/invoices'),
        },
      ],
      category: t('headers.category.applications'),
    },
    {
      name: t('headers.traceability'),
      url: '/traceability/overview',
      selected: false,
      onClick: handleClick('/traceability/overview'),
      section: 2,
      category: t('headers.category.applications'),
    },
    {
      name: t('headers.tokenization'),
      url: '/tokens',
      section: 2,
      onClick: handleClick('/tokens'),
      category: t('headers.category.coreServices'),
    },
    {
      name: t('headers.notarization'),
      url: '/notarizations',
      section: 2,
      onClick: handleClick('/notarizations'),
      category: t('headers.category.coreServices'),
    },
    // disable for public beta
    // {
    //   name: t('headers.product_plural'),
    //   url: '/products',
    //   onClick: handleClick('/products'),
    // },
  ];

  const applyFeatureFlagToNavItems = () => {
    return NAV_ITEMS.reduce((acc, curr) => {
      if (subscriptions[curr.url]) {
        let subItems = [];
        if (curr.subItems) {
          subItems = curr.subItems.filter(item => {
            return subscriptions[item.url] !== false;
          });

          acc.push({
            ...curr,
            subItems,
          });
        } else {
          acc.push(curr);
        }
      }
      return acc;
    }, []);
  };

  const findSelected = () => {
    const rootPath = pathname.split('/')[1];
    const networkProcurementPaths = [
      // 'partners',
      // 'rfq',
      'organization',
      'contracts',
      'demand',
      'purchase-orders',
      'purchaseOrders',
      'openOrderSummary',
      'schedule',
      'invoices',
    ];
    const productPaths = ['traceability'];
    const traceabilityPaths = [
      'overview',
      'instances',
      'instance-review',
      'items',
      'steps',
      'stepdata',
      'personalization',
    ];
    let pathName = '';
    const networkProcurementIndex = networkProcurementPaths.indexOf(rootPath);
    const productIndex = productPaths.indexOf(rootPath);
    const selectedObj = NAV_ITEMS.find(obj => obj.url === pathname);
    if (networkProcurementIndex > -1) {
      pathName = NAV_ITEMS.find(obj => obj.name === t('headers.contractManager')).subItems[
        networkProcurementIndex
      ].name;
    } else if (productIndex > -1) {
      const productPath = pathname.split('/')[2];
      const traceabilityIndex = traceabilityPaths.indexOf(productPath);
      if (traceabilityIndex > -1) {
        pathName = NAV_ITEMS.find(obj => obj.name === t('headers.traceability'))?.name;
      } else {
        pathName = selectedObj?.name;
      }
    } else {
      pathName = selectedObj?.name;
    }
    return pathName;
  };

  return (
    <SideBar
      variant="permanent"
      selected={findSelected()}
      className={classes.root}
      navigationList={applyFeatureFlagToNavItems()}
      contentClassName={contentClassName}
    >
      {children}
    </SideBar>
  );
};

SideNavComponent.propTypes = {
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
};

SideNavComponent.defaultProps = {
  contentClassName: null,
};

export default SideNavComponent;
