import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import { useLazyQuery } from '@apollo/react-hooks';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { GET_TRANSACTION_INFO } from '../../graphql/Shared/transaction';
import { delay } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  updateStatusContainer: {
    display: 'flex',
    padding: '1px 7px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '25px',
    color: theme.palette.primary.contrastText,
    marginLeft: '.5rem',
  },
  label: {
    fontSize: '.75em',
    marginTop: theme.spacing(3),
    fontFamily: 'EYInterstate Light',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '1rem',
    color: theme.palette.primary.light,
  },
  account: {
    fontSize: '1.125rem',
    fontFamily: 'EYInterstate',
    fontWeight: '400',
  },
  positionLoader: {
    position: 'relative',
    bottom: '3px',
    left: '10px',
  },
  panelRoot: {
    margin: 0,
    padding: 0,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.lightest,
  },
  panelExpanded: {
    borderLeftColor: theme.palette.secondary.main,
    margin: '0 !important',
  },
  panelSummaryRoot: {
    backgroundColor: theme.palette.primary.lightest,
    display: 'flex',
  },
  summaryIcon: {
    width: '4rem',
    height: '3rem',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '4rem',
    alignItems: 'center',
  },
  detailsRoot: {
    display: 'block',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    color: theme.palette.primary.light,
  },
}));

const GetUpdatedStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.updateStatusContainer}>
      <Typography variant="body2">{t('common.updated')}</Typography>
    </div>
  );
};

const BlockchainInfo = ({
  validateForm,
  values,
  contractAddress,
  contractName,
  method,
  methodArgs,
  byPassValidation,
  setIsLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const [transactionInfo, setTransactionInfo] = useState(null);
  const [isExpanded, setExpanded] = useState(false);
  const [transactionInfoUpdated, setTransactionInfoUpdated] = useState(false);

  const [getTransactionInfo, { loading }] = useLazyQuery(GET_TRANSACTION_INFO, {
    onCompleted: async data => {
      setTransactionInfo({
        balance: data?.transactionInfo?.balance,
        gasLimit: data?.transactionInfo?.gasLimit,
        gasPrice: data?.transactionInfo?.gasPrice,
        transactionFee: data?.transactionInfo?.gasPrice * data?.transactionInfo?.gasLimit,
      });
      if (transactionInfo) {
        setTransactionInfoUpdated(true);
        await delay(3000);
        setTransactionInfoUpdated(false);
      }
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleChange = () => async () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleValueChange = async () => {
      const errors = !byPassValidation ? await validateForm() : null;
      if (byPassValidation || Object.keys(errors).length === 0) {
        getTransactionInfo({ variables: { contractAddress, contractName, method, methodArgs } });
      }
    };

    handleValueChange();
  }, [values]);

  const currency = activeWallet?.blockchainType
    ? t(`tokens.blockchainTypes.${activeWallet.blockchainType}`)
    : t(`tokens.blockchainTypes.ETH`);

  return (
    <div className={classes.root}>
      <Accordion
        onChange={handleChange()}
        expanded={isExpanded}
        classes={{ root: classes.panelRoot, expanded: classes.panelExpanded }}
      >
        <AccordionSummary
          expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.panelSummaryRoot }}
        >
          <div className={classes.summaryIcon}>
            {loading ? (
              <CircularProgress color="primary" size={30} thickness={2} />
            ) : (
              <img src="/static/marketingImage.svg" alt="" />
            )}
          </div>
          <div>
            <Typography variant="body1" className={classes.title}>
              {t('common.blockchainInfo')}
            </Typography>
            <Typography variant="body1" className={classes.account}>
              {activeWallet?.name}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsRoot }}>
          {activeWallet && (
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.label}>{t('common.account')}</Typography>
                <Typography>{activeWallet?.walletName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>{t('common.balance')}</Typography>
                <Typography>
                  {transactionInfo
                    ? transactionInfo.balance.toFixed(10)
                    : activeWallet?.balance.toFixed(10)}{' '}
                  {currency}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>{t('common.network')}</Typography>
                <Typography>{activeWallet?.connectionName}</Typography>
              </Grid>
            </Grid>
          )}
          {transactionInfo && (
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.label}>{t('common.gasLimit')}</Typography>
                <div className={classes.valueContainer}>
                  <span className={`${loading ? classes.loading : ''}`}>
                    {transactionInfo?.gasLimit} {t('common.units')}
                  </span>{' '}
                  {transactionInfoUpdated && <GetUpdatedStatus />}
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>{t('common.gasPrice')}</Typography>
                <div className={classes.valueContainer}>
                  <span className={`${loading ? classes.loading : ''}`}>
                    {transactionInfo?.gasPrice.toFixed(10)} {currency}
                  </span>{' '}
                  {transactionInfoUpdated && <GetUpdatedStatus />}
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>{t('common.transactionFee')}</Typography>
                <div className={classes.valueContainer}>
                  <span className={`${loading ? classes.loading : ''}`}>
                    {transactionInfo?.transactionFee.toFixed(10)} {currency}
                  </span>{' '}
                  {transactionInfoUpdated && <GetUpdatedStatus />}
                </div>
              </Grid>
            </Grid>
          )}
          {!transactionInfo && (
            <Paper className={classes.paper}>
              <Typography className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {t('common.transactionMessage')}
              </Typography>
            </Paper>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

BlockchainInfo.propTypes = {
  values: PropTypes.shape({}).isRequired,
  validateForm: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  contractName: PropTypes.string,
  method: PropTypes.string.isRequired,
  methodArgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  byPassValidation: PropTypes.bool,
  setIsLoading: PropTypes.func,
};

BlockchainInfo.defaultProps = {
  contractName: '',
  contractAddress: '',
  byPassValidation: false,
  setIsLoading: x => x,
};

export default BlockchainInfo;
