import gql from 'graphql-tag';
import { PURCHASE_ORDER_ATTRIBUTES } from './purchaseOrder';

export const SIGN_DELIVERY = gql`
  mutation signDelivery(
    $purchaseOrderId: String!
    $buyerSignature: String!
    $actualDeliveryDate: String!
    $supplierOrganizationId: String!
  ) {
    signDelivery(
      purchaseOrderId: $purchaseOrderId
      buyerSignature: $buyerSignature
      actualDeliveryDate: $actualDeliveryDate
      supplierOrganizationId: $supplierOrganizationId
    ) {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const PURCHASE_ORDER_DELIVERED = gql`
  subscription onPoDelivered {
    poDelivered {
      _id
      purchaseOrderId
      status
    }
  }
`;
