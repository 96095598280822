import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => {
  return {
    root: {
      background: theme.palette.primary.lightest,
      padding: theme.spacing(2),
      color: '#747480',
    },
    title: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    poMasterDetails: {
      background: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(3),
    },
    typeFilter: {
      background: theme.palette.primary.lightest,
      padding: theme.spacing(3),
    },
    table: {
      width: '100%',
      '& .MuiTableCell-root': {
        padding: '8px',
      },
      '@global': {
        '*::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, .5)',
          borderRadius: '10px',
          border: '1px solid white',
        },
        '*::-webkit-scrollbar:horizontal': {
          height: '10px',
        },
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    link: {
      cursor: 'pointer',
      color: 'blue',
    },
    chip: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  };
});
