import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Table from '@eyblockchain/ey-ui/core/Table';
import Divider from '@material-ui/core/Divider';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { find } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import Breadcrumbs from '../../components/Shared/Breadcrumbs';
import PageLoader from '../../components/Shared/PageLoader';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import ItemDetails from '../../components/Traceability/WizardV2/ItemDetails';
import { TRACEABILITY_ROUTE } from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { useIngestionContext } from '../../contexts/Traceability/ingestion';
import { TRANSACTION_STATUS } from '../../graphql/Tokenization/token';
import { ITEM_CONTENT_FOR_INSTANCE } from '../../graphql/Traceability/itemContent';
import useUserInfo from '../../hooks/useUserInfo';
import DGPHeaderTitle from './DGPHeaderTitle';
import InstanceError from './InstanceError';

const useStyles = makeStyles(theme => ({
  portalHistoryRoot: {
    background: theme.palette.primary.lightest,
    paddingTop: theme.spacing(1),
    minHeight: '100vh',
  },
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      marginBottom: '1rem',
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
      },
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-root': {
      padding: '11px',
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  draftChip: {
    border: `1px solid ${theme.colors.hyperLinkBlue}`,
    color: theme.colors.hyperLinkBlue,
  },
  blockchainChip: {
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
    '& .MuiChip-icon': {
      color: theme.palette.success.main,
    },
  },
  pendingChip: {
    border: `1px solid ${theme.palette.primary.dark}`,
    '& .MuiChip-icon': {
      color: theme.palette.primary.dark,
    },
  },
  failedChip: {
    border: `1px solid ${theme.palette.primary.lighter}`,
    color: theme.palette.primary.lighter,
    '& .MuiChip-icon': {
      color: theme.palette.primary.lighter,
    },
  },
  toolbar: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    background: '#c4c4cd57',
    width: '100%',
    margin: '0',
    marginTop: '0.5rem',
  },
  accordionPaper: {
    margin: theme.spacing(3),
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
}));

const PortalHistory = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { handleNotification } = useNotification();

  const {
    instanceDetails,
    isDataLoading,
    instanceId,
    instanceNotFound,
    errorLoadingInstance,
  } = useConfigurationWizardContext();
  const { activeWallet } = useBecOrganizationContext();
  const { steps } = instanceDetails?.content;
  const {
    draft: { setDraftValue },
  } = useIngestionContext();
  const [ingHistoryData, setIngHistoryData] = useState([]);
  const [isDataProcessing, setDataProcessing] = useState(true);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [currentWallet] = useState(activeWallet);

  const {
    permissionsFlags: {
      isUserAuthToViewRecordInDGP,
      isUserAuthToViewAllRecordsInDGP,
      isUserAuthToInsertRecordInDGP,
      isUserAuthToViewValueChainDetails,
    },
  } = useUserInfo();

  const buildItemRowDetails = itemContent => {
    return {
      token: itemContent.content,
      itemType: itemContent.item?.name,
      stepName: itemContent.step?.name,
      ingStatus: itemContent?.latestTransaction?.status,
      ingTime: itemContent.token?.latestTransaction.createdAt
        ? moment(itemContent.token?.latestTransaction.createdAt).format('YYYY/MM/DD')
        : '-',
      itemContentId: itemContent._id,
      itemId: itemContent.item?._id,
      stepId: itemContent.step?._id,
      parentItemName: find(steps, { _id: itemContent.step?._id })?.involvedItem?.parentItem?.name,
      parentItemContentId: itemContent?.linkedToken
        ? [itemContent?.linkedToken?.tokenId]
        : itemContent?.metadata?.links?.children?.map(({ id }) => id) ||
          itemContent?.metadata?.dependentItems?.map(({ id }) => id),
      metadata: {
        metadata: itemContent?.linkedToken
          ? itemContent?.linkedToken?.metadata
          : itemContent?.metadata,
      },
      transactionHash: itemContent?.latestTransaction?.transactionHash,
      isDraft: itemContent.isDraft,
      tokenMetadataConfig: itemContent?.linkedToken
        ? itemContent?.linkedToken?.metadataStructure?.metadataConfig
        : itemContent?.token?.metadataStructure?.metadataConfig.filter(
            config => config?.step?._id === itemContent.step?._id,
          ),
      amount: itemContent?.amount,
      parentTokenAmount:
        itemContent?.metadata?.links?.children?.map(({ id, amount }) => ({
          tokenId: id,
          amount,
        })) ||
        itemContent?.metadata?.dependentItems?.map(({ id, amount }) => ({ tokenId: id, amount })),
      linkedTokenDetails: itemContent?.linkedToken,
    };
  };

  const [getItemContentForInstance] = useLazyQuery(ITEM_CONTENT_FOR_INSTANCE, {
    fetchPolicy: 'no-cache',
    onCompleted: async data => {
      if (data.itemContentsForInstanceId) {
        const rows = data.itemContentsForInstanceId.map(itemContent => {
          return buildItemRowDetails(itemContent);
        });
        setIngHistoryData(rows);
      }
      setDataProcessing(false);
    },
    onError: () => {
      handleNotification(t('traceability.ingestionHistory.fetchError'), 'error');
    },
  });

  const instanceName = instanceDetails?.content?.name;

  const columnDefs = () => {
    const customOptions = () => {
      return {
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      };
    };

    const customChipBody = () => {
      const options = customOptions();
      options.customBodyRender = (data, tableMeta) => {
        const isDraft = tableMeta.rowData[12];
        switch (data) {
          case 'completed':
            return (
              <Chip
                label={t('traceability.ingestionHistory.statusCodes.completed')}
                className={classes.blockchainChip}
                icon={<CheckOutlinedIcon />}
                variant="outlined"
              />
            );
          case 'pending':
            return (
              <Chip
                icon={<MoreHorizOutlinedIcon />}
                label={t('traceability.ingestionHistory.statusCodes.pending')}
                className={classes.pendingChip}
                variant="outlined"
              />
            );
          case 'failed':
            return (
              <Chip
                icon={<CloseOutlinedIcon />}
                label={t('traceability.ingestionHistory.statusCodes.failed')}
                className={classes.failedChip}
                variant="outlined"
              />
            );
          default:
            return isDraft ? (
              <Chip
                label={t('traceability.ingestionHistory.statusCodes.savedAsDraft')}
                className={classes.draftChip}
                variant="outlined"
              />
            ) : (
              <Chip
                label={t('traceability.ingestionHistory.statusCodes.completed')}
                className={classes.blockchainChip}
                icon={<CheckOutlinedIcon />}
                variant="outlined"
              />
            );
        }
      };

      return options;
    };

    const hiddenBody = () => ({
      filter: false,
      display: false,
    });

    return [
      {
        name: 'token',
        label: t('traceability.ingestionHistory.table.codeColumn'),
        options: customOptions(),
      },
      {
        name: 'itemType',
        label: t('traceability.ingestionHistory.table.itemNameColumn'),
        options: customOptions(),
      },
      {
        name: 'stepName',
        label: t('traceability.ingestionHistory.table.stepColumn'),
        options: customOptions(),
      },
      {
        name: 'ingStatus',
        label: t('traceability.ingestionHistory.table.statusColumn'),
        options: customChipBody(),
      },
      {
        name: 'ingTime',
        label: t('traceability.ingestionHistory.table.timeColumn'),
        options: customOptions(),
      },
      {
        name: 'itemContentId',
        options: hiddenBody(),
      },
      {
        name: 'itemId',
        options: hiddenBody(),
      },
      {
        name: 'stepId',
        options: hiddenBody(),
      },
      {
        name: 'parentItemName',
        options: hiddenBody(),
      },
      {
        name: 'parentItemContentId',
        options: hiddenBody(),
      },
      {
        name: 'metadata',
        options: hiddenBody(),
      },
      {
        name: 'transactionHash',
        options: hiddenBody(),
      },
      {
        name: 'isDraft',
        options: hiddenBody(),
      },
      {
        name: 'tokenMetadataConfig',
        options: hiddenBody(),
      },
      {
        name: 'amount',
        options: hiddenBody(),
      },
      {
        name: 'parentTokenAmount',
        options: hiddenBody(),
      },
      {
        name: 'linkedTokenDetails',
        options: hiddenBody(),
      },
    ];
  };

  useEffect(() => {
    if (instanceId && !isDataLoading) {
      getItemContentForInstance({ variables: { input: instanceId } });
    }
  }, [instanceId, isDataLoading]);

  useEffect(() => {
    if (currentWallet !== activeWallet) {
      history.push(TRACEABILITY_ROUTE);
    }
  }, [activeWallet]);

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const { transactionHash, status } = data.subscriptionData?.data?.transactionStatus;
      const modifiedIngHistoryData = ingHistoryData.map(curRowData => {
        if (curRowData.transactionHash === transactionHash) {
          return { ...curRowData, transactionHash, ingStatus: status };
        }
        return { ...curRowData };
      });
      setIngHistoryData(modifiedIngHistoryData);
    },
  });

  if (errorLoadingInstance || instanceNotFound) return <InstanceError />;

  if (isDataLoading || isDataProcessing) {
    return <PageLoader />;
  }

  const generateBreadCrumbs = () => {
    const breadCrumbsData = {
      name: t('common.back'),
      path: `/traceability/overview`,
    };

    if (isUserAuthToInsertRecordInDGP) {
      breadCrumbsData.name = instanceName;
      breadCrumbsData.path = `/traceability/data-gathering-portal/${instanceId}`;
    } else if (isUserAuthToViewValueChainDetails) {
      breadCrumbsData.name = instanceName;
      breadCrumbsData.path = `/traceability/instance-review/${instanceId}`;
    }
    return <Breadcrumbs breadCrumbsData={[breadCrumbsData]} />;
  };

  return (
    <div className={classes.portalHistoryRoot}>
      {generateBreadCrumbs()}
      <Divider className={classes.divider} />
      {!(isUserAuthToViewAllRecordsInDGP || isUserAuthToViewRecordInDGP) ? (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('traceability.forbiddenDGPHistory')}
          </Typography>
        </Paper>
      ) : (
        <>
          <Table
            title={
              <DGPHeaderTitle
                instanceName={instanceName}
                subHeaderTitle={t('traceability.dgp.registeredData')}
              />
            }
            className={classes.table}
            columns={columnDefs()}
            data={ingHistoryData}
            options={{
              textLabels: {
                body: {
                  noMatch: t('common.noMatchingRecords'),
                },
                pagination: {
                  rowsPerPage: t('common.rowsPerPage'),
                },
                responsive: 'stacked',
              },
              filter: false,
              search: false,
              searchOpen: false,
              elevation: 0,
              pagination: true,
              onRowClick: rowData => {
                const isDraft =
                  rowData[3]?.props?.label ===
                  t('traceability.ingestionHistory.statusCodes.savedAsDraft');
                if (isDraft) {
                  setDraftValue({
                    itemContentId: rowData[5],
                    instanceId,
                    tokenId: rowData[0],
                    itemId: rowData[6],
                    stepId: rowData[7],
                    parentItemContentId: rowData[9],
                    metadata: rowData[10],
                    amount: rowData[14],
                    parentTokenAmount: rowData[15],
                    linkedTokenDetails: rowData[16],
                  });
                  history.push(`/traceability/data-gathering-portal/${instanceId}`);
                } else if (
                  rowData[3]?.props?.label ===
                  t('traceability.ingestionHistory.statusCodes.completed')
                ) {
                  setCurrentRowData([...rowData]);
                  setShowItemDetails(true);
                }
              },
            }}
          />
          <ItemDetails
            tokenId={currentRowData[0]}
            metadata={currentRowData[10]}
            metadataConfigs={currentRowData[13]}
            transactionHash={currentRowData[11]}
            open={showItemDetails}
            stepName={currentRowData[2]}
            itemName={currentRowData[1]}
            itemId={currentRowData[6]}
            dependentItemName={currentRowData[8]}
            dependentItemIds={currentRowData[9]}
            amount={currentRowData[14]}
            parentTokenAmount={currentRowData[15]}
            closeModal={() => {
              setShowItemDetails(false);
              setCurrentRowData([]);
            }}
          />
        </>
      )}
    </div>
  );
};

export default PortalHistory;
