import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Loader from '@eyblockchain/ey-ui/core/Loader';

const useStyles = makeStyles(() => ({
  loadContainer: {
    position: 'relative',
    height: '100vh',
  },
  loader: {
    position: 'inherit',
    top: 125,
    left: '50%',
  },
}));

const PageLoader = ({ classes }) => {
  const defaultClasses = useStyles();

  return (
    <div className={clsx(defaultClasses.loadContainer, classes.loadContainer)}>
      <Loader className={clsx(defaultClasses.loader, classes.loader)} />
    </div>
  );
};

PageLoader.propTypes = {
  classes: PropTypes.shape({
    loadContainer: PropTypes.string,
    loader: PropTypes.string,
  }),
};

PageLoader.defaultProps = {
  classes: {},
};

export default PageLoader;
