import gql from 'graphql-tag';

export const CODIFICATION_TYPE_ATTRIBUTES = gql`
  fragment CODIFICATION_TYPE_ATTRIBUTES on CodificationType {
    _id
    name
  }
`;

export const GET_CODIFICATION_TYPES = gql`
  query codificationTypes {
    codificationTypes {
      ...CODIFICATION_TYPE_ATTRIBUTES
    }
  }
  ${CODIFICATION_TYPE_ATTRIBUTES}
`;

export const GET_CODIFICATION_TYPE_BY_ID = gql`
  query codificationType($codificationTypeId: String!) {
    codificationType(_id: $codificationTypeId) {
      ...CODIFICATION_TYPE_ATTRIBUTES
    }
  }
  ${CODIFICATION_TYPE_ATTRIBUTES}
`;
