import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  label: {
    float: 'right',
    fontFamily: 'EYInterstate',
    fontSize: 10,
    fontWeight: 300,
    letterSpacing: 0.03,
  },
  marker: {
    width: 1,
    position: 'absolute',
    bottom: 0,
  },
}));

const VolumeMarker = props => {
  const { markerStyles, label, rebateContract, final, purchaseOrderFound } = props;
  const classes = useStyles();
  return (
    <div className={classes.marker} style={{ ...markerStyles }}>
      {!rebateContract && (
        <>
          <Typography
            className={classes.label}
            style={{ width: 50 }}
          >{`${label} units`}</Typography>
          <Typography className={classes.label} style={{ width: 63 }}>
            for purchase
          </Typography>
        </>
      )}
      {rebateContract && label > 0 && final === false && (
        <>
          <Typography
            className={classes.label}
            style={{ width: 50 }}
          >{`${label} quantities`}</Typography>
          <Typography className={classes.label} style={{ width: 50 }}>
            ordered
          </Typography>
        </>
      )}
      {rebateContract && label > 0 && final === true && !purchaseOrderFound && (
        <>
          <Typography
            className={classes.label}
            style={{ width: 50 }}
          >{`${label} quantities`}</Typography>
          <Typography className={classes.label} style={{ width: 50 }}>
            needed for next threshold
          </Typography>
        </>
      )}
      {rebateContract && label === 0 && final === true && !purchaseOrderFound && (
        <>
          <Typography className={classes.label} style={{ width: 50 }}>
            reached maximum
          </Typography>
          <Typography className={classes.label} style={{ width: 50 }}>
            threshold
          </Typography>
        </>
      )}
      {rebateContract && label > 0 && final === true && purchaseOrderFound && (
        <>
          <Typography
            className={classes.label}
            style={{ width: 50 }}
          >{`${label} quantities`}</Typography>
          <Typography className={classes.label} style={{ width: 50 }}>
            ordered
          </Typography>
        </>
      )}
    </div>
  );
};

VolumeMarker.propTypes = {
  markerStyles: PropTypes.shape({
    background: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.number.isRequired,
  rebateContract: PropTypes.bool,
  final: PropTypes.bool,
  purchaseOrderFound: PropTypes.bool,
};

VolumeMarker.defaultProps = {
  rebateContract: false,
  final: false,
  purchaseOrderFound: false,
};

export default VolumeMarker;
