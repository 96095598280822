import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import MandatoryLabel from './MandatoryLabel';

const useStyles = makeStyles(theme => ({
  stepContent: {
    display: 'grid',
    gridTemplateColumns: '27% 27% 12% 27% 5%',
    padding: theme.spacing(1),
    gridGap: '0.5%',
    position: 'relative',
  },
  checkField: {
    justifySelf: 'center',
  },
  selectField: {
    minWidth: '25%',
  },
  stepNameInput: {
    minHeight: '0px',
  },
  stepNameRoot: {
    minWidth: '25%',
  },
  deleteButton: {
    height: 'max-content',
  },
  switchButton: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.success.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const StepContent = ({
  field: { name, value: stepContent },
  form: { errors, setFieldValue },
  index,
  onRemove,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const parentArrayName = name && name.split('.')[0];
  let stepErrors;

  const [checkboxState, setCheckboxState] = useState(false);
  const { instanceDetails, ingestionTypes } = useConfigurationWizardContext();

  const { ingestionTypes: ingestionTypeRows } = ingestionTypes;
  const { items: involvedItemRows } = instanceDetails?.content;

  const involvedItemOptions = [];
  const ingestionTypeOptions = [];

  involvedItemRows.forEach(item => {
    involvedItemOptions.push({ label: item.name, value: item.name });
  });

  ingestionTypeRows.forEach(ingestionTypeRow => {
    ingestionTypeOptions.push({ label: ingestionTypeRow.name, value: ingestionTypeRow.name });
  });

  stepErrors = null;
  if (!isEmpty(errors) && errors[parentArrayName]) {
    stepErrors = errors[parentArrayName][index];
  }

  const fieldNames = {
    stepName: `step-${index}-stepName`,
    involvedItem: `step-${index}-involvedItem`,
    createToken: `step-${index}-createToken`,
    ingestionType: `step-${index}-ingestionType`,
  };

  const itemInstances = instanceDetails?.content?.items;

  const disableCheckbox = value => {
    const tokenisedItems = [];
    for (let i = 0; i < itemInstances.length; i += 1) {
      if (itemInstances[i].tokenize === true) {
        tokenisedItems.push(itemInstances[i].name);
      }
    }
    if (tokenisedItems.includes(value)) {
      setCheckboxState(false);
    } else {
      setCheckboxState(true);
    }
  };
  const stepChangeHandler = event => {
    const modifiedField = event?.target?.name;
    let newValue;
    if (event?.target?.type === 'checkbox') {
      newValue = event?.target?.checked;
    } else {
      newValue = event?.target?.value;
    }

    if (!modifiedField) {
      throw new Error('Invalid field or value');
    }

    switch (modifiedField) {
      case fieldNames.stepName:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...stepContent,
          stepName: newValue,
          dirty: true,
        });
        break;
      case fieldNames.involvedItem:
        disableCheckbox(newValue);
        setFieldValue(`${parentArrayName}.${index}`, {
          ...stepContent,
          involvedItem: newValue,
          dirty: true,
        });
        break;
      case fieldNames.ingestionType:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...stepContent,
          ingestionType: newValue,
          dirty: true,
        });
        break;
      case fieldNames.createToken:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...stepContent,
          createToken: newValue,
          dirty: true,
        });
        break;
      default:
        break;
    }
  };

  const buildOptions = option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  );
  useEffect(() => {
    disableCheckbox(stepContent.involvedItem);
  });
  return (
    <div className={classes.stepContent}>
      <TextField
        name={fieldNames.stepName}
        label={
          <MandatoryLabel
            text={t('traceability.configurationWizard.steps.stepName')}
            error={stepErrors && !!stepErrors.stepName}
          />
        }
        size="small"
        variant="outlined"
        value={stepContent.stepName}
        onChange={stepChangeHandler}
        className={classes.stepNameRoot}
        InputProps={{
          classes: {
            input: classes.stepNameInput,
          },
        }}
      />
      <TextField
        select
        size="small"
        variant="outlined"
        label={
          <MandatoryLabel
            text={t('traceability.configurationWizard.steps.involvedItem')}
            error={stepErrors && !!stepErrors.involvedItem}
          />
        }
        name={fieldNames.involvedItem}
        onChange={stepChangeHandler}
        disabled={!involvedItemOptions.length}
        value={stepContent.involvedItem}
        classes={{
          root: classes.selectField,
        }}
      >
        {involvedItemOptions.map(buildOptions)}
      </TextField>

      <div className={classes.checkField} data-testid="create-token-switch-stage2">
        <FormControlLabel
          control={
            <Switch
              className={classes.switchButton}
              name={fieldNames.createToken}
              checked={stepContent.createToken}
              disabled={checkboxState}
              color="primary"
              onChange={stepChangeHandler}
            />
          }
          label={
            stepContent.createToken
              ? t('traceability.traceItemForm.labels.yes')
              : t('traceability.traceItemForm.labels.no')
          }
        />
      </div>

      <TextField
        select
        size="small"
        variant="outlined"
        label={
          <MandatoryLabel
            text={t('traceability.configurationWizard.steps.ingestionType')}
            error={stepErrors && !!stepErrors.ingestionType}
          />
        }
        name={fieldNames.ingestionType}
        onChange={stepChangeHandler}
        disabled
        value={t(stepContent.ingestionType)}
        classes={{
          root: classes.selectField,
        }}
      >
        {ingestionTypeOptions.map(buildOptions)}
      </TextField>

      <Button className={classes.deleteButton} onClick={onRemove}>
        <DeleteIcon />
      </Button>
    </div>
  );
};

StepContent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({}),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

StepContent.defaultProps = {};

export default StepContent;
