import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageLoader from '../../Shared/PageLoader';
import RegisteredDocsTable from './RegisteredDocsTable';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import useUserInfo from '../../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  emptyDocumentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  startNowButton: {
    marginTop: '2rem',
  },
  emptyStartBanner: {
    marginTop: '2rem',
    fontWeight: 'initial',
  },
}));
const RegisteredDocuments = () => {
  const {
    setDocumentUploadVisible,
    getDocumentsForContract,
    isDocsForContractLoading,
    notarizedDocs,
    selectedNotarizationContract,
  } = useNotarizationContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const { permissionsFlags: { isUserAuthToNotarizeDocuments, isUserAuthToVerifyDocuments } } = useUserInfo();

  const handleStart = () => {
    setDocumentUploadVisible(true);
  };

  useEffect(() => {
    if (selectedNotarizationContract) {
      getDocumentsForContract({ variables: { contractId: selectedNotarizationContract?._id } });
    }
  }, [getDocumentsForContract, selectedNotarizationContract]);

  if (isDocsForContractLoading) {
    return <PageLoader />;
  }

  if (!notarizedDocs || notarizedDocs?.length < 1) {
    return (
      <div className={classes.emptyDocumentContainer}>
        <Typography
          variant="h5"
          className={classes.emptyStartBanner}
          data-testid="notarization-document-emptyStartBanner"
        >
          {t('notarization.noDocument')}
        </Typography>
        {!isUserAuthToNotarizeDocuments && (
          <Typography variant="h5" className={classes.emptyStartBanner}>
            {isUserAuthToVerifyDocuments
              ? t('notarization.noNotarizeDocsAuthorization')
              : t('notarization.noVerifyRegisterAuthorization')}
          </Typography>
        )}
        {isUserAuthToVerifyDocuments && (
          <Button
            className={classes.startNowButton}
            variant="contained"
            color="primary"
            onClick={handleStart}
            data-testid="notarization-startnow"
          >
            {isUserAuthToNotarizeDocuments ? t('notarization.startNow') : t('notarization.verify')}
          </Button>
        )}
      </div>
    );
  }

  return <RegisteredDocsTable />;
};
export default RegisteredDocuments;
