import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { getCurrGeoLocation, checkGeoLocationSupported } from '../../utils';
import { useNotification } from '../../contexts/Shared/notification';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: 'fit-content',
    width: 'fit-content',
  },
}));

const LocationButton = ({ onLocationReceived, disabled: inpDisabled, ...other }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleNotification } = useNotification();

  const [isFetching, setIsFetching] = useState(false);

  const checkGeoLocationDisabled = () => {
    if (inpDisabled) return true;
    if (!checkGeoLocationSupported()) return true;

    return false;
  };

  const isLocationDisabled = checkGeoLocationDisabled();

  const getToolTip = () => {
    if (isLocationDisabled) return t('tokens.location.autoDetectDisabled');
    if (isFetching) return t('tokens.location.autoDetectFetching');

    return t('tokens.location.autoDetectEnabled');
  };

  const onButtonClick = async () => {
    try {
      setIsFetching(true);
      const locData = await getCurrGeoLocation();
      onLocationReceived(locData);
    } catch (err) {
      handleNotification(t('tokens.location.locationError'), 'error');
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Tooltip title={getToolTip()}>
      <span className={classes.iconWrapper}>
        <IconButton onClick={onButtonClick} disabled={isLocationDisabled || isFetching} {...other}>
          <MyLocationIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

LocationButton.propTypes = {
  disabled: PropTypes.bool,
  onLocationReceived: PropTypes.func.isRequired,
};

LocationButton.defaultProps = {
  disabled: false,
};

export default LocationButton;
