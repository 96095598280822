import React from 'react';
import clsx from 'clsx';
import BecFramework from '@eyblockchain/ey-ui/core/BecFramework';
import Snackbar from '@eyblockchain/ey-ui/core/Snackbar';
import PropTypes from 'prop-types';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { EYUILanguageContext, languages } from '@eyblockchain/ey-ui/core/locale';
import SideNav from './SideNav';
import { useNotification } from '../../contexts/Shared/notification';
import i18n from '../../i18n';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import InsufficientFunds from './InsufficientFunds';
import LiveTransactionsPanel from './LiveTransactionsPanel';
import { PAGE_HEADERS } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    minHeight: '100vh',
    maxWidth: '100%',
    background: theme.palette.primary.contrastText,
  },
  container: {
    flexGrow: 1,
  },
  main: {
    maxWidth: `calc(100% - ${theme.typography.pxToRem(300)})`,
  },
  content: {
    width: '100%',
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  positionElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}));

const PageLayout = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    displayNotification,
    closeNotification,
    notificationMessage,
    notificationVariant,
    showFaucetModal,
  } = useNotification();

  const { subscription, accessLiveTransactionsPanel } = useSubscriptionContext();

  const paths = pathname.split('/');
  const logoHeader = i18n.t(PAGE_HEADERS[paths[1]] || PAGE_HEADERS.default);

  return (
    <>
      <Main maxWidth="xl" className={classes.root}>
        <EYUILanguageContext.Provider value={languages[i18n.language || 'en']}>
          <BecFramework
            applicationName={logoHeader}
            LogoProps={{ href: '/' }}
            defaultLanguage={i18n.language}
            showOrganizationWallet
            ignoreOldBec
          />

          {!subscription ? (
            <div className={classes.positionElement}>
              <Typography variant="h2">{i18n.t('common.subscriptionRequired')}</Typography>
            </div>
          ) : (
            <>
              <SideNav contentClassName={clsx(classes.main, classes.content)}>
                <Grid container direction="column" className={classes.container}>
                  <Snackbar
                    open={!showFaucetModal && displayNotification}
                    onClose={closeNotification}
                    message={notificationMessage}
                    variant={notificationVariant}
                  />
                  <Grid item className={classes.content}>
                    <InsufficientFunds />
                    {accessLiveTransactionsPanel && pathname.indexOf('tokens') !== -1 && (
                      <LiveTransactionsPanel />
                    )}
                    {children}
                  </Grid>
                </Grid>
              </SideNav>
            </>
          )}
        </EYUILanguageContext.Provider>
      </Main>
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
