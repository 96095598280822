import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  listCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
    },
  },
  selectedBox: {
    width: '4px',
    backgroundColor: theme.palette.secondary.main,
  },
  titleArea: {
    margin: theme.spacing(2),
  },
}));

const StepListCard = ({ selectedIndex, handleStepClick, stepName, stepId, isFormPreparing }) => {
  const classes = useStyles();

  const isSelected = selectedIndex === stepId;

  const cardClass = clsx(classes.listCardRoot, {
    [classes.selectedCard]: isSelected,
  });

  const handleCardClick = () => {
    if (isFormPreparing) return;
    handleStepClick(stepId);
  };

  return (
    <Card variant="outlined" className={cardClass} onClick={handleCardClick}>
      {isSelected && <div className={classes.selectedBox} />}
      <div className={classes.titleArea}>
        <Typography variant="h5">{stepName}</Typography>
      </div>
    </Card>
  );
};

StepListCard.propTypes = {
  selectedIndex: PropTypes.string.isRequired,
  handleStepClick: PropTypes.func.isRequired,
  stepName: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
  isFormPreparing: PropTypes.bool.isRequired,
};

export default StepListCard;
