import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@eyblockchain/ey-ui/core/Table';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  table: {
    '& .MuiTableCell-head': {
      paddingTop: `${theme.spacing(0)} !important`,
      color: theme.palette.primary.dark,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
      },
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(2),
    },
  },
}));

const DataStructureView = ({ formValues, viewEditMode, columnDefs, hideEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        {!hideEdit && (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={viewEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
      <div>
        <Table
          columns={columnDefs}
          className={classes.table}
          data={formValues}
          options={{
            filter: false,
            searchOpen: false,
            search: false,
            elevation: 0,
            textLabels: { body: { noMatch: t('tokens.addDataInstruction') } },
          }}
        />
      </div>
    </div>
  );
};

DataStructureView.propTypes = {
  hideEdit: PropTypes.bool,
  viewEditMode: PropTypes.func.isRequired,
  formValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DataStructureView.defaultProps = {
  hideEdit: false,
};

export default DataStructureView;
