import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import NavItem from './subcomponents/NavItem';

const useStyles = makeStyles(theme => ({
  light: {
    border: '1px solid transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.contrastText}`,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
    },
  },
  dark: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: '1px solid transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      backgroundColor: '#1a1a24',
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
    },
  },
  listRoot: {
    backgroundColor: '#F0F0F2',
    boxShadow: 'inset -1px 0 0 0 #E7E7EA',
  },
  categorySubItems: {
    paddingLeft: theme.spacing(1.5),
  },
}));

const NestedList = ({
  parentItem,
  selected,
  variant,
  classes,
  onItemClick,
  CollapseProps,
  hasCategory,
  ...props
}) => {
  const { icon, name, subItems } = parentItem;
  const defaultClasses = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <ListItem
        button
        className={clsx({
          [classes.parentRoot]: classes.parentRoot,
          [defaultClasses.light]: variant === 'light',
          [defaultClasses.dark]: variant === 'dark',
        })}
        onClick={handleClick}
        {...props}
      >
        {icon && <Box pr={2}>{icon}</Box>}
        <ListItemText
          primary={name}
          classes={{
            primary: clsx({
              [classes.rootPrimaryText]: classes.rootPrimaryText,
              [defaultClasses.categorySubItems]: hasCategory && defaultClasses.categorySubItems,
              [classes.selectedItemPrimaryText]: open && classes.selectedItemPrimaryText,
            }),
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit {...CollapseProps}>
        <List
          component="div"
          className={clsx({
            [defaultClasses.listRoot]: true,
            [classes.listRoot]: classes.listRoot,
          })}
          disablePadding
        >
          {subItems.map(item => (
            <NavItem
              key={item.name.toLowerCase().replace(' ', '_')}
              item={
                item.onClick
                  ? {
                      ...item,
                      onClick: () => {
                        if (item.onClick) item.onClick();
                        if (onItemClick) onItemClick();
                      },
                    }
                  : item
              }
              selected={selected}
              variant={variant}
              hasCategory={hasCategory}
              isItemInsideList
              classes={{
                root: classes.listItemRoot,
                selected: classes.selectedItem,
                selectedPrimaryText: classes.selectedItemPrimaryText,
                primaryText: classes.listItemPrimaryText,
              }}
            />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

NestedList.propTypes = {
  /**
   * Data for the root item
   */
  parentItem: PropTypes.shape({
    /**
     * Optional icon element to display next to name
     */
    icon: PropTypes.element,
    /**
     * Root item label
     */
    name: PropTypes.string.isRequired,
    /**
     * Array of items to display in the collapsible section
     */
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
  }).isRequired,
  /**
   * Name of the currently selected item
   */
  selected: PropTypes.string,
  /**
   * Variant to render the component in light or dark
   */
  variant: PropTypes.oneOf(['light', 'dark']),
  /**
   * Class overrides
   */
  classes: PropTypes.shape({
    root: PropTypes.string,
    parentRoot: PropTypes.string,
    rootPrimaryText: PropTypes.string,
    listRoot: PropTypes.string,
    listItemRoot: PropTypes.string,
    selectedItem: PropTypes.string,
    selectedItemPrimaryText: PropTypes.string,
    listItemPrimaryText: PropTypes.string,
  }),
  /**
   * Optional callback called after clicking any subitem
   */
  onItemClick: PropTypes.func,
  /**
   * Props passed to Collapse component
   */
  CollapseProps: PropTypes.shape({}),
  hasCategory: PropTypes.bool,
};

NestedList.defaultProps = {
  selected: '',
  variant: 'light',
  classes: {},
  onItemClick: null,
  CollapseProps: null,
  hasCategory: false,
};

export default NestedList;
