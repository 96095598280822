import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  instanceMenuRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(1),
  },
}));

const InstanceMenu = ({
  rowStatus,
  rowId,
  rowName,
  dialogState,
  clickedItem,
  setInstanceId,
  setInstanceName,
  setModalTitle,
  setModalDescription,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    permissionsFlags: { isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setInstanceId(rowId);
    setAnchorEl(null);
  };

  const handleActivate = async event => {
    dialogState(true);
    setInstanceId(rowId);
    setModalTitle(t('traceability.instanceReview.activateValueChain'));
    setModalDescription(
      `${t('traceability.instanceReview.activateValueChainAlert')} ${rowId}. ${t(
        'common.continue',
      )}`,
    );
    clickedItem(t('traceability.instanceStatus.active'));
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeactivate = async event => {
    setInstanceId(rowId);
    setInstanceName(rowName);
    dialogState(true);
    clickedItem(t('traceability.instanceStatus.deactivated'));
    setModalTitle(t('traceability.instanceReview.deactivateValueChain'));
    setModalDescription(
      `${t('traceability.instanceReview.deactivateValueChainAlert')} ${rowId}. ${t(
        'common.continue',
      )}`,
    );
    event.stopPropagation();
    setAnchorEl(null);
  };

  const buildMenu = () => {
    if (!rowStatus || !rowId) {
      return null;
    }

    const editMenuItem = (
      <MenuItem onClick={handleEdit} key={uniqid()}>
        {t('traceability.instanceReview.editValueChain')}
      </MenuItem>
    );

    const deactivateMenuItem = (
      <MenuItem onClick={handleDeactivate} key={uniqid()}>
        {t('traceability.instanceReview.deactivate')}
      </MenuItem>
    );

    const activateMenuItem = (
      <MenuItem onClick={handleActivate} key={uniqid()}>
        {t('traceability.instanceReview.activate')}
      </MenuItem>
    );

    switch (rowStatus) {
      case t('traceability.instanceStatus.config'):
        return editMenuItem;
      case t('traceability.instanceStatus.active'):
        return isUserAuthToViewValueChainDetails
          ? [editMenuItem, deactivateMenuItem]
          : deactivateMenuItem;
      case t('traceability.instanceStatus.deactivated'):
        return isUserAuthToViewValueChainDetails
          ? [editMenuItem, activateMenuItem]
          : activateMenuItem;
      default:
        return null;
    }
  };

  return (
    <div className={classes.InstanceMenuRoot}>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id={`instance-menu-${rowName}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {buildMenu()}
      </Menu>
    </div>
  );
};

InstanceMenu.propTypes = {
  rowStatus: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  rowName: PropTypes.string.isRequired,
  dialogState: PropTypes.func.isRequired,
  clickedItem: PropTypes.func.isRequired,
  setInstanceId: PropTypes.func.isRequired,
  setInstanceName: PropTypes.func.isRequired,
  setModalTitle: PropTypes.func.isRequired,
  setModalDescription: PropTypes.func.isRequired,
};

InstanceMenu.defaultProps = {};

export default InstanceMenu;
