import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Table from '@eyblockchain/ey-ui/core/Table';
import { countBy } from 'lodash';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
// import GetAppIcon from '@material-ui/icons/GetApp';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import { CONSTANTS, DATE_PATTERNS } from '../../../constants';
import CertDetailsModal from './CertDetailsModal';
import DocumentMenu from '../DocumentMenu';
import NotarizeStatusChip from '../NotarizeStatusChip';
import useUserInfo from '../../../hooks/useUserInfo';
import StatusBanner from '../StatusBanner';

const useStyles = makeStyles(theme => ({
  registerButton: {
    height: '2.5rem',
  },
  searchInput: {
    height: '2.5rem',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingRight: 0,
    width: '75%',
  },
  downloadButton: {
    borderRadius: '0',
    border: `1px solid ${theme.palette.primary.lighter}`,
    marginRight: theme.spacing(3),
  },
  table: {
    paddingLeft: '0px',
  },
  btnArea: {
    textAlign: 'right',
  },
}));

const RegisteredCertsTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    permissionsFlags: {
      isUserAuthToNotarizeCertificates,
      isUserAuthToDownloadCertificate,
      isUserAuthToViewTransactionDetails,
      isUserAuthToViewTransactionStatus,
    },
  } = useUserInfo();

  const { notarizedCerts, setCertUploadVisible } = useNotarizationContext();
  const [selectedCert, setSelectedCert] = useState(null);

  const resetCertSelection = () => {
    setSelectedCert(null);
  };

  const handleMoreBtnClick = certDetails => {
    setSelectedCert(certDetails);
  };

  const columnDefs = () => {
    const customOptions = () => {
      return {
        filter: false,
      };
    };

    const customDateSorted = () => {
      const options = customOptions();
      options.sortOrder = 'desc';
      options.customBodyRender = dateField => {
        return moment(dateField).format(DATE_PATTERNS.DEFAULT);
      };

      return options;
    };

    const customChipBody = () => {
      const options = customOptions();
      options.customBodyRender = statusField => <NotarizeStatusChip statusField={statusField} />;
      return options;
    };

    const customActionsBody = () => {
      const options = customOptions();

      options.sort = false;
      options.customHeadLabelRender = () => '';

      options.customBodyRender = certField => {
        return (
          <div className={classes.btnArea}>
            {/* {certField?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED && (
              <IconButton
                variant="contained"
                className={classes.downloadButton}
                size="small"
                disabled
              >
                <GetAppIcon />
              </IconButton>
            )} */}
            {isUserAuthToViewTransactionDetails && (
              <DocumentMenu notarizedDoc={certField} handleMoreBtnClick={handleMoreBtnClick} />
            )}
          </div>
        );
      };

      return options;
    };

    return [
      {
        name: 'certificateName',
        label: t('notarization.certTable.filename'),
        options: customOptions(),
      },
      ...(isUserAuthToViewTransactionStatus
        ? [
            {
              name: 'status',
              label: t('notarization.certTable.status'),
              options: customChipBody(),
            },
          ]
        : []),
      {
        name: 'creationDate',
        label: t('notarization.certTable.dateColumn'),
        options: customDateSorted(),
      },
      ...(isUserAuthToDownloadCertificate || isUserAuthToViewTransactionDetails
        ? [
            {
              name: 'actions',
              label: t('notarization.certTable.actions'),
              options: customActionsBody(),
            },
          ]
        : []),
    ];
  };

  const tableData = notarizedCerts?.map(notarizedCert => {
    return {
      certificateName: notarizedCert.certificateName,
      status: notarizedCert.transaction?.status || CONSTANTS.CONTRACT_STATUSES.FAILED,
      creationDate: parseInt(notarizedCert.createdAt, 10),
      actions: notarizedCert,
    };
  });

  const certCountPerStatus = countBy(notarizedCerts, value => value.transaction?.status);

  const handleCreate = () => {
    setCertUploadVisible(true);
  };

  const registerToolbar = () => {
    return isUserAuthToNotarizeCertificates ? (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.registerButton}
        onClick={handleCreate}
        data-testid="notarization-certificates-registerButton"
      >
        {t('notarization.register')}
      </Button>
    ) : (
      <></>
    );
  };

  const customCertSearch = (searchText, handleSearch) => (
    <Input
      className={classes.searchInput}
      variant="filled"
      disableUnderline
      placeholder={t('notarization.searchForCertificate')}
      onChange={e => handleSearch(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton className={classes.searchButton} variant="contained">
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );

  return (
    <>
      <StatusBanner countPerStatus={certCountPerStatus} />
      <Table
        columns={columnDefs()}
        data={tableData}
        className={classes.table}
        options={{
          filter: false,
          selectableRows: 'none',
          selectToolbarPlacement: 'none',
          sort: true,
          search: false,
          searchOpen: true,
          pagination: true,
          elevation: 0,
          customToolbar: registerToolbar,
          customSearchRender: customCertSearch,
          searchProps: {
            variant: 'outlined',
          },
          textLabels: {
            body: {
              noMatch: t('notarization.certTable.noRecordsFound'),
            },
          },
        }}
      />
      {selectedCert && isUserAuthToViewTransactionDetails && (
        <CertDetailsModal
          notarizedCertificate={selectedCert}
          resetCertSelection={resetCertSelection}
        />
      )}
    </>
  );
};

RegisteredCertsTable.propTypes = {};

RegisteredCertsTable.defaultProps = {};

export default RegisteredCertsTable;
