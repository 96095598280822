import gql from 'graphql-tag';

const ProcurementSku = gql`
  fragment ProcurementSku on PurchaseOrderMtr {
    sku {
      description
      supplierSku
      supplementInfo {
        #
        countryOrigin
        plantLocation
        manufacturer
        sparesCategory
        #
      }
    }
  }
`;

const Company = gql`
  fragment Company on Company {
    name
    country
    city
    address {
      street_line
      country
      city
      postal_code
      state
      phone
    }
  }
`;

const PoSupplementaryInfo = gql`
  fragment PoSupplementaryInfo on PurchaseOrderMtr {
    supplementInfo {
      partNumber
      foreignPurchaseNumberMaster
      foreignPurchaseNumber
      foreignApprovedAt
      #
      countryOrigin
      plantLocation
      manufacturer
      sparesCategory
      #
      isClosed
      volumeReceived
      volumeAccepted
      volumeRejected
      volumeBilled
      unitOfItem
      buyerEmployeeName
    }
  }
`;

const PurchaseOrderMtr = gql`
  fragment PurchaseOrderMtr on PurchaseOrderMtr {
    poHeader {
      supplier {
        signature
        acknowledgedAt
      }
      elapsedSinceCreation
    }
    purchaseOrderId
    isSender
    buyer {
      company {
        ...Company
      }
    }
    supplier {
      company {
        ...Company
      }
    }
    volume
    material
    supplierInput {
      leadtime
      inventory
      hasRawMaterial
      materialStartDate
      productionStartDate
      productionCompleteDate
    }
    ...PoSupplementaryInfo
    ...ProcurementSku
  }
  ${ProcurementSku}
  ${PoSupplementaryInfo}
  ${Company}
`;

export const PoHeaderSupplementaryInfo = gql`
  fragment PoHeaderSupplementaryInfo on PurchaseOrderHeaderMtr {
    supplementInfo {
      foreignPurchaseNumberMaster
      ukda {
        buyerSku
        description
      }
    }
  }
`;

const PurchaseOrderHeaderMtr = gql`
  fragment PurchaseOrderHeaderMtr on PurchaseOrderHeaderMtr {
    isSender
    elapsedSinceCreation
    supplier {
      signature
      acknowledgedAt
      company {
        ...Company
      }
    }
    buyer {
      company {
        ...Company
      }
    }
    ...PoHeaderSupplementaryInfo
  }
  ${Company}
  ${PoHeaderSupplementaryInfo}
`;

// query po
export const GET_MTR_PURCHASE = gql`
  query purchaseOrderMtr($foreignPurchaseNumber: String!) {
    purchaseOrderMtr(foreignPurchaseNumber: $foreignPurchaseNumber) {
      ...PurchaseOrderMtr
    }
  }
  ${PurchaseOrderMtr}
`;

// query po list
export const GET_MTR_PURCHASES = gql`
  query purchaseOrderMtrs(
    $buyerId: String
    $supplierId: String
    $foreignPurchaseNumberMaster: String
    $isOpen: Boolean!
    $isClosed: Boolean!
  ) {
    purchaseOrderMtrs(
      input: {
        isClosed: $isClosed
        isOpen: $isOpen
        buyerId: $buyerId
        supplierId: $supplierId
        foreignPurchaseNumberMaster: $foreignPurchaseNumberMaster
      }
    ) {
      ...PurchaseOrderMtr
    }
  }
  ${PurchaseOrderMtr}
`;

// update po
export const UPDATE_MTR_PURCHASES = gql`
  mutation updatePurchaseOrderMtr($input: purchaseOrderInputMtr!) {
    updatePurchaseOrderMtr(input: $input) {
      supplierInput {
        leadtime
        inventory
        hasRawMaterial
        materialStartDate
        productionStartDate
        productionCompleteDate
      }
    }
  }
`;

// query po master
export const GET_PURCHASE_MASTER = gql`
  query purchaseOrderHeaderMtr($foreignPurchaseNumberMaster: String!) {
    purchaseOrderHeaderMtr(foreignPurchaseNumberMaster: $foreignPurchaseNumberMaster) {
      ...PurchaseOrderHeaderMtr
    }
  }
  ${PurchaseOrderHeaderMtr}
`;

// update po master
export const UPDATE_PURCHASE_MASTER = gql`
  mutation updatePurchaseOrderHeaderMtr($foreignPurchaseNumberMaster: String!) {
    updatePurchaseOrderHeaderMtr(
      input: {
        supplier: { signature: true }
        supplementInfo: { foreignPurchaseNumberMaster: $foreignPurchaseNumberMaster }
      }
    ) {
      ...PurchaseOrderHeaderMtr
    }
  }
  ${PurchaseOrderHeaderMtr}
`;

// shipment.
const shipment = gql`
  fragment shipment_fragment on ShipmentMtr {
    foreignPurchaseNumber
    shipmentNumber
    status
    volume
    volumeDue
    volumeReceived
    volumeAccepted
    volumeRejected
    volumeBilled
    volumeCancelled
    promiseDate
    closeCode
    buyerCategory
    buyerUpdate1
    targetDeliveryDate
    supplierUpdate
    supplierUpdateLast
    preInspectionDate
    rid
    dispatchDate
    exitFactoryDate
    exitFactoryLocation
    waybill
    vessel
    etd
    eta
    ata
    purchaseOrder {
      ...PurchaseOrderMtr
    }
    discrepancyReports {
      reportNum
      reportDate
      receivedDate
      inspectionCode
      inspectionResult
      volumeDeliveryNote
      volumeReceived
      volumeDiscrepancy
      volumeSettled
      discrepancyType
      reason1
      reason2
      settlementDate
    }
  }
  ${PurchaseOrderMtr}
`;

// query shipment.
export const GET_MTR_SHIPMENTS = gql`
  query shipmentMtrs($foreignPurchaseNumber: String, $isOpenOrderOnly: Boolean) {
    shipmentMtrs(isOpenOrderOnly: $isOpenOrderOnly, foreignPurchaseNumber: $foreignPurchaseNumber) {
      ...shipment_fragment
    }
  }
  ${shipment}
`;

export const UPDATE_MTR_SHIPMENTS = gql`
  mutation updateShipmentMtr($input: shipmentMtrInput!) {
    updateShipmentMtr(input: $input) {
      ...shipment_fragment
    }
  }
  ${shipment}
`;
