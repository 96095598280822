import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Typography, Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Table from '@eyblockchain/ey-ui/core/Table';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useHistory } from 'react-router';
import { RBAC_ACTIONS, CONSTANTS, RBAC_ROLES } from '../../constants';
import {
  generateRoleOptions,
  getPrettifiedEthAddr,
  getUpdateCollaboratorsMixpanelEvent,
} from '../../utils';
import ConfirmationPopup from './ConfirmationPopup';
import useUserInfo from '../../hooks/useUserInfo';
import { usePartnerContext } from '../../contexts/Procurement/partner';
import {
  ADD_COLLABORATORS,
  REMOVE_COLLABORATORS,
  CHANGE_COLLABORATOR_ROLE,
  UPDATE_ERC_1155_PERMISSION,
} from '../../graphql/Tokenization/token';
import { useNotification } from '../../contexts/Shared/notification';
import PageLoader from './PageLoader';
import { useCollaboratorContext } from '../../contexts/Shared/collaboratorContext';
import track from '../../mixpanel';

const useStyles = makeStyles(theme => ({
  progressRoot: {
    '& .MuiButton-outlinedPrimary': {
      border: '0px',
    },
  },
  inProgressBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
  },
  inProgressBanner: {
    marginRight: theme.spacing(1),
  },
  descriptionText: {
    fontSize: 'unset',
  },
  headerText: {
    fontWeight: '400',
    marginBottom: theme.spacing(2),
  },
  root: {
    marginLeft: theme.spacing(3),
  },
  subsection: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectField: {
    minWidth: '10%',
    width: '95%',
    '& .MuiSelect-selectMenu': {
      minHeight: '1.5em',
    },
  },
  selectionControl: {
    width: '100%',
    display: 'flex',
  },
  addButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  searchInput: {
    height: '2.5rem',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingRight: 0,
    width: '75%',
  },
  addrArea: {
    display: 'flex',
    alignItems: 'center',
  },
  chipArea: {
    justifyContent: 'space-between',
  },
  removeButton: {
    fontWeight: '200',
    textTransform: 'capitalize',
  },
  table: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiTable-root': {
      width: '99%',
    },
    '& .MuiTableCell-head': {
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: theme.palette.primary.lightest,
      },
      '&:hover': {
        '&:nth-child(odd)': {
          backgroundColor: 'white',
        },
        '&:nth-child(even)': {
          backgroundColor: theme.palette.primary.lightest,
        },
      },
    },
    '& .MuiInputBase-root': {
      fontSize: '1em',
    },
    '& .MuiTableBody-root': {
      border: `1px solid ${theme.palette.primary.lightest}`,
    },
  },
  customToolbar: {
    paddingLeft: 0,
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
  },
  autocompleteOption: {
    margin: 0,
    padding: 0,
  },
  autocomplete: {
    minWidth: '10%',
    width: '95%',
    marginRight: theme.spacing(2),
  },
  autoCompletePaper: {
    padding: 0,
  },
}));

const ContractPermissions = selectedContractDetails => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { allOrgRoles } = useUserInfo();
  const { getPartners, partners, getPartnersLoading } = usePartnerContext();
  const {
    chainUpdatingStatus,
    setChainUpdatingStatus,
    setPartnerUpdateType,
  } = useCollaboratorContext();
  const { handleNotification } = useNotification();
  const { activeWallet } = useBecOrganizationContext();
  const history = useHistory();

  const [addCollaborators, { loading: isAddingCollaborators }] = useMutation(ADD_COLLABORATORS, {
    onCompleted: data => {
      track(getUpdateCollaboratorsMixpanelEvent(history), {
        blockchainNetwork: data?.addCollaborators?.blockchainNetwork,
        contractId: data?.addCollaborators?._id,
        contractType: data?.addCollaborators?.tokenType,
        action: CONSTANTS.MIXPANEL_EVENTS.ADD_BUSINESS_PARTNER,
      });
    },
    onError: error => {
      track(getUpdateCollaboratorsMixpanelEvent(history, true), {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
        action: CONSTANTS.MIXPANEL_EVENTS.ADD_BUSINESS_PARTNER,
      });
    },
  });
  const [removeCollaborators, { loading: removingCollaborators }] = useMutation(
    REMOVE_COLLABORATORS,
    {
      onCompleted: data => {
        track(getUpdateCollaboratorsMixpanelEvent(history), {
          blockchainNetwork: data?.removeCollaborator?.blockchainNetwork,
          contractId: data?.removeCollaborator?._id,
          contractType: data?.removeCollaborator?.tokenType,
          action: CONSTANTS.MIXPANEL_EVENTS.REMOVE_BUSINESS_PARTNER,
        });
      },
      onError: error => {
        track(getUpdateCollaboratorsMixpanelEvent(history, true), {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
          action: CONSTANTS.MIXPANEL_EVENTS.REMOVE_BUSINESS_PARTNER,
        });
      },
    },
  );
  const [changeCollaboratorRole, { loading: changingCollaboratorRole }] = useMutation(
    CHANGE_COLLABORATOR_ROLE,
    {
      onCompleted: data => {
        track(getUpdateCollaboratorsMixpanelEvent(history), {
          blockchainNetwork: data?.changeCollaboratorRole?.blockchainNetwork,
          contractId: data?.changeCollaboratorRole?._id,
          contractType: data?.changeCollaboratorRole?.tokenType,
          action: CONSTANTS.MIXPANEL_EVENTS.UPDATE_ROLE,
        });
      },
      onError: error => {
        track(getUpdateCollaboratorsMixpanelEvent(history, true), {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
          action: CONSTANTS.MIXPANEL_EVENTS.UPDATE_ROLE,
        });
      },
    },
  );

  const [updateERC1155Permission, { loading: isUpdateERC1155PermissionLoading }] = useMutation(
    UPDATE_ERC_1155_PERMISSION,
    {
      onCompleted: data => {
        track(getUpdateCollaboratorsMixpanelEvent(history), {
          blockchainNetwork: data?.updateERC1155Permissions?.contract?.blockchainNetwork,
          contractId: data?.updateERC1155Permissions?.contract?._id,
          contractType: data?.updateERC1155Permissions?.contract?.tokenType,
          action: CONSTANTS.MIXPANEL_EVENTS.UPDATE_ROLE,
        });
      },
      onError: error => {
        track(getUpdateCollaboratorsMixpanelEvent(history, true), {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
          action: CONSTANTS.MIXPANEL_EVENTS.UPDATE_ROLE,
        });
      },
    },
  );

  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState({
    title: '',
    confirmation: '',
    warning: '',
    actionParameters: null,
    executeAction: () => {},
  });
  const [mappedOrgs, setMappedOrgs] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const getPartnerNameAddress = partnerId => {
    let name = '';
    let address = '';
    if (partners?.length > 0) {
      const selectedPartner = find(partners, partner => {
        return partner._id === partnerId;
      });

      if (selectedPartner) {
        name = selectedPartner?.partnerOrganization?.name;
        address = selectedPartner?.partnerOrganization?.wallets[0].address;
      }
    }
    return { name, address };
  };

  const getExistingCollaborators = selectedContract => {
    const existingOrgs = [];
    if (selectedContract?.collaborators) {
      selectedContract.collaborators.forEach(collaborator => {
        const { name, address } = getPartnerNameAddress(collaborator?.partner?._id);
        existingOrgs.push({
          name: name,
          address: address,
          id: collaborator?.partner?._id,
          role: collaborator?.role,
        });
      });
    }
    return existingOrgs;
  };

  const setupPartnerList = selectedContract => {
    const orgDetails = [];
    if (partners?.length > 0) {
      partners.forEach(partner => {
        if (partner?.partnerOrganization) {
          orgDetails.push({
            name: partner?.partnerOrganization?.name,
            address: partner?.partnerOrganization?.wallets[0].address,
            id: partner?._id,
          });
        }
      });

      const existingCollaborator = getExistingCollaborators(selectedContract);

      if (existingCollaborator?.length > 0) {
        const optionArray = orgDetails.filter(orgDetail => {
          return !existingCollaborator.find(existingCollb => {
            return orgDetail.id === existingCollb.id;
          });
        });
        setMappedOrgs(existingCollaborator);
        setOrgOptions(
          optionArray
            .filter(org => org.role === undefined)
            .map(org => ({ label: org.name, value: org.id })),
        );
      } else {
        setOrgOptions(
          orgDetails
            .filter(org => org.role === undefined)
            .map(org => ({ label: org.name, value: org.id })),
        );
        setMappedOrgs([]);
      }
    }
  };

  useEffect(() => {
    const { selectedContract } = selectedContractDetails;
    setupPartnerList(selectedContract);
  }, [selectedContractDetails, partners]);

  const roles = generateRoleOptions(allOrgRoles).filter(role =>
    Object.getOwnPropertyDescriptor(RBAC_ROLES, role.label.toUpperCase()),
  );

  const closeModal = () => {
    setIsPopupOpen(false);
    setPopupData({
      title: '',
      confirmation: '',
      warning: '',
      actionParameters: null,
      executeAction: () => {},
    });
  };

  const blockChainProcessPending = () => {
    let isPending = false;
    if (
      selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC721 ||
      selectedContractDetails?.selectedContract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
    ) {
      if (
        selectedContractDetails?.selectedContract?.latestMintersTransaction?.status ===
        CONSTANTS.CONTRACT_STATUSES.PENDING
      ) {
        isPending = true;
      }
    }
    return isPending;
  };
  const addBusinessPartners = async () => {
    try {
      const { selectedContract } = selectedContractDetails;
      let newOrgOptions = orgOptions;
      const collaboratorList = [];
      selectedOrganizations.forEach(selection => {
        collaboratorList.push({
          partnerId: selection.value,
          role: selectedRole,
        });
        newOrgOptions = newOrgOptions.filter(org => org.value !== selection.value);
      });

      if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
      ) {
        mappedOrgs.forEach(existingCollaborator =>
          collaboratorList.push({
            partnerId: existingCollaborator.id,
            role: existingCollaborator.role,
          }),
        );
      }

      const inputAddCollaborators = {
        variables: {
          smartContractAddress: selectedContract?.contractAddress,
          collabList: collaboratorList,
        },
      };

      setChainUpdatingStatus(true);
      setPartnerUpdateType(CONSTANTS.PARTNER_UPDATE_TYPE.ADD);
      if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC721
      ) {
        await addCollaborators(inputAddCollaborators);
      } else if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
      ) {
        await updateERC1155Permission(inputAddCollaborators);
      }
      setSelectedOrganizations([]);
      setSelectedRole('');
      closeModal();
    } catch (err) {
      handleNotification(t('tokens.partnerAddFailed'), 'error');
    }
  };

  const removeBusinessPartner = async organizationRemoveInfo => {
    try {
      const { selectedContract } = selectedContractDetails;
      const { organizationAddress } = organizationRemoveInfo;
      const selectedOrganization = find(mappedOrgs, ['address', organizationAddress])?.id;
      setChainUpdatingStatus(true);
      setPartnerUpdateType(CONSTANTS.PARTNER_UPDATE_TYPE.REMOVE);
      if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC721
      ) {
        const inputRemoveCollaborators = {
          variables: {
            smartContractAddress: selectedContract?.contractAddress,
            partnerToRemove: selectedOrganization,
          },
        };
        await removeCollaborators(inputRemoveCollaborators);
      } else if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
      ) {
        const updatedCollabList = mappedOrgs
          .filter(org => org.address !== organizationAddress)
          .map(org => ({
            partnerId: org.id,
            role: org.role,
          }));
        const inputRemoveCollaborators = {
          variables: {
            smartContractAddress: selectedContract?.contractAddress,
            collabList: updatedCollabList,
          },
        };
        await updateERC1155Permission(inputRemoveCollaborators);
      }
      closeModal();
    } catch (err) {
      handleNotification(t('tokens.partnerRemoveFailed'), 'error');
    }
  };

  const updateRole = async organizationUpdateInfo => {
    try {
      const { selectedContract } = selectedContractDetails;
      const { organizationAddress, role } = organizationUpdateInfo;
      const selectedOrganization = find(mappedOrgs, ['address', organizationAddress])?.id;
      setChainUpdatingStatus(true);
      setPartnerUpdateType(CONSTANTS.PARTNER_UPDATE_TYPE.UPDATE);
      if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC721
      ) {
        const inputChangeCollaboratorsRole = {
          variables: {
            smartContractAddress: selectedContract?.contractAddress,
            newCollaboratorInfo: {
              partnerId: selectedOrganization,
              role: role,
            },
          },
        };
        await changeCollaboratorRole(inputChangeCollaboratorsRole);
      } else if (
        selectedContractDetails?.selectedContract?.tokenType ===
        CONSTANTS.SMARTCONTRACT_TYPES.ERC1155
      ) {
        const updatedCollabList = mappedOrgs?.map(existingCollaborator => ({
          partnerId: existingCollaborator.id,
          role:
            existingCollaborator?.address === organizationAddress
              ? role
              : existingCollaborator.role,
        }));
        const inputChangeCollaboratorsRole = {
          variables: {
            smartContractAddress: selectedContract?.contractAddress,
            collabList: updatedCollabList,
          },
        };
        await updateERC1155Permission(inputChangeCollaboratorsRole);
      }
      closeModal();
    } catch (err) {
      handleNotification(t('tokens.roleUpdateFailed'), 'error');
    }
  };

  const buildOptions = option => (
    <MenuItem key={option.value} value={option.value} type={option.key} name={option.label}>
      {option.label}
    </MenuItem>
  );

  const generatePopupData = (action, organizationAddress, organizationName, role = '') => {
    let title = '';
    let confirmation = '';
    let warning = '';
    let executeAction = null;
    let actionParameters = null;

    switch (action) {
      case RBAC_ACTIONS.UPDATE_ROLE:
        title = t('common.rbac.changeRole');
        confirmation = (
          <>
            {t('common.rbac.changeRoleConfirmation')} <strong>{organizationName}</strong>{' '}
            {t('common.rbac.questionMark')}
          </>
        );
        warning = t('common.rbac.changeRoleWarning');
        executeAction = updateRole;
        actionParameters = { organizationAddress: organizationAddress, role: role };
        break;
      case RBAC_ACTIONS.REMOVE_BUSINESS_PARTNER:
        title = t('common.rbac.removeBusinessPartner');
        confirmation = (
          <>
            {t('common.rbac.removeBusinessPartnerConfirmation')} <strong>{organizationName}</strong>{' '}
            {t('common.rbac.questionMark')}
          </>
        );
        warning = t('common.rbac.removeBusinessPartnerWarning');
        executeAction = removeBusinessPartner;
        actionParameters = { organizationAddress: organizationAddress };
        break;
      default:
        break;
    }

    setPopupData({
      title,
      confirmation,
      warning,
      actionParameters,
      executeAction,
    });
  };

  const customPartnersSearch = (searchText, handleSearch) => {
    return (
      <div className={classes.searchBarWrapper}>
        <Input
          className={classes.searchInput}
          variant="filled"
          disableUnderline
          placeholder={t('common.search')}
          onChange={e => handleSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton className={classes.searchButton} variant="contained">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    );
  };

  const customTableToolbar = () => <div className={classes.customToolbar} />;

  const getMetaContent = addressField => {
    return (
      <div className={classes.addrArea}>
        {addressField && (
          <>
            <Typography variant="body2">{getPrettifiedEthAddr(addressField)}</Typography>
            <CopyContent content={addressField} />
          </>
        )}
      </div>
    );
  };

  const columnDefs = () => {
    const customOptions = () => {
      return {
        filter: false,
        sort: false,
      };
    };

    const customNameOpt = () => {
      const options = customOptions();
      options.sortOrder = 'desc';
      return options;
    };

    const customAddressOpt = () => {
      const options = customOptions();

      options.customBodyRender = addressField => (
        <div className={classes.chipArea}>
          <div className={classes.metaArea}>{getMetaContent(addressField)}</div>
        </div>
      );

      return options;
    };

    const customRolesOpt = () => {
      const options = customOptions();

      options.customBodyRender = (roleField, tableMeta, updateValue) => {
        const organizationName = tableMeta.rowData[0];
        const organizationAddress = tableMeta.rowData[1];

        return (
          <TextField
            select
            size="small"
            name="tableRoles"
            value={roleField}
            classes={{
              root: classes.selectRole,
            }}
            SelectProps={{
              native: true,
            }}
            onChange={event => {
              generatePopupData(
                RBAC_ACTIONS.UPDATE_ROLE,
                organizationAddress,
                organizationName,
                event.target.value,
              );
              updateValue(event.target.value);
              setIsPopupOpen(true);
            }}
          >
            {roles.map(option => (
              <option
                className={classes.roleOption}
                key={`${option.value}-table`}
                value={option.value}
              >
                {t(`contracts.roles.${option.label}`)}
              </option>
            ))}
          </TextField>
        );
      };

      return options;
    };

    const customActionsOpt = () => {
      const options = customOptions();

      options.customBodyRender = (actionField, tableMeta) => {
        const organizationName = tableMeta.rowData[0];
        const organizationAddress = tableMeta.rowData[1];

        return (
          <Button
            className={classes.removeButton}
            variant="outlined"
            size="small"
            style={{ minWidth: '2rem' }}
            onClick={() => {
              generatePopupData(
                RBAC_ACTIONS.REMOVE_BUSINESS_PARTNER,
                organizationAddress,
                organizationName,
              );
              setIsPopupOpen(true);
            }}
          >
            {t('common.remove')}
          </Button>
        );
      };

      return options;
    };

    return [
      {
        name: 'name',
        label: t('common.businessPartner_plural'),
        options: customNameOpt(),
      },
      {
        name: 'address',
        label: t('common.address'),
        options: customAddressOpt(),
      },
      {
        name: 'role',
        label: t('common.roles'),
        options: customRolesOpt(),
      },
      {
        name: 'actions',
        label: t('common.actions'),
        options: customActionsOpt(),
      },
    ];
  };

  if (
    getPartnersLoading ||
    isAddingCollaborators ||
    removingCollaborators ||
    changingCollaboratorRole ||
    isUpdateERC1155PermissionLoading
  ) {
    return (
      <Card variant="outlined">
        <PageLoader />
      </Card>
    );
  }

  if (chainUpdatingStatus || blockChainProcessPending()) {
    return (
      <div className={classes.progressRoot}>
        <div className={classes.inProgressBox}>
          <Typography variant="body1" className={classes.inProgressBanner}>
            {t('tokens.partnerUpdateInProgress')}
          </Typography>
          <CircularProgress size={20} thickness={10} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.descriptionText}>
          {t('tokens.addPartnerDescription')}
        </Typography>
      </div>
      <div className={classes.subsection}>
        <Typography className={classes.headerText}>{t('tokens.addPartnerHeading')}</Typography>
        <div className={classes.selectionControl}>
          <Autocomplete
            multiple
            limitTags={2}
            className={classes.autocomplete}
            options={orgOptions?.map(org => ({
              label: org.label,
              value: org.value,
              selected: org.selected,
            }))}
            disableCloseOnSelect
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={(event, value) => setSelectedOrganizations(value)}
            value={selectedOrganizations}
            renderOption={(option, { selected }) => (
              <div className={classes.autocompleteOption}>
                <Checkbox
                  key={option.value}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" className={classes.checkBox} />}
                  checkedIcon={<CheckBoxIcon fontSize="small" className={classes.checkBox} />}
                  checked={selected}
                  className={classes.autocompleteCheckbox}
                />
                {option.label}
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tokens.orgNameLabel')}
                variant="outlined"
                size="small"
                classes={{
                  root: classes.autocompleteField,
                }}
              />
            )}
          />
          <TextField
            select
            size="small"
            variant="outlined"
            label={t('tokens.selectOneLabel')}
            classes={{
              root: classes.selectField,
            }}
            value={selectedRole}
            onChange={event => setSelectedRole(event?.target?.value)}
          >
            {roles?.map(buildOptions)}
          </TextField>
        </div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.addButton}
          disabled={selectedRole === '' || selectedOrganizations.length === 0}
          onClick={addBusinessPartners}
        >
          {t('tokens.add')}
        </Button>
        {mappedOrgs.length > 0 && <Divider className={classes.divider} />}
      </div>

      {mappedOrgs.length > 0 && (
        <>
          <Typography className={classes.headerText}>
            {t('common.businessPartner_plural')}
          </Typography>
          <Table
            columns={columnDefs()}
            data={mappedOrgs}
            className={classes.table}
            options={{
              filter: false,
              selectableRows: 'none',
              selectToolbarPlacement: 'none',
              search: false,
              searchOpen: true,
              pagination: true,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10],
              elevation: 0,
              customToolbar: customTableToolbar,
              customSearchRender: customPartnersSearch,
              searchProps: {
                variant: 'outlined',
              },
              textLabels: {
                body: {
                  noMatch: t('notarization.docTable.noRecordsFound'),
                },
              },
            }}
          />
        </>
      )}

      {isPopupOpen && <ConfirmationPopup popupData={popupData} closePopup={closeModal} />}
    </div>
  );
};

export default ContractPermissions;
