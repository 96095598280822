import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { IconButton, Radio, Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { Field, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { filter, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  CONSTANTS,
  METADATA_BEHAVIORS,
  TOKEN_METADATA_FIELDS,
  TRACEABILITY_DISPATCHER_ACTIONS,
  TRACE_WIZARD_PROGRESSION_V2,
  VALUE_CHAIN_FIELDS,
  VALUE_CHAIN_FORMS,
} from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import { useConfigurationWizardContext } from '../../../contexts/Traceability/configurationWizard';
import { SET_TOKEN_METADATA_CONFIG } from '../../../graphql/Tokenization/token';
import { UPDATE_INSTANCE_PROGRESS } from '../../../graphql/Traceability/instance';
import useUserInfo from '../../../hooks/useUserInfo';
import track from '../../../mixpanel';
import { isMeasurementDataType } from '../../../utils';
import MetaDataStructureView, {
  customOptions,
} from '../../Tokenization/MetadataStructure/MetaDataStructureView';
import InfoMessage from './InfoMessage';
import MetadataRow from './MetadataRow';
import { handleNavigation, useEditMode } from './traceabilityUtils';
import WizardButtonArea from './WizardButtonArea';
import WizardHelper, { getHeaderNameText } from './WizardHelper';

const currentFormName = VALUE_CHAIN_FORMS.METADATA;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '1000px',
  },
  formAndTable: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemHelpers: {
    display: 'grid',
    // gridTemplateColumns: '18% 18% 18% 18% 8% 11% 5%', // style with codType
    gridTemplateColumns: '18% 18% 18% 18% 18% 6%',
    margin: '0.5em 0',
    padding: theme.spacing(1),
    gridGap: '0.5%',
  },
  buttonArea: {
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  editButtonArea: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
    marginRight: theme.spacing(1),
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    size: 'small',
    marginTop: theme.spacing(2),
  },
}));

const ItemTabs = props => {
  const { tabData, currentTab, changeTab } = props;
  return (
    <Tabs value={currentTab} onChange={changeTab}>
      {tabData.map(({ name }) => (
        <Tab label={name} key={name} />
      ))}
    </Tabs>
  );
};

ItemTabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  changeTab: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
};

const MetadataForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [isProcessing, setProcessing] = useState(false);
  const { handleNotification } = useNotification();
  const { activeWallet } = useBecOrganizationContext();
  const {
    instanceDetails: {
      content: { _id: instanceId, items, steps, isOwner },
    },
    metadataTypes: { metadataTypes },
    lastCompletedIteration,
    instanceDispatcher,
    setIsDataLoading,
  } = useConfigurationWizardContext();

  const {
    permissionsFlags: { isUserAuthToEditValueChain, isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const userNotAllowedToViewContent =
    lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
    !isUserAuthToViewValueChainDetails;

  const userAllowedToEdit = isUserAuthToEditValueChain && isOwner;

  const filteredMetadataTypes = filter(
    metadataTypes,
    metadataType => metadataType.metadataTypeName !== TOKEN_METADATA_FIELDS.LINKED_TOKEN,
  );

  const metadataTypeOptions = filteredMetadataTypes.map(_ => ({
    label: t(_.metadataTypeName),
    value: _._id,
    options: _.metadataTypeOptions,
    key: _.metadataTypeName,
  }));

  const [updateInstanceStatus] = useMutation(UPDATE_INSTANCE_PROGRESS);

  const [setTokenMetadataConfig] = useMutation(SET_TOKEN_METADATA_CONFIG, {
    onCompleted: data => {
      track(CONSTANTS.MIXPANEL_EVENTS.TRACEABILITY.SET_METADATA_STRUCTURE, {
        blockchainNetwork: activeWallet?.network,
        contractId: data.setMetadataConfig?._id,
        totalMetadata: data.setMetadataConfig?.metadataStructure?.metadataConfig?.length || 0,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TRACEABILITY.SET_METADATA_STRUCTURE, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const [isEditMode, setEditMode] = useEditMode(
    TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code,
    lastCompletedIteration,
  );

  const toggleEditMode = () => {
    setEditMode(prevMode => !prevMode);
  };

  // columnDefs form table and WizardHelper
  const columnDefs = [
    {
      field: VALUE_CHAIN_FIELDS.METADATA.METADATA_NAME,
      name: 'metadataName',
    },
    {
      field: VALUE_CHAIN_FIELDS.METADATA.DATA_TYPE,
      name: 'dataType',
    },
    {
      field: VALUE_CHAIN_FIELDS.METADATA.PERMISSION,
      name: 'permission',
    },
    {
      field: VALUE_CHAIN_FIELDS.METADATA.MUTABILITY,
      name: 'permission',
    },
    {
      field: VALUE_CHAIN_FIELDS.METADATA.VALUE_CHAIN_STEP,
      name: 'valueChainStep',
    },
    {
      field: VALUE_CHAIN_FIELDS.METADATA.ACTIONS,
      centeredFields: true,
      name: 'ACTIONS',
      hideInTable: true,
    },
  ].map(item => ({
    ...item,
    label: t(getHeaderNameText({ formName: currentFormName, field: item.field })),
  }));

  const filterStepDataOption = tabIndex => {
    const itemSteps = filter(steps, { involvedItem: { _id: items[tabIndex]?._id } });
    const valueChainStepOptions = itemSteps?.map(({ _id, name }) => ({
      value: _id,
      label: name,
      key: _id,
    }));
    return valueChainStepOptions;
  };

  const tabData = items?.map(({ name, tokenContract, parentItem, _id }) => {
    const arrStepId = steps.map(step => step._id);
    const isItemLinkedWithUpdateStep = steps
      .filter(step => step?.involvedItem?._id === _id)
      .some(({ createToken }) => !createToken);

    const isErc1155 = tokenContract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC1155;
    const isParentErc721 =
      parentItem?.tokenContract?.tokenType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721;

    let linkedMetadata;
    const curMetadataConfig = tokenContract?.metadataStructure?.metadataConfig;
    if (
      lastCompletedIteration >= TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code &&
      isErc1155 &&
      isParentErc721
    ) {
      if (tokenContract?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED) {
        linkedMetadata = 'true';
      } else linkedMetadata = 'false';
    }

    const metadataArray = curMetadataConfig
      ?.filter(mdc => arrStepId?.includes(mdc?.step?._id))
      ?.map(md => ({
        metadataName: md.metadataName,
        metadataType: md.metadataType._id,
        metadataPermission: md.metadataPermission,
        metadataMutability: md.metadataMutability,
        step: { name: md.step?.name, value: md.step?._id },
        chosenMetadataOption: md.chosenMetadataOption,
        contractAddress: tokenContract?.contractAddress,
      }));

    return {
      name,
      content: name,
      metadataArray,
      contractAddress: tokenContract?.contractAddress,
      isErc1155,
      isParentErc721,
      linkedMetadata,
      parentItem,
      isItemLinkedWithUpdateStep,
    };
  });

  const submitForm = async (values, { resetForm }) => {
    try {
      setProcessing(true);
      setIsDataLoading(true);
      // const dataFromAllTabs = values.tabsMetadata.filter(dataFromEachTab => dataFromEachTab?.metadataArray?.length > 0);
      const mutationArgs = [];
      values.tabsMetadata.forEach((dataFromEachTab, index) => {
        const { metadataArray: metadataFromEachTab, linkedMetadata } = dataFromEachTab;
        if (linkedMetadata === 'true')
          mutationArgs.push({
            behavior: METADATA_BEHAVIORS.LINKED,
            instanceId,
            contractAddress: tabData[index].contractAddress,
            metadataConfig: [],
          });
        else if (metadataFromEachTab?.length > 0) {
          const contractAddress = metadataFromEachTab?.[0]?.contractAddress;
          //  generate the field array for one contract
          const inputConfig = metadataFromEachTab.map(metadataRow => {
            const inpMetadataOption = isMeasurementDataType(
              metadataRow.metadataType,
              metadataTypeOptions,
            )
              ? metadataRow.chosenMetadataOption
              : '';

            // destructuring field-by-field as "contractAddress" field is not required in each config record
            return {
              metadataName: metadataRow.metadataName,
              metadataPermission: metadataRow.metadataPermission,
              metadataMutability: metadataRow.metadataMutability,
              metadataType: metadataRow.metadataType,
              step: metadataRow?.step?.value,
              chosenMetadataOption: inpMetadataOption,
            };
          });
          mutationArgs.push({
            behavior: METADATA_BEHAVIORS.STRUCTURED,
            instanceId,
            contractAddress,
            metadataConfig: inputConfig,
          });
        } else {
          mutationArgs.push({
            behavior: METADATA_BEHAVIORS.STRUCTURED,
            instanceId,
            contractAddress: tabData[index].contractAddress,
            metadataConfig: [],
          });
        }
      });
      const arrMetadataSavedPromise = mutationArgs.map(input =>
        setTokenMetadataConfig({
          variables: {
            input,
          },
        }),
      );

      const arrMetadataSaved = await Promise.all(arrMetadataSavedPromise);

      if (arrMetadataSaved?.length) {
        arrMetadataSaved.forEach(({ data: { setMetadataConfig } }) => {
          const newMetadataConfig = setMetadataConfig?.metadataStructure?.metadataConfig;
          const itemDetailsFromState = find(items, {
            tokenContract: { contractAddress: setMetadataConfig?.contractAddress },
          });
          const itemIdToUpdate =
            newMetadataConfig?.[0]?.step?.involvedItem?._id || itemDetailsFromState?._id;

          let latestParentMeatadataConfig;
          if (setMetadataConfig?.metadataStructure?.behavior === METADATA_BEHAVIORS.LINKED) {
            latestParentMeatadataConfig = arrMetadataSaved
              .filter(
                e =>
                  e?.data?.setMetadataConfig?.metadataStructure?.metadataConfig?.[0]?.step
                    ?.involvedItem?._id === itemDetailsFromState?.parentItem?._id,
              )
              .map(e => e?.data?.setMetadataConfig?.metadataStructure?.metadataConfig)?.[0];
          }

          if (itemIdToUpdate) {
            instanceDispatcher({
              type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_METADATA,
              payload: {
                itemId: itemIdToUpdate,
                metadataConfig: setMetadataConfig?.metadataStructure,
                ...(latestParentMeatadataConfig && {
                  parentMetadataConfig: latestParentMeatadataConfig,
                }),
              },
            });
          }
        });
      }

      // this will update the status if we're editing a finalized VC and didn't apply any changes to the metadata
      await updateInstanceStatus({
        variables: {
          instanceId: instanceId,
          inputIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
        },
      });
      instanceDispatcher({
        type: TRACEABILITY_DISPATCHER_ACTIONS.UPDATE_INSTANCE_STATUS,
        payload: {
          status: CONSTANTS.INSTANCE_STATUSES.FINALIZE,
          lastCompletedIteration: TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_METADATA.code,
          currentIteration: TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code,
        },
      });

      setEditMode(false);
      resetForm({ values });
      handleNotification(t('traceability.metadataForm.success'), 'success');
      handleNavigation(history, instanceId);
    } catch (err) {
      handleNotification(err?.message, 'error');
    } finally {
      setProcessing(false);
      setIsDataLoading(false);
    }
  };

  const tabsMetadata = tabData?.map(tabDataRow => {
    return {
      metadataArray: tabDataRow.metadataArray,
      linkedMetadata: tabDataRow.linkedMetadata,
    };
  });

  const metadataValidationSchema = Yup.object().shape({
    tabsMetadata: Yup.array().of(
      Yup.object().shape({
        metadataArray: Yup.array().of(
          Yup.object().shape({
            metadataName: Yup.string().required(t('tokens.validation.metadataNameRequired')),
            metadataType: Yup.string().required(t('tokens.validation.dataTypeRequired')),
            chosenMetadataOption: Yup.mixed().when('metadataType', {
              is: value => isMeasurementDataType(value, metadataTypeOptions),
              then: Yup.string().required('required'),
            }),
            metadataPermission: Yup.string().required(t('tokens.validation.permissionRequired')),
            metadataMutability: Yup.string().required(
              t('tokens.validation.dataMutabilityRequired'),
            ),
            step: Yup.object().shape({
              value: Yup.string().required(),
            }),
          }),
        ),
      }),
    ),
  });

  const validateLinkedMetadata = values => {
    const linkedMetadataErrors = values?.tabsMetadata?.map((elem, index) => {
      const { isErc1155, isParentErc721, isItemLinkedWithUpdateStep } = tabData[index];
      if (isErc1155 && isParentErc721 && !elem?.linkedMetadata)
        return { linkedMetadata: 'required' };
      if (elem?.linkedMetadata === 'true' && isItemLinkedWithUpdateStep)
        return {
          linkedMetadata: t('traceability.metadataForm.linkedMetadataSelected'),
        };
      return {};
    });

    const errors = linkedMetadataErrors?.every(obj => Object.keys(obj).length === 0)
      ? {}
      : { tabsMetadata: [...linkedMetadataErrors] };
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tabsMetadata,
    },
    validate: validateLinkedMetadata,
    validationSchema: metadataValidationSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  const buildContentArea = (dataFromEachTab, tabIndex) => {
    const parentItemName = dataFromEachTab?.parentItem?.name;
    const linkedMessageText = t('traceability.metadataForm.linkedMetadata.info', {
      parentItemName,
    });
    const { isErc1155, isParentErc721, isItemLinkedWithUpdateStep } = dataFromEachTab;
    const promptLinkingSection = isErc1155 && isParentErc721;
    const displayMetadataField =
      !promptLinkingSection ||
      (promptLinkingSection && formik?.values?.tabsMetadata[tabIndex]?.linkedMetadata === 'false');
    const displayLinkedMetadataMessage =
      formik?.values?.tabsMetadata[tabIndex]?.linkedMetadata === 'true' &&
      !formik?.errors?.tabsMetadata?.[tabIndex]?.linkedMetadata;

    const isLinkedMetadataSelectedForUpdateStep =
      formik?.values?.tabsMetadata[tabIndex]?.linkedMetadata === 'true' &&
      isItemLinkedWithUpdateStep;

    if (!isEditMode) {
      if (dataFromEachTab?.linkedMetadata === 'true')
        return <InfoMessage messageText={linkedMessageText} />;
      return (
        <MetaDataStructureView
          formValues={{ metadataArray: tabsMetadata[tabIndex].metadataArray ?? [] }}
          metaDataTypes={metadataTypeOptions}
          additionalColumns={[
            {
              name: 'step',
              label: t('traceability.metadataForm.labels.valueChainStep'),
              options: customOptions(),
            },
          ]}
        />
      );
    }

    const linkedMetadataSelection = () => {
      const handleMetadtaLinkingSelection = event => {
        if (event?.target?.value === 'true')
          formik.setFieldValue(`tabsMetadata[${tabIndex}]`, {
            linkedMetadata: 'true',
            metadataArray: [],
          });
        else formik.setFieldValue(`tabsMetadata[${tabIndex}].linkedMetadata`, event.target.value);
      };
      return (
        <>
          <Typography>
            {t('traceability.metadataForm.linkedMetadata.radio.description1')}
          </Typography>
          <Typography>
            {t('traceability.metadataForm.linkedMetadata.radio.description2')}
          </Typography>
          <div className={classes.radioButtons}>
            <Field
              component={Radio}
              color="default"
              name={`tabsMetadata[${tabIndex}].linkedMetadata`}
              value="true"
              checked={formik?.values?.tabsMetadata[tabIndex]?.linkedMetadata === 'true'}
              onChange={handleMetadtaLinkingSelection}
            />
            <Typography>{t('tokens.yes')}</Typography>
            <Field
              component={Radio}
              color="default"
              name={`tabsMetadata[${tabIndex}].linkedMetadata`}
              value="false"
              checked={formik?.values?.tabsMetadata[tabIndex]?.linkedMetadata === 'false'}
              onChange={handleMetadtaLinkingSelection}
            />
            <Typography>{t('tokens.no')}</Typography>
          </div>
        </>
      );
    };

    return (
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {promptLinkingSection && <div>{linkedMetadataSelection()}</div>}
          {displayLinkedMetadataMessage && <InfoMessage messageText={linkedMessageText} />}
          {isLinkedMetadataSelectedForUpdateStep && (
            <InfoMessage messageText={formik?.errors?.tabsMetadata?.[tabIndex]?.linkedMetadata} />
          )}
          {displayMetadataField && (
            <Box>
              <Box className={classes.itemHelpers}>
                <WizardHelper
                  formName={currentFormName}
                  orderedFields={columnDefs.map(({ field }) => field)}
                  centeredFields={columnDefs
                    .filter(({ centeredFields }) => centeredFields)
                    .map(({ field }) => field)}
                />
              </Box>
              <Box>
                <FieldArray
                  name={`tabsMetadata[${tabIndex}].metadataArray`}
                  render={arrayHelpers => (
                    <MetadataRow
                      contractAddress={dataFromEachTab?.contractAddress}
                      arrayHelpers={arrayHelpers}
                      tabIndex={tabIndex}
                      metadataTypeOptions={metadataTypeOptions}
                      valueChainStepOptions={filterStepDataOption(tabIndex)}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Form>
      </FormikProvider>
    );
  };

  return (
    <Box className={classes.root}>
      {userNotAllowedToViewContent ? (
        <Paper className={classes.accordionPaper}>
          <Typography className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {t('traceability.forbiddenContent')}
          </Typography>
        </Paper>
      ) : (
        <>
          <Typography variant="body1">{t('traceability.metadataForm.description')}</Typography>
          <Box className={classes.formAndTable}>
            <Box className={classes.tabContainer} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              {tabData && (
                <ItemTabs
                  tabData={tabData}
                  currentTab={currentTab}
                  changeTab={(_, value) => setCurrentTab(value)}
                />
              )}
            </Box>
            <div className={classes.editButtonArea}>
              {userAllowedToEdit && !isEditMode && (
                <IconButton
                  id="btn_edit_metadata"
                  color="primary"
                  onClick={toggleEditMode}
                  className={classes.editButton}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>

            {tabData.map((dataFromEachTab, tabIndex) => (
              <Box hidden={currentTab !== tabIndex} key={dataFromEachTab.name}>
                {buildContentArea(dataFromEachTab, tabIndex)}
              </Box>
            ))}

            <WizardButtonArea
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              isCancelDisabled={
                tabData?.length === 0 ||
                lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.VALUE_CHAIN_PERMISSIONS.code
              }
              errors={formik.errors}
              formik={formik}
              isProcessing={isProcessing}
              optionalStep={!formik.dirty && isEmpty(formik.errors) && tabData?.length > 0}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MetadataForm;
