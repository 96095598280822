import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BecMap from '@eyblockchain/ey-ui/core/BecMap';
import Layers from '@eyblockchain/ey-ui/core/BecMap/Layers/Layers';
import MarkerLayer from '@eyblockchain/ey-ui/core/BecMap/Layers/MarkerLayer';
import { validateLatitude, validateLongitude } from '../../utils';

const useStyles = makeStyles(theme => ({
  TokenMapRoot: {
    height: '30vh',
    width: '100%',
    border: `1px solid ${theme.palette.primary.light}`,
    margin: theme.spacing(1),
  },
}));

const TokenMap = ({ longitude, latitude, zoomValue }) => {
  const classes = useStyles();

  const lngValue = validateLongitude(longitude) ? Number(longitude) : 0;
  const latValue = validateLatitude(latitude) ? Number(latitude) : 0;

  /**
   * Style provider URL that contains the mapbox API token needs to be available in the platform config file
   */
  if (!window?.platformConfig?.styleProvider) return null;

  return (
    <BecMap
      center={[lngValue, latValue]}
      zoom={zoomValue}
      disableInteractions
      className={classes.TokenMapRoot}
    >
      <Layers>
        <MarkerLayer long={lngValue} lat={latValue} />
      </Layers>
    </BecMap>
  );
};

TokenMap.propTypes = {
  longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zoomValue: PropTypes.number,
};

TokenMap.defaultProps = {
  longitude: 0,
  latitude: 0,
  zoomValue: 10,
};

export default TokenMap;
