import gql from 'graphql-tag';

export const NOTARIZATION_CONTRACT_ATTRIBUTES = gql`
  fragment NOTARIZATION_CONTRACT_ATTRIBUTES on NotarizationContract {
    _id
    organizationId
    contractName
    contractAddress
    transaction {
      status
      transactionHash
    }
    createdAt
    collaborators {
      partner {
        _id
      }
      role
    }
  }
`;

export const DEPLOY_NOTARIZATION_CONTRACT = gql`
  mutation deployNotarizationContract($contractName: String!) {
    deployNotarizationContract(contractName: $contractName) {
      ...NOTARIZATION_CONTRACT_ATTRIBUTES
    }
  }
  ${NOTARIZATION_CONTRACT_ATTRIBUTES}
`;

export const DEPLOY_NOTARIZATION_CONTRACT_BCOS = gql`
  mutation deployNotarizationContractBcos($contractName: String!) {
    deployNotarizationContractBcos(contractName: $contractName) {
      ...NOTARIZATION_CONTRACT_ATTRIBUTES
    }
  }
  ${NOTARIZATION_CONTRACT_ATTRIBUTES}
`;

export const GET_NOTARIZATION_CONTRACT_DEPLOYED_STATUS = gql`
  subscription onNotarizationContractDeployed {
    notarizationContractDeployed {
      ...NOTARIZATION_CONTRACT_ATTRIBUTES
    }
  }
  ${NOTARIZATION_CONTRACT_ATTRIBUTES}
`;

export const GET_NOTARIZATION_CONTRACTS = gql`
  query notarizationContracts {
    notarizationContracts {
      ...NOTARIZATION_CONTRACT_ATTRIBUTES
    }
  }
  ${NOTARIZATION_CONTRACT_ATTRIBUTES}
`;
