import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  ADD_PARTNER,
  GET_MY_PARTNERS,
  GET_PARTNER_UPDATE,
  GET_ALL_PARTNERS,
} from '../../graphql/Procurement/partner';
import { useNotification } from '../Shared/notification';
import track from '../../mixpanel';

const PartnerContext = React.createContext([{}, () => {}]);

const PartnerProvider = ({ children }) => {
  const { handleNotification } = useNotification();
  const { t } = useTranslation();
  const [
    getPartners,
    { data: partners, loading: getPartnersLoading, error: getPartnersError },
  ] = useLazyQuery(GET_MY_PARTNERS, {
    onError: error => {
      handleNotification(
        t([
          `partners.error.${error?.graphQLErrors[0]?.errorCode}`,
          'partners.error.addPartnerError',
        ]),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getAllPartners,
    { data: allPartners, loading: getAllPartnersLoading, error: getAllPartnersError },
  ] = useLazyQuery(GET_ALL_PARTNERS, {
    onError: error => {
      handleNotification(
        t([
          `partners.error.${error?.graphQLErrors[0]?.errorCode}`,
          'partners.error.addPartnerError',
        ]),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [addPartner, { loading: loadingAddPartner, error: errorAddingPartner }] = useMutation(
    ADD_PARTNER,
    {
      onCompleted: () => {
        handleNotification(t('partners.addPartnerSuccess'), 'success');
        track('Add Partner status received', { AddPartnerSuccess: true });
      },
      onError: error => {
        handleNotification(
          t([
            `partners.error.${error?.graphQLErrors[0]?.errorCode}`,
            'partners.error.addPartnerError',
          ]),
          'error',
        );
        track('Add Partner status received', { AddPartnerSuccess: false });
      },
    },
  );

  useSubscription(GET_PARTNER_UPDATE, {
    onSubscriptionData: async () => {
      await getPartners();
    },
  });
  return (
    <PartnerContext.Provider
      value={{
        partners: partners?.partners || [],
        getPartnersLoading,
        getPartnersError,
        getPartners,
        loadingAddPartner,
        addPartner,
        errorAddingPartner,
        getAllPartners,
        allPartners: allPartners?.getAllPartners || [],
        getAllPartnersLoading,
        getAllPartnersError,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

const usePartnerContext = () => useContext(PartnerContext);

PartnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { usePartnerContext, PartnerProvider };
