import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../constants';
import i18n from '../../i18n';

const useStyles = makeStyles(theme => ({
  copyIcon: {
    marginLeft: '4px',
    width: '20px',
    height: '20px',
    padding: '5px',
    border: '1px solid #C4C4CD',
  },
  copySvg: {
    width: '12px',
    height: '12px',
  },
  statusIcon: {
    borderRadius: '13px',
    color: '#FFFF',
    padding: '4px',
    marginRight: theme.spacing(1.5),
  },
  summaryTitle: {
    fontFamily: 'EYInterstate-Regular',
    fontSize: '14px',
    lineHeight: 1,
  },
  summarySubtitle: {
    fontFamily: 'EYInterstate-Light',
    fontSize: '12px',
    marginRight: theme.spacing(0.5),
  },
  etherscanLink: {
    fontFamily: 'EYInterstate-Regular',
    fontSize: '14px',
  },
  clearItemButton: {
    height: '2rem',
    width: '2rem',
    border: '1px solid #EAEAF2',
    marginRight: theme.spacing(1.5),
    borderRadius: 0,
  },
  valueStyle: {
    fontSize: '12px',
    fontFamily: 'EYInterstate-Regular',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  labelStyle: {
    fontSize: '12px',
    fontFamily: 'EYInterstate-Light',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  progressIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
}));

const Accordion = withStyles({
  root: {
    border: 0,
    boxShadow: 'none',
    backgroundColor: '#F6F6FA',
    padding: 0,
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      border: '1px solid #2E2E38',
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
      backgroundColor: '#FFFFFF',
    },
    '&$expanded': {
      backgroundColor: '#FFFFFF',
      boxShadow:
        'inset 0 1px 0 0 #C4C4CD, inset -1px 0 0 0 #C4C4CD, inset 0 -1px 0 0 #C4C4CD, inset 4px 0 0 0 #FFE600',
      margin: 0,
    },
    '&$expanded:hover ': {
      border: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    padding: 0,
    margin: theme.spacing(0, 0.5),
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 2),
    borderTop: '1px solid #EAEAF2',
  },
}))(MuiAccordionDetails);

/* eslint-disable no-unused-vars, no-nested-ternary, react/jsx-no-target-blank */
const LiveTransactionsItem = ({ transactionItem, handleClickItem, isExpanded, clickClearTnx }) => {
  const { t } = useTranslation('liveTnxPanel');
  const classes = useStyles();
  const [expanded, setExpanded] = useState('');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const formatTransactionType = transactionType => {
    switch (transactionType) {
      case CONSTANTS.TRANSACTION_TYPES.DEPLOY:
        return t('transactitionTypes.deploy');
      case CONSTANTS.TRANSACTION_TYPES.MINT:
        return t('transactitionTypes.mint');
      case CONSTANTS.TRANSACTION_TYPES.BURN:
        return t('transactitionTypes.burn');
      case CONSTANTS.TRANSACTION_TYPES.TRANSFER:
        return t('transactitionTypes.transfer');
      case CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA_ON_CHAIN:
        return t('transactitionTypes.setTokenMetadata');
      case CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA:
        return t('transactitionTypes.updateMetadata');
      case CONSTANTS.TRANSACTION_TYPES.SET_MINTERS:
        return t('transactitionTypes.setMinters');
      default:
        return '';
    }
  };

  const formatTokenType = smartcontractType => {
    if (smartcontractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC721)
      return t('accordionLabels.tokenTypeValues.ERC721');
    if (smartcontractType === CONSTANTS.SMARTCONTRACT_TYPES.ERC20)
      return t('accordionLabels.tokenTypeValues.ERC20');
    return '';
  };

  const getEtherscanLink = () => {
    switch (transactionItem.blockchainNetwork) {
      case 'ropsten':
        return `https://ropsten.etherscan.io/tx/${transactionItem.transactionHash}`;
      case 'mainnet':
        return `https://etherscan.io/tx/${transactionItem.transactionHash}`;
      case 'polygon_testnet':
        return `https://mumbai.polygonscan.com/tx/${transactionItem.transactionHash}`;
      case 'polygon_mainnet':
        return `https://polygonscan.com/tx/${transactionItem.transactionHash}`;
      default:
        return '';
    }
  };

  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
    hour12: false,
    timeZoneName: 'short',
  };

  const formattedDate = new Intl.DateTimeFormat(i18n.language, dateOptions).format(
    new Date(transactionItem.createdAt),
  );

  const panelName = `panel_${transactionItem._id}`;

  return (
    <Accordion square expanded={isExpanded} onChange={handleClickItem(panelName)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box display="flex" flexGrow={1} alignItems="center">
          <Box mr="4px">{isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</Box>
          {transactionItem.status === 'pending' && (
            <CircularProgress className={classes.progressIcon} size="1.5rem" />
          )}
          {transactionItem.status === 'completed' && (
            <CheckIcon className={classes.statusIcon} style={{ backgroundColor: '#168736' }} />
          )}

          {transactionItem.status === 'failed' && (
            <ClearIcon className={classes.statusIcon} style={{ backgroundColor: '#B9251C' }} />
          )}
          <Box flexGrow={1}>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.summaryTitle}>
                {' '}
                {formatTransactionType(transactionItem.transactionType)}{' '}
              </Typography>
              {transactionItem.status !== 'pending' && transactionItem.transactionHash !== '' && (
                <Box display="flex" alignItems="flex-end">
                  <span className={classes.summarySubtitle}>{t('transactionHash')}</span>
                  {getEtherscanLink() !== '' ? (
                    <>
                      <a
                        target="_blank"
                        className={classes.etherscanLink}
                        style={{ color: '#155CB4' }}
                        href={getEtherscanLink()}
                      >
                        {`${transactionItem.transactionHash.slice(
                          0,
                          6,
                        )}...${transactionItem.transactionHash.slice(-4)}`}
                      </a>
                      <LaunchIcon style={{ fontSize: '16px', color: '#155CB4' }} />
                    </>
                  ) : (
                    <span className={classes.etherscanLink}>
                      {`${transactionItem.transactionHash.slice(
                        0,
                        6,
                      )}...${transactionItem.transactionHash.slice(-4)}`}
                    </span>
                  )}
                  <CopyContent
                    content={transactionItem.transactionHash}
                    iconClassName={classes.copySvg}
                    iconButtonClassName={classes.copyIcon}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {isExpanded && transactionItem.status !== 'pending' && (
            <IconButton
              className={classes.clearItemButton}
              disableRipple
              onClick={() => clickClearTnx(transactionItem._id)}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex">
          <Box flexGrow={1} mr={1}>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.labelStyle}>
                {t('accordionLabels.tokenType')}
              </Typography>
              {!(
                transactionItem.transactionType === CONSTANTS.TRANSACTION_TYPES.DEPLOY &&
                transactionItem.status === 'pending'
              ) &&
                transactionItem.contractInfo && (
                  <Typography className={classes.labelStyle} style={{ paddingTop: '5px' }}>
                    {t('accordionLabels.contractAddress')}
                  </Typography>
                )}
              {transactionItem.status !== 'pending' && transactionItem.costInUSD && (
                <Typography className={classes.labelStyle}>
                  {t('accordionLabels.gasFee')}
                </Typography>
              )}
              <Typography className={classes.labelStyle}>
                {t('accordionLabels.timestamp')}
              </Typography>
              {transactionItem.tokenId && transactionItem.tokenId !== null && (
                <Typography className={classes.labelStyle}>
                  {t('accordionLabels.tokenId')}
                </Typography>
              )}
              {transactionItem.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER && (
                <Typography className={classes.labelStyle} style={{ paddingTop: '3px' }}>
                  {t('accordionLabels.toAddress')}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.valueStyle}>
              {formatTokenType(transactionItem.smartcontractType)}
            </Typography>
            {!(
              transactionItem.transactionType === CONSTANTS.TRANSACTION_TYPES.DEPLOY &&
              transactionItem.status === 'pending'
            ) &&
              transactionItem.contractInfo && (
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <span className={classes.valueStyle} style={{ marginBottom: 0 }}>
                    {`${transactionItem.contractInfo.contractAddress.slice(
                      0,
                      6,
                    )}...${transactionItem.contractInfo.contractAddress.slice(-4)}`}
                  </span>
                  <CopyContent
                    content={transactionItem.contractInfo.contractAddress}
                    iconClassName={classes.copySvg}
                    iconButtonClassName={classes.copyIcon}
                  />
                </Box>
              )}
            {transactionItem.status !== 'pending' && transactionItem.costInUSD && (
              <Typography
                className={classes.valueStyle}
              >{`US$ ${transactionItem.costInUSD}`}</Typography>
            )}
            <Typography className={classes.valueStyle}>{formattedDate}</Typography>
            {transactionItem.tokenId && transactionItem.tokenId !== null && (
              <Typography className={classes.valueStyle}>{transactionItem.tokenId}</Typography>
            )}
            {transactionItem.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER && (
              <Box display="flex" alignItems="flex-end" mb={1}>
                <span className={classes.valueStyle} style={{ marginBottom: 0 }}>
                  {`${transactionItem.to.slice(0, 6)}...${transactionItem.to.slice(-4)}`}
                </span>
                <CopyContent
                  content={transactionItem.to}
                  iconClassName={classes.copySvg}
                  iconButtonClassName={classes.copyIcon}
                />
              </Box>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

LiveTransactionsItem.propTypes = {
  transactionItem: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    blockchainNetwork: PropTypes.string,
    transactionHash: PropTypes.string,
    smartcontractType: PropTypes.string,
    transactionType: PropTypes.string,
    createdAt: PropTypes.string,
    costInUSD: PropTypes.number,
    tokenId: PropTypes.string,
    to: PropTypes.string,
    contractInfo: PropTypes.shape({
      contractAddress: PropTypes.string,
      contractName: PropTypes.string,
    }),
  }).isRequired,
  isExpanded: PropTypes.bool,
  handleClickItem: PropTypes.func,
  clickClearTnx: PropTypes.func,
};

LiveTransactionsItem.defaultProps = {
  isExpanded: false,
  handleClickItem: null,
  clickClearTnx: null,
};

export default LiveTransactionsItem;
