import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import WizardProgressBar from '../../components/Traceability/WizardV2/WizardProgressBar';
import ValueChainSummary from '../../components/Traceability/WizardV2/ValueChainSummary';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import BackButton from '../../components/Traceability/BackButton';
import { UPDATE_INSTANCE } from '../../graphql/Traceability/instance';
import {
  CONSTANTS,
  TRACEABILITY_DISPATCHER_ACTIONS,
  TRACE_WIZARD_PROGRESSION_V2,
} from '../../constants';
import { useNotification } from '../../contexts/Shared/notification';
import PageLoader from '../../components/Shared/PageLoader';
import track from '../../mixpanel';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  traceItemRoot: {
    padding: theme.spacing(1),
    minHeight: '100vh',
    minWidth: '1000px',
  },
  buttonArea: {
    marginTop: theme.spacing(3),
  },
  backButton: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}));

const SummaryView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    instanceDetails,
    instanceDispatcher,
    instanceId,
    lastCompletedIteration,
  } = useConfigurationWizardContext();
  const [isDeploymentProcessing, setIsDeploymentProcessing] = useState(false);
  const { handleNotification } = useNotification();
  const history = useHistory();

  const {
    permissionsFlags: { isUserAuthToEditValueChain, isUserAuthToViewValueChainDetails },
  } = useUserInfo();

  const userNotAllowedToViewContent =
    lastCompletedIteration === TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code &&
    !isUserAuthToViewValueChainDetails;

  const userAllowedToEditContent =
    lastCompletedIteration !== TRACE_WIZARD_PROGRESSION_V2.FINALIZE.code ||
    (isUserAuthToEditValueChain && instanceDetails?.content?.isOwner);

  const populateItems = () => {
    if (userNotAllowedToViewContent) {
      return null;
    }
    const items = instanceDetails?.content?.items;
    return items.map(item => <ValueChainSummary item={item} key={uniqid()} />);
  };

  const { activeWallet } = useBecOrganizationContext();

  const [updateInstance] = useMutation(UPDATE_INSTANCE, {
    onCompleted: data => {
      // TODO: metadataCount only works for tokenized items, needs future update for non-tokenized items
      const countMetadata = (prev, curr) =>
        prev + (curr?.tokenContract?.metadataStructure?.metadataConfig?.length || 0);
      const metadataLength = data?.updateInstance?.items?.reduce(countMetadata, 0);

      track(CONSTANTS.MIXPANEL_EVENTS.TRACEABILITY.VALUE_CHAIN_FINALIZATION, {
        blockchainNetwork: data?.updateInstance?.blockchainNetwork,
        valueChainId: data?.updateInstance?._id,
        totalSteps: data?.updateInstance?.steps?.length,
        totalItems: data?.updateInstance?.items?.length,
        totalMetadata: metadataLength,
      });
    },
    onError: error => {
      track(CONSTANTS.MIXPANEL_ERRORS.TRACEABILITY.VALUE_CHAIN_FINALIZATION, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const finalizeValueChain = async () => {
    try {
      setIsDeploymentProcessing(true);
      if (instanceId) {
        await updateInstance({
          variables: {
            instanceId: instanceId,
            status: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
            name: instanceDetails?.content?.name,
          },
        });
        instanceDispatcher({
          type: TRACEABILITY_DISPATCHER_ACTIONS.FINALIZE_INSTANCE,
          payload: CONSTANTS.INSTANCE_STATUSES.ACTIVE,
        });
        setIsDeploymentProcessing(false);
        history.push(`/traceability/instance-review/${instanceId}`);
      }
    } catch (error) {
      setIsDeploymentProcessing(false);
      handleNotification(error, 'error');
    }
  };
  if (isDeploymentProcessing) {
    return (
      <Card variant="outlined">
        <PageLoader />
      </Card>
    );
  }
  return (
    <>
      <WizardProgressBar>
        <div className={classes.traceItemRoot}>
          <div className={classes.formArea}>{populateItems()}</div>
          <div className={classes.buttonArea}>
            <div className={classes.backButton}>
              <BackButton />
            </div>
            {userAllowedToEditContent && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitButton}
                onClick={finalizeValueChain}
                disabled={instanceDetails?.content?.status === CONSTANTS.INSTANCE_STATUSES.ACTIVE}
              >
                {t('traceability.configurationWizard.progress.submit')}
              </Button>
            )}
          </div>
        </div>
      </WizardProgressBar>
    </>
  );
};

export default SummaryView;
