import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '60vh',
    [theme.breakpoints.up('md')]: {
      minHeight: '90vh',
    },
    float: 'left',
    textAlign: 'center',
    align: 'center',
  },
  header: {
    paddingBottom: '30px',
    paddingLeft: '15px',
    paddingRight: '15px',
    textAlign: 'center',
    align: 'center',
    color: '#2e2e38',
    display: 'flex',
    flexGrow: '1',
    marginTop: '72px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '24px',
    fontWeight: 400,
  },
  subTitle: {
    fontWeight: 300,
    width: 'revert',
  },
  subTextWrapper: {
    margin: '30px 0px',
  },
  gridContainer: {
    padding: `0px ${theme.spacing(1)}px`,
    minWidth: '1100px',
    minHeight: '450px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const OcmAccessDeniedLandingPage = ({ redirectUrl, subText, buttonLabel }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="h1"
          align="center"
          data-testid="welcome-heading"
          className={classes.title}
        >
          {t('ocmAccessDeniedLandingPage.title')}
        </Typography>
        <Typography
          className={classes.subTitle}
          dangerouslySetInnerHTML={{ __html: subText || t('ocmAccessDeniedLandingPage.subText') }}
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            window.location.href = redirectUrl;
          }}
        >
          {buttonLabel || t('ocmAccessDeniedLandingPage.buttonLabel')}
        </Button>
      </div>
    </div>
  );
};

OcmAccessDeniedLandingPage.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  subText: PropTypes.string,
  buttonLabel: PropTypes.string,
};

OcmAccessDeniedLandingPage.defaultProps = {
  subText: '',
  buttonLabel: '',
};

export default OcmAccessDeniedLandingPage;
