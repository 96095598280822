import React from 'react';
import { NotarizationProvider } from '../../contexts/Notarization/notarizationContext';
import NotarizationLanding from '../../components/Notarization/NotarizationLanding';
import { CollaboratorProvider } from '../../contexts/Shared/collaboratorContext';

const Notarizations = () => {
  return (
    <>
      <NotarizationProvider>
        <CollaboratorProvider>
          <NotarizationLanding />
        </CollaboratorProvider>
      </NotarizationProvider>
    </>
  );
};

export default Notarizations;
