import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import Table from '@eyblockchain/ey-ui/core/Table';
import uniqid from 'uniqid';
import { getMetadataTypeName } from '../../../utils';
import TableHeadCell from '../../Shared/TableHeadCell';
import { METADATA_STRUCTURE_DATA_MUTABILITY } from '../../../constants';

const useStyles = makeStyles(theme => ({
  perChip: {
    marginRight: '10px',
    borderColor: '#DCE7F4',
    backgroundColor: '#DCE7F4',
  },
  mutableChip: {
    marginRight: '10px',
    borderColor: '#DCEDE1',
    backgroundColor: '#DCEDE1',
  },
  immutableChip: {
    marginRight: '10px',
    borderColor: '#F5DFDD',
    backgroundColor: '#F5DFDD',
  },
  rootdiv: {
    display: 'grid',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  table: {
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
      },
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-root': {
      padding: '11px',
    },
  },
}));

export const customOptions = () => {
  return {
    filter: false,
    customHeadRender: (columnMeta, handleToggleColumn) => (
      <TableHeadCell
        key={uniqid()}
        columnMeta={columnMeta}
        handleToggleColumn={handleToggleColumn}
      />
    ),
  };
};

const MetaDataStructureView = ({ formValues, metaDataTypes, additionalColumns }) => {
  const { metadataArray } = formValues;
  const classes = useStyles();
  const { t } = useTranslation();

  const metadataStructure = metadataArray?.map(metaData => {
    const data = {
      metadataName: metaData.metadataName,
      metadataType: getMetadataTypeName(metaData.metadataType, metaDataTypes),
      metadataPermission: metaData.metadataPermission,
      metadataMutability: metaData.metadataMutability,
    };
    additionalColumns.forEach(_ => {
      // Allow additionalColumn to be object as well in which
      // case the prop name within object is used
      data[_.name] =
        metaData[_.name] === Object(metaData[_.name]) ? metaData[_.name].name : metaData[_.name];
    });
    return data;
  });

  const permissionChip = permission => {
    return (
      <Chip
        className={classes.perChip}
        label={t(`tokens.metaDataStructure.permissions.${permission}`)}
      />
    );
  };

  const mutablityChip = mutability => {
    return (
      <Chip
        color="secondary"
        className={
          mutability === METADATA_STRUCTURE_DATA_MUTABILITY.MUTABLE
            ? classes.mutableChip
            : classes.immutableChip
        }
        label={t(`tokens.metaDataStructure.dataMutability.${mutability}`)}
      />
    );
  };

  const columnDefs = () => {
    const permissionChipColumn = () => {
      const options = customOptions();
      options.customBodyRender = data => {
        return permissionChip(data);
      };
      return options;
    };
    const mutabilityChipColumn = () => {
      const options = customOptions();
      options.customBodyRender = data => {
        return mutablityChip(data);
      };
      return options;
    };

    return [
      { name: 'metadataName', label: t('tokens.metadataName'), options: customOptions() },
      { name: 'metadataType', label: t('tokens.dataType'), options: customOptions() },
      {
        name: 'metadataPermission',
        label: t('tokens.permissionsLabel'),
        options: permissionChipColumn(),
      },
      {
        name: 'metadataMutability',
        label: t('tokens.mutability'),
        options: mutabilityChipColumn(),
      },
    ].concat(additionalColumns);
  };

  return (
    <div className={classes.rootdiv}>
      <Table
        columns={columnDefs()}
        className={classes.table}
        data={metadataStructure}
        options={{
          elevation: 0,
          filter: false,
          searchOpen: false,
          search: false,
          textLabels: {
            body: {
              noMatch: t('tokens.addMetadataInstruction'),
            },
          },
        }}
      />
    </div>
  );
};

MetaDataStructureView.propTypes = {
  formValues: PropTypes.shape({
    metadataArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  metaDataTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  additionalColumns: PropTypes.arrayOf(PropTypes.shape({})),
};

MetaDataStructureView.defaultProps = {
  additionalColumns: [],
};

export default MetaDataStructureView;
