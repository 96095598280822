import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  nameBanner: {
    fontWeight: '400',
  },
  instanceTitle: {
    fontWeight: '100',
  },
}));

const DGPHeaderTitle = ({ instanceName, subHeaderTitle }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h6">
        <span className={classes.instanceTitle}>{t('traceability.dgp.valueChain')}</span>
        {instanceName || t('traceability.instanceName')}
      </Typography>
      <Typography variant="h4" className={classes.nameBanner}>
        {subHeaderTitle}
      </Typography>
    </div>
  );
};

DGPHeaderTitle.propTypes = {
  instanceName: PropTypes.string.isRequired,
  subHeaderTitle: PropTypes.string.isRequired,
};

export default DGPHeaderTitle;
