import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import PropTypes from 'prop-types';
import BlockchainInfo from './BlockchainInfo';
import { CONSTANTS } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  infoArea: {
    marginTop: '2em',
  },
}));

const PaymentConfirmation = ({
  open,
  closeModal,
  contractAddress,
  contractName,
  formik,
  method,
  methodArgs,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const [isLoading, setIsLoading] = useState(true);

  const submitPayment = () => {
    closeModal();
    formik.handleSubmit();
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={closeModal}
    >
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {t('tokens.confirmation')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <div className={classes.infoArea}>
          <Typography variant="body1" className={classes.info}>
            {t(`tokens.confirmationWarning`)}
          </Typography>
          {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
            <BlockchainInfo
              validateForm={formik.validateForm}
              values={formik.values}
              contractAddress={contractAddress}
              contractName={contractName}
              method={method}
              methodArgs={methodArgs}
              defaultExpanded
              setIsLoading={setIsLoading}
            />
          ) : (
            ''
          )}
          <Divider className={classes.divider} />
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={submitPayment}
            data-testid={!isLoading && 'confirmationPopup-confirm'}
          >
            {t('common.confirm')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            onClick={closeModal}
          >
            {t('common.cancel')}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

PaymentConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contractAddress: PropTypes.string,
  contractName: PropTypes.string,
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
  }).isRequired,
  method: PropTypes.string.isRequired,
  methodArgs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PaymentConfirmation.defaultProps = {
  contractAddress: '',
  contractName: '',
};

export default PaymentConfirmation;
