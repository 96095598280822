import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { Card } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import MUITextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { CONSTANTS, PERMISSIONS } from '../../../constants';
import { getPrettifiedEthAddr, parseGraphqlError } from '../../../utils';
import { UPDATE_DESC, HIDE_DOCUMENT } from '../../../graphql/Notarization/documents';
import { useNotification } from '../../../contexts/Shared/notification';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import useUserInfo from '../../../hooks/useUserInfo';
import { useSubscriptionContext } from '../../../contexts/Shared/subscription';
import track from '../../../mixpanel';

const useStyles = makeStyles(theme => ({
  docDetailsPaper: {
    top: '56px',
    position: 'absolute',
    width: '60vw',
  },
  DocDetailsModalRoot: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  txHashLabel: {
    fontWeight: '100',
    marginRight: theme.spacing(2),
  },
  txArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: theme.palette.grey[100],
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  descriptionLabel: {
    fontWeight: '800',
    marginTop: theme.spacing(2),
  },
  descriptionDetails: {
    fontWeight: '100',
    marginTop: theme.spacing(1),
  },
  successChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
  failedChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  progressChip: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  listCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
  },
  errorContentDiv: {
    display: 'flex',
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  completeIcon: {
    color: theme.palette.success.main,
    fontSize: 'xxx-large',
    marginTop: '3px',
    marginLeft: '3px',
  },
  completeContent: {
    padding: theme.spacing(2),
    color: theme.palette.success.main,
  },
  errorContent: {
    padding: theme.spacing(2),
    color: theme.palette.error.main,
  },
  infoContent: {
    padding: theme.spacing(2),
    color: theme.palette.info.main,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  },
  downloadButton: {
    marginRight: theme.spacing(2),
  },
  descriptionArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  txtBoxField: {
    minWidth: '10%',
    width: '100%',
    margin: '5px',
  },
}));

const DocDetailsModal = ({ notarizedDoc, resetDocSelection }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [viewEditMode, setViewEditMode] = useState(false);
  const { handleNotification } = useNotification();
  const {
    removeDocFromList,
    updateDocumentList,
    selectedNotarizationContract,
  } = useNotarizationContext();
  const { storageAccess } = useSubscriptionContext();
  const { allOrgPermissions, permissionsFlags: {isUserAuthToDownloadDocument, isUserAuthToUnlistTransaction, isUserAuthToViewTransactionStatus} } = useUserInfo();
  const { activeWallet } = useBecOrganizationContext();

  const isDetailsViewer =
    allOrgPermissions.find(
      permission => permission.slug === PERMISSIONS.NOTARIZATION.VIEWTRANSACTIONDETAILS,
    ).viewer === 1;

  const formik = useFormik({
    initialValues: {
      description: notarizedDoc?.description,
    },
  });
  const { values: formValues } = formik;

  const valueChangeHandler = event => {
    const newValue = event?.target?.value;
    formik.setFieldValue('description', newValue);
  };

  const [updateDesc, { loading: isUpdateDescLoading }] = useMutation(UPDATE_DESC, {
    onCompleted: data => {
      updateDocumentList(data?.updateDocumentDescription);
      resetDocSelection();
      track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.UPDATE_DOCUMENT_DESCRIPTION, {
        blockchainNetwork: data?.updateDocumentDescription?.blockchainNetwork,
        transactionStatus: data?.updateDocumentDescription?.transaction?.status,
        documentId: data?.updateDocumentDescription?._id,
        contractId: data?.updateDocumentDescription?.notarizationContract?._id,
      });
    },
    onError: err => {
      const parsedError = parseGraphqlError(err) || err;
      handleNotification(parsedError, 'error');
      track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.UPDATE_DOCUMENT_DESCRIPTION, {
        blockchainNetwork: activeWallet?.network,
        error: err.message,
      });
    },
  });

  const [hideDocument] = useMutation(HIDE_DOCUMENT, {
    onCompleted: data => {
      if (data?.hideDocument) {
        removeDocFromList(data?.hideDocument?.documentId);
        const successMessage =
          notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
            ? t('notarization.documentUnlistSuccess')
            : t('notarization.documentDeleteSuccess');
        handleNotification(successMessage, 'success');
        resetDocSelection();
      }
      track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.UNLIST_DOCUMENT, {
        blockchainNetwork: data?.hideDocument?.blockchainNetwork,
        transactionStatus: data?.hideDocument?.transaction?.status,
        documentId: data?.hideDocument?._id,
        contractId: data?.hideDocument?.notarizationContract?._id,
      });
    },
    onError: () => {
      const errorMessage =
        notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
          ? t('notarization.documentUnlistFailed')
          : t('notarization.documentDeleteFailed');
      handleNotification(errorMessage, 'error');
      track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.UNLIST_DOCUMENT, {
        blockchainNetwork: activeWallet?.network,
        error: errorMessage,
      });
    },
  });

  const descriptionDetails = () => {
    const description = notarizedDoc?.description;
    return (
      <div>
        <div className={classes.descriptionArea}>
          <Typography className={classes.descriptionLabel}>
            {t('notarization.description')}
          </Typography>
          {notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED &&
            !isDetailsViewer && (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  setViewEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
        <div>
          {!viewEditMode && (
            <Typography className={classes.descriptionDetails}>{description}</Typography>
          )}
          {viewEditMode &&
            notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED && (
              <MUITextField
                name="description"
                label={t('tokens.writeHere')}
                size="small"
                variant="outlined"
                multiline
                rows={3}
                className={classes.txtBoxField}
                value={formValues.description}
                onChange={valueChangeHandler}
              />
            )}
        </div>
      </div>
    );
  };

  const okButtonClickEvent = () => {
    if (viewEditMode) {
      updateDesc({
        variables: {
          input: {
            documentId: notarizedDoc?.documentId,
            contractId: selectedNotarizationContract?._id,
            description: formValues?.description,
          },
        },
      });
    } else {
      resetDocSelection();
    }
  };

  const hideDocumentHandler = async () => {
    await hideDocument({
      variables: {
        documentId: notarizedDoc.documentId,
        contractId: selectedNotarizationContract._id,
      },
    });
  };

  const statusChip = () => {
    switch (notarizedDoc?.transaction?.status) {
      case CONSTANTS.CONTRACT_STATUSES.FAILED:
        return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
      case CONSTANTS.CONTRACT_STATUSES.PENDING:
        return (
          <Chip label={t('notarization.statusCodes.pending')} className={classes.progressChip} />
        );
      case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
        return (
          <Chip label={t('notarization.statusCodes.completed')} className={classes.successChip} />
        );
      default:
        return <Chip label={t('notarization.statusCodes.failed')} className={classes.failedChip} />;
    }
  };

  const statusDescription = () => {
    switch (notarizedDoc?.transaction?.status) {
      case CONSTANTS.CONTRACT_STATUSES.FAILED:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography className={classes.errorContent}>
              {t('notarization.docErrorMessage')}
            </Typography>
          </Card>
        );
      case CONSTANTS.CONTRACT_STATUSES.PENDING:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <InfoIcon className={classes.infoIcon} />
            <Typography className={classes.infoContent}>
              {t('notarization.docInprogressMessage')}
            </Typography>
          </Card>
        );
      case CONSTANTS.CONTRACT_STATUSES.COMPLETED:
        return (
          <Card variant="outlined" className={classes.listCardRoot}>
            <CheckCircleIcon className={classes.completeIcon} />
            <Typography className={classes.completeContent}>
              {t('notarization.docCompleteMessage')}
            </Typography>
          </Card>
        );
      default:
        return <div />;
    }
  };

  const buttonArea = () => {
    return (
      <div>
        {notarizedDoc?.transaction?.status !== CONSTANTS.CONTRACT_STATUSES.PENDING &&
          isUserAuthToUnlistTransaction && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={hideDocumentHandler}
              disabled={isUpdateDescLoading}
            >
              {notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED
                ? t('notarization.docButtons.unlist')
                : t('notarization.docButtons.delete')}
            </Button>
          )}
        {/* {notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED &&
          storageAccess &&
          isUserAuthToDownloadDocument && (
            <Button variant="contained" color="primary" className={classes.downloadButton} disabled>
              {t('notarization.docButtons.download')}
            </Button>
          )} */}
        <Button
          variant="contained"
          color="secondary"
          onClick={okButtonClickEvent}
          disabled={isUpdateDescLoading}
        >
          {notarizedDoc?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.PENDING
            ? t('notarization.docButtons.ok')
            : t('notarization.docButtons.continue')}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.DocDetailsModalRoot}
      open
      onClose={resetDocSelection}
    >
      <Paper className={classes.docDetailsPaper}>
        <Typography variant="h4" className={classes.title}>
          {notarizedDoc?.filename}
        </Typography>
        <div className={classes.txArea}>
          <Typography variant="body2" className={classes.txHashLabel}>
            {t('notarization.txHashLabel')}
          </Typography>
          <Typography variant="body2">
            {getPrettifiedEthAddr(notarizedDoc.transaction?.transactionHash)}
          </Typography>
          <CopyContent content={notarizedDoc.transaction?.transactionHash} />
        </div>
        {descriptionDetails()}
        {isUserAuthToViewTransactionStatus && !isDetailsViewer && statusChip() && statusDescription()}
        <Divider className={classes.divider} />
        {buttonArea()}
        <IconButton aria-label="close" className={classes.closeButton} onClick={resetDocSelection}>
          <CloseIcon />
        </IconButton>
      </Paper>
    </Modal>
  );
};

DocDetailsModal.propTypes = {
  notarizedDoc: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    description: PropTypes.string,
    documentId: PropTypes.string,
    _id: PropTypes.string,
    transaction: PropTypes.shape({
      transactionHash: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
  }),
  resetDocSelection: PropTypes.func.isRequired,
};

DocDetailsModal.defaultProps = {
  notarizedDoc: null,
};

export default DocDetailsModal;
