import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CustomError from '@eyblockchain/ey-ui/core/BecErrorPages/CustomError';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';
import ContractForm from './ContractForm';
import PageLoader from '../../components/Shared/PageLoader';
import DeployERCTokenContract from '../../components/Tokenization/DeployERCTokenContract';
import useUserInfo from '../../hooks/useUserInfo';
import { CollaboratorProvider } from '../../contexts/Shared/collaboratorContext';
import UpdateBatchMetadata from './UpdateBatchMetadata';

const useStyles = makeStyles(theme => ({
  TokenListRoot: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tokenHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    fontWeight: '100',
    color: theme.palette.primary.light,
  },
  errorBanner: {
    marginTop: theme.spacing(3),
  },
}));

const ContractList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    tokenContractsLoading,
    tokenContracts,
    errorGetTokenContracts,
    totalTokenCount,
    getTokenContractsByPage,
    tokenContractsByPageLoading,
    tokenContractsByPageError,
  } = useTokenContext();
  const { showFaucetModal } = useNotification();

  const { activeWallet } = useBecOrganizationContext();
  const {
    permissionsFlags: { isUserAuthToDeployERC20, isUserAuthToDeployERC721 },
  } = useUserInfo();
  const [updateBatchMetadataFormOpen, setUpdateBatchMetadataFormOpen] = useState(false);
  const [updateBatchMetadataList, setUpdateBatchMetadataList] = useState(false);
  const { setSelectedTokenBatchID } = useTokenContext();

  const isUserAuthToDeploy = isUserAuthToDeployERC20 || isUserAuthToDeployERC721;

  const [deployFormOpen, setDeployFormOpen] = useState(false);

  const handleDeploy = () => {
    setDeployFormOpen(true);
  };

  const closeForm = () => {
    setDeployFormOpen(false);
  };

  const metadaUpdateHandler = (open, batchTokensList) => {
    setUpdateBatchMetadataFormOpen(open);
    if (batchTokensList) {
      setSelectedTokenBatchID(batchTokensList[0].tokenBatchId);
      setUpdateBatchMetadataList(batchTokensList);
    }
  };

  useEffect(() => {
    getTokenContractsByPage({
      variables: {
        pageNumber: 1,
        contractsPerPage: 5,
      },
    });
  }, [getTokenContractsByPage, activeWallet]);

  if (errorGetTokenContracts || tokenContractsByPageError) {
    return (
      <div className={classes.TokenListRoot}>
        <div className={classes.tokenHeaderArea}>
          <Typography variant="h3">{t('headers.token_plural')}</Typography>
        </div>
        <CustomError
          errorCode="500"
          title={t('errors.500')}
          subtitle={t('tokens.errorGetTokenContractsFull')}
          callToAction={t('common.refreshPage')}
          callToActionBtnProps={{ href: '/tokens' }}
        />
      </div>
    );
  }

  if (tokenContractsLoading || tokenContractsByPageLoading) {
    return (
      <div className={classes.TokenListRoot}>
        <PageLoader />
      </div>
    );
  }

  if (updateBatchMetadataFormOpen) {
    return (
      <UpdateBatchMetadata
        hideBatchMetadataUpdate={setUpdateBatchMetadataFormOpen}
        updateBatchMetadataList={updateBatchMetadataList}
      />
    );
  }

  return (
    <>
      <CollaboratorProvider>
        <div className={classes.TokenListRoot}>
          <div className={classes.tokenHeaderArea}>
            <Typography variant="h3">{t('headers.token_plural')}</Typography>

            {isUserAuthToDeploy && (
              <Button
                variant="contained"
                color="secondary"
                id="btnDeployContract"
                onClick={handleDeploy}
              >
                {t('tokens.deployContract')}
              </Button>
            )}
          </div>
          <Typography variant="h5" className={classes.subHeader}>
            {t('tokens.totalContracts')}: {totalTokenCount || 0}
          </Typography>

          <ContractForm
            tokenList={tokenContracts}
            updateBatchMetadataHandler={metadaUpdateHandler}
          />
        </div>
        {!showFaucetModal && (
          <>
            <DeployERCTokenContract
              open={deployFormOpen}
              closeModal={closeForm}
              isERC20Disabled={!isUserAuthToDeployERC20}
              isERC721Disabled={!isUserAuthToDeployERC721}
            />
          </>
        )}
      </CollaboratorProvider>
    </>
  );
};

ContractList.propTypes = {};

ContractList.defaultProps = {
  batchTokensList: [],
};

export default ContractList;
