import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import uniqid from 'uniqid';
import moment from 'moment';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Tooltip from '@material-ui/core/Tooltip';
import ContractPricingTable from '../../components/Procurement/ContractPricingTable';
import ContractAssociatedPurchaseOrders from '../../components/Procurement/ContractAssociatedPurchaseOrders';
import ContractRebateTable from '../../components/Procurement/ContractRebateTable';
import { useAuth } from '../../contexts/Shared/auth';
import {
  formatRates,
  formatCurrencySymbol,
  getPrettifiedEthAddr,
  getBlockchainExplorerUrl,
  getBlockchainExplorerAddressUrl,
} from '../../utils';
import { useTransaction } from '../../contexts/Procurement/transaction';
import { useContractContext } from '../../contexts/Procurement/contract';
import SignButton from '../../components/Shared/SignButton';
// import TruncatedString from '../../components/Shared/TruncatedString';
import BlockchainInfo from '../../components/Shared/BlockchainInfo';
import { CONSTANTS, PERMISSIONS } from '../../constants';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import useUserInfo from '../../hooks/useUserInfo';
import UpdateAuthorizedParties from '../../components/Procurement/updateAuthorizedParties';
import { useOrganizationContext } from '../../contexts/Procurement/organization';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    color: '#747480',
  },
  section: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  modalTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  message: {
    paddingTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  orgTypeText: {
    paddingTop: theme.spacing(4),
    fontSize: theme.spacing(2),
    letterSpacing: 0,
    lineHeight: 0,
    color: theme.palette.primary.main,
  },
  table: {
    boxShadow: '0px 0px 0px 0px',
    paddingTop: '0px',
  },
  ContractPricingTable: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  ContractAssociatedPurchaseOrders: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  approveIcon: {
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
  },
  loaderContainer: theme.loaderContainer,
  navButtonDivider: {
    background: '#e0e0e0',
  },
  navContainer: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    margin: 0,
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  contractTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(3),
    letterSpacing: 0,
    fontWeight: 'bold',
  },
  statusContainer: {
    display: 'flex',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: 'none',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    width: '50%',
    padding: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  cardText: {
    color: theme.colors.blue,
    fontSize: theme.spacing(2.2),
    letterSpacing: 0,
    lineHeight: 'normal',
  },
  button: {
    margin: theme.spacing(1),
  },
  icon: {
    height: '30px',
    width: '30px',
    color: theme.colors.blue,
    marginRight: '.5rem',
  },
  addresses: {
    padding: theme.spacing(1),
    '& > p': {
      fontSize: theme.spacing(1.5),
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: `${theme.spacing(3)}px`,
    },
  },
  addressesLinks: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      color: theme.colors.blue,
      letterSpacing: 0,
      lineHeight: `${theme.spacing(3)}px`,
    },
    cursor: 'pointer',
  },
  circularLoader: {
    marginRight: theme.spacing(3),
  },
  processing: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  successChip: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
    '& > svg': {
      margin: 0,
    },
  },
  gridDisplay: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
  contractDetails: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  gridContainer: {
    display: 'flex',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '1rem',
  },
  iconArrowForward: {
    fontWeight: 'bold',
    height: '16px',
    width: '16px',
  },
  signingBox: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#FFF',
    width: '100%',
    zIndex: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: `-${theme.spacing(2)}px`,
    boxShadow: '-8px 8px 8px 0px rgba(0,0,0,.25)',
  },
  authorizedPartiesChips: {
    margin: '0px 5px 0px 5px',
    display: 'inline',
  },
  chipData: {
    color: '#2E2E38',
    borderColor: '#2E2E38',
    marginLeft: '3px',
    height: '18px',
  },
  chipLabel: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  tooltipContentWrapper: {
    display: 'inline',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  customPadding: {
    padding: '6px 0px',
  },
  editIconWrapper: {
    border: '1px solid #EAEAF2',
    padding: '5px 5px 0px 5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  editIcon: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const ContractModal = ({
  open,
  closeModal,
  contract,
  handleSignContract,
  processing,
  processed,
  smartContract,
  isUpdatingAuthorizedParties,
}) => {
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const classes = useStyles();
  const baseUrl = getBlockchainExplorerUrl(activeWallet.network);
  const baseAddressUrl = getBlockchainExplorerAddressUrl(activeWallet.network);

  const formik = useFormik({
    initialValues: {
      contractId: contract.contractId,
      contractName: contract.contractName || '2',
    },
    validationSchema: Yup.object().shape({
      contractId: Yup.string().required(),
      contractName: Yup.string().required(),
    }),
  });
  return (
    <>
      {!processing ? (
        <Modal className={classes.modal} open={open} onClose={closeModal}>
          <Paper className={classes.paper}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('contracts.transactionReminder')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  {/* Uncomment when implementing dynamic messages with variables */}
                  {/* <Typography variant="body1" display="inline" className={classes.message}>
                    <Trans i18nKey="contracts.transactionCost">
                      <b> eth fee</b>
                      <b> usd </b>
                    </Trans>
                  </Typography> */}
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.wantToProceed')}
                  </Typography>
                  {activeWallet?.networkType === CONSTANTS.NETWORK_TYPES.PUBLIC ? (
                    <BlockchainInfo
                      validateForm={formik.validateForm}
                      values={formik.values}
                      contractAddress=""
                      contractName={CONSTANTS.SMARTCONTRACT_TYPES.ERC721}
                      method={CONSTANTS.TRANSACTION_TYPES.DEPLOY}
                      methodArgs={[formik.values.contractName, formik.values.contractId]}
                    />
                  ) : (
                    ''
                  )}
                  <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.gasExplanation')}
                  </Typography>
                  {/* Uncomment when implementing dynamic messages with variables */}
                  {/* <Typography variant="body1" display="inline" className={classes.message}>
                    {t('contracts.transactionPrivacy')}
                  </Typography> */}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.section}>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.cancel')}
                    </Button>
                    <SignButton
                      onSign={() =>
                        handleSignContract(formik.values.contractName, formik.values.contractId)
                      }
                      className={classes.button}
                      label={t('common.proceed')}
                      testId="signContractAndPushToChain"
                    />
                  </div>
                </Grid>
              </Form>
            </FormikProvider>
          </Paper>
        </Modal>
      ) : (
        <>
          {isUpdatingAuthorizedParties ? (
            <Modal className={classes.modal} open={open && !processed} onClose={closeModal}>
              <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('contracts.pushCommitment')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  <>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.processing}
                    >
                      <CircularProgress
                        color="primary"
                        size={30}
                        thickness={2}
                        className={classes.circularLoader}
                      />
                      <Typography variant="body1" display="inline" className={classes.message}>
                        {t('contracts.pushingCommitment')}
                      </Typography>
                    </Grid>
                    <Card className={classes.signCard}>
                      <CardContent className={classes.cardContent}>
                        <div className={classes.iconContainer}>
                          <InfoIcon className={classes.icon} />
                        </div>
                        <Typography variant="h4" className={classes.cardText}>
                          {t('contracts.processingSigning')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.section}>
                    <Button
                      className={classes.submit}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.close')}
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Modal>
          ) : (
            <Modal className={classes.modal} open={open} onClose={closeModal}>
              <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h4" className={classes.modalTitle}>
                    {t('contracts.pushCommitment')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container direction="column">
                  {!processed ? (
                    <>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.processing}
                      >
                        <CircularProgress
                          color="primary"
                          size={30}
                          thickness={2}
                          className={classes.circularLoader}
                        />
                        <Typography variant="body1" display="inline" className={classes.message}>
                          {t('contracts.pushingCommitment')}
                        </Typography>
                      </Grid>
                      <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                          <div className={classes.iconContainer}>
                            <InfoIcon className={classes.icon} />
                          </div>
                          <Typography variant="h4" className={classes.cardText}>
                            {t('contracts.processingSigning')}
                          </Typography>
                        </CardContent>
                      </Card>
                    </>
                  ) : (
                    <Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.processing}
                      >
                        <Chip type="success" className={classes.successChip} />
                        <Typography variant="body1" display="inline" className={classes.message}>
                          {t('contracts.contractDeployedOnChain')}
                        </Typography>
                      </Grid>
                      <Grid container direction="column">
                        <div className={classes.addresses}>
                          <Typography variant="body2">
                            {t('traceability.instanceReview.smartContractAddress')}
                          </Typography>
                          <div
                            className={classes.addressesLinks}
                            onClick={() => {
                              window.open(`${baseAddressUrl}/${smartContract?.contractAddress}`);
                            }}
                            aria-hidden="true"
                          >
                            <Typography variant="body2">
                              {getPrettifiedEthAddr(smartContract?.contractAddress)}
                            </Typography>
                            <CopyContent content={smartContract?.contractAddress} />
                          </div>
                        </div>
                        <div className={classes.addresses}>
                          <Typography variant="body2">{t('notarization.txHashLabel')}</Typography>
                          <div
                            className={classes.addressesLinks}
                            onClick={() => {
                              window.open(
                                `${baseUrl}/${smartContract?.transaction?.transactionHash}`,
                              );
                            }}
                            aria-hidden="true"
                          >
                            <Typography variant="body2">
                              {getPrettifiedEthAddr(smartContract?.transaction?.transactionHash)}
                            </Typography>
                            <CopyContent content={smartContract?.transaction?.transactionHash} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Divider classes={{ root: classes.navButtonDivider }} />
                  <div className={classes.section}>
                    <Button
                      className={classes.submit}
                      variant="outlined"
                      color="primary"
                      onClick={closeModal}
                    >
                      {t('common.close')}
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

ContractModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    contractId: PropTypes.string,
    contractName: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  handleSignContract: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  processed: PropTypes.bool.isRequired,
  smartContract: PropTypes.shape({
    contractAddress: PropTypes.string,
    transaction: PropTypes.shape({
      transactionHash: PropTypes.string,
    }),
  }),
  isUpdatingAuthorizedParties: PropTypes.bool.isRequired,
};

ContractModal.defaultProps = {
  smartContract: null,
};

const ContractDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: contractId } = useParams();
  const { company } = useAuth();
  const { signData } = useTransaction();
  const { activeOrganization, activeWallet } = useBecOrganizationContext();
  const activeOrganizationId = activeOrganization._id;
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [openUpdateAuthorizedParties, setOpenUpdateAuthorizedParties] = useState(false);
  const [authorizedParties, setAuthorizedParties] = useState([]);
  const [isUpdatingAuthorizedParties, setIsUpdatingAuthorizedParties] = useState(false);

  const history = useHistory();
  const { euroCurrencySymbol, authorizedPartiesAccess } = useSubscriptionContext();
  const {
    getContractDetail,
    contract,
    contractLoading,
    signContract,
    contractDeploySubscription,
    showFaucetModal,
    setShowFaucetModal,
    onpDeploymentFailureTrigger,
    updateAuthorizedOrganizations,
    updatedAuthorizedOrganizationsSubscription,
  } = useContractContext();

  const baseUrl = getBlockchainExplorerUrl(activeWallet.network);
  const baseAddressUrl = getBlockchainExplorerAddressUrl(activeWallet.network);
  const { userPermissions } = useUserInfo();

  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;

  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.GETCONTRACTLISTING)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  useEffect(() => {
    getContractDetail({ variables: { contractId } });
  }, [getContractDetail, contractId, company, activeOrganization]);

  useEffect(() => {
    setProcessing(false);
    setOpen(false);
  }, [onpDeploymentFailureTrigger]);

  useEffect(() => {
    const contractStatus = contractDeploySubscription.data?.contractONPDeployed;
    if (
      contractStatus?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED &&
      contractStatus.contractId === contract?._id
    ) {
      setProcessed(true);
    }
  }, [contractDeploySubscription]);

  useEffect(() => {
    const updateStatus = updatedAuthorizedOrganizationsSubscription.data?.updatedMyOrganizations;
    if (updateStatus?.myOrganizationstatus === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
      setProcessed(true);
    } else if (updateStatus?.myOrganizationstatus === CONSTANTS.CONTRACT_STATUSES.DEPLOYING) {
      setProcessed(false);
    }
  }, [updatedAuthorizedOrganizationsSubscription]);

  useEffect(() => {
    if (contract?.status === 'deploying') setProcessing(true);
    if (contract?.status === 'action-required') setProcessed(false);
  }, [contract]);

  const { organizations, getOrganizations, getOrganizationsLoading } = useOrganizationContext();

  useEffect(() => {
    getOrganizations();
  }, []);

  const orgTypes = {
    subsidiary: {
      label: 'Subsidiaries',
    },
    authorized_user: {
      label: 'Authorized Organizations',
    },
  };

  const organizationList = organizations
    .map(org => {
      return {
        label: org.childOrganization.name,
        organizationId: org.childOrganizationId,
        address: org.childOrganization.wallets[0]?.address,
        role: org.type,
        groupby: orgTypes[org.type].label,
        value: org._id,
        action: null,
      };
    })
    .sort((a, b) => {
      if (a.groupby.toUpperCase() < b.groupby.toUpperCase()) {
        return -1;
      }
      if (a.groupby.toUpperCase() > b.groupby.toUpperCase()) {
        return 1;
      }
      return 0;
    });

  const getAddedAuthorizedParties = (addedAuthorizedParties, myOrganizationsList) => {
    const mapOrganizations = [];
    addedAuthorizedParties.forEach(o => {
      const findOrganization = myOrganizationsList.find(
        x => JSON.stringify(x.value) === JSON.stringify(o.myOrgHeaderId),
      );
      if (findOrganization) {
        mapOrganizations.push(findOrganization);
      }
    });
    return mapOrganizations;
  };

  if (!contract && contractLoading)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  if (!contract || !Object.keys(contract).length) {
    return (
      <OcmAccessDeniedLandingPage
        redirectUrl="/contracts"
        subText={t('contracts.ContractNotFound')}
        buttonLabel={t('common.backToContracts')}
      />
    );
  }

  const items = [];
  contract.materials.forEach(material => {
    let item = {};
    if (contract.contractType === 'tierPricing') {
      const rates = formatRates([material]);
      item = {
        material: contract.rfq ? contract.rfq.material : material.materialName,
        materialDescription: contract.rfq
          ? contract.rfq.materialDescription
          : material.materialDescription,
        contractEndDate: contract.endDate,
        pricings: rates.map((rate, rateIndex) => {
          return {
            Quantity:
              rates.length - 1 === rateIndex
                ? `${rate.startRange} - ${rate.endRange}+`
                : `${rate.startRange} - ${rate.endRange}`,
            NetPrice: formatCurrencySymbol(`${rate.price}`, euroCurrencySymbol),
          };
        }),
      };
    } else {
      item = {
        material: contract.rfq ? contract.rfq.material : material.materialName,
        materialDescription: contract.rfq
          ? contract.rfq.materialDescription
          : material.materialDescription,
        contractEndDate: contract.endDate,
        pricings: material.rebateRules?.thresholdQty?.map((value, index) => {
          return {
            RebateThreshold: value,
            RebatePercentage: `${material.rebateRules.rebateValue[index]}%`,
          };
        }),
        accumulatedVolume: material.accumulatedVolume ? material.accumulatedVolume : 0,
        smartContractId: contract?.smartContract?._id,
        totalValue: material.accumulatedVolume * material.rebateRules?.unitPrice,
        unitPrice: material.rebateRules?.unitPrice,
      };
      item.isOwner = !!item.pricings;
    }
    items.push(item);
  });

  const contractOwners = contract?.myOrganizations.filter(
    o => o.createdBy && o.createdBy === activeOrganizationId,
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignContract = async () => {
    setProcessing(true);
    const blockchainCookie = atob(Cookies.get(`${window.config.cookiePrefix}blockchainInfo`));
    const { blockchainType } = JSON.parse(blockchainCookie);

    const supplierSignature = await signData(contract.preimage, blockchainType);
    const organizationDetails = contract.myOrganizations.map(
      ({ __typename, myOrgHeaderId, ...orgDetails }) => ({
        ...orgDetails,
      }),
    );

    if (
      contract.isSender ||
      (contract.smartContract !== null && contract.supplier.id === activeOrganizationId)
    ) {
      const inputData = {
        supplierOrgId: contract.supplier.organization._id,
        buyerOrgId: contract.buyer.organization._id,
        myOrganizations: authorizedParties,
      };

      const preimageData = { ...inputData };
      const preimage = JSON.stringify(preimageData);
      const buyerSignature = await signData(preimage, blockchainType);

      await updateAuthorizedOrganizations({
        variables: {
          input: {
            ...inputData,
            buyerSignature,
            contractId: contract.contractId,
            blockchainType: blockchainType,
          },
        },
      });
    } else {
      await signContract({
        variables: {
          input: {
            supplierSignature,
            contractId: contract.contractId,
            buyerOrgId: contract.buyer.organization._id,
            myOrganizations: organizationDetails,
          },
        },
      });
    }
  };

  const onUpdateAuthorizedPartiesHandler = async value => {
    setAuthorizedParties(value);
    setOpenUpdateAuthorizedParties(false);
    setProcessing(false);
    setIsUpdatingAuthorizedParties(true);

    if (activeWallet.balance === 0) {
      setShowFaucetModal(true);
    } else {
      setShowFaucetModal(false);
      setOpen(true);
    }
  };

  const showAuthorisedParties = value => {
    if (value.supplier.id === activeOrganizationId && value?.smartContract) {
      return true;
    }

    return (
      authorizedPartiesAccess &&
      value?.isSender &&
      value?.myOrganizations &&
      value?.smartContract &&
      value?.organizationId === activeOrganizationId
    );
  };

  const showUpdateAuthorisedPartiesModal = value => {
    if (value.supplier.id === activeOrganizationId && openUpdateAuthorizedParties) {
      return true;
    }
    return openUpdateAuthorizedParties && authorizedPartiesAccess;
  };

  return isUserNotAuthorized() ? (
    isUserNotAuthorized()
  ) : (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={2}
        className={classes.navContainer}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => history.replace('/contracts')}
          className={classes.navButton}
          startIcon={<ArrowBackIosIcon />}
        >
          {t('headers.contract_plural')}
        </Button>
      </Box>
      <Divider classes={{ root: classes.navButtonDivider }} />
      <Grid className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.section}
        >
          <div>
            <Typography className={classes.contractTitle} variant="h5">
              {`${t('pos.contractId')} `}
              <span className={classes.preTitleId}>CT-{contract.contractId.padStart(10, 0)}</span>
            </Typography>
          </div>
          <div className={classes.statusContainer}>
            <Typography style={{ marginRight: '8px' }}>{t('common.globalStatus')} </Typography>{' '}
            {contract.status.toLowerCase() === 'active' && (
              <Chip type="success" label={t('common.active')} />
            )}
            {contract.status.toLowerCase() === 'pending' && (
              <Chip type="warning" label={t('common.pending')} />
            )}
            {contract.status.toLowerCase() === 'deploying' && (
              <Chip type="warning" label={t('common.deployingContract')} />
            )}
            {contract.status.toLowerCase() === 'action-required' && (
              <Chip type="warning" label={t('common.actionRequired')} />
            )}
            {contract.status.toLowerCase() === 'in-active' && (
              <Chip type="warning" label={t('common.inactive')} />
            )}
          </div>
        </Grid>
        <Grid container direction="row" alignItems="center">
          {contract?.smartContract && (
            <>
              <div className={classes.addresses}>
                <Typography variant="body2">{t('notarization.txHashLabel')}</Typography>
                <div
                  className={classes.addressesLinks}
                  onClick={() => {
                    window.open(
                      `${baseUrl}/${contract?.smartContract?.transaction?.transactionHash}`,
                    );
                  }}
                  aria-hidden="true"
                >
                  <Typography variant="body2">
                    {getPrettifiedEthAddr(contract?.smartContract?.transaction?.transactionHash)}
                  </Typography>
                  <CopyContent content={contract?.smartContract?.transaction?.transactionHash} />
                </div>
              </div>
              <div className={classes.addresses}>
                <Typography variant="body2">
                  {t('traceability.instanceReview.smartContractAddress')}
                </Typography>
                <div
                  className={classes.addressesLinks}
                  onClick={() => {
                    window.open(`${baseAddressUrl}/${contract?.smartContract?.contractAddress}`);
                  }}
                  aria-hidden="true"
                >
                  <Typography variant="body2">
                    {getPrettifiedEthAddr(contract?.smartContract?.contractAddress)}
                  </Typography>
                  <CopyContent content={contract?.smartContract?.contractAddress} />
                </div>
              </div>
            </>
          )}
          <div className={classes.addresses}>
            <Typography variant="body2">{t('contracts.pricingModel')}</Typography>
            <div className={`${classes.contractTitle} ${classes.customPadding}`} aria-hidden="true">
              {contract?.contractType === 'tierPricing' ? (
                <Typography variant="body2">{t('contracts.volumeBasedTirePricing')}</Typography>
              ) : (
                <Typography variant="body2">{t('contracts.fixedPriceWithRebates')}</Typography>
              )}
            </div>
          </div>
          {contract?.contractType === 'rebatePricing' && showAuthorisedParties(contract) && (
            <div className={classes.addresses}>
              <Typography variant="body2">{t('common.authorizedParties')}</Typography>
              <div className={classes.addressesLinks} aria-hidden="true">
                {getOrganizationsLoading && <Loader />}
                <div data-testid="assigned-authorised-parties">
                  {!contractOwners.length && (
                    <div style={{ marginRight: '5px' }}>
                      <Typography variant="body2">None added</Typography>
                    </div>
                  )}
                  {contractOwners.map((org, index) => {
                    const mapOrganization = organizationList.find(
                      o => JSON.stringify(o.value) === JSON.stringify(org.myOrgHeaderId),
                    );
                    if (mapOrganization && index < 2) {
                      return (
                        <Chip
                          label={mapOrganization.label}
                          size="small"
                          className={classes.authorizedPartiesChips}
                        />
                      );
                    }
                    return <></>;
                  })}
                  {contractOwners.length > 2 && (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                      title={
                        <span>
                          {contractOwners.map((org, key) => {
                            const mapOrganization = organizationList.find(
                              o => JSON.stringify(o.value) === JSON.stringify(org.myOrgHeaderId),
                            );
                            return key !== 0 && key !== 1 && mapOrganization ? (
                              <p key={uniqid()}>{` - ${mapOrganization.label}`}</p>
                            ) : (
                              ''
                            );
                          })}
                        </span>
                      }
                      placement="bottom"
                      arrow
                    >
                      <div className={classes.tooltipContentWrapper}>
                        <Chip
                          label={`+${contract?.myOrganizations.length - 2}`}
                          variant="outlined"
                          classes={{
                            root: classes.chipData,
                            label: classes.chipLabel,
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.editIconWrapper}>
                  <EditIcon
                    className={classes.editIcon}
                    fontSize="small"
                    onClick={() => {
                      setOpenUpdateAuthorizedParties(!openUpdateAuthorizedParties);
                    }}
                    data-testid="edit-authorized-parties-modal"
                  />
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Divider classes={{ root: classes.navButtonDivider }} />
        <Grid container className={classes.contractDetails}>
          <Grid item xs={4}>
            <>
              <Typography>
                {contract.isSender ? t('common.supplier') : t('common.buyer')}
              </Typography>
              <Typography className={classes.bold}>
                {contract.isSender
                  ? contract.supplier.organization?.name
                  : contract.buyer.organization?.name}
              </Typography>
              <Typography>
                {contract.isSender
                  ? contract.supplier.organization?.address?.street_line
                  : contract.buyer.organization?.address?.street_line}
              </Typography>
              <Typography>
                {contract.isSender
                  ? contract.supplier.organization.address.city
                  : contract.buyer.organization.address.city}
                {contract.isSender && contract.supplier.organization.address.country
                  ? `, ${contract.supplier.organization.address.country} ${contract.supplier.organization.address.postal_code}`
                  : ''}
                {!contract.isSender && contract.buyer.organization.address.country
                  ? `, ${contract.buyer.organization.address.country} ${contract.buyer.organization.address.postal_code}`
                  : ''}
              </Typography>
              <Typography>
                {contract.isSender
                  ? contract.supplier.organization.phone
                  : contract.buyer.organization.phone}
              </Typography>
            </>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.gridContainer}>
              <div className={classes.infoBlock}>
                <Typography variant="body1" display="inline">
                  {t('contracts.contractStartDate')}
                </Typography>
                <Typography variant="body1" display="inline" className={classes.bold}>
                  {moment(+contract.startDate).format('D MMMM YYYY')}
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography />
                <Typography variant="body1" display="inline" className={classes.bold}>
                  <ArrowForwardIcon className={classes.iconArrowForward} />
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography variant="body1" display="inline">
                  {t('contracts.contractEndDate')}
                </Typography>
                <Typography variant="body1" display="inline" className={classes.bold}>
                  {moment(+contract.endDate).format('D MMMM YYYY')}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        {items && contract?.contractType === 'tierPricing' ? (
          <ContractPricingTable key={uniqid()} materials={items} />
        ) : (
          <ContractRebateTable
            key={uniqid()}
            materials={items}
            smartContract={contract?.smartContract?._id}
          />
        )}
        {items && contract?.contractType === 'tierPricing' ? (
          <ContractAssociatedPurchaseOrders key={uniqid()} materials={items} />
        ) : (
          <ContractAssociatedPurchaseOrders
            key={uniqid()}
            materials={items}
            smartContract={contract?.smartContract?._id}
          />
        )}
        {!contract.isSender &&
          (contract.status === 'action-required' || contract.status === 'deploying') &&
          userPermissions?.contract_manager?.includes(
            PERMISSIONS.CONTRACT_MANAGER.SIGNCONTRACT,
          ) && (
            <div className={classes.signingBox}>
              <SignButton
                onSign={() => {
                  if (activeWallet.balance === 0) {
                    setShowFaucetModal(true);
                  } else {
                    setShowFaucetModal(false);
                    setOpen(true);
                  }
                }}
                label={t('contracts.countersignContract')}
                testId="signContract"
              />
            </div>
          )}
      </Grid>
      {!showFaucetModal && (
        <ContractModal
          open={open}
          closeModal={handleClose}
          contract={contract}
          handleSignContract={handleSignContract}
          processing={processing}
          processed={processed}
          smartContract={contract?.smartContract}
          isUpdatingAuthorizedParties={isUpdatingAuthorizedParties}
        />
      )}
      {contract?.contractType === 'rebatePricing' && showUpdateAuthorisedPartiesModal(contract) && (
        <UpdateAuthorizedParties
          loading={getOrganizationsLoading}
          open={openUpdateAuthorizedParties}
          organizationList={organizationList || []}
          addedAuthorizedParties={getAddedAuthorizedParties(
            contract.myOrganizations,
            organizationList,
          )}
          onUpdateAuthorizedPartiesHandler={onUpdateAuthorizedPartiesHandler}
          closeModal={() => {
            setOpenUpdateAuthorizedParties(false);
          }}
        />
      )}
    </>
  );
};

export default ContractDetails;
