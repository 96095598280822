import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';
import CopyContent from '@eyblockchain/ey-ui/core/CopyContent';
import { CONSTANTS } from '../../constants';
import { getPrettifiedEthAddr } from '../../utils';
import TokenTypeChip from '../Shared/TokenTypeChip';

const useStyles = makeStyles(theme => ({
  listCardRoot: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    padding: '0px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 2px 1px 1px ${theme.palette.primary.lighter}`,
    },
  },
  disabledCard: {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.primary.lightest,
  },
  hiddenCard: {
    display: 'none',
  },
  minCard: {
    width: '40%',
  },
  selectedCard: {
    zIndex: '1',
  },
  imageArea: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  headerArea: {
    display: 'flex',
    alignItems: 'center',
  },
  symbolText: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '22rem',
  },
  minChipArea: {
    justifyContent: 'space-between',
    width: '100%',
  },
  selectedBox: {
    width: '4px',
    backgroundColor: theme.palette.secondary.main,
  },
  minBalanceArea: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    alignItems: 'baseline',
  },
  balanceArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  metaArea: {
    marginLeft: theme.spacing(2),
  },
  addrArea: {
    paddingLeft: '24px',
    borderLeft: `1px solid ${theme.palette.primary.lighter}`,
    display: 'flex',
    alignItems: 'center',
  },
  chipBox: {
    width: '5rem',
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  balanceLabel: {
    color: theme.palette.primary.light,
    marginRight: '1px',
  },
}));

const ContractListCard = ({
  isMinimized,
  contractDetails,
  handleMinimize,
  selectedIndex,
  contractDisable,
  hideContract,
  testId,
}) => {
  const classes = useStyles();
  const isCompleted =
    contractDisable === true
      ? false
      : contractDetails?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED;
  const showLoader = !contractDisable;
  const isSelected = selectedIndex === contractDetails?._id;
  const cardClass = clsx(classes.listCardRoot, {
    [classes.selectedCard]: isSelected,
    [classes.disabledCard]: !isCompleted,
    [classes.hiddenCard]: !hideContract,
  });
  const metaAreaClass = clsx(classes.chipArea, { [classes.minChipArea]: isMinimized });

  const handleContentClick = () => {
    if (isCompleted) {
      handleMinimize(contractDetails?._id);
    }
  };

  const getMetaContent = () => {
    return (
      <div className={classes.addrArea}>
        {contractDetails?.address && (
          <>
            <Typography variant="body2">
              {getPrettifiedEthAddr(contractDetails?.address)}
            </Typography>
            <CopyContent content={contractDetails?.address} />
          </>
        )}
      </div>
    );
  };

  return (
    <Card
      variant="outlined"
      className={cardClass}
      onClick={handleContentClick}
      data-testid={testId}
    >
      {isSelected && <div className={classes.selectedBox} />}
      <div className={classes.imageArea}>
        <ImageIcon fontSize="large" />
      </div>
      <div className={classes.bannerArea}>
        <div className={classes.headerArea}>
          <Typography variant="h5">{contractDetails?.name}</Typography>
          {isCompleted && contractDetails?.symbol && (
            <Typography variant="h5" className={classes.symbolText}>
              ({contractDetails?.symbol})
            </Typography>
          )}
          {!isCompleted && showLoader && (
            <CircularProgress size={20} thickness={20} className={classes.spinner} />
          )}
        </div>
        <div className={metaAreaClass}>
          <div className={classes.chipBox}>
            <TokenTypeChip tokenType={contractDetails.type} />
          </div>
          {!isMinimized && <div className={classes.metaArea}>{getMetaContent()}</div>}
        </div>
      </div>
    </Card>
  );
};

ContractListCard.propTypes = {
  isMinimized: PropTypes.bool.isRequired,
  handleMinimize: PropTypes.func.isRequired,
  selectedIndex: PropTypes.string,
  contractDetails: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    address: PropTypes.string,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  contractDisable: PropTypes.bool,
  hideContract: PropTypes.bool,
  testId: PropTypes.string,
};

ContractListCard.defaultProps = {
  selectedIndex: '-1',
  contractDetails: {
    address: '',
    symbol: '',
  },
  contractDisable: false,
  hideContract: true,
  testId: 'contractCard-',
};

export default ContractListCard;
