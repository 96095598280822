import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  companyInfo: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    fontSize: '0.8rem',
  },

  companyName: {
    marginBottom: theme.spacing(1),
  },

  header: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(3),
  },

  poNumber: {
    fontWeight: 'bold',
  },

  status: {
    color: theme.palette.primary.light,
  },
  details: {
    color: theme.palette.primary.dark,
    // marginBottom: theme.spacing(1),
  },
  tableHead: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2),
  },
  skuDesc: {
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
  data: {
    margin: '1rem 0',
  },
  dataTitle: {
    fontWeight: 'bold',
  },
  row: {
    margin: theme.spacing(1),
  },
  rowHead: {
    fontWeight: 'bold',
  },
  rowNumber: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  section: {
    backgroundColor: '#F6F6FA',
  },

  acknowledgedChip: { background: theme.colors.green, color: theme.colors.white },
  pendingChip: { background: '#A9A9A9' },
  timelineLabel: {
    color: theme.palette.primary.dark,
    // fontWeight: 'bold',
    fontSize: '0.85rem',
  },
  timeline: {
    padding: '0px',
  },
}));
