import request from './request';
import { getActiveOrganizationId } from '../utils';
import { HTTP_METHODS } from '../constants';

const userAPI = ({ handleNotification, t }) => {
  return {
    checkUserPreferences: async ({ userId, errorMessage }) => {
      let userPreferences;
      if (userId) {
        const response = await request(
          `${window.platformConfig.authApiUrl}/users/${userId}`,
          { method: HTTP_METHODS.GET },
          { handleNotification, errorMessage, t },
        );
        const metadata = response?.user_metadata;
        userPreferences = metadata?.preferences;
      }
      return userPreferences;
    },

    getUserData: async ({ userId, errorMessage }) => {
      return request(
        `${window.platformConfig.authApiUrl}/users/${userId}`,
        { method: HTTP_METHODS.GET },
        { handleNotification, errorMessage, t },
      );
    },

    getRolesAndPermissionsFromBEC: async ({ userId, organizationId, errorMessage }) => {
      const appId = window.platformConfig.opschainAppId;
      const currentOrganizationId = organizationId || getActiveOrganizationId();
      let url = `${window.platformConfig.becUrl}/api/organizations/${currentOrganizationId}/applications/${appId}/permissions`;
      if (userId) {
        url += `?findByUser=${userId}`;
      }
      return request(url, { method: HTTP_METHODS.GET }, { handleNotification, errorMessage, t });
    },
    getOrgRolesAndPermissionsFromBEC: async orgId => {
      const appId = window.platformConfig.opschainAppId;
      const url = `${window.platformConfig.becUrl}/api/organizations/${orgId}/applications/${appId}/permissions`;
      return request(url, { method: HTTP_METHODS.GET }, { handleNotification, t });
    },
    getUserPackages: async ({ userId }) => {
      const url = `${window.platformConfig.becUrl}/api/users/${userId}/subscriptions?type=packages`;
      return request(url, { method: HTTP_METHODS.GET }, { handleNotification, t });
    },
    getOrgPackages: async () => {
      const currentOrganizationId = getActiveOrganizationId();
      const url = `${window.platformConfig.becUrl}/api/organizations/${currentOrganizationId}/subscriptions?type=packages`;
      return request(url, { method: HTTP_METHODS.GET }, { handleNotification, t });
    },
    updatePackageForUser: async (userId, packageId) => {
      const url = `${window.platformConfig.becUrl}/api/users/${userId}/subscriptions`;
      return request(
        url,
        {
          method: HTTP_METHODS.PATCH,
          body: {
            operation: 'add',
            path: '/packages',
            value: packageId,
            metadata: {
              start_date: new Date().toISOString(),
              end_date: '',
              max_users: 1,
            },
          },
        },
        { handleNotification, t },
      );
    },
    updatePackageForOrg: async packageId => {
      const currentOrganizationId = getActiveOrganizationId();
      const url = `${window.platformConfig.becUrl}/api/organizations/${currentOrganizationId}/subscriptions`;
      return request(
        url,
        {
          method: HTTP_METHODS.PATCH,
          body: {
            operation: 'add',
            path: '/packages',
            value: packageId,
            metadata: {
              start_date: new Date().toISOString(),
              end_date: '',
              max_users: 1,
            },
          },
        },
        { handleNotification, t },
      );
    },
  };
};

export default {
  userAPI,
};
