import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { groupBy } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import NavItem from '../../NestedListComponent/subcomponents/NavItem';
import NestedList from '../../NestedListComponent/NestedList';

const navSchema = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  subItems: PropTypes.navigationList,
  category: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  divider: {
    height: '1px',
    backgroundColor: '#E7E7EA',
    margin: theme.spacing(1, 0),
  },
  category: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  categoryText: {
    fontWeight: 'bold',
    color: `${theme.palette.primary.light}`,
  },
  selectedPrimaryText: {
    fontWeight: 'bold !important',
  },
  primaryText: {
    fontWeight: 500,
  },
}));

const NavSection = ({ count, index, items, selected }) => {
  const classes = useStyles();
  const categories = groupBy(items, 'category');

  return (
    <>
      {Object.entries(categories).map(currentCategory => (
        <React.Fragment key={currentCategory[0]}>
          {currentCategory[0] && currentCategory[0] !== 'undefined' && (
            <Box component="nav" className={classes.category}>
              <Typography variant="body1" className={classes.categoryText}>
                {currentCategory[0]}
              </Typography>
            </Box>
          )}
          <List component="nav">
            {currentCategory[1]?.map(item =>
              !item.subItems ? (
                <NavItem
                  key={item.name.toLowerCase().replace(' ', '_')}
                  item={item}
                  selected={selected}
                  hasCategory={currentCategory[0] && currentCategory[0] !== 'undefined'}
                  classes={{
                    selectedPrimaryText: classes.selectedPrimaryText,
                    primaryText: classes.primaryText,
                  }}
                />
              ) : (
                <NestedList
                  key={item.name.toLowerCase().replace(' ', '_')}
                  parentItem={item}
                  selected={selected}
                  hasCategory={currentCategory[0] && currentCategory[0] !== 'undefined'}
                  classes={{
                    selectedItemPrimaryText: classes.selectedPrimaryText,
                    rootPrimaryText: classes.primaryText,
                    listItemPrimaryText: classes.primaryText,
                  }}
                />
              ),
            )}
          </List>
        </React.Fragment>
      ))}
      {index < count - 1 && <Divider className={classes.divider} />}
    </>
  );
};

NavSection.propTypes = {
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(navSchema)).isRequired,
  selected: PropTypes.string.isRequired,
};

export default NavSection;
