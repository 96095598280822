import React, { useState } from 'react';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@eyblockchain/ey-ui/core/DialogTitle';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useConfigurationWizardContext } from '../../contexts/Traceability/configurationWizard';
import { UPDATE_INSTANCE } from '../../graphql/Traceability/instance';
import { useNotification } from '../../contexts/Shared/notification';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  instanceNameRoot: {
    padding: theme.spacing(1),
    marginTop: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loader: {
    marginLeft: '2em',
  },
  processingStatus: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '2em',
  },
  errorDisplay: {
    boxShadow: 'none',
  },
}));

const InstanceName = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { instanceDetails } = useConfigurationWizardContext();
  const { content, getInstanceDetails, loadingInstanceDetails } = instanceDetails;
  const instanceName = content?.name;
  const instanceId = content?._id;
  const instanceStatus = content?.status;
  const instanceIssuer = content?.issuer;
  const { handleNotification } = useNotification();

  const {
    permissionsFlags: { isUserAuthToEditValueChain },
  } = useUserInfo();

  const userAllowedToEdit = isUserAuthToEditValueChain && instanceDetails?.content?.isOwner;

  const [isEditNameOpen, setEditNameOpen] = useState(false);
  const [isFormProcessing, setFormProcessing] = useState(false);

  const [updateInstance] = useMutation(UPDATE_INSTANCE);

  const formik = useFormik({
    initialValues: {
      valueChainName: '',
    },
    validationSchema: Yup.object().shape({
      valueChainName: Yup.string().required(t('traceability.missingInstanceName')),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFormProcessing(true);

      try {
        const { valueChainName } = values;

        await updateInstance({
          variables: {
            instanceId: instanceId,
            name: valueChainName,
            issuer: instanceIssuer,
            status: instanceStatus,
          },
        });
        handleNotification(t('traceability.changeInstanceNameSuccess', 'success'));
        getInstanceDetails({ variables: { input: instanceId } });
      } catch (err) {
        handleNotification(err, 'error');
      } finally {
        setEditNameOpen(false);
        setFormProcessing(false);
        resetForm();
      }
    },
  });

  const { resetForm } = formik;

  const handleClose = () => {
    setEditNameOpen(false);
    setFormProcessing(false);
    resetForm();
  };

  const handleOpen = () => {
    setEditNameOpen(true);
  };

  return (
    <>
      <div className={classes.instanceNameRoot} data-testid="instance-name-wizard">
        {loadingInstanceDetails && (
          <>
            <Typography variant="h3">{t('traceability.loadInstanceName')}</Typography>
            <CircularProgress size={30} thickness={20} color="primary" className={classes.loader} />
          </>
        )}
        {!loadingInstanceDetails && (
          <>
            <Typography variant="h3">{instanceName}</Typography>
            {userAllowedToEdit && (
              <IconButton size="small" onClick={handleOpen} className={classes.pencilIcon}>
                <CreateIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
      <Dialog open={isEditNameOpen} onClose={handleClose} fullWidth>
        <DialogTitle onClose={handleClose}>{t('traceability.changeInstanceName')}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent dividers>
              <Field
                size="small"
                component={TextField}
                label={t('traceability.instanceName')}
                name="valueChainName"
                className={classes.addrField}
              />
              {!isFormProcessing && (
                <FormErrors form={formik} className={classes.errorDisplay} bgcolor="none" />
              )}
              {isFormProcessing && (
                <div className={classes.processingStatus}>
                  <Typography variant="body2">{t('common.processing')}</Typography>
                  <CircularProgress
                    size={10}
                    thickness={10}
                    color="primary"
                    className={classes.loader}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                disabled={isFormProcessing}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isFormProcessing}
                data-testid="update-value-chain-name-button"
              >
                {t('common.save')}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
};

export default InstanceName;
