import gql from 'graphql-tag';
import { PURCHASE_ORDER_ATTRIBUTES } from './purchaseOrder';

export const INVOICE_ATTRIBUTES = gql`
  fragment INVOICE_ATTRIBUTES on Invoice {
    _id
    purchaseOrder {
      ...PURCHASE_ORDER_ATTRIBUTES
    }
    buyer {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    supplier {
      id
      signature
      signedBy
      organization {
        _id
        country
        city
        street_line
        postal_code
        state
        phone
        name
        organizationId
        address {
          street_line
          country
          city
          postal_code
          state
          phone
        }
      }
    }
    isSender
    invoiceId
    invoiceDate
    dueDate
    status
  }
  ${PURCHASE_ORDER_ATTRIBUTES}
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: inputInvoice!) {
    createInvoice(input: $input) {
      ...INVOICE_ATTRIBUTES
    }
  }
  ${INVOICE_ATTRIBUTES}
`;

export const GET_INVOICES = gql`
  query invoices {
    invoices {
      ...INVOICE_ATTRIBUTES
    }
  }
  ${INVOICE_ATTRIBUTES}
`;

export const GET_INVOICE_BY_ID = gql`
  query invoice($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
      ...INVOICE_ATTRIBUTES
    }
  }
  ${INVOICE_ATTRIBUTES}
`;

export const GET_INVOICE_UPDATE = gql`
  subscription onNewInvoice {
    newInvoice {
      ...INVOICE_ATTRIBUTES
    }
  }
  ${INVOICE_ATTRIBUTES}
`;
