import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework';
import { CircularProgress } from '@material-ui/core';
import SmartContractModal from './SmartContractModal';
import MandatoryLabel from './MandatoryLabel';
import {
  DEPLOY_ERC721_BCOS,
  DEPLOY_ERC721_BSN,
  DEPLOY_ERC721,
  GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS,
  DEPLOY_ERC1155,
} from '../../../graphql/Tokenization/token';
import WizardTooltip from './WizardTooltip';
import { CONSTANTS, TRACEABILITY_FORM } from '../../../constants';
import { useNotification } from '../../../contexts/Shared/notification';
import track from '../../../mixpanel';

const useStyles = makeStyles(theme => ({
  ItemRowRoot: {
    display: 'grid',
    gridTemplateColumns: '24% 24% 24%  8% 11% 5%',
    margin: '0.5em 0',
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    gridGap: '0.5%',
  },
  selectField: {
    minWidth: '18%',
  },
  checkField: {
    justifySelf: 'center',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '18%',
  },
  setUpButton: {
    border: `solid 1px ${theme.palette.primary.lighter}`,
    borderRadius: '5px',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    height: 'max-content',
    width: '100%',
  },
  deleteButton: {
    height: 'max-content',
  },
  switchButton: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.success.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
  chipRoot: {
    width: '100%',
    position: 'relative',
    alignSelf: 'center',
    fontWeight: '400',
    fontSize: '0.9rem',
    justifyContent: 'left',
  },
  contractTooltip: {
    fontSize: '0.9rem',
  },
  chipDeleteIcon: {
    position: 'absolute',
    right: 0,
  },
  formLabelRoot: {
    marginLeft: '0',
  },

  spinner: {
    verticalAlign: 'sub',
    position: 'absolute',
    right: '6px',
  },
  chipRootLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: '6px',
  },
}));

const ItemRowV2 = ({
  field: { name, value: itemRow },
  form: { errors, setFieldValue },
  index,
  dependsOnOptions,
  itemTypeOptions,
  updateDependencies,
  onRemove,
  instanceId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSmartContractModalOpen, setIsSmartContractModalOpen] = useState(false);

  const emptyContract = {
    contractName: '',
    contractId: '',
  };

  const [pendingToken, setPendingToken] = useState();
  const { activeWallet } = useBecOrganizationContext();
  const { handleNotification, setShowFaucetModal } = useNotification();
  const nameRef = useRef();

  const parentArrayName = name && name.split('.')[0];
  let currItemErrors;

  currItemErrors = null;
  if (!isEmpty(errors) && errors[parentArrayName]) {
    currItemErrors = errors[parentArrayName][index];
  }

  const fieldNames = {
    itemName: `item-${index}-itemName`,
    itemType: `item-${index}-itemType`,
    dependsOn: `item-${index}-dependsOn`,
    codifiedWith: `item-${index}-codifiedWith`,
    tokenize: `item-${index}-tokenize`,
    tokenContract: `item-${index}-tokenContract`,
  };

  const itemChangeHandler = event => {
    const modifiedField = event?.target?.name;
    const newValue = event?.target?.value;
    const newCheckedValue = event?.target?.checked;
    if (!modifiedField) {
      throw new Error('Invalid field or value');
    }

    switch (modifiedField) {
      case fieldNames.itemType:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...itemRow,
          itemType: newValue,
          dirty: true,
        });
        break;
      case fieldNames.dependsOn:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...itemRow,
          dependsOn: newValue,
          dirty: true,
        });
        break;
      case fieldNames.codifiedWith:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...itemRow,
          codifiedWith: newValue,
          dirty: true,
        });
        break;
      case fieldNames.tokenize:
        setFieldValue(`${parentArrayName}.${index}`, {
          ...itemRow,
          tokenize: newCheckedValue,
          tokenContract: newCheckedValue ? itemRow.tokenContract : emptyContract,
          dirty: true,
        });
        break;
      default:
        break;
    }
  };

  const tokenContractChangeHandler = contract => {
    setFieldValue(`${parentArrayName}.${index}`, {
      ...itemRow,
      tokenContract: contract,
      dirty: true,
    });
  };

  const resetTokenContract = () => {
    tokenContractChangeHandler(emptyContract);
  };

  const [deployERC721TokenContract] = useMutation(DEPLOY_ERC721, {
    onCompleted: data => {
      setPendingToken(data?.deployErc721Eth?._id);
      track(CONSTANTS.MIXPANEL_EVENTS.TRACEABILITY.NEW_SMART_CONTRACT, {
        blockchainNetwork: activeWallet?.network,
        valueChainId: instanceId,
        contractId: data?.deployErc721Eth?._id,
        contractType: data?.deployErc721Eth?.tokenType,
      });
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') {
        setShowFaucetModal(true);
      }
      setPendingToken(null);
      resetTokenContract();
      track(CONSTANTS.MIXPANEL_ERRORS.TRACEABILITY.NEW_SMART_CONTRACT, {
        blockchainNetwork: activeWallet?.network,
        error: error.message,
      });
    },
  });

  const [deployERC1155TokenContract] = useMutation(DEPLOY_ERC1155, {
    onCompleted: data => {
      setPendingToken(data?.deployERC1155Contract?._id);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') {
        setShowFaucetModal(true);
      }
      setPendingToken(null);
      resetTokenContract();
    },
  });

  const [deployERC721TokenContractBCOS] = useMutation(DEPLOY_ERC721_BCOS, {
    onCompleted: data => {
      setPendingToken(data?.deployErc721Bcos?._id);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') {
        setShowFaucetModal(true);
      }
      setPendingToken(null);
      resetTokenContract();
    },
  });

  const [deployERC721TokenContractBsn] = useMutation(DEPLOY_ERC721_BSN, {
    onCompleted: data => {
      setPendingToken(data?.deployErc721Bsn?._id);
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') {
        setShowFaucetModal(true);
      }
      setPendingToken(null);
      resetTokenContract();
    },
  });

  const deployErc721Contract = async variables => {
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        const response = await deployERC721TokenContractBCOS(variables);
        setPendingToken(response?.data?.deployErc721Bcos?._id);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
      case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
        const response = await deployERC721TokenContract(variables);
        setPendingToken(response?.data?.deployErc721Eth?._id);
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BSN: {
        const response = await deployERC721TokenContractBsn(variables);
        setPendingToken(response?.data?.deployErc721Bsn?._id);
        break;
      }
      default:
        break;
    }
  };

  useSubscription(GET_ERC_TOKEN_CONTRACT_DEPLOYED_STATUS, {
    onSubscriptionData: data => {
      const contract = data.subscriptionData?.data?.smartContractDeployed;
      if (pendingToken === contract?._id) {
        if (contract?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          const updatedContract = {
            contractName: contract?.contractName,
            contractId: contract?._id,
          };
          tokenContractChangeHandler(updatedContract);
        } else {
          handleNotification(t('tokens.deployError'), 'error');
          resetTokenContract();
        }
      }
    },
  });

  const closeSmartContractModal = () => {
    setIsSmartContractModalOpen(false);
  };

  const onContractSubmit = async (values, { resetForm }) => {
    if (values.contractSelection === TRACEABILITY_FORM.SELECT_CONTRACT) {
      const contract = {
        contractName: values.selectedContractName,
        contractId: values.selectedContractId,
      };
      tokenContractChangeHandler(contract);
      resetForm();
      closeSmartContractModal();
    }
    if (values.contractSelection === TRACEABILITY_FORM.CREATE_CONTRACT) {
      const contract = {
        contractName: values.newContractName,
        contractId: '',
      };
      tokenContractChangeHandler(contract);
      const variables = {
        variables: {
          tokenName: values.newContractName,
          tokenSymbol: values.newContractSymbol,
          baseURI: '',
        },
      };
      resetForm();
      closeSmartContractModal();
      switch (values.selectedTokenType) {
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC1155:
          deployERC1155TokenContract(variables);
          break;
        case CONSTANTS.SMARTCONTRACT_TYPES.ERC721:
          deployErc721Contract(variables);
          break;
        default:
          throw new Error(t('traceability.tokenTypeRequired'));
      }
    }
  };

  const contractChip = () => {
    const params = {};
    let tooltipText = '';
    let progressIcon = null;

    if (itemRow.tokenContract.contractId) {
      tooltipText = (
        <>
          <strong>{t('traceability.traceItemForm.labels.contractName')}: </strong>
          {itemRow?.tokenContract?.contractName}
        </>
      );
      params.onDelete = resetTokenContract;
      params.label = {};
    } else {
      tooltipText = t('traceability.contractDeployProgressing');
      progressIcon = <CircularProgress size={20} thickness={20} className={classes.spinner} />;
    }

    return (
      <WizardTooltip text={<div className={classes.contractTooltip}>{tooltipText}</div>}>
        <div className={classes.chipRoot}>
          <Chip
            {...params}
            label={
              <div className={classes.chipRootLabel}>
                {itemRow?.tokenContract?.contractName}
                {progressIcon}
              </div>
            }
            classes={{
              root: classes.chipRoot,
              deleteIcon: classes.chipDeleteIcon,
            }}
          />
        </div>
      </WizardTooltip>
    );
  };

  const buildOptions = option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  );

  return (
    <div className={classes.ItemRowRoot}>
      <TextField
        label={
          <MandatoryLabel
            text={t('traceability.traceItemForm.labels.name')}
            error={currItemErrors && !!currItemErrors.itemName}
          />
        }
        size="small"
        variant="outlined"
        inputRef={nameRef}
        defaultValue={itemRow.itemName}
        InputProps={{
          classes: {
            input: classes.itemNameInput,
          },
        }}
        onBlur={() => {
          setFieldValue(`${parentArrayName}.${index}`, {
            ...itemRow,
            itemName: nameRef.current.value,
            dirty: true,
          });
          updateDependencies(nameRef.current.value, itemRow.itemId);
        }}
        data-testid="add-item-name-stage1"
      />

      <TextField
        select
        size="small"
        variant="outlined"
        label={t('traceability.traceItemForm.labels.select')}
        name={fieldNames.itemType}
        onChange={itemChangeHandler}
        error={currItemErrors && !!currItemErrors.itemType}
        disabled={!itemTypeOptions.length}
        value={itemRow.itemType}
        classes={{
          root: classes.selectField,
        }}
        data-testid="add-item-type-stage1"
      >
        {itemTypeOptions.map(buildOptions)}
      </TextField>

      <TextField
        select
        size="small"
        variant="outlined"
        label={t('traceability.traceItemForm.labels.select')}
        name={fieldNames.dependsOn}
        onChange={itemChangeHandler}
        error={currItemErrors && !!currItemErrors.dependsOn}
        disabled={dependsOnOptions.length <= 2}
        value={itemRow.dependsOn}
        classes={{
          root: classes.selectField,
        }}
      >
        {dependsOnOptions.filter(option => option.value !== itemRow.itemId).map(buildOptions)}
      </TextField>

      {/* <TextField
        select
        size="small"
        variant="outlined"
        label={t('traceability.traceItemForm.labels.select')}
        name={fieldNames.codifiedWith}
        onChange={itemChangeHandler}
        error={currItemErrors && !!currItemErrors.codifiedWith}
        disabled={!codificationOptions.length}
        value={itemRow.codifiedWith}
        classes={{
          root: classes.selectField,
        }}
      >
        {codificationOptions.map(buildOptions)}
      </TextField> */}

      <div className={classes.checkField}>
        <FormControlLabel
          control={
            <Switch
              className={classes.switchButton}
              name={fieldNames.tokenize}
              checked={itemRow.tokenize}
              color="primary"
              onChange={itemChangeHandler}
              disabled
            />
          }
          label={
            itemRow.tokenize
              ? t('traceability.traceItemForm.labels.yes')
              : t('traceability.traceItemForm.labels.no')
          }
          classes={{
            root: classes.formLabelRoot,
          }}
        />
      </div>

      {itemRow.tokenContract?.contractName ? (
        contractChip()
      ) : (
        <div className={classes.tokenContractField}>
          <Button
            disabled={!itemRow.tokenize}
            className={`${classes.setUpButton} ${itemRow.tokenize ? classes.setUpButtonError : ''}`}
            onClick={() => setIsSmartContractModalOpen(true)}
            data-testid="setup-contract-stage1"
          >
            <MandatoryLabel
              text={t('traceability.traceItemForm.labels.setUp')}
              error={currItemErrors && !!currItemErrors.tokenContract}
            />
          </Button>
        </div>
      )}

      <Button className={classes.deleteButton} onClick={onRemove}>
        <DeleteIcon />
      </Button>

      <SmartContractModal
        open={isSmartContractModalOpen}
        closeModal={closeSmartContractModal}
        itemName={itemRow.itemName}
        onContractSubmit={onContractSubmit}
      />
    </div>
  );
};

ItemRowV2.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({}),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
  dependsOnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  itemTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  updateDependencies: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
};

ItemRowV2.defaultProps = {};

export default ItemRowV2;
