import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const PROTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}))(Tabs);

const PROTab = withStyles(theme => ({
  root: {
    '&$selected': {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
    },
  },
  selected: {},
}))(props => <Tab {...props} />);

export { PROTab, PROTabs };
