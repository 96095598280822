import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
  },
  infoArea: {
    marginTop: '2em',
  },
  warningPaper: {
    margin: theme.spacing(2),
    width: '100%',
  },
  message: {
    display: 'flex',
    fontSize: '.875rem',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: theme.colors.blue,
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginLeft: theme.spacing(2),
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
}));

const ConfirmationPopup = ({ popupData, closePopup }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { title, warning, confirmation, actionParameters, executeAction, content } = popupData;
  const [confirmClicked, setConfirmClicked] = useState(false);
  return (
    <Modal className={classes.modal} open onClose={closePopup}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closePopup}>
          <CloseIcon />
        </IconButton>
        <div className={classes.infoArea}>
          <Typography variant="body1" className={classes.info}>
            {confirmation}
          </Typography>

          {!!warning && (
            <Paper className={classes.warningPaper}>
              <Typography className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {warning}
              </Typography>
            </Paper>
          )}

          {!!content && <Typography>{content}</Typography>}

          <Divider className={classes.divider} />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setConfirmClicked(true);
              executeAction(actionParameters);
            }}
            disabled={confirmClicked}
          >
            {t('common.confirm')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            onClick={closePopup}
          >
            {t('common.cancel')}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  popupData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    confirmation: PropTypes.object.isRequired,
    warning: PropTypes.string.isRequired,
    actionParameters: PropTypes.shape({}).isRequired,
    executeAction: PropTypes.func.isRequired,
    content: PropTypes.object,
  }).isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default ConfirmationPopup;
