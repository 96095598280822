import React, { useState, useEffect } from 'react';
import Table from '@eyblockchain/ey-ui/core/Table';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import uniqid from 'uniqid';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Public from '@material-ui/icons/Public';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import set from 'lodash/set';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import TableHeadCell from '../../components/Shared/TableHeadCell';
import MintNonFungibleToken from '../../components/Tokenization/MintNonFungibleToken';
import TransferErc721Token from '../../components/Tokenization/TransferErc721Token';
import MetadataErc721Token from '../../components/Tokenization/MetadataErc721Token';
import { useAuth } from '../../contexts/Shared/auth';
import { useNotification } from '../../contexts/Shared/notification';
import { useTokenContext } from '../../contexts/Tokenization/token';
import TokenSearchBar from '../../components/Tokenization/TokenSearchBar';
import PageLoader from '../../components/Shared/PageLoader';
import IsolatedMenu from '../../components/Shared/IsolatedMenu';
import {
  GET_ERC721_TOKEN_LIST,
  MINT_ERC721,
  MINT_ERC721_BCOS,
  MINT_ERC721_BSN,
  TRANSFER_ERC721,
  TRANSFER_ERC721_BCOS,
  TRANSFER_ERC721_BSN,
  TRANSACTION_STATUS,
  UPDATE_ERC721_TOKEN_METADATA,
  UPDATE_ERC721_TOKEN_METADATA_BCOS,
  UPDATE_ERC721_TOKEN_METADATA_BSN,
  DEPOSIT_ERC721,
  WITHDRAW_ERC721,
  ZKP_TRANSFER_ERC721,
  ZKP_TRANSACTION_STATUS,
} from '../../graphql/Tokenization/token';
import { CONSTANTS } from '../../constants';
import track from '../../mixpanel';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
      },
    },
    '& .MuiTableCell-alignRight': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiList-root': {
      margin: '0',
      padding: '0',
      position: 'relative',
      listStyle: 'none',
    },
    '& .MuiList-padding': {
      paddingTop: '0',
      paddingBottom: '0',
    },
    '& .MuiList-subheader': {
      paddingTop: '0',
    },
  },
  button: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderColor: '#000000',
    marginRight: theme.spacing(1),
    height: '10px',
    minWidth: '9em',
    fontWeight: '100',
    whiteSpace: 'nowrap',
  },
  iconButton: {
    background: theme.palette.primary.lightest,
    padding: theme.spacing(1),
  },
  balanceLoader: {
    height: '10%',
    width: '10%',
  },
  positionLoader: {
    position: 'relative',
    bottom: '3px',
    left: '10px',
  },
  titleStyle: {
    marginLeft: '-10px',
  },
  popperStyle: {
    margin: '0px',
    padding: '0px',
    zIndex: '1',
    width: '200px',
    marginLeft: '-60px',
  },
  growStyles: {
    padding: '0px',
    zIndex: '1',
  },
  zeroStyle: {
    margin: '0px',
    padding: '0px',
  },
  menuItem: {
    fontSize: '12px',
  },
  visibilityIcon: {
    verticalAlign: 'middle',
    paddingRight: theme.spacing(1),
  },
  tooltip: {
    backgroundColor: '#2E2E38',
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '12px',
    color: '#fff',
  },
  tooltipArrow: {
    color: '#2E2E38',
  },
  icon: {
    fontSize: '1.5em',
  },
  privateIcon: {
    fill: theme.colors.green,
  },
  publicIcon: {
    fill: theme.colors.blue,
  },
  chipPrivate: {
    color: theme.colors.green,
    borderColor: theme.colors.green,
  },
  chipPublic: {
    color: theme.colors.blue,
    borderColor: theme.colors.blue,
  },
}));

const ListingERC721 = () => {
  const classes = useStyles();
  const { id: tokenId } = useParams();
  const { t } = useTranslation();
  const { company } = useAuth();
  const [tokens, setTokens] = useState([]);
  const [contractAddress, setContractAddress] = useState(null);
  const [selectedToken, setSelectedToken] = useState({});
  const [mintErc721FormOpen, setMintErc721FormOpen] = useState(false);
  const [transferErc721FormOpen, setTransferErc721FormOpen] = useState(false);
  const [metadataErc721FormOpen, setMetadataErc721FormOpen] = useState(false);
  const { handleNotification, showFaucetModal, setShowFaucetModal } = useNotification();
  const {
    incrementPendingTransactions,
    decrementPendingTransactions,
    incrementPendingTransfers,
    decrementPendingTransfers,
    hasPendingTransfers,
    pendingDepositsCount,
    setPendingDepositsCount,
    pendingWithdrawsCount,
    setPendingWithdrawsCount,
    pendingZkpSingleTransferCount,
    setPendingZkpSingleTransferCount,
  } = useTokenContext();
  const { activeWallet } = useBecOrganizationContext();
  const { zkpAccess } = useSubscriptionContext();

  const resetForms = () => {
    setSelectedToken({});
    if (mintErc721FormOpen) setMintErc721FormOpen(false);
    if (transferErc721FormOpen) setTransferErc721FormOpen(false);
    if (metadataErc721FormOpen) setMetadataErc721FormOpen(false);
  };

  useEffect(() => {
    if (showFaucetModal) resetForms();
  }, [showFaucetModal]);

  const [getErc721TokenList, { loading: tokenDataLoading }] = useLazyQuery(GET_ERC721_TOKEN_LIST, {
    variables: { smartContractId: tokenId },
    onCompleted: data => {
      setTokens(data.erc721TokenList);
      setContractAddress(data.erc721TokenList[0]?.smartContract?.contractAddress);
      data.erc721TokenList.forEach(token => {
        if (token.pendingZkpTransaction === 'deposit') {
          set(
            pendingDepositsCount,
            `${token.smartContract.contractAddress}.${token.tokenId}`,
            true,
          );
        }

        if (token.pendingZkpTransaction === 'withdraw') {
          set(
            pendingWithdrawsCount,
            `${token.smartContract.contractAddress}.${token.tokenId}`,
            true,
          );
        }

        if (token.pendingZkpTransaction === 'single_transfer') {
          set(
            pendingZkpSingleTransferCount,
            `${token.smartContract.contractAddress}.${token.tokenId}`,
            true,
          );
        }
      });
      setPendingDepositsCount({ ...pendingDepositsCount });
      setPendingWithdrawsCount({ ...pendingWithdrawsCount });
      setPendingZkpSingleTransferCount({ ...pendingZkpSingleTransferCount });
    },
    onError: error => {
      track('ERC721 Token Listing Error');
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.contractDataError']),
        'error',
      );
    },
    fetchPolicy: 'no-cache',
  });

  const openForm = token => {
    setTransferErc721FormOpen(true);
    setSelectedToken(token);
  };

  const closeForm = formType => {
    setSelectedToken({});
    if (formType === 'mintErc721') setMintErc721FormOpen(false);
    if (formType === 'transferErc721') setTransferErc721FormOpen(false);
    if (formType === 'metadataErc721') setMetadataErc721FormOpen(false);
  };

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const newState = [...tokens];
      const contract = data.subscriptionData?.data?.transactionStatus;
      if (contract?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
        getErc721TokenList();
        handleNotification(
          t([`tokens.${contract.transactionType}Success`, 'tokens.transactionSuccess']),
          'success',
        );
        track('Token transaction success', {
          tokenType: contract.smartcontractType || 'Unknown token type',
          transactionType: contract.transactionType || 'Unknown transaction type',
        });
      } else if (contract?.status === CONSTANTS.CONTRACT_STATUSES.FAILED) {
        handleNotification(
          t([`tokens.${contract.transactionType}Error`, 'tokens.transactionFailure']),
          'error',
        );
        track('Token transaction failure', {
          tokenType: contract.smartcontractType || 'Unknown token type',
          transactionType: contract.transactionType || 'Unknown transaction type',
        });
      }
      setTokens(newState);
      if (
        contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.TRANSFER ||
        contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.BURN ||
        contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.UPDATE_METADATA
      ) {
        decrementPendingTransfers(contract?.transactionHash);
      } else if (contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.MINT) {
        decrementPendingTransactions(contract?.contractAddress);
      } else if (contract?.transactionType === CONSTANTS.TRANSACTION_TYPES.RECEIVE) {
        getErc721TokenList();
      }
    },
  });

  useSubscription(ZKP_TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const {
        type,
        success,
        contractAddress: contractAddr,
        tokenId: token,
      } = data.subscriptionData.data.zkpTransactionStatus;
      if (success) {
        getErc721TokenList();
        handleNotification(t([`tokens.success.${type}`, 'tokens.transactionSuccess']), 'success');
      } else {
        handleNotification(t([`tokens.error.${type}`, 'tokens.transactionFailure']), 'error');
      }
      if (type === 'deposit') {
        set(pendingDepositsCount, `${contractAddr}.${token}`, false);
        setPendingDepositsCount({ ...pendingDepositsCount });
      } else if (type === 'withdraw') {
        set(pendingWithdrawsCount, `${contractAddr}.${token}`, false);
        setPendingWithdrawsCount({ ...pendingWithdrawsCount });
      } else if (type === 'single_transfer') {
        set(pendingZkpSingleTransferCount, `${contractAddr}.${token}`, false);
        setPendingZkpSingleTransferCount({ ...pendingZkpSingleTransferCount });
      }
    },
  });

  useEffect(() => {
    getErc721TokenList();
  }, [getErc721TokenList, company]);

  const [mintErc721TokenEth, { loading: isErc721MintLoading }] = useMutation(MINT_ERC721, {
    onCompleted: data => {
      incrementPendingTransactions(data.mintErc721.contractAddress);
      closeForm('mintErc721');
      handleNotification(t('tokens.transactionSubmitted'), 'success');
      getErc721TokenList();
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [mintErc721TokenBcos, { loading: isErc721BcosMintLoading }] = useMutation(
    MINT_ERC721_BCOS,
    {
      onCompleted: data => {
        closeForm('mintErc721');
        handleNotification(t('tokens.mintSuccess'), 'success');
        track('ERC721 BCOS Token Mint transaction Completed', {
          contract: data?.mintERC20TokenBcos?.contractAddress,
        });
        getErc721TokenList();
      },
      onError: error => {
        track('ERC721 BCOS Token Mint transaction Error');
        handleNotification(
          t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.mintError']),
          'error',
        );
      },
    },
  );

  const [mintErc721TokenBsn, { loading: isErc721BsnMintLoading }] = useMutation(MINT_ERC721_BSN, {
    onCompleted: data => {
      closeForm('mintErc721');
      handleNotification(t('tokens.mintSuccess'), 'success');
      track('ERC721 BSN Token Mint transaction Completed', {
        contract: data?.mintErc721TokenBsn?.contractAddress,
      });
      getErc721TokenList();
    },
    onError: error => {
      track('ERC721 BSN Token Mint transaction Error');
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.mintError']),
        'error',
      );
    },
  });

  const mintErc721Token = variables => {
    switch (activeWallet.blockchainType) {
      case 'ETH': {
        mintErc721TokenEth(variables);
        break;
      }
      case 'BCOS': {
        mintErc721TokenBcos(variables);
        break;
      }
      case 'BSN': {
        mintErc721TokenBsn(variables);
        break;
      }
      default:
    }
  };

  const [depositErc721] = useMutation(DEPOSIT_ERC721, {
    onCompleted: data => {
      const depositedToken = data.depositErc721;
      if (pendingDepositsCount[depositedToken.smartContract.contractAddress]) {
        pendingDepositsCount[depositedToken.smartContract.contractAddress][
          depositedToken.tokenId
        ] = true;
      } else {
        pendingDepositsCount[depositedToken.smartContract.contractAddress] = {
          [depositedToken.tokenId]: true,
        };
      }
      setPendingDepositsCount({
        ...pendingDepositsCount,
      });
      handleNotification(t('tokens.transactionSubmitted'), 'success');
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [withdrawErc721] = useMutation(WITHDRAW_ERC721, {
    onCompleted: data => {
      const withdrawnToken = data.withdrawErc721;
      if (pendingWithdrawsCount[withdrawnToken.smartContract.contractAddress]) {
        pendingWithdrawsCount[withdrawnToken.smartContract.contractAddress][
          withdrawnToken.tokenId
        ] = true;
      } else {
        pendingWithdrawsCount[withdrawnToken.smartContract.contractAddress] = {
          [withdrawnToken.tokenId]: true,
        };
      }
      setPendingWithdrawsCount({
        ...pendingWithdrawsCount,
      });
      handleNotification(t('tokens.transactionSubmitted'), 'success');
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [zkpTransferErc721TokenEth, { loading: isZkpErc721TransferLoading }] = useMutation(
    ZKP_TRANSFER_ERC721,
    {
      onCompleted: data => {
        // incrementPendingTransfers(data?.zkpSingleTransferErc721?.transactionHash);
        closeForm('transferErc721');
        const transferedToken = data.zkpSingleTransferErc721;
        if (pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress]) {
          pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress][
            transferedToken.tokenId
          ] = true;
        } else {
          pendingZkpSingleTransferCount[transferedToken.smartContract.contractAddress] = {
            [transferedToken.tokenId]: true,
          };
        }
        setPendingZkpSingleTransferCount({
          ...pendingZkpSingleTransferCount,
        });
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        track('ERC721 Token Transfer transaction Submitted', {
          contract: data?.zkpSingleTransferErc721?.smartContract?.contractAddress,
          transactionHash: data?.zkpSingleTransferErc721?.latestTransaction?.transactionHash,
        });
        // getErc721TokenList();
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        if (errorTransfer?.graphQLErrors[0]?.errorCode === 'insufficient_funds')
          setShowFaucetModal(true);
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [
    updateERC721TokenMetadataEth,
    { loading: isErc721TokenMetadataUpdateLoading },
  ] = useMutation(UPDATE_ERC721_TOKEN_METADATA, {
    onCompleted: data => {
      incrementPendingTransfers(data?.updateERC721TokenMetadata?.transactionHash);
      closeForm('metadataErc721');
      handleNotification(t('tokens.transactionSubmitted'), 'success');
      getErc721TokenList();
    },
    onError: error => {
      if (error?.graphQLErrors[0]?.errorCode === 'insufficient_funds') setShowFaucetModal(true);
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [
    updateERC721TokenMetadataBcos,
    { loading: isErc721BcosTokenMetadataUpdateLoading },
  ] = useMutation(UPDATE_ERC721_TOKEN_METADATA_BCOS, {
    onCompleted: data => {
      closeForm('metadataErc721');
      handleNotification(t('tokens.transactionSuccess'), 'success');
      track('ERC721 Token metadata has been updated', {
        contract: data?.updateERC721TokenMetadataBcos?.contractAddress,
        transactionHash: data?.updateERC721TokenMetadataBcos?.transactionHash,
      });
      getErc721TokenList();
    },
    onError: error => {
      track('ERC721 Token Metadata Update Error');
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const [
    updateERC721TokenMetadataBsn,
    { loading: isErc721BsnTokenMetadataUpdateLoading },
  ] = useMutation(UPDATE_ERC721_TOKEN_METADATA_BSN, {
    onCompleted: data => {
      closeForm('metadataErc721');
      handleNotification(t('tokens.transactionSuccess'), 'success');
      track('ERC721 Token metadata has been updated', {
        contract: data?.updateERC721TokenMetadataBsn?.contractAddress,
        transactionHash: data?.updateERC721TokenMetadataBsn?.transactionHash,
      });
      getErc721TokenList();
    },
    onError: error => {
      track('ERC721 Token Metadata Update Error');
      handleNotification(
        t([`tokens.errors.${error?.graphQLErrors[0]?.errorCode}`, 'tokens.transactionError']),
        'error',
      );
    },
  });

  const updateERC721TokenMetadata = variables => {
    switch (activeWallet.blockchainType) {
      case 'ETH': {
        updateERC721TokenMetadataEth(variables);
        break;
      }
      case 'BCOS': {
        updateERC721TokenMetadataBcos(variables);
        break;
      }
      case 'BSN': {
        updateERC721TokenMetadataBsn(variables);
        break;
      }
      default:
    }
  };

  const [transferErc721TokenEth, { loading: isErc721TransferLoading }] = useMutation(
    TRANSFER_ERC721,
    {
      onCompleted: data => {
        incrementPendingTransfers(data?.transferErc721?.transactionHash);
        closeForm('transferErc721');
        handleNotification(t('tokens.transactionSubmitted'), 'success');
        track('ERC721 Token Transfer transaction Submitted', {
          contract: data?.transferErc721?.contractAddress,
          transactionHash: data?.transferErc721?.transactionHash,
        });
        getErc721TokenList();
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        if (errorTransfer?.graphQLErrors[0]?.errorCode === 'insufficient_funds')
          setShowFaucetModal(true);
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [transferErc721TokenBcos, { loading: isErc721BcosTransferLoading }] = useMutation(
    TRANSFER_ERC721_BCOS,
    {
      onCompleted: data => {
        closeForm('transferErc721');
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC721 Token Transfer transaction completed', {
          contract: data?.transferErc721Bcos?.contractAddress,
          transactionHash: data?.transferErc721Bcos?.transactionHash,
        });
        getErc721TokenList();
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  const [transferErc721TokenBsn, { loading: isErc721BsnTransferLoading }] = useMutation(
    TRANSFER_ERC721_BSN,
    {
      onCompleted: data => {
        closeForm('transferErc721');
        handleNotification(t('tokens.transferSuccess'), 'success');
        track('ERC721 Token Transfer transaction completed', {
          contract: data?.transferErc721Bsn?.contractAddress,
          transactionHash: data?.transferErc721Bsn?.transactionHash,
        });
        getErc721TokenList();
      },
      onError: errorTransfer => {
        track('ERC721 Token Transfer Error');
        handleNotification(
          t([
            `tokens.errors.${errorTransfer?.graphQLErrors[0]?.errorCode}`,
            'tokens.transactionError',
          ]),
          'error',
        );
      },
    },
  );

  // checks if a token is deposited
  const isPrivate = token => {
    return !!token.commitment;
  };

  const transferErc721Token = variables => {
    switch (activeWallet.blockchainType) {
      case 'ETH': {
        if (zkpAccess && isPrivate(selectedToken)) zkpTransferErc721TokenEth(variables);
        else transferErc721TokenEth(variables);
        break;
      }
      case 'BCOS': {
        transferErc721TokenBcos(variables);
        break;
      }
      case 'BSN': {
        transferErc721TokenBsn(variables);
        break;
      }
      default:
    }
  };

  const columns = [
    {
      name: 'tokenId',
      label: t('tokens.serialNo'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'receivedBy',
      label: t('tokens.receivedBy'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'commitment',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="center"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'actions',
      label: t('common.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="center"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        setCellProps: () => ({ align: 'right' }),
      },
    },
  ];

  const buildReceivedBy = token => {
    if (
      hasPendingTransfers(token?.latestTransaction?.transactionHash) || // Transfer Token
      hasPendingTransfers(token?.latestMetadataTransaction?.transactionHash) || // Update Token Metadata
      token?.latestTransaction?.status === CONSTANTS.CONTRACT_STATUSES.PENDING
    ) {
      return (
        <>
          <CircularProgress color="primary" size={20} thickness={20} />
          <span className={classes.positionLoader}>{t('tokens.deploymentStatus')}</span>
        </>
      );
    }

    return token.latestReceivedBy;
  };

  const tokenZkpStatus = token => {
    const hasPendingDeposits =
      pendingDepositsCount[token.smartContract.contractAddress] &&
      pendingDepositsCount[token.smartContract.contractAddress][token.tokenId];

    const hasPendingWithdraws =
      pendingWithdrawsCount[token.smartContract.contractAddress] &&
      pendingWithdrawsCount[token.smartContract.contractAddress][token.tokenId];

    const hasPendingZkpSingleTransfers =
      pendingZkpSingleTransferCount[token.smartContract.contractAddress] &&
      pendingZkpSingleTransferCount[token.smartContract.contractAddress][token.tokenId];

    if (hasPendingDeposits) {
      return (
        <>
          <CircularProgress color="primary" size={20} thickness={20} />
          <span className={classes.positionLoader}>{t('tokens.loaderTextMakingPrivate')}</span>
        </>
      );
    }

    if (hasPendingWithdraws) {
      return (
        <>
          <CircularProgress color="primary" size={20} thickness={20} />
          <span className={classes.positionLoader}>{t('tokens.loaderTextMakingPublic')}</span>
        </>
      );
    }

    if (hasPendingZkpSingleTransfers) {
      return (
        <>
          <CircularProgress color="primary" size={20} thickness={20} />
          <span className={classes.positionLoader}>{t('tokens.loaderTextZkpTransfer')}</span>
        </>
      );
    }

    return token.commitment ? (
      <Chip
        className={classes.chipPrivate}
        icon={<VerifiedUser className={`${classes.icon} ${classes.privateIcon}`} />}
        variant="outlined"
        label={t('tokens.private')}
        color="primary"
      />
    ) : (
      <Chip
        className={classes.chipPublic}
        icon={<Public className={`${classes.icon} ${classes.publicIcon}`} />}
        variant="outlined"
        label={t('tokens.public')}
        color="primary"
      />
    );
  };

  const buildMenuItems = token => {
    return [
      {
        label: t('tokens.history'),
        clickAction: () => {
          window.open(
            `${window.config.vizualizerTokensUrl}${token?.tokenId}/history?sc=${token?.smartContract.contractAddress}`,
            '_blank',
          );
        },
      },
      {
        label: t('tokens.metadata'),
        clickAction: () => {
          setSelectedToken(token);
          setMetadataErc721FormOpen(true);
        },
      },
    ];
  };

  const buildActionButtons = token => {
    const buttonList = [];
    const hasPendingDeposits =
      pendingDepositsCount[token.smartContract.contractAddress] &&
      pendingDepositsCount[token.smartContract.contractAddress][token.tokenId];

    const hasPendingWithdraws =
      pendingWithdrawsCount[token.smartContract.contractAddress] &&
      pendingWithdrawsCount[token.smartContract.contractAddress][token.tokenId];

    const hasPendingZKPSingleTransfers =
      pendingZkpSingleTransferCount[token.smartContract.contractAddress] &&
      pendingZkpSingleTransferCount[token.smartContract.contractAddress][token.tokenId];

    if (zkpAccess) {
      if (!isPrivate(token)) {
        buttonList.push(
          <Button
            key={uniqid()}
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            disabled={hasPendingDeposits}
            onClick={() => {
              depositErc721({
                variables: {
                  tokenId: `${token.tokenId}`,
                  contractId: token.smartContract._id,
                },
              });
            }}
          >
            {t('tokens.deposit')}
          </Button>,
        );
      } else {
        buttonList.push(
          <Button
            key={uniqid()}
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            disabled={hasPendingWithdraws || hasPendingZKPSingleTransfers}
            onClick={() => {
              withdrawErc721({
                variables: {
                  tokenId: `${token.tokenId}`,
                  contractAddress: token.smartContract.contractAddress,
                },
              });
            }}
          >
            {t('tokens.withdraw')}
          </Button>,
        );
      }
    }

    buttonList.push(
      <Button
        key={uniqid()}
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        disabled={
          hasPendingTransfers(token?.latestTransaction?.transactionHash) || // Transfer Token
          hasPendingTransfers(token?.latestMetadataTransaction?.transactionHash) || // Update Token Metadata
          token?.latestTransaction?.status !== CONSTANTS.CONTRACT_STATUSES.COMPLETED ||
          hasPendingDeposits ||
          hasPendingWithdraws ||
          hasPendingZKPSingleTransfers
        }
        onClick={() => openForm(token)}
      >
        {zkpAccess && isPrivate(token) ? t('tokens.zkpTransfer') : t('tokens.transfer')}
      </Button>,
    );
    buttonList.push(
      <IsolatedMenu
        key={token?._id}
        disabled={
          hasPendingTransfers(token?.latestTransaction?.transactionHash) || // Transfer Token
          hasPendingTransfers(token?.latestMetadataTransaction?.transactionHash) || // Update Token Metadata
          token.latestTransaction?.status !== CONSTANTS.CONTRACT_STATUSES.COMPLETED
        }
        menuItems={buildMenuItems(token)}
      />,
    );

    return buttonList;
  };

  const tokenRow = token => {
    return {
      name: token.smartContract?.contractName,
      tokenId: token?.tokenId,
      receivedBy: buildReceivedBy(token),
      transferReason: token?.latestTransferReason,
      commitment: tokenZkpStatus(token),
      actions: buildActionButtons(token),
    };
  };

  if (tokenDataLoading) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      <Table
        title={
          <Typography className={classes.titleStyle} variant="h4">
            {t('headers.token_plural')}
          </Typography>
        }
        className={classes.table}
        columns={columns}
        data={[...(tokens?.map(token => tokenRow(token)) || [])]}
        options={{
          textLabels: {
            body: {
              noMatch: t('common.noMatchingRecords'),
            },
          },
          filter: false,
          search: false,
          searchOpen: true,
          elevation: 0,
          customSearchRender: (searchText, handleSearch, hideSearch, options) => (
            <TokenSearchBar
              searchText={searchText}
              className={classes.search}
              handleSearch={handleSearch}
              hideSearch={hideSearch}
              options={options}
              placeHolderText={t('tokens.searchTokens')}
              createButtonText={t('tokens.mintTokens')}
              buttonDisabled={
                !contractAddress ||
                (tokens.length > 0
                  ? tokens[0].smartContract?.companyEthereumAddress !==
                    company.companyEthereumAddress
                  : false)
              }
              handleOpenForm={() => {
                setMintErc721FormOpen(true);
              }}
            />
          ),
        }}
      />
      {!showFaucetModal && (
        <>
          <MintNonFungibleToken
            open={mintErc721FormOpen}
            closeModal={() => closeForm('mintErc721')}
            onSubmit={mintErc721Token}
            loading={isErc721MintLoading || isErc721BcosMintLoading || isErc721BsnMintLoading}
            contractAddress={contractAddress}
          />
          <TransferErc721Token
            open={transferErc721FormOpen}
            closeModal={() => closeForm('transferErc721')}
            onSubmit={transferErc721Token}
            contractAddress={contractAddress}
            currentUserEthAddress={company.companyEthereumAddress || ''}
            tokenId={selectedToken?.tokenId}
            loading={
              isErc721TransferLoading ||
              isErc721BcosTransferLoading ||
              isErc721BsnTransferLoading ||
              isZkpErc721TransferLoading
            }
            isPrivate={!!selectedToken.commitment}
          />
          <MetadataErc721Token
            open={metadataErc721FormOpen}
            closeModal={() => closeForm('metadataErc721')}
            onSubmit={updateERC721TokenMetadata}
            contractAddress={contractAddress}
            token={{
              tokenId: selectedToken?.tokenId,
              metadata: selectedToken?.metadata,
            }}
            loading={
              isErc721TokenMetadataUpdateLoading ||
              isErc721BcosTokenMetadataUpdateLoading ||
              isErc721BsnTokenMetadataUpdateLoading
            }
          />
        </>
      )}
    </>
  );
};
ListingERC721.propTypes = {};

ListingERC721.defaultProps = {};

export default ListingERC721;
