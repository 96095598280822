import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import PublicIcon from '@material-ui/icons/Public';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import LanguageIcon from '@material-ui/icons/Language';
import { GET_TRANSACTION } from '../../graphql/Shared/transaction';

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const { txHash } = useParams();
  const [isUTC, setIsUTC] = useState(true);

  const [getTransactionDetails, { loading: isLoading, data = {} }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'no-cache',
  });
  const { transactionBcos } = data;
  const transactionDetails = { ...transactionBcos };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  useEffect(() => {
    getTransactionDetails({ variables: { transactionHash: txHash } });
  }, [txHash, getTransactionDetails]);

  const smallScreen = useMediaQuery('(max-width:400px)');

  const useStyles = makeStyles(theme => ({
    posterWrapper: {
      margin: smallScreen ? '16px' : '28px',
    },
    poster: {
      border: '3px solid',
      color: theme.palette.secondary.dark,
      margin: smallScreen ? '-32px 0px 36px 0px' : '-32px 36px 36px',
    },
    innerPoster: {
      border: '1px solid',
      color: theme.palette.secondary.dark,
      margin: '16px',
      padding: '16px',
      textAlign: 'center',
      position: 'relative',
    },
    ribbon: {
      fontSize: '24px',
      width: '80%',
      position: 'relative',
      background: theme.palette.secondary.main,
      textAlign: 'center',
      padding: smallScreen ? '16px' : '24px',
      margin: '0 auto',
      zIndex: '101',
      '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: smallScreen ? '-16px' : '-24px',
        border: smallScreen ? '24px solid' : '36px solid',
        color: theme.palette.secondary.main,
        left: '-48px',
        borderRightWidth: '36px',
        borderLeftColor: 'transparent',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: smallScreen ? '-16px' : '-24px',
        border: smallScreen ? '24px solid' : '36px solid',
        color: theme.palette.secondary.main,
        right: '-48px',
        borderLeftWidth: '36px',
        borderRightColor: 'transparent',
      },
    },
    recordRow: {
      display: 'flex',
      margin: '24px 0',
      flexWrap: 'wrap',
      flex: '1 1 auto',
    },
    recordCol: {
      flexBasis: 0,
      flexGrow: 1,
      width: '100%',
    },
    header: {
      fontSize: smallScreen ? '16px' : '24px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    value: {
      fontSize: '16px',
      color: theme.palette.primary.main,
      wordWrap: 'break-word',
      wordBreak: 'normal',
    },
    buttonSuccess: {
      fontSize: '40px',
      color: theme.palette.success.main,
    },
    buttonSwitchTime: {
      fontSize: '16px',
      marginLeft: '8px',
    },
    buttonFail: {
      fontSize: '40px',
      color: theme.palette.warning.main,
    },
    lngButton: {
      justifyContent: 'flex-end',
      cursor: 'pointer',
      display: 'flex',
    },
  }));
  const classes = useStyles();

  const i18nButton = (
    <Typography
      className={classes.lngButton}
      onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en')}
    >
      <LanguageIcon />
      {i18n.language === 'en' ? 'EN' : 'ZH'}
    </Typography>
  );

  const certificateHeader = (
    <Box className={classes.ribbon}>
      <Typography className={classes.header}>
        {transactionDetails?.blockchainType} {t('landingPage.header')}
      </Typography>
    </Box>
  );

  const certificateContainer = (
    <Box className={classes.certificateContainer}>
      {certificateHeader}
      <Box className={classes.poster}>
        {!transactionDetails.blockHash ? (
          <Box className={classes.innerPoster}>
            <Box className={classes.recordRow}>
              <Box className={classes.recordCol}>
                <Typography className={classes.label}>{t('landingPage.error.invalid')}</Typography>
                <ErrorOutlineIcon className={classes.buttonFail} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.innerPoster}>
            <Box className={classes.recordRow}>
              <Box className={classes.recordCol}>
                <Typography className={classes.label}>{t('landingPage.txHash')}</Typography>
                <Typography className={classes.value}>{txHash}</Typography>
              </Box>
            </Box>

            <Box className={classes.recordRow}>
              <Box className={classes.recordCol}>
                <Typography className={classes.label}>
                  {t('landingPage.timestamp')}{' '}
                  <PublicIcon
                    className={classes.buttonSwitchTime}
                    onClick={() => {
                      setIsUTC(!isUTC);
                    }}
                  />
                </Typography>
                <Typography className={classes.value}>
                  {isUTC ? (
                    <span>
                      {moment(transactionDetails?.timestamp)
                        .utc()
                        .format('YYYY/MM/DD HH:mm')}{' '}
                      (UTC)
                    </span>
                  ) : (
                    <span>
                      {moment(transactionDetails?.timestamp).format('YYYY/MM/DD HH:mm')} ({timeZone}
                      )
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.recordRow}>
              <Box className={classes.recordCol}>
                <Typography className={classes.label}>{t('landingPage.blockNumber')}</Typography>
                <Typography className={classes.value}>{transactionDetails?.blockNumber}</Typography>
              </Box>
            </Box>

            <Box className={classes.recordRow}>
              <Box className={classes.recordCol}>
                <Typography className={classes.label}>{t('landingPage.blockHash')}</Typography>
                <Typography className={classes.value}>{transactionDetails?.blockHash}</Typography>
              </Box>
            </Box>

            <Box className={classes.recordCol}>
              <CheckCircleOutlineSharpIcon className={classes.buttonSuccess} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box className={classes.posterWrapper}>
      {i18nButton}
      {isLoading ? <MaskedLoader loading /> : certificateContainer}
    </Box>
  );
};

export default LandingPage;
