import Grid from '@material-ui/core/Grid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import React from 'react';
import useStyles from './styles';

export default () => {
  const classes = useStyles();
  return (
    <Grid className={classes.loaderContainer}>
      <Loader />
    </Grid>
  );
};
