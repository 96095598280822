import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { remove, filter } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  addButton: {
    marginTop: '10px',
  },
  removeButtonContainer: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  textField: {
    margin: '5px 0px 5px 0px',
  },
  recipientButton: {
    marginLeft: theme.spacing(3),
  },
}));

const MultiKeyArray = ({ limit, field: { name, value }, form: { errors, setFieldValue } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [recipientDisable, setRecipientDisable] = useState(false);

  const disableRecipientButton = () => {
    if (value) {
      const recipientRow = filter(value, { key: t('notarization.recipient') });
      if (recipientRow.length > 0) {
        setRecipientDisable(true);
      } else {
        setRecipientDisable(false);
      }
    }
  };
  useEffect(() => {
    disableRecipientButton();
  });
  return (
    <FieldArray name={name}>
      {({ push }) => (
        <>
          <Grid container spacing={2}>
            {value?.length > 0 &&
              value.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  <Grid item xs={5}>
                    <Field
                      size="small"
                      name={`${name}.${index}.key`}
                      data-testid={`${name}.${index}.key`}
                      label={t('notarization.name')}
                      component={TextField}
                      className={classes.textField}
                      error={errors && !!errors?.fieldArray?.[index]?.key}
                      helperText={errors && errors?.fieldArray?.[index]?.key}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Field
                      size="small"
                      name={`${name}.${index}.value`}
                      data-testid={`${name}.${index}.value`}
                      label={t('common.value')}
                      component={TextField}
                      className={classes.textField}
                      error={errors && !!errors?.fieldArray?.[index]?.value}
                      helperText={errors && errors?.fieldArray?.[index]?.value}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.removeButtonContainer}>
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={value.length < 2}
                      onClick={() => {
                        if (value.length >= 2) {
                          const tempArray = [...value];
                          remove(tempArray, (tempValue, tempIndex) => tempIndex === index);
                          setFieldValue(name, tempArray);
                        }
                        setCounter(counter - 1);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
          <div className={classes.addButton}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                push({ key: '', value: '' });
                setCounter(counter + 1);
              }}
              startIcon={<AddIcon />}
              disabled={counter === limit - 1}
              data-testid="notarization-certificates-addMoreData"
            >
              {t('notarization.addMoreData')}
            </Button>

            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.recipientButton}
              onClick={() => {
                push({ key: 'Recipient', value: '' });
                setCounter(counter + 1);
              }}
              disabled={recipientDisable}
            >
              {t('notarization.addRecipient')}
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

MultiKeyArray.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({
      fieldArray: PropTypes.array,
    }),
  }).isRequired,
  limit: PropTypes.number,
};

MultiKeyArray.defaultProps = {
  limit: Number.MAX_SAFE_INTEGER,
};

export default MultiKeyArray;
