import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageLoader from '../../Shared/PageLoader';
import RegisteredCertsTable from './RegisteredCertsTable';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';
import useUserInfo from '../../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  emptyDocumentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  startNowButton: {
    marginTop: '2rem',
  },
  emptyStartBanner: {
    marginTop: '2rem',
    fontWeight: 'initial',
  },
}));
const RegisteredCertificates = () => {
  const {
    setCertUploadVisible,
    getCertificatesForContract,
    isCertsForContractLoading,
    notarizedCerts,
    selectedNotarizationContract,
  } = useNotarizationContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    permissionsFlags: { isUserAuthToNotarizeCertificates },
  } = useUserInfo();

  const handleStart = () => {
    setCertUploadVisible(true);
  };

  useEffect(() => {
    if (selectedNotarizationContract) {
      getCertificatesForContract({ variables: { contractId: selectedNotarizationContract?._id } });
    }
  }, [getCertificatesForContract, selectedNotarizationContract]);

  if (isCertsForContractLoading) {
    return <PageLoader />;
  }

  if (!notarizedCerts || notarizedCerts?.length < 1) {
    return (
      <div
        className={classes.emptyDocumentContainer}
        data-testid="notarization-certificates-emptyStartBanner"
      >
        <Typography variant="h5" className={classes.emptyStartBanner}>
          {t('notarization.noCertificate')}
        </Typography>
        {isUserAuthToNotarizeCertificates ? (
          <Button
            className={classes.startNowButton}
            variant="contained"
            color="primary"
            onClick={handleStart}
            data-testid="notarization-certificates-startnow"
          >
            {t('notarization.startNow')}
          </Button>
        ) : (
          <Typography variant="h5" className={classes.emptyStartBanner}>
            {t('notarization.noNotarizeCertsAuthorization')}
          </Typography>
        )}
      </div>
    );
  }

  return <RegisteredCertsTable />;
};
export default RegisteredCertificates;
