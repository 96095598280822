import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@eyblockchain/ey-ui/core/Table';
import { makeStyles } from '@material-ui/styles';
import uniqid from 'uniqid';
import Loader from '@eyblockchain/ey-ui/core/Loader';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import capitalize from 'lodash/capitalize';
import TableHeadCell from '../../components/Shared/TableHeadCell';
// import CreateInvoice from '../../components/Procurement/CreateInvoice';
import { PROTabs, PROTab } from '../../components/Procurement/PROTabs';
import { useAuth } from '../../contexts/Shared/auth';
import { formatCurrency } from '../../utils';
import i18n from '../../i18n';
import { useInvoiceContext } from '../../contexts/Procurement/invoice';
import track from '../../mixpanel';
import TableActions from '../../components/Shared/TableActions';
import TableSearch from '../../components/Shared/TableSearch';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import OcmAccessDeniedLandingPage from './OcmAccesDeniedLandingPage';

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      boxShadow: '0 -1.75px 0 0 rgb(0 0 0 / 4%)',
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTabs-root': {
      marginBottom: '20px',
      borderBottom: '0px',
      boxShadow: '0px',
    },
    '& .MuiTab-root': {
      minWidth: '0px',
    },
  },
  positionElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  openChip: {
    background: '#A9A9A9',
  },
  pendingChip: { background: '#FF9831' },
  successChip: { background: 'rgb(22 134 54)', color: '#fff' },
  loaderContainer: theme.loaderContainer,
  invoiceListRoot: {
    background: theme.palette.primary.lightest,
    padding: '16px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  indicator: {
    background: theme.palette.primary.lightest,
    backgroundColor: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'EYInterstate, Roboto, sans-serif',
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  chipData: {
    color: '#2E2E38',
    borderColor: '#2E2E38',
    marginLeft: '3px',
    height: '18px',
  },
  chipLabel: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
}));

const Invoices = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { company } = useAuth();
  const { userPermissions } = useUserInfo();

  const history = useHistory();
  const { invoices, getInvoices, invoiceLoading } = useInvoiceContext();
  const { activeOrganization } = useBecOrganizationContext();
  const { becUrl, opschainAppId } = window.platformConfig;
  const redirectUrl = `${becUrl}/organizations/${activeOrganization._id}/applications/${opschainAppId}/details?mod=contract_manager`;
  const [activeTab, setActiveTab] = useState('All');
  const [filteredInvoices, setfilteredInvoices] = useState([]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices, company]);

  useEffect(() => {
    if (activeTab === 'Issued' && invoices.length > 0) {
      const formattedInvoice = invoices.filter(invoice => invoice.isSender === true);
      setfilteredInvoices(formattedInvoice);
    } else if (activeTab === 'Received' && invoices.length > 0) {
      const formattedInvoice = invoices.filter(invoice => invoice.isSender === false);
      setfilteredInvoices(formattedInvoice);
    } else if (activeTab === 'All' && invoices.length > 0) {
      setfilteredInvoices(invoices);
    }
  }, [invoices, activeTab]);
  const isUserNotAuthorized = () => {
    if (
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.INVOICES) &&
      !userPermissions?.contract_manager?.includes(PERMISSIONS.CONTRACT_MANAGER.CREATEINVOICE)
    ) {
      return <OcmAccessDeniedLandingPage redirectUrl={redirectUrl} />;
    }
    return false;
  };

  const businessPartner = invoice => {
    return activeOrganization._id === invoice.buyer.organization._id
      ? invoice.supplier.organization.name
      : invoice.buyer.organization.name;
  };

  const handleCreate = () => {
    history.push(`/invoice/create`);
  };

  const getFormattedMaterial = materials => {
    const materialArr = materials.map(material => material.materialName);
    const materialComma = materialArr.toString();

    return materialComma;
  };
  const formatRow = invoice => ({
    id: invoice._id,
    invoiceId: invoice.invoiceId || '',
    businessPartner: businessPartner(invoice),
    material: getFormattedMaterial(invoice.purchaseOrder.materials),
    quantity:
      invoice.purchaseOrder.materials.length > 1
        ? 'Multi'
        : invoice.purchaseOrder.materials[0].volume.toString(),
    netValue:
      invoice.purchaseOrder.materials.length > 1
        ? 'Multi'
        : `US ${formatCurrency(invoice.purchaseOrder.materials[0].totalPrice || 0)}`,
    dueDate: moment(+invoice.dueDate).format('D MMM YYYY'),
    status: capitalize(invoice.status),
  });

  const handleChangeTab = (_, newValue) => {
    setActiveTab(newValue);
  };

  const columns = [
    {
      name: 'invoiceId',
      label: i18n.t('invoices.invoiceID'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return `IN-${value.padStart(10, 0)}`;
        },
      },
    },
    {
      name: 'businessPartner',
      label: i18n.t('common.businessPartner'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'material',
      label: i18n.t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          const materialArr = value ? value.split(',') : [];
          const totalMaterial = materialArr.length;
          if (materialArr.length > 5) {
            materialArr.splice(6);
            materialArr.push(t('common.more'));
          }
          return (
            <div className={classes.associatedBox}>
              <Typography>{materialArr[0]}</Typography>
              {materialArr.length > 1 ? (
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={
                    <span>
                      {materialArr.map((material, key) => {
                        return key !== 0 ? <p key={uniqid()}>{` - ${material}`}</p> : '';
                      })}
                    </span>
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <Chip
                      label={materialArr.length > 0 ? `+${totalMaterial - 1}` : ''}
                      variant="outlined"
                      classes={{
                        root: classes.chipData,
                        label: classes.chipLabel,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'quantity',
      label: i18n.t('common.quantity'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netValue',
      label: i18n.t('invoices.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'dueDate',
      label: i18n.t('invoices.dueDate'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: i18n.t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          if (value === 'Posted') {
            return <Chip className={classes.pendingChip} label={t('common.posted')} />;
          }
          return <Chip className={classes.successChip} label={t('common.posted')} />;
        },
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="left"
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return (
            <TableActions
              options={[
                {
                  label: t('invoices.viewInvoice'),
                  buttonText: t('invoices.viewInvoice'),
                  testId: `invoice-${value}`,
                  handleClick: () => {
                    history.push(`/invoices/${value}`);
                  },
                },
              ]}
              testId={`invoice-${value}`}
            />
          );
        },
      },
    },
  ];

  return (
    <>
      {!isUserNotAuthorized() && invoiceLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {!isUserNotAuthorized() && (
            <>
              <div className={classes.invoiceListRoot}>
                <div className={classes.invoiceHeaderArea}>
                  <Typography variant="h3">{t('invoices.invoices')}</Typography>

                  {userPermissions?.contract_manager?.includes(
                    PERMISSIONS.CONTRACT_MANAGER.CREATEINVOICE,
                  ) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<PostAddIcon />}
                      onClick={handleCreate}
                      data-testid="createInvoice"
                    >
                      {t('invoices.issueInvoice')}
                    </Button>
                  )}
                </div>
              </div>
              <div className="invoices">
                <Table
                  title={t('invoices.invoices')}
                  className={classes.table}
                  columns={columns}
                  data={
                    userPermissions?.contract_manager?.includes(
                      PERMISSIONS.CONTRACT_MANAGER.INVOICES,
                    )
                      ? [...(filteredInvoices?.map(invoice => formatRow(invoice)) || [])]
                      : []
                  }
                  options={{
                    pagination: true,
                    filter: false,
                    search: false,
                    searchOpen: true,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                    onRowClick: row => {
                      track('View Purchase Order Detailed View', { status: row[6]?.props?.label });
                    },
                    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
                      <>
                        <PROTabs value={activeTab} onChange={handleChangeTab}>
                          <PROTab label={t('invoices.all')} value="All" />
                          <PROTab label={t('invoices.issued')} value="Issued" />
                          <PROTab label={t('invoices.received')} value="Received" />
                        </PROTabs>
                        <TableSearch
                          searchText={searchText}
                          handleSearch={handleSearch}
                          hideSearch={hideSearch}
                          options={options}
                          placeHolderText={t('invoices.searchInvoices')}
                          testId="createInvoice"
                        />
                      </>
                    ),
                  }}
                />
              </div>
            </>
          )}
          {isUserNotAuthorized()}
        </>
      )}
    </>
  );
};

export default Invoices;
