import gql from 'graphql-tag';

export const PARTNER_ATTRIBUTES = gql`
  fragment PARTNER_ATTRIBUTES on Partner {
    _id
    auth0Id
    partnerAuth0Id
    organizationId
    partnerOrganization {
      _id
      name
      address {
        street_line
        country
        city
        postal_code
        state
        phone
      }
      wallets {
        name
        network
        networkId
        blockchainType
        networkType
        address
      }
    }
    hasActiveContract
  }
`;

export const GET_PARTNER_UPDATE = gql`
  subscription onNewPartner {
    newPartner {
      auth0Id
      partnerAuth0Id
      organizationId
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation createPartner($input: partnerInput!) {
    createPartner(input: $input) {
      ...PARTNER_ATTRIBUTES
    }
  }
  ${PARTNER_ATTRIBUTES}
`;

export const GET_MY_PARTNERS = gql`
  query partners {
    partners {
      ...PARTNER_ATTRIBUTES
    }
  }
  ${PARTNER_ATTRIBUTES}
`;

export const GET_ALL_PARTNERS = gql`
  query getAllPartners {
    getAllPartners {
      networkLeaderPartners {
        _id
        organizationId
        partnerOrganization {
          _id
          name
        }
      }
      ownPartners {
        _id
        organizationId
        partnerOrganization {
          _id
          name
        }
      }
    }
  }
`;
