const eyinterstateRegular = {
  fontFamily: 'EYInterstate',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate'),
        local('EYInterstate-Regular'),
        url('../../public/fonts/EYInterstate/EYInterstate-Regular.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateBold = {
  fontFamily: 'EYInterstate',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-Bold'),
        url('../../public/fonts/EYInterstate/EYInterstate-Bold.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateBoldItalic = {
  fontFamily: 'EYInterstate',
  fontStyle: 'italic',
  fontWeight: 'bold',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-BoldItalic'),
        url('../../public/fonts/EYInterstate/EYInterstate-BoldItalic.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateItalic = {
  fontFamily: 'EYInterstate',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-Italic'),
        url('../../public/fonts/EYInterstate/EYInterstate-Italic.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateLight = {
  fontFamily: 'EYInterstate',
  fontStyle: 'normal',
  fontWeight: 300,
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-Light'),
        url('../../public/fonts/EYInterstate/EYInterstate-Light.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateLightBold = {
  fontFamily: 'EYInterstate',
  fontStyle: 'normal',
  fontWeight: 500,
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-LightBold'),
        url('../../public/fonts/EYInterstate/EYInterstate-LightBold.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateLightBoldItalic = {
  fontFamily: 'EYInterstate-LightBoldItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-LightBoldItalic'),
        url('../../public/fonts/EYInterstate/EYInterstate-LightBoldItalic.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const eyinterstateLightItalic = {
  fontFamily: 'EYInterstate-LightItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
        local('EYInterstate-LightItalic'),
        url('../../public/fonts/EYInterstate/EYInterstate-LightItalic.ttf') format('ttf')
      `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          eyinterstateRegular,
          eyinterstateBold,
          eyinterstateBoldItalic,
          eyinterstateItalic,
          eyinterstateLight,
          eyinterstateLightBold,
          eyinterstateLightBoldItalic,
          eyinterstateLightItalic,
        ],
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.95em',
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: '50px',
        left: 'calc(-70%)',
        right: 'calc(30%)',
      },
      line: {
        borderColor: '#EAEAEE',
      },
    },
    MuiStepLabel: {
      labelContainer: {
        width: 'auto',
      },
      label: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      completed: {
        color: '#168736 !important',
      },
      active: {
        fontWeight: '600 !important',
      },
    },
  },
  typography: {
    fontFamily: ['EYInterstate', 'Roboto', 'sans-serif'].join(','),
  },
  loaderContainer: {
    position: 'relative',
    top: '30vh',
  },
  colors: {
    red: 'rgb(185, 37, 28)',
    green: 'rgb(22 134 54)',
    grey: '#A9A9A9',
    white: '#fff',
    errorMessageRed: '#B9251C',
    hyperLinkBlue: '#155CB4',
    blue: '#155CB4',
  },
};
