import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import PropTypes from 'prop-types';

import EthereumLogo from '../EthereumLogo';
import ExpansionPanel from '../ExpansionPanel';
import i18n from '../../../i18n';
import PolygonLogo from '../../MetaMaskSetup/PolygonLogo';

const Typography = withStyles(theme => ({
  body2: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
}))(MuiTypography);

const useStyles = makeStyles(theme => ({
  img: {
    marginRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(5),
  },
  arrows: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  contentWrapper: {
    height: '300px',
    marginBottom: '50px',
  },
  logosBox: {
    height: '300px',
    display: 'flex',
    margin: '80px 50px 0 50px',
  },
  ethLogo: {
    width: '97.5px',
    height: '97.5px',
    marginTop: '20px',
  },
  polygonLogo: {
    width: '150px',
    height: '150px',
  },
  circle: {
    width: '150px',
    height: '150px',
    marginLeft: '20px',
    marginRight: '20px',
    textAlign: 'center',
    backgroundColor: '#EAEAF2',
    borderRadius: '50%',
  },
  activeButton: {
    '&:hover': { backgroundColor: theme.palette.primary.lightest },
    padding: '0 0 0 10px',
    marginLeft: '10px',
  },
  disabledButton: {
    '&:hover': { backgroundColor: theme.palette.common.white },
    padding: '0 10px 0 0',
    marginRight: '10px',
    color: theme.palette.primary.lighter,
  },
  lightNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.lighter,
  },
  darkNavCircle: {
    width: '11px',
    margin: '5px',
    color: theme.palette.primary.main,
  },
  inactiveArrow: {
    color: theme.palette.primary.lighter,
  },
}));

const WelcomeScreen = ({ nextOnClick }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Typography
          style={{ width: '600px', marginTop: '20px' }}
          variant="body1"
          align="center"
          gutterBottom
        >
          {i18n.t('OnboardingModal.ScreenOne.body1')}
        </Typography>
      </Box>
      <Typography align="center" variant="body2" gutterBottom>
        {i18n.t('OnboardingModal.ScreenOne.body2')}
      </Typography>
      <Box display="flex" justifyContent="flex-end" className={classes.contentWrapper}>
        <Box className={classes.logosBox}>
          <span className={classes.circle}>
            <PolygonLogo className={classes.polygonLogo} />
          </span>
          <span className={classes.circle}>
            <EthereumLogo className={classes.ethLogo} />
          </span>
        </Box>
        <img alt="" src="walkthrough.gif" width="475" height="260" className={classes.img} />
      </Box>
      <Box>
        <ExpansionPanel
          summary={i18n.t('OnboardingModal.ScreenOne.blockchainNetworkExpansion.summary1')}
        >
          <Typography variant="body1" gutterBottom>
            {i18n.t('OnboardingModal.ScreenOne.blockchainNetworkExpansion.body1')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {i18n.t('OnboardingModal.ScreenOne.blockchainNetworkExpansion.body2')}
          </Typography>
        </ExpansionPanel>
        <ExpansionPanel
          summary={i18n.t('OnboardingModal.ScreenOne.blockchainNetworkExpansion.summary2')}
        >
          <Typography variant="body1" gutterBottom>
            {i18n.t('OnboardingModal.ScreenOne.testnetExpansion.body1')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {i18n.t('OnboardingModal.ScreenOne.testnetExpansion.body2')}
          </Typography>
        </ExpansionPanel>
      </Box>
      <Box className={classes.arrows} display="flex" justifyContent="center">
        <Button className={classes.disabledButton} variant="outlined">
          <KeyboardArrowLeftIcon className={classes.inactiveArrow} fontSize="large" />
          {i18n.t('OnboardingModal.back')}
        </Button>

        <FiberManualRecordIcon className={classes.darkNavCircle} />
        <FiberManualRecordIcon className={classes.lightNavCircle} />
        <FiberManualRecordIcon className={classes.lightNavCircle} />

        <Button
          className={classes.activeButton}
          onClick={nextOnClick}
          color="secondary"
          variant="contained"
        >
          {i18n.t('OnboardingModal.next')}
          <KeyboardArrowRightIcon className={classes.activeArrow} fontSize="large" />
        </Button>
      </Box>
    </>
  );
};

WelcomeScreen.propTypes = {
  nextOnClick: PropTypes.func.isRequired,
};

export default WelcomeScreen;
