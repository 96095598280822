import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import FileDropZone from '@eyblockchain/ey-ui/core/FileDropZone';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormikSwitch from '../../Shared/FormikSwitch';
import { useNotarizationContext } from '../../../contexts/Notarization/notarizationContext';

const useStyles = makeStyles(theme => ({
  rawModeSwitch: {
    marginBottom: theme.spacing(2),
  },
  jsonArea: {
    display: 'grid',
  },
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      padding: '0px',
      margin: '0px',
    },
    '& .MuiAccordionSummary-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentArea: {
    width: '100%',
  },
  dropZone: {
    backgroundColor: '#E7E7EA',
  },
  autocompleteOption: {
    margin: 0,
    padding: 0,
  },
  autocomplete: {
    minWidth: '10%',
    width: '95%',
    marginRight: theme.spacing(2),
  },
  autoCompletePaper: {
    padding: 0,
  },
}));

const AddAttachment = ({ form: { values }, selectedDocuments, setSelectedDocuments }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadView } = values;
  const { notarizedDocs } = useNotarizationContext();
  const trueLabelDefault = t('notarization.attachUploadMode');
  const falseLabelDefault = t('notarization.attachExistingMode');

  const [viewSwitchOptions, setViewSwitchOptions] = useState({
    trueLabel: trueLabelDefault,
    falseLabel: falseLabelDefault,
  });

  const docsOptions = notarizedDocs?.map(doc => ({
    label: doc?.filename,
    value: {
      fileName: doc?.filename,
      hash: doc?.hash,
      documentId: doc?.documentId,
    },
    key: doc?.documentId,
    selected: false,
  }));

  useEffect(() => {
    if (selectedDocuments?.length === 0) {
      setViewSwitchOptions({
        trueLabel: trueLabelDefault,
        falseLabel: falseLabelDefault,
      });
    } else {
      setViewSwitchOptions({
        trueLabel: trueLabelDefault,
        falseLabel: `${falseLabelDefault}(${selectedDocuments?.length})`,
      });
    }
  }, [selectedDocuments]);

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{t('notarization.attachmentButton')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.contentArea}>
            <div className={classes.jsonArea}>
              <Field
                label="mode"
                name="uploadView"
                color="primary"
                className={classes.rawModeSwitch}
                component={FormikSwitch}
                labels={viewSwitchOptions}
              />
            </div>
            {uploadView ? (
              <div>
                <Field
                  component={FileDropZone}
                  name="documentUpload"
                  className={classes.dropZone}
                  acceptedFormats=".png,.jpg,.jpeg,.docx,.pdf,.xls"
                  secondaryText={t('notarization.fileUploadSecondaryText')}
                  maxSizeInMB={2}
                />
              </div>
            ) : (
              <Autocomplete
                multiple
                className={classes.autocomplete}
                options={docsOptions}
                disableCloseOnSelect
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) => option?.value?.hash === value?.value?.hash}
                onChange={(event, value) => setSelectedDocuments(value)}
                value={selectedDocuments}
                renderOption={(option, { selected }) => (
                  <div className={classes.autocompleteOption}>
                    <Checkbox
                      key={option.value}
                      icon={
                        <CheckBoxOutlineBlankIcon fontSize="small" className={classes.checkBox} />
                      }
                      checkedIcon={<CheckBoxIcon fontSize="small" className={classes.checkBox} />}
                      checked={selected}
                      className={classes.autocompleteCheckbox}
                    />
                    {option.label}
                  </div>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('notarization.labelExistingAttachments')}
                    variant="outlined"
                    size="small"
                    classes={{
                      root: classes.autocompleteField,
                    }}
                  />
                )}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

AddAttachment.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      uploadView: PropTypes.bool,
    }),
  }).isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};
export default AddAttachment;
