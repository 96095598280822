import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useSubscription, useMutation } from '@apollo/react-hooks';
import { findIndex, remove } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import {
  GET_NOTARIZATION_CONTRACTS,
  GET_NOTARIZATION_CONTRACT_DEPLOYED_STATUS,
  DEPLOY_NOTARIZATION_CONTRACT,
  DEPLOY_NOTARIZATION_CONTRACT_BCOS,
} from '../../graphql/Notarization/notarizationContract';
import { GET_DOCS_FOR_CONTRACT } from '../../graphql/Notarization/documents';
import { GET_CERTS_FOR_CONTRACT } from '../../graphql/Notarization/certificates';
import { useNotification } from '../Shared/notification';
import { CONSTANTS } from '../../constants';
import { TRANSACTION_STATUS } from '../../graphql/Shared/transaction';
import track from '../../mixpanel';

const NotarizationContext = React.createContext([{}, () => {}]);

const NotarizationProvider = ({ children }) => {
  const { t } = useTranslation();
  const [notarizationContracts, setNotarizationContracts] = useState([]);
  const { handleNotification } = useNotification();
  const [selectedNotarizationContract, setSelectedNotarizationContract] = useState({});
  const { activeWallet } = useBecOrganizationContext();

  /**
   * Documents
   */
  const [notarizedDocs, setNotarizedDocs] = useState(null);
  const [documentUploadVisible, setDocumentUploadVisible] = useState(false);

  const [getnotarizationContracts, { loading: getContractsLoading }] = useLazyQuery(
    GET_NOTARIZATION_CONTRACTS,
    {
      onCompleted: data => {
        setNotarizationContracts(data.notarizationContracts);
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [deployNotarizationContractEth, { loading: deployContractsEthLoading }] = useMutation(
    DEPLOY_NOTARIZATION_CONTRACT,
    {
      onCompleted: data => {
        track(CONSTANTS.MIXPANEL_EVENTS.NOTARIZATION.DEPLOY_CONTRACT, {
          blockchainNetwork: activeWallet?.network,
          contractId: data?.deployNotarizationContract?._id,
        });
      },
      onError: error => {
        track(CONSTANTS.MIXPANEL_ERRORS.NOTARIZATION.DEPLOY_CONTRACT, {
          blockchainNetwork: activeWallet?.network,
          error: error.message,
        });
      },
    },
  );

  const [deployNotarizationContractBcos, { loading: deployContractsBcosLoading }] = useMutation(
    DEPLOY_NOTARIZATION_CONTRACT_BCOS,
  );

  useSubscription(GET_NOTARIZATION_CONTRACT_DEPLOYED_STATUS, {
    onSubscriptionData: data => {
      const newState = notarizationContracts?.length > 0 ? [...notarizationContracts] : [];
      const contract = data.subscriptionData?.data?.notarizationContractDeployed;
      if (contract?.transaction?.status === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
        const index = findIndex(newState, {
          transaction: { transactionHash: contract?.transaction?.transactionHash || '' },
        });

        newState.splice(index, 1, contract);

        if (index === -1) {
          getnotarizationContracts();
        } else {
          setNotarizationContracts(newState);
        }
        handleNotification(t('notarization.deploySuccess'), 'success');
      } else if (
        data?.subscriptionData?.data?.smartContractDeployed?.transaction?.status ===
        CONSTANTS.CONTRACT_STATUSES.FAILED
      ) {
        handleNotification(t('notarization.deployError'), 'error');
        setNotarizationContracts(stateTokens =>
          stateTokens.filter(
            token =>
              token.contract.transaction?.transactionHash !== contract.transaction.transactionHash,
          ),
        );
      }
    },
  });

  const [getDocumentsForContract, { loading: isDocsForContractLoading }] = useLazyQuery(
    GET_DOCS_FOR_CONTRACT,
    {
      onCompleted: data => {
        setNotarizedDocs(data.documentsForContract);
      },
      onError: err => {
        handleNotification(
          t('notarization.validation.docRetrievalError', { errorMessage: err?.message }),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const removeDocFromList = documentId => {
    const newState = notarizedDocs?.length > 0 ? [...notarizedDocs] : [];
    remove(newState, { documentId });
    setNotarizedDocs(newState);
  };

  const addContractToList = async variables => {
    let newContract;
    switch (activeWallet.blockchainType) {
      case CONSTANTS.BLOCKCHAIN_TYPES.MATIC:
      case CONSTANTS.BLOCKCHAIN_TYPES.ETH: {
        const {
          data: { deployNotarizationContract: contractResponse },
        } = await deployNotarizationContractEth(variables);
        newContract = contractResponse;
        break;
      }
      case CONSTANTS.BLOCKCHAIN_TYPES.BCOS: {
        const {
          data: { deployNotarizationContractBcos: contractResponse },
        } = await deployNotarizationContractBcos(variables);
        newContract = contractResponse;
        break;
      }
      default:
    }
    setNotarizationContracts([...notarizationContracts, newContract]);
  };

  const updateDocumentList = updatedDoc => {
    if (updatedDoc) {
      const newState = notarizedDocs?.length > 0 ? [...notarizedDocs] : [];
      const index = findIndex(newState, { _id: updatedDoc?._id });
      newState.splice(index, 1, updatedDoc);
      setNotarizedDocs(newState);
    }
  };

  /**
   * Certificates
   */
  const [notarizedCerts, setNotarizedCerts] = useState(null);
  const [certUploadVisible, setCertUploadVisible] = useState(false);
  const [getCertificatesForContract, { loading: isCertsForContractLoading }] = useLazyQuery(
    GET_CERTS_FOR_CONTRACT,
    {
      onCompleted: data => {
        setNotarizedCerts(data.certificatesForContract);
      },
      onError: err => {
        handleNotification(
          t('notarization.validation.certRetrievalError', { errorMessage: err?.message }),
          'error',
        );
      },
      fetchPolicy: 'no-cache',
    },
  );

  const notarizationListLoading =
    getContractsLoading || deployContractsEthLoading || deployContractsBcosLoading;

  useSubscription(TRANSACTION_STATUS, {
    onSubscriptionData: data => {
      const txType = data?.subscriptionData?.data?.transactionStatus?.transactionType;
      const txResponse = data?.subscriptionData?.data?.transactionStatus;
      const txHash = txResponse?.transactionHash;
      const txStatus = txResponse?.status;

      if (txType === CONSTANTS.TRANSACTION_TYPES.NOTARIZE_DOCUMENT) {
        const newState = notarizedDocs?.length > 0 ? [...notarizedDocs] : [];

        const index = findIndex(newState, {
          transaction: { transactionHash: txHash || '' },
        });

        if (newState[index]) {
          newState[index].transaction.status = txStatus;
        }

        setNotarizedDocs(newState);

        if (txStatus === CONSTANTS.CONTRACT_STATUSES.COMPLETED) {
          handleNotification(
            t([`notarization.${txType}Success`, 'tokens.transactionSuccess']),
            'success',
          );
        } else if (txStatus === CONSTANTS.CONTRACT_STATUSES.FAILED) {
          handleNotification(
            t([`notarization.${txType}Error`, 'tokens.transactionFailure']),
            'error',
          );
        }
      }
      if (txType === CONSTANTS.TRANSACTION_TYPES.CREATE_CERTIFICATE) {
        const newState = notarizedCerts?.length > 0 ? [...notarizedCerts] : [];

        const index = findIndex(newState, {
          transaction: { transactionHash: txHash || '' },
        });

        if (newState[index]) {
          newState[index].transaction.status = txStatus;
        }
        setNotarizedCerts(newState);
      }
    },
  });

  const removeCertificateFromList = certificateId => {
    const newState = notarizedCerts?.length > 0 ? [...notarizedCerts] : [];
    remove(newState, { certificateId });
    setNotarizedCerts(newState);
  };

  const updateCertificateList = updatedCertificate => {
    if (updatedCertificate) {
      const newState = notarizedCerts?.length > 0 ? [...notarizedCerts] : [];
      const index = findIndex(newState, { _id: updatedCertificate?._id });
      newState.splice(index, 1, updatedCertificate);
      setNotarizedCerts(newState);
    }
  };

  useEffect(() => {
    const resetNotarizationContext = () => {
      setDocumentUploadVisible(false);
      if (notarizedDocs) {
        setNotarizedDocs(null);
      }
    };

    resetNotarizationContext();
  }, [selectedNotarizationContract]);

  return (
    <NotarizationContext.Provider
      value={{
        notarizationContracts,
        setNotarizationContracts,
        addContractToList,
        getnotarizationContracts,
        notarizationListLoading,
        setSelectedNotarizationContract,
        selectedNotarizationContract,
        // documents
        documentUploadVisible,
        setDocumentUploadVisible,
        getDocumentsForContract,
        isDocsForContractLoading,
        notarizedDocs,
        removeDocFromList,
        updateDocumentList,
        // certificates
        certUploadVisible,
        setCertUploadVisible,
        notarizedCerts,
        getCertificatesForContract,
        isCertsForContractLoading,
        removeCertificateFromList,
        updateCertificateList,
      }}
    >
      {children}
    </NotarizationContext.Provider>
  );
};

const useNotarizationContext = () => useContext(NotarizationContext);

NotarizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useNotarizationContext, NotarizationProvider };
