import gql from 'graphql-tag';

export const ITEM_CONTENT_ATTRIBUTES = gql`
  fragment ITEM_CONTENT_ATTRIBUTES on ItemContent {
    _id
    content
    amount
    organizationId
    parentItemContent {
      _id
      content
    }
    item {
      name
      _id
      itemType {
        name
      }
      tokenContract {
        _id
        contractAddress
        minters {
          _id
        }
        transaction {
          _id
          transactionHash
          status
          chainId
        }
      }
    }
    step {
      _id
      name
      collaborators {
        partner {
          _id
          partnerOrganization {
            _id
          }
        }
        role
      }
    }
    token {
      _id
      latestTransaction {
        status
        createdAt
      }
      metadataStructure {
        metadataConfig {
          metadataName
          metadataMutability
          metadataPermission
          metadataType {
            _id
            metadataTypeName
            metadataTypeOptions
          }
          chosenMetadataOption
          step {
            _id
            name
            involvedItem {
              _id
            }
          }
        }
      }
    }
    linkedToken {
      _id
      metadata
      tokenId
      metadataStructure {
        metadataConfig {
          metadataName
          metadataMutability
          metadataPermission
          metadataType {
            _id
            metadataTypeName
            metadataTypeOptions
          }
          chosenMetadataOption
        }
      }
    }
    latestTransaction {
      _id
      transactionHash
      status
      chainId
      createdAt
    }
    metadata
    isDraft
  }
`;

export const ITEM_CONTENT_BY_ITEM = gql`
  query itemContentsByItemId($input: String!) {
    itemContentsByItemId(item: $input) {
      ...ITEM_CONTENT_ATTRIBUTES
    }
  }
  ${ITEM_CONTENT_ATTRIBUTES}
`;

export const ITEM_CONTENT_FOR_INSTANCE = gql`
  query itemContentsForInstanceId($input: String!) {
    itemContentsForInstanceId(instance: $input) {
      ...ITEM_CONTENT_ATTRIBUTES
    }
  }
  ${ITEM_CONTENT_ATTRIBUTES}
`;

export const CREATE_ITEM_CONTENT = gql`
  mutation createItemContent($input: inputItemContent!) {
    createItemContent(input: $input) {
      ...ITEM_CONTENT_ATTRIBUTES
    }
  }
  ${ITEM_CONTENT_ATTRIBUTES}
`;

export const UPDATE_ITEM_CONTENT = gql`
  mutation updateItemContent($input: inputUpdateItemContent!) {
    updateItemContent(input: $input) {
      ...ITEM_CONTENT_ATTRIBUTES
    }
  }
  ${ITEM_CONTENT_ATTRIBUTES}
`;
