import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { capitalize, Tooltip, Typography } from '@material-ui/core';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import TableHeadCell from '../Shared/TableHeadCell';
import { usePurchaseOrderContext } from '../../contexts/Procurement/purchaseOrder';
import { formatCurrencySymbol } from '../../utils';
import { useSubscriptionContext } from '../../contexts/Shared/subscription';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';
import { useContractContext } from '../../contexts/Procurement/contract';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: '40px',
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const ContractAssociatedPurchaseOrders = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    purchaseOrders,
    // purchaseOrderLoading: loading,
    getPurchaseOrders,
  } = usePurchaseOrderContext();
  const { euroCurrencySymbol } = useSubscriptionContext();
  const { userPermissions } = useUserInfo();
  const { contract } = useContractContext();
  useEffect(() => {
    getPurchaseOrders();
  }, getPurchaseOrders);

  const columns = [
    {
      name: 'purchaseOrderId',
      label: t('pos.poId'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return `PO-${value.padStart(10, 0)}`;
        },
      },
    },
    {
      name: 'businessPartnerBuyer',
      label: t('common.businessPartnerBuyer'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'businessPartnerSupplier',
      label: t('common.businessPartnerSupplier'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'material',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          const materialArr = value ? value.split(',') : [];
          const totalMaterial = materialArr.length;
          if (materialArr.length > 5) {
            materialArr.splice(6);
            materialArr.push(t('common.more'));
          }
          return (
            <div className={classes.associatedBox}>
              <Typography>{materialArr[0]}</Typography>
              {materialArr.length > 1 ? (
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={
                    <span>
                      {materialArr.map((material, key) => {
                        return key !== 0 ? <p key={uniqid()}>{` - ${material}`}</p> : '';
                      })}
                    </span>
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <Chip
                      label={materialArr.length > 0 ? `+${totalMaterial - 1}` : ''}
                      variant="outlined"
                      classes={{
                        root: classes.chipData,
                        label: classes.chipLabel,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'netValue',
      label: t('pos.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'status',
      label: t('common.status'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          if (value === 'Action-required') {
            return <Chip className={classes.pendingChip} label={t('common.actionRequired')} />;
          }
          if (value === 'Deploying') {
            return <Chip className={classes.pendingChip} label={t('pos.comparingPricing')} />;
          }
          if (value === 'Open') {
            return <Chip className={classes.openChip} label={t('common.open')} />;
          }
          if (value === 'Accepted') {
            return <Chip className={classes.acceptedChip} label={t('common.accepted')} />;
          }
          if (value === 'Failed') {
            return <Chip className={classes.failedChip} label={t('common.failed')} />;
          }
          return <Chip className={classes.deliveredChip} label={t('common.delivered')} />;
        },
      },
    },
  ];

  const businessPartner = purchaseOrder => {
    return purchaseOrder.isSender === true
      ? purchaseOrder.supplier.organization.name
      : purchaseOrder.buyer.organization.name;
  };

  const businessPartnerBuyer = purchaseOrder => {
    return purchaseOrder.buyer.organization.name;
  };

  const businessPartnerSupplier = purchaseOrder => {
    return purchaseOrder.supplier.organization.name;
  };

  const getFormattedMaterial = materials => {
    const materialArr = materials.map(material => material.materialName);
    const materialComma = materialArr.toString();

    return materialComma;
  };

  const formatRow = purchaseOrder => {
    return {
      id: purchaseOrder._id,
      contractDetails: {
        contractId: purchaseOrder.contract.contractId,
        _id: purchaseOrder.contract._id,
        poId: purchaseOrder._id,
      },
      purchaseOrderId: purchaseOrder.purchaseOrderId || '',
      businessPartner: businessPartner(purchaseOrder),
      businessPartnerBuyer: businessPartnerBuyer(purchaseOrder),
      businessPartnerSupplier: businessPartnerSupplier(purchaseOrder),
      material: getFormattedMaterial(purchaseOrder.materials),
      poQuantity:
        purchaseOrder.materials.length > 1 ? 'Multi' : purchaseOrder.materials[0].volume.toString(),
      netValue: `${formatCurrencySymbol(purchaseOrder.totalPrice, euroCurrencySymbol)}`,
      status: capitalize(purchaseOrder.status),
    };
  };

  return (
    <div data-testid="associatedPurchaseOrders">
      <Table
        title={t('common.associatedPurchaseOrders')}
        className={classes.table}
        columns={columns}
        data={
          userPermissions?.contract_manager?.includes(
            PERMISSIONS.CONTRACT_MANAGER.GETPURCHASEORDERS,
          )
            ? [
                ...(purchaseOrders
                  .filter(purchaseOrder => {
                    const purchaseOrderContractAddress =
                      purchaseOrder.contract.smartContract.contractAddress;
                    const contractDetailsPageContractAddress =
                      contract?.smartContract?.contractAddress;
                    return purchaseOrderContractAddress === contractDetailsPageContractAddress;
                  })
                  ?.map(purchaseOrder => {
                    return formatRow(purchaseOrder);
                  }) || []),
              ]
            : []
        }
        options={{
          pagination: false,
          filter: false,
          search: false,
          searchOpen: false,
          elevation: 0,
          textLabels: {
            body: {
              noMatch: t('common.noMatchingRecords'),
            },
            pagination: {
              rowsPerPage: t('common.rowsPerPage'),
            },
          },
        }}
      />
    </div>
  );
};

export default ContractAssociatedPurchaseOrders;
