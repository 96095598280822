import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Chip from '@eyblockchain/ey-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import TableHeadCell from '../Shared/TableHeadCell';
import PurchaseOrderProgressBar from './PurchaseOrderProgressBar';
import TableActions from '../Shared/TableActions';
import useUserInfo from '../../hooks/useUserInfo';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: 40,
    boxShadow: '0px 0px 0px 0px',
    marginTop: theme.spacing(3),
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      borderLeft: '0.5rem solid rgba(247, 218, 11, 11)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tiertable: {
    width: '35%',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.lightest,
      width: '10%',
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tierComponentSubTable: {
    marginTop: 0,
    width: '50%',
  },
  assoicatedButton: {
    width: 120,
    fontSize: '10px !important',
  },
  assoicatedButtonFont: {
    fontSize: '10px !important',
  },
  navButton: {
    color: theme.palette.info.main,
    fontWeight: 300,
  },
  acceptedChip: {
    background: 'white',
    color: theme.palette.info.main,
    fontWeight: 300,
    fontSize: '12px',
    marginLeft: 10,
    display: 'inline-block',
    padding: '0.3em 1.2em',
    margin: '0 0.1em 0.1em 0',
    borderRadius: '2em',
    textTransform: 'none',
  },
  icon: {
    height: '20px',
    width: '20px',
    color: 'inherit',
  },
  partiallyChip: {
    color: '#155CB4',
    borderColor: '#155CB4',
    marginRight: '0.5rem',
    padding: '.8rem 0.3rem',
  },
  associatedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const PurchaseOrderDetailsTable = ({ materials, getAssociatedId, setAssociateGoodsView }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userPermissions } = useUserInfo();
  const viewTokensPermission = userPermissions?.contract_manager?.includes(
    PERMISSIONS.CONTRACT_MANAGER.ERCTOKENCONTRACTS,
  );
  const assignTokensPermission = userPermissions?.contract_manager?.includes(
    PERMISSIONS.CONTRACT_MANAGER.ASSOCIATEERC721TOKENPURCHASEORDER,
  );
  const disassociateTokensPermission = userPermissions?.contract_manager?.includes(
    PERMISSIONS.CONTRACT_MANAGER.DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER,
  );

  const getAssociateTokenText = isSender => {
    if (
      !isSender &&
      !assignTokensPermission &&
      !disassociateTokensPermission &&
      viewTokensPermission
    ) {
      return t('tokenizedGoods.viewAssociateGoods');
    }
    return t('tokenizedGoods.associateGoods');
  };

  const columns = [
    {
      name: 'materialName',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'description',
      label: t('pos.description'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'volume',
      label: t('pos.quantity'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'shipment',
      label: t('pos.shipment'),
      options: {
        display: false,
      },
    },
    {
      name: 'isRebateContract',
      label: t('pos.shipment'),
      options: {
        display: false,
      },
    },
    {
      name: 'deliveryDate',
      label: t('pos.deliveryDate'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netPrice',
      label: t('pos.netPrice'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netValue',
      label: t('pos.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'associatedTokens',
      label: t('pos.associatedTokens'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          if (value[0]?.length === materials[value[1]]?.volume) {
            return (
              <div className={classes.associatedBox}>
                <Chip
                  label={t('tokenizedGoods.fullyAssociated')}
                  variant="outlined"
                  icon={<CheckIcon className={classes.icon} />}
                  classes={{
                    root: classes.fullyChip,
                  }}
                />
              </div>
            );
          }
          if (value[0]?.length > 0) {
            return (
              <div className={classes.associatedBox}>
                <Chip
                  label={t('tokenizedGoods.partiallyAssociated')}
                  variant="outlined"
                  icon={<ErrorIcon variant="outlined" className={classes.icon} />}
                  classes={{
                    root: classes.partiallyChip,
                  }}
                />
                <Typography>{`${value[0].length}/${materials[value[1]].volume}`}</Typography>
              </div>
            );
          }
          return t('tokenizedGoods.noGoodsAssociated');
        },
      },
    },
    {
      name: 'index',
      label: t('common.actions'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            align="left"
            key={uniqid()}
            columnMeta={columnMeta}
            className={classes.assoicatedButton}
            handleToggleColumn={handleToggleColumn}
          />
        ),
        customBodyRender: value => {
          return (
            ((userPermissions?.contract_manager?.includes(
              PERMISSIONS.CONTRACT_MANAGER.ERCTOKENCONTRACTS,
            ) &&
              materials[value]?.isSender) ||
              ((userPermissions?.contract_manager?.includes(
                PERMISSIONS.CONTRACT_MANAGER.ASSOCIATEERC721TOKENPURCHASEORDER,
              ) ||
                userPermissions?.contract_manager?.includes(
                  PERMISSIONS.CONTRACT_MANAGER.DISSOCIATEERC721SMARTCONTRACTPURCHASEORDER,
                ) ||
                userPermissions?.contract_manager?.includes(
                  PERMISSIONS.CONTRACT_MANAGER.ERCTOKENCONTRACTS,
                )) &&
                !materials[value]?.isSender)) && (
              <TableActions
                className={classes.assoicatedButtonFont}
                options={[
                  {
                    label: getAssociateTokenText(materials[value]?.isSender),
                    buttonText: getAssociateTokenText(materials[value]?.isSender),
                    testId: getAssociateTokenText(materials[value]?.isSender),
                    handleClick: () => {
                      getAssociatedId(value);
                      setAssociateGoodsView(true);
                    },
                  },
                ]}
                testId={getAssociateTokenText(materials[value]?.isSender)}
              />
            )
          );
        },
      },
    },
    {
      name: 'tierValue',
      label: t('pos.tierValue'),
      options: {
        display: false,
      },
    },
    {
      name: 'tireProgressBar',
      label: t('pos.tierProgressBar'),
      options: {
        display: false,
      },
    },
  ];

  const tierColumns = [
    {
      name: 'quantity',
      label: t('common.quantity'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netPrice',
      label: t('common.netPrice'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'qualityInTier',
      label: t('pos.qualityInTier'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const rebateColumns = [
    {
      name: 'rebateThreshold',
      label: t('contracts.rebateThreshold'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'rebatePercentage',
      label: t('contracts.rebatePercentage'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  const Row = props => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            {row[10].length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            {row[0]}
          </TableCell>
          <TableCell>{row[1]}</TableCell>
          <TableCell>{row[2]}</TableCell>
          <TableCell>{row[5]}</TableCell>
          <TableCell>{row[6]}</TableCell>
          <TableCell>{row[7]}</TableCell>
          <TableCell>{row[8]}</TableCell>
          <TableCell>{row[9]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  title={!row[4] ? t('contracts.tierPrices') : t('contracts.rebateThresholds')}
                  className={classes.tiertable}
                  columns={!row[4] ? tierColumns : rebateColumns}
                  data={row[10]}
                  options={{
                    pagination: false,
                    filter: false,
                    search: false,
                    searchOpen: false,
                    elevation: 0,
                    textLabels: {
                      body: {
                        noMatch: t('common.noMatchingRecords'),
                      },
                      pagination: {
                        rowsPerPage: t('common.rowsPerPage'),
                      },
                    },
                  }}
                />
              </Box>
              {!row[4] ? (
                <PurchaseOrderProgressBar
                  key={uniqid()}
                  tierBounds={row[11][0]}
                  accumulatedVolume={0}
                  purchaseOrderVolume={row[11][1]}
                  pricesByTier={row[11][2]}
                  className={classes.tierComponentSubTable}
                />
              ) : (
                ''
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  Row.propTypes = {
    row: PropTypes.oneOfType([PropTypes.array]),
  };

  Row.defaultProps = {
    row: [],
  };

  return (
    <Table
      title={t('common.materialRequested')}
      className={classes.table}
      columns={columns}
      data={materials}
      options={{
        filter: false,
        search: false,
        searchOpen: false,
        elevation: 0,
        customRowRender: data => data && data.length > 0 && <Row key={uniqid()} row={data} />,
        textLabels: {
          body: {
            noMatch: t('common.noMatchingRecords'),
          },
        },
      }}
    />
  );
};

PurchaseOrderDetailsTable.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      isSender: PropTypes.bool.isRequired,
    }),
  ),
  getAssociatedId: PropTypes.func,
  setAssociateGoodsView: PropTypes.func.isRequired,
};

PurchaseOrderDetailsTable.defaultProps = {
  materials: [],
  getAssociatedId: '',
};

export default PurchaseOrderDetailsTable;
