import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: '0.8rem',
  },
  tooltipArrow: {
    color: theme.palette.primary.dark,
  },
}));

const WizardTooltip = ({ text, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      arrow
      placement="top"
      title={text}
    >
      {children}
    </Tooltip>
  );
};

WizardTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default WizardTooltip;
