import gql from 'graphql-tag';

export const COMPANY_ATTRIBUTES = gql`
  fragment COMPANY_ATTRIBUTES on Company {
    _id
    street_line
    postal_code
    country
    state
    city
    phone
    name
    address {
      street_line
      country
      city
      postal_code
      state
      phone
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompanyByAuth0Id {
    getCompanyByAuth0Id {
      ...COMPANY_ATTRIBUTES
    }
  }
  ${COMPANY_ATTRIBUTES}
`;

export const GET_COMPANY_BY_COMPANY_CODE = gql`
  query getCompanyByCompanyCode($companyCode: String!, $companyEthereumAddress: String!) {
    getCompanyByCompanyCode(
      companyCode: $companyCode
      companyEthereumAddress: $companyEthereumAddress
    ) {
      ...COMPANY_ATTRIBUTES
    }
  }
  ${COMPANY_ATTRIBUTES}
`;

export const GET_COMPANY_BY_COMPANY_ETHEREUM_ADDRESS = gql`
  query getCompanyByEthereumAddress($companyEthereumAddress: String!) {
    getCompanyByEthereumAddress(companyEthereumAddress: $companyEthereumAddress) {
      ...COMPANY_ATTRIBUTES
    }
  }
  ${COMPANY_ATTRIBUTES}
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $companyCode: String!
    $streetNumber: String
    $postalCode: String
    $country: String
    $region: String
    $city: String
    $telephoneNumber: String
  ) {
    createCompany(
      companyCode: $companyCode
      streetNumber: $streetNumber
      postalCode: $postalCode
      country: $country
      region: $region
      city: $city
      telephoneNumber: $telephoneNumber
    ) {
      ...COMPANY_ATTRIBUTES
    }
  }
  ${COMPANY_ATTRIBUTES}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $companyCode: String!
    $streetNumber: String
    $postalCode: String
    $country: String
    $region: String
    $city: String
    $telephoneNumber: String
  ) {
    updateCompany(
      companyCode: $companyCode
      streetNumber: $streetNumber
      postalCode: $postalCode
      country: $country
      region: $region
      city: $city
      telephoneNumber: $telephoneNumber
    ) {
      ...COMPANY_ATTRIBUTES
    }
  }
  ${COMPANY_ATTRIBUTES}
`;

export const GET_COMPANIES = gql`
  query companies {
    companies {
      companyCode
      companyEthereumAddress
    }
  }
`;
