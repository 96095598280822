import Cookies from 'js-cookie';
import axios from 'axios';
import { CONTENT_TYPES, HTTP_METHODS, MIME_TYPES } from '../constants';

const { GET } = HTTP_METHODS;

const getCodeMessage = (errorCode, t) => {
  if (t) {
    return t(`httpResponseText.${errorCode}`);
  }

  return errorCode;
};

const request = (url, options, handlers) => {
  const handleNotification = handlers?.handleNotification;
  const errorMessage = handlers?.errorMessage;
  const t = handlers?.t;

  try {
    const accessToken = Cookies.get(`${window.config.cookiePrefix}access_token`);
    const newHeaders = { ...options?.headers, Authorization: `Bearer ${accessToken}` };
    let newBody;

    if (options.method !== GET) {
      newBody = JSON.stringify(options.body);
      newHeaders.Accept = MIME_TYPES.JSON;

      const isFormData = newBody instanceof FormData;

      if (!isFormData) {
        newHeaders['Content-type'] = CONTENT_TYPES.JSON;
      }
    }

    /**
     * TODO: This should ideally async/await, but the UI currently does not display a loader
     * if we await the calls to BEC.
     */
    const reqPromise = axios({
      method: options.method,
      url,
      data: newBody,
      headers: newHeaders,
    });

    return reqPromise.then(response => {
      const { status, statusText } = response;
      if (status < 200 && status >= 300) {
        const errorObj = {
          name: status,
          text: getCodeMessage(status, t) || statusText,
        };

        // eslint-disable-next-line no-console
        console.error(`Request to ${url} failed: `, status);

        handleNotification(errorMessage || getCodeMessage(status, t), 'error');

        throw errorObj;
      }

      return response?.data;
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Request to ${url} failed: `, error);

    handleNotification(errorMessage || getCodeMessage(error.message, t), 'error');
    throw error;
  }
};

export default request;
