import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  searchButton: {
    height: '2.5rem',
    alignItems: 'center',
    borderRadius: 0,
    padding: 0,
    width: '2.5rem',
  },
  searchInput: {
    height: '2.5rem',
    border: '1px solid #2E2E38',
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingRight: 0,
    width: '70%',
  },
  searchBar: {
    maxHeight: '2.5rem',
  },
  button: {
    height: '2.5rem',
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(1),
    minWidth: '11.125rem',
  },
}));

const TableSearch = ({ handleSearch, placeHolderText }) => {
  const classes = useStyles();

  return (
    <div className={classes.searchBar}>
      <Input
        className={classes.searchInput}
        disableUnderline
        placeholder={placeHolderText}
        onChange={e => handleSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton className={classes.searchButton} variant="contained">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};

TableSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  placeHolderText: PropTypes.string,
};

TableSearch.defaultProps = {
  placeHolderText: 'Search',
};

export default TableSearch;
