import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import { generateDefaultMetadataValues } from '../../../tokenMetadataUtils';
import { getBlockchainExplorerUrl } from '../../../utils';
import TokenMetaData from '../../Shared/TokenMetaData';

const useStyles = makeStyles(theme => ({
  paper: {
    top: '56px',
    position: 'absolute',
    width: '1000px',
    maxHeight: 'calc(100vh - 52px)',
    overflow: 'auto',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    background: '#d4d4ce',
    width: '100%',
    margin: '0 auto',
    marginTop: '32px',
    marginBottom: '25px',
  },
  headerRoot: {
    display: 'grid',
    gridTemplateColumns: '48% 2% 48%',
    gridGap: '1%',
  },
  itemPanel: {
    minWidth: '30%',
  },
  dependentItemPanel: {
    marginLeft: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontWeight: '400',
  },
  subPanelTitle: {
    marginBottom: theme.spacing(2),
  },
  dividerLine: {
    backgroundColor: theme.palette.primary.lighter,
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    height: '1rem',
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fill: theme.colors.hyperLinkBlue,
    color: theme.colors.hyperLinkBlue,
  },
  transactionLink: {
    color: theme.colors.hyperLinkBlue,
    textDecoration: 'underline',
  },
  subHeaderValue: {
    fontWeight: '400',
  },
  itemIdPanel: {
    fontWeight: '500',
  },
  itemIdPanelWithAmount: {
    fontWeight: '500',
    alignSelf: 'center',
  },
  parentTokenTable: {
    marginTop: theme.spacing(1),
    maxHeight: '140px',
    overflowY: 'overlay',
  },
  itemNameInput: {
    minHeight: '0px',
    minWidth: '20%',
  },
  parentTokenAmount: {
    display: 'grid',
    gridTemplateColumns: '55% 45%',
    gridGap: '1%',
  },
  itemPanelWithAmount: {
    display: 'grid',
    gridTemplateColumns: '55% auto',
    gridGap: '1%',
  },
  amount: {
    color: theme.palette.primary.dark,
  },
}));

const ItemDetails = ({
  open,
  closeModal,
  metadataConfigs,
  stepName,
  itemName,
  dependentItemName,
  dependentItemIds,
  metadata,
  tokenId,
  transactionHash,
  amount,
  parentTokenAmount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeWallet } = useBecOrganizationContext();
  const baseUrl = getBlockchainExplorerUrl(activeWallet.network);

  const getFieldTitle = (subHeaderTitle, subHeaderName) => {
    return (
      <div className={classes.subHeaderBox}>
        <Typography variant="h5">{subHeaderTitle}</Typography>
        <Typography variant="h5" className={classes.subHeaderValue}>
          {subHeaderName}
        </Typography>
      </div>
    );
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateDefaultMetadataValues(metadataConfigs, metadata),
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {},
  });

  const isDefaultMetadataLoading = metadataConfigs?.some(
    ({ metadataName }) => formik?.values?.[metadataName] === undefined,
  );

  const generateDependentItemsAmount = () =>
    parentTokenAmount.map(parentToken => (
      <div className={classes.parentTokenAmount} key={`parentTokenAmount-${parentToken.tokenId}`}>
        <Typography variant="h4" className={classes.itemIdPanelWithAmount}>
          {parentToken.tokenId}
        </Typography>
        <Typography variant="h5" className={classes.itemIdPanelWithAmount}>
          {t('traceability.dgp.amount')}
          {': '}
          {parentToken.amount}
        </Typography>
      </div>
    ));

  return (
    <FormikProvider value={formik}>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={open}
        onClose={closeModal}
      >
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            {t('traceability.dgp.registeredDataForStep')}
            {stepName}
          </Typography>
          <div className={classes.headerRoot}>
            <div className={classes.itemPanel} data-testid="item-details-popup-head">
              {getFieldTitle(t('traceability.dgp.headerTitle.item'), itemName)}
              {amount ? (
                <div className={classes.itemPanelWithAmount}>
                  <Typography variant="h4" className={classes.itemIdPanelWithAmount}>
                    {tokenId}
                  </Typography>
                  <Typography variant="h5" className={classes.itemIdPanelWithAmount}>
                    {t('traceability.dgp.amount')}
                    {': '}
                    {amount}
                  </Typography>
                </div>
              ) : (
                <Typography variant="h4" className={classes.itemIdPanel}>
                  {tokenId}
                </Typography>
              )}
            </div>
            {!!dependentItemIds && dependentItemIds.length !== 0 && (
              <>
                <Divider orientation="vertical" flexItem classes={{ root: classes.dividerLine }} />
                <div className={classes.dependentItemPanel}>
                  {dependentItemName &&
                    getFieldTitle(
                      t('traceability.dgp.headerTitle.dependentItem'),
                      dependentItemName,
                    )}
                  {parentTokenAmount.length > 0 ? (
                    <Box className={classes.parentTokenTable}>{generateDependentItemsAmount()}</Box>
                  ) : (
                    <Typography variant="h4" className={classes.itemIdPanel}>
                      {dependentItemIds.join(';')}
                    </Typography>
                  )}
                </div>
              </>
            )}
          </div>
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          <Divider className={classes.divider} />
          {metadataConfigs && !isDefaultMetadataLoading && (
            <>
              <div>
                <Typography variant="h6" className={classes.subPanelTitle}>
                  {t('tokens.metadataLabel')}
                </Typography>
              </div>
              <TokenMetaData metadataConfigs={metadataConfigs} readOnly />
              <Divider className={classes.divider} />
            </>
          )}
          <div className={classes.buttonArea}>
            <Button variant="contained" color="secondary" onClick={closeModal}>
              {t('common.close')}
            </Button>
            {transactionHash && (
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${baseUrl}/${transactionHash}`}
                  className={classes.transactionLink}
                >
                  {t('common.transactionLink')}
                </a>
                <LaunchIcon className={classes.icon} />
              </div>
            )}
          </div>
        </Paper>
      </Modal>
    </FormikProvider>
  );
};

ItemDetails.propTypes = {
  metadataConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  metadata: PropTypes.shape({}),
  open: PropTypes.bool.isRequired,
  tokenId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  stepName: PropTypes.string,
  itemName: PropTypes.string,
  transactionHash: PropTypes.string,
  dependentItemName: PropTypes.string,
  dependentItemIds: PropTypes.arrayOf(PropTypes.string),
  amount: PropTypes.number,
  parentTokenAmount: PropTypes.arrayOf(
    PropTypes.shape({
      tokenId: PropTypes.number,
      amount: PropTypes.number,
    }),
  ),
};

ItemDetails.defaultProps = {
  dependentItemName: '',
  dependentItemIds: [],
  transactionHash: '',
  metadataConfigs: [],
  metadata: {},
  tokenId: '',
  stepName: '',
  itemName: '',
  parentTokenAmount: [],
  amount: undefined,
};
export default ItemDetails;
