import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const IngestionContext = React.createContext([{}, () => {}]);

const IngestionContextProvider = ({ children }) => {
  const [draftValue, setDraftValue] = useState(null);

  const resetDraftValue = () => setDraftValue(null);

  const contextValues = {
    draft: {
      draftValue,
      setDraftValue,
      resetDraftValue,
    },
  };

  return <IngestionContext.Provider value={contextValues}>{children}</IngestionContext.Provider>;
};

const useIngestionContext = () => useContext(IngestionContext);

IngestionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useIngestionContext, IngestionContextProvider };
