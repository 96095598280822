import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@eyblockchain/ey-ui/core/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import uniqid from 'uniqid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import TableHeadCell from '../Shared/TableHeadCell';

const useStyles = makeStyles(theme => ({
  poPrincingTable: {
    marginBottom: '40px',
    background: theme.palette.primary.lightest,
    '& .MuiTableCell-head': {
      paddingTop: theme.spacing(2),
      width: '10%',
      color: theme.palette.primary.lightest,
      '& svg': {
        fill: theme.palette.primary.light,
      },
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
    '& .MuiToolbar-root': {
      padding: 0,
      paddingTop: '28px',
    },
    '& .MuiTableBody-root': {
      backgroundColor: theme.palette.primary.contrastText,
      borderLeft: '0.5rem solid rgba(247, 218, 11, 11)',
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  bottomContent: {
    background: theme.palette.primary.lightest,
  },
}));

const Row = props => {
  const { row, parentChildren } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row[0]}
        </TableCell>
        <TableCell>{row[1]}</TableCell>
        <TableCell>{row[4]}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {parentChildren}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.oneOfType([PropTypes.array]),
  parentChildren: PropTypes.shape({}),
};

Row.defaultProps = {
  row: [],
  parentChildren: {},
};

const PurchaseOrderPricingTable = ({ materials, children }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      name: 'materialName',
      label: t('common.material'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
    {
      name: 'netValue',
      label: t('pos.netValue'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },

    {
      name: 'pricings',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'isRebateContract',
      label: t('common.materialDescription'),
      options: {
        display: false,
      },
    },
    {
      name: 'accumulatedVolume',
      label: t('pos.accumulatedVolume'),
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <TableHeadCell
            key={uniqid()}
            columnMeta={columnMeta}
            handleToggleColumn={handleToggleColumn}
          />
        ),
      },
    },
  ];

  return (
    <Table
      title=""
      className={classes.poPrincingTable}
      columns={columns}
      data={materials}
      options={{
        pagination: false,
        filter: false,
        customRowRender: data =>
          data && data.length > 0 && <Row row={data} parentChildren={children} />,
        search: false,
        searchOpen: false,
        elevation: 0,
        textLabels: {
          body: {
            noMatch: t('common.noMatchingRecords'),
          },
          pagination: {
            rowsPerPage: t('common.rowsPerPage'),
          },
        },
      }}
    />
  );
};

PurchaseOrderPricingTable.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.shape({}),
};

PurchaseOrderPricingTable.defaultProps = {
  materials: [],
  children: {},
};

export default PurchaseOrderPricingTable;
