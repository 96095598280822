import React from 'react';
import PropTypes from 'prop-types';
import { BecOrganizationProvider } from '@eyblockchain/ey-ui/core/BecFramework';
import { SubscriptionProvider } from './subscription';
import { NotificationProvider } from './notification';
import UserInfoProvider from '../UserInfoProvider';

function RootProviders({ children }) {
  return (
    <UserInfoProvider>
      <SubscriptionProvider>
        <BecOrganizationProvider>
          <NotificationProvider>{children}</NotificationProvider>
        </BecOrganizationProvider>
      </SubscriptionProvider>
    </UserInfoProvider>
  );
}

RootProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootProviders;
