import React, { useState } from 'react';
import { findIndex } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Card } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import uniqid from 'uniqid';
import { useBecOrganizationContext } from '@eyblockchain/ey-ui/core/BecFramework/BecOrganizationProvider';
import { TAB_VALUES } from '../../constants';
import { useNotarizationContext } from '../../contexts/Notarization/notarizationContext';
import RegisteredDocuments from './documents/RegisteredDocuments';
import RegisteredCertificates from './certificates/RegisteredCertificates';
import DocumentUpload from './documents/DocumentUpload';
import CertificateUpload from './certificates/CertificateUpload';
import ContractPermissions from '../Shared/ContractPermissions';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  contractListRoot: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
  },
  contractListContentArea: {
    marginTop: theme.spacing(2),
  },
}));

const NotarizationContractDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TAB_VALUES.NOTARIZATION_CONTRACT.REG_DOCUMENT);
  const {
    documentUploadVisible,
    certUploadVisible,
    selectedNotarizationContract,
  } = useNotarizationContext();
  const { activeOrganization } = useBecOrganizationContext();
  const {
    permissionsFlags: {
      isUserAuthToNotarizeCertificates,
      isUserAuthToNotarizeDocuments,
      isUserAuthToVerifyDocuments,
    },
  } = useUserInfo();

  const tabs = [
    {
      label: t('notarization.registeredDocuments'),
      name: TAB_VALUES.NOTARIZATION_CONTRACT.REG_DOCUMENT,
    },
    {
      label: t('notarization.issuedCertificates'),
      name: TAB_VALUES.NOTARIZATION_CONTRACT.ISSUE_CERTIFICATE,
    },
  ];

  if (activeOrganization?._id === selectedNotarizationContract?.organizationId) {
    tabs.push({
      label: t('common.permissions'),
      name: TAB_VALUES.PERMISSIONS,
    });
  }

  const tabChangeHandler = (event, newTabIndex) => {
    event.preventDefault();
    setActiveTab(tabs[newTabIndex]?.name);
  };

  if (documentUploadVisible && (isUserAuthToNotarizeDocuments || isUserAuthToVerifyDocuments)) {
    return <DocumentUpload />;
  }

  if (certUploadVisible && isUserAuthToNotarizeCertificates) {
    return <CertificateUpload />;
  }

  return (
    <Card variant="outlined" className={classes.contractListRoot}>
      <Tabs
        value={findIndex(tabs, { name: activeTab })}
        onChange={tabChangeHandler}
        classes={{
          indicator: classes.indicator,
        }}
      >
        {tabs.map(({ label, name }) => (
          <Tab label={label} key={uniqid()} data-testid={`notarization-tab-${name}`} />
        ))}
      </Tabs>
      <div className={classes.contractListContentArea}>
        {activeTab === TAB_VALUES.NOTARIZATION_CONTRACT.REG_DOCUMENT && <RegisteredDocuments />}
        {activeTab === TAB_VALUES.NOTARIZATION_CONTRACT.ISSUE_CERTIFICATE && (
          <RegisteredCertificates />
        )}
        {activeTab === TAB_VALUES.PERMISSIONS && (
          <ContractPermissions selectedContract={selectedNotarizationContract} />
        )}
      </div>
    </Card>
  );
};
export default NotarizationContractDetails;
