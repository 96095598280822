import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useSubscription, useMutation } from '@apollo/react-hooks';
import { useNotification } from '../Shared/notification';
import track from '../../mixpanel';
import {
  GET_INVOICES,
  GET_INVOICE_UPDATE,
  GET_INVOICE_BY_ID,
  CREATE_INVOICE,
} from '../../graphql/Procurement/invoice';
import { orderListing } from '../../utils';

const InvoiceContext = React.createContext([{}, () => {}]);

const InvoiceProvider = ({ children }) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotification();
  const [invoices, setInvoices] = useState([]);
  const [getInvoices, { error: invoicesError, loading: invoicesLoading }] = useLazyQuery(
    GET_INVOICES,
    {
      onCompleted: data => {
        setInvoices(orderListing(data.invoices));
      },
      fetchPolicy: 'no-cache',
    },
  );
  const [
    getInvoiceDetail,
    { data: invoiceDetailData, error: invoiceDetailError, loading: invoiceDetailLoading },
  ] = useLazyQuery(GET_INVOICE_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  useSubscription(GET_INVOICE_UPDATE, {
    onSubscriptionData: data =>
      setInvoices(stateInvoices => [data.subscriptionData.data.newInvoice, ...stateInvoices]),
  });

  const [postInvoice, { loading: postInvoiceLoading }] = useMutation(CREATE_INVOICE, {
    onCompleted: response => {
      if (response.createInvoice !== null) {
        const successMsg = `${t(
          'invoices.createInvoiceSuccess',
        )}PO-${response.createInvoice.purchaseOrder.purchaseOrderId.padStart(10, 0)}
        ${t('invoices.createInvoiceSuccess-Subheader')}
        IN-${response.createInvoice.invoiceId.padStart(10, 0)}`;
        track('Invoice submission status', { invoiceSubmissionStatus: 'Success' });
        handleNotification(successMsg, 'success');
      }
    },
    onError: errorResponse => {
      if (
        errorResponse.message === 'GraphQL error: Invoice already created for this purchase order'
      ) {
        track('Invoice submission status', { invoiceSubmissionStatus: 'Fail' });
        handleNotification(t('invoices.alreadyInvoiceCreated'), 'error');
      } else {
        track('Invoice submission status', { invoiceSubmissionStatus: 'Fail' });
        handleNotification(t('invoices.createInvoiceError'), 'error');
      }
    },
  });

  const invoiceLoading = invoicesLoading || invoiceDetailLoading || postInvoiceLoading;
  const error = invoicesError || invoiceDetailError;

  return (
    <InvoiceContext.Provider
      value={{
        invoices,
        invoiceDetailData,
        invoiceLoading,
        error,
        getInvoices,
        getInvoiceDetail,
        setInvoices,
        postInvoice,
        invoiceData: invoiceDetailData?.invoice,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

const useInvoiceContext = () => useContext(InvoiceContext);

InvoiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useInvoiceContext, InvoiceProvider };
