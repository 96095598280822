import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@eyblockchain/ey-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const WarningDialog = ({ show, toggle }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={show}>
      <DialogTitle>{t('common.errors.unsavedWarning')}</DialogTitle>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => toggle(false)}>
          {t('common.back')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WarningDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default WarningDialog;
